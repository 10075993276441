import React from "react";
import { useClient } from "../../../../hooks/useClient";
import ClientDetails, {
  ClientDetailsDialogMode,
} from "../../../../components/coach-clients/ClientDetails";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { DefaultLoader } from "../../../../components/loading/DefaultLoader";
import { Box } from "@mui/material";
import LayoutLoadingContext from "../../../../contexts/LayoutLoadingContext";

function CoachClientGeneric({
  dialogMode,
}: {
  dialogMode?: ClientDetailsDialogMode;
}) {
  const client = useClient();

  return (
    client && (
      <>
        <ClientDetails dialog={dialogMode} tags={client.tags} />
        <TrackInfoTool
          trackInfo={{
            name:
              dialogMode === "progress"
                ? "Coach - Client Progress Photos"
                : dialogMode === "stats"
                  ? "Coach - Client Stats"
                  : "Coach - Client Overview",
            properties: {
              clientUsername: client.username,
            },
          }}
        />
      </>
    )
  );
}

export const CoachClientOverviewRoute = () => {
  return <CoachClientGeneric />;
};

export const CoachClientProgressRoute = () => {
  return <CoachClientGeneric dialogMode={ClientDetailsDialogMode.PROGRESS} />;
};
