import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import useAuth from "../hooks/auth/useAuth";
import { UserRole } from "../constants";
import { COACH_PROGRAMS_PUBLISHED_ROUTE, HOME_ROUTE } from "../routes/routes";
import { useLogout } from "../hooks/useLogout";

export const RequireLogoutPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole } = useAuth();

  const from = location.state?.from?.pathname || "/";
  const homeHref =
    userRole === UserRole.COACH ? COACH_PROGRAMS_PUBLISHED_ROUTE : HOME_ROUTE;

  const logout = useLogout({ returnUrl: from, immediate: true });

  const handleGoHome = () => {
    navigate(homeHref, { replace: true });
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <Box
          sx={{
            textAlign: "center",
            maxWidth: 400,
            mx: "auto",
          }}
        >
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 600 }}>
            You're Already Logged In
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 4 }}>
            You need to log out first to access this page. Would you like to log
            out and continue?
          </Typography>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            <Button
              variant="outlined"
              onClick={handleGoHome}
              sx={{ minWidth: 120 }}
            >
              Go Home
            </Button>
            <Button
              variant="contained"
              onClick={handleLogout}
              sx={{ minWidth: 120 }}
            >
              Log Out
            </Button>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};
