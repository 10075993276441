import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Box,
  ListItemIcon,
  Typography,
  ListItem,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize } from "lodash";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import { ProUpgradeCard, ProUpgradeCardProps } from "../card/ProUpgradeCard";
import { RequiresUpgradeDialog } from "../dialog/RequiresUpgradeDialog";
import { UpgradedDialog } from "../dialog/payments/UpgradedDialog";
import { getRequiresUpgrade } from "../../utils/user";
import { useQueryParam } from "../../hooks/useQueryParam";
import { CoachSettingsPlanTextSubtitle } from "./CoachSettingsPlanTextSubtitle";
import {
  SelectClientsDialog,
  SelectClientsDialogProps,
} from "../dialog/SelectClientsDialog";
import {
  Plan,
  REACTIVATE_PROMO_CANCEL_BEFORE_DATE,
  planClients,
} from "../../constants";
import { ReactComponent as CheckMarkCircle } from "../../icons/CheckMarkCircle.svg";
import { CoachSettingsPlanStatusText } from "./CoachSettingsPlanStatusText";

import { UserInfo } from "../../hooks/useCurrentUser";
import { ActiveClientsSlider } from "./ActiveClientsSlider";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { useMutation } from "@tanstack/react-query";
import BillingsService from "../../services/BillingsService";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    padding: theme.spacing(3, 2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 4),
    },
  },

  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2.5),
  },

  layout: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      marginBottom: 0,
    },
  },

  leftColumn: {
    flex: 1,
    marginRight: 0,

    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(4),
    },
  },

  rightColumn: {
    flex: 1,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
    },
  },

  features: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: theme.spacing(4.5),
    marginTop: theme.spacing(4),

    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5),
      marginBottom: 0,
    },
  },

  feature: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(3),
    },
  },

  check: {
    marginRight: theme.spacing(1.25),
    "& svg": {
      width: 30,
      height: 30,
      color: theme.palette.primary.main,
    },
  },

  text: {
    fontSize: 16,

    fontWeight: "bold",
    color: theme.palette.common.black,
  },

  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },

  card: {
    marginTop: theme.spacing(4),
  },

  downgrade: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  },
  titleModal: {
    lineBreak: "anywhere",
  },
  customerPortal: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: theme.spacing(2),
  },

  icon: {
    fontSize: theme.typography.fontSize * 1.5,
    marginRight: theme.spacing(1),
  },

  subtext: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },

  customerPortalBox: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

type GetFeaturesProps = {
  text: string;
};

function getFeatures(plan: Plan): GetFeaturesProps[] {
  if (!plan) {
    return [];
  }

  const clientsCount = planClients(plan) as string;

  return [
    {
      text: "Stridist Coaching Portal",
    },
    {
      text: "Stridist Marketing Suite",
    },
    {
      text: "Stridist Business Incubator",
    },
    {
      text: `${plan !== Plan.UP_TO_UNLIMITED ? "Up to " : ""}${capitalize(
        clientsCount,
      )} clients`,
    },
  ];
}

export interface CoachSettingsPlanProps
  extends Omit<ContainerProps, "children"> {
  user: UserInfo;
}

export function CoachSettingsPlan(props: CoachSettingsPlanProps) {
  const { className, user, ...other } = props;
  const s = useStyles();
  const { showToastAlert } = useToastAlert();
  const [requiresUpgrade, reason] = getRequiresUpgrade(
    user.trialExpired,
    user.plan as Plan,
    user.subscription?.status,
  );
  const [requiresUpgradeDialogOpen, setRequiresUpgradeDialogOpen] =
    React.useState(requiresUpgrade);
  const [upgraded, setUpgraded] = useQueryParam("upgraded", undefined);
  const [plan] = useQueryParam("plan", undefined);
  const [
    downgradeSelectClientsDialogOpen,
    setDowngradeSelectClientsDialogOpen,
  ] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState<Plan>(
    Plan[user.plan] || Plan.UP_TO_INFINITY,
  );

  const {
    mutate: createCustomerPortal,
    isPending: creatingCustomerPortal,
    isSuccess: createdCustomerPortal,
  } = useMutation({
    mutationKey: ["create-customer-portal"],
    mutationFn: BillingsService.createCustomerPortal,
  });

  const createPortal = React.useCallback(() => {
    createCustomerPortal(undefined, {
      onSuccess: (sessionUrlDto) => {
        const { sessionUrl } = sessionUrlDto;
        if (sessionUrl) {
          window.location.href = sessionUrl;
        } else {
          showToastAlert("error", {
            message: "Something went wrong while creating Customer Portal.",
          });
        }
      },
    });
  }, [createCustomerPortal]);

  const handleCloseRequiresUpgradeDialog = React.useCallback(() => {
    setRequiresUpgradeDialogOpen(false);
  }, []);

  const handleCloseUpgradedDialog = React.useCallback(() => {
    setUpgraded(undefined);
  }, [setUpgraded]);

  const handleDowngradeClick = React.useCallback(() => {
    if (
      !user.plan &&
      user.clientsCountNoSample > Number(planClients(selectedPlan, true))
    ) {
      setDowngradeSelectClientsDialogOpen(true);
    }
  }, [user.clientsCountNoSample, user.plan, selectedPlan]);

  const handlePlanChange: ProUpgradeCardProps["onPlanChange"] =
    React.useCallback((plan) => {
      setSelectedPlan(plan);
    }, []);

  const features = React.useMemo(
    () => getFeatures(user.plan as Plan),
    [user.plan],
  );

  const pending = creatingCustomerPortal || createdCustomerPortal;

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={s.layout}>
        <Box className={s.leftColumn}>
          <CoachSettingsPlanStatusText
            plan={user.plan}
            subscription={user.subscription}
          />

          <CoachSettingsPlanTextSubtitle
            plan={user.plan}
            trialExpired={user.trialExpired}
            trialExpiredDate={user.trialExpiryDate?.toString()}
            subscription={user.subscription}
          />

          <Box className={s.features}>
            <Typography className={s.title}>What's included?</Typography>
            {features.map(({ text }, index) => (
              <ListItem key={index} className={s.feature} disableGutters>
                <ListItemIcon className={s.check}>
                  <CheckMarkCircle />
                </ListItemIcon>
                <Typography className={s.text}>{text}</Typography>
              </ListItem>
            ))}
          </Box>

          <Box className={s.customerPortalBox}>
            <Typography className={s.subtext}>
              Change credit card details, keep billing address up to date,
              update subscription, download invoices. All in one place.
            </Typography>
            <Button
              variant="outlined"
              className={s.customerPortal}
              disabled={pending}
              onClick={() => {
                createPortal();
              }}
            >
              <CreditCardIcon className={clsx(s.icon)} />
              Manage my subscription
            </Button>
          </Box>
        </Box>

        <Box className={s.rightColumn}>
          <ActiveClientsSlider
            activeClients={user?.clientsCountNoSample}
            currentPlan={user?.plan as Plan}
          />

          <ProUpgradeCard
            className={s.card}
            user={user}
            onPlanChange={handlePlanChange}
          />
        </Box>
      </Box>

      {!user.isImpersonating &&
        new Date(user?.subscription?.canceledDate) >
          REACTIVATE_PROMO_CANCEL_BEFORE_DATE && (
          <RequiresUpgradeDialog
            open={requiresUpgradeDialogOpen}
            onClose={handleCloseRequiresUpgradeDialog}
            reason={reason}
            onDowngradeClick={handleDowngradeClick}
          />
        )}

      <UpgradedDialog
        open={Boolean(upgraded)}
        onClose={handleCloseUpgradedDialog}
        plan={plan}
      />
    </Container>
  );
}
