import { fbUsersClient } from "../api/ApiClients";
import {
  ClientSignUpCommand,
  ClientSignUpWithAuthTokenCommand,
  CompleteCoachSignUpCommand,
  IClientSignUpCommand,
  IClientSignUpWithAuthTokenCommand,
  ICompleteCoachSignUpCommand,
  IPreCoachSignUpCommand,
  IResetPasswordCommand,
  PreCoachSignUpCommand,
  ResetPasswordCommand,
  IVerifySignUpEmailCommand,
  VerifySignUpEmailCommand,
  IPreClientSignUpCommand,
  PreClientSignUpCommand,
  IClientSignUpWithCodeCommand,
  ClientSignUpWithCodeCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class SignUpService extends ServiceBase {
  async clientSignUp(variables: IClientSignUpCommand) {
    const command = ClientSignUpCommand.fromJS(variables);
    return fbUsersClient.clientSignUp(command);
  }

  async clientSignUpWithInviteCode(variables: IClientSignUpWithCodeCommand) {
    const command = ClientSignUpWithCodeCommand.fromJS(variables);
    return fbUsersClient.clientSignUpWithCode(command);
  }

  async preCoachSignUp(variables: IPreCoachSignUpCommand) {
    const command = PreCoachSignUpCommand.fromJS(variables);
    return fbUsersClient.preCoachSignUp(command);
  }

  async preClientSignUp(variables: IPreClientSignUpCommand) {
    const command = PreClientSignUpCommand.fromJS(variables);
    return fbUsersClient.preClientSignUp(command);
  }

  async verifyCoachSignUpEmail(variables: IVerifySignUpEmailCommand) {
    const command = VerifySignUpEmailCommand.fromJS(variables);
    return fbUsersClient.verifySignUpEmail(command);
  }

  async completeCoachSignUp(variables: ICompleteCoachSignUpCommand) {
    const command = CompleteCoachSignUpCommand.fromJS(variables);
    return fbUsersClient.completeCoachSignUp(command);
  }

  async clientSignUpWithAuthToken(
    variables: IClientSignUpWithAuthTokenCommand,
  ) {
    const command = ClientSignUpWithAuthTokenCommand.fromJS(variables);
    return fbUsersClient.clientSignUpWithAuthToken(command);
  }

  async resetPassword(variables: IResetPasswordCommand) {
    const command = ResetPasswordCommand.fromJS(variables);
    fbUsersClient.resetPassword(command);
  }
}

export default new SignUpService();
