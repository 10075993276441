import React from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { Box, Fade, IconButton, Typography } from "@mui/material";

import { EmbedElementType, MoreMenu } from "./MoreMenu";
import ReactPlayer from "react-player";
import { Volume2 } from "lucide-react";
import { EmbedFileDialog } from "../modals/EmbedFileDialog";
import { useReadOnly } from "../hooks";

export const AUDIO = "audio";

interface IMediaEmbedProps {
  handleChange: (
    url: string,
    name: string,
    size: number,
    mimeType: string,
  ) => void;
  handleRemoveNode: () => void;
  element: any;
  url: string;
  name: string;
  size: number | null;
  mimeType: string;
}

export const Audio = ({
  handleChange,
  handleRemoveNode,
  url,
  name,
  size,
  mimeType,
  element,
}: IMediaEmbedProps) => {
  const empty = !url;
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const readOnly = useReadOnly();
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  const handleClick = () => {
    if (!readOnly && !openDialog && empty) {
      setOpenDialog(true);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  if (empty && readOnly) return <></>;

  return (
    <Box
      display={"flex"}
      gap={1.5}
      sx={{
        border: (theme) =>
          empty ? `${theme.shape.border.xs}px solid` : "none",
        borderRadius: 1,
        padding: empty ? 2.5 : 0,
        pt: 1.4,
        pr: 0,
        display: "flex",
        backgroundColor: (theme) =>
          empty ? theme.palette.selected.light : null,
        borderColor: (theme) => theme.palette.border.primary,
      }}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline",

          width: "100%",
        }}
        contentEditable={false}
      >
        {empty ? (
          <Box sx={{ pt: 1, display: "flex", gap: 1 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.text.disabled, p: 0 }}
            >
              <Volume2 />
            </IconButton>
            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              Upload or embed audio
            </Typography>
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <ReactPlayer width="100%" height="110px" controls url={url} />
          </Box>
        )}
        {!readOnly && (
          <>
            <Fade in={isHovered}>
              <Box>
                <MoreMenu
                  element={element}
                  handleRemoveNode={handleRemoveNode}
                  empty={empty}
                  setOpenDialog={setOpenDialog}
                  type={EmbedElementType.AUDIO}
                ></MoreMenu>
              </Box>
            </Fade>
            <EmbedFileDialog
              url={url}
              size={size}
              name={name}
              mimeType={mimeType}
              open={openDialog}
              type={EmbedElementType.AUDIO}
              title="Upload an Audio"
              accept=".mp3,audio/*"
              onSubmit={handleChange}
              onClose={handleClose}
            ></EmbedFileDialog>
          </>
        )}
      </Box>
    </Box>
  );
};

export const AudioElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element } = props;
    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    if (!path) return;

    const handleChange = React.useCallback(
      (url: string, name: string, size: number, mimeType: string) => {
        setNodes(editor, { ...{}, url, name, size, mimeType }, { at: path });
      },
      [],
    );

    const handleRemoveNode = () => {
      removeNodes(editor, { at: path });
    };

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <Box sx={{ visibility: "hidden", height: 0 }} contentEditable={false}>
          {children}
        </Box>
        <Audio
          size={element.size as number}
          name={element.name as string}
          url={element.url as string}
          mimeType={element.mimeType as string}
          element={element}
          handleChange={handleChange}
          handleRemoveNode={handleRemoveNode}
        />
      </PlateElement>
    );
  },
);
