import queryString from "query-string";

import { ClientSort, NavigationItem } from "../../constants";
import {
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_CLIENTS_ARCHIVED_ROUTE,
  COACH_CLIENTS_FORMS_ROUTE,
  COACH_CLIENTS_PENDING_ROUTE,
} from "../../routes/routes";
import { CoachClientsCount } from "../../contexts/CoachTabsContext";
import { Box, Skeleton } from "@mui/material";
import React from "react";
import InlineCounter from "../loading/InlineCounter";

export const clientsNavigation = (
  count?: CoachClientsCount,
): NavigationItem[] => {
  const query: any = () => ({
    // sort: queryString.parse(window.location.search).sort || ClientSort.NAME_ASC,
    sort: "",
    tag: "",
  });

  return [
    {
      name: "Active",
      link: COACH_CLIENTS_ACTIVE_ROUTE,
      query,
    },
    {
      name: (
        <InlineCounter
          count={count.pending}
          label="Pending"
          loading={!count.pending && count.pending !== 0}
        />
      ),
      link: COACH_CLIENTS_PENDING_ROUTE,
      query,
    },
    {
      name: "Archived",
      link: COACH_CLIENTS_ARCHIVED_ROUTE,
      query,
    },
    {
      name: "Forms",
      link: COACH_CLIENTS_FORMS_ROUTE,
      query: () => ({}),
    },
  ];
};
