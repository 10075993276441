import clsx from "clsx";
import React, { useState, useEffect } from "react";
import {
  DialogProps,
  Button,
  useTheme,
  Typography,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useCurrentUser } from "../../../hooks/useCurrentUser";

import { BaseDialog } from "../BaseDialog";
import { Celebration } from "../../../icons/Celebration";
import { DefaultLoader } from "../../loading/DefaultLoader";
import { useQueryClient } from "@tanstack/react-query";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import { useToastAlert } from "../../app/ToastAlert/ToastAlertProvider";
import { useCreateSubAccountMutation } from "../../admin/mutations/useCreateSubAccountMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",

    "& .MuiDialog-paper": {
      minWidth: "240px",
      minHeight: "180px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  svg: {
    width: "100%",
    maxWidth: 345,
    margin: theme.spacing(4, 0),
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
  },
}));

export interface UpgradedDialogProps extends DialogProps {}

export function ActivatedDialog(props: UpgradedDialogProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const { showToastAlert } = useToastAlert();
  const [hasExecuted, setHasExecuted] = useState(false);

  const { className, onClose, open, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const queryClient = useQueryClient();

  const { mutate: createSubAccount, isPending: creatingSubAccount } =
    useCreateSubAccountMutation();

  useEffect(() => {
    if (
      user?.subscription?.status === "trialing" ||
      user?.subscription?.status === "active" ||
      user?.subscription?.status === "incomplete"
    ) {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (!open) return;

    let count = 0;

    const intervalId = setInterval(() => {
      if (count >= 30) {
        clearInterval(intervalId);
        onClose({}, "backdropClick");
        showToastAlert("error", { message: "Unable to fetch user data." });
        return;
      }
      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
      count++;
    }, 3000);

    return () => clearInterval(intervalId);
  }, [queryClient]);

  const handleButtonClick = React.useCallback(
    (event) => {
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  useEffect(() => {
    if (!isLoading && !hasExecuted) {
      createSubAccount({ coachId: user.id });
      setHasExecuted(true);
    }
  }, [isLoading, createSubAccount, user.id, hasExecuted]);

  return (
    <BaseDialog
      open={open}
      className={clsx(s.root, className)}
      title={!isLoading && "Hooray! Your account is reactivated!"}
      showClose={false}
      {...other}
    >
      {isLoading ? (
        <Stack
          spacing={1.5}
          direction="column"
          sx={{
            mt: 3,
          }}
        >
          <DefaultLoader />
          <Typography variant="caption">Processing...</Typography>
        </Stack>
      ) : (
        <>
          <Celebration className={s.svg} fill={theme.palette.primary.main} />
          <Button
            className={s.button}
            fullWidth
            variant="contained"
            children="Let’s Go!"
            onClick={handleButtonClick}
          />
        </>
      )}
    </BaseDialog>
  );
}
