import React, { useState } from "react";
import {
  TextField,
  TextFieldProps,
  Typography,
  IconButton,
  Stack,
  ClickAwayListener,
  Box,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { passwordRules } from "../../utils/password";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { motion, AnimatePresence } from "framer-motion";

const rules = {
  length: {
    ...passwordRules.length,
    message: "At least 8 characters",
  },
  alphabeticalCharacter: {
    ...passwordRules.alphabeticalCharacter,
    message: "One alphabetical character",
  },
  specialCharacter: {
    ...passwordRules.specialCharacter,
    message: "One special character",
  },
  numericCharacter: {
    ...passwordRules.numericCharacter,
    message: "One number",
  },
};

type Props = TextFieldProps & {
  register?: any;
  tooltipPlacement?: TooltipProps["placement"];
};

const ValidationRequirements = ({ validations }) => (
  <Stack spacing={1}>
    {validations.map(({ message, valid }, index) => (
      <Stack key={index} direction="row" spacing={1} alignItems="center">
        {valid ? (
          <TaskAltIcon sx={{ fontSize: 16, color: "success.main" }} />
        ) : (
          <RadioButtonUncheckedIcon
            sx={{ fontSize: 16, color: "text.disabled" }}
          />
        )}
        <Typography
          variant="body2"
          noWrap
          sx={{
            color: valid ? "success.main" : "text.secondary",
          }}
        >
          {message}
        </Typography>
      </Stack>
    ))}
  </Stack>
);

const PasswordField = ({
  value,
  register,
  tooltipPlacement,
  ...props
}: Props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isMobile = useMediaMobile();

  const validations = Object.keys(rules).map((key) => {
    const rule = rules[key as keyof typeof rules];
    return {
      message: rule.message,
      valid: rule.regex.test((value as string) || ""),
    };
  });

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setOpen(true);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Tooltip
          arrow
          open={open}
          onClose={handleClickAway}
          placement={tooltipPlacement ?? (isMobile ? "top" : "left-end")}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Box sx={{ minWidth: "fit-content" }}>
              <Typography
                variant="subtitle2"
                sx={{ mb: 1, color: "text.secondary" }}
              >
                Password requirements:
              </Typography>
              <ValidationRequirements validations={validations} />
            </Box>
          }
          slotProps={{
            arrow: {
              sx: {
                fontSize: 14,
                "&:before": {
                  border: "1px solid",
                  borderColor: "divider",
                },
                color: (theme) => theme.palette.common.white,
              },
            },
            tooltip: {
              sx: {
                backgroundColor: "background.paper",
                border: "1px solid",
                borderColor: "divider",
                boxShadow: (theme) => theme.shadows[2],
              },
            },
            popper: {
              disablePortal: true,
            },
          }}
        >
          <TextField
            {...props}
            {...(register || {})}
            type={passwordVisible ? "text" : "password"}
            onFocus={handleFocus}
            slotProps={{
              input: {
                endAdornment: (
                  <IconButton
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    edge="end"
                    size="small"
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              },
            }}
          />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default PasswordField;
