import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { HttpError } from "found";

import { AppLogo } from "../app/AppLogo";
import {
  useCurrentBrand,
  useCurrentWorkspace,
} from "../../hooks/useCurrentWorkspace";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { Login } from "../login/Login";
import { ClientPortalMode } from "../../constants";
import { ForgotPassword } from "../login/ForgotPassword";
import { ChangePassword } from "../auth/ChangePassword";
import ErrorPage from "../app/ErrorPage";
import { isMobileApp } from "../../utils/mobile";
import { WelcomeClient } from "../auth/WelcomeClient";
import { EnterInviteCode } from "../auth/EnterInviteCode";
import { ReactComponent as ArrowBackIcon } from "../../icons/ArrowBack.svg";

import { ClientPortalLoginForm } from "./ClientPortalLoginForm";
import { ClientPortalForgotPasswordForm } from "./ClientPortalForgotPasswordForm";
import { ClientPortalChangePasswordForm } from "./ClientPortalChangePasswordForm";
import { polyfillCSS } from "../../utils/css";
import { ConsentBanner } from "../app/ConsentBanner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: theme.spacing(6),
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
  },

  section: {
    width: "100%",
    maxWidth: 450,
    paddingLeft: 0,
    paddingRight: 0,
  },

  header: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },

  logo: {
    cursor: "pointer",
    marginBottom: theme.spacing(1),
  },

  forgotPassword: {
    textAlign: "center",
  },

  title: {
    fontWeight: 600,
    fontSize: 18,
    margin: theme.spacing(1.5, 0, 1),

    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      margin: theme.spacing(3, 0, 1.5),
    },
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(2),
  },

  forgot: {
    fontSize: 16,
  },

  backButton: {
    fontSize: 18,
    fontWeight: 600,
    padding: theme.spacing(1),
    margin: theme.spacing(-1, 0, 0.5, -1),

    "& svg": {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      marginRight: theme.spacing(0.5),
    },

    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: polyfillCSS(
        `calc(${theme.spacing(4)} + var(--safe-area-inset-top))`,
      ),
      left: polyfillCSS(
        `calc(${theme.spacing(3)} + var(--safe-area-inset-left))`,
      ),
    },
  },
}));

export interface ClientPortalScreenProps
  extends Omit<ContainerProps, "children"> {
  mode?: ClientPortalMode;
  slug?: string;
}

export const ClientPortalScreen = (props: ClientPortalScreenProps) => {
  const { className, mode: initialMode, slug, ...other } = props;

  const s = useStyles();
  const { brandName } = useCurrentBrand();

  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));

  const [mode, setMode] = React.useState<ClientPortalMode>(initialMode);
  const user = useCurrentUser();
  const workspace = useCurrentWorkspace() || ({} as any);
  const { name } = workspace;
  const [email, setEmail] = React.useState("");

  const handleForgotClick = React.useCallback(() => {
    setMode(ClientPortalMode.FORGOT_PASSWORD);
  }, []);

  const handleSignUpClick = React.useCallback(() => {
    setMode(ClientPortalMode.SIGNUP);
  }, []);

  const handleSignInClick = React.useCallback(() => {
    setMode(ClientPortalMode.LOGIN);
  }, []);

  const handleLogoClick = React.useCallback(() => {
    setMode(initialMode);
  }, [initialMode]);

  const handleBackClick = handleSignInClick;
  const slugMismatch = slug && slug !== workspace?.slug;

  return (slugMismatch && !isMobileApp) || Boolean(user) ? (
    <ErrorPage error={new HttpError(404)} />
  ) : (
    <Container className={clsx(s.root, className)} {...other}>
      <Box className={clsx(s.header, s.section)}>
        {mode === ClientPortalMode.SIGNUP && (
          <Button className={s.backButton} onClick={handleBackClick}>
            <ArrowBackIcon />
            Back
          </Button>
        )}

        {(mode !== ClientPortalMode.SIGNUP || mdUp) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AppLogo
              className={s.logo}
              onClick={handleLogoClick}
              {...(!mdUp && {
                full: true,
                width: 160,
                height: 37,
              })}
            />
          </Box>
        )}
        <Typography className={s.title} variant="h1">
          {mode === ClientPortalMode.WELCOME || !name ? (
            <>
              Your Fitness & Nutrition coaching.
              <br />
              On Autopilot.
            </>
          ) : mode === ClientPortalMode.SIGNUP ? (
            "Please enter your invite code."
          ) : (
            name
          )}
        </Typography>
        <Typography className={s.subtitle} variant="h2">
          {mode === ClientPortalMode.SIGNUP
            ? "Ask your coach for a code, then enter it below."
            : ![
                  ClientPortalMode.WELCOME,
                  ClientPortalMode.FORGOT_PASSWORD,
                ].includes(mode)
              ? `Create a free ${brandName} account to join your coach.`
              : ""}
        </Typography>
      </Box>

      {mode === ClientPortalMode.WELCOME && (
        <WelcomeClient
          className={s.section}
          onSignIn={handleSignInClick}
          onSignUp={handleSignUpClick}
        />
      )}

      {mode === ClientPortalMode.SIGNUP && (
        <EnterInviteCode className={s.section} />
      )}

      {mode === ClientPortalMode.LOGIN && (
        <Box className={clsx(s.section, s.forgotPassword)}>
          <Login component={ClientPortalLoginForm} onEmailChange={setEmail} />
          <Button variant="text" color="primary" onClick={handleForgotClick}>
            Forgot password?
          </Button>
        </Box>
      )}

      {mode === ClientPortalMode.FORGOT_PASSWORD && (
        <ForgotPassword
          className={s.section}
          email={email}
          component={ClientPortalForgotPasswordForm}
          workspaceSlug={workspace.slug}
          onCancel={handleBackClick}
        />
      )}

      {mode === ClientPortalMode.CHANGE_PASSWORD && (
        <ChangePassword
          className={s.section}
          component={ClientPortalChangePasswordForm}
        />
      )}
      <ConsentBanner />
    </Container>
  );
};
