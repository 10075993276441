import clsx from "clsx";
import React from "react";
import {
  MenuList,
  MenuListProps,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ForwardIcon } from "../../icons/ChevronForwardOutline.svg";

import { LibrarySections, LibrarySection } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
  name: {
    fontSize: 24,
    fontWeight: 600,
  },
  description: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 700,
  },
  forward: {
    color: theme.palette.text.secondary,
    width: 24,
    height: 24,
  },
}));

export interface ComponentLibrarySectionListProps extends MenuListProps {
  onSectionChange?: (section: LibrarySection) => void;
}

export function ComponentLibrarySectionList(
  props: ComponentLibrarySectionListProps,
) {
  const { className, onSectionChange, ...other } = props;
  const s = useStyles();
  const entries = Object.entries(LibrarySections);

  const handleSectionClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const {
        dataset: { section },
      } = event.currentTarget;

      if (onSectionChange) {
        onSectionChange(section as any);
      }
    },
    [onSectionChange],
  );

  return (
    <MenuList className={clsx(s.root, className)} {...other}>
      {entries.map(
        ([sectionType, { name, description, icon: Icon }], index) => (
          <div key={sectionType}>
            {index !== 0 && <Divider />}
            <ListItemButton
              data-section={sectionType}
              onClick={handleSectionClick}
            >
              <ListItemIcon children={<Icon />} className={s.icon} />
              <ListItemText
                primary={name}
                secondary={description}
                classes={{
                  primary: s.name,
                  secondary: s.description,
                }}
              />
              <ForwardIcon className={s.forward} />
            </ListItemButton>
          </div>
        ),
      )}
    </MenuList>
  );
}
