import {
  alpha,
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  ChevronsRight,
  FlagTriangleRight,
  PanelLeftOpen,
  PanelRightOpen,
} from "lucide-react";
import React, { ReactNode, useContext, useState } from "react";
import ComponentBarHeightContext from "../../contexts/ComponentBarHeightContext";
import ScheduleWithMap from "../curriculum-sidebar/tabs/ScheduleWithMap";
import { CURRICULUM_SIDEBAR_WIDTH } from "../curriculum-sidebar/Sidebar";
import { ProgramWeeksContext } from "../../hooks/useProgramWeeks";
import { ComponentRepeat, ComponentType, ReminderType } from "../../constants";
import { parseComponentDays } from "../../utils/component";
import ComponentTimeSettings from "../reminder/ComponentTimeSettings";
import { CurriculumComponent } from "../../redux/types";
import {
  countComponentDays,
  getScheduleEndWeek,
} from "../program-component/utils";
import dayjs from "dayjs";
import { getCommonTransition } from "../../utils/mui";
import { useTimeFormat } from "../../hooks/useTimeFormat";

const REPEATS_ASSETS = {
  [ComponentRepeat.NONE]: { title: "" },
  [ComponentRepeat.WEEKLY]: { title: "every week" },
  [ComponentRepeat.BIWEEKLY]: { title: "every 2 weeks" },
  [ComponentRepeat.EVERY_3_WEEKS]: { title: "every 3 weeks" },
  [ComponentRepeat.EVERY_4_WEEKS]: { title: "every 4 weeks" },
};

type ComponentScheduleReminderMode = "schedule" | "reminder";

interface IComponentScheduleReminderProps {
  component: CurriculumComponent;
  repeatsAmount: number;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

//
// <...Reminder /> naming could be a bit confusing,
// since `ComponentType.MESSAGE` introduce a bit different logic
const ComponentScheduleReminder = (props: IComponentScheduleReminderProps) => {
  const { component, repeatsAmount, setIsSidebarOpen } = props;

  const theme = useTheme();
  const timeFormat = useTimeFormat();
  const heightContext = useContext(ComponentBarHeightContext);
  const WEEK_ARR = useContext(ProgramWeeksContext);
  const DAYS = parseComponentDays(component.days).map(
    (day, index) => day && index + 1,
  );
  const OVERRIDES_AMOUNT = component?.overrides?.length ?? 0;

  const START_WEEK_NUMBER = WEEK_ARR.find(
    (w) => w.id === component.weekId,
  ).week;

  const END_WEEK_NUMBER = getScheduleEndWeek(
    component.weekId,
    component.duration,
    WEEK_ARR,
    component.repeat,
    true,
  );
  const REPEAT_NONE = component.repeat === ComponentRepeat.NONE;
  const IS_OVERRIDE = !!component.baseComponentId;
  const IS_MESSAGE = component.type === ComponentType.MESSAGE;

  const [selectedMode, setSelectedMode] =
    useState<ComponentScheduleReminderMode>();

  const openDrawer = (mode: ComponentScheduleReminderMode) => {
    setIsSidebarOpen(true);
    setSelectedMode(mode);
  };

  const closeDrawer = () => {
    setIsSidebarOpen(false);
    setSelectedMode(null);
  };

  const handleCardClick = (mode: ComponentScheduleReminderMode) => {
    const isCurrent = selectedMode === mode;

    if (isCurrent) {
      closeDrawer();
      return;
    }
    openDrawer(mode);
  };

  const getScheduleTitle = () => {
    const repeat = `Repeat ${REPEATS_ASSETS[component.repeat].title} `;
    const week = `Week ${START_WEEK_NUMBER} `;
    const weekRange = `• Weeks ${START_WEEK_NUMBER} ${REPEAT_NONE ? "" : "- " + END_WEEK_NUMBER} `;
    const tillTheEnd = `• Till program end `;
    const days = `• Days ${DAYS.filter(Boolean).join(", ")}`;

    if (REPEAT_NONE && countComponentDays(component.days) === 1)
      return week + days;

    if (!END_WEEK_NUMBER) return repeat + tillTheEnd + days;

    if (START_WEEK_NUMBER === END_WEEK_NUMBER) return week + days;

    return repeat + weekRange + days;
  };

  const getReminderTitle = () => {
    if (component?.reminderType)
      return `Each client will receive
        ${component.reminderType === ReminderType.AUTOMATIC ? "automatic" : "a"}
        reminder notification.`;
    return "Configure automatic email, SMS or push notifications.";
  };

  const getRepeatsAmount = () => {
    return repeatsAmount - OVERRIDES_AMOUNT;
  };

  const getComponentTypeTitle = () => {
    return component.type.toLowerCase() + (repeatsAmount > 1 ? "s" : "");
  };

  const TOP_HEIGHT = heightContext?.componentBarHeight;

  return (
    <>
      <Box
        display={"grid"}
        gridTemplateColumns={{ xs: "1fr", md: "1fr auto 1fr" }}
      >
        <Card
          title={"Schedule"}
          subTitle={getScheduleTitle()}
          mode="schedule"
          isSelected={selectedMode === "schedule"}
          handleCardClick={handleCardClick}
          highlightData1={
            (repeatsAmount > 1 && getRepeatsAmount().toString()) ||
            (IS_OVERRIDE && <FlagTriangleRight size={18} />)
          }
          highlightData2={
            OVERRIDES_AMOUNT > 0 && (
              <>
                {OVERRIDES_AMOUNT.toString()}
                <FlagTriangleRight size={12} strokeWidth={3} />
              </>
            )
          }
          highlightSubText={repeatsAmount > 1 && getComponentTypeTitle()}
        />
        <Divider
          orientation="vertical"
          sx={{ borderColor: selectedMode && theme.palette.primary.main }}
        />

        {IS_MESSAGE ? (
          <Card
            title={"Time"}
            subTitle={`The message will reach your client's message inbox at`}
            mode="reminder"
            isSelected={selectedMode === "reminder"}
            handleCardClick={handleCardClick}
            highlightData1={
              component?.messageTime &&
              dayjs(component.messageTime, "hh:mm").format(timeFormat)
            }
          />
        ) : (
          <Card
            title={"Reminder"}
            subTitle={getReminderTitle()}
            mode="reminder"
            isSelected={selectedMode === "reminder"}
            handleCardClick={handleCardClick}
            highlightData1={
              component?.reminderType === ReminderType.AUTOMATIC
                ? "Auto"
                : component?.reminderTime
                  ? dayjs(component.reminderTime, "hh:mm").format(timeFormat)
                  : null
            }
            highlightSubText={component?.reminderTime && "at"}
          />
        )}
      </Box>

      <Drawer
        open={!!selectedMode}
        onClose={closeDrawer}
        sx={{ zIndex: theme.zIndex.modal, width: "fit-content" }}
        anchor={"right"}
        elevation={0}
        hideBackdrop
        disableEnforceFocus
        PaperProps={{
          sx: {
            borderTop: 1,
            width: { xs: 1, sm: CURRICULUM_SIDEBAR_WIDTH },
            top: TOP_HEIGHT,
            borderLeft: 1,
            borderColor: theme.palette.divider,
            overflow: "visible",
          },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            left: { xs: 8, sm: -16 },
            top: { xs: 0, sm: 26 },
            background: theme.palette.common.white,
            p: 0.5,
          }}
          onClick={closeDrawer}
        >
          <ChevronsRight />
        </IconButton>

        {/* Content wrapper */}
        <Box
          sx={{
            width: CURRICULUM_SIDEBAR_WIDTH,
            marginInline: "auto",
            overflowY: "scroll",
            overflowX: "hidden",
            height: `calc(100% - ${TOP_HEIGHT}px)`,
          }}
        >
          {/* Schedule mode */}
          {selectedMode === "schedule" && (
            <ScheduleWithMap
              componentData={component}
              color={theme.palette.primary.main}
            />
          )}

          {/* Reminder mode */}
          {selectedMode === "reminder" && (
            <ComponentTimeSettings component={component} />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default ComponentScheduleReminder;

interface ICardProps {
  title: string;
  subTitle: string;
  mode: ComponentScheduleReminderMode;
  isSelected: boolean;
  handleCardClick: (mode: ComponentScheduleReminderMode) => void;
  highlightData1?: ReactNode;
  highlightData2?: ReactNode;
  highlightSubText?: string;
}
const Card = (props: ICardProps) => {
  const {
    title,
    subTitle,
    mode,
    isSelected,
    handleCardClick,
    highlightData1,
    highlightData2,
    highlightSubText,
  } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        paddingBlock: 3,
        paddingInline: 4,
        border: 1,
        borderRadius: { xs: 0, md: 2 },
        borderColor: theme.palette.divider,

        transition: getCommonTransition(theme, ["background", "border-color"]),

        ...(mode === "schedule" && {
          borderRightWidth: { xs: 1, md: 0 },
          borderBottomRightRadius: { xs: 0, md: 0 },

          borderTopRightRadius: { xs: 4, md: 0 },
          borderTopLeftRadius: { xs: 4 },
        }),

        ...(mode === "reminder" && {
          borderLeftWidth: { xs: 1, md: 0 },
          borderTopLeftRadius: { xs: 0, md: 0 },

          borderBottomLeftRadius: { xs: 4, md: 0 },
          borderBottomRightRadius: { xs: 4 },

          borderTopWidth: { xs: 0, md: 1 },
        }),

        ...(isSelected && {
          background: alpha(theme.palette.primary.main, 0.05),
          borderColor: alpha(theme.palette.primary.main, 1),
        }),

        ":hover": {
          cursor: "pointer",
          background: alpha(theme.palette.primary.main, 0.075),
        },
      }}
      onClick={() => handleCardClick(mode)}
    >
      <Box display={"flex"} justifyContent={"space-between"} gap={2} height={1}>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              color: theme.palette.text.secondary,
            }}
          >
            <Typography variant="h6">{title}</Typography>
            {mode === "reminder" && (
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                fontWeight={600}
              >
                {highlightSubText}
              </Typography>
            )}
            <Typography
              sx={{
                visibility: highlightData1 ? "visible" : "hidden",
                p: 0.5,
                paddingInline: 1.5,
                borderRadius: 2,
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {highlightData1}
            </Typography>
            {highlightData2 && <>+</>}
            <Typography
              sx={{
                display: highlightData2 ? "flex" : "none",
                alignItems: "center",
                p: 0.5,
                paddingLeft: 1,
                paddingRight: 0.5,
                borderRadius: 2,
                background: theme.palette.grey[400],
                color: theme.palette.primary.contrastText,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {highlightData2}
            </Typography>
            {mode === "schedule" && (
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                fontWeight={600}
              >
                {highlightSubText}
              </Typography>
            )}
          </Box>

          <Typography variant="subtitle2" color={theme.palette.text.secondary}>
            {subTitle}
          </Typography>
        </Box>

        <Box height={1} display={"flex"} alignItems={"center"}>
          <Box sx={{ aspectRatio: 1 }}>
            {isSelected ? <PanelLeftOpen /> : <PanelRightOpen />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
