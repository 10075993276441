import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import {
  setUnitTimeTypeOptions,
  setUnitsTypeOptions,
  ExerciseTypeExtra,
  ExerciseTypeSet,
  ExerciseTypeReps,
  getExerciseUnits,
} from "../../constants";
import { RadioGroupField } from "../fields/RadioGroupField";

const triangleHeight = 18;
const triangleWidth = 12;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: colorSystem.white,
    border: `solid 1.2px ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(4),
    position: "relative",
  },

  triangle: {
    position: "absolute",
    top: `-${triangleHeight}px`,
    right: "30px",
    width: "0",
    height: "0",
    borderLeft: `${triangleWidth}px solid transparent`,
    borderRight: `${triangleWidth}px solid transparent`,
    borderBottom: `${triangleHeight}px solid ${theme.palette.primary.main}`,
    "&::before": {
      content: '""',
      position: "absolute",
      top: "2px",
      left: `-${triangleWidth}px`,
      width: "0",
      height: "0",
      borderLeft: `${triangleWidth}px solid transparent`,
      borderRight: `${triangleWidth}px solid transparent`,
      borderBottom: `${triangleHeight}px solid ${colorSystem.white}`,
    },
  },

  label: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: 16,
  },

  settingsColumns: {
    display: "flex",
    gap: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      gap: 0,
      flexWrap: "wrap",
    },
  },

  radioButtonGroup: {
    marginInline: theme.spacing(-2),
  },

  typeControlLabel: {
    minWidth: 240,
  },

  controlLabel: {
    fontWeight: 500,
    borderWidth: 1,
    borderStyle: "unset",
    borderColor: colorSystem.secondaryGray,
    borderRadius: 2,

    padding: theme.spacing(0.5),
    margin: "unset",
    width: "100%",
  },

  selectedControlLabel: {
    borderColor: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}0A`,
  },
  settingsForm: {
    marginTop: theme.spacing(2),
  },

  split: {
    border: 0,
    borderTop: `1px solid ${theme.palette.border.primary}`,
    margin: theme.spacing(3, 0),
  },

  fieldGroup: {
    width: "100%",
    border: "1px solid lightgray",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  radioLabel: { width: "100%" },
}));

export interface WorkoutExerciseSettingsProps extends BoxProps {
  extraMeasurement: ExerciseTypeExtra;
  typeSet: ExerciseTypeSet;
  typeReps: ExerciseTypeReps;
  units: string;
  onChangeSettings: (field: string, value: string) => void;
}

export const measurementTypeOptions = [
  {
    label: "RPE",
    value: ExerciseTypeExtra.RATE_OF_PERCEIVED_EXERTION,
  },
  {
    label: "RIR",
    value: ExerciseTypeExtra.REPS_IN_RESERVE,
  },
];

const WorkoutExerciseSettings = (props: WorkoutExerciseSettingsProps) => {
  const { extraMeasurement, typeSet, typeReps, onChangeSettings, units } =
    props;
  const s = useStyles();
  const theme = useTheme();

  const typeSetOptions = [
    { label: "Distance", value: ExerciseTypeSet.DISTANCE },
    { label: "Time", value: ExerciseTypeSet.TIME },
    { label: "Weight", value: ExerciseTypeSet.WEIGHT },
  ];

  const repsTypeOptions = [
    { label: "Number", value: ExerciseTypeReps.WHOLE },
    { label: "Range", value: ExerciseTypeReps.RANGE },
  ];

  return (
    <Box className={s.root}>
      <div className={s.triangle}></div>
      <Typography variant="h6" className={s.label}>
        Set column settings
      </Typography>
      <Box className={s.settingsForm}>
        <Box className={s.settingsColumns}>
          <RadioGroupField
            value={typeReps}
            label="Reps type"
            options={repsTypeOptions}
            onChange={(event, value) => onChangeSettings("typeReps", value)}
            className={s.radioButtonGroup}
            fieldGroupClassName={s.fieldGroup}
            controlLabelStyle={s.controlLabel}
            controlLabelSelectedStyle={s.selectedControlLabel}
          />

          <RadioGroupField
            value={typeSet}
            label="Set type"
            options={typeSetOptions}
            optionElement={(option) => (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: 1.5,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  {option.label}
                </Typography>
                {typeSet === option.value && (
                  <TextField
                    color="primary"
                    size="small"
                    select
                    value={units}
                    onChange={(event) =>
                      onChangeSettings("units", event.target.value as string)
                    }
                    sx={{
                      textTransform: "lowercase",
                      ".MuiInputBase": {
                        fontWeight: 500,
                      },
                    }}
                    slotProps={{
                      input: {
                        sx: { minHeight: 40 },
                      },

                      select: {
                        MenuProps: {
                          sx: {
                            zIndex: 99999,
                          },
                        },
                      },
                    }}
                  >
                    {typeSet === ExerciseTypeSet.TIME
                      ? setUnitTimeTypeOptions.map((item) => (
                          <MenuItem
                            value={item.value}
                            sx={{ textTransform: "lowercase", fontWeight: 500 }}
                          >
                            {item.labelShortened}
                          </MenuItem>
                        ))
                      : setUnitsTypeOptions.map((item) => (
                          <MenuItem
                            value={item.value}
                            sx={{ textTransform: "lowercase", fontWeight: 500 }}
                          >
                            {getExerciseUnits(typeSet, item.value)}
                          </MenuItem>
                        ))}
                  </TextField>
                )}
              </Box>
            )}
            onChange={(_, value) => onChangeSettings("typeSet", value)}
            className={s.radioButtonGroup}
            fieldGroupClassName={s.fieldGroup}
            controlLabelStyle={clsx(s.controlLabel, s.typeControlLabel)}
            controlLabelSelectedStyle={s.selectedControlLabel}
            radioLabelStyle={s.radioLabel}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h6" className={s.label}>
            + extra columns
          </Typography>

          <ToggleButtonGroup
            value={extraMeasurement}
            color="primary"
            exclusive
            onChange={(_, value) =>
              onChangeSettings(
                "typeExtraMeasurement",
                value ?? ExerciseTypeExtra.NONE,
              )
            }
          >
            {measurementTypeOptions.map((item, index) => (
              <ToggleButton
                key={index}
                value={item.value}
                sx={{
                  fontWeight: 700,
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.action.hover,
                  },
                  "&.Mui-selected": {
                    backgroundColor: (theme) => theme.palette.action.selected,

                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.action.selected,
                    },
                  },
                }}
              >
                {item.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutExerciseSettings;
