import React from "react";
import { alpha, Box, Theme, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Plan } from "../../constants";

interface StyleProps {
  position: number;
  planPosition: number;
  hasExceededPlan: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  sliderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "left",
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(10),
      paddingLeft: theme.spacing(2),
    },
  },

  boxWrapper: {
    position: "relative",
    width: "90%",
  },

  activeClientsBox: {
    position: "absolute",
    transform: `translate(-50%, calc(-100% - ${theme.spacing(1.5)}))`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.radius.md,
    padding: theme.spacing(1, 1.5),
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    border: `${theme.spacing(0.25)} solid ${theme.palette.primary.main}`,
  },

  arrowDown: {
    position: "absolute",
    bottom: theme.spacing(-1.25),
    left: "50%",
    transform: "translateX(-50%)",
    width: 0,
    height: 0,
    borderLeft: `${theme.spacing(1)} solid transparent`,
    borderRight: `${theme.spacing(1)} solid transparent`,
    borderTop: `${theme.spacing(1.25)} solid ${theme.palette.primary.main}`,
    backgroundColor: "transparent",
  },

  trackWrapper: {
    position: "relative",
    width: "100%",
    height: theme.spacing(2.5),
    display: "flex",
    alignItems: "center",
  },

  track: (props) => {
    const { position, planPosition, hasExceededPlan } = props;
    const primary = theme.palette.primary.main;
    const textSecondary = theme.palette.text.secondary;
    if (hasExceededPlan) {
      return {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "100%",
        height: 2,
        borderRadius: theme.shape.borderRadius,
        background: alpha(primary, 1),
      };
    }

    return {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: "100%",
      height: 2,
      borderRadius: theme.shape.borderRadius,
      background: `linear-gradient(
        to right,
        ${alpha(primary, 1)} 0%,
        ${alpha(primary, 1)} ${position}%,
        ${alpha(primary, 0.5)} ${position}%,
        ${alpha(primary, 0.5)} ${planPosition}%,
        ${alpha(textSecondary, 0.5)} ${planPosition}%,
        ${alpha(textSecondary, 0.5)} 100%
      )`,
    };
  },

  mark: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    borderRadius: "50%",
    border: `${theme.spacing(0.25)} solid ${alpha(theme.palette.text.secondary, 0.5)}`,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  markLabel: {
    position: "absolute",
    top: theme.spacing(3),
    marginTop: theme.spacing(0.5),
    textAlign: "center",
    transform: "translateX(-50%)",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
  },

  checkIconWrapper: {
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    borderRadius: "50%",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },

  label: {
    fontSize: 16,
    textAlign: "center",
    lineHeight: 1,
  },

  subtitle: {
    fontSize: 14,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const fixedLimitedMarks = [
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 300, label: "unlimited" },
];

const fixedUnlimitedMarks = [
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 200, label: "200" },
  { value: 10000000, label: "unlimited" },
];

const getMaxClientsForPlanSlidebar = (plan: Plan): number => {
  switch (plan) {
    case "UP_TO_50":
    case "YEARLY_UP_TO_50":
      return 50;
    case "UP_TO_100":
    case "YEARLY_UP_TO_100":
      return 100;
    case "UP_TO_200":
    case "YEARLY_UP_TO_200":
      return 200;
    case "UP_TO_UNLIMITED":
    case "YEARLY_UNLIMITED":
      return 10000000;
    default:
      return 0;
  }
};

const MIN_FILL_PERCENT = 1;
function normalizePosition(baseValue: number) {
  return baseValue * ((100 - MIN_FILL_PERCENT) / 100) + MIN_FILL_PERCENT;
}

function getBasePositionLimited(value: number, planLimit: number): number {
  const breakpoints = [0, 50, 100, 200, 300];
  const positions = [0, 18, 40, 68, 100];
  if (value <= 0) return 0;
  if (value > planLimit) value = planLimit;
  for (let i = 0; i < breakpoints.length - 1; i++) {
    if (value >= breakpoints[i] && value <= breakpoints[i + 1]) {
      const ratio =
        (value - breakpoints[i]) / (breakpoints[i + 1] - breakpoints[i]);
      return positions[i] + ratio * (positions[i + 1] - positions[i]);
    }
  }
  return positions[positions.length - 1];
}

function getBasePositionUnlimited(value: number): number {
  const breakpoints = [0, 50, 100, 200, 300, 500, 10000000];
  const positions = [0, 18, 40, 68, 80, 90, 100];
  if (value <= 0) return 0;
  for (let i = 0; i < breakpoints.length - 1; i++) {
    if (value >= breakpoints[i] && value <= breakpoints[i + 1]) {
      const ratio =
        (value - breakpoints[i]) / (breakpoints[i + 1] - breakpoints[i]);
      return positions[i] + ratio * (positions[i + 1] - positions[i]);
    }
  }
  return 100;
}

function getPosition(
  value: number,
  isUnlimited: boolean,
  planLimit?: number,
): number {
  const basePos = isUnlimited
    ? getBasePositionUnlimited(value)
    : getBasePositionLimited(value, planLimit!);
  return normalizePosition(basePos);
}

function getMarkPositionLimited(value: number): number {
  const breakpoints = [0, 50, 100, 200, 300];
  const positions = [0, 18, 40, 68, 100];
  if (value <= 0) return 0;
  for (let i = 0; i < breakpoints.length - 1; i++) {
    if (value >= breakpoints[i] && value <= breakpoints[i + 1]) {
      const ratio =
        (value - breakpoints[i]) / (breakpoints[i + 1] - breakpoints[i]);
      return positions[i] + ratio * (positions[i + 1] - positions[i]);
    }
  }
  return 100;
}

function getMarkPositionUnlimited(value: number): number {
  const breakpoints = [0, 50, 100, 200, 300, 500, 10000000];
  const positions = [0, 18, 40, 68, 80, 90, 100];
  if (value <= 0) return 0;
  for (let i = 0; i < breakpoints.length - 1; i++) {
    if (value >= breakpoints[i] && value <= breakpoints[i + 1]) {
      const ratio =
        (value - breakpoints[i]) / (breakpoints[i + 1] - breakpoints[i]);
      return positions[i] + ratio * (positions[i + 1] - positions[i]);
    }
  }
  return 100;
}

function clampTo100(v: number) {
  return v > 100 ? 100 : v;
}

export interface ActiveClientsSliderProps {
  activeClients: number;
  currentPlan: Plan;
}

export const ActiveClientsSlider: React.FC<ActiveClientsSliderProps> = ({
  activeClients,
  currentPlan,
  ...other
}) => {
  const theme = useTheme();
  const isUnlimited =
    currentPlan === Plan.UP_TO_UNLIMITED ||
    currentPlan === Plan.YEARLY_UNLIMITED;
  const planLimit = getMaxClientsForPlanSlidebar(currentPlan);

  const fillPosition = clampTo100(
    getPosition(
      activeClients,
      isUnlimited,
      isUnlimited ? undefined : planLimit,
    ),
  );
  const planPosition = clampTo100(
    getPosition(planLimit, isUnlimited, isUnlimited ? undefined : planLimit),
  );
  const hasExceededPlan = activeClients > planLimit;
  const bubbleLeft = fillPosition > 100 ? 100 : fillPosition;

  const marks = isUnlimited ? fixedUnlimitedMarks : fixedLimitedMarks;
  const indexForPlan = marks.findIndex((m) => m.value === planLimit);

  const s = useStyles({
    position: fillPosition,
    planPosition,
    hasExceededPlan,
  });

  return (
    <Box className={s.sliderContainer} {...other}>
      <Box className={s.boxWrapper}>
        <Box className={s.activeClientsBox} style={{ left: `${bubbleLeft}%` }}>
          <Typography className={s.label}>
            <b>{activeClients.toLocaleString("en-US")}</b>
            <br />
            <span className={s.subtitle}>active</span>
          </Typography>
          <Box className={s.arrowDown} />
        </Box>

        <Box className={s.trackWrapper}>
          <Box className={s.track} />

          {marks.map((mark, index) => {
            const leftPercent = clampTo100(
              normalizePosition(
                isUnlimited
                  ? getMarkPositionUnlimited(mark.value)
                  : getMarkPositionLimited(mark.value),
              ),
            );
            const isCurrentPlan = index === indexForPlan;
            const isMarkActive = activeClients >= mark.value;
            const isMarkAfterCurrentPlan = index > indexForPlan;

            return (
              <React.Fragment key={mark.value}>
                {isCurrentPlan ? (
                  <Box
                    className={s.checkIconWrapper}
                    style={{ left: `${leftPercent}%` }}
                  >
                    <CheckCircleIcon className={s.checkIcon} />
                  </Box>
                ) : (
                  <Box
                    className={s.mark}
                    style={{
                      left: `${leftPercent}%`,
                      borderColor: isMarkActive
                        ? theme.palette.primary.main
                        : isMarkAfterCurrentPlan
                          ? alpha(theme.palette.text.secondary, 0.5)
                          : alpha(theme.palette.primary.main, 0.5),
                    }}
                  />
                )}

                <Typography
                  className={s.markLabel}
                  style={{
                    left: `${leftPercent}%`,
                    fontWeight: isCurrentPlan ? 600 : 500,
                  }}
                >
                  {isCurrentPlan ? <b>{mark.label}</b> : <>{mark.label}</>}
                  <br />
                  <span
                    className={s.subtitle}
                    style={{ fontWeight: isCurrentPlan ? 600 : 500 }}
                  >
                    clients
                  </span>
                </Typography>
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
