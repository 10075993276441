import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { ComponentType } from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";

export const updateComponentIconAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    iconName: string;
  }>,
) => {
  const { componentId, iconName } = action.payload;
  if (!componentId || !iconName) return;

  const component = state.value.components[componentId];
  if (!component) return;

  state.value.components[componentId] = {
    ...component,
    iconName: iconName,
    updatedAt: iconName === component.iconName ? component.updatedAt : dayjs(),
  };
};

export const updateComponentDefaultIconAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentType: ComponentType;
    iconName: string;
  }>,
) => {
  const { componentType, iconName } = action.payload;
  if (!componentType || !iconName) return;

  state.value.components = Object.fromEntries(
    Object.entries(state.value.components).map(([id, component]) => [
      id,
      component.type === componentType
        ? {
            ...component,
            iconName,
            updatedAt:
              component.iconName === iconName ? component.updatedAt : dayjs(),
          }
        : component,
    ]),
  );
};
