import React from "react";
import { ProgressDialog } from "../../../../components/client-progress/ProgressDialog";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";

function ClientProgressRoute() {
  const navigate = useNavigate();
  const user = useCurrentUser();
  return (
    <ProgressDialog clientId={user.id} open onClose={() => navigate(-1)} />
  );
}

export default ClientProgressRoute;
