import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { colorSystem } from "../../../theme";
import { FileRejection, useDropzone, ErrorCode } from "react-dropzone";
import { getCustomErrorMessage } from "../other/reactDropzoneUtil";
import FileRejectionHelperText from "./FileRejectionHelperText";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Icons } from "../../plate-ui/Icons/icons";
import { useUploadFile } from "../../../hooks/useUploadFile";
import { AssetType } from "../../../constants";
import { useEditorProgram } from "../hooks";

export interface FilePreviewBoxProps {
  file: FileInfo;
  onFileDelete?: () => void;
  disabled?: boolean;
}

type PreviewBoxComponent = React.ComponentType<FilePreviewBoxProps>;

const DefaultPreviewBox: PreviewBoxComponent = ({
  file,
  onFileDelete,
  disabled,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        mt: 1,
      }}
    >
      {file.type.includes("image") && (
        <Box>
          <img
            src={file.url}
            alt={file.name}
            style={{
              maxWidth: 150,
              maxHeight: 150,
              objectFit: "contain",
            }}
          />
        </Box>
      )}
      <Typography
        variant="body2"
        sx={{
          textAlign: "center",
        }}
      >
        {file.name}
      </Typography>
      {onFileDelete && (
        <IconButton
          disabled={disabled}
          onClick={() => onFileDelete()}
          size="medium"
          sx={{ color: (theme) => theme.palette.text.disabled }}
        >
          <Icons.close />
        </IconButton>
      )}
    </Box>
  );
};

interface IPhotoUploaderProps {
  file?: FileInfo;
  disabled?: boolean;
  onlyImages?: boolean;
  readonly?: boolean;
  onFileUpload?: (file: FileInfo) => void;
  onFileDelete?: () => void;
  previewBox?: PreviewBoxComponent;
}

export type FileInfo = {
  name: string;
  url: string;
  type: string;
  size: number;
};

const MAX_FILES = 1;
const MAX_FILE_SIZE = 20000000; // 20 mb
const MIN_FILE_SIZE = 0;
const IMAGE_FILE_TYPES = {
  "image/png": [".png"],
  "image/jpg": [".jpeg", ".jpg"],
  "image/gif": [".gif"],
};

const FileUploader = ({
  file,
  disabled,
  onlyImages = false,
  readonly = false,
  onFileUpload,
  onFileDelete,
  previewBox: FileView = DefaultPreviewBox,
}: IPhotoUploaderProps) => {
  const theme = useTheme();

  const user = useCurrentUser();

  const editorProgram = useEditorProgram();
  const programId = editorProgram?.programId;

  const [uploaderErrors, setUploaderErrors] = useState<FileRejection[]>();

  const [progress, setProgress] = useState<number>(0);
  const [uploadFile, , isUploading] = useUploadFile({
    id: programId || user.id,
    refType: programId ? "Program" : "User",
    getAssetType: () => AssetType.PROGRAM_FILE,
    onProgress: (progress) => {
      setProgress(Math.round((progress.loaded / progress.total) * 100));
    },
  });

  const preventUpload = disabled || isUploading || !!file;

  const { getRootProps, getInputProps } = useDropzone({
    accept: onlyImages ? IMAGE_FILE_TYPES : undefined,
    maxFiles: MAX_FILES,
    maxSize: MAX_FILE_SIZE,
    multiple: false,
    noClick: preventUpload ? true : false,
    noKeyboard: preventUpload ? true : false,
    disabled: preventUpload,
    onDropAccepted: (acceptedFiles) => {
      if (!acceptedFiles[0] && MAX_FILES === 1) return;

      // Since MAX_FILES = 1;
      const imageFile = acceptedFiles[0];
      const reader = new FileReader();
      setProgress(0);

      reader.onloadend = () => {
        uploadFile(imageFile).then(({ url }) => {
          onFileUpload &&
            onFileUpload({
              url,
              name: imageFile.name,
              size: imageFile.size,
              type: imageFile.type,
            });
        });
      };

      reader.readAsDataURL(imageFile);

      setUploaderErrors(undefined);
    },
    onDropRejected: (fileRejections) => {
      setUploaderErrors(fileRejections);
    },
  });
  const getErrorMessage = (code: ErrorCode | string) => {
    return getCustomErrorMessage(
      code,
      IMAGE_FILE_TYPES,
      MAX_FILE_SIZE / 1000000, // to mb
      MIN_FILE_SIZE,
      MAX_FILES,
    );
  };
  return (
    <Box
      sx={{
        paddingTop: 2,
        height: "100%",
        minWidth: 200,
      }}
    >
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "100%",
          height: "100%",
          gap: 1,
          border: "2px solid",
          borderStyle: "dashed",
          borderColor: theme.palette.divider,
          background: colorSystem.white2,
          borderRadius: 2,
          padding: 6,

          ":hover": !preventUpload && {
            cursor: "pointer",
            borderColor: theme.palette.primary.main,
          },

          ...(disabled && {
            filter: (theme) => theme.filters.disabled,
          }),
        }}
      >
        <input {...getInputProps()} />

        {isUploading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 1,
              height: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                mb: 1,
              }}
            >
              Uploading...
            </Typography>
            <LinearProgress
              variant={"determinate"}
              value={progress}
              sx={{
                color: theme.palette.primary.main,
                width: "25%",
                borderRadius: 2,
              }}
            />
          </Box>
        ) : file ? (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <FileView
              file={file}
              onFileDelete={!readonly ? onFileDelete : undefined}
              disabled={disabled}
            />
          </Box>
        ) : readonly ? (
          <Box>
            <Typography
              color={"inherit"}
              sx={{
                textAlign: "center",
              }}
            >
              No file uploaded
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                borderRadius: "100%",
                p: 2,
                color: theme.palette.common.white,
                background: theme.palette.primary.main,
              }}
            >
              {onlyImages ? (
                <AddPhotoAlternateIcon color="inherit" fontSize="large" />
              ) : (
                <UploadFileIcon color="inherit" fontSize="large" />
              )}
            </Box>
            <Typography
              color={"inherit"}
              sx={{
                textAlign: "center",
              }}
            >
              Drag and drop here <br /> or
            </Typography>

            <Button
              disabled={disabled}
              variant="outlined"
              endIcon={<FileUploadIcon />}
            >
              Upload
            </Button>
          </>
        )}
      </Box>
      <FileRejectionHelperText
        uploaderErrors={uploaderErrors}
        getErrorMessage={getErrorMessage}
      />
    </Box>
  );
};

export default FileUploader;
