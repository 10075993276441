import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Button, Typography } from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import AdminService from "../../services/AdminService";
import { SortableList } from "../sortable-list/SortableList";
import { TemplateListItem } from "./TemplateListItem";
import { useChangeProgramTemplatesOrdinalMutation } from "./hooks/useReorderProgramTemplates";
import { LoadingPage } from "../loading/LoadingPage";
import ProgramTemplatesService from "../../services/ProgramTemplatesService";

const useStyles = makeStyles((theme) => ({
  root: {},

  tabs: {
    "& span": {
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "uppercase",
    },
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.selected.main,
  },

  container: {
    margin: theme.spacing(3, 2),
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 6),
    },
  },

  sortableList: {
    borderRadius: theme.spacing(1.5),
    padding: 1,
    marginBottom: theme.spacing(3),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },

  createButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: theme.spacing(2),
  },

  createButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export interface ProgramTemplatesPage {}

export const ADMIN_TEMPLATES_KEY_QUERY_KEY = "admin-templates";

export function ProgramTemplatesPage() {
  const s = useStyles();

  const { data: programTemplates, isPending } = useQuery({
    queryKey: [ADMIN_TEMPLATES_KEY_QUERY_KEY],
    queryFn: () => ProgramTemplatesService.getProgramTemplates(),
  });

  const { mutate: changeOrdinal } = useChangeProgramTemplatesOrdinalMutation();

  const [updatedOrder, setUpdatedOrder] = React.useState<{
    [key: string]: number;
  }>(null);

  const handleListUpdate = React.useCallback(
    (newOrder) => {
      const programTemplateDictionary = newOrder.reduce(
        (acc, programTemplate, index) => {
          acc[String(programTemplate.id)] = index + 1;
          return acc;
        },
        {} as { [key: string]: number },
      );
      setUpdatedOrder(programTemplateDictionary);
    },
    [programTemplates],
  );

  const handleButtonClick = () => {
    changeOrdinal(
      { idsToOrdinals: updatedOrder },
      { onSuccess: () => setUpdatedOrder(null) },
    );
  };

  return (
    <Paper className={s.root}>
      <Tabs
        className={s.tabs}
        indicatorColor="primary"
        textColor="primary"
        value={"Programs"}
      >
        <Tab label="Programs" value={"Programs"} />
      </Tabs>
      <Box className={s.createButtonContainer}>
        <Button
          className={s.createButton}
          variant="contained"
          disabled={!updatedOrder}
          onClick={handleButtonClick}
        >
          Public changes
        </Button>{" "}
      </Box>
      <Box className={s.container}>
        {isPending ? (
          <LoadingPage />
        ) : programTemplates?.programTemplates &&
          programTemplates.programTemplates.length > 0 ? (
          <SortableList
            className={s.sortableList}
            itemType="admin_template"
            ListItem={TemplateListItem as any}
            ListItemProps={{ divider: false }}
            onUpdate={handleListUpdate}
            options={programTemplates?.programTemplates}
          />
        ) : (
          <Typography variant="h6">No program templates found</Typography>
        )}
      </Box>
    </Paper>
  );
}
