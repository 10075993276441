import clsx from "clsx";
import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useInputChange } from "../../hooks/useInputChange";
import { ActionButton } from "../button/ActionButton";
import { partiallyCompare } from "../../utils/misc";
import { SettingsCard, SettingsCardProps } from "../card/SettingsCard";
import {
  GROUP_NAME_MAX_LENGTH,
  useUpdateGroupMutation,
} from "../groups/mutations/UpdateGroup";
import { InviteLinkSettings } from "../coach-clients/InviteLinkSettings";
import { AssetType } from "../../constants";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";
import { InviteCodePreviewDialog } from "../dialog/InviteCodePreviewDialog";

import { GroupArchiveDialog } from "./GroupArchiveDialog";
import { SettingsCoverImage } from "./SettingsCoverImage";
import {
  GroupDto2,
  GroupSettingsDto,
  InviteCodeDto,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GroupsService from "../../services/GroupsService";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import InviteCodesService from "../../services/InviteCodesService";

const useStyles = makeStyles((theme) => ({
  root: {},

  fieldsGroup: {
    "&:not(:nth-child(1))": {
      marginTop: theme.spacing(5),
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  name: {
    width: "50%",
  },

  settingsList: {
    margin: theme.spacing(-2, 0, 3),
  },

  settingItem: {
    paddingLeft: 0,
  },

  primaryText: {
    fontSize: 18,
    fontWeight: 600,
  },

  secondaryText: {
    fontSize: 14,
    fontWeight: 500,
  },

  generateInviteLink: {
    width: "100%",
  },

  copyInviteLink: {
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(-1),
    fontSize: 16,
    fontWeight: 500,
  },

  mutedText: {
    color: theme.palette.text.secondary,
  },

  image: {
    marginTop: theme.spacing(2),
  },

  previewInviteLink: {
    fontSize: 16,
    fontWeight: 500,
    marginRight: theme.spacing(-0.5),
  },
}));

const groupSettings = [
  {
    name: "membersCanNotPost",
    primary: "Prevent members from posting",
    secondary: "If enabled, members won’t be able to post in this group",
  },

  {
    name: "membersCanNotDiscover",
    primary: "Hide group from members",
    secondary: "If hidden, link will not appear in client’s portal",
  },

  {
    name: "membersCanNotSeeSidebar",
    primary: "Hide sidebar",
    secondary:
      "Members will not see group members and description in right sidebar",
  },

  {
    name: "membersCanMessage",
    primary: "Group chat",
    secondary: "Allow members to view and send group messages.",
  },
] as const;

export interface GroupSettingsCardProps
  extends Omit<SettingsCardProps, "header"> {
  group: GroupDto2;
}

const GROUP_SETTINGS_INVITE_CODE_QUERY_KEY = "group-invite-code";

export function GroupSettingsCard(props: GroupSettingsCardProps) {
  const { className, group, ...other } = props;
  const s = useStyles();
  const queryClient = useQueryClient();

  const { data: inviteCode, isLoading: inviteCodeLoading } = useQuery({
    queryKey: [GROUP_SETTINGS_INVITE_CODE_QUERY_KEY, { groupId: group.id }],
    queryFn: () => GroupsService.getGroupSettings(group.id),
    select: (data) => data.inviteCode,
  });

  const { updateSettingsMutation } = useUpdateGroupMutation({
    disableToastAlerts: true,
    currentSlug: group.slug,
  });
  const {
    mutate: updateGroup,
    isPending: updateGroupInFlight,
    status: updateStatus,
  } = updateSettingsMutation;

  const { mutate: generateCode, isPending: creatingCode } = useMutation({
    mutationFn: InviteCodesService.createCode,
    onSuccess: (data) => {
      queryClient.setQueriesData(
        {
          queryKey: [
            GROUP_SETTINGS_INVITE_CODE_QUERY_KEY,
            { groupId: group.id },
          ],
        },
        (oldData: GroupSettingsDto) =>
          GroupSettingsDto.fromJS({
            ...oldData,
            inviteCode: InviteCodeDto.fromJS(data),
          }),
      );
    },
  });

  const { mutate: deleteCode, isPending: deletingCode } =
    useOptimisticUpdateMutation({
      queryKey: [GROUP_SETTINGS_INVITE_CODE_QUERY_KEY, { groupId: group.id }],
      mutationFn: InviteCodesService.deleteCode,
      optimisticUpdater: {
        updateFn: (oldData: GroupSettingsDto) =>
          GroupSettingsDto.fromJS({
            ...oldData,
            inviteCode: undefined,
          }),
      },
      disableToastAlerts: true,
    });

  const [name, setName] = React.useState(group.name);
  const [error, setError] = useState<string>("");
  const [settings, setSettings] = React.useState<Record<string, boolean>>({
    membersCanNotDiscover: group.membersCanNotDiscover,
    membersCanNotPost: group.membersCanNotPost,
    membersCanNotSeeSidebar: group.membersCanNotSeeSidebar,
    membersCanMessage: group.membersCanMessage,
  });
  const [imageURL, setImageURL] = React.useState(group.image);
  const [openArchiveDialog, setOpenArchiveDialog] = React.useState(false);
  const [previewInviteCode, setPreviewInviteCode] = React.useState(false);

  const handleArchiveDialogOpen = React.useCallback(() => {
    setOpenArchiveDialog(true);
  }, []);

  const handleArchiveDialogClose = React.useCallback(() => {
    setOpenArchiveDialog(false);
  }, []);

  const handlePreviewInviteCodeOpen = React.useCallback(
    () => setPreviewInviteCode(true),
    [],
  );

  const handlePreviewInviteCodeClose = React.useCallback(
    () => setPreviewInviteCode(false),
    [],
  );

  const handleSettingChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSettings({
        ...settings,
        [event.currentTarget.name]: checked,
      });
    },
    [settings],
  );

  const handleNameChange = (value: string) => {
    if (value.length > GROUP_NAME_MAX_LENGTH) {
      setError("Max length of group name is 200 characters");
    } else {
      setError("");
    }
    setName(value);
  };

  const handleSave = React.useCallback(
    () =>
      updateGroup({
        ...group,
        id: group.id,
        name,
        image: imageURL,
        ...settings,
      }),
    [group.id, imageURL, name, settings, updateGroup],
  );

  const dirty = !partiallyCompare(
    { name, image: imageURL, ...settings },
    group,
  );
  return (
    <SettingsCard
      className={clsx(s.root, className)}
      header="Group Settings"
      ButtonProps={{
        onClick: handleSave,
        disabled: !dirty || updateGroupInFlight || !!error,
        status: updateStatus,
      }}
      {...other}
    >
      <DiscardChangesDialog dirty={dirty} />
      <Box className={s.fieldsGroup}>
        <Typography variant="h4" className={s.label}>
          Group name
        </Typography>
        <TextField
          className={s.name}
          variant="outlined"
          value={name}
          onChange={useInputChange(handleNameChange)}
          helperText={error}
          error={!!error}
        />
      </Box>
      <Box className={s.fieldsGroup}>
        <Typography variant="h4" className={s.label}>
          General settings
        </Typography>

        <List className={s.settingsList}>
          {groupSettings.map(({ name, ...props }) => (
            <ListItem key={name} className={s.settingItem} divider>
              <ListItemText
                classes={{
                  primary: s.primaryText,
                  secondary: s.secondaryText,
                }}
                {...props}
              />
              <ListItemSecondaryAction>
                <Switch
                  name={name}
                  checked={settings[name]}
                  onChange={handleSettingChange}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
      <SettingsCoverImage
        className={s.fieldsGroup}
        nodeId={group.id}
        assetType={AssetType.GROUP_IMAGE}
        image={imageURL}
        refType="Group"
        onImageChange={setImageURL}
      >
        Give your group identity with a cover photo. Similar to a book cover,
        this photo will appear at the top of your group page. Upload your own,
        or choose a beautiful royalty free image from Unsplash.
      </SettingsCoverImage>
      <Box className={s.fieldsGroup}>
        <Typography variant="h4" className={s.label}>
          Invite settings
        </Typography>

        {inviteCodeLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
            }}
          >
            <Box
              sx={{
                width: "40%",
              }}
            >
              <Skeleton width="100%" height={20} />
              <Skeleton width="100%" height={15} />
            </Box>
            <Skeleton width="30%" height={30} />
          </Box>
        ) : (
          <List className={s.settingsList}>
            <ListItem
              className={s.settingItem}
              divider={!inviteCodeLoading && Boolean(inviteCode)}
            >
              <ListItemText
                classes={{
                  primary: s.primaryText,
                  secondary: s.secondaryText,
                }}
                primary="Invite link"
                secondary="Allow clients to join this group via an invite link."
              />

              <ListItemSecondaryAction>
                <InviteLinkSettings
                  generateCode={() => generateCode({ groupId: group.id })}
                  deleteCode={() => deleteCode(inviteCode.id)}
                  generatingCode={creatingCode}
                  deletingCode={deletingCode}
                  groupId={group.id}
                  inviteCode={inviteCode}
                  textLink
                  GenerateButtonProps={{
                    className: s.generateInviteLink,
                  }}
                  CopyButtonProps={{
                    className: s.copyInviteLink,
                    variant: "text",
                    color: "primary",
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>

            {inviteCode && (
              <ListItem className={s.settingItem}>
                <ListItemText
                  classes={{
                    primary: s.primaryText,
                  }}
                  primary="Invite code"
                />

                <ListItemSecondaryAction>
                  <Button
                    className={s.previewInviteLink}
                    variant="text"
                    color="primary"
                    onClick={handlePreviewInviteCodeOpen}
                  >
                    Share via invite code
                  </Button>
                </ListItemSecondaryAction>

                {previewInviteCode && (
                  <InviteCodePreviewDialog
                    open
                    inviteCode={inviteCode}
                    onClose={handlePreviewInviteCodeClose}
                  />
                )}
              </ListItem>
            )}
          </List>
        )}
      </Box>
      <Box className={s.fieldsGroup}>
        <ActionButton
          color="primary"
          size="large"
          onClick={handleArchiveDialogOpen}
        >
          Archive group
        </ActionButton>
        {openArchiveDialog && (
          <GroupArchiveDialog
            groupId={group.id}
            open={openArchiveDialog}
            onClose={handleArchiveDialogClose}
          />
        )}
      </Box>
    </SettingsCard>
  );
}
