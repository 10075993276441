import { Box, Divider, Typography, useTheme } from "@mui/material";
import React, { ReactNode } from "react";

interface ISectionTitleProps {
  text: string;
  divider?: boolean;
  action?: ReactNode;
}

const SectionTitle = (props: ISectionTitleProps) => {
  const { text, divider = true, action } = props;
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {divider && <Divider sx={{ marginBlock: 1, width: 1 }} />}
      <Typography
        fontWeight={"bold"}
        sx={{
          display: "flex",
          gap: 1,
          pl: 3,
          pr: 1,
          alignItems: "center",
          height: theme.spacing(4),
          textDecoration: "underline",
        }}
      >
        {text}
      </Typography>
      {action && action}
    </Box>
  );
};

export default SectionTitle;
