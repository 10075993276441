import clsx from "clsx";
import React from "react";
import {
  TextField,
  DialogProps,
  FormControl,
  FormLabel,
  Box,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
  SelectProps,
  ListItemIcon,
  FormHelperText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";

import { useAnalytics } from "../../hooks/useAnalytics";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import {
  ProgramDetailsViewMode,
  defaultCalendarViewOptions,
} from "../program/ProgramDetailsViewButton";
import { maybePluralize } from "../../utils/text";
import { SettingsCoverImage } from "../group-settings/SettingsCoverImage";
import { AssetType, getRandomProgramGalleryImage } from "../../constants";
import { ActionButton } from "../button/ActionButton";
import { useCurrentUserId } from "../../hooks/useCurrentUser";

import { useNavigate } from "react-router-dom";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../routes/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CoachProgramsService from "../../services/CoachProgramsService";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../coach-programs/CoachProgramsListScreen";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { ProgramDefaultView } from "@growth-machine-llc/stridist-api-client";
import { PROGRAM_TEMPLATES_QUERY_KEY } from "../screen/ProgramTemplatesScreen";
import { FieldsGroup } from "../fields/FieldsGroup";
import { FieldError } from "../fields/FieldError";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(84),
    },
  },

  paper: {
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(84),
    },
  },

  control: {
    marginBottom: theme.spacing(4),
    width: "100%",
  },

  label: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
    color: `${theme.palette.text.secondary} !important`,
  },

  fieldsRow: {
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: 0,
    },
  },

  select: {
    "& > div": {
      padding: 0,
    },
  },

  option: {
    padding: theme.spacing(0, 2),
  },

  optionIcon: {
    color: theme.palette.text.primary,
    minWidth: theme.spacing(3),
    paddingRight: theme.spacing(1.5),
  },

  primaryText: {
    fontSize: 16,
    fontWeight: 500,
  },

  coverImage: {
    "& h4": {
      marginBottom: theme.spacing(1),
    },
  },

  submit: {
    marginTop: theme.spacing(3),
  },

  info: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  error: {
    position: "absolute",
  },
  header: {
    marginBottom: 0,
  },

  fieldGroupLabel: {
    marginBottom: theme.spacing(1),
  },

  settingCoverImageContainer: {
    alignItems: "flex-start",
  },
  uploadImageButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    border: `2px solid ${theme.palette.text.secondary}`,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export const defaultProgramUnsplashQuery = "fitness";

export interface CreateProgramDialogProps extends DialogProps {}

export function CreateProgramDialog(props: CreateProgramDialogProps) {
  const { className, ...other } = props;
  const queryClient = useQueryClient();
  const { mutate: createProgram, isPending: inFlight } = useMutation({
    mutationKey: ["create-program"],
    mutationFn: CoachProgramsService.createProgram,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
      });

      queryClient.invalidateQueries({
        queryKey: [PROGRAM_TEMPLATES_QUERY_KEY],
      });
    },
  });

  const { showToastAlert } = useToastAlert();
  const navigate = useNavigate();
  const userId = useCurrentUserId();
  const s = useStyles();
  const [trackEvent] = useAnalytics();

  const [errors, setErrors] = React.useState({ name: "", description: "" });
  const onError = useGenericErrorHandler({
    disableDefaultSnackbar: false,
    setFieldError: (field, message) => {
      setErrors((errors) => ({ ...errors, [field]: message }));
    },
  });

  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [programLength, setProgramLength] = React.useState(4);
  const [imageURL, setImageURL] = React.useState(
    getRandomProgramGalleryImage(),
  );
  const [defaultView, setDefaultView] = React.useState(
    ProgramDetailsViewMode.CALENDAR,
  );

  //TODO_PROGRAM_TEMPLATES: Rewrite with rhf
  const handleSubmit = React.useCallback(() => {
    const validationErrors = { name: "", description: "" };
    let hasError = false;

    if (name.trim().length < 4) {
      validationErrors.name = "Name must be at least 4 characters long";
      hasError = true;
    }

    if (!description.trim()) {
      validationErrors.description = "Description cannot be empty";
      hasError = true;
    }

    if (hasError) {
      setErrors(validationErrors);
      return;
    }

    setErrors({ name: "", description: "" });
    createProgram(
      {
        name,
        description,
        image: imageURL,
        length: programLength,
        defaultView: defaultView.toUpperCase() as ProgramDefaultView,
      },
      {
        onSuccess(slug) {
          trackEvent("Coach - Create Program", {
            name: name,
            length: programLength,
          });
          showToastAlert("success", {
            message: "Successfully created new program.",
          });
          navigate(COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", slug));
        },
        onError,
      },
    );
  }, [
    createProgram,
    name,
    description,
    imageURL,
    programLength,
    defaultView,
    onError,
    trackEvent,
  ]);

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setName(event.target.value);
      if (errors.name && event.target.value.trim().length >= 4) {
        setErrors((errors) => ({ ...errors, name: "" }));
      }
    },
    [errors],
  );

  const handleDescriptionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setDescription(event.target.value);
      if (errors.description && event.target.value.trim()) {
        setErrors((errors) => ({ ...errors, description: "" }));
      }
    },
    [errors],
  );

  const handleProgramLengthChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setProgramLength(event.target.value as number);
    },
    [],
  );

  const handleChangeCoverImage = React.useCallback((value: string) => {
    setImageURL(value || "");
  }, []);

  const handleDefaultViewChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setDefaultView(event.target.value as ProgramDetailsViewMode);
    },
    [],
  );

  const disabled = inFlight;

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      PaperProps={{ className: s.paper }}
      classes={{ header: s.header }}
      title="Create your program"
      {...other}
    >
      <Typography className={s.info}>
        You can change to your program at any time later on.
      </Typography>

      <FieldsGroup
        className={clsx(s.control)}
        labelClassName={s.fieldGroupLabel}
        label="Name"
        {...other}
      >
        <TextField
          variant="outlined"
          fullWidth
          id="name"
          placeholder="Enter program name..."
          value={name}
          onChange={handleNameChange}
          disabled={disabled}
          required
        />
        {errors.name && (
          <FieldError hideIcon={true} className={s.error}>
            {errors.name}
          </FieldError>
        )}
      </FieldsGroup>

      <FieldsGroup
        className={clsx(s.control)}
        labelClassName={s.fieldGroupLabel}
        label="Description"
        {...other}
      >
        <TextField
          variant="outlined"
          fullWidth
          multiline
          id="description"
          placeholder="Describe the program..."
          value={description}
          onChange={handleDescriptionChange}
          disabled={disabled}
          required
        />
        {errors.description && (
          <FieldError hideIcon={true} className={s.error}>
            {errors.description}
          </FieldError>
        )}
      </FieldsGroup>

      <Box className={s.fieldsRow}>
        <FormControl className={s.control}>
          <FormLabel
            className={s.label}
            htmlFor="length"
            component="label"
            children="Length"
          />
          <Select
            variant="outlined"
            id="length"
            value={programLength}
            onChange={handleProgramLengthChange}
            fullWidth
            disabled={disabled}
            className={s.select}
          >
            {Array.from({ length: 99 }, (_, i) => i + 1).map((value) => (
              <MenuItem className={s.option} key={value} value={value}>
                <ListItem component="div">
                  <ListItemText classes={{ primary: s.primaryText }}>
                    {maybePluralize(value, "week")}
                  </ListItemText>
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={s.control}>
          <FormLabel
            className={s.label}
            htmlFor="defaultView"
            component="label"
            children="Default view"
          />
          <Select
            className={s.select}
            variant="outlined"
            id="defaultView"
            value={defaultView}
            onChange={handleDefaultViewChange}
            fullWidth
            disabled={disabled}
          >
            {Object.entries(defaultCalendarViewOptions).map(
              ([viewMode, { label, icon }]) => (
                <MenuItem key={viewMode} value={viewMode} className={s.option}>
                  <ListItem component="div">
                    <ListItemIcon className={s.optionIcon}>{icon}</ListItemIcon>
                    <ListItemText classes={{ primary: s.primaryText }}>
                      {label}
                    </ListItemText>
                  </ListItem>
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </Box>

      <SettingsCoverImage
        className={clsx(s.control, s.coverImage)}
        nodeId={userId}
        refType="Program"
        defaultUnsplashQuery={defaultProgramUnsplashQuery}
        assetType={AssetType.PROGRAM_IMAGE}
        image={imageURL}
        uploadImageText="Change cover"
        onImageChange={handleChangeCoverImage}
        disabled={disabled}
        classes={{
          container: s.settingCoverImageContainer,
          uploadButton: s.uploadImageButton,
        }}
        removable={false}
      >
        Give your program its own identity with a cover photo.
      </SettingsCoverImage>

      <ActionButton
        className={s.submit}
        onClick={handleSubmit}
        size="large"
        fullWidth
      >
        Create your program
      </ActionButton>
    </BaseDialog>
  );
}
