import clsx from "clsx";
import React from "react";
import { TextField, IconButton, OutlinedTextFieldProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { ReactComponent as SearchIcon } from "../../icons/Search.svg";

const useStyles = makeStyles((theme) => ({
  root: {},

  searchIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },

  clearButton: {
    marginRight: `${theme.spacing(-1)} !important`,
    padding: theme.spacing(1),
    "& svg": {
      width: 24,
      height: 24,
      color: theme.palette.text.secondary,
    },
  },
}));

export interface SearchFieldProps extends OutlinedTextFieldProps {
  hideSearchIcon?: boolean;
  hideClearButton?: boolean;
  onReset?: () => void;
}

export function SearchField(props: SearchFieldProps) {
  const {
    className,
    value,
    onChange,
    onReset,
    hideSearchIcon = false,
    hideClearButton = false,
    ...other
  } = props;
  const s = useStyles();

  const handleClearClick = React.useCallback(
    (event) => {
      if (onReset) {
        onReset();
      } else if (onChange) {
        event.currentTarget.value = "";
        event.target.value = "";
        onChange(event);
      }
    },
    [onChange, onReset],
  );

  return (
    <TextField
      className={clsx(s.root, className)}
      value={value}
      onChange={onChange}
      {...other}
      slotProps={{
        input: {
          startAdornment: !hideSearchIcon && (
            <SearchIcon className={s.searchIcon} />
          ),
          endAdornment: !hideClearButton && value && (
            <IconButton
              className={s.clearButton}
              onClick={handleClearClick}
              children={<CloseIcon />}
              size="large"
            />
          ),
        },
      }}
    />
  );
}
