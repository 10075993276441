import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardProps,
  IconButton,
  Portal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import makeStyles from "@mui/styles/makeStyles";
import { sortBy, reverse } from "lodash";
import omit from "lodash.omit";

import {
  getDefaultUnit,
  Units,
  UnitType,
  WeightMeasurementUnit,
} from "../../constants";

import { colorSystem } from "../../theme";
import { TextButton } from "../button/TextButton";

import {
  Calendar,
  ChartColumn,
  ChartColumnBig,
  ChartSpline,
  ChevronLeft,
  Pencil,
  Plus,
  Settings,
} from "lucide-react";

import { ReactComponent as BodyWeightIcon } from "../../icons/bodyWeight.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";

import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  TooltipProps as RechartsTooltipProps,
} from "recharts";
import dayjs, { Dayjs } from "dayjs";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BodyWeightMeasurementsService from "../../services/BodyWeightMeasurementsService";
import {
  BodyWeightDto,
  MeasurementUnit,
} from "@growth-machine-llc/stridist-api-client";
import {
  MeasurementDrawer,
  MetricType,
} from "../client-generic-measurement/MeasurementDrawer";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { PROGRESS_DIALOG_QUERY_KEY } from "../client-progress/ProgressDialog";
import weekday from "dayjs/plugin/weekday";
import timezone from "dayjs/plugin/timezone";
import weekOfYear from "dayjs/plugin/weekOfYear";
import {
  NameType,
  ValueType,
  // eslint-disable-next-line import/no-unresolved
} from "recharts/types/component/DefaultTooltipContent";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  chunkBy,
  chunkKeepTail,
  interpolateDataContinuous,
} from "../../utils/chart";
import SettingsPopup from "../charts/SettingsPopup";
import SettingsPopupOption from "../charts/SettingsPopupOption";
import { convertWeight } from "../../utils/units";

dayjs.extend(weekday);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    position: "relative",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  headerContent: {
    marginLeft: theme.spacing(1.3),
  },

  arrowIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  today: {
    fontSize: 10,
    fontWeight: 800,
    lineHeight: "12px",
    color: theme.palette.common.black,
    backgroundColor: colorSystem.gray9,
    borderRadius: 4,
    width: "fit-content",
    padding: theme.spacing(0.2, 0.8),
    textTransform: "uppercase",
  },

  subtitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "24px",
    color: theme.palette.common.black,
  },

  iconButton: {
    margin: theme.spacing(-10, 0),
  },

  iconButtonGroup: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(1.5),

    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(5.7),
      right: theme.spacing(8),
    },
  },

  buttons: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2.3, -0.45, 3, -0.45),
    gap: theme.spacing(0.5),
  },

  button: {
    backgroundColor: "transparent",
    borderWidth: 0,
    color: theme.palette.text.secondary,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 700,
    margin: 0,
    padding: theme.spacing(0.8, 1),
    lineHeight: "17px",
    minWidth: 0,
    "&:hover": {
      color: colorSystem.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  activeButton: {
    color: colorSystem.white,
    backgroundColor: theme.palette.secondary.main,
  },

  headerNav: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  invisible: {
    visibility: "hidden",
  },

  navButtonRight: {
    transform: "rotate(180deg)",
  },

  navButton: {
    width: 40,
  },

  todayContainer: {
    display: "flex",
    alignItems: "center",
  },
  todayWeight: {
    fontSize: 13,
    lineHeight: "13px",
    fontWeight: 500,
    color: colorSystem.gray,
    marginLeft: 6,
  },
  indicator: {
    fontSize: 14,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    "&$gain": {
      color: theme.palette.progress.red,
    },

    "&$drop": {
      color: theme.palette.progress.green,
    },
  },
  indicatorValue: {
    fontSize: 20,
    "&$gain": {
      color: theme.palette.progress.red,
    },

    "&$drop": {
      color: theme.palette.progress.green,
    },
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  headerContainer: {
    display: "flex",
  },
  gain: {},
  drop: {},
}));

export interface ClientStepsCardProps extends CardProps {
  clientId: number;
  bodyWeight?: boolean;
  clientUnits: string;
}

export enum TimeUnit {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  ALLTIME = "alltime",
}

export const WEIGHT_MEASUREMENTS_QUERY_KEY = "weight-measurements-profile-card";

const AVERAGING_WINDOW_SIZE = 2; // Amount of data points on each side to take into averaging min/max
const AVERAGING_FN_SLOPE = 1 + AVERAGING_WINDOW_SIZE; // coefficient of slope of the averaging function, bigger coeff = more influence of distant points

const ChartDot = (color: string, r: number, showInterpolated?: boolean) => {
  return ({ cx, cy, payload }: { cx: number; cy: number; payload: any }) => {
    return (!showInterpolated && payload.interpolated) || !payload.y ? null : (
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={color}
        stroke={colorSystem.white}
        strokeWidth={r / 2}
      />
    );
  };
};

const ChartTooltip = (themeColor: string) => {
  return ({
    active,
    payload,
    label,
  }: RechartsTooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <Card sx={{ padding: 1 }}>
          <Typography variant="subtitle2">
            {dayjs(label).format("MMMM DD, YYYY")}
          </Typography>
          <Typography color={themeColor} variant="h5">
            {payload[0].payload.interpolated ? "~" : ""}
            {payload[0].value.valueOf() as number} {payload[0].unit}
          </Typography>
        </Card>
      );
    }

    return null;
  };
};

const averagingFn = (index: number) =>
  (AVERAGING_FN_SLOPE - Math.abs(index - AVERAGING_WINDOW_SIZE)) /
  (AVERAGING_FN_SLOPE * AVERAGING_FN_SLOPE);

interface Settings {
  pointsAveraging: boolean;
  alignToRange: boolean;
}

export function ClientBodyWeightCard(props: ClientStepsCardProps) {
  const { clientId, clientUnits, ...other } = props;

  const { data: bodyWeights } = useQuery({
    queryKey: [WEIGHT_MEASUREMENTS_QUERY_KEY, { clientId }],
    queryFn: () =>
      BodyWeightMeasurementsService.getBodyWeightMeasurements(clientId),
    placeholderData: [],
  });

  const s = useStyles();
  const theme = useTheme();
  const { breakpoints } = useTheme();
  const queryClient = useQueryClient();

  const [activeButton, setActiveButton] = useState<TimeUnit>(TimeUnit.WEEK);
  const [navIndex, setNavIndex] = useState(0);
  const [isOpenContent, setIsOpenContent] = useState(true);
  const [settingsDialogAnchorEl, setSettingsDialogAnchorEl] =
    useState<HTMLElement | null>(null);
  const [settings, setSettings] = useLocalStorage<Settings>(
    "weightChartSettings",
    {
      pointsAveraging: false,
      alignToRange: false,
    },
  );
  const smUp = useMediaQuery(breakpoints.up("sm"));

  const formattedValue = useMemo(() => {
    return bodyWeights?.map((bodyWeight) => {
      return {
        ...bodyWeight,
        value: convertWeight(
          bodyWeight.value,
          bodyWeight.unit as unknown as WeightMeasurementUnit,
          clientUnits as Units,
        ),
      };
    });
  }, [bodyWeights]);

  const sortedBodyWeight = useMemo(
    () =>
      reverse(
        sortBy(formattedValue, ({ measuredOn }) => measuredOn)
          .filter((bodyWeight) => bodyWeight)
          .map((bodyWeight) =>
            BodyWeightDto.fromJS({
              ...bodyWeight,
              activityDate: bodyWeight.measuredOn,
            }),
          ),
      ),
    [formattedValue],
  );

  const {
    mutate: createMeasurement,
    isPending: creating,
    variables: createVars,
  } = useOptimisticUpdateMutation({
    queryKey: [WEIGHT_MEASUREMENTS_QUERY_KEY, { clientId }],
    mutationFn: BodyWeightMeasurementsService.createBodyWeightMeasurement,
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (oldData: BodyWeightDto[], newData, tempId) => {
        const newMeasurement = BodyWeightDto.fromJS({
          ...newData,
          id: tempId,
        });
        return [newMeasurement, ...oldData];
      },
    },
    successUpdater: {
      updateFn: (oldData, id, tempId) => {
        return oldData.map((item) => {
          if (item.id === tempId) {
            item.id = id;
          }
          return item;
        });
      },
    },
  });

  const {
    mutate: updateMeasurement,
    isPending: updating,
    variables: updateVars,
  } = useOptimisticUpdateMutation({
    queryKey: [WEIGHT_MEASUREMENTS_QUERY_KEY, { clientId }],
    mutationFn: BodyWeightMeasurementsService.updateBodyWeightMeasurement,
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (oldData: BodyWeightDto[], newData) => {
        const index = oldData.findIndex((item) => item.id === newData.id);
        const updatedData = [...oldData];
        updatedData[index] = BodyWeightDto.fromJS({
          ...updatedData[index],
          unit: newData.unit,
          value: newData.value,
          measuredOn: newData.measuredOn,
        });
        return updatedData;
      },
    },
  });

  const { mutate: removeMeasurement } = useOptimisticUpdateMutation({
    queryKey: [WEIGHT_MEASUREMENTS_QUERY_KEY, { clientId }],
    mutationFn: BodyWeightMeasurementsService.deleteBodyWeightMeasurement,
    optimisticUpdater: {
      updateFn: (oldData: BodyWeightDto[], id) => {
        const updateSteps = [...oldData];
        return updateSteps.filter((item) => item.id !== id);
      },
    },
  });

  const updatingId = updating && updateVars?.id;
  const creatingForDate =
    createVars?.measuredOn && creating ? createVars?.measuredOn : null;

  const units = clientUnits as Units;
  const unit = getDefaultUnit(UnitType.WEIGHT, units) as MeasurementUnit;

  const handleSave = React.useCallback(
    ({ id, date, measurement }: MetricType, onSuccess: () => void) => {
      if (id) {
        updateMeasurement(
          {
            id,
            measuredOn: date,
            value: measurement,
            unit,
          },
          {
            onSuccess: () => {
              if (onSuccess) onSuccess();
              queryClient.invalidateQueries({
                queryKey: [PROGRESS_DIALOG_QUERY_KEY, { clientId }],
              });
            },
          },
        );
      } else {
        createMeasurement(
          {
            clientId,
            name: "bodyweight",
            unit,
            measuredOn: date,
            value: measurement,
          },
          {
            onSuccess: () => {
              if (onSuccess) onSuccess();
              queryClient.invalidateQueries({
                queryKey: [PROGRESS_DIALOG_QUERY_KEY, { clientId }],
              });
            },
          },
        );
      }
    },
    [clientId, createMeasurement, bodyWeights, updateMeasurement, units],
  );

  const handleRemove = useCallback(
    ({ id }: MetricType) => {
      removeMeasurement(id);
    },
    [removeMeasurement, bodyWeights],
  );

  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-goals",
  });

  const borderColor = theme.palette.primary.main;

  const getToday = useCallback(() => {
    return sortedBodyWeight.find((day) =>
      day.measuredOn.isSame(dayjs(), "day"),
    );
  }, [sortedBodyWeight]);

  const todayValue = useMemo(() => {
    const today = getToday();
    return today ? `${today.value} ${unit}` : `0 ${unit} `;
  }, [getToday, unit]);

  const data = useMemo(
    () =>
      sortBy(sortedBodyWeight, ({ measuredOn }) => measuredOn).map(
        ({ measuredOn, value }) => ({
          x: measuredOn as Dayjs,
          y: value,
        }),
      ),
    [sortedBodyWeight],
  );

  const chartData = useMemo(() => {
    // undefined so that alltime data will never be averaged
    const timeUnits = ["day", "week", "month", "year", undefined, "alltime"];
    const averagingWindow: any = timeUnits[timeUnits.indexOf(activeButton) - 1];
    const unaveragedChunkSizes = {
      week: 7,
      month: 30,
      year: 365,
      alltime: Infinity,
    };
    const interpolatedData = interpolateDataContinuous(
      data,
      !settings.alignToRange || activeButton === "alltime",
      settings.pointsAveraging ? averagingWindow : undefined,
    );
    if (settings.alignToRange) {
      return chunkBy(
        interpolatedData,
        activeButton !== "alltime"
          ? [
              (activeButton === "week" ? "isoWeek" : activeButton) as string,
              activeButton === "week" ? "isoWeekYear" : "year",
            ]
          : [],
      );
    } else {
      return chunkKeepTail(
        interpolatedData,
        unaveragedChunkSizes[activeButton],
      );
    }
  }, [activeButton, data, settings]);

  useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);

  const averagingStartPoint =
    navIndex - AVERAGING_WINDOW_SIZE > 0 ? navIndex - AVERAGING_WINDOW_SIZE : 0;
  const averagingEndPoint =
    navIndex + AVERAGING_WINDOW_SIZE + 1 > chartData.length
      ? chartData.length
      : navIndex + AVERAGING_WINDOW_SIZE + 1;

  const averagingData = chartData.slice(averagingStartPoint, averagingEndPoint);

  const chartMax = React.useMemo(() => {
    if (!chartData[navIndex] || !averagingData) return 0;
    return (
      Math.ceil(
        (Math.max(
          averagingData
            .map(
              (page, index) =>
                Math.max(...page.map((point) => point.y || -Infinity)) *
                averagingFn(
                  index + AVERAGING_WINDOW_SIZE * 2 + 1 - averagingData.length,
                ),
            )
            .reduce((acc, item) => acc + item, 0),
          ...chartData[navIndex].map((point) => point.y || -Infinity),
        ) *
          1.1) /
          5 +
          Number.EPSILON,
      ) * 5
    );
  }, [chartData, navIndex]);

  const chartMin = React.useMemo(() => {
    if (!chartData[navIndex] || !averagingData) return 0;
    return (
      Math.floor(
        Math.min(
          averagingData
            .map(
              (page, index) =>
                Math.min(...page.map((point) => point.y || Infinity)) /
                averagingFn(
                  index + AVERAGING_WINDOW_SIZE * 2 + 1 - averagingData.length,
                ),
            )
            .reduce((acc, item) => acc + item, 0),
          ...chartData[navIndex].map((point) => point.y || Infinity),
        ) /
          1.1 /
          5 +
          Number.EPSILON,
      ) * 5
    );
  }, [chartData, navIndex]);

  const chartTicks = React.useMemo(() => {
    const tickCount = 7;
    const ticks = new Set();
    for (let i = 1; i <= tickCount; i++) {
      ticks.add(
        Math.round(
          ((Math.pow(i, 2) / Math.pow(tickCount, 2)) * (chartMax - chartMin) +
            chartMin) /
            5,
        ) * 5,
      );
    }
    return Array.from(ticks) as number[];
  }, [chartMax, chartMin]);

  const xTicksWithYear = React.useMemo(() => {
    const viewData = chartData[navIndex];
    if (!viewData || activeButton !== "alltime") return [];
    const tickCount = 9;
    const ticksPadding = Math.round(viewData.length / tickCount);
    const yearsStartpoints = viewData
      .filter((item) => item.year)
      .map((item) => item.x);

    const tickMeanGap = Math.round(viewData.length / tickCount);

    let ticks = [...yearsStartpoints];

    for (let i = 0; i < tickCount - yearsStartpoints.length; i++) {
      const curr = ticks[i];
      const next = ticks[i + 1] || viewData[viewData.length - 1].x;
      if (
        dayjs(next).diff(dayjs(curr), "days") >
        tickMeanGap + 2 * ticksPadding
      ) {
        const tick =
          viewData[
            viewData.findIndex((item) => item.x === curr) +
              tickMeanGap +
              ticksPadding
          ].x;
        ticks = [...ticks.slice(0, i + 1), tick, ...ticks.slice(i + 1)];
      }
    }

    return ticks;
  }, [chartData, navIndex, activeButton]);

  const handleSettingsChange = (field) => {
    return (value) =>
      setSettings((settings) => ({ ...settings, [field]: value }));
  };

  useEffect(() => {
    setNavIndex(chartData.length - 1);
  }, [chartData.length, activeButton]);

  const bodyWeightCard = useMemo(() => {
    return (
      <Card className={clsx(s.root)} {...other}>
        <Box className={s.header}>
          <Box className={s.headerContainer}>
            <BodyWeightIcon />
            <Box className={s.headerContent}>
              <Box className={s.todayContainer}>
                <Typography variant="h6" className={s.today} children="Today" />
                <Typography
                  variant="h6"
                  className={s.todayWeight}
                  children="Weight"
                />
                <Box className={s.iconButtonGroup}>
                  <IconButton
                    className={s.iconButton}
                    {...bindToggle(drawerState)}
                  >
                    <Pencil size={18} />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      setSettingsDialogAnchorEl(e.currentTarget);
                    }}
                    className={s.iconButton}
                  >
                    <Settings size={18} />
                  </IconButton>
                </Box>
              </Box>
              <Typography variant="body1" className={clsx(s.subtitle)}>
                {todayValue}
              </Typography>
            </Box>
          </Box>
          <ArrowIcon
            className={clsx(s.arrowIcon, isOpenContent && s.arrowIconDown)}
            onClick={() => setIsOpenContent(!isOpenContent)}
          />
        </Box>
        {isOpenContent && (
          <>
            {chartData && chartData.length > 0 ? (
              <>
                <Box className={s.buttons}>
                  <TextButton
                    className={clsx(
                      s.button,
                      activeButton === "week" && s.activeButton,
                    )}
                    onClick={() => {
                      setActiveButton(TimeUnit.WEEK);
                      setNavIndex(0);
                    }}
                  >
                    Week
                  </TextButton>
                  <TextButton
                    className={clsx(
                      s.button,
                      activeButton === "month" && s.activeButton,
                    )}
                    onClick={() => {
                      setActiveButton(TimeUnit.MONTH);
                      setNavIndex(0);
                    }}
                  >
                    Month
                  </TextButton>
                  <TextButton
                    className={clsx(
                      s.button,
                      activeButton === "year" && s.activeButton,
                    )}
                    onClick={() => {
                      setActiveButton(TimeUnit.YEAR);
                      setNavIndex(0);
                    }}
                  >
                    Year
                  </TextButton>
                  <TextButton
                    className={clsx(
                      s.button,
                      activeButton === "alltime" && s.activeButton,
                    )}
                    onClick={() => {
                      setActiveButton(TimeUnit.ALLTIME);
                      setNavIndex(0);
                    }}
                  >
                    {smUp ? "All time" : "All"}
                  </TextButton>
                  <Box
                    className={clsx(
                      s.headerNav,
                      activeButton === "alltime" && s.invisible,
                    )}
                  >
                    <IconButton
                      className={s.navButton}
                      onClick={() => setNavIndex((prev) => prev - 1)}
                      disabled={navIndex === 0 || chartData.length === 0}
                    >
                      <ChevronLeft
                        scale={2}
                        color={
                          navIndex === 0 || chartData.length === 0
                            ? "grey"
                            : "black"
                        }
                      />
                    </IconButton>
                    <IconButton
                      className={clsx(s.navButton, s.navButtonRight)}
                      onClick={() => setNavIndex((prev) => prev + 1)}
                      disabled={
                        chartData &&
                        (navIndex >= chartData.length - 1 ||
                          chartData.length === 0)
                      }
                    >
                      <ChevronLeft
                        scale={2}
                        color={
                          chartData &&
                          (navIndex >= chartData.length - 1 ||
                            chartData.length === 0)
                            ? "grey"
                            : "black"
                        }
                      />
                    </IconButton>
                  </Box>
                </Box>
                <ResponsiveContainer
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                  width="100%"
                  height={308}
                >
                  <AreaChart
                    margin={{ right: 50, top: 10 }}
                    data={chartData[navIndex]}
                  >
                    <CartesianGrid strokeWidth={1.5} strokeDasharray="3 3" />
                    {activeButton === "week" ? (
                      <>
                        <XAxis
                          dataKey={"x"}
                          tickFormatter={(value) => dayjs(value).format("ddd")}
                          xAxisId={0}
                          strokeWidth={1.5}
                          tickMargin={10}
                          allowDuplicatedCategory={false}
                          stroke={theme.palette.text.secondary}
                          fill={theme.palette.text.secondary}
                        />
                        <XAxis
                          strokeWidth={1.5}
                          dataKey="x"
                          minTickGap={15}
                          xAxisId={1}
                          tickMargin={-5}
                          axisLine={false}
                          tickLine={false}
                          tickFormatter={(value) =>
                            dayjs(value).format("MMM D")
                          }
                          stroke={theme.palette.text.secondary}
                          fill={theme.palette.text.secondary}
                        />
                      </>
                    ) : (
                      <XAxis
                        strokeWidth={1.5}
                        dataKey="x"
                        minTickGap={15}
                        xAxisId={0}
                        tickMargin={10}
                        interval="preserveStart"
                        ticks={activeButton === "alltime" && xTicksWithYear}
                        tickFormatter={(value) => dayjs(value).format("MMM D")}
                        stroke={theme.palette.text.secondary}
                        fill={theme.palette.text.secondary}
                      />
                    )}

                    {activeButton === "alltime" && (
                      <XAxis
                        dataKey={"year"}
                        ticks={chartData[navIndex]
                          .filter((item) => item.year)
                          .map((item) => item.year)}
                        axisLine={false}
                        xAxisId={1}
                        strokeWidth={1.5}
                        tickMargin={-5}
                        tickLine={false}
                        stroke={theme.palette.text.secondary}
                        fill={theme.palette.text.secondary}
                      />
                    )}

                    <YAxis
                      domain={[chartMin, chartMax]}
                      ticks={chartTicks}
                      axisLine={false}
                      tickLine={false}
                      strokeWidth={1.5}
                      yAxisId={0}
                      stroke={theme.palette.text.secondary}
                      fill={theme.palette.text.secondary}
                    />

                    <RechartsTooltip
                      animationDuration={300}
                      animationEasing="linear"
                      cursor={
                        activeButton === "alltime" || activeButton === "year"
                      }
                      content={ChartTooltip(borderColor)}
                    />

                    <Area
                      type={
                        settings.pointsAveraging && activeButton !== "alltime"
                          ? "step"
                          : "monotone"
                      }
                      dataKey="y"
                      unit={unit}
                      stroke={borderColor}
                      fillOpacity={0.6}
                      fill={borderColor}
                      strokeWidth={2}
                      animationDuration={300}
                      activeDot={ChartDot(
                        borderColor,
                        6,
                        activeButton === "alltime" || activeButton === "year",
                      )}
                      dot={
                        activeButton !== "alltime" && activeButton !== "year"
                          ? ChartDot(borderColor, 4)
                          : false
                      }
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  height: 320,
                }}
              >
                <Typography variant="h6">No weight logged yet</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<Plus size={17} />}
                  {...bindToggle(drawerState)}
                >
                  Log weight
                </Button>
              </Box>
            )}
          </>
        )}
      </Card>
    );
  }, [bodyWeights, navIndex, activeButton, isOpenContent, settings]);
  return (
    <>
      {bodyWeightCard}
      {drawerState.isOpen && (
        <Portal>
          <MeasurementDrawer
            {...omit(bindPopper(drawerState), ["anchorStepEl"])}
            handleSave={handleSave}
            handleRemove={handleRemove}
            title={"Body Weight"}
            subtitle="Correct body weight"
            onClose={drawerState.close}
            today={getToday()}
            steps={sortedBodyWeight.map((item) => ({
              ...item,
              value: item.value.toFixed(1),
            }))}
            clientId={clientId}
            unit={unit}
            creatingForDate={creatingForDate}
            creating={creating}
            updatingId={updatingId}
          />
        </Portal>
      )}
      <SettingsPopup
        anchorEl={settingsDialogAnchorEl}
        setAnchorEl={setSettingsDialogAnchorEl}
      >
        <SettingsPopupOption
          title="Points"
          setting={settings.pointsAveraging}
          handleSettingChange={handleSettingsChange("pointsAveraging")}
          disabled={activeButton === "alltime"}
          options={[
            {
              label: "All records",
              description:
                "See daily records within your selected time frame (week, month, year, or all-time).",
              icon: <ChartSpline size={15} />,
            },
            {
              label: "Averages",
              description:
                "See average client progress, shown daily (for weeks), weekly (for months), monthly (for years)",
              icon: <ChartColumnBig size={15} />,
            },
          ]}
        />
        <SettingsPopupOption
          title="Alignment"
          setting={settings.alignToRange}
          handleSettingChange={handleSettingsChange("alignToRange")}
          disabled={activeButton === "alltime"}
          options={[
            {
              label: "Recent days",
              description:
                "Focus on the latest 7, 30, or 365 days of the data.",
              icon: <ChartColumn size={15} />,
            },
            {
              label: "Calendar view",
              description:
                "View data for ongoing calendar week, month, or year.",
              icon: <Calendar size={15} />,
            },
          ]}
        />
      </SettingsPopup>
    </>
  );
}
