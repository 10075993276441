import clsx from "clsx";
import React, { useState } from "react";
import {
  Box,
  BoxProps,
  MenuItem,
  ListItemText,
  MenuItemProps,
  alpha,
  IconButton,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { sevenDays } from "../../constants";
import {
  generateComponentDays,
  parseComponentDays,
  stringifyComponentDays,
} from "../../utils/component";
import { Save } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {},
  selected: {
    backgroundColor: theme.palette.selected.main,
  },

  badge: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    fontWeight: "bold",
    width: "fit-content",
    borderRadius: 8,
    paddingInline: theme.spacing(2),
  },
  custom: {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },

  single: {
    backgroundColor: theme.palette.selected.main,
  },

  icon: {
    marginLeft: "auto",
    color: theme.palette.common.black,
  },
}));

export interface ISelectComponentDayProps extends Omit<BoxProps, "onChange"> {
  onChange?: (newDays: string) => void;
  currentDays: string;
  singleDayMode?: boolean;
}

function isArrCustom(boolArray: boolean[]) {
  const trueCount = boolArray.reduce(
    (count, value) => count + (value ? 1 : 0),
    0,
  );

  return trueCount > 1;
}

export function SelectComponentDay(props: ISelectComponentDayProps) {
  const { className, onChange, currentDays, singleDayMode, ...other } = props;
  const s = useStyles();

  const parsedCurrentDays = parseComponentDays(currentDays);

  const [selectedDays, setSelectedDays] = useState(parsedCurrentDays);
  const [isCustom, setIsCustom] = useState(isArrCustom(parsedCurrentDays));

  const handleClick: MenuItemProps["onClick"] = (event) => {
    const { day } = event.currentTarget.dataset;
    const dayIndex = Number(day) - 1;

    setSelectedDays((prevSelectedDays) => {
      const updatedDays = [...prevSelectedDays];

      if (!isCustom) {
        onChange && onChange(generateComponentDays(Number(day) - 1));
      }

      updatedDays[dayIndex] = !updatedDays[dayIndex];
      return updatedDays;
    });
  };

  const handleSave = () => {
    const processedDays = stringifyComponentDays(selectedDays);
    onChange && onChange(processedDays);
  };

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {sevenDays.map((day) => (
        <MenuItem
          key={day}
          data-day={day}
          onClick={handleClick}
          className={clsx({
            [s.selected]: selectedDays[day - 1],
          })}
        >
          <ListItemText primary={`Day ${day}`} />
        </MenuItem>
      ))}
      <Divider />

      <Box
        display={"flex"}
        sx={{
          paddingInline: 2,
        }}
      >
        <Box
          className={clsx(s.badge, {
            [s.custom]: isCustom,
            [s.single]: !isCustom,
          })}
          onClick={() => setIsCustom((v) => (singleDayMode ? false : !v))}
        >
          <b>{isCustom ? "Custom" : "Single"}</b>
        </Box>

        <IconButton size="small" className={s.icon} onClick={handleSave}>
          <Save />
        </IconButton>
      </Box>
    </Box>
  );
}
