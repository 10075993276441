import clsx from "clsx";
import React, { MouseEventHandler, useMemo, useRef } from "react";
import {
  Box,
  BoxProps,
  Typography,
  ButtonProps,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ProgramWeekSchedule } from "../../hooks/useProgramSchedule";
import { Filters } from "../program/filters/ComponentTypeFilter";
import { ComponentType, sevenDays } from "../../constants";
import { ProgramWeekDateRange } from "../program/ProgramWeekDateRange";
import { calcScheduleGrid } from "./utils";
import {
  ProgramCalendarDay,
  ProgramCalendarDayProps,
} from "./ProgramCalendarDay";
import { ProgramCalendarComponentContainer } from "./ProgramCalendarComponentContainer";
import { ProgramCalendarComponentButtons } from "./ProgramCalendarComponentButtons";
import { ProgramCalendarComponent } from "./ProgramCalendarComponent";
import { getBannerHeight } from "../../contexts/BannersContext";
import { headerHeight } from "../app/AppBar";
import { useCurriculumSelector } from "../../redux/hooks";
import {
  selectCantMoveWeeksExceptSelf,
  selectProgram,
  selectWeekById,
} from "../../redux/curriculum/selectors/curriculum";
import WeekActionItems from "../program-week/WeekActionItems";
import { DefaultLoader } from "../loading/DefaultLoader";
import { selectGeneralLoading } from "../../redux/api/selectors";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import { useDialog } from "../../contexts/CurriculumComponentDialogContext";
import { ComponentClickHandlerType } from "../program/ProgramDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:not(:last-of-type)": {
      marginBlock: theme.spacing(4),
    },
    "&:first-of-type": {
      marginTop: theme.spacing(2),
    },
  },

  header: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    position: "sticky",
    background: "white",
    zIndex: theme.zIndex.speedDial + 1,
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 0,
    height: 44,
    alignItems: "center",
    display: "flex",
  },

  dateRange: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2.25),
  },

  moreButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "none",
    },
    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.main,
    },
  },

  calendar: {
    backgroundColor: theme.palette.background.paper,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    flex: 1,
    borderTopWidth: 0,
  },

  days: {
    display: "flex",
    height: "100%",
  },

  day: {
    position: "relative",
    paddingBottom: theme.spacing(12),
  },
}));

export interface ProgramCalendarWeekProps extends BoxProps {
  weekId: number;
  filters?: Filters;
  startDate?: string;
  onOpenMenu?: ButtonProps["onClick"];
  onMove?: ProgramCalendarDayProps["onMove"];
  onMoveEnd?: () => void;
  schedule: ProgramWeekSchedule;
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
  componentClickHandler: ComponentClickHandlerType;
  componentDoubleClickHandler: ComponentClickHandlerType;
}

export function ProgramCalendarWeek(props: ProgramCalendarWeekProps) {
  const {
    weekId,
    className,
    schedule,
    filters,
    startDate,
    onOpenMenu,
    onMove,
    onMoveEnd,
    weeksActions,
    componentClickHandler,
    componentDoubleClickHandler,
    ...other
  } = props;
  const week = useCurriculumSelector(selectWeekById(weekId));
  const cantMove = useCurriculumSelector(selectCantMoveWeeksExceptSelf(weekId));
  const program = useCurriculumSelector(selectProgram);
  const loading = useCurriculumSelector(selectGeneralLoading);
  const { componentSlug: sidebarComponentSlug } = useSidebar();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const s = useStyles();
  const grid = React.useMemo(
    () =>
      calcScheduleGrid(
        schedule.filter(({ weeks }) => weeks.includes(week.weekNumber)),
        week.weekNumber,
      ),
    [schedule, week.weekNumber],
  );

  const bannerSpacing = theme.spacing(getBannerHeight());
  const computedHeaderHeight = isMd ? headerHeight(theme) : "0px";

  const upperIsDisabled = useMemo(
    () => cantMove.includes(week.weekNumber - 1),
    [cantMove, week.weekNumber],
  );

  const lowerIsDisabled = useMemo(
    () => cantMove.includes(week.weekNumber + 1),
    [cantMove, week.weekNumber],
  );

  // TODO refactor code duplication (all views),
  // handle waitingForApiResponse for component click and other states
  const weeksData = {
    canDuplicate: !week.waitingForApiResponse,
    canDelete: program.length > 1 && !week.waitingForApiResponse,
  };

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box
        className={s.header}
        sx={{
          top: `calc(${bannerSpacing} + ${computedHeaderHeight})`,
        }}
      >
        <Typography variant="h5" className={s.title}>
          Week {week.weekNumber}
          {week.waitingForApiResponse ? " - creating..." : ""}
        </Typography>

        <WeekActionItems
          weekId={week.id}
          onOpenMenu={onOpenMenu}
          onClickDelete={weeksActions.onClickDelete}
          onClickDuplicate={weeksActions.onClickDuplicate}
          canDuplicate={weeksData.canDuplicate}
          canDelete={weeksData.canDelete}
        />
        {loading && <DefaultLoader />}
      </Box>

      {startDate && (
        <ProgramWeekDateRange
          variant="h6"
          className={s.dateRange}
          startDate={startDate}
          week={week.weekNumber}
        />
      )}
      <Box className={s.calendar}>
        <Box className={s.days}>
          {sevenDays.map((_, dayIndex) => (
            <ProgramCalendarDay
              className={s.day}
              key={dayIndex}
              weekId={week.id}
              week={week.weekNumber}
              day={dayIndex}
              startDate={startDate}
              onMove={onMove}
              onMoveEnd={onMoveEnd}
              disabled={week.waitingForApiResponse}
            >
              {grid[dayIndex].map(({ span, id, component }, index) => (
                <ProgramCalendarComponentContainer
                  componentId={id}
                  week={week.weekNumber}
                  day={dayIndex}
                  key={[id || index, dayIndex, span].join(".")}
                  span={span}
                  disableResize={
                    component?.type === ComponentType.LESSON ||
                    !!component?.baseComponentId
                  }
                  zIndex={7 - dayIndex}
                >
                  {component && (
                    <React.Fragment key={id}>
                      <ProgramCalendarComponentButtons
                        componentData={component}
                        span={span}
                        spanOffset={dayIndex}
                        dayIndex={dayIndex}
                        weekNumber={week.weekNumber}
                        activeComponentSlug={sidebarComponentSlug}
                        containerWeekId={week.id}
                      />
                      <ProgramCalendarComponent
                        component={component}
                        onCardBodyClick={componentClickHandler}
                        onCardBodyDoubleClick={componentDoubleClickHandler}
                        activeComponentSlug={sidebarComponentSlug}
                        isOverride={!!component.baseComponentId}
                        dayIndex={dayIndex}
                        weekNumber={week.weekNumber}
                      />
                    </React.Fragment>
                  )}
                </ProgramCalendarComponentContainer>
              ))}
            </ProgramCalendarDay>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
