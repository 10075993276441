import clsx from "clsx";
import React from "react";
import { Card, CardProps, Typography, Box, IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import { LastActiveStatus, Units, UserRole } from "../../constants";
import { colorSystem } from "../../theme";
import {
  formatAgeAndGender,
  formatWeight,
  formatHeight,
} from "../../utils/units";

import { useCurrentUser, UserInfo } from "../../hooks/useCurrentUser";
import { CardAvatar } from "../card/CardAvatar";

import { useNavigate } from "react-router-dom";
import { COACH_CLIENTS_ARCHIVED_ROUTE } from "../../routes/routes";
import { IBriefClientInfo } from "@growth-machine-llc/stridist-api-client";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import ClientContextMenu from "./ClientContextMenu";
import useDeleteClientsMutation from "./mutations/useDeleteClientsMutation";
import dayjs from "dayjs";
import { TimeAgo } from "../../routes/utils/TimeAgo";
import useArchiveSingleClientMutation from "./mutations/useArchiveSingleClientMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: 0,
    borderRadius: 0,
    marginTop: 20,
  },

  content: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },

  avatar: {
    width: 100,
    height: 100,
    margin: 0,
    borderRadius: 4,
  },

  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: theme.spacing(0, 3),
  },

  header: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "20px",
    color: theme.palette.common.black,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "29px",
    },
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },

  topMargin: {
    marginTop: theme.spacing(0.5),
  },

  metrics: {
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    borderRadius: theme.spacing(0.5),
    backgroundColor: colorSystem.blue4,
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      maxWidth: 500,
      width: "100%",
      marginTop: theme.spacing(0),
    },
  },

  metricWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },

  metricValue: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.common.black,
  },

  more: {
    color: theme.palette.text.secondary,
    position: "absolute",
    right: theme.spacing(-2),
    top: theme.spacing(-2),
    height: theme.spacing(6),
    width: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(0),
      right: theme.spacing(0),
    },
  },

  userMenuItem: {
    padding: theme.spacing(0),
    margin: theme.spacing(1, 0),
    cursor: "pointer",
    "&:hover *": {
      color: theme.palette.common.black,
    },
  },

  userMenuText: {
    "& > span": {
      fontWeight: "bold",
      fontSize: 15,
    },
  },
  circle: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  infoUser: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },

  active: {
    backgroundColor: colorSystem.green,
  },
  recently: {
    backgroundColor: colorSystem.yellow,
  },
  away: {
    backgroundColor: colorSystem.gray,
  },
}));

const getLastActiveStatus = (
  lastActiveAt: dayjs.Dayjs | null,
): LastActiveStatus => {
  if (lastActiveAt) {
    const now = dayjs();
    const diffMinutes = now.diff(lastActiveAt, "minute");
    const diffDays = now.diff(lastActiveAt, "day");

    if (diffMinutes < 10) {
      return LastActiveStatus.ACTIVE;
    } else if (diffMinutes < 60 * 2) {
      return LastActiveStatus.RECENTLY;
    } else if (diffDays < 100) {
      return LastActiveStatus.AWAY;
    }
  }
  return LastActiveStatus.NO_DATA;
};

const getLastActiveMessage = (status: LastActiveStatus): string => {
  const lastSeenMessage = "last seen";
  switch (status) {
    case LastActiveStatus.ACTIVE:
      return `${lastSeenMessage} just now`;
    case LastActiveStatus.RECENTLY:
      return `${lastSeenMessage} recently`;
    case LastActiveStatus.AWAY:
      return lastSeenMessage;
    default:
      return `${lastSeenMessage} a long time ago`;
  }
};

export interface ClientSummaryProps extends CardProps {
  client:
    | (Omit<UserInfo, "phoneCode"> & Omit<IBriefClientInfo, "id">)
    | IBriefClientInfo;
}

export function ClientSummary(props: ClientSummaryProps) {
  const { className, client, ...other } = props;
  const s = useStyles();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const {
    id: clientId,
    displayName,
    photoUrl: photoURL,
    email,
    age,
    gender,
    weight,
    height,
    units,
    location,
    status,
    lastActiveAt,
    username,
  } = client;

  const initials = displayName.substring(0, 1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { showToastAlert } = useToastAlert();

  const { mutate: toggleArchiveClient, isPending: archivingClient } =
    useArchiveSingleClientMutation(username);

  const { mutate: deleteArchivedClient, isPending: deletingClient } =
    useDeleteClientsMutation(status);

  const handleDelete = React.useCallback(() => {
    deleteArchivedClient(
      { clientIds: [clientId] },
      {
        onSuccess() {
          navigate(COACH_CLIENTS_ARCHIVED_ROUTE);
        },
      },
    );
  }, [deleteArchivedClient, clientId]);

  const details = React.useMemo(
    () => [location || "No location to show", email],
    [location, email],
  );

  const metrics = React.useMemo(
    () => [
      { name: "Age", value: formatAgeAndGender(age ?? "-", gender) },
      { name: "Weight", value: formatWeight(weight, units as Units) },
      { name: "Height", value: formatHeight(height, units as Units) },
    ],
    [age, gender, weight, height, units],
  );

  const handleMoreClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl],
  );

  const handleMoreClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onArchivedChange = React.useCallback(
    (archived: boolean) => {
      toggleArchiveClient(
        {
          clientId: +clientId,
          archived,
        },
        {
          onSuccess: () => {
            const message = archived ? "Client archived" : "Client restored";
            showToastAlert("success", { message });
          },
        },
      );
    },
    [clientId, showToastAlert, toggleArchiveClient],
  );

  const lastActiveToDeterminateStatus = lastActiveAt
    ? dayjs(lastActiveAt.toString())
    : null;

  const lastActiveStatus = getLastActiveStatus(lastActiveToDeterminateStatus);
  const lastActiveMessage = getLastActiveMessage(lastActiveStatus);

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box className={s.content}>
        <CardAvatar className={s.avatar} src={photoURL} variant="rounded">
          {initials}
        </CardAvatar>
        <Box className={s.info}>
          <Box>
            <Box className={s.infoUser}>
              <Box
                className={clsx(s.circle, {
                  [s.active]: lastActiveStatus === LastActiveStatus.ACTIVE,
                  [s.recently]: lastActiveStatus === LastActiveStatus.RECENTLY,
                  [s.away]:
                    lastActiveStatus === LastActiveStatus.AWAY ||
                    lastActiveStatus === LastActiveStatus.NO_DATA,
                })}
              />
              <Typography
                variant="body1"
                className={clsx(s.text)}
                children={
                  LastActiveStatus.AWAY === lastActiveStatus ? (
                    <>
                      last seen <TimeAgo date={lastActiveAt} />
                    </>
                  ) : (
                    lastActiveMessage
                  )
                }
              />
            </Box>
            <Typography variant="h6" className={s.header}>
              {displayName}
            </Typography>
            {details.map((detail, index) => (
              <Typography
                key={index}
                variant="body1"
                className={clsx(s.text, s.topMargin)}
                children={detail}
              />
            ))}
          </Box>
        </Box>
      </Box>

      <Box className={s.metrics}>
        {metrics.map((metric, index) => (
          <Box key={index} className={s.metricWrapper}>
            <Typography
              variant="body1"
              className={s.text}
              children={metric.name}
            />
            <Typography
              variant="body1"
              className={s.metricValue}
              children={metric.value}
            />
          </Box>
        ))}
      </Box>

      {user.role === UserRole.COACH && (
        <>
          <IconButton
            aria-label="settings"
            className={s.more}
            onClick={handleMoreClick}
            children={<MoreVert />}
            size="large"
          />

          <ClientContextMenu
            anchorEl={anchorEl}
            handleClose={handleMoreClose}
            archivingClient={archivingClient}
            onArchivedChange={onArchivedChange}
            deletingClient={deletingClient}
            onDelete={handleDelete}
            user={client}
            isSample={user.accounts.some(
              (account) => client.id === account.id && account.isSample,
            )}
          />
        </>
      )}
    </Card>
  );
}
