import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import { FieldsGroup, FieldsGroupProps } from "./FieldsGroup";
import { ClientSettingsSchema } from "../../utils/clientSettingsSchema";
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { colorSystem } from "../../theme";

const preferredCountries = ["us", "ca", "gb", "au"];

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),

    "& .react-tel-input .special-label": {
      display: "none",
    },

    "& .react-tel-input ul.country-list": {
      padding: theme.spacing(1),
    },

    "& .react-tel-input ul.country-list .flag": {
      display: "none",
    },

    "& .react-tel-input ul.country-list li": {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 15,
      fontWeight: 500,

      padding: theme.spacing(1),
      borderRadius: 2,

      "&:hover": {
        backgroundColor: theme.palette.quote,
      },
    },
  },

  input: {
    width: "100%",
    fontSize: 16,
    fontWeight: 500,
    padding: theme.spacing(1.75, 1.75, 2.25, 7),
    height: theme.spacing(7),
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderWidth: 1,
    borderRadius: theme.spacing(0.5),
    outline: 0,

    "&:hover ": {
      borderColor: theme.palette.secondary.main,
    },

    "&:focus": {
      borderColor: colorSystem.primaryOpacity2,
    },
  },

  error: {
    position: "absolute",
  },
}));

export interface PhoneFieldProps<T = { phone?: string; phoneCode?: string }>
  extends Omit<FieldsGroupProps, "onChange" | "label" | "children"> {
  disabled?: boolean;
  errorMessagePhone?: string;
  errorMessagePhoneCode?: string;
  setError: UseFormSetError<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  clearErrors: UseFormClearErrors<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
  handleBlur?: () => void;
}

export function PhoneField(props: PhoneFieldProps) {
  const {
    className,
    disabled,
    errorMessagePhone,
    errorMessagePhoneCode,
    setError,
    watch,
    trigger,
    handleBlur,
    setValue,
    control,
    ...other
  } = props;
  const s = useStyles();

  const countryCode = watch("phoneCode");

  const handleChange = async (value: string, data: CountryData | {}) => {
    const phoneCode = data ? (data as any).countryCode : null;
    setValue("phoneCode", phoneCode, { shouldDirty: true });

    const phone =
      value.length > 4 ? (value.startsWith("+") ? value : "+" + value) : null;

    setValue("phone", phone, { shouldDirty: true });
  };

  return (
    <FieldsGroup
      className={clsx(s.root, className)}
      label="Phone number"
      error={errorMessagePhone || errorMessagePhoneCode}
      hideErrorIcon={true}
      errorClassName={s.error}
      {...other}
    >
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <PhoneInput
            inputProps={{ className: s.input }}
            country={countryCode}
            value={field.value}
            onChange={handleChange}
            onBlur={handleBlur}
            preferredCountries={preferredCountries}
            countryCodeEditable
            disabled={disabled}
          />
        )}
      />
    </FieldsGroup>
  );
}
