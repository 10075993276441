import React from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardProps,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { downloadCanvas, WorkoutStat } from "../../utils/canvas";
import SwipeableViews from "react-swipeable-views";
import dumbbell from "../../icons/workout-stats/dumbbell.svg";
import commit from "../../icons/workout-stats/commit.svg";
import repeat from "../../icons/workout-stats/repeat.svg";
import timer from "../../icons/workout-stats/timer.svg";
import ruler from "../../icons/workout-stats/ruler.svg";
import gymnastic from "../../icons/workout-stats/person-standing.svg";
import { isMobileApp } from "../../utils/mobile";
import Dots from "../startWorkout/Dots";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TrainingShareImage from "./TrainingShareImage";
import WorkoutButton from "../workout/WorkoutButton";
import { useAnalytics } from "../../hooks/useAnalytics";
import { Download, Share2 } from "lucide-react";
import { useClient } from "../../hooks/useClient";
import { formatDateToLongString } from "../../utils/date";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ActivityBriefDto } from "@growth-machine-llc/stridist-api-client";
import { unitSetLabelCoach } from "../../utils/units";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginBottom: spacing(2),
    padding: spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    height: "min-content",
    marginBottom: spacing(2),
    alignItems: "center",
  },

  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 18,
    color: palette.text.secondary,
    fontWeight: 500,
    display: "block",
    marginLeft: spacing(1),
  },

  slider: {
    width: "100%",
  },

  navigationBlock: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    marginTop: spacing(2),

    "& > .container": {
      marginTop: "-5vh",
    },
  },

  dots: {
    marginTop: 0,
  },

  button: {
    paddingRight: 0,
    display: "flex",
    alignItems: "center",
    borderRadius: spacing(0.5),
    height: spacing(4),
    "& svg": {
      width: spacing(2),
      height: spacing(2),
    },
  },

  buttonText: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: spacing(0.75),
  },
}));

export interface TrainingShareProps extends CardProps {
  endWorkout: any;
  workoutSection: any;
  workoutDate: ActivityBriefDto["date"];
}

export default function TrainingShare(props: TrainingShareProps) {
  const { endWorkout, workoutSection, className, workoutDate, ...other } =
    props;
  const s = useStyles();
  const theme = useTheme();
  const [trackEvent] = useAnalytics();
  const clientDisplayName = useClient()?.displayName;
  const user = useCurrentUser();
  const displayName = clientDisplayName || user.displayName;

  const [selectedPhoto, setSelectedPhoto] = React.useState<number>(0);
  const [statistics, setStatistics] = React.useState<WorkoutStat[]>([]);

  const media = endWorkout.media || [];

  const [canvases, setCanvases] = React.useState<
    React.RefObject<HTMLCanvasElement>[]
  >(Array(media.length + 2).fill(null));

  const slidesCount = media.length + (workoutSection?.[0]?.workout ? 2 : 1);

  const handlePageChange = React.useCallback(
    (page: number) => {
      setSelectedPhoto(
        selectedPhoto + page > 0 ? page % slidesCount : slidesCount - 1,
      );
    },
    [media.length, selectedPhoto],
  );

  React.useEffect(() => {
    const workout = workoutSection?.[0]?.workout;

    if (workout) {
      const weightExercises = workout.exercises.filter(
        (exercise: any) => exercise.typeSet === "WEIGHT",
      );
      const timeExercises = workout.exercises.filter(
        (exercise: any) => exercise.typeSet === "TIME",
      );
      const distanceExercises = workout.exercises.filter(
        (exercise: any) => exercise.typeSet === "DISTANCE",
      );

      let stats: WorkoutStat[] = [];

      const calculateVolume = (exercises: any[]) => {
        return Math.round(
          exercises.reduce(
            (acc: number, exercise: any) =>
              acc +
                exercise.sets
                  .filter(({ checked }) => checked)
                  .reduce(
                    (acc: number, set: any) =>
                      acc +
                      +set.weight * (+set.completedReps || +set.reps || 0),
                    0,
                  ) || 0,
            0,
          ),
        );
      };

      const volumes = [
        calculateVolume(weightExercises),
        calculateVolume(timeExercises),
        calculateVolume(distanceExercises),
      ];

      switch (volumes.indexOf(Math.max(...volumes))) {
        default:
        case 0:
          stats = [
            {
              icon: dumbbell,
              value: volumes[0],
              unit: unitSetLabelCoach(
                weightExercises[0].units || user.units,
                "WEIGHT",
                "s",
              ),
              title: "Volume",
            },
          ];
          break;
        case 1:
          stats = [
            {
              icon: timer,
              value: volumes[1],
              unit: unitSetLabelCoach(
                timeExercises[0].units || user.units,
                "TIME",
                "s",
              ),
              title: "Volume",
            },
          ];
          break;
        case 2:
          stats = [
            {
              icon: ruler,
              value: volumes[2],
              unit: unitSetLabelCoach(
                distanceExercises[0].units || user.units,
                "DISTANCE",
                "s",
              ),
              title: "Volume",
            },
          ];
          break;
      }
      stats.push({
        icon: repeat,
        title: "Reps",
        value: workout.exercises.reduce(
          (acc: number, exercise: any) =>
            acc +
            (exercise.result?.reduce(
              (acc: number, set: any) => acc + +set.reps,
              0,
            ) || 0),
          0,
        ),
        unit: "reps",
      });
      stats.push({
        icon: commit,
        title: "Sets",
        value: workout.exercises.reduce(
          (acc: number, exercise: any) => acc + (exercise.result?.length || 0),
          0,
        ),
        unit: "sets",
      });
      stats.push({
        icon: gymnastic,
        title: "Exercises",
        value: workout.exercises.length,
      });
      setStatistics(stats);
    } else {
      setStatistics([
        {
          icon: dumbbell,
          title: "Volume",
          value: 0,
          unit: "kg",
        },
        {
          icon: repeat,
          title: "Reps",
          value: 0,
          unit: "reps",
        },
        {
          icon: commit,
          title: "Sets",
          value: 0,
          unit: "sets",
        },
        {
          icon: gymnastic,
          title: "Exercises",
          value: 0,
        },
      ]);
    }
  }, [endWorkout]);

  const handleDownload = React.useCallback(() => {
    trackEvent("Workout share", {
      type:
        selectedPhoto < media.length
          ? "photo"
          : selectedPhoto === media.length
            ? "exercise_list"
            : "stats_grid",
    });
    downloadCanvas(
      canvases[selectedPhoto],
      `${workoutSection[0]?.workout?.title || "Workout"} - ${displayName} - ${workoutDate.format("DD/MM/YYYY")}`,
    );
  }, [canvases, selectedPhoto]);

  const slides = media.map((photo: any, index: number) => (
    <TrainingShareImage
      key={index}
      photoUrl={photo.url}
      setCanvas={(canvas) => {
        setCanvases((old) => {
          old[index] = canvas;
          return old;
        });
      }}
      stats={statistics}
    />
  ));

  if (workoutSection?.[0]?.workout) {
    slides.push(
      <TrainingShareImage
        key="workout"
        workout={workoutSection?.[0]?.workout}
        stats={statistics}
        setCanvas={(canvas) => {
          setCanvases((old) => {
            old[slides.length - 1] = canvas;
            return old;
          });
        }}
      />,
    );
  }

  slides.push(
    <TrainingShareImage
      key="stats"
      stats={statistics}
      setCanvas={(canvas) => {
        setCanvases((old) => {
          old[slides.length - 1] = canvas;
          return old;
        });
      }}
    />,
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Typography className={s.title} variant="body1">
          Workout highlights
        </Typography>
        <WorkoutButton
          icon={
            isMobileApp ? (
              <Share2 color={theme.palette.primary.main} />
            ) : (
              <Download color={theme.palette.primary.main} />
            )
          }
          text={isMobileApp ? "Share" : "Download"}
          style={s.button}
          styleText={s.buttonText}
          onClick={handleDownload}
        />
      </Box>
      {statistics.length > 0 && (
        <SwipeableViews
          index={selectedPhoto}
          onChangeIndex={handlePageChange}
          className={s.slider}
          slideStyle={{ overflow: "hidden" }}
        >
          {slides}
        </SwipeableViews>
      )}

      <Box className={s.navigationBlock}>
        <IconButton onClick={() => handlePageChange(selectedPhoto - 1)}>
          <ChevronLeftIcon />
        </IconButton>
        <Dots className={s.dots} activeDot={selectedPhoto} dots={slidesCount} />
        <IconButton onClick={() => handlePageChange(selectedPhoto + 1)}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Card>
  );
}
