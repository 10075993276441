import ThreadsService from "../../../services/ThreadsService";
import { IPaginatedListOfThreadsDto } from "@growth-machine-llc/stridist-api-client";
import {
  MESSAGES_THREAD_QUERY_KEY,
  MESSAGES_THREADS_QUERY_KEY,
} from "../../screen/MessagingScreen";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { useUpdateCurrentUserCache } from "../../../hooks/useUpdateCurrentUser";

/**
 * Custom hook for updating thread mutation.
 * This hook performs optimistic updates to the cache by archiving/reading the thread before the mutation is complete.
 * If the mutation fails, the cache is rolled back to the previous state.
 *
 */
export default function useUpdateThreadMutation() {
  const updateCurrentUserCache = useUpdateCurrentUserCache();
  return useOptimisticUpdateMutation({
    mutationFn: ThreadsService.updateThread,
    queryKey: [MESSAGES_THREADS_QUERY_KEY],
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (
        oldData: IPaginatedListOfThreadsDto,
        { threadId, archived, read },
      ) => {
        if (!oldData) return oldData;
        if (archived) {
          return {
            ...oldData,
            items: oldData.items.filter((item) => item.id !== threadId),
          };
        }
        if (read !== undefined) {
          return {
            ...oldData,
            items: oldData.items.map((item) => {
              if (item.id === threadId) {
                item.read = read;
              }
              return item;
            }),
          };
        }
        return oldData;
      },
    },
    options: {
      onSuccess: ({ messagesCount: difference }, { read, threadId }) => {
        // TODO_API_V2_NOTIFICATIONS: Since currently notifications counter represents a count of `messages`,
        // while UI logic depends on `threads` read/unread operations, need to update counter specifically.
        // Replace with `useUpdateNotificationsCounter` after logic changed to depends on `unreadThreads` count.
        updateCurrentUserCache((prev) => ({
          ...prev,
          ...(read === false && { notificationsActive: true }),
          unreadMessagesCount: Math.max(
            0,
            prev.unreadMessagesCount + difference,
          ),
          notificationUnreadCount: Math.max(
            0,
            prev.notificationUnreadCount + difference,
          ),
        }));
      },
    },
  });
}
