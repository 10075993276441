import clsx from "clsx";
import React from "react";
import { Box, Grow, ListItem, Typography } from "@mui/material";
import { ExerciseAsset } from "./types";

import { ThumbnailVideoBadge } from "./ThumbnailVideoBadge";
import makeStyles from "@mui/styles/makeStyles";

export const useStylesExerciseOptionStyles = makeStyles((theme) => ({
  optionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    gap: 3,
    padding: theme.spacing(0.5, 0),
  },

  contentContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    maxWidth: "100%",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },

  title: {
    fontWeight: 550,
    fontSize: 16,
    lineHeight: 1,
    wordBreak: "break-word",
  },

  instructions: {
    textAlign: "start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.grey[600],
  },
}));

interface Props {
  option: ExerciseAsset;
  classes?: {
    option?: string;
  };
  canEdit?: boolean;
}

const ExerciseAutocompleteOption: React.FC<
  Props & React.HTMLAttributes<HTMLElement>
> = ({ option, classes, canEdit, ...props }) => {
  const s = useStylesExerciseOptionStyles();
  return (
    <Grow in>
      <ListItem {...props} key={option.assetId}>
        <Box className={s.optionContainer}>
          <ThumbnailVideoBadge
            border={canEdit}
            size={48}
            video={option.exercise.video}
          />

          <Box className={s.contentContainer}>
            <Typography className={clsx(s.title, classes?.option)}>
              {option.exercise.title}
            </Typography>
            {option.exercise.instructions && (
              <Typography variant="caption" className={s.instructions}>
                {option.exercise.instructions}
              </Typography>
            )}
          </Box>
        </Box>
      </ListItem>
    </Grow>
  );
};

export default ExerciseAutocompleteOption;
