// Currently read only

import React, { createContext, useContext, ReactNode, useEffect } from "react";
import { CurriculumViewOptions } from "../components/screen/CurriculumEditScreen";
import { ProgramDetailsViewMode } from "../components/program/ProgramDetailsViewButton";
import { ProgramStartDateView } from "../constants";
import { useSidebar } from "./CurriculumSidebarContext";

export type ViewOptionsContextType = CurriculumViewOptions;

export const DEFAULT_VIEW_OPTIONS = {
  view: ProgramDetailsViewMode.CALENDAR,
  dateDetails: {
    view: ProgramStartDateView.ANY,
  },
  visibleWeeks: [1, 2, 3],
  showCovers: true,
  enableColors: true,
  enableSidebar: true,
};

const ViewOptionsContext =
  createContext<ViewOptionsContextType>(DEFAULT_VIEW_OPTIONS);

export const CurriculumViewOptionsProvider = ({
  children,
  viewOptions,
}: {
  children: ReactNode;
  viewOptions: ViewOptionsContextType;
}) => {
  const { closeSidebar } = useSidebar();

  //
  //
  // ADDITIONAL LOGIC

  // Close sidebar if enableSidebar turned off
  useEffect(() => {
    !viewOptions.enableSidebar && closeSidebar();
  }, [viewOptions.enableSidebar]);

  return (
    <ViewOptionsContext.Provider value={viewOptions}>
      {children}
    </ViewOptionsContext.Provider>
  );
};

export const useCurriculumViewOption = (): ViewOptionsContextType => {
  const context = useContext(ViewOptionsContext);
  if (!context) {
    throw new Error(
      "useCurriculumViewOption must be used within a CurriculumViewOptionsProvider",
    );
  }
  return context;
};
