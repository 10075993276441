import clsx from "clsx";
import React from "react";
import { BoxProps, TextField, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { ChangePasswordFormProps } from "../auth/ChangePasswordForm";
import { ActionButton } from "../button/ActionButton";
import PasswordField from "../fields/PasswordField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  field: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },

  submit: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

export interface ClientPortalChangePasswordFormProps
  extends ChangePasswordFormProps {}

export function ClientPortalChangePasswordForm(
  props: ClientPortalChangePasswordFormProps,
) {
  const { className, state, errors, onChange, loading, ...other } = props;
  const s = useStyles();
  const complete = !!state.password;

  return (
    <form className={clsx(s.root, className)} {...other}>
      <Typography sx={{ textAlign: "center" }}>
        Set your new password
      </Typography>
      <PasswordField
        className={s.field}
        name="password"
        label="New Password"
        value={state.password}
        onChange={onChange}
        disabled={loading}
        error={Boolean(errors.password)}
        helperText={errors.password || " "}
        fullWidth
      />

      <ActionButton
        className={s.submit}
        type="submit"
        disabled={loading || !complete}
        children="Change my password"
        fullWidth
      />
    </form>
  );
}
