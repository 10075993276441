import { IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { X } from "lucide-react";
import React, { ReactNode } from "react";

interface ISidebarTabTitleProps {
  title: string;
  showIcon: boolean;
  icon?: ReactNode;
  handleIconClick?: () => void;
}
const SidebarTabTitle = (props: ISidebarTabTitleProps) => {
  const { title, showIcon, icon = <X />, handleIconClick } = props;

  const theme = useTheme();
  return (
    <>
      <Typography
        variant="h6"
        sx={{
          marginTop: 3,
          marginBottom: 4,
          borderBottom: 2,
          borderColor: theme.palette.text.secondary,

          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {title}
        <IconButton
          onClick={handleIconClick}
          size="small"
          sx={{ visibility: showIcon ? "visible" : "hidden" }}
        >
          {icon}
        </IconButton>
      </Typography>
    </>
  );
};

export default SidebarTabTitle;
