import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Divider, Button, Typography } from "@mui/material";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as ManIcon } from "../../icons/man3.svg";
import { ReactComponent as WindowIcon } from "../../icons/window1.svg";
import { ReactComponent as CheckMarkCircleIcon } from "../../icons/CheckMarkCircle2.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin2.svg";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { getStatusColor, UserRole } from "../../constants";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import { UpdateTrialExpirationDateDialog } from "../dialog/UpdateTrialExpirationDateDialog";

import { AdminPaper } from "./AdminPaper";
import { AdminLabeledValue } from "./AdminLabeledValue";
import { AdminTag } from "./AdminTag";
import { useNavigate } from "react-router-dom";
import { UserInfoDto2 } from "@growth-machine-llc/stridist-api-client";
import { useUpdateUserMutation } from "./mutations/useUpdateUserMutation";
import { useDeleteUserMutation } from "./mutations/useDeleteUserMutation";
import { useCreateSubAccountMutation } from "./mutations/useCreateSubAccountMutation";
import { useDeleteSubAccountMutation } from "./mutations/useDeleteSubAccountMutation";
import { useToggleStopSubAccount } from "./mutations/useToggleStopSubAccount";
import { GHLAccountLink } from "./GoHighLevelLink";
import { StripeCustomerInfo } from "./StripeCustomerInfo";
import { GoHighLevelAccountState } from "./GoHighLevelLAccountState";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },

  paper: {
    flexGrow: 1,
  },

  info: {
    marginRight: theme.spacing(5),
  },

  actions: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },

  button: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },

  extraMarginTop: {
    marginTop: theme.spacing(2),
  },

  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,

    "&:hover": {
      backgroundColor: lighten(theme.palette.error.main, 0.2),
    },
  },

  statusLabel: {
    padding: theme.spacing(0.5, 1.5),
    borderRadius: 4,
    color: theme.palette.common.white,
    display: "inline-block",
    fontSize: "14px",
    fontWeight: 500,
  },
}));

export interface UserDetailsProps extends BoxProps {
  user: UserInfoDto2;
}

export function UserDetails(props: UserDetailsProps) {
  const { className, user, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [updateTrialDialogOpen, setUpdateTrialDialogOpen] =
    React.useState(false);
  const [addAsAdminDialogOpen, setAddAsAdminDialogOpen] = React.useState(false);
  const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] =
    React.useState(false);
  const [deleteSubAccountDialogOpen, setDeleteSubAccountDialogOpen] =
    React.useState(false);
  const { mutate: updateUser, isPending: updatingUser } =
    useUpdateUserMutation();

  const { mutate: deleteUser, isPending: deletingClient } =
    useDeleteUserMutation();
  const switchUser = useSwitchUser();

  const { mutate: createSubAccount, isPending: creatingSubAccount } =
    useCreateSubAccountMutation();

  const { mutate: deleteSubAccount, isPending: deletingSubAccount } =
    useDeleteSubAccountMutation();

  const { mutate: toggleStopSubAccount, isPending: togglingStopSubAccount } =
    useToggleStopSubAccount();

  const handleLogInAsUser = React.useCallback(() => {
    switchUser(+user.id);
  }, [switchUser, user.id]);

  const handleUpdateTrial = React.useCallback(() => {
    setUpdateTrialDialogOpen(true);
  }, []);

  const handleCloseUpdateTrial = React.useCallback(() => {
    setUpdateTrialDialogOpen(false);
  }, []);

  const handleAddAsAdmin = React.useCallback(() => {
    setAddAsAdminDialogOpen(true);
  }, []);

  const handleCloseAddAsAdmin = React.useCallback(() => {
    setAddAsAdminDialogOpen(false);
  }, []);

  const updateUserAdmin = React.useCallback(
    (admin: boolean) => {
      updateUser({ id: user.id, admin });
    },
    [updateUser, user.id],
  );

  const handleConfirmAddAsAdmin = React.useCallback(() => {
    updateUserAdmin(true);
    setAddAsAdminDialogOpen(false);
  }, [updateUserAdmin]);

  const handleRemoveAsAdmin = React.useCallback(() => {
    updateUserAdmin(false);
  }, [updateUserAdmin]);

  const handleDeleteAccount = React.useCallback(() => {
    setDeleteAccountDialogOpen(true);
  }, []);

  const handleCloseDeleteAccount = React.useCallback(() => {
    setDeleteAccountDialogOpen(false);
  }, []);

  const handleConfirmDeleteAccount = React.useCallback(() => {
    deleteUser(user.id);
  }, [deleteUser, user.id]);

  const handleCreateSubAccount = React.useCallback(() => {
    createSubAccount({ coachId: user.id });
  }, [createSubAccount, user.id]);

  const handleDeleteSubAccount = React.useCallback(() => {
    setDeleteSubAccountDialogOpen(true);
  }, []);

  const handleCloseDeleteSubAccount = React.useCallback(() => {
    setDeleteSubAccountDialogOpen(false);
  }, []);

  const handleConfirmDeleteSubAccount = React.useCallback(() => {
    deleteSubAccount(user.id);
    setDeleteSubAccountDialogOpen(false);
  }, [deleteSubAccount, user.id]);

  const handleToggleSubAccount = React.useCallback(
    (pause: boolean) => {
      toggleStopSubAccount({ id: user.id, pause });
    },
    [toggleStopSubAccount, user.id],
  );

  const disabled = updatingUser || deletingClient;
  const ghlDisabled =
    creatingSubAccount || deletingSubAccount || togglingStopSubAccount;

  const statusLabel = React.useMemo(() => {
    if (!user.subscriptionStatus) return "No status";

    const backgroundColor = getStatusColor(user.subscriptionStatus);
    return (
      <Typography
        component="span"
        className={clsx(s.statusLabel)}
        style={{ backgroundColor }}
      >
        {user.subscriptionStatus.toUpperCase()}
      </Typography>
    );
  }, [user.subscriptionStatus, s.statusLabel]);

  return (
    <>
      <Box className={clsx(s.root, className)} {...other}>
        <AdminPaper className={clsx(s.paper, s.info)} title="User Info">
          <AdminLabeledValue label="Email" value={user.email}>
            {user.admin && <AdminTag />}
          </AdminLabeledValue>

          <Divider />

          <AdminLabeledValue label="Role" value={user.role} />

          <Divider />

          <AdminLabeledValue label="Status" value={statusLabel} />

          <Divider />

          <AdminLabeledValue
            label="Subscription"
            value={
              <StripeCustomerInfo
                link={user.stripeCustomerLink}
                subscriptionPeriodEnd={user.subscriptionPeriodEnd}
              />
            }
          />

          {user.role === UserRole.COACH && (
            <>
              <Divider />

              <AdminLabeledValue
                label="GoHighLevel"
                value={<GHLAccountLink links={user?.goHighLevelAccountLink} />}
                valueComponent={
                  <GoHighLevelAccountState
                    user={user}
                    ghlDisabled={ghlDisabled}
                    handleCreateSubAccount={handleCreateSubAccount}
                    handleToggleSubAccount={handleToggleSubAccount}
                    handleDeleteSubAccount={handleDeleteSubAccount}
                  />
                }
              />
            </>
          )}

          <Divider />

          <Box className={s.actions}>
            <Button
              className={s.button}
              variant="text"
              startIcon={<ManIcon />}
              onClick={handleLogInAsUser}
              children="Log in as user"
              disabled={disabled}
            />

            <Button
              className={s.button}
              variant="text"
              startIcon={<WindowIcon />}
              onClick={handleUpdateTrial}
              children="Update trial expiration"
              disabled={disabled}
            />

            {user.admin ? (
              <Button
                className={s.button}
                variant="text"
                startIcon={<CheckMarkCircleIcon />}
                onClick={handleRemoveAsAdmin}
                children="Remove as Admin"
                disabled={disabled}
              />
            ) : user.role === UserRole.COACH ? (
              <Button
                className={s.button}
                variant="text"
                startIcon={<CheckMarkCircleIcon />}
                onClick={handleAddAsAdmin}
                children="Add as Admin"
                disabled={disabled}
              />
            ) : null}

            <Button
              className={clsx(s.button, s.deleteButton, s.extraMarginTop)}
              startIcon={<BinIcon />}
              onClick={handleDeleteAccount}
              children="Delete account"
              disabled={disabled}
            />
          </Box>
        </AdminPaper>
      </Box>

      <UpdateTrialExpirationDateDialog
        user={user}
        open={updateTrialDialogOpen}
        onClose={handleCloseUpdateTrial}
      />

      <ConfirmActionDialog
        title="Are you sure you want to make this user an admin?"
        open={addAsAdminDialogOpen}
        onClose={handleCloseAddAsAdmin}
        onCancel={handleCloseAddAsAdmin}
        onConfirm={handleConfirmAddAsAdmin}
      />

      <ConfirmActionDialog
        title={`Are you sure you want to delete this ${user.role.toLowerCase()}?`}
        description={
          user.role === UserRole.COACH &&
          "All their data and clients will be removed and cannot be recovered."
        }
        open={deleteAccountDialogOpen}
        disabled={disabled}
        onClose={handleCloseDeleteAccount}
        onCancel={handleCloseDeleteAccount}
        onConfirm={handleConfirmDeleteAccount}
      />

      <ConfirmActionDialog
        title="Are you sure you want to delete the GHL account?"
        description="This action cannot be undone. All associated data will be permanently removed."
        open={deleteSubAccountDialogOpen}
        disabled={deletingSubAccount}
        onClose={handleCloseDeleteSubAccount}
        onCancel={handleCloseDeleteSubAccount}
        onConfirm={handleConfirmDeleteSubAccount}
      />
    </>
  );
}
