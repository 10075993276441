import React from "react";
import { useEditor, useSelected, withReact } from "slate-react";
import { Range, createEditor } from "slate";
import { withHistory } from "slate-history";
import { CheckInComponentStatus, EditorElementView } from "./utils/editorUtils";
import { TElement } from "@udecode/plate-common";
import { EditorConfig } from "../editor/utils/configure";
import { CurriculumComponent } from "../../redux/types";

export function useOnlySelected() {
  const editor = useEditor();
  const selected =
    useSelected() && editor.selection && Range.isCollapsed(editor.selection);

  return selected;
}

export const EditorConfigContext = React.createContext<EditorConfig>(null);
export const EditorDisabledContext = React.createContext<boolean>(false);
export const EditorReadOnlyContext = React.createContext<boolean>(false);

export const CheckInComponentContext = React.createContext<{
  status?: CheckInComponentStatus;
  view?: EditorElementView;
  editingEl?: TElement | null;
  setEditingEl?: (value: TElement | null) => void;
}>(null);
export const EditorComponentItemDisabledContext = React.createContext<
  (type: string) => boolean
>(() => false);

export function useEditorConfig() {
  return React.useContext(EditorConfigContext);
}

export function useDisabled() {
  return React.useContext(EditorDisabledContext);
}

export function useReadOnly() {
  return React.useContext(EditorReadOnlyContext);
}

export function useCheckInComponent() {
  return React.useContext(CheckInComponentContext);
}

export function useItemDisabled() {
  return React.useContext(EditorComponentItemDisabledContext);
}

export function useNewEditor() {
  return React.useMemo(() => withHistory(withReact(createEditor())), []);
}

export const EditorProgramContext = React.createContext<{ programId: number }>(
  null,
);

export function useEditorProgram() {
  return React.useContext(EditorProgramContext);
}

export const EditorComponentContext =
  React.createContext<CurriculumComponent>(null);

export function useEditorComponent() {
  return React.useContext(EditorComponentContext);
}
