import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Container, Typography, Box, IconButton } from "@mui/material";

import { AppLayout } from "../app/AppLayout";
import { ReactComponent as CloseIcon } from "../../icons/Close.svg";

import { Link } from "../link/Link";
import { ProgramTemplatesList } from "../program/ProgramTemplatesList";

import { COACH_PROGRAMS_PUBLISHED_ROUTE } from "../../routes/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { DefaultLoader } from "../loading/DefaultLoader";
import ProgramTemplatesService from "../../services/ProgramTemplatesService";
import { ProgramTemplateCategory } from "@growth-machine-llc/stridist-api-client";
import { TextButton } from "../button/TextButton";
import { colorSystem } from "../../theme";
import { BackButton } from "../button/BackButton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1500,
  },

  header: {
    margin: theme.spacing(5, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize: 18,
    fontWeight: 500,
  },

  button: {
    backgroundColor: "transparent",
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(-2),

    "& span": {
      fontSize: 18,
      fontWeight: 600,
      margin: theme.spacing(0, 0.5),
    },
  },

  buttons: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1, -0.45, 1, -0.45),
  },

  filtersButton: {
    margin: theme.spacing(0, 0.45),
    backgroundColor: "transparent",
    borderWidth: 0,
    color: theme.palette.text.secondary,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 700,
    padding: theme.spacing(1.1, 2),
    lineHeight: "17px",
    "&:hover": {
      color: colorSystem.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  activeButton: {
    color: colorSystem.white,
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const PROGRAM_TEMPLATES_QUERY_KEY = "program-templates";

export function ProgramTemplatesScreen() {
  const backUrl = COACH_PROGRAMS_PUBLISHED_ROUTE;
  const s = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] =
    React.useState<ProgramTemplateCategory>(ProgramTemplateCategory.NONE);

  const categorySelected =
    activeButton === ProgramTemplateCategory.NONE ? null : activeButton;

  const { data: programTemplates, isLoading } = useQuery({
    queryKey: [PROGRAM_TEMPLATES_QUERY_KEY],
    queryFn: () => ProgramTemplatesService.getProgramTemplates(),
  });

  const filteredTemplates = programTemplates?.programTemplates.filter(
    (t) => !categorySelected || t.category === categorySelected,
  );

  return (
    <AppLayout
      appBar={false}
      appDrawer={false}
      trackInfo={{
        name: "Coach - Program Templates",
      }}
      hideUpgradeBanner={location.pathname.endsWith("/new")}
    >
      <Container className={s.root}>
        <Box className={s.header}>
          <Box>
            <BackButton onClick={() => navigate(backUrl)} />
            <Typography variant="h2" className={s.title} gutterBottom>
              Choose a program template
            </Typography>
            <Box className={s.buttons}>
              <Box className={s.buttons}>
                {Object.values(ProgramTemplateCategory)
                  .filter(
                    (category) =>
                      category === ProgramTemplateCategory.NONE ||
                      programTemplates?.programTemplates.some(
                        (t) => t.category === category,
                      ),
                  )
                  .map((c) => (
                    <TextButton
                      key={c}
                      className={clsx(s.filtersButton, {
                        [s.activeButton]: c === activeButton,
                      })}
                      onClick={() => setActiveButton(c)}
                    >
                      {c === ProgramTemplateCategory.NONE ? "All" : c}
                    </TextButton>
                  ))}
              </Box>
            </Box>
          </Box>

          <Link href={backUrl} underline="none">
            <IconButton className={s.button} size="large">
              <CloseIcon />
            </IconButton>
          </Link>
        </Box>

        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "80vh",
            }}
          >
            <DefaultLoader fillParent />
          </Box>
        ) : (
          <ProgramTemplatesList templates={filteredTemplates} />
        )}
      </Container>
    </AppLayout>
  );
}
