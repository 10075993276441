import { createContext } from "react";
import { InfoItemName } from "../components/menu/GetAnyHelpModal";

export interface IGetAnyHelpModalContextProps {
  getAnyHelpModal: boolean;
  setGetAnyHelpModal: (open: boolean, items?: InfoItemName[]) => void;
}

const GetAnyHelpModalContext = createContext<
  IGetAnyHelpModalContextProps | undefined
>(undefined);

export default GetAnyHelpModalContext;
