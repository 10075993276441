import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Grid2,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useClient } from "../../hooks/useClient";

import { getISODate } from "../../utils/date";
import { ClientProgramProgressCard } from "../card/ClientProgramProgressCard";
import { ClientMeasurementsCard } from "../card/ClientMeasurementsCard";
import { ClientNutritionCard } from "../client-nutrition-targets/ClientNutritionCard";
import { ClientGoalsCard } from "../card/ClientGoalsCard";
import { ClientProgressPhotosCard } from "../card/ClientProgressPhotosCard";
import { ClientFormsCard } from "../client-forms/ClientFormsCard";

import { ClientSummary } from "./ClientSummary";
import { ClientStepsCard } from "../client-steps/ClientStepsCard";
import { ClientBodyWeightCard } from "../client-body-weight/ClientBodyWeightCard";
import ClientWorkoutsExercises from "../client-workouts/ClientWorkoutsExercises";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TagDto } from "@growth-machine-llc/stridist-api-client";
import ClientDetailsPrograms from "./ClientDetailsPrograms";
import { ProgressDialog } from "../client-progress/ProgressDialog";
import WorkoutHighlights from "../card/WorkoutHighlights";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    maxWidth: breakpoints.values.slg,
  },
  button: {
    color: palette.common.white,
    borderColor: palette.common.white,
  },
  status: {
    marginTop: "auto",
    marginLeft: spacing(5),
    marginBottom: spacing(3),
  },
  bodyweightCard: {
    [breakpoints.up("lg")]: {
      height: 224,
    },
  },
}));

const today = getISODate();

type TagsProps = {
  node: {
    title: string;
    id: string;
  };
};

export enum ClientDetailsDialogMode {
  PROGRESS = "progress",
  STATS = "stats",
}

export interface ClientDetailsProps extends Omit<ContainerProps, "children"> {
  dialog?: ClientDetailsDialogMode;
  tags?: TagDto[];
}

const ClientDetails = (props: ClientDetailsProps) => {
  const { className, tags, dialog } = props;
  const navigate = useNavigate();
  const client = useClient();

  const s = useStyles();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));

  return (
    <Container className={clsx(s.root, className)}>
      <Grid2 sx={{ mb: 3 }} container spacing={3}>
        <Grid2
          size={{
            xs: 12,
            lg: 12,
          }}
        >
          <ClientSummary client={client} />
        </Grid2>

        <WorkoutHighlights clientId={client.id} />

        <ClientDetailsPrograms clientId={client.id} />

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <ClientFormsCard clientId={client.id} />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <ClientStepsCard client={{ id: client.id, units: client.units }} />
        </Grid2>

        <Grid2 size={12}>
          <ClientNutritionCard
            client={{ clientId: client.id, username: client.username }}
          />
        </Grid2>

        <Grid2 size={12}>
          <ClientGoalsCard clientId={client.id} />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <ClientBodyWeightCard
            clientId={client.id}
            clientUnits={client.units}
          />
        </Grid2>

        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <ClientMeasurementsCard clientId={client.id} units={client.units} />
        </Grid2>

        <Grid2 size={12}>
          <ClientProgressPhotosCard clientId={client.id} />
        </Grid2>

        <Grid2 size={12}>
          <ClientWorkoutsExercises typeBox="workout" clientId={client.id} />
        </Grid2>

        {!smUp && (
          <Grid2 size={12}>
            <ClientWorkoutsExercises typeBox="exercises" clientId={client.id} />
          </Grid2>
        )}
      </Grid2>
      <ProgressDialog
        open={dialog === ClientDetailsDialogMode.PROGRESS}
        onClose={() => navigate(-1)}
        clientId={client.id}
      />
    </Container>
  );
};

export default ClientDetails;
