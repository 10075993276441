import clsx from "clsx";
import React from "react";
import {
  Box,
  CardMedia,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  SortableListItem,
  SortableListItemProps,
} from "../sortable-list/SortableListItem";

import {
  ProgramTemplateDto,
  ProgramTemplateCategory,
  ProgramTemplatesVm,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingButtonWithStatus } from "../button/LoadingButtonWithStatus";
import { ADMIN_TEMPLATES_KEY_QUERY_KEY } from "./ProgramTemplatesPage";
import AdminService from "../../services/AdminService";

const useStyles = makeStyles((theme) => ({
  root: { margin: 0, width: "auto" },

  card: {
    width: 350,
    height: 555,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
  },

  name: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.common.black,
  },

  date: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
  },

  media: {
    width: "100%",
    height: 200,
  },

  content: {
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  category: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.secondary.main}74`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    fontSize: 14,
    fontWeight: 700,
    position: "absolute",
    left: 0,
    top: theme.spacing(3.5),
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
    color: theme.palette.secondary.main,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  description: {
    maxHeight: "4.5rem",
    overflow: "hidden",
    "& p": {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  templateCategoryOptions: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  saveButton: {
    marginTop: theme.spacing(2),
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

export interface TemplateListItem
  extends SortableListItemProps<ProgramTemplateDto> {}

export function TemplateListItem(props: TemplateListItem) {
  const { className, ...other } = props;
  const {
    option: { name, length, image, used, description, category: category },
  } = other;
  const s = useStyles();
  const queryClient = useQueryClient();
  const { mutate: updateProgramTemplate, status } = useMutation({
    mutationKey: ["update-program-template"],
    mutationFn: AdminService.updateProgramTemplate,
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        [ADMIN_TEMPLATES_KEY_QUERY_KEY],
        (oldData: ProgramTemplatesVm) => {
          if (!oldData.programTemplates) return oldData;

          const updatedProgramTemplates = oldData?.programTemplates?.map(
            (template) =>
              template.id === variables.id
                ? { ...template, category: selectedCategory }
                : template,
          );

          return {
            ...oldData,
            programTemplates: updatedProgramTemplates,
          };
        },
      );
    },
  });

  const [selectedCategory, setSelectedCategory] = React.useState(category);

  const isSaveEnabled = selectedCategory !== category;

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(event.target.value as ProgramTemplateCategory);
  };

  return (
    <SortableListItem
      className={clsx(s.root, className)}
      divider={false}
      {...other}
    >
      <Box className={s.card}>
        <CardMedia className={s.media} image={image} />
        <Box className={s.content}>
          <Typography className={s.title} variant="subtitle1">
            {name}
          </Typography>
          <Typography className={s.subtitle}>
            {length} week{length > 1 && "s"}
            {" • "}
            Used {used} time{used > 1 && "s"}
          </Typography>
          <Box className={s.description}>
            <Typography>{description}</Typography>
          </Box>
        </Box>
        <FormControl>
          <FormLabel id="category">Category</FormLabel>
          <RadioGroup
            aria-labelledby="category"
            className={s.templateCategoryOptions}
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            {Object.values(ProgramTemplateCategory).map((c) => (
              <FormControlLabel
                key={c}
                value={c}
                control={<Radio />}
                label={c}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <LoadingButtonWithStatus
          disabled={!isSaveEnabled}
          status={status}
          fullWidth
          variant="contained"
          onClick={() =>
            updateProgramTemplate({
              id: other.option.id,
              category: selectedCategory,
            })
          }
        >
          Save
        </LoadingButtonWithStatus>
      </Box>
    </SortableListItem>
  );
}
