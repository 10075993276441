import React from "react";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Slide,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack } from "@mui/icons-material";

import { ComponentRepeatValues, ComponentType } from "../../constants";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ReactComponent as FlashIcon } from "../../icons/streamline-icon-flash.svg";
import { Menu, MenuProps } from "../menu/Menu";
import { SelectComponentIcons } from "../program-component/SelectComponentIcons";
import { SelectComponentWeek } from "../program-component/SelectComponentWeek";
import { CopyComponentDialog } from "../dialog/CopyComponentDialog";
import { ChangeComponentCoverDialog } from "../dialog/ChangeComponentCoverDialog";
import { useCopyPasteComponent } from "../../contexts/CopyPasteComponentProvider";

import {
  LockOpen,
  Lock,
  CalendarDays,
  CalendarRange,
  CalendarClock,
  CopyPlus,
  CopySlash,
  Copy,
  SquarePen,
  Eye,
  Image,
  Archive,
  SquareSplitVertical,
  FlagTriangleRight,
  CheckCheck,
} from "lucide-react";
import { ProgramDetailsViewMode } from "../program/ProgramDetailsViewButton";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import {
  archiveComponent,
  duplicateComponent,
  removeComponent,
  updateComponentWeek,
  updateComponentCover,
  lockComponent,
  updateComponentDays,
  duplicateComponentToWeek,
  splitRepeat,
  removeOverride,
} from "../../redux/curriculum/curriculum-slice";
import { CurriculumComponent } from "../../redux/types";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import ComponentMenuItem from "./WeekComponentMenu/ComponentMenuItem";
import SectionTitle from "./WeekComponentMenu/SectionTitle";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import { SidebarTabs } from "../../constants";
import { SelectComponentDay } from "../program-component/SelectComponentDay";
import { selectWeekNumberByWeekId } from "../../redux/curriculum/selectors/curriculum";
import {
  isOverrrideCreationEnabled,
  overrideCreationDisabledReason,
} from "../program-component/OverrideCreationDialog";
import CreateOverride from "../program-component/CreateOverride";
import { useProgramWeekGetNumberById } from "../../hooks/useProgramWeeks";
import {
  DeleteConfirmationMode,
  useDeleteConfirmation,
} from "../program/modals/DeleteConfirmation.tsx/DeleteConfirmationModalContext";
import { useDialog } from "../../contexts/CurriculumComponentDialogContext";
import { publishComponent } from "../../redux/curriculum/curriculum-slice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  back: {
    "& .MuiListItemText-primary": {
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: 500,
    },
  },

  paper: {
    minWidth: theme.spacing(40),
    borderRadius: theme.spacing(1.5),
  },

  selectItems: {
    display: "flex",
    gap: 2,
    justifyContent: "space-between",
  },
}));

export enum MenuType {
  ICONS = "icons",
  CHANGE_WEEK = "change-week",
  CHANGE_DAY = "change-day",
  COPY_TO_WEEK = "copy-to-week",
}

export interface WeekComponentMenuProps extends MenuProps {
  componentData: CurriculumComponent;
  view: ProgramDetailsViewMode;
  containerWeekId: number;
  weekNumber?: number;
  dayIndex?: number;
}

export function WeekComponentMenu(props: WeekComponentMenuProps) {
  const dispatch = useCurriculumDispatch();
  const {
    componentData,
    onClose,
    view,
    containerWeekId,
    weekNumber,
    dayIndex,
    ...other
  } = props;
  const component = componentData;
  const s = useStyles();
  const { showToastAlert } = useToastAlert();
  const { openSidebar, closeSidebar } = useSidebar();
  const { openDialog } = useDialog();
  const { openDialog: openDeleteConfirmationDialog, popupState } =
    useDeleteConfirmation();

  const [menu, setMenu] = React.useState<MenuType>(null);
  const [overrideDialog, setOverrideDialog] = React.useState(false);
  const [openCopyDialog, setOpenCopyDialog] = React.useState(false);
  const [openChangeCoverDialog, setOpenChangeCoverDialog] =
    React.useState(false);

  const { setCopiedComponentId } = useCopyPasteComponent();

  const containerWeekNumber = useProgramWeekGetNumberById(containerWeekId);

  const componentId = component.id;
  const weekId = component.weekId;

  const componentRepeatsSince = useCurriculumSelector(
    selectWeekNumberByWeekId(weekId),
  );

  const componentStatusData = {
    status: component.status,
    previousStatus: component.previousStatus,
  };

  const IS_CALENDAR = view === ProgramDetailsViewMode.CALENDAR;
  const IS_SHEETS = view === ProgramDetailsViewMode.SPREADSHEET;
  const IS_ARCHIVED = component.status === "ARCHIVED";
  const IS_OVERRIDE = component.baseComponentId !== null;
  const IS_LESSON = component.type === ComponentType.LESSON;
  const IS_MESSAGE = component.type === ComponentType.MESSAGE;
  const IS_WORKOUT = component.type === ComponentType.WORKOUT;
  const IS_OVERRIDING_ENABLED = isOverrrideCreationEnabled(component);

  //
  // MENU ACTIONS

  const closeMenu = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const handleChangeMenu = React.useCallback((event) => {
    const {
      dataset: { menu },
    } = event.currentTarget;

    setMenu(menu);
  }, []);

  //
  // DIALOGS ACTIONS

  const handleCopyToProgramDialogOpen = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setOpenCopyDialog(true);
    },
    [],
  );

  const handleOverrideDialogClose = React.useCallback(() => {
    setOverrideDialog(false);
    onClose(null, "backdropClick");
  }, [onClose, setOverrideDialog]);

  const handleCopyDialogClose = React.useCallback(
    (event) => {
      setOpenCopyDialog(false);
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  const handleChangeCoverDialogOpen = React.useCallback(() => {
    setOpenChangeCoverDialog(true);
  }, []);

  const handleChangeCoverDialogClose = React.useCallback(
    (event) => {
      setOpenChangeCoverDialog(false);
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  //
  // COMPONENT ACTIONS

  const handleDuplicateClick = () => {
    closeMenu();
    dispatch(duplicateComponent({ weekId, componentId }));
  };

  const handleSplitRepeatClick = () => {
    closeMenu();
    const toastId = showToastAlert("loading", {
      message: "Splitting component...",
    });
    dispatch(splitRepeat({ weekId: containerWeekId, componentId, toastId }));
  };

  const handleDeleteCustomDay = () => {
    openDeleteConfirmationDialog(DeleteConfirmationMode.COMPONENT, () => {
      closeSidebar();
      closeMenu();
      dispatch(removeOverride({ overrideId: componentId }));
    });
  };

  const handlePublishClick = () => {
    closeMenu();
    dispatch(
      publishComponent({
        componentId,
        title: component.title,
        content: component.content,
      }),
    );
  };

  const handleToggleArchivedClick = () => {
    closeMenu();
    dispatch(archiveComponent({ weekId, componentId, componentStatusData }));
  };

  const handleRemoveClick = () => {
    openDeleteConfirmationDialog(DeleteConfirmationMode.COMPONENT, () => {
      closeSidebar();
      closeMenu();
      dispatch(removeComponent({ weekId, componentId }));
    });
  };

  const handleDayChange = (newComponentDays: string) => {
    closeMenu();
    dispatch(updateComponentDays({ componentId, days: newComponentDays }));
  };

  const handleWeekChange = (newWeekId: number) => {
    closeMenu();
    dispatch(updateComponentWeek({ componentId, newWeekId }));
  };

  const handleCopyToWeek = (newWeekId: number) => {
    closeMenu();
    dispatch(duplicateComponentToWeek({ componentId, weekId: newWeekId }));
  };

  const handleCopy = (event) => {
    showToastAlert("info", {
      message: "Hold shift to paste to multiple days",
    });

    setCopiedComponentId(component.id);
    onClose(event, "backdropClick");
  };

  const handleSaveChangeCover = (image: string) => {
    dispatch(updateComponentCover({ componentId, newImage: image }));
  };

  const handleLockClick = () => {
    dispatch(lockComponent({ componentId, currentState: component.locked }));
  };

  const handleRescheduleClick = (event) => {
    openSidebar(component.slug, SidebarTabs.SCHEDULE);
    onClose(event, "backdropClick");
  };

  const handlePreviewClick = (event) => {
    openSidebar(component.slug, SidebarTabs.PREVIEW);
    onClose(event, "backdropClick");
  };

  const handleEditClick = () => {
    let dayParams = undefined;
    if (weekNumber && dayIndex !== undefined) {
      dayParams = {
        week: weekNumber.toString(),
        day: (dayIndex + 1).toString(),
      };
    }

    openDialog(component.slug, dayParams);
    onClose({}, "backdropClick");
  };

  const handleOverrideClick = () => {
    setOverrideDialog(true);
  };

  //
  // UI ELEMENTS

  const getSecondLevelMenuTitle = (menu: MenuType) => {
    switch (menu) {
      case MenuType.ICONS:
        return "Select icon";
      case MenuType.CHANGE_DAY:
        return "Select days";
      case MenuType.CHANGE_WEEK:
      case MenuType.COPY_TO_WEEK:
        return "Select week";

      default:
        return "";
    }
  };

  const getSecondLevelMenuItems = (menu: MenuType) => {
    switch (menu) {
      case MenuType.ICONS:
        return (
          <SelectComponentIcons
            componentData={component}
            onSubmit={closeMenu}
          />
        );
      case MenuType.CHANGE_DAY:
        return (
          <SelectComponentDay
            currentDays={component.days}
            onChange={handleDayChange}
            singleDayMode={IS_LESSON || IS_OVERRIDE}
          />
        );
      case MenuType.CHANGE_WEEK:
        return (
          <SelectComponentWeek
            weekId={component.weekId}
            onChange={handleWeekChange}
          />
        );

      case MenuType.COPY_TO_WEEK:
        return (
          <SelectComponentWeek
            weekId={component.weekId}
            onChange={handleCopyToWeek}
          />
        );

      default:
        return "";
    }
  };

  const publishedNoPendingChanges =
    component.status == "PUBLISHED" && !component.draftExist;

  const showSplitAction = !IS_MESSAGE && containerWeekId != weekId;

  const splitText = `Split at week ${containerWeekNumber}`;

  const splitTooltipText =
    `Create ${component.type.toString().toLowerCase()} copy ` +
    `starting Week ${containerWeekNumber} and stop ` +
    `current ${component.type.toString().toLowerCase()} at Week ${containerWeekNumber - ComponentRepeatValues[component.repeat]}`;

  let initialSelectedDay = null;
  if (containerWeekNumber) {
    initialSelectedDay = {
      week: containerWeekNumber,
      day: JSON.parse(component.days).findIndex((day) => day) + 1,
    };
  }

  return overrideDialog ? (
    <CreateOverride
      component={component}
      onClose={handleOverrideDialogClose}
      initialSelectedDay={initialSelectedDay}
    />
  ) : openCopyDialog ? (
    <CopyComponentDialog
      componentData={component}
      open
      onClose={handleCopyDialogClose}
    />
  ) : openChangeCoverDialog ? (
    <ChangeComponentCoverDialog
      componentData={component}
      open
      onClose={handleChangeCoverDialogClose}
      onCoverChange={handleSaveChangeCover}
    />
  ) : (
    <Menu
      className={s.root}
      classes={{ paper: s.paper }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      variant="menu"
      onClose={onClose}
      {...other}
    >
      {!menu ? (
        <Box>
          <SectionTitle text="Move" divider={false} />

          <ComponentMenuItem
            text={"Change days"}
            icon={<CalendarDays />}
            onClick={handleChangeMenu}
            dataMenu={MenuType.CHANGE_DAY}
          />
          <ComponentMenuItem
            text={"Change start week"}
            icon={<CalendarRange />}
            onClick={handleChangeMenu}
            dataMenu={MenuType.CHANGE_WEEK}
          />

          <SectionTitle text="Schedule" />

          <ComponentMenuItem
            text={"Repeat & customize"}
            icon={<CalendarClock />}
            onClick={handleRescheduleClick}
          />

          {IS_OVERRIDE ? (
            <ComponentMenuItem
              text={"Delete custom day"}
              icon={<FlagTriangleRight />}
              onClick={(event) => {
                handleDeleteCustomDay();
                popupState.open(event);
              }}
              tooltipTitle={`Discard customization for this day of the ${component.type.toString().toLowerCase()} only`}
            />
          ) : (
            <ComponentMenuItem
              text={"Add custom day"}
              icon={<FlagTriangleRight />}
              onClick={handleOverrideClick}
              disabled={!IS_OVERRIDING_ENABLED}
              tooltipTitle={
                IS_OVERRIDING_ENABLED
                  ? `Change content for specific day of the ${component.type.toString().toLowerCase()} only`
                  : overrideCreationDisabledReason(component)
              }
            />
          )}

          {showSplitAction && (
            <ComponentMenuItem
              text={splitText}
              icon={<SquareSplitVertical />}
              onClick={handleSplitRepeatClick}
              tooltipTitle={splitTooltipText}
            />
          )}

          {!IS_OVERRIDE && (
            <>
              <SectionTitle text="Copy" />

              {(IS_CALENDAR || IS_SHEETS) && (
                <ComponentMenuItem
                  text={"Paste"}
                  icon={<FlashIcon />}
                  onClick={handleCopy}
                />
              )}

              <ComponentMenuItem
                text={"Duplicate"}
                icon={<Copy />}
                onClick={handleDuplicateClick}
              />
              <ComponentMenuItem
                text={"To another week"}
                icon={<CopyPlus />}
                onClick={handleChangeMenu}
                dataMenu={MenuType.COPY_TO_WEEK}
              />
              <ComponentMenuItem
                text={"To another program"}
                icon={<CopySlash />}
                onClick={handleCopyToProgramDialogOpen}
              />
            </>
          )}

          <SectionTitle text="Manage" />

          <ComponentMenuItem
            text={"Edit"}
            icon={<SquarePen />}
            onClick={handleEditClick}
          />

          <ComponentMenuItem
            text={"Preview"}
            icon={<Eye />}
            onClick={handlePreviewClick}
          />

          {!IS_ARCHIVED && (
            <ComponentMenuItem
              text={
                publishedNoPendingChanges
                  ? // for disabled case with "No pending changes." tooltip
                    "Publish"
                  : component.status == "DRAFT"
                    ? "Publish draft"
                    : "Publish pending changes"
              }
              icon={<CheckCheck />}
              onClick={handlePublishClick}
              disabled={publishedNoPendingChanges}
              tooltipTitle={
                publishedNoPendingChanges
                  ? "No pending changes."
                  : component.status == "DRAFT"
                    ? "Publish to make visible to clients."
                    : "Publish to make pending changes visible to clients."
              }
            />
          )}
          {!IS_MESSAGE && (
            <ComponentMenuItem
              text={"Change cover image"}
              icon={<Image />}
              onClick={handleChangeCoverDialogOpen}
            />
          )}
          <ComponentMenuItem
            text={component.locked ? "Unlock content" : "Lock content"}
            icon={component.locked ? <LockOpen /> : <Lock />}
            onClick={handleLockClick}
            tooltipTitle="Prevent clients from viewing this until the scheduled date. Lock/unlock all components in settings."
          />
          {!IS_OVERRIDE && (
            <ComponentMenuItem
              text={IS_ARCHIVED ? "Restore" : "Archive"}
              icon={<Archive />}
              onClick={handleToggleArchivedClick}
            />
          )}
          {!IS_OVERRIDE && IS_ARCHIVED && (
            <ComponentMenuItem
              text="Delete permanently"
              icon={<BinIcon />}
              onClick={(event) => {
                handleRemoveClick();
                popupState.open(event);
              }}
            />
          )}
        </Box>
      ) : (
        <Slide in={true} direction="left" mountOnEnter unmountOnExit>
          <Box>
            <MenuItem className={s.back} onClick={handleChangeMenu}>
              <ListItemIcon children={<ArrowBack />} />
              <ListItemText primary={getSecondLevelMenuTitle(menu)} />
            </MenuItem>
            <Divider />
            {getSecondLevelMenuItems(menu)}
          </Box>
        </Slide>
      )}
    </Menu>
  );
}
