import React from "react";
import { CurriculumComponent } from "../../redux/types";
import OverrideCreationDialog from "./OverrideCreationDialog";
import { useProgramWeeks } from "../../hooks/useProgramWeeks";
import { useCurriculumDispatch } from "../../redux/hooks";
import { overrideComponent } from "../../redux/curriculum/curriculum-slice";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import { SidebarTabs } from "../../constants";

interface CreateOverrideProps {
  component: CurriculumComponent;
  onClose: () => void;
  initialSelectedDay?: { week: number; day: number } | null;
}

const CreateOverride = ({
  component,
  onClose,
  initialSelectedDay,
}: CreateOverrideProps) => {
  const dispatch = useCurriculumDispatch();
  const { openSidebar } = useSidebar();
  const programWeeks = useProgramWeeks();

  const [dialogOpen, setDialogOpen] = React.useState(true);

  const handleClose = () => {
    setDialogOpen(false);
    onClose();
  };

  const handleConfirmPublishOverride = (selected: {
    week: number;
    day: number;
  }) => {
    const overrideWeekId = programWeeks.find(
      (w) => w.week === selected.week,
    )?.id;

    const overrideWeekDayPattern = JSON.stringify(
      Array.from({ length: 7 }, (_, i) => i + 1 === selected.day),
    );

    dispatch(
      overrideComponent({
        baseComponentId: component.id,
        weekId: overrideWeekId,
        days: overrideWeekDayPattern,
        data: {},
        noDiscard: true,
      }),
    );
  };

  const handleOverrideReady = (overrideSlug: string) => {
    openSidebar(overrideSlug, SidebarTabs.SCHEDULE);
    onClose();
    setDialogOpen(false);
  };

  return (
    <OverrideCreationDialog
      component={component}
      open={dialogOpen}
      onClose={handleClose}
      onConfirmPublishOverride={handleConfirmPublishOverride}
      initialSelectedDay={initialSelectedDay}
      onOverrideReady={handleOverrideReady}
    />
  );
};

export default CreateOverride;
