import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MenuRounded } from "@mui/icons-material";

import { UserRole } from "../../constants";
import { Link } from "../link/Link";
import { GroupNavItem } from "../groups/GroupNavItem";

import { menuItems } from "./constants";
import { CurrentUserBox } from "./CurrentUserBox";
import { AppLogo } from "./AppLogo";
import {
  CLIENT_HOME_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
} from "../../routes/routes";
import { useCurrentUserRole } from "../../hooks/useCurrentUser";
import { isDrawerItemSelected } from "./common";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.llg,
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    textDecoration: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  link: {
    lineHeight: 0,
  },

  logo: {
    width: "100%",
    height: "auto",
    maxWidth: 60,
    maxHeight: 42,
  },

  menuIcon: {
    fontSize: 32,
    marginLeft: theme.spacing(1),
    cursor: "pointer",
  },

  menu: {
    minWidth: 260,
    maxWidth: 300,
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(-6),
  },

  itemLink: {
    textDecoration: "none !important",
    "&:hover": {
      textDecoration: "none !important",
    },
  },

  menuItem: {
    paddingLeft: theme.spacing(4),
  },

  icon: {
    color: theme.palette.text.secondary,
    "&$active": {
      "& *": {
        stroke: theme.palette.primary.main,
      },
    },
  },

  itemText: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    padding: theme.spacing(1.25, 0, 1.25, 1.75),

    "&$active": {
      color: theme.palette.primary.main,
    },
  },

  divider: {
    marginTop: theme.spacing(5),
  },

  active: {},
}));

export interface AppBarNavProps extends ContainerProps {}

export function AppBarNav(props: AppBarNavProps) {
  const { className, children, ...other } = props;
  const s = useStyles();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const mode = useCurrentUserRole();

  const items = React.useMemo(
    () => (mode === UserRole.COACH ? menuItems.coach : menuItems.client),
    [mode],
  );

  const openMenu = React.useCallback((event) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = React.useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleItemClick = React.useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  const handleUserMenuClose = React.useCallback((closeAppDrawer: boolean) => {
    if (closeAppDrawer) {
      closeMenu();
    }
  }, []);

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Link
        className={s.link}
        href={
          mode === UserRole.CLIENT ? HOME_ROUTE : COACH_PROGRAMS_PUBLISHED_ROUTE
        }
        children={<AppLogo size={42} className={s.logo} />}
      />

      <MenuRounded className={s.menuIcon} onClick={openMenu} />

      {children}

      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        PaperProps={{
          className: s.menu,
        }}
      >
        {items.map(({ text, link, Icon }) =>
          link.includes("/groups") ? (
            <GroupNavItem key={link} />
          ) : (
            <Link
              className={s.itemLink}
              key={link}
              href={link}
              target={link.includes("marketing") && "_blank"}
              onClick={handleItemClick}
            >
              <MenuItem className={s.menuItem}>
                <Icon
                  className={clsx({
                    [s.icon]: true,
                    [s.active]: isDrawerItemSelected(link, mode),
                  })}
                />
                <Typography
                  className={clsx({
                    [s.itemText]: true,
                    [s.active]: isDrawerItemSelected(link, mode),
                  })}
                  children={text}
                />
              </MenuItem>
            </Link>
          ),
        )}

        <Divider className={s.divider} />

        <CurrentUserBox onClose={handleUserMenuClose} />
      </Menu>
    </Container>
  );
}
