import clsx from "clsx";
import React from "react";
import { List, ListProps, Box, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AddCircleOutline } from "@mui/icons-material";
import {
  WeekComponentListItem,
  WeekComponentListItemProps,
} from "../program-week/WeekComponentListItem";
import { ComponentType } from "../../constants";
import { getComponentSpecifics } from "../../utils/component";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { useCurriculumDispatch } from "../../redux/hooks";
import { addComponent } from "../../redux/curriculum/curriculum-slice";
import { CurriculumComponent } from "../../redux/types";
import { ComponentClickHandlerType } from "../program/ProgramDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0, 0, 0),
  },

  header: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top",
  },

  title: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    fontSize: 16,
  },

  list: {
    padding: 0,
  },

  listItem: {},

  button: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),

    fontSize: 16,
  },
}));

export interface WeekComponentListProps extends ListProps {
  components: CurriculumComponent[];
  componentType: ComponentType;
  positions?: string[];
  week: number;
  onMove?: WeekComponentListItemProps["onMove"];
  onMoveEnd?: WeekComponentListItemProps["onMoveEnd"];
  weekId: number;
  componentClickHandler: ComponentClickHandlerType;
  componentDoubleClickHandler: ComponentClickHandlerType;
}

export function WeekComponentList(props: WeekComponentListProps) {
  const {
    className,
    components,
    week,
    componentType,
    positions = [],
    onMove,
    onMoveEnd,
    weekId,
    componentClickHandler,
    componentDoubleClickHandler,
    ...other
  } = props;
  const dispatch = useCurriculumDispatch();

  const s = useStyles();
  const mobile = useMediaMobile();

  const handleAddComponent = () => {
    dispatch(addComponent({ weekId, componentType }));
  };

  const sortedComponents = React.useMemo(
    () =>
      positions
        .map((id, index) => ({
          component: components.find((it) => String(it.id) == String(id)),
          index,
        }))
        .filter(({ component }) => Boolean(component)),
    [components, positions],
  );

  const { typeName } = React.useMemo(() => {
    return getComponentSpecifics(componentType as ComponentType);
  }, [componentType]);

  const total = sortedComponents.length;

  return (
    <Box className={clsx(s.root, className)}>
      <Box className={s.header}>
        <Typography variant="h5" className={s.title}>
          {total} {typeName}
          {total === 1 ? "" : "s"}
        </Typography>

        {!mobile && (
          <Button
            className={s.button}
            endIcon={<AddCircleOutline />}
            onClick={handleAddComponent}
          >
            Add new {typeName.toLocaleLowerCase()}
          </Button>
        )}
      </Box>

      <List className={s.list} {...other}>
        {sortedComponents.map(({ component, index }) => (
          <WeekComponentListItem
            key={component.id}
            className={s.listItem}
            componentData={component}
            onMove={onMove}
            onMoveEnd={onMoveEnd}
            index={index}
            week={week}
            id={String(component.id)}
            weekId={weekId}
            componentClickHandler={componentClickHandler}
            componentDoubleClickHandler={componentDoubleClickHandler}
          />
        ))}
      </List>
    </Box>
  );
}
