import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useAnalytics } from "../../hooks/useAnalytics";
import { colorSystem } from "../../theme";
import { AppLogo } from "../app/AppLogo";
import { LocalStorageKeys, UserRole } from "../../constants";
import {
  useAuthTokenSignIn,
  AuthTokenSignInResponse,
} from "../../hooks/useAuthTokenSignIn";
import { iOSMobileApp } from "../../utils/mobile";

import { LoginFormX, LoginCredentials } from "./LoginForm";
import { useSignInMutation } from "./mutations/SignIn";
import firebase from "firebase";
import {
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
} from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { polyfillCSS } from "../../utils/css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  logo: {
    marginBottom: theme.spacing(15.5),
  },
}));

export interface LoginScreenProps extends BoxProps {
  returnUrl?: string;
}

export function LoginScreen(props: LoginScreenProps) {
  const { className, returnUrl, ...other } = props;
  const s = useStyles();
  const [credentials, setCredentials] = React.useState<LoginCredentials>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState<LoginCredentials>({
    email: "",
    password: "",
  });
  const [noAccountEmail, setNoAccountEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [trackEvent] = useAnalytics();
  const onError = useGenericErrorHandler({
    disableDefaultSnackbar: iOSMobileApp,
  });
  const { mutate: signIn, isPending: signingIn } = useSignInMutation();

  const navigate = useNavigate();

  const signInWithIdToken = React.useCallback(
    (error, response: AuthTokenSignInResponse) => {
      if (error) {
        if (error.code === "auth/user-not-found") {
          setNoAccountEmail(
            response.data.email ||
              `your ${(response?.data?.provider ?? "").toLowerCase()} account`,
          );
        } else {
          onError(error);
        }
      } else {
        signIn(
          {
            idToken: response.fireBaseToken,
          },
          {
            onSuccess: (userRole) => {
              localStorage.removeItem(LocalStorageKeys.FAVICON_LOGO_URL);
              trackEvent("Login");
              let navigateTo: string;
              if (returnUrl) {
                navigateTo = returnUrl;
              } else {
                navigateTo =
                  userRole === UserRole.CLIENT
                    ? HOME_ROUTE
                    : COACH_PROGRAMS_PUBLISHED_ROUTE;
              }
              navigate(navigateTo);
            },
          },
        );
      }
    },
    [onError, returnUrl, signIn, trackEvent],
  );

  const [handleSignIn, signInInFlight] = useAuthTokenSignIn(signInWithIdToken);

  const handleChange = React.useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (Object.keys(credentials).includes(name)) {
        setCredentials((credentials) => ({
          ...credentials,
          [name]: value,
        }));
        setErrors((errors) => ({
          ...errors,
          [name]: value ? undefined : errors[name],
        }));
      }
    },
    [credentials],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setLoading(true);

      const { email, password } = credentials;

      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((credential) => credential.user.getIdToken())
        .then((fireBaseToken) =>
          signInWithIdToken(null, {
            fireBaseToken,
          }),
        )
        .catch((error) => {
          console.error(error);

          if (error.code === "auth/user-not-found") {
            setErrors((errors) => ({
              ...errors,
              email: "The email you entered does not belong to any account",
            }));
          } else if (error.code === "auth/wrong-password") {
            setErrors((errors) => ({
              ...errors,
              password: "Invalid password",
            }));
            setCredentials((credentials) => ({
              ...credentials,
              password: "",
            }));
          } else {
            onError(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [credentials, onError, signInWithIdToken],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <AppLogo className={s.logo} main full width={160} height={37} />
      <LoginFormX
        onSignIn={handleSignIn}
        credentials={credentials}
        errors={errors}
        onChange={handleChange}
        onSubmit={handleSubmit}
        disabled={loading || signingIn || signInInFlight}
        noAccountEmail={noAccountEmail}
      />
    </Box>
  );
}
