import React from "react";
import { ComponentType } from "../../../constants";
import { useCurriculumSelector } from "../../../redux/hooks";
import { selectComponentBySlug } from "../../../redux/curriculum/selectors/curriculum";
import { Box } from "@mui/material";
import DevicePreview, { DeviceForPreview } from "../../preview/DevicePreview";

const PreviewTab = ({ slug }: { type: ComponentType; slug: string }) => {
  const component = useCurriculumSelector(selectComponentBySlug(slug));

  return (
    <Box paddingTop={1.5} paddingBottom={0} paddingInline={3}>
      <DevicePreview
        device={DeviceForPreview.IPHONE}
        mode="component"
        component={component}
        minWidth={275}
      />
    </Box>
  );
};

export default PreviewTab;
