import clsx from "clsx";
import React, { MouseEventHandler } from "react";
import { Box, BoxProps, Slide, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  useProgramSchedule,
  ScheduleUpdate,
} from "../../hooks/useProgramSchedule";
import {
  ProgramCalendarWeek,
  ProgramCalendarWeekProps,
} from "../program-calendar/ProgramCalendarWeek";
import { WEEK_DRAG_ITEM_TYPE } from "./types";
import { getDaysSpan, setSpan } from "./utils";
import { TransitionProps } from "@mui/material/transitions";
import { NormalizedCurriculumProgram } from "../../redux/types";
import {
  parseComponentDays,
  stringifyComponentDays,
} from "../../utils/component";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import { updateComponentPosition } from "../../redux/curriculum/curriculum-slice";
import { denormalizeCurriculum } from "../../redux/normalize";
import { selectCurriculum } from "../../redux/curriculum/selectors/curriculum";
import { ComponentClickHandlerType } from "../program/ProgramDetails";
import WeeksGap from "../curriculum/WeeksGap";
import { ProgramDefaultView } from "../../constants";

export interface ProgramCalendarWeeksListProps extends BoxProps {
  filters?: ProgramCalendarWeekProps["filters"];
  startDate?: string;
  onOpenMenu?: ProgramCalendarWeekProps["onOpenMenu"];
  program: NormalizedCurriculumProgram;
  weeks?: number[];
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
  weeksLength: number;
  componentClickHandler: ComponentClickHandlerType;
  componentDoubleClickHandler: ComponentClickHandlerType;
  weeksByNumber: number[];
}

export function ProgramCalendarWeeksList(props: ProgramCalendarWeeksListProps) {
  const {
    program,
    filters,
    startDate,
    onOpenMenu,
    weeks,
    weeksActions,
    weeksLength,
    componentClickHandler,
    componentDoubleClickHandler,
    weeksByNumber,
  } = props;

  const dispatch = useCurriculumDispatch();
  const [scheduleUpdate, setScheduleUpdate] = React.useState<ScheduleUpdate>();
  const scheduleAssets = denormalizeCurriculum(
    useCurriculumSelector(selectCurriculum).value,
  );
  const [components, schedule] = useProgramSchedule(
    scheduleAssets,
    filters,
    scheduleUpdate,
  );

  const handleMove: ProgramCalendarWeekProps["onMove"] = React.useCallback(
    (update) => {
      const { type, componentId, day, week } = update;
      const originDay = scheduleUpdate
        ? scheduleUpdate.originDay
        : update.originDay;
      const originWeek = update.originWeek;
      const component = components.find(({ id }) => id === componentId);
      const days = [...parseComponentDays(component.days)];
      const originSpan = getDaysSpan(days, originDay);
      let weekId = scheduleUpdate ? scheduleUpdate.weekId : component.weekId,
        offset = 0;

      setSpan(days, originDay, originSpan, false);

      switch (type) {
        case WEEK_DRAG_ITEM_TYPE.COMPONENT:
          const weekOffset = week - originWeek;
          const currentWeekIndex = program.weeks.findIndex(
            (id) => id === weekId,
          );
          const newWeekIndex = Math.min(
            Math.max(currentWeekIndex + weekOffset, 0),
            weeksLength - 1,
          );

          weekId = program.weeks[newWeekIndex];
          setSpan(days, day, originSpan, true);
          break;

        case WEEK_DRAG_ITEM_TYPE.RESIZE_LEFT:
          offset = originDay - day;
          offset = Math.max(offset, 1 - originSpan);

          setSpan(days, originDay - offset, originSpan + offset, true);
          break;

        case WEEK_DRAG_ITEM_TYPE.RESIZE_RIGHT:
          offset = originDay + originSpan - day - 1;

          setSpan(days, originDay, Math.max(originSpan - offset, 1), true);
          break;
      }

      setScheduleUpdate({
        id: update.componentId,
        weekId,
        days,
        originDay,
        originWeek,
      });
    },
    [components, weeks, scheduleUpdate],
  );

  const handleMoveEnd = React.useCallback(() => {
    if (!scheduleUpdate) return;

    dispatch(
      updateComponentPosition({
        originWeek: scheduleUpdate.originWeek,
        newWeekId: scheduleUpdate.weekId,
        componentId: scheduleUpdate.id,
        days: stringifyComponentDays(scheduleUpdate.days),
      }),
    );

    setScheduleUpdate(null);
  }, [scheduleUpdate]);

  return weeks.map((week, i) => {
    const isGap = weeksByNumber[i] - weeksByNumber[i - 1] > 1;
    return (
      <>
        {isGap && (
          <WeeksGap
            startWeekNumber={weeksByNumber[i - 1] + 1}
            endWeekNumber={weeksByNumber[i] - 1}
            view={ProgramDefaultView.CALENDAR}
          />
        )}
        <ProgramCalendarWeek
          key={week}
          weekId={week}
          schedule={schedule}
          filters={filters}
          startDate={startDate}
          onOpenMenu={onOpenMenu}
          onMove={handleMove}
          onMoveEnd={handleMoveEnd}
          weeksActions={weeksActions}
          componentClickHandler={componentClickHandler}
          componentDoubleClickHandler={componentDoubleClickHandler}
        />
      </>
    );
  });
}
