import { useCurrentUser } from "../../../hooks/useCurrentUser";
import MessagesService from "../../../services/MessagesService";
import {
  MessageDirection,
  MessageDto,
  ICursorPaginatedListOfMessageItemDto,
} from "@growth-machine-llc/stridist-api-client";
import { MESSAGES_THREAD_QUERY_KEY } from "../../screen/MessagingScreen";
import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import dayjs from "dayjs";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { InfiniteData } from "@tanstack/react-query";

/**
 * Custom hook for creating a new message mutation.
 * This hook performs optimistic updates to the cache by adding the new message to the thread before the mutation is complete.
 * If the mutation fails, the cache is rolled back to the previous state.
 *
 * @param recipient - The recipient of the message to generate the query key for caching.
 */
export default function useCreateMessageMutation(recipient?: string) {
  const { email, displayName, photoUrl } = useCurrentUser() || {};

  const updateFn = INFINITE_PAGINATED_DATA_UPDATERS.addItem;
  const successUpdater = INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties;

  return useOptimisticUpdateMutation({
    mutationFn: MessagesService.createMessage,
    queryKey: [MESSAGES_THREAD_QUERY_KEY, { recipient }],
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (
        oldData: InfiniteData<ICursorPaginatedListOfMessageItemDto>,
        { content },
        tempId,
      ) =>
        oldData
          ? updateFn(
              oldData,
              MessageDto.fromJS({
                id: tempId,
                content,
                direction: MessageDirection.OUTGOING,
                formattedDate: dayjs().format("dddd DD MMM YYYY"),
                messageAuthor: {
                  email,
                  displayName,
                  photoUrl,
                },
              }),
            )
          : oldData,
    },
    successUpdater: {
      updateFn: (
        oldData: InfiniteData<ICursorPaginatedListOfMessageItemDto>,
        newMessage,
        tempId,
      ) => successUpdater(oldData, tempId, newMessage),
    },
  });
}
