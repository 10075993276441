import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  InputAdornment,
  TextFieldProps,
  IconButton,
  IconButtonProps,
  InputBaseComponentProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { capitalize } from "lodash";

import { ReactComponent as PlusSquare } from "../../icons/streamline-icon-add-square.svg";
import { ReactComponent as MinusSquare } from "../../icons/streamline-icon-subtract-square.svg";
import { NutritionTrackingType, NutrientType } from "../../utils/nutrition";
import { RATIONAL_NUMBER_REGEX } from "../../constants";

import { ClientMealLoggingQuestionProps } from "./types";
import {
  MealLoggingQuestion,
  MealLoggingQuestionNutrition,
} from "../meal-logging-settings/types";

const useStyles = makeStyles((theme) => ({
  root: {},

  fieldWrapper: {
    marginTop: theme.spacing(3),
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    marginBottom: theme.spacing(2),
  },

  inputWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  button: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: 0,
    padding: 0,
    color: theme.palette.text.secondary,
  },

  input: {
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),

    "& input": {
      backgroundColor: "initial !important",
    },
  },

  adornment: {
    "& > .MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "17px",
      color: theme.palette.text.secondary,
    },
  },
}));

const step = 0.5;

enum Operation {
  DECREMENT = "DECREMENT",
  INCREMENT = "INCREMENT",
}

type NutritionAnswer = Partial<Record<NutrientType, number>>;

function getInitialAnswer(
  option: MealLoggingQuestion<MealLoggingQuestionNutrition>,
) {
  const result =
    option.answer && option.answer !== "" ? JSON.parse(option.answer) : {};

  if (Object.keys(result).length === 0) {
    for (const nutrientType of option.nutrientTypes) {
      result[nutrientType as NutrientType] = 0;
    }
  }

  return result as NutritionAnswer;
}

export interface ClientMealLoggingNutritionQuestionProps
  extends BoxProps,
    ClientMealLoggingQuestionProps<MealLoggingQuestionNutrition> {}

export function ClientMealLoggingNutritionQuestion(
  props: ClientMealLoggingNutritionQuestionProps,
) {
  const { className, option, onUpdate, ...other } = props;
  const s = useStyles();
  const [answer, setAnswer] = React.useState<NutritionAnswer>(
    getInitialAnswer(option),
  );

  React.useEffect(() => {
    if (onUpdate) {
      onUpdate({ ...option, answer: JSON.stringify(answer) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateAnswer = React.useCallback(
    (value: string, nutrientType: NutrientType) => {
      setAnswer({ ...answer, [nutrientType]: value });

      if (onUpdate) {
        const valid = RATIONAL_NUMBER_REGEX.test(value);
        const parsed = Number(value);
        const newAnswer = { ...answer };

        if (valid && parsed >= 0) {
          newAnswer[nutrientType] = parsed;
        } else {
          delete newAnswer[nutrientType];
        }

        onUpdate({
          ...option,
          answer: JSON.stringify(newAnswer),
        });
      }
    },
    [answer, onUpdate, option],
  );

  const handleChange: TextFieldProps["onChange"] = React.useCallback(
    ({
      currentTarget: {
        value,
        dataset: { nutrientType },
      },
    }) => {
      handleUpdateAnswer(value, nutrientType as NutrientType);
    },
    [handleUpdateAnswer],
  );

  const handleKeyDown: InputBaseComponentProps["onKeyDown"] = React.useCallback(
    (event) => {
      const valid = /[0-9.,-]/.test(event.key);
      const isSpecialKey = event.key.length > 1;

      if (!valid && !isSpecialKey) {
        event.preventDefault();
      }
    },
    [],
  );

  const handleButtonClick: IconButtonProps["onClick"] = React.useCallback(
    ({
      currentTarget: {
        dataset: { nutrientType, operation },
      },
    }) => {
      const parsedAnswer = Number(answer[nutrientType]);
      const newAnswer =
        parsedAnswer + (operation === Operation.DECREMENT ? -step : step);

      if (newAnswer >= 0) {
        handleUpdateAnswer(String(newAnswer), nutrientType as NutrientType);
      }
    },
    [answer, handleUpdateAnswer],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {option.nutrientTypes.map((nutrientType) => (
        <Box className={s.fieldWrapper} key={nutrientType}>
          <Typography
            variant="h5"
            className={s.label}
            children={`${capitalize(nutrientType)} ${
              option.trackingType === NutritionTrackingType.MACROS
                ? "grams"
                : "portions"
            }`}
          />
          <Box className={s.inputWrapper}>
            <IconButton
              className={s.button}
              data-operation={Operation.DECREMENT}
              data-nutrient-type={nutrientType}
              onClick={handleButtonClick}
              children={<MinusSquare />}
              size="large"
            />
            <TextField
              className={s.input}
              variant="outlined"
              fullWidth
              value={answer[nutrientType]}
              onChange={handleChange}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment
                      className={s.adornment}
                      position="end"
                      children={
                        option.trackingType === NutritionTrackingType.MACROS
                          ? "grams"
                          : "portions"
                      }
                    />
                  ),
                },

                htmlInput: {
                  "data-nutrient-type": nutrientType,
                  onKeyDown: handleKeyDown,
                },
              }}
            />
            <IconButton
              className={s.button}
              data-operation={Operation.INCREMENT}
              data-nutrient-type={nutrientType}
              onClick={handleButtonClick}
              children={<PlusSquare />}
              size="large"
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
