import React from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { RowType } from "../../../constants";
import { alpha, Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  Video,
  PanelLeftClose,
  SquarePen,
  FlagTriangleRight,
} from "lucide-react";
import { ExerciseDrawerData, getDefaultExerciseCellTitle } from "../utils";
import makeStyles from "@mui/styles/makeStyles";
import { useSpreadsheetInfoDrawer } from "../../../hooks/useSpreadsheetInfoDrawer";
import { getColorByComponentType } from "../../program-calendar/utils/common";
import { useSidebar } from "../../../contexts/CurriculumSidebarContext";
import { ComponentClickHandlerType } from "../../program/ProgramDetails";
import { useCurriculumViewOption } from "../../../contexts/CurriculumViewOptionsContext";
import { useEnableColors } from "../../../hooks/useEnableColors";

export const ComponentCellIcon = (
  isSidebarOpen: boolean,
  isSidebarOpenForClicked,
) => {
  const theme = useTheme();

  const { enableSidebar } = useCurriculumViewOption();

  const commonProps = {
    size: 20,
    strokeWidth: 1.2,
    color: "inherit",
  };

  if (isSidebarOpen) {
    return isSidebarOpenForClicked ? (
      <SquarePen {...commonProps} />
    ) : (
      <PanelLeftClose {...commonProps} />
    );
  } else {
    return enableSidebar ? (
      <PanelLeftClose {...commonProps} />
    ) : (
      <SquarePen {...commonProps} />
    );
  }
};

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover $icon": {
      stroke: theme.palette.common.black,
    },
  },
  icon: {
    transition: theme.transitions.create(["color, fill"]),
  },
}));

const ExerciseCellRenderer = ({
  value,
  data,
  exerciseDrawerData,
  setExerciseDrawerData,
  componentClickHandler,
}: CustomCellRendererProps & {
  exerciseDrawerData: ExerciseDrawerData;
  setExerciseDrawerData: React.Dispatch<
    React.SetStateAction<ExerciseDrawerData>
  >;
  componentClickHandler: ComponentClickHandlerType;
}) => {
  const s = useStyles();
  const theme = useTheme();

  const [_, setInfoDrawerColumnField] = useSpreadsheetInfoDrawer();
  const { isOpen: isSidebarOpen, componentSlug: currentSidebarComponent } =
    useSidebar();
  const enableColors = useEnableColors();

  const isCurrentExerciseOpenedInDrawer =
    data.exerciseData?.id &&
    data.exerciseData?.id === exerciseDrawerData?.exercise?.id;

  const handleIconClick = (e) => {
    e.stopPropagation();

    setInfoDrawerColumnField(undefined);
    if (isCurrentExerciseOpenedInDrawer) {
      setExerciseDrawerData(undefined);
      return;
    }

    setExerciseDrawerData({
      componentId: data.componentId,
      componentContent: data.componentContent,
      workoutSection: data.workoutSectionData,
      exercise: data.exerciseData,
    });
  };

  const handleCalendarComponentClick = (slug?: string) => {
    setInfoDrawerColumnField(undefined);
    componentClickHandler(slug);
  };

  const placeholderValue =
    value === getDefaultExerciseCellTitle(RowType.EXERCISE) ||
    value === getDefaultExerciseCellTitle(RowType.WORKOUT_SECTION) ||
    value === getDefaultExerciseCellTitle(RowType.WORKOUT)
      ? value.replace("Untitled", "Enter") + " title..."
      : value;

  const IS_OVERRIDE = !!data?.baseComponentId;
  const IS_SIDEBAR_COMPONENT = currentSidebarComponent === data?.slug;

  return (
    <>
      <Box
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {placeholderValue}
      </Box>
      {data.rowType === RowType.EXERCISE &&
        (!data.exerciseData?.video || isCurrentExerciseOpenedInDrawer) && (
          <Box sx={{ marginLeft: 2, marginRight: -1 }}>
            <IconButton
              size="small"
              color="inherit"
              onClick={handleIconClick}
              sx={{ marginBottom: 0.5 }}
              className={s.button}
            >
              <Video
                size={22}
                strokeWidth={1.2}
                color={
                  isCurrentExerciseOpenedInDrawer
                    ? theme.palette.common.black
                    : isCurrentExerciseOpenedInDrawer
                      ? "transparent"
                      : theme.palette.divider
                }
                className={s.icon}
              />
            </IconButton>
          </Box>
        )}
      {![RowType.EXERCISE, RowType.WORKOUT_SECTION].includes(data.rowType) && (
        <Box
          sx={{
            marginLeft: 2,
            marginRight: -1.4,
            display: "flex",
            alignItems: "center",
          }}
        >
          {IS_OVERRIDE && (
            <FlagTriangleRight size={18} color={theme.palette.common.black} />
          )}
          <IconButton
            onClick={() => handleCalendarComponentClick(data.slug)}
            sx={{
              borderRadius: 2,
              aspectRation: 1,

              stroke: IS_SIDEBAR_COMPONENT
                ? theme.palette.common.white
                : theme.palette.common.black,

              background: IS_SIDEBAR_COMPONENT
                ? theme.palette.common.black
                : enableColors
                  ? getColorByComponentType(data?.type, 0.3)
                  : theme.palette.grey[300],

              ":hover": {
                background: IS_SIDEBAR_COMPONENT
                  ? alpha(theme.palette.common.black, 0.3)
                  : enableColors
                    ? getColorByComponentType(data?.type, 0.3)
                    : theme.palette.grey[300],
              },
            }}
          >
            {ComponentCellIcon(
              isSidebarOpen,
              currentSidebarComponent === data.slug,
            )}
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ExerciseCellRenderer;
