import InvitesService from "../../../services/InvitesService";
import {
  IClientsCountDto,
  PaginatedListOfClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { COACH_PENDING_CLIENTS_COUNT_QUERY_KEY } from "../../../wrappers/router/coach/CoachClientsWrapper";

export default function useRevokeInviteMutation() {
  const queryClient = useQueryClient();
  return useOptimisticUpdateMutation({
    queryKey: [
      COACH_CLIENTS_LIST_QUERY_KEY,
      { status: [UserInviteStatus.PENDING, UserInviteStatus.ADDED] },
    ],
    mutationFn: InvitesService.revokeInvite,
    optimisticUpdater: {
      updateFn: (store: PaginatedListOfClientInfoDto, id: number) => {
        store.items = store.items?.filter(
          (invitedUser) => invitedUser.invite.id !== id,
        );

        return store;
      },
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            COACH_CLIENTS_LIST_QUERY_KEY,
            { isDialog: true, status: [UserInviteStatus.PENDING] },
          ],
        });

        queryClient.setQueryData<IClientsCountDto>(
          [COACH_PENDING_CLIENTS_COUNT_QUERY_KEY],
          (prev) => (prev ? { ...prev, count: prev.count - 1 } : prev),
        );
      },
    },
  });
}
