import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { UserInfo } from "../../hooks/useCurrentUser";
import { SendUsMessageLink } from "../app/SendUsMessageLink";
import { AppLogo } from "../app/AppLogo";
import { GoHighLevelResetPasswordUrl, GoHighLevelUrl } from "../../constants";
import { ArrowLeft, ArrowUpRight, Mail } from "lucide-react";
import { OrDivider } from "../auth/OrDivider";
import { openEmailInbox } from "../../utils/user";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    padding: theme.spacing(4),
  },
  logo: {
    marginBottom: theme.spacing(6),
    "& path": {
      fill: theme.palette.common.black,
    },
  },
  email: {
    fontWeight: 600,
  },
  divider: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  dividerText: {
    backgroundColor: theme.palette.common.white,
  },
  split: {
    border: 0,
    borderTop: `1px solid ${theme.palette.border.primary}`,
    margin: theme.spacing(5, 0, 3),
  },
  notFoundText: {
    fontSize: 16,
    color: theme.palette.common.black,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
    marginRight: theme.spacing(3)
  }
}));

export interface CoachMarketingInstructionsProps {
  user: UserInfo;
}

export function CoachMarketingInstructions(
  props: CoachMarketingInstructionsProps,
) {
  const { user } = props;
  const s = useStyles();
  const [inboxDialogOpen, setInboxDialogOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  const handleCloseInboxDialog = () => {
    setInboxDialogOpen(false);
  };

  const handleCloseResetDialog = () => {
    setResetDialogOpen(false);
  };

  const handleResetPassword = () => {
    window.location.href = GoHighLevelResetPasswordUrl;
  };

  return (
    <Container className={s.root}>
      <AppLogo className={s.logo} full size={160} width={160} main />
      <Typography component="p" sx={{ mb: 2 }}>
        Welcome to our Marketing Suite!
        <br />
        Please check your inbox to activate your marketing account.
        <br />
        An email has been sent to <span className={s.email}>{user.email}</span>
      </Typography>

      <Button
        variant="contained"
        size="large"
        sx={{ fontWeight: "bold" }}
        onClick={() =>
          openEmailInbox(user.email, () => setInboxDialogOpen(true))
        }
      >
        Open Activation Email
        <Box sx={{ pl: 1.5 }}>
          <Mail />
        </Box>
      </Button>

      <Typography sx={{ mt: 2 }}>
        <Link
          component="button"
          variant="body2"
          color="inherit"
          onClick={() => setResetDialogOpen(true)}
        >
          Haven't received email?
        </Link>
      </Typography>

      <OrDivider className={s.divider} textClassName={s.dividerText} />

      <Typography gutterBottom>
        Navigate back Home or directly to Marketing Suite.
      </Typography>
      <Stack
        direction="row"
        gap={[0, 1]}
        flexWrap="wrap"
        justifyContent="center"
      >
        <Button variant="text" href="/" sx={{}}>
          <Box sx={{ pr: 0.5 }}>
            <ArrowLeft />
          </Box>
          Back to Home
        </Button>
        <Button variant="text" href={GoHighLevelUrl}>
          Navigate to Marketing
          <Box sx={{ pl: 0.5 }}>
            <ArrowUpRight />
          </Box>
        </Button>
      </Stack>

      <hr className={s.split} />

      <Typography className={s.notFoundText}>
        Need help? <SendUsMessageLink />
      </Typography>

      <Dialog open={inboxDialogOpen} onClose={handleCloseInboxDialog}>
        <DialogTitle>Check your inbox</DialogTitle>
        <DialogContent>
          <Typography>
            Please check your inbox for the activation email.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInboxDialog} variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={resetDialogOpen} onClose={handleCloseResetDialog}>
        <DialogTitle>Marketing Suite activation</DialogTitle>
        <DialogContent>
          <Typography>
            The activation email may be delayed due to the high demand.
            <br />
            <br />
            If you need access to Marketing Suite as quickly as possible. Please
            follow the reset your password link below.
            <br />
            <br />
            That should get you straight to the account immediately. Thank you
            for understanding!
          </Typography>
        </DialogContent>
        <DialogActions className={s.buttonContainer}>
          <Button onClick={handleCloseResetDialog}>Close</Button>
          <Button onClick={handleResetPassword} variant="contained">
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
