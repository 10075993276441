import { useEffect } from "react";
import { removeComponentSplitMarks } from "../../redux/curriculum/curriculum-slice";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import { selectSplitResults } from "../../redux/curriculum/selectors/curriculum";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";

export const useDeferredSidebarOpening = () => {
  const dispatch = useCurriculumDispatch();
  const splitResults = useCurriculumSelector(selectSplitResults);
  const { openSidebar, componentSlug: currentSidebarComponentSlug } =
    useSidebar();

  // Track split results
  useEffect(() => {
    if (splitResults.length > 0) {
      // expect only one result
      const { slug } = splitResults[0];
      // open sidebar only when api returns actual slug
      if (!slug.endsWith("-tempslug") && slug !== currentSidebarComponentSlug) {
        // reset state until next split action
        dispatch(removeComponentSplitMarks());
        openSidebar(slug);
      }
    }
  }, [splitResults]);
};
