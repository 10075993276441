import CoachProgramsService from "../../../services/CoachProgramsService";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../CoachProgramsListScreen";
import {
  IPaginatedListOfProgramDto,
  IProgramsCountDto,
  ProgramDto,
} from "@growth-machine-llc/stridist-api-client";
import { ProgramStatus } from "../../../constants";
import {
  predicateQueryKeyByParams,
  useOptimisticUpdateMutation,
} from "../../../hooks/useOptimisticUpdateMutation";
import { INFINITE_PAGINATED_DATA_UPDATERS } from "../../../utils/optimisticUpdate";
import { InfiniteData, useQueryClient } from "@tanstack/react-query";
import { COACH_PROGRAMS_COUNT_QUERY_KEY } from "../../../wrappers/router/coach/CoachProgramsListWrapper";

export const useArchiveProgramMutation = (status?: ProgramStatus) => {
  const filterOutProgram = INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem;
  const queryClient = useQueryClient();
  return useOptimisticUpdateMutation({
    mutationFn: CoachProgramsService.archiveProgram,
    queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
    optimisticUpdater: {
      updateFn: (oldData: InfiniteData<IPaginatedListOfProgramDto>, params) =>
        filterOutProgram<ProgramDto>(oldData, params.programId),
      predicateFn: (queryKey) =>
        // Filter out program from all tabs except the `ARCHIVED` tab
        !predicateQueryKeyByParams(queryKey, {
          status: ProgramStatus.ARCHIVED,
        }),
    },
    invalidateQueryOptions: {
      enabled: true,
      predicateFn: (queryKey) =>
        predicateQueryKeyByParams(queryKey, {
          status: ProgramStatus.ARCHIVED,
        }),
    },
    options: {
      onSuccess: () => {
        // NOTE: Updating only `draft` count since it's the only tab with counter on nav tabs
        if (status === ProgramStatus.DRAFT) {
          queryClient.setQueryData<IProgramsCountDto>(
            [COACH_PROGRAMS_COUNT_QUERY_KEY],
            (prev) => (prev ? { draft: prev.draft - 1 } : prev),
          );
        }
      },
    },
  });
};
