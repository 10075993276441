import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Typography, TextField } from "@mui/material";

import { Accordion, AccordionProps } from "../accordion/Accordion";

import { defaultPromptValue } from "./HabitPrompt";
import { HabitCompleteButton } from "./HabitCompleteButton";

const useStyles = makeStyles((theme) => ({
  root: {},

  text: {
    margin: theme.spacing(3, 1, 0, 1),
    fontSize: 14,
  },

  input: {
    border: "1px solid",
    borderColor: theme.palette.border.secondary,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(3, 0),
    "& input": {
      padding: theme.spacing(2, 1.5),
      fontWeight: 600,
      fontSize: 18,
    },
  },

  button: {
    borderColor: theme.palette.border.primary,
  },
}));

export interface HabitPromptEditProps extends Omit<AccordionProps, "onChange"> {
  prompt: string;
  onChange: (prompt: string) => void;
  onPanelChange?: AccordionProps["onChange"];
}

export function HabitPromptEdit(props: HabitPromptEditProps) {
  const { className, onChange, prompt, onPanelChange, ...other } = props;
  const s = useStyles();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <Accordion
      label="Prompt"
      header={prompt || "Will clients be shown a custom prompt?"}
      className={clsx(s.root, className)}
      onChange={onPanelChange}
      {...other}
    >
      <Typography variant="body1" className={s.text}>
        Motivate your clients with a custom message.
      </Typography>
      <TextField
        className={s.input}
        fullWidth
        value={!prompt || prompt === defaultPromptValue ? "" : prompt}
        placeholder={defaultPromptValue}
        onChange={handleChange}
        slotProps={{
          input: {
            disableUnderline: true,
          },
        }}
      />
      <HabitCompleteButton className={s.button} completed={true} />
    </Accordion>
  );
}
