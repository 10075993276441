import React from "react";
import { Box, Button, MenuItem, Popover, Typography } from "@mui/material";
import { ChevronDownIcon } from "lucide-react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { TagDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    fontWeight: 700,
    display: "flex",
    justifyContent: "flex-start",

    "&.active": {
      color: theme.palette.primary.main,
    },
  },
  paper: {
    borderRadius: theme.spacing(0.5),
    minWidth: theme.spacing(24),
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 2),
  },
  arrow: {
    transform: "rotate(180deg)",
  },
  menuTitle: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  menuItem: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20px",
    color: theme.palette.secondary.main,
    width: "100%",
  },
}));

type TagsProps = {
  node: {
    title: string;
    id: string;
  };
};

type CoachClientsSortHeaderTagMenuProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  tagList: TagDto[];
  onChangeFilterTagId: (tagId: number) => void;
  filterTag?: number;
  disabled?: boolean;
  active?: boolean;
};

const CoachClientsSortHeaderTagMenu = ({
  open,
  setOpen,
  tagList,
  onChangeFilterTagId,
  filterTag,
  disabled,
  active,
}: CoachClientsSortHeaderTagMenuProps) => {
  const s = useStyles();
  const ref = React.useRef<HTMLButtonElement>();
  return (
    <>
      <Button
        ref={ref}
        className={clsx(s.button, { active })}
        children="Tag"
        endIcon={
          <ChevronDownIcon
            size={20}
            style={{ marginLeft: -1.5, fill: "none" }}
          />
        }
        onClick={() => setOpen(true)}
        disabled={disabled}
      />
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        classes={{ paper: s.paper }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        <Box className={s.menu}>
          <Typography className={s.menuTitle}>TAG:</Typography>
          <Box className={s.arrow}>
            <ChevronDownIcon size={20} color={"grey"} />
          </Box>
        </Box>

        {tagList.map(({ title, id }) => (
          <MenuItem
            className={s.menu}
            key={id}
            onClick={() => {
              onChangeFilterTagId(id === filterTag ? null : id);
              setOpen(false);
            }}
            selected={id === filterTag}
          >
            <Typography className={s.menuItem}>{title}</Typography>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default CoachClientsSortHeaderTagMenu;
