import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  Button,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import notes from "../../icons/notes.svg";
import { UserNoteDialog } from "../user-notes/UserNoteDialog";
import { UserNotesList } from "../user-notes/UserNotesList";
import { InfoBox } from "../info/InfoBox";

import NoNotes from "../../icons/NoNotes";
import { useClient } from "../../hooks/useClient";
import UserNotesService from "../../services/UserNotesService";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import LoadingActionButtonWithStatus from "../button/LoadingActionButtonWithStatus";
import useCreateNoteMutation from "../user-notes/mutations/CreateNote";
import { PageSkeleton } from "../loading/PageSkeleton";
import { NotesCardSkeleton } from "../loading/NotesCardSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.slg,
  },

  empty: {
    maxWidth: 444,
    margin: theme.spacing(12, "auto", 0),
  },

  emptyButton: {
    width: "100%",
    marginTop: theme.spacing(3),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    padding: theme.spacing(2.25),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
  },

  emptyTitle: {
    marginTop: theme.spacing(2),
  },

  button: {
    fontWeight: 700,
    fontSize: 16,
    borderRadius: 4,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1.5, 8),
    },
  },
}));

export interface CoachClientNotesScreenProps
  extends Omit<ContainerProps, "children"> {}

const CLIENT_NOTES_PAGE_SIZE = 10;
export const CLIENT_NOTES_QUERY_KEY = "client-notes";

export function CoachClientNotesScreen(props: CoachClientNotesScreenProps) {
  const { className, ...other } = props;
  const client = useClient();
  const s = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);

  const {
    data: clientNotesPages,
    ref: fetchRef,
    isPending: loadingNotes,
    hasNextPage: hasNext,
    fetchNextPage: fetchMore,
    isFetchingNextPage: loading,
  } = useInfiniteScrollQuery({
    queryKey: [CLIENT_NOTES_QUERY_KEY, { client: client?.id }],
    queryFn: ({ pageParam }) =>
      UserNotesService.getUserNotes(
        client.id,
        pageParam as number,
        CLIENT_NOTES_PAGE_SIZE,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.hasNextPage ? lastPage.pageNumber + 1 : undefined,
  });

  const {
    mutate: createNote,
    isPending: creating,
    status: creatingStatus,
  } = useCreateNoteMutation({
    onError: () => setOpenDialog(true),
  });

  const clientNotes =
    clientNotesPages?.pages.flatMap((page) => page.items) ?? [];

  const handleNewNoteClick = React.useCallback(() => setOpenDialog(true), []);
  const handleDialogClose = React.useCallback(() => setOpenDialog(false), []);
  const theme = useTheme();

  return (
    <Container className={clsx(s.root, className)} {...other}>
      {loadingNotes ? (
        Array.from({ length: 3 }).map((_, i) => (
          <NotesCardSkeleton sx={{ mt: 2 }} key={i} />
        ))
      ) : clientNotes?.length ? (
        <Box className={s.header}>
          <Typography className={s.title} variant="h4">
            Notes
          </Typography>
          <LoadingActionButtonWithStatus
            className={s.button}
            variant="contained"
            onClick={handleNewNoteClick}
            disabled={creating}
            status={clientNotes?.length > 1 ? creatingStatus : "idle"}
          >
            <Box sx={{ px: 8 }}>New note</Box>
          </LoadingActionButtonWithStatus>
        </Box>
      ) : (
        <InfoBox
          className={s.empty}
          classes={{ title: s.emptyTitle }}
          image={<NoNotes fill={theme.palette.primary.main} />}
          title="Add a client note"
        >
          <Button
            className={s.emptyButton}
            variant="contained"
            onClick={handleNewNoteClick}
            children="New note"
          />
        </InfoBox>
      )}

      <UserNotesList
        fetchMore={fetchMore}
        loading={loading}
        hasNext={hasNext}
        fetchRef={fetchRef}
        notes={clientNotes}
      />

      {openDialog && (
        <UserNoteDialog
          open={openDialog}
          createNote={createNote}
          onClose={handleDialogClose}
        />
      )}
    </Container>
  );
}
