import React, { useMemo } from "react";
import {
  Box,
  Paper,
  IconButton,
  useTheme,
  Typography,
  Divider,
} from "@mui/material";
import GenericScheduleTitle from "./generic/GenericScheduleTitle";
import {
  FlagTriangleRight,
  ArrowRight,
  Trash2,
  CalendarPlus,
} from "lucide-react";
import {
  ComponentRepeat,
  ComponentType,
  SidebarTabs,
} from "../../../../constants";
import { useSearchParams } from "react-router-dom";
import { useSidebar } from "../../../../contexts/CurriculumSidebarContext";
import { useDialog } from "../../../../contexts/CurriculumComponentDialogContext";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../../../redux/hooks";
import { removeOverride } from "../../../../redux/curriculum/curriculum-slice";
import MinimizedTooltip from "../../../tooltip/MinimizedTooltip";
import CreateOverride from "../../../program-component/CreateOverride";
import { CurriculumComponent } from "../../../../redux/types";
import {
  DeleteConfirmationMode,
  useDeleteConfirmation,
} from "../../../program/modals/DeleteConfirmation.tsx/DeleteConfirmationModalContext";
import OverridesPaperButton from "./overrides/OverridesPaperButton";
import { useUrlClickedDay } from "../../../program-component/OverrideCreationDialog";
import { selectWeekNumberByWeekId } from "../../../../redux/curriculum/selectors/curriculum";

interface IOverridesComponentProps {
  overrides: {
    id: number;
    slug: string;
    weekNumber: number;
    dayNumber: number;
  }[];
  component: CurriculumComponent;
  baseComponent: {
    id: number;
    slug: string;
  } | null;
}

const OverridesComponent = (props: IOverridesComponentProps) => {
  const { overrides: unsortedOverrides, component, baseComponent } = props;
  const dispatch = useCurriculumDispatch();
  const { openSidebar } = useSidebar();
  const { openDialog, closeDialog } = useDialog();
  const [overrideDialog, setOverrideDialog] = React.useState(false);
  const [searchParams, _] = useSearchParams();
  const componentSearchParam = searchParams.get("component");
  const isDialogEnv = !!componentSearchParam;
  const urlInitialSelectedDay = useUrlClickedDay();
  const startWeekNum = useCurriculumSelector(
    selectWeekNumberByWeekId(component.weekId),
  );
  const theme = useTheme();

  // Only needed when showing the override list
  const overrides = useMemo(() => {
    return [...unsortedOverrides].sort((a, b) => {
      if (a.weekNumber === b.weekNumber) {
        return a.dayNumber - b.dayNumber;
      }
      return a.weekNumber - b.weekNumber;
    });
  }, [unsortedOverrides]);

  const getOverrideSlug = (id: number) => {
    return overrides.find((override) => override.id === id)?.slug;
  };

  const handleOverrideClick = (id: number) => {
    const overrideSlug = getOverrideSlug(id);
    if (isDialogEnv) {
      closeDialog();
      setTimeout(() => {
        openDialog(overrideSlug);
      }, theme.transitions.duration.leavingScreen);
    } else {
      openSidebar(overrideSlug, SidebarTabs.SCHEDULE);
    }
  };

  const { openDialog: openDeleteConfirmationDialog, popupState } =
    useDeleteConfirmation();

  const handleDeleteClick = (id: number) => {
    dispatch(removeOverride({ overrideId: id }));
  };

  const handleEditClick = (id: number) => {
    openDialog(getOverrideSlug(id));
  };

  const handleBaseClick = () => {
    if (!baseComponent) return;
    if (isDialogEnv) {
      closeDialog();
      setTimeout(() => {
        openDialog(baseComponent.slug);
      }, theme.transitions.duration.leavingScreen);
    } else {
      openSidebar(baseComponent.slug, SidebarTabs.SCHEDULE);
    }
  };

  const handleOverrideDialogOpen = React.useCallback(() => {
    setOverrideDialog(true);
  }, [setOverrideDialog]);

  const handleOverrideDialogClose = React.useCallback(() => {
    setOverrideDialog(false);
  }, [setOverrideDialog]);

  // When a base component is provided, show a single "Main component" item.
  if (baseComponent !== null) {
    return (
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row", mb: 0.5 }}>
          <GenericScheduleTitle title="Main component" />
        </Box>
        <OverridesPaperButton onClick={handleBaseClick}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleBaseClick();
            }}
          >
            <ArrowRight
              size={20}
              strokeWidth={2.5}
              color={theme.palette.common.black}
            />
          </IconButton>
          <Divider orientation={"vertical"} flexItem sx={{ mr: 1 }} />
          <Typography
            component="span"
            style={{ fontSize: 15, fontWeight: 500 }}
          >
            Go to main {component.type.toString().toLowerCase()}
          </Typography>
        </OverridesPaperButton>
      </Box>
    );
  }

  // Messages don't have content, thus doesn't support custom days
  if (component.type === ComponentType.MESSAGE) {
    return;
  }

  // Component is scheduled for single day, no need to prompt custom day creation
  const canAddCustomComponent =
    component.repeat !== ComponentRepeat.NONE ||
    JSON.parse(component.days).filter((value) => value).length > 1;
  if (!canAddCustomComponent && overrides.length === 0) {
    return;
  }

  const initSelectedDayToOverride =
    isDialogEnv && urlInitialSelectedDay
      ? urlInitialSelectedDay
      : {
          week: startWeekNum,
          day: JSON.parse(component.days).findIndex((d) => d === true) + 1,
        };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", mb: 0.5 }}>
        <GenericScheduleTitle title="Custom days" />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
        {overrides.map((override) => (
          <OverridesPaperButton
            onClick={() => handleOverrideClick(override.id)}
          >
            <MinimizedTooltip title="Customize this day">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditClick(override.id);
                }}
              >
                <FlagTriangleRight
                  size={20}
                  strokeWidth={2.5}
                  style={{ marginBottom: 2 }}
                  color={theme.palette.common.black}
                />
              </IconButton>
            </MinimizedTooltip>

            <Divider orientation={"vertical"} flexItem sx={{ mr: 1 }} />
            <Typography
              component="span"
              style={{ fontSize: 15, fontWeight: 500 }}
            >
              Week {override.weekNumber}, Day {override.dayNumber}
            </Typography>
            <Box sx={{ display: "flex", ml: "auto" }}>
              <MinimizedTooltip title="Remove customization from this day">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();

                    openDeleteConfirmationDialog(
                      DeleteConfirmationMode.COMPONENT,
                      () => {
                        handleDeleteClick(override.id);
                      },
                    );

                    popupState.open(e);
                  }}
                >
                  <Trash2
                    size={18}
                    strokeWidth={2.5}
                    color={theme.palette.common.black}
                  />
                </IconButton>
              </MinimizedTooltip>
            </Box>
          </OverridesPaperButton>
        ))}
        {canAddCustomComponent && (
          <>
            {overrides.length > 0 && <Divider />}
            <Box>
              <Typography component="p" variant="caption" gutterBottom>
                Change content for one day only?
              </Typography>
              <OverridesPaperButton onClick={handleOverrideDialogOpen}>
                <IconButton size="small">
                  <CalendarPlus
                    strokeWidth={2}
                    color={theme.palette.common.black}
                  />
                </IconButton>
                <Divider orientation={"vertical"} flexItem sx={{ mr: 1 }} />
                <Typography
                  component="span"
                  style={{ fontSize: 15, fontWeight: 500 }}
                >
                  Add custom day
                </Typography>
              </OverridesPaperButton>
            </Box>
          </>
        )}
      </Box>
      {overrideDialog && (
        <CreateOverride
          component={component}
          onClose={handleOverrideDialogClose}
          initialSelectedDay={initSelectedDayToOverride}
        />
      )}
    </Box>
  );
};

export default OverridesComponent;
