import React, { useEffect, useRef, useState } from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  TElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import {
  Box,
  Button,
  Dialog,
  Fade,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import ExploreIcon from "@mui/icons-material/Explore";
import { IElementChildrenProp } from "../other/commonInterfaces";
import { EmbedElementType, MoreMenu } from "./MoreMenu";
import UnsplashImagePicker from "./UnsplashImagePicker";
import FileUpload from "./FileUpload";
import ImageCropDialog from "./CropDialog";
import { PixelCrop } from "react-image-crop";
import { MoreMenuButton } from "./MoreMenuButton";
import { isValidUrl } from "../utils/validationUtil";
import { Crop } from "react-image-crop";
import { ReactEditor, RenderElementProps } from "slate-react";
import { Transforms, Element } from "slate";
import "./rnd.css";
import ResizableImage from "./ResizableImage";
import { Image as ImageIcon } from "lucide-react";
import { useReadOnly } from "../hooks";
export interface CropScaled extends Crop {
  scaleX: number;
  scaleY: number;
}
export const IMAGE_ELEMENT = "image";

interface IImageProps {
  element: any;
  handleSetNode: any;
  handleChange: any;
  handleRemoveNode: any;
  url: string;
  setUrl: any;
  setImageCaption: any;
  imageCaption: string;
  isReadOnly: boolean;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export type ImageDimensions = {
  width?: number;
  height?: number;
};
export interface Media {
  url: string;
  name?: string;
  dimensions?: ImageDimensions;
  crop?: CropScaled;
  mimeType?: string;
}
export interface MediaElementProps extends RenderElementProps {}

const emptyMedia = (): Media => ({
  url: "",
  name: "",
  dimensions: undefined,
  crop: undefined,
});

const mediaFromElement = (element: TElement): Media => {
  const url = (element.url as string) || "";
  const name = (element.name as string) || undefined;
  const dimensions = (element.dimensions as ImageDimensions) || undefined;
  const crop = (element.crop as CropScaled) || undefined;

  return url
    ? {
        url,
        name,
        dimensions,
        crop,
      }
    : emptyMedia();
};

export const Image = ({
  children,
  element,
  handleSetNode,
  handleRemoveNode,
  url,
  setUrl,
  imageCaption,
  setImageCaption,
  isReadOnly,
}: IElementChildrenProp & IImageProps) => {
  const theme = useTheme();

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const handleClick = () => {
    if (!openDialog && empty) {
      setOpenDialog(true);
    } else if (!empty) {
      setOpenAlignDialog(true);
      handleTooltipOpen();
    }
  };
  const [value, setValue] = React.useState(0);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const handleFileChange = (event) => {
    setCompletedCrop(undefined);
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUrl(reader.result);
        const newMedia = {
          ...media,
          url: reader.result as string,
        };
        setMedia(newMedia);
      };
      reader.readAsDataURL(file);
      setOpenDialog(false);
    } else {
      setUrl(null);
    }
  };
  const [media, setMedia] = React.useState<Media>(mediaFromElement(element));
  React.useEffect(() => {
    setUrl(undefined);
    setMedia(emptyMedia);
  }, [value]);
  React.useEffect(() => {
    handleSetNode({ ...media });
  }, [media]);
  React.useEffect(() => {
    const newMedia = {
      ...media,
      name: imageCaption,
    };
    setMedia(newMedia);
  }, [imageCaption]);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [isCroppedImage, setIsCroppedImage] = useState(false);

  const empty = !url;
  const handleUnsplashImageClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    imageUrl: string,
  ) => {
    setPreview(false);
    event.stopPropagation();
    const newMedia = {
      ...media,
      url: imageUrl,
    };
    setMedia(newMedia);
    setUrl(imageUrl);
    setOpenDialog(false);
  };
  const [openAlignDialog, setOpenAlignDialog] = useState(false);
  const [alignImage, setAlignImage] = useState("center");
  const [openCropDialog, setOpenCropDialog] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [preview, setPreview] = useState(false);
  const [contentHeight, setContentHeight] = useState<any>(150);
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const handleTooltipClose = () => {
    setOpenAlignDialog(false);
  };

  const handleTooltipOpen = () => {
    setOpenAlignDialog(true);
  };
  const handleCropSave = (croppedImage) => {
    setCroppedImage(croppedImage);
  };
  const handleCrop = () => {
    setOpenCropDialog(true);
  };
  const [isClicked, setIsClicked] = useState(false);

  React.useEffect(() => {
    const newMedia = {
      ...media,
      dimensions: {
        width: document.getElementById("image-box")?.offsetWidth,
        height: document.getElementById("image-box")?.offsetHeight,
      },
    };
    setMedia(newMedia);
  }, [
    document.getElementById("image-box")?.offsetWidth,
    document.getElementById("image-box")?.offsetHeight,
  ]);

  const handleClickImage = () => {
    setIsClicked(true);
  };
  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setContentHeight(contentHeight);
    }
  }, [value, openDialog]);
  const handleDocumentClick = (event) => {
    if (
      previewCanvasRef.current &&
      !previewCanvasRef.current.contains(event.target)
    ) {
      setIsClicked(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  const caption = (openAlignDialog || imageCaption) && (
    <Box display="flex" width="100%">
      <TextField
        fullWidth
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          ".css-1dictev-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            { border: 0 },
          visibility: openAlignDialog || imageCaption ? "visible" : "hidden",
        }}
        placeholder="Add a caption"
        value={imageCaption}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setImageCaption(event.target.value);
        }}
        slotProps={{
          input: {
            sx: {
              "& input": {
                textAlign: "center",
              },
            },
            disableUnderline: true,
          },
        }}
      ></TextField>
    </Box>
  );
  const previewCanva = (
    <ResizableImage
      caption={caption}
      alignImage={alignImage}
      children={
        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
          <canvas
            ref={previewCanvasRef}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              border: openAlignDialog
                ? `2px solid ${theme.palette.primary.main}`
                : "none",
            }}
            onClick={handleClickImage}
          />
        </Box>
      }
      handleTooltipClose={handleTooltipClose}
      openAlignDialog={openAlignDialog}
      setAlignImage={setAlignImage}
      media={media}
      setMedia={setMedia}
      isClicked={openAlignDialog}
    ></ResizableImage>
  );

  const [zoomed, setZoomed] = React.useState(false);
  const readOnly = useReadOnly();

  const handleZoomIn = React.useCallback(() => {
    setOpenDialog(false);
    setOpenAlignDialog(false);
    setZoomed(true);
  }, []);

  const handleZoomOut = React.useCallback(() => {
    setZoomed(false);
  }, []);

  if (empty && readOnly) return <></>;

  return (
    <Box
      display={"flex"}
      gap={1.5}
      sx={{
        border: empty ? "1px solid" : "none",
        borderRadius: 1,
        padding: 2.5,
        pt: 1.4,
        pr: 0,
        pl: empty ? 2.5 : 0,
        display: "flex",
        backgroundColor: (theme) =>
          empty ? theme.palette.selected.light : null,
        borderColor: (theme) => theme.palette.border.primary,
        width: "100%",
        height: "100%",
      }}
      onClick={handleClick}
      contentEditable={false}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: empty ? "flex-start" : alignImage,
          alignItems: "baseline",
          width: "100%",
          aspectRatio: empty ? "none" : "1.6",
        }}
      >
        {empty ? (
          <Box sx={{ pt: 1, display: "flex", gap: 1 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.text.disabled, p: 0 }}
            >
              <ImageIcon />
            </IconButton>
            <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>
              Upload or embed an image.
            </Typography>
          </Box>
        ) : (
          url && (
            <Box
              sx={{
                aspectRatio: "1.8",
              }}
            >
              {isCroppedImage ? (
                previewCanva
              ) : zoomed ? (
                <Dialog fullScreen open={true} onClick={handleZoomOut}>
                  <Box>
                    <img src={url} width="100%" height="100%" />
                  </Box>
                </Dialog>
              ) : (
                <ResizableImage
                  caption={caption}
                  children={
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        onDoubleClick={handleZoomIn}
                        width="100%"
                        height="100%"
                        src={url}
                        onClick={handleClickImage}
                        style={{
                          border: openAlignDialog
                            ? `2px solid ${theme.palette.primary.main}`
                            : "none",
                        }}
                      />
                    </Box>
                  }
                  handleTooltipClose={handleTooltipClose}
                  openAlignDialog={openAlignDialog}
                  alignImage={alignImage}
                  setAlignImage={setAlignImage}
                  media={media}
                  setMedia={setMedia}
                  isClicked={openAlignDialog}
                ></ResizableImage>
              )}
            </Box>
          )
        )}
        {!isReadOnly && (
          <Fade in={isHovered}>
            <Box>
              <MoreMenu
                handleCrop={handleCrop}
                handleRemoveNode={handleRemoveNode}
                empty={empty}
                setOpenDialog={setOpenDialog}
                type={EmbedElementType.IMAGE}
              ></MoreMenu>
            </Box>
          </Fade>
        )}
        <ImageCropDialog
          open={openCropDialog}
          onClose={() => setOpenCropDialog(false)}
          imageSrc={url}
          previewCanvasRef={previewCanvasRef}
          setCompletedCrop={setCompletedCrop}
          completedCrop={completedCrop}
          setPreview={setPreview}
          preview={preview}
          setMedia={setMedia}
          media={media}
          setIsCroppedImage={setIsCroppedImage}
        />

        <Dialog
          onClose={() => {
            setOpenDialog(false);
          }}
          open={openDialog}
          PaperProps={{
            style: {
              transition: "height 0.3s ease",
              height: `${contentHeight}px`,
              overflow: "hidden",
            },
          }}
          sx={{
            ".MuiPaper-root": {
              minWidth: 600,
              padding: 3,
              borderRadius: 2,
              backgroundColor: (theme) => theme.palette.background.paper,
            },
          }}
        >
          <Box sx={{ width: "100%", pb: 3 }} ref={contentRef}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tabs value={value} onChange={handleTabsChange}>
                <Tab label="Upload" {...a11yProps(0)} />
                <Tab label="Embed" {...a11yProps(1)} />
                <Tab label="Unsplash" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box sx={{ overflow: "hidden" }}>
                <FileUpload
                  handleFileChange={handleFileChange}
                  previewUrl={url}
                  selectedFile={selectedFile}
                  accept="image/*"
                ></FileUpload>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TextField
                variant="outlined"
                label="URL"
                value={url}
                helperText={
                  !isValidUrl(url) && !empty
                    ? "Paste valid link"
                    : "Paste any link to embed it into your component."
                }
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >,
                ) => {
                  setCompletedCrop(undefined);
                  setUrl(event.target.value);
                  const newMedia = {
                    ...media,
                    url: event.target.value,
                  };
                  setMedia(newMedia);
                }}
                autoFocus
                fullWidth
                error={!isValidUrl(url) && !empty}
              />
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                disabled={!isValidUrl(url)}
                onClick={() => {
                  handleSetNode({ url: url });
                  setOpenDialog(false);
                }}
                fullWidth
              >
                Embed link
              </Button>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Box sx={{ overflowY: "auto" }} height={"500px"}>
                <UnsplashImagePicker
                  handleUnsplashImageClick={handleUnsplashImageClick}
                ></UnsplashImagePicker>
              </Box>
            </CustomTabPanel>
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
};

export const ImageElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const [url, setUrl] = useState("");
    const [imageCaption, setImageCaption] = useState<string>("");

    const { children, element } = props;
    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    if (!path) return;
    const isReadOnly = ReactEditor.isReadOnly(editor as any);

    useEffect(() => {
      setNodes(editor, { url: url }, { at: path });
    }, [url]);

    const handleSetNode = (dataToSet: object) => {
      setNodes(editor, dataToSet, { at: path });
    };
    const handleChange = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
      setUrl(event.target.value);
    };
    const handleRemoveNode = () => {
      removeNodes(editor, { at: path });
    };

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".50rem" }}>
        <Box sx={{ visibility: "hidden", height: 0 }} contentEditable={false}>
          {children}
        </Box>
        <Image
          element={element}
          children={children}
          handleSetNode={handleSetNode}
          handleChange={handleChange}
          handleRemoveNode={handleRemoveNode}
          url={url}
          setUrl={setUrl}
          imageCaption={imageCaption}
          setImageCaption={setImageCaption}
          isReadOnly={isReadOnly}
        />
      </PlateElement>
    );
  },
);
