import clsx from "clsx";
import React, { ReactNode, ReactElement } from "react";
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Container,
  IconButton,
  useMediaQuery,
  Link,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Menu } from "@mui/icons-material";

import logo from "../app/logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  barContainer: {
    minWidth: "100vw",
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(10),
    },
  },
  menu: {
    color: theme.palette.common.black,
  },
  logo: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    "& svg": {
      width: theme.spacing(10),
    },
    "& span": {
      color: theme.palette.progress.red,
      textTransform: "uppercase",
      fontWeight: 600,
      fontSize: 10,
      margin: theme.spacing(0.5, 0, 0, 0.5),
    },
  },
  toolbar: {},
}));

type ToolbarProps = {
  className?: string;
  children?: ReactNode;
  actions?: Array<ReactNode>;
};

function Toolbar(props: ToolbarProps) {
  const { className, actions, ...other } = props;
  const s = useStyles();
  return <MuiToolbar className={clsx(s.toolbar, className)} {...other} />;
}

export interface AppBarProps {
  children?: ReactNode;
  title?: string;
  onMenuClick?: () => void;
  actions?: Array<ReactElement>;
  hideOnMobile?: boolean;
}

function AppBar(props: AppBarProps) {
  const { children, title, actions, onMenuClick, hideOnMobile, ...other } =
    props;
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const s = useStyles(props);

  return (
    <MuiAppBar
      className={s.root}
      position="relative"
      color="inherit"
      elevation={0}
      {...other}
    >
      <Box>
        {!mdUp && (
          <IconButton className={s.menu} onClick={onMenuClick} size="large">
            <Menu />
          </IconButton>
        )}
        <Typography
          className={s.barContainer}
          variant="h3"
          component="h1"
          sx={{
            color: "inherit",
            margin: 2,
            marginBottom: 0,
          }}
        >
          {title}
        </Typography>
      </Box>
    </MuiAppBar>
  );
}

const AdminBar = AppBar;

export { AdminBar };
