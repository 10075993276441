import _ from "lodash";
import dayjs from "dayjs";
import { NormalizedCurriculum } from "./curriculum/curriculum-slice";

interface Difference {
  path: string;
  valueA: any;
  valueB: any;
}

const DAYJS_DIFF_SLIPPAGE_SECONDS = 10;

/**
 * Deeply compares two NormalizedCurriculum objects and returns an array
 * of detected differences.
 *
 * Custom comparisons for:
 *  1. The "days" property: a stringified JSON array of booleans.
 *  2. The "content" and "messageContent" properties: compare after deserialization/serialization
 *  3. dayjs objects: ignores differences of up to fixed amount of seconds as slippage.
 *  4. Arrays:
 *     - If the path ends with ".positions" or is exactly "positions",
 *       compare elements in the same index order.
 *     - Otherwise, compare as if they are sets (ignoring element order).
 *  5. 'null' and 'undefined' are treated as equal.
 *  6. For all other cases, it falls back on Lodash's _.isEqual.
 *
 * @param a - The first NormalizedCurriculum object.
 * @param b - The second NormalizedCurriculum object.
 * @returns An array of Difference objects describing where the two inputs differ.
 */
export function compareNormalizedCurriculum(
  a: NormalizedCurriculum,
  b: NormalizedCurriculum,
): Difference[] {
  const differences: Difference[] = [];

  /**
   * Recursively compares two values at a given path. If a difference is found,
   * it is added to the `differences` array with the dot-notation path.
   */
  function compareValues(valueA: any, valueB: any, currentPath: string): void {
    // temporary ignore "positions" property
    if (currentPath.endsWith(".positions") || currentPath === "positions") {
      return;
    }

    // 1. If this is the "days" property, parse the stringified JSON array of booleans
    //    and compare the resulting arrays (or original strings if parsing fails).
    if (currentPath.endsWith(".days") || currentPath === "days") {
      const parsedA = parseDays(valueA);
      const parsedB = parseDays(valueB);

      if (!_.isEqual(parsedA, parsedB)) {
        differences.push({
          path: currentPath,
          valueA,
          valueB,
        });
      }
      return;
    }

    // 2. If this is the "content" or "messageContent" property,
    //    compare after deserialization/serialization.
    if (
      currentPath.endsWith(".content") ||
      currentPath.endsWith(".messageContent") ||
      currentPath === "content" ||
      currentPath === "messageContent"
    ) {
      const parsedA = formatJSON(valueA);
      const parsedB = formatJSON(valueB);

      if (!_.isEqual(parsedA, parsedB)) {
        differences.push({
          path: currentPath,
          valueA,
          valueB,
        });
      }
      return;
    }

    // 3. If both values are dayjs objects, compare their timestamps
    //    while allowing some slippage (in seconds).
    if (dayjs.isDayjs(valueA) && dayjs.isDayjs(valueB)) {
      const diffInSeconds = Math.abs(valueA.diff(valueB, "second"));
      if (diffInSeconds > DAYJS_DIFF_SLIPPAGE_SECONDS) {
        differences.push({
          path: currentPath,
          valueA: valueA?.toISOString?.() ?? valueA,
          valueB: valueB?.toISOString?.() ?? valueB,
        });
      }
      return;
    }

    // If exactly one of them is a dayjs object, they are different.
    if (dayjs.isDayjs(valueA) || dayjs.isDayjs(valueB)) {
      if (!_.isEqual(valueA, valueB)) {
        differences.push({
          path: currentPath,
          valueA: valueA?.toISOString?.() ?? valueA,
          valueB: valueB?.toISOString?.() ?? valueB,
        });
      }
      return;
    }

    // 4. If both values are arrays, handle them with compareArrays.
    if (Array.isArray(valueA) && Array.isArray(valueB)) {
      compareArrays(valueA, valueB, currentPath);
      return;
    }

    // 5. Cases where 'null' and 'undefined' are treated as equal:
    if (
      (valueA === null && valueB === undefined) ||
      (valueA === undefined && valueB === null)
    ) {
      return;
    }

    // 6. If neither is an object (or array), compare them directly.
    //    Use Lodash _.isEqual for any non-object, non-array type.
    if (!_.isObject(valueA) || !_.isObject(valueB)) {
      if (!_.isEqual(valueA, valueB)) {
        differences.push({
          path: currentPath,
          valueA,
          valueB,
        });
      }
      return;
    }

    // If both are plain objects, collect their keys and compare child properties.
    const keys = _.union(Object.keys(valueA), Object.keys(valueB));
    for (const key of keys) {
      const newPath = currentPath ? `${currentPath}.${key}` : key;
      compareValues(valueA[key], valueB[key], newPath);
    }
  }

  /**
   * Compares two arrays at the given path.
   *
   * - If the path is "positions" or ends with ".positions":
   *   Compare arrays in order (element by element).
   * - Otherwise:
   *   Compare arrays in a "set-like" manner, ignoring the element order.
   */
  function compareArrays(arrA: any[], arrB: any[], currentPath: string): void {
    const isPositionsPath =
      currentPath === "positions" || currentPath.endsWith(".positions");

    // 1) Compare in strict order for "positions" arrays.
    if (isPositionsPath) {
      if (arrA.length !== arrB.length) {
        differences.push({
          path: currentPath,
          valueA: arrA,
          valueB: arrB,
        });
        return;
      }
      // Compare each element by index.
      for (let i = 0; i < arrA.length; i++) {
        compareValues(arrA[i], arrB[i], `${currentPath}[${i}]`);
      }
    } else {
      // 2) Compare ignoring order for non-"positions" arrays.
      if (arrA.length !== arrB.length) {
        differences.push({
          path: currentPath,
          valueA: arrA,
          valueB: arrB,
        });
        return;
      }

      // Use a set-like comparison: find a matching element in arrB
      // for each element in arrA, and remove it from arrBcopy once matched.
      const arrBcopy = [...arrB];
      for (let i = 0; i < arrA.length; i++) {
        const aItem = arrA[i];

        let matchedIndex = -1;
        for (let j = 0; j < arrBcopy.length; j++) {
          const bCandidate = arrBcopy[j];
          if (_.isEqual(aItem, bCandidate)) {
            matchedIndex = j;
            break;
          }
        }

        // If we fail to find a match, report a difference and stop checking further.
        if (matchedIndex === -1) {
          differences.push({
            path: currentPath,
            valueA: arrA,
            valueB: arrB,
          });
          return;
        } else {
          arrBcopy.splice(matchedIndex, 1);
        }
      }
    }
  }

  /**
   * Attempts to parse the "days" property as a JSON array of booleans.
   * If parsing fails, the original string is returned, allowing normal
   * string comparison to detect differences.
   *
   * @param value - The value potentially containing JSON data for "days".
   * @returns An array of booleans if parsing succeeds, or the original value.
   */
  function parseDays(value: any): any {
    if (typeof value !== "string") {
      return value;
    }
    try {
      return JSON.parse(value);
    } catch {
      // If JSON parsing fails, treat as the original string.
      return value;
    }
  }

  // Initiate the recursive comparison from the root.
  compareValues(a, b, "");

  return differences;
}

// Helpers for json content
//

// alphabetically order keys of JSON.
const sortObjectKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  } else if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj)
      .sort()
      .reduce((sortedObj, key) => {
        sortedObj[key] = sortObjectKeys(obj[key]);
        return sortedObj;
      }, {});
  } else {
    return obj;
  }
};

const formatJSON = (contentString: string) => {
  const content = JSON.parse(contentString);
  const sortedContent = content.map(sortObjectKeys);
  return JSON.stringify(sortedContent, null, 2);
};
