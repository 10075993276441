import React, { useState, useEffect, ReactElement } from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

interface LoadingStepperProps {
  title: string | ReactElement;
  steps: string[];
  interval?: number;
  onComplete?: () => void;
}

const LoadingStepper = ({
  title,
  steps,
  interval = 1,
  onComplete,
}: LoadingStepperProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentStep((prev) => {
        if (prev === steps.length - 1) {
          clearInterval(timer);
          setComplete(true);
          onComplete?.();
          return prev;
        }
        return prev + 1;
      });
    }, interval * 1000);

    return () => clearInterval(timer);
  }, [steps.length, interval, onComplete]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "100%",
        maxWidth: 400,
        margin: "0 auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          fontWeight: 500,
          color: "text.primary",
        }}
      >
        {title}
      </Typography>

      <Box sx={{ position: "relative", height: 24 }}>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            style={{
              position: "absolute",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontWeight: 500,
              }}
            >
              {steps[currentStep]}
            </Typography>
          </motion.div>
        </AnimatePresence>
      </Box>

      <LinearProgress
        variant="determinate"
        value={complete ? 100 : (currentStep / steps.length) * 100}
        sx={{
          height: 6,
          borderRadius: 3,
          backgroundColor: "action.hover",
          "& .MuiLinearProgress-bar": {
            borderRadius: 3,
            backgroundColor: "primary.main",
          },
        }}
      />
    </Box>
  );
};

export default LoadingStepper;
