import { MenuItem } from "@mui/material";
import React from "react";
import GenericScheduleSelect, {
  IScheduleSelectProps,
} from "./generic/GenericScheduleSelect";
import { ProgramWeek } from "../../../../hooks/useProgramWeeks";

interface IStartWeekSelectProps {
  weeks: ProgramWeek[];
  endWeek: number;
  repeats: boolean;
  flashInputs?: boolean;
}

const StartWeekSelect = (
  props: IScheduleSelectProps<number> & IStartWeekSelectProps,
) => {
  const { value, onChange, weeks, endWeek, disabled, repeats, flashInputs } = props;

  const startWeekIndex = weeks.findIndex((w) => w.id === value);
  const endWeekIndex = weeks.findIndex((w) => w.id === endWeek);

  return (
    <>
      <GenericScheduleSelect
        title={repeats ? "Start week" : "On week"}
        value={value}
        enableSwitch={false}
        onChange={onChange}
        disabled={disabled}
        flashInputs={flashInputs}
      >
        {weeks.map((week, index) => (
          <MenuItem
            value={week.id}
            disabled={
              // `Start` before `End`
              index > endWeekIndex &&
              // `Till the end`
              endWeekIndex !== -1 &&
              // No repeats case
              startWeekIndex !== endWeekIndex
            }
          >
            Week {week.week}
          </MenuItem>
        ))}
      </GenericScheduleSelect>
    </>
  );
};

export default StartWeekSelect;
