import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Box, useTheme, BoxProps } from "@mui/material";

import { ActionButton } from "../button/ActionButton";

import { DropzoneUploadButton } from "../button/DropzoneUploadButton";
import { AssetType } from "../../constants";

import { getMimeTypes } from "../../utils/file";
import { ElementType } from "../editor/types/elements";

import EmptyAvatar from "../../icons/EmptyAvatar";
import { UserInfo } from "../../hooks/useCurrentUser";
import { Control, useForm, UseFormWatch } from "react-hook-form";
import { ClientCompleteSignUpSchema } from "../../utils/clientCompleteSignupSchema";
import { FieldsGroup } from "../fields/FieldsGroup";
import { CardAvatar } from "../card/CardAvatar";
import { Close as CloseIcon } from "@mui/icons-material";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {},

  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  empty: {
    maxWidth: "100%",
    height: theme.spacing(20),
  },

  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    position: "relative",
    "& > *": {
      borderRadius: theme.spacing(1),
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  },

  buttons: {
    textAlign: "center",
    width: "100%",

    marginTop: theme.spacing(2),
    "& > *": {
      margin: theme.spacing(0.5, 0),
    },

    [theme.breakpoints.up("sm")]: {
      marginBottom: "auto",
    },
  },

  uploadButton: {
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,

    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },

  uploadButtonText: {
    color: theme.palette.secondary.contrastText,
    fontWeight: 700,
  },

  uploadAnotherButton: {
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.background.default,
    backgroundColor: theme.palette.background.default,

    "&:hover": {
      backgroundColor: colorSystem.gray9,
    },
  },

  skipButton: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    fontSize: 14,
  },

  header: {
    margin: 0,
  },

  resetButton: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5),
    height: 20,
    width: 20,
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export interface SignupClientCompletePhotoScreenProps extends BoxProps {
  user: UserInfo;
  control: Control<ClientCompleteSignUpSchema>;
  setValue: ReturnType<typeof useForm<ClientCompleteSignUpSchema>>["setValue"];
  watch: UseFormWatch<ClientCompleteSignUpSchema>;
  disabled?: boolean;
  error?: any;
  handleSubmit: (
    event: React.MouseEvent<HTMLFormElement | HTMLButtonElement>,
  ) => void;
}

export function SignupClientCompletePhotoScreen(
  props: SignupClientCompletePhotoScreenProps,
) {
  const s = useStyles();
  const {
    className,
    user,
    watch,
    setValue,
    control,
    disabled,
    handleSubmit,
    ...other
  } = props;
  const [uploadedImage, setUploadedImage] = React.useState<string>();
  const [isUploading, setUploading] = React.useState(false);

  const handleReset = React.useCallback(() => {
    setUploadedImage(null);
  }, []);

  const image = uploadedImage;

  const handleSave = React.useCallback(
    (event) => {
      setValue("picture", image);
      handleSubmit(event);
    },
    [uploadedImage, handleSubmit],
  );

  const theme = useTheme();

  return (
    <FieldsGroup
      className={clsx(s.root, className)}
      label="Profile photo"
      {...other}
    >
      {image ? (
        <Box className={s.image}>
          <Button
            variant="text"
            color="secondary"
            className={s.resetButton}
            onClick={handleReset}
          >
            Reset <CloseIcon />
          </Button>{" "}
          <CardAvatar src={image} />
        </Box>
      ) : (
        <EmptyAvatar className={s.empty} fill={theme.palette.primary.main} />
      )}

      <Box className={s.buttons}>
        {image ? (
          <>
            <ActionButton
              size="large"
              onClick={(e) => handleSave(e)}
              fullWidth
              name="saveButton"
              disabled={disabled}
            >
              Save profile photo and finish
            </ActionButton>
            <DropzoneUploadButton
              classes={{
                root: s.uploadAnotherButton,
                text: s.skipButton,
              }}
              onChange={setUploadedImage}
              onUploading={setUploading}
              assetType={AssetType.USER_PHOTO}
              disabled={disabled || isUploading}
              mimeType={getMimeTypes(ElementType.IMAGE)}
              color="secondary"
              inputName="photoURL"
              text="Upload another photo"
            />
          </>
        ) : (
          <>
            <DropzoneUploadButton
              classes={{
                root: s.uploadButton,
                text: s.uploadButtonText,
              }}
              onChange={setUploadedImage}
              onUploading={setUploading}
              assetType={AssetType.USER_PHOTO}
              mimeType={getMimeTypes(ElementType.IMAGE)}
              inputName="photoURL"
              text="Upload photo"
              color="secondary"
              disabled={disabled || isUploading}
            />
            <Button
              className={s.skipButton}
              variant="text"
              fullWidth
              onClick={(e) => handleSubmit(e)}
              name="skipAndSaveButton"
              disabled={disabled || isUploading}
            >
              Skip for now and finish
            </Button>
          </>
        )}
      </Box>
    </FieldsGroup>
  );
}
