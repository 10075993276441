import { Tooltip, Typography, alpha } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { makeStyles } from "@mui/styles";

interface IMinimizedTooltipProps {
  children: ReactElement;
  title: React.ReactNode;
  delayNextEnter?: boolean;
  opacity?: number;
  placement?:
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  disableHoverListener?: boolean;
  disableFocusListener?: boolean;
  disableTouchListener?: boolean;
  isExerciseLibrary?: boolean;
}

const useStyles = makeStyles({
  exerciseLibraryPopper: {
    zIndex: "999999 !important",
  },
});

export const TooltipLine = ({ children }: { children: ReactNode }) => (
  <Typography variant="hint">{children}</Typography>
);

const MinimizedTooltip = ({
  children,
  placement,
  title,
  opacity = 0.5,
  delayNextEnter = true,
  disableHoverListener,
  disableFocusListener = true,
  disableTouchListener,
  isExerciseLibrary = false,
}: IMinimizedTooltipProps) => {
  const s = useStyles();
  return (
    <Tooltip
      arrow
      placement={placement}
      enterDelay={300}
      enterNextDelay={delayNextEnter ? 3000 : 300}
      disableInteractive
      title={title}
      disableHoverListener={disableHoverListener}
      disableFocusListener={disableFocusListener}
      disableTouchListener={disableTouchListener}
      classes={{
        popper: isExerciseLibrary && s.exerciseLibraryPopper,
      }}
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: (theme) => alpha(theme.palette.common.black, opacity),
            px: 1,
            py: 0.8,
            userSelect: "none",
          },
        },
        arrow: {
          sx: {
            color: (theme) => alpha(theme.palette.common.black, opacity),
          },
        },
      }}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default MinimizedTooltip;
