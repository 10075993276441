import React from "react";
import { NavigationItem } from "../../../../constants";
import {
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  COACH_PROGRAM_CURRICULUM_ROUTE,
  COACH_PROGRAM_ENROLLMENTS_ROUTE,
  COACH_PROGRAM_SETTING_ROUTE,
} from "../../../routes";
import InlineCounter from "../../../../components/loading/InlineCounter";

export const programsBreadcrumb: NavigationItem = {
  name: "Programs",
  link: COACH_PROGRAMS_PUBLISHED_ROUTE,
};

export const coachProgramNavigation = (
  slug: string,
  enrollmentsCount: number,
): NavigationItem[] => [
  {
    name: "Curriculum",
    link: COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", slug),
  },
  {
    name: (
      <InlineCounter
        label="Enrollments"
        count={enrollmentsCount}
        loading={!enrollmentsCount && enrollmentsCount !== 0}
      />
    ),
    link: COACH_PROGRAM_ENROLLMENTS_ROUTE.replace(":slug", slug),
  },
  {
    name: "Settings",
    link: COACH_PROGRAM_SETTING_ROUTE.replace(":slug", slug),
  },
];
