import React from "react";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { DebugWarning } from "../../redux/api/api-slice";

interface DebugWarningsListProps {
  warnings: DebugWarning[];
}

const DebugWarningsList: React.FC<DebugWarningsListProps> = ({ warnings }) => {
  const theme = useTheme();

  // Only show the first five warnings
  const displayedWarnings = warnings.slice(0, 5);

  // Helper to stringify and truncate a value for display
  const shorten = (value: string, maxLength: number = 40) => {
    const jsonValue =
      value === undefined
        ? "undefined"
        : value === null
          ? "null"
          : JSON.stringify(value);
    if (jsonValue.length > maxLength) {
      return jsonValue.slice(0, maxLength) + "...";
    }
    return jsonValue;
  };

  const labelBoxStyle = {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    padding: "0px 6px",
    borderRadius: "4px",
    fontSize: "0.75rem",
    marginRight: "6px",
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Detected {warnings.length} inconsistencies
      </Typography>

      {displayedWarnings.map((warning, index) => (
        <Paper
          key={index}
          sx={{
            width: "100%",
            p: 0,
            marginBlock: 2,
            marginInline: 0,
          }}
          variant="outlined"
        >
          {/* Path (italic and grayish, no label) */}
          <Typography
            variant="body2"
            gutterBottom
            sx={{ fontStyle: "italic", color: theme.palette.grey[600], mb: 1 }}
          >
            {warning.path}
          </Typography>

          {/* "got" label in a small grayish box */}
          <Typography variant="body2" gutterBottom>
            <Box component="span" sx={labelBoxStyle}>
              got
            </Box>
            {shorten(warning.optimistic)}
          </Typography>

          {/* "exp" label in a small grayish box */}
          <Typography variant="body2">
            <Box component="span" sx={labelBoxStyle}>
              exp
            </Box>
            {shorten(warning.actual)}
          </Typography>
        </Paper>
      ))}
    </Box>
  );
};

export default DebugWarningsList;
