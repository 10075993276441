import clsx from "clsx";
import React from "react";
import {
  BoxProps,
  Box,
  Typography,
  Divider,
  Button,
  darken,
  useMediaQuery,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { WorkoutSection } from "../workout/types";
import {
  countExercises,
  getExtraColumnLabel,
  getRepsColumnLabel,
  hasExtraColumn,
  parseWorkoutResultValueJsonToWorkoutSection,
} from "../workout/utils";
import { Link } from "../link/Link";
import WorkoutSet from "../workout/workout-set/WorkoutSet";
import { colorSystem } from "../../theme";
import {
  ComponentType,
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
  UserRole,
} from "../../constants";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { unitSetLabel } from "../../utils/units";

import { TrainingSummaryWellBegin } from "../training-summary/TrainingSummaryWellBegin";
import { useWorkoutTableStyles } from "../workout/tableStyles";
import {
  ActivityDto,
  WorkoutExerciseResultDto,
} from "@growth-machine-llc/stridist-api-client";
import { ProgressImageInfo } from "../new-editor/components/checkInQuestions/ProgressPhotoQuestion";
import { ZoomableImage } from "../image/ZoomableImage";

import SwipeableViews from "react-swipeable-views";
import Dots from "../startWorkout/Dots";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },

  setsContainer: {
    maxWidth: 560,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
  },

  header: {
    display: "flex",
    color: colorSystem.gray,
    fontWeight: 500,
    marginBottom: 12,
  },

  exercise: {
    position: "relative",

    "&::after": {
      content: "''",
      display: "block",

      width: theme.spacing(2),
      height: theme.spacing(2),

      position: "absolute",
      left: 0,
      top: theme.spacing(0.25),

      borderRadius: "50%",
      borderColor: theme.palette.secondary.main,
      borderWidth: theme.spacing(0.25),
      borderStyle: "solid",
      backgroundColor: theme.palette.background.paper,

      zIndex: 2,
    },
  },

  divider: {
    backgroundColor: theme.palette.grey[100],
    margin: theme.spacing(2, 0, 2, 3),
  },

  dots: {
    marginTop: 10,
  },

  before: {
    content: "''",
    display: "block",
    position: "absolute",
    width: 2,
    transform: `translateY(calc(10px))`,
    left: 7,
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
  },

  exerciseTitle: {
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontStyle: "italic",
    paddingLeft: theme.spacing(3),
  },

  exerciseResult: {
    fontWeight: 500,
    fontSize: 16,
  },

  flex: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },

  button: {
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.light, 0.2),
    },
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },

  emptyResult: {
    backgroundColor: theme.palette.grey[100],
    height: "42px",
    display: "flex",
    alignItems: "center",
    borderRadius: 4,
    fontSize: 14,
    fontStyle: "italic",
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 3),
  },

  imageContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    overflowX: "auto",
    width: "100%",
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up("sm")]: {
      flexFlow: "row nowrap",
    },
  },

  image: {
    width: "100%",
    height: theme.spacing(35),
    border: "1px solid",
    borderColor: theme.palette.border.primary,
    borderRadius: theme.spacing(1),
    objectFit: "cover",
    margin: theme.spacing(0, 2, 2, 0),

    "&:not(:last-child)": {
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
        marginRight: theme.spacing(1),
      },
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "30%",
      margin: 0,
    },
  },
}));

export interface ActivityStartWorkout {
  mindValue: string;
  bodyValue: string;
}

export interface ActivityEndWorkout {
  feel: string;
  notes: string;
  time: number;
  media: ProgressImageInfo[];
}

export interface ActivityWorkoutResponseProps extends BoxProps {
  workoutExerciseResult: WorkoutExerciseResultDto;
  handleOpenVideo?: (url) => void;
  activity?: ActivityDto;
}

export function ActivityWorkoutResponse({
  workoutExerciseResult,
  className,
  handleOpenVideo,
  activity,
  ...other
}: ActivityWorkoutResponseProps) {
  const { title, exercises } = parseWorkoutResultValueJsonToWorkoutSection(
    workoutExerciseResult.value,
  );

  const [selectedPhoto, setSelectedPhoto] = React.useState<number>(0);

  const s = useStyles(exercises);
  const tableStyles = useWorkoutTableStyles();
  const counters = countExercises(exercises);
  const user = useCurrentUser();

  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const isCoach = user.role === UserRole.COACH;
  const date = activity?.date.format("YYYY-MM-DD");
  const linkHref = `${isCoach ? "/coach" : ""}/programs/summary/${
    activity?.enrollment?.program.slug
  }/${date}/${activity?.component?.slug}/${
    isCoach ? activity?.client?.username : ""
  }`;

  const startWorkout: ActivityStartWorkout = React.useMemo(
    () => JSON.parse(activity.startWorkout) || [],
    [activity.startWorkout],
  );
  const endWorkout: ActivityEndWorkout = React.useMemo(
    () => JSON.parse(activity.endWorkout) || [],
    [activity.endWorkout],
  );

  const handlePageChange = React.useCallback(
    (page: number) => {
      setSelectedPhoto(
        selectedPhoto + page > 0
          ? page % endWorkout.media?.length
          : endWorkout.media?.length - 1,
      );
    },
    [endWorkout.media?.length, selectedPhoto],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: 2,
          }}
        >
          {title && <Typography className={s.title}>{title}</Typography>}
          {activity?.component?.type === ComponentType.WORKOUT && (
            <Button
              LinkComponent={Link}
              href={linkHref}
              color="primary"
              variant="outlined"
              size="small"
            >
              View Summary
            </Button>
          )}
        </Box>
        <Box
          sx={{
            pt: 2,
          }}
        >
          <TrainingSummaryWellBegin
            shortened
            startWorkout={startWorkout}
            endWorkout={endWorkout}
          />
        </Box>
      </Box>
      <Box className={s.imageContainer}>
        {isSmDown ? (
          <>
            <SwipeableViews
              index={selectedPhoto}
              onChangeIndex={handlePageChange}
              slideStyle={{ overflow: "hidden", padding: 10 }}
            >
              {Array.isArray(endWorkout.media) &&
                endWorkout.media?.map((media, index) => (
                  <ZoomableImage
                    className={s.image}
                    src={media.url}
                    alt={media.fileName}
                    key={index}
                  />
                ))}
            </SwipeableViews>
            <Dots
              className={s.dots}
              activeDot={selectedPhoto}
              dots={endWorkout.media?.length || 0}
            />
          </>
        ) : (
          Array.isArray(endWorkout.media) &&
          endWorkout.media?.map((media, index) => (
            <ZoomableImage
              className={s.image}
              src={media.url}
              alt={media.fileName}
              key={index}
            />
          ))
        )}
      </Box>
      {exercises.map((exercise, index) => (
        <React.Fragment key={exercise.id}>
          {index !== 0 && <Divider className={s.divider} />}
          <Box className={clsx(s.exercise)}>
            {counters[index] >= 1 &&
              counters[index] < counters[index + 1] &&
              Array.isArray(exercises[index + 1]?.sets) && (
                <div
                  style={{
                    height: `calc(100% + 40px)`,
                  }}
                  className={s.before}
                />
              )}
            <Typography className={s.exerciseTitle}>
              {exercise.title}
            </Typography>
            <div className={s.setsContainer}>
              {Array.isArray(exercise.result) ? (
                exercise.result.length === 0 ? (
                  <Box className={s.emptyResult}>{"no completed sets..."}</Box>
                ) : (
                  <>
                    <div
                      className={clsx(
                        s.header,
                        tableStyles.header,
                        hasExtraColumn(exercise?.typeExtraMeasurement) &&
                          tableStyles.headerWithExtraColumn,
                      )}
                    >
                      <div className={clsx(tableStyles.cell, tableStyles.set)}>
                        Set
                      </div>
                      <div className={clsx(tableStyles.cell, tableStyles.reps)}>
                        {getRepsColumnLabel(exercise?.typeReps, true)}
                      </div>
                      <div
                        className={clsx(tableStyles.cell, tableStyles.weight)}
                      >
                        {unitSetLabel(
                          exercise?.units || user.units,
                          exercise?.typeSet || ExerciseTypeSet.WEIGHT,
                        )}
                      </div>
                      {hasExtraColumn(exercise?.typeExtraMeasurement) && (
                        <div
                          className={clsx(
                            tableStyles.cell,
                            tableStyles.extraColumn,
                          )}
                        >
                          {getExtraColumnLabel(exercise?.typeExtraMeasurement)}
                        </div>
                      )}
                      <div
                        className={clsx(
                          tableStyles.cell,
                          tableStyles.result,
                          history && tableStyles.resultHistory,
                        )}
                      ></div>
                    </div>
                    {exercise.result?.map((set, index) => (
                      <WorkoutSet
                        key={index}
                        set={set as any} // TODO alight ResultSet and Sets types
                        setNumber={index}
                        history
                        typeExtraMeasurement={
                          exercise?.typeExtraMeasurement ||
                          ExerciseTypeExtra.NONE
                        }
                        typeReps={exercise?.typeReps || ExerciseTypeReps.WHOLE}
                        typeSet={exercise?.typeSet || ExerciseTypeSet.WEIGHT}
                      />
                    ))}
                  </>
                )
              ) : (
                <>
                  <Box>{exercise?.result}</Box>
                </>
              )}
            </div>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
}
