import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField, Box, Typography, Button } from "@mui/material";
import Skeleton from "@mui/lab/Skeleton";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ADMIN_EXERCISES_ROUTE } from "../../routes/routes";
import { ExercisesTableRow } from "./ExercisesTableRow";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  ExerciseAssetsType,
  AdminExerciseAssetDto,
  BodypartType,
  MovementPatternType,
} from "@growth-machine-llc/stridist-api-client";
import AdminService from "../../services/AdminService";
import { DollarSign } from "lucide-react";
import { EditorProgramContext } from "../new-editor/hooks";
import { AdminWorkoutDrawer } from "./AdminWorkoutDrawer";
import { ToggleButtonWithTooltip } from "./ToogleButtonWithTooltip";
import { parseBooleanString, toggleBooleanString } from "./ProgramsTable";
import {
  createInitialFilters,
  ExerciseCategoriesFilter,
} from "./filters/ExerciseCategoriesFilters";
import {
  BodypartTypeLabels,
  MovementPatternTypeLabels,
} from "../exercise-library/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
    padding: 1,
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(3),
  },

  tabs: {
    "& span": {
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "uppercase",
    },
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.selected.main,
  },

  container: {
    margin: theme.spacing(3, 2),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 6),
    },
  },

  searchContainer: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    justifyContent: "space-between",
    gap: theme.spacing(5),
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(2),
    },
  },

  searchInput: {
    "& input": {
      fontSize: 15,
      padding: theme.spacing(2),
      minWidth: theme.spacing(50),
    },
    marginBottom: theme.spacing(2),
  },

  summary: {
    margin: theme.spacing(1.5, 0),
    fontWeight: 500,
    fontSize: 16,
  },

  table: {
    tableLayout: "auto",
    minWidth: 650,

    "& th": {
      fontWeight: "bold",
      fontSize: 15,
    },

    "& td": {
      fontWeight: 500,
      fontSize: 14,
    },

    "& tfoot td": {
      borderBottom: "none",
    },
  },
  createButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  search: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export interface ExercisesTableProps {}

export const getBodypartTypes = (filters: Record<BodypartType, boolean>) =>
  Object.entries(filters)
    .filter(([, enabled]) => enabled)
    .map(([type]) => type) as BodypartType[];

export const getMovementPatternTypes = (
  filters: Record<MovementPatternType, boolean>,
) => {
  return Object.entries(filters)
    .filter(([, enabled]) => enabled)
    .map(([type]) => type) as MovementPatternType[];
};

export const ADMIN_EXERCISES_ASSETS_KEY_QUERY_KEY = "admin-exercises";

const filterInputHeightProps = {
  minHeight: 40,
  height: 40,
};

export function ExercisesTable(props: ExercisesTableProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    page: "0",
    perPage: "5",
    assetName: null,
    coachId: null,
    type: null,
  });
  const page = parseInt(queryParams.page) || 0;
  const rowsPerPage = parseInt(queryParams.perPage) || 5;
  const [exerciseAssetDrawerOpen, setExerciseAssetDrawerOpen] =
    React.useState(false);
  const [exerciseAsset, setExerciseAsset] =
    React.useState<AdminExerciseAssetDto>(null);
  const [searchParams] = useSearchParams();

  const typeFromParam = searchParams.get("type");
  const selectedType =
    typeFromParam === null
      ? ExerciseAssetsType.LIBRARY
      : (typeFromParam as ExerciseAssetsType);

  const initialBodypartFilters = () => createInitialFilters(BodypartType);

  const initialMovementPatternsFilters = React.useMemo(
    () => createInitialFilters(MovementPatternType),
    [],
  );

  const [bodypartFilters, setBodypartFilters] = React.useState(
    initialBodypartFilters,
  );

  const [movementPatternFilters, setMovementPatternFilters] = React.useState(
    initialMovementPatternsFilters,
  );

  const querySearchByName = queryParams.assetName || "";
  const [searchByName, setSearchByName] = useState(querySearchByName);

  const querySearchByCoachId = queryParams.coachId || "";
  const [searchByCoachId, setSearchByCoachId] = useState(querySearchByCoachId);

  const { data: exerciseAssetItems, isFetching } = useQuery({
    queryKey: [
      ADMIN_EXERCISES_ASSETS_KEY_QUERY_KEY,
      { queryParams, bodypartFilters, movementPatternFilters },
    ],
    queryFn: () =>
      AdminService.getExerciseCustomAssetsByType(
        selectedType,
        page + 1,
        rowsPerPage,
        querySearchByName,
        parseInt(querySearchByCoachId) || null,
        parseBooleanString(queryParams.activeSubscription),
        getBodypartTypes(bodypartFilters),
        getMovementPatternTypes(movementPatternFilters),
      ),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (
      querySearchByName !== searchByName ||
      querySearchByCoachId !== searchByCoachId
    ) {
      const timer = setTimeout(() => {
        setQueryParams({
          assetName: searchByName,
          coachId: searchByCoachId,
          page: "0",
          activeSubscription: "true",
        });
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [
    querySearchByCoachId,
    querySearchByName,
    searchByCoachId,
    searchByName,
    setQueryParams,
  ]);

  const handleChangePage = (event, newPage) => {
    setQueryParams({
      page: newPage.toString(),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({
      perPage: event.target.value,
      page: "0",
    });
  };

  const handleTabChange = (event, value) => {
    setSearchByName("");
    setSearchByCoachId("");
    setQueryParams({
      type: value,
      page: "0",
      assetName: null,
      coachId: null,
    });
    navigate(
      ADMIN_EXERCISES_ROUTE +
        `?page=0&type=${value}&perPage=${queryParams.perPage}`,
    );
  };

  const handleActiveSubscriptionToggle = () => {
    setQueryParams({
      activeSubscription: toggleBooleanString(queryParams.activeSubscription),
    });
  };

  const handleSelectExercise = (exerciseAsset: AdminExerciseAssetDto) => {
    setExerciseAsset(exerciseAsset);
    setExerciseAssetDrawerOpen(true);
  };

  const handleCloseExerciseDrawer = () => {
    setExerciseAssetDrawerOpen(false);
    setExerciseAsset(null);
  };

  const emptyRows = Array.from(new Array(rowsPerPage));
  return (
    <Paper className={s.root}>
      <Tabs
        className={s.tabs}
        value={selectedType}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab label="Library" value={ExerciseAssetsType.LIBRARY} />
        <Tab label="Private" value={ExerciseAssetsType.PRIVATE} />
      </Tabs>

      <Box className={s.container}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Box className={s.searchContainer}>
            <Box gap={2} display="flex">
              <TextField
                className={s.searchInput}
                variant="outlined"
                placeholder="Search records by name"
                value={searchByName}
                onChange={(e) => setSearchByName(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    ...filterInputHeightProps,

                    "& fieldset": {
                      borderRadius: (theme) => theme.shape.borderRadius / 3,
                    },
                  },
                }}
              />

              {selectedType === ExerciseAssetsType.PRIVATE && (
                <>
                  <TextField
                    className={s.searchInput}
                    variant="outlined"
                    placeholder="Search records by coach id"
                    value={searchByCoachId}
                    onChange={(e) => setSearchByCoachId(e.target.value)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...filterInputHeightProps,

                        "& fieldset": {
                          borderRadius: (theme) => theme.shape.borderRadius / 3,
                        },
                      },
                    }}
                  />
                  <ToggleButtonWithTooltip
                    value={"subscription"}
                    icon={<DollarSign />}
                    toggleFlag={parseBooleanString(
                      queryParams.activeSubscription,
                    )}
                    onToggle={handleActiveSubscriptionToggle}
                    tooltipTitle="Active coaches"
                    tooltipPlacement="top"
                  />
                </>
              )}
            </Box>
            <Box>
              {selectedType === ExerciseAssetsType.LIBRARY && (
                <Button
                  className={s.createButton}
                  variant="contained"
                  onClick={() => {
                    setExerciseAssetDrawerOpen(true);
                  }}
                >
                  Create public exercise asset
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <ExerciseCategoriesFilter
            filterType={Object.keys(BodypartType) as unknown as BodypartType}
            filters={bodypartFilters}
            onChange={setBodypartFilters}
            filterTypesLabels={BodypartTypeLabels}
            labelType="bodypart"
          />

          <ExerciseCategoriesFilter
            filterType={
              Object.keys(MovementPatternType) as unknown as MovementPatternType
            }
            filters={movementPatternFilters}
            onChange={(filters) => setMovementPatternFilters(filters)}
            filterTypesLabels={MovementPatternTypeLabels}
            labelType="movement pattern"
          />
        </Box>
        <Typography className={s.summary}>
          {exerciseAssetItems?.totalCount || 0} record
          {exerciseAssetItems?.totalCount === 1 ? "" : "s"} found
        </Typography>

        <Table className={s.table} aria-label="simple table">
          {isFetching ? (
            <>
              <TableHead>
                <TableRow>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {emptyRows.map((_, index) => (
                  <TableRow sx={{ height: 80 }} key={index}>
                    {Array.from({ length: 5 }).map((_, cellIndex) => (
                      <TableCell key={cellIndex} align="center">
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Video</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Asset Id</TableCell>
                  <TableCell align="center">History</TableCell>
                  <TableCell align="center">No. Media</TableCell>
                  <TableCell align="center">Sets</TableCell>
                  {selectedType === ExerciseAssetsType.PRIVATE && (
                    <TableCell align="center">Coach</TableCell>
                  )}
                  <TableCell align="center">Last Actions</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exerciseAssetItems.items.flatMap((e, index) => (
                  <ExercisesTableRow
                    exerciseInfo={e}
                    type={selectedType}
                    key={index}
                    onSelectedExercise={handleSelectExercise}
                  />
                ))}

                {!exerciseAssetItems.items.length && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={
                        selectedType === ExerciseAssetsType.PRIVATE ? 9 : 8
                      }
                    >
                      No exercises found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={selectedType === ExerciseAssetsType.PRIVATE ? 9 : 8}
                count={exerciseAssetItems?.totalCount || 0}
                rowsPerPage={rowsPerPage}
                page={Number(page)}
                showFirstButton
                showLastButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <EditorProgramContext.Provider value={{ programId: 0 }}>
          {exerciseAssetDrawerOpen && (
            <AdminWorkoutDrawer
              exerciseAsset={exerciseAsset}
              onUpdate={() => {
                setExerciseAssetDrawerOpen(false);
              }}
              onCancel={handleCloseExerciseDrawer}
              onClose={handleCloseExerciseDrawer}
              onRemove={handleCloseExerciseDrawer}
              open={exerciseAssetDrawerOpen}
            />
          )}
        </EditorProgramContext.Provider>
      </Box>
    </Paper>
  );
}
