import clsx from "clsx";
import React from "react";
import { ListItemButton, ListItemButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { UserRole } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { COACH_ROUTE, GROUPS_ROUTE } from "../../routes/routes";
import { useDrawer } from "../../hooks/useDrawer";
import { APP_DRAWER_ICON_WIDTH } from "../app/AppDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
    display: "block",
    padding: theme.spacing(0.5, 0),
    // HACK: add extra padding that matched icon width to align subitems with menu item
    paddingLeft: `${APP_DRAWER_ICON_WIDTH}px`,
    borderRadius: theme.spacing(0.5),
    textTransform: "uppercase",
    margin: theme.spacing(1, 0),

    "& span": {
      maxWidth: theme.spacing(18),
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden",
    },
  },

  active: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}19`,

    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}40`,
    },
  },
}));

export interface GroupNavItemListItemProps extends ListItemButtonProps {
  slug: string;
  name: string;
}

export function GroupNavItemListItem(props: GroupNavItemListItemProps) {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useDrawer();
  const { className, slug, name, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const location = useLocation();
  const active = location.pathname.includes(slug);

  const handleClick = React.useCallback(() => {
    navigate(
      `${user.role === UserRole.COACH ? COACH_ROUTE : ""}${GROUPS_ROUTE + "/" + slug + "/posts"}`,
      { state: { title: name } },
    );
    setDrawerOpen(false);
  }, [slug, user.role]);

  return (
    <ListItemButton
      className={clsx(s.root, className, active && s.active)}
      onClick={handleClick}
      {...other}
    >
      <span>{name}</span>
    </ListItemButton>
  );
}
