import React from "react";
import { CoachSettingsAccount } from "../../../../components/settings/CoachSettingsAccount";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";

export const COACH_NOTIFICATIONS_SETTINGS_QUERY_KEY =
  "coach-notifications-settings";

export function CoachSettingsProfileRoute() {
  const me = useCurrentUser();

  return (
    <>
      {me && <CoachSettingsAccount user={me} />}
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Settings",
        }}
      />
    </>
  );
}
