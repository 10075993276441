import React, { useContext, useRef, useEffect } from "react";
import { Programs } from "../../../../components/client-programs/Programs";
import { EnrollmentStatus } from "../../../../constants";
import { useQuery } from "@tanstack/react-query";
import EnrollmentsClientService from "../../../../services/EnrollmentsClientService";
import ProgramsContext from "../../../../contexts/ClientProgramsContext";
import useInfiniteScrollQuery from "../../../../hooks/useInfiniteScroll";
import { Box, Typography } from "@mui/material";
import { InfoBox } from "../../../../components/info/InfoBox";

interface IClientProgramListRouteProps {
  status: EnrollmentStatus;
}

export const CLIENT_PROGRAMS_LIST_QUERY_KEY = "CLIENT_PROGRAMS_LIST_QUERY_KEY";
function ClientProgramListRoute({ status }: IClientProgramListRouteProps) {
  const CLIENT_ENROLLMENTS_STATUS_COUNT = "CLIENT_ENROLLMENTS_STATUS_COUNT";
  const CLIENT_PROGRAMS_ACTIVE_LIST_PAGE_SIZE = 10;
  const CLIENT_PROGRAMS_UPCOMING_PAST_LIST_PAGE_SIZE = 30;
  const context = useContext(ProgramsContext);
  const page_size =
    status === EnrollmentStatus.ACTIVE
      ? CLIENT_PROGRAMS_ACTIVE_LIST_PAGE_SIZE
      : CLIENT_PROGRAMS_UPCOMING_PAST_LIST_PAGE_SIZE;

  const {
    data: enrollmentsData,
    isLoading: enrollmentsLoading,
    ref: observeElementRef,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [CLIENT_PROGRAMS_LIST_QUERY_KEY, { status, page_size }],
    queryFn: ({ pageParam = 1 }) =>
      EnrollmentsClientService.getClientEnrolments({
        status,
        pageNumber: pageParam as number,
        pageSize: page_size,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const enrollments =
    enrollmentsData?.pages.flatMap((page) => page.items) ?? [];

  const { data: enrollmentsCountData, isLoading: enrollmentCountLoading } =
    useQuery({
      queryKey: [CLIENT_ENROLLMENTS_STATUS_COUNT],
      queryFn: () => EnrollmentsClientService.getClientEnrolmentsCount(),
    });

  React.useEffect(() => {
    enrollmentsCountData && context.setEnrollmentsCount(enrollmentsCountData);
  }, [enrollments]);

  const isLoading =
    enrollmentsLoading ||
    enrollmentCountLoading ||
    (isFetching && !isFetchingNextPage);

  return (
    <>
      {!isLoading && enrollments.length === 0 && (
        <Box
          sx={{
            p: 4,
          }}
        >
          <InfoBox
            title={`No ${status.toLocaleLowerCase()} programs`}
            subtitle={
              status === EnrollmentStatus.ACTIVE
                ? "Don't worry, your coach will assign you a new program soon."
                : `You don't have any ${status.toLocaleLowerCase()} programs.`
            }
            sx={{
              mt: 10,
            }}
          />
        </Box>
      )}
      <Programs
        enrollments={enrollments}
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        observeRef={observeElementRef}
      />
    </>
  );
}

export const ClientProgramsActiveRoute = () => {
  return <ClientProgramListRoute status={EnrollmentStatus.ACTIVE} />;
};

export const ClientProgramsUpcomingRoute = () => {
  return <ClientProgramListRoute status={EnrollmentStatus.UPCOMING} />;
};

export const ClientProgramsPastRoute = () => {
  return <ClientProgramListRoute status={EnrollmentStatus.PAST} />;
};
