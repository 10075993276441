import React from "react";
import {
  Grid2,
  Card,
  CardHeader,
  Skeleton,
  Box,
  CardProps,
} from "@mui/material";

export interface NotesCardSkeletonProps extends CardProps {}

export const NotesCardSkeleton = (props: NotesCardSkeletonProps) => (
  <Card {...props}>
    <CardHeader
      title={<Skeleton width={300} animation="wave" />}
      subheader={
        <Grid2
          size={{
            xs: 6,
            md: 3,
          }}
        >
          <Skeleton width={100} animation="wave" />
          <Box
            sx={{
              mt: 4,
            }}
          >
            {Array.from({ length: 3 }).map((_, i) => (
              <Skeleton key={i} animation="wave" />
            ))}
          </Box>
        </Grid2>
      }
    />
  </Card>
);
