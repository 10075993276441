import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { CurriculumComponent } from "../../redux/types";
import OverrideCreationDialog, {
  isOverrrideCreationEnabled,
  useUrlClickedDay,
} from "./OverrideCreationDialog";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectWeekNumberByWeekId } from "../../redux/curriculum/selectors/curriculum";

export interface CoachComponentBarOverrideProps {
  component: Omit<CurriculumComponent, "overrides">;
  repeatsAmount: number;
  onConfirmPublishOverride: (selected: { week: number; day: number }) => void;
  onOverrideReady: (overrideSlug: string) => void;
}

const CoachComponentBarOverride = ({
  component,
  repeatsAmount,
  onConfirmPublishOverride,
  onOverrideReady,
}: CoachComponentBarOverrideProps) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const initialSelectedDay = useUrlClickedDay();

  const startWeekNum = useCurriculumSelector(
    selectWeekNumberByWeekId(component.weekId),
  );

  const [openDialog, setOpenDialog] = React.useState(false);

  const openConfirmationDialog = () => {
    setOpenDialog(true);
  };

  const closeConfirmationDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirm = (selected: { week: number; day: number }) => {
    onConfirmPublishOverride(selected);
  };

  const handleOverrideReady = (overrideSlug: string) => {
    onOverrideReady(overrideSlug);
    closeConfirmationDialog();
  };

  if (!isOverrrideCreationEnabled(component)) {
    return <></>;
  }

  const initSelectedDayToOverride = initialSelectedDay
    ? initialSelectedDay
    : {
        week: startWeekNum,
        day: JSON.parse(component.days).findIndex((d) => d === true) + 1,
      };

  return (
    <>
      <Box sx={{ color: theme.palette.grey[600], ml: "auto" }}>
        {isSm && (
          <Typography
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
            variant="caption"
          >
            You are editing all&nbsp;
            <b>{repeatsAmount}</b>&nbsp; {isMd && "scheduled "}
            {component.type.toLowerCase() + "s"}
          </Typography>
        )}

        <Typography
          display={"flex"}
          justifyContent={"end"}
          alignItems={"center"}
          gap={1}
          variant="caption"
        >
          <Button
            variant="text"
            sx={{
              textDecoration: "underline",
              color: theme.palette.grey[600],
              padding: 0,
            }}
            onClick={openConfirmationDialog}
          >
            Pick single {isMd && component.type.toLowerCase()} day change
          </Button>
        </Typography>
      </Box>
      <OverrideCreationDialog
        component={component}
        open={openDialog}
        onClose={closeConfirmationDialog}
        onConfirmPublishOverride={handleConfirm}
        initialSelectedDay={initSelectedDayToOverride}
        onOverrideReady={handleOverrideReady}
      />
    </>
  );
};

export default CoachComponentBarOverride;
