import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Tabs,
  Tab,
  Divider,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import queryString from "query-string";
import assign from "lodash.assign";

import { useLocation, useNavigate } from "react-router-dom";

import { NavigationItem } from "../../constants";
import { getNavigationLineStyles } from "../navigation/styled/NavigationTab.styles";
import { Navigation } from "../navigation/NavigationBasic";
import { NavItem } from "../navigation/NavItem";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: "0 !important",
  },

  tabs: {},

  tab: {
    maxWidth: "initial",
    fontSize: 14,
    fontWeight: "medium",
    color: `${theme.palette.secondary.main}99`,
  },

  divider: {
    height: 1,
    backgroundColor: theme.palette.border.primary,
    marginTop: -1,
  },

  selected: {
    "&.Mui-selected": {
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
  },
}));

export interface AppTabsNavigationProps
  extends Omit<ContainerProps, "children"> {
  items: NavigationItem[];
}

const getQuery = (query: NavigationItem["query"]) =>
  typeof query === "function" ? query() : query;

export function AppTabsNavigation(props: AppTabsNavigationProps) {
  const navigate = useNavigate();
  const { className, items, ...other } = props;
  const s = useStyles();
  const location = useLocation();
  const [selected, setSelected] = React.useState<number>(0);

  const theme = useTheme();
  const styles = getNavigationLineStyles(theme);

  React.useEffect(() => {
    const selected = items.findIndex((item) => {
      if (item.selected) {
        return true;
      }
      if (location.pathname.includes(item.link)) {
        return true;
      }
      return false;
    });

    setSelected(selected >= 0 ? selected : 0);
  }, [items, location.pathname, location.search]);

  const getTabClickHandler = React.useCallback(
    (item, index) => () => {
      const query = item.link + location.search;

      setSelected(index);

      navigate(query);
    },
    [location.search],
  );

  return (
    <Container className={clsx(s.root, className)} maxWidth={false} {...other}>
      <Navigation sx={{ ...styles, height: 48 }}>
        {items.map((item, index) => (
          <NavItem
            key={index}
            className={clsx(s.tab, selected === index && s.selected)}
            active={selected === index}
            onClick={getTabClickHandler(item, index)}
          >
            {item.name}
          </NavItem>
        ))}
      </Navigation>
    </Container>
  );
}
