import React, { Suspense } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { NetworkBoundary } from "../../components/loading/NetworkBoundary";
import { HistoryContext } from "../../hooks/useHistory";
import { createBrowserHistory } from "history";
import { LoadingPage } from "../loading/LoadingPage";
import AppRouter from "../../routes/router";
import { setupDeepLinks } from "../../utils/device";
import { PlateController } from "@udecode/plate-common";
import { AppMetadataProvider } from "../routes/AppMetadataProvider";
import ReactQueryClient from "../../api/ReactQueryClient";
import AxiosInterceptor from "../../api/AxiosInterceptor";
import { AuthContextProvider } from "../../hooks/auth/useAuth";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastAlertProvider } from "./ToastAlert/ToastAlertProvider";
import { initGoogleAnalytics } from "../../utils/googleAnalytics";

const App = () => {
  const history = createBrowserHistory();

  setupDeepLinks(history);

  initGoogleAnalytics();

  return (
    <HistoryContext.Provider value={history}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DndProvider backend={HTML5Backend}>
          <CssBaseline />
          <NetworkBoundary>
            <ToastAlertProvider>
              <Suspense fallback={<LoadingPage />}>
                <AppMetadataProvider>
                  <AuthContextProvider>
                    <AxiosInterceptor>
                      <ReactQueryClient>
                        <PlateController>
                          <AppRouter />
                        </PlateController>
                      </ReactQueryClient>
                    </AxiosInterceptor>
                  </AuthContextProvider>
                </AppMetadataProvider>
              </Suspense>
            </ToastAlertProvider>
          </NetworkBoundary>
        </DndProvider>
      </LocalizationProvider>
    </HistoryContext.Provider>
  );
};

export default App;
