import React, { FC, ReactNode } from "react";
import { Box, Grid2, Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  CheckCircle as CheckCircleIcon,
  HourglassEmpty as PendingIcon,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  typography: {
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  emptyIcon: {
    color: theme.palette.secondary.main,
  },
}));

export interface LastSyncProps extends TypographyProps {
  value: ReactNode;
}

const LastSync: FC<LastSyncProps> = (props) => {
  const { value, children, ...restProps } = props;

  const s = useStyles(props);

  return (
    <Grid2
      container
      wrap="nowrap"
      sx={{
        alignItems: "center",
      }}
    >
      <Typography variant="body1" className={s.typography} {...restProps}>
        {value ? `Last sync: ${value}` : "Not synced yet"}
        {children}
      </Typography>
      <Box
        sx={{
          display: "flex",
          pl: 1,
        }}
      >
        {value ? (
          <CheckCircleIcon fontSize="small" className={s.checkIcon} />
        ) : (
          <PendingIcon fontSize="inherit" className={s.emptyIcon} />
        )}
      </Box>
    </Grid2>
  );
};

export default LastSync;
