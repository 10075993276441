import React, { SyntheticEvent, ChangeEvent } from "react";

import { UserRole } from "../../constants";
import { isMobileApp } from "../../utils/mobile";

import {
  ChangePasswordForm,
  ChangePasswordFormProps,
} from "./ChangePasswordForm";
import { useSignInMutation } from "./mutations/SignIn";
import firebase from "firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CLIENT_HOME_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
} from "../../routes/routes";
import { validatePassword } from "../../utils/password";

type Props = {
  className?: string;
  component?: React.FC<ChangePasswordFormProps>;
};

export function ChangePassword(props: Props) {
  const navigate = useNavigate();
  const { className, component: Form = ChangePasswordForm } = props;

  const [searchParams] = useSearchParams();
  const code = searchParams.get("oobCode");

  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState<string>("");
  const { mutate: signIn } = useSignInMutation();

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const { value } = event.target;

    setError(null);
    setPassword(value);
  }

  function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    let error = null;
    if (!password) {
      error = "New password is required.";
    } else {
      error = validatePassword(password);
    }

    if (error) {
      setError(error);
      return;
    }

    changePassword(code, password);
  }

  const changePassword = async (code: string, password: string) => {
    setLoading(true);

    try {
      const auth = firebase.auth();
      const {
        data: { email },
        operation,
      } = await auth.checkActionCode(code);
      if (operation !== "PASSWORD_RESET") {
        throw new Error("Incorrect operation token");
      }
      await auth.confirmPasswordReset(code, password);
      if (!email) {
        return;
      }
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      if (!user) {
        return;
      }
      const idToken = await user.getIdToken();
      signIn(
        {
          idToken,
        },
        {
          onSuccess: (userRole: UserRole) => {
            navigate(
              isMobileApp || userRole === UserRole.CLIENT
                ? HOME_ROUTE
                : COACH_PROGRAMS_PUBLISHED_ROUTE,
            );
          },
        },
      );
    } catch (err) {
      setLoading(false);
      const errorMessage =
        err instanceof Error ? err.message : "Password Confirmation error";
      setError(errorMessage);
      console.error(err);
    }
  };

  return (
    <Form
      className={className}
      state={{ password }}
      errors={{ password: error }}
      onSubmit={handleSubmit}
      onChange={handleChange}
      loading={loading}
    />
  );
}
