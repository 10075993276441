import { programTemplatesClient } from "../api/ApiClients";
import {
  IUseProgramTemplateCommand,
  ProgramTemplateCategory,
  UseProgramTemplateCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class ProgramTemplatesService extends ServiceBase {
  async getProgramTemplates(category?: ProgramTemplateCategory) {
    return programTemplatesClient.getProgramTemplates(category);
  }

  async useProgramTemplate(variables: IUseProgramTemplateCommand) {
    const command = UseProgramTemplateCommand.fromJS(variables);
    return programTemplatesClient.useProgramTemplate(command);
  }
}

export default new ProgramTemplatesService();
