"use client";

import React, { useEffect, useState } from "react";
import { cn, withRef } from "@udecode/cn";
import {
  ClassNames,
  PlateElementProps,
  TEditor,
  findNodePath,
  focusEditor,
  insertElements,
  removeNodes,
  useEditorRef,
} from "@udecode/plate-common";
import {
  DragItemNode,
  useDraggable,
  useDraggableState,
} from "@udecode/plate-dnd";
import { DropTargetMonitor } from "react-dnd";

import { Icons } from "./Icons/icons";

import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
  Stack,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { IMenuSection } from "../new-editor/utils/menuSectionsUtil";
import { ChevronRight, Repeat2, Trash, Copy } from "lucide-react";
import {
  TURN_INTO_CHECK_IN_MENU_ITEM_GROUP,
  TURN_INTO_TEXT_MENU_ITEM_GROUPS,
} from "../new-editor/utils/menuItemTurnIntoGroups";
import { CheckInTypes } from "../new-editor/utils/menuItemUtil";
import MinimizedTooltip, { TooltipLine } from "../tooltip/MinimizedTooltip";
import {
  duplicateElement,
  insertElementAt,
  turnIntoElement,
} from "../new-editor/utils/editorUtils";
import { Path } from "slate";
import InsertLine from "../new-editor/components/editor/InsertLine";
import { ComponentLibrarySource } from "../component-library/ComponentLibrarySnippet";
import { SchemaElements } from "../editor/normalizers/withSchema";
import { ComponentLibraryAssetSource } from "../component-library/ComponentLibraryAsset";

export interface DraggableProps
  extends PlateElementProps,
    ClassNames<{
      /**
       * Block and gutter.
       */
      blockAndGutter: string;

      /**
       * Block.
       */
      block: string;

      /**
       * Gutter at the left side of the editor.
       * It has the height of the block
       */
      gutterLeft: string;

      /**
       * Block toolbar wrapper in the gutter left.
       * It has the height of a line of the block.
       */
      blockToolbarWrapper: string;

      /**
       * Block toolbar in the gutter.
       */
      blockToolbar: string;

      blockWrapper: string;

      /**
       * Button to dnd the block, in the block toolbar.
       */
      dragHandle: string;

      /**
       * Icon of the drag button, in the drag icon.
       */
      dragIcon: string;

      /**
       * Show a dropline above or below the block when dragging a block.
       */
      dropLine: string;
    }> {
  /**
   * Intercepts the drop handling.
   * If `false` is returned, the default drop behavior is called after.
   * If `true` is returned, the default behavior is not called.
   */
  onDropHandler?: (
    editor: TEditor,
    props: {
      monitor: DropTargetMonitor<DragItemNode, unknown>;
      dragItem: DragItemNode;
      nodeRef: any;
      id: string;
    },
  ) => boolean;

  generalDropDownMenuItems: IMenuSection[];
  showInsertDropdownOnDraggable: boolean;
}

export const Draggable = withRef<"div", DraggableProps>(
  ({ className, classNames = {}, onDropHandler, ...props }, ref) => {
    const {
      children,
      element,
      generalDropDownMenuItems,
      showInsertDropdownOnDraggable,
    } = props;
    const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

    const [dragMenuAnchorEl, setDragMenuAnchorEl] =
      React.useState<null | HTMLElement>(null);
    const [turnIntoAnchorEl, setTurnIntoAnchorElAnchorEl] =
      React.useState<null | HTMLElement>(null);
    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setDragMenuAnchorEl(event.currentTarget);
    };

    const handleTurnIntoMenuMouseEnter = (
      event: React.MouseEvent<HTMLElement>,
    ) => {
      setTurnIntoAnchorElAnchorEl(event.currentTarget);
    };

    const handleTurnIntoMenuMouseLeave = () => {
      setTurnIntoAnchorElAnchorEl(null);
    };

    const turnIntoMenuItems = Object.values(CheckInTypes).includes(
      element.type as CheckInTypes,
    )
      ? TURN_INTO_CHECK_IN_MENU_ITEM_GROUP
      : TURN_INTO_TEXT_MENU_ITEM_GROUPS;

    const onTurnIntoClick = (elementType: string) => {
      focusEditor(editor, path);
      turnIntoElement(editor, elementType);

      setTurnIntoAnchorElAnchorEl(null);
      setDragMenuAnchorEl(null);
    };

    const handleElementDuplicate = () => {
      focusEditor(editor, path);
      duplicateElement(editor, path);
      focusEditor(editor, Path.next(path));

      setDragMenuAnchorEl(null);
    };

    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    const isFirstElement = path[0] === 0;

    const handleRemoveNode = () => {
      removeNodes(editor, { at: path });
    };

    const state = useDraggableState({
      element,
      onDropHandler: (editor, options) => {
        if (
          (options.dragItem.content as any)?.source === ComponentLibrarySource
        ) {
          insertElementFromComponentLibrary(
            (options.dragItem.content as any)?.type,
          );
          return true;
        }

        if (
          (options.dragItem.content as any)?.source ===
          ComponentLibraryAssetSource
        ) {
          insertElements(editor, (options.dragItem.content as any).element, {
            select: true,
            removeEmpty: true,
            at: path,
            nextBlock: dropLine !== "top",
          });

          return true;
        }

        return false;
      },
    });
    const { dropLine, isDragging, isHovered } = state;

    const insertElementFromComponentLibrary = (elementType: string) => {
      if (dropLine === "top") {
        insertElementAt(elementType, editor, path);
      } else {
        insertElementAt(elementType, editor, Path.next(path));
      }
    };

    const {
      groupProps,
      droplineProps,
      gutterLeftProps,
      previewRef,
      handleRef,
    } = useDraggable(state);
    const theme = useTheme();

    const [insertItemAnchorEl, setInsertItemAnchorEl] =
      React.useState<null | HTMLElement>(null);
    const openInsetItemMenu = Boolean(insertItemAnchorEl);

    const handleOpenInsertMenu = (event: React.MouseEvent<HTMLDivElement>) => {
      setInsertItemAnchorEl(event.currentTarget);
    };

    const handleCloseInsertMenu = (event) => {
      event.stopPropagation();
      setInsertItemAnchorEl(null);
    };

    const [insertFirstItemAnchorEl, setInsertFirstItemAnchorEl] =
      React.useState<null | HTMLElement>(null);
    const openInsetFirstItemMenu = Boolean(insertFirstItemAnchorEl);

    const handleOpenInsertFirstMenu = (
      event: React.MouseEvent<HTMLDivElement>,
    ) => {
      setInsertFirstItemAnchorEl(event.currentTarget);
    };

    const handleCloseInsertFirstMenu = (event) => {
      event.stopPropagation();
      setInsertFirstItemAnchorEl(null);
    };

    const [isInsertLineHovered, setIsInsertLineHovered] = useState(false);

    return (
      <>
        {isFirstElement && (
          <InsertLine
            handleOpenInsertMenu={handleOpenInsertFirstMenu}
            isInsertLineHovered={isInsertLineHovered}
            setIsInsertLineHovered={setIsInsertLineHovered}
            element={element}
            generalDropDownMenuItems={generalDropDownMenuItems}
            insertItemAnchorEl={insertFirstItemAnchorEl}
            openInsetItemMenu={openInsetFirstItemMenu}
            handleCloseInsertMenu={handleCloseInsertFirstMenu}
            insertPath={path}
          />
        )}
        <div
          ref={ref}
          className={cn(
            "relative",
            isDragging && "opacity-50",
            "group",
            className,
          )}
          {...groupProps}
        >
          <div
            className={cn(
              "absolute top-0 flex h-full -translate-x-full pl-28 pt-[.5rem] transition-opacity duration-300",
              isHovered && !isInsertLineHovered && "group-hover:opacity-100",
              dragMenuAnchorEl ? "opacity-100" : "opacity-0",
              classNames.gutterLeft,
            )}
            {...gutterLeftProps}
          >
            <div
              className={cn("flex h-[1.5em]", classNames.blockToolbarWrapper)}
            >
              <div
                className={cn(
                  `pointer-events-auto ${isSm && "mr-1"} flex items-center`,
                  classNames.blockToolbar,
                )}
              >
                <div ref={handleRef} className="h-4">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MinimizedTooltip
                      title={
                        <Stack direction="column">
                          <TooltipLine>
                            <b>Drag</b> to move
                          </TooltipLine>
                          <TooltipLine>
                            <b>Click</b> to action
                          </TooltipLine>
                        </Stack>
                      }
                    >
                      <Button
                        onClick={handleClickMenu}
                        sx={{
                          minWidth: 0,
                          p: 0.4,
                          mt: -0.375,
                          pr: { xs: 0, sm: 0.4 },
                        }}
                      >
                        <Icons.dragHandle
                          className="h-4 w-4"
                          style={{ color: theme.palette.grey[500] }}
                        />
                      </Button>
                    </MinimizedTooltip>

                    <Menu
                      anchorEl={dragMenuAnchorEl}
                      open={Boolean(dragMenuAnchorEl)}
                      onClose={() => {
                        setDragMenuAnchorEl(null);
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem
                        onMouseEnter={handleTurnIntoMenuMouseEnter}
                        onClick={handleTurnIntoMenuMouseEnter}
                        onMouseLeave={handleTurnIntoMenuMouseLeave}
                      >
                        <div className="flex items-center">
                          <ListItemIcon
                            children={
                              <Repeat2 color={theme.palette.common.black} />
                            }
                          ></ListItemIcon>
                          <ListItemText
                            primary="Turn into"
                            primaryTypographyProps={{ fontWeight: 500 }}
                          />
                          <IconButton
                            sx={{ color: theme.palette.grey[400], ml: 2, p: 0 }}
                          >
                            <ChevronRight />
                          </IconButton>
                        </div>

                        <Popper
                          open={Boolean(turnIntoAnchorEl)}
                          anchorEl={turnIntoAnchorEl}
                          role={undefined}
                          placement="right"
                          transition
                          sx={{ zIndex: 9999 }}
                          onMouseLeave={handleTurnIntoMenuMouseLeave}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={handleTurnIntoMenuMouseLeave}
                                >
                                  <MenuList
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                  >
                                    {turnIntoMenuItems.map(
                                      ({ items: groupItems }, groupIndex) => (
                                        <>
                                          {groupItems.map(
                                            ({
                                              value: itemValue,
                                              label,
                                              icon: Icon,
                                            }) => (
                                              <MenuItem
                                                onClick={() =>
                                                  onTurnIntoClick(itemValue)
                                                }
                                                key={itemValue}
                                                value={itemValue}
                                                selected={
                                                  element.type === itemValue
                                                }
                                                className="min-w-[170px] pr-10 h-8"
                                              >
                                                <Box
                                                  sx={{
                                                    p: 0.5,
                                                    borderRadius: 1,
                                                    border: "1px solid",
                                                    background: (theme) =>
                                                      theme.palette.common
                                                        .white,
                                                    aspectRatio: 1,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    mr: 1.2,
                                                  }}
                                                >
                                                  <Icon className="h-4 w-4" />
                                                </Box>
                                                {label}
                                              </MenuItem>
                                            ),
                                          )}
                                          {groupIndex <
                                            turnIntoMenuItems.length - 1 && (
                                            <Divider sx={{ width: 1 }} />
                                          )}
                                        </>
                                      ),
                                    )}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </MenuItem>
                      <MenuItem onClick={handleElementDuplicate}>
                        <ListItemIcon
                          children={<Copy color={theme.palette.common.black} />}
                        />
                        <ListItemText
                          primary="Duplicate"
                          primaryTypographyProps={{ fontWeight: 500 }}
                        />
                      </MenuItem>
                      <MenuItem onClick={handleRemoveNode}>
                        <ListItemIcon
                          children={
                            <Trash color={theme.palette.common.black} />
                          }
                        />
                        <ListItemText
                          primary="Delete"
                          primaryTypographyProps={{ fontWeight: 500 }}
                        />
                      </MenuItem>
                    </Menu>
                  </Box>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames.blockWrapper} ref={previewRef}>
            {children}

            {!!dropLine && (
              <div
                className={cn(
                  "absolute inset-x-0 h-0.5 opacity-100",
                  "bg-ring",
                  dropLine === "top" && "-top-px",
                  dropLine === "bottom" && "-bottom-px",
                  classNames.dropLine,
                )}
                {...droplineProps}
              ></div>
            )}

            <InsertLine
              handleOpenInsertMenu={handleOpenInsertMenu}
              isInsertLineHovered={isInsertLineHovered}
              setIsInsertLineHovered={setIsInsertLineHovered}
              element={element}
              generalDropDownMenuItems={generalDropDownMenuItems}
              insertItemAnchorEl={insertItemAnchorEl}
              openInsetItemMenu={openInsetItemMenu}
              handleCloseInsertMenu={handleCloseInsertMenu}
              insertPath={Path.next(path)}
            />
          </div>
        </div>
      </>
    );
  },
);
