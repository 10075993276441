import { call, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import { selectComponentTempIdsMap } from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";

export default function* removeOverrideWorker(payload: { overrideId: number }) {
  const { overrideId } = payload;
  const componentMap: TempIdsMap = yield select(selectComponentTempIdsMap);
  const checkedOverrideId = componentMap?.[overrideId] || overrideId;

  try {
    yield call(ComponentsService.removeComponentOverride, checkedOverrideId);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
