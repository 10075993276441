import { z } from "zod";
import { passwordRules } from "./password";
import { SignupStep } from "../constants";

export type CoachSignupField = "fullName" | "email" | "password" | "code";

export const stepFields: Record<number, CoachSignupField[]> = {
  [SignupStep.EMAIL]: ["email"],
  [SignupStep.EMAIL_VERIFICATION]: ["code"],
  [SignupStep.CONFIRMATION]: ["password", "fullName"],
};

export const verificationCodeLength = 6;

export const coachSignupSchema = z.object({
  // Step 1
  email: z.string().email("Email address is invalid"),

  // Step 2
  code: z
    .string()
    .length(verificationCodeLength, "Verification code is invalid"),

  // Step 3
  fullName: z.string().min(1, "Full name is required"),
  password: z
    .string()
    .regex(passwordRules.length.regex, passwordRules.length.message)
    .regex(
      passwordRules.alphabeticalCharacter.regex,
      passwordRules.alphabeticalCharacter.message,
    )
    .regex(
      passwordRules.numericCharacter.regex,
      passwordRules.numericCharacter.message,
    )
    .regex(
      passwordRules.specialCharacter.regex,
      passwordRules.specialCharacter.message,
    ),
});

export type CoachSignupSchema = z.infer<typeof coachSignupSchema>;
