import React, { ReactNode } from "react";
import { ButtonProps, ToggleButton as MuiToggleButton } from "@mui/material";
import MinimizedTooltip from "../tooltip/MinimizedTooltip";

export interface ToggleColorsButtonProps extends ButtonProps {
  value: string;
  icon: ReactNode;
  toggleFlag: boolean;
  onToggle: (value: boolean) => void;
  tooltipTitle?: string;
}

export function ToggleButton(props: ToggleColorsButtonProps) {
  const { value, icon, toggleFlag, onToggle, tooltipTitle } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onToggle(!toggleFlag);
  };

  return (
    <MinimizedTooltip title={tooltipTitle} placement="top">
      <MuiToggleButton
        color="primary"
        value={value}
        selected={toggleFlag}
        onClick={handleClick}
        size={"small"}
      >
        {icon}
      </MuiToggleButton>
    </MinimizedTooltip>
  );
}
