import React from "react";
import { Box, Typography, Grid2, useTheme, useMediaQuery } from "@mui/material";
import { BackButton } from "../button/BackButton";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import clsx from "clsx";
import WorkoutSet from "../workout/workout-set/WorkoutSet";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";
import { ExerciseTypeExtra, ExerciseTypeSet, Units } from "../../constants";
import { unitSetLabel } from "../../utils/units";
import {
  getExtraColumnLabel,
  getRepsColumnLabel,
  hasExtraColumn,
} from "../workout/utils";
import { useWorkoutTableStyles } from "../workout/tableStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: "24px",
    paddingLeft: 8,
    marginTop: 8,
  },
  recorded: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
    paddingLeft: 8,
  },
  container: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  header: {
    display: "flex",
    color: colorSystem.gray,
    fontWeight: 500,
    marginBottom: 12,
    marginTop: 12,
  },
  result: {
    display: "flex",
    backgroundColor: colorSystem.green2,
    margin: 8,
    justifyContent: "space-around",
    color: colorSystem.white,
    padding: 5,
    borderRadius: 4,
    marginTop: 20,
  },
  resultText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
  },
  exerciseTitle: {
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: "bold",
    paddingLeft: 8,
  },
  noResult: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "bold",
    paddingLeft: 8,
    marginTop: 16,
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  exerciseTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

type ClientWorkoutsChooseProps = {
  onClose: () => void;
  selectWorkout: any;
};

const ClientWorkoutsChoose = (props: ClientWorkoutsChooseProps) => {
  const { onClose, selectWorkout } = props;
  const s = useStyles();
  const tableStyles = useWorkoutTableStyles();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const [sets, setSets] = React.useState(selectWorkout.value);

  const handleClickWorkout = React.useCallback(
    (index) => {
      const data = sets.map((item, i) =>
        i === index ? { ...item, isClose: !item.isClose } : { ...item },
      );
      setSets(data);
    },
    [sets, setSets],
  );

  return (
    <Box className={s.root}>
      {smUp && <BackButton tooltip={""} onClick={onClose} />}
      <Typography className={s.title}>{selectWorkout.title}</Typography>
      <Typography
        className={s.recorded}
      >{`Recorded ${selectWorkout.date}`}</Typography>
      {selectWorkout.session ? (
        <>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 6,
            }}
          >
            <Box className={s.result}>
              <Typography
                className={s.resultText}
              >{`Sets ${selectWorkout.session}`}</Typography>
              <Typography
                className={s.resultText}
              >{`${selectWorkout.min} min`}</Typography>
            </Box>
          </Grid2>
          <Grid2 container spacing={2} className={s.container}>
            {sets.map((item, index) => {
              return (
                <Grid2
                  key={index}
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 6,
                  }}
                >
                  <Box className={s.exerciseTitleContainer}>
                    <Typography className={s.exerciseTitle}>
                      {item.title}
                    </Typography>
                    {!smUp && (
                      <ArrowIcon
                        className={!item.isClose ? "" : s.arrowIconDown}
                        onClick={() => handleClickWorkout(index)}
                      />
                    )}
                  </Box>
                  {!item.isClose && Array.isArray(item.result) && (
                    <>
                      <div
                        className={clsx(
                          s.header,
                          tableStyles.header,
                          hasExtraColumn(item?.typeExtraMeasurement) &&
                            tableStyles.headerWithExtraColumn,
                        )}
                      >
                        <div
                          className={clsx(tableStyles.cell, tableStyles.set)}
                        >
                          Set
                        </div>
                        <div
                          className={clsx(tableStyles.cell, tableStyles.reps)}
                        >
                          {getRepsColumnLabel(item?.typeReps, true)}
                        </div>
                        <div
                          className={clsx(tableStyles.cell, tableStyles.weight)}
                        >
                          {unitSetLabel(
                            item?.units || Units.METRIC,
                            item.typeSet || ExerciseTypeSet.WEIGHT,
                          )}
                        </div>
                        {hasExtraColumn(item?.typeExtraMeasurement) && (
                          <div
                            className={clsx(
                              tableStyles.cell,
                              tableStyles.extraColumn,
                            )}
                          >
                            {getExtraColumnLabel(item?.typeExtraMeasurement)}
                          </div>
                        )}
                        <div
                          className={clsx(
                            tableStyles.cell,
                            tableStyles.result,
                            history && tableStyles.resultHistory,
                          )}
                        ></div>
                      </div>
                      {item.result.map((i, index) => (
                        <WorkoutSet
                          key={index}
                          setNumber={index}
                          set={i}
                          typeExtraMeasurement={
                            item?.typeExtraMeasurement || ExerciseTypeExtra.NONE
                          }
                          typeReps={item.typeReps}
                          typeSet={item.typeSet}
                          history
                        />
                      ))}
                    </>
                  )}
                </Grid2>
              );
            })}
          </Grid2>
        </>
      ) : (
        <Typography className={s.noResult}>No results</Typography>
      )}
    </Box>
  );
};

export default React.memo(ClientWorkoutsChoose);
