import React from "react";
import {
  Grid2,
  ContainerProps,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ClientWorkoutsChoose from "./ClientWorkoutsChoose";
import ClientWorkoutsItem from "./ClientWorkoutsItem";
import { parseWorkoutResultValueJsonToWorkoutSection } from "../workout/utils";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import WorkoutResultsService from "../../services/WorkoutResultsService";
import { CardPagination } from "../pagination/CardPagination";
import { WorkoutResultsSkeletonCard } from "../loading/WorkoutResultsSkeletonCard";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 100,
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  hasMore: {
    margin: theme.spacing(0, 1),
  },
  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    width: "100%",
    textAlign: "center",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

export interface ClientWorkoutsProps extends Omit<ContainerProps, "children"> {
  pageSize?: number;
  selectWorkout: any;
  handleCloseWorkout: any;
  chooseWorkoutItem: any;
  sumSessions: any;
  timeWorkout: any;
  sumLbs: any;
  setLastRecorded: any;
  lastRecorded: any;
  clientId: number;
}

const CLIENT_WORKOUTS_PAGE_SIZE_SM_UP = 6;
const CLIENT_WORKOUTS_PAGE_SIZE_SM = 3;
export const CLIENT_WORKOUTS_LIST_QUERY_KEY = "client-workouts-list";

function ClientWorkouts(props: ClientWorkoutsProps) {
  const {
    selectWorkout,
    handleCloseWorkout,
    chooseWorkoutItem,
    sumSessions,
    timeWorkout,
    sumLbs,
    setLastRecorded,
    lastRecorded,
    clientId,
  } = props;

  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const pageSize = smUp
    ? CLIENT_WORKOUTS_PAGE_SIZE_SM_UP
    : CLIENT_WORKOUTS_PAGE_SIZE_SM;
  const [currentPage, setCurrentPage] = React.useState(1);

  const {
    data,
    isFetching,
    isPlaceholderData: isPrevPageData,
    isLoading: isFirstPageFirstFetch,
  } = useQuery({
    // keep old data to display pagination
    placeholderData: keepPreviousData,
    queryKey: [CLIENT_WORKOUTS_LIST_QUERY_KEY, { clientId, currentPage }],
    queryFn: () =>
      WorkoutResultsService.getUserExerciseResults(
        currentPage,
        pageSize,
        clientId,
      ),
  });

  const s = useStyles();

  const workoutExerciseResults = React.useMemo(
    () =>
      data?.items?.map(({ value, activity, ...dto }) => ({
        ...dto,
        activity: {
          ...activity,
          completedAt: activity.completedAt.format("DD-MM-YYYY"),
        },
        section: parseWorkoutResultValueJsonToWorkoutSection(value),
      })),
    [data],
  );

  React.useEffect(() => {
    if (!lastRecorded) {
      if (workoutExerciseResults?.length)
        setLastRecorded(workoutExerciseResults[0].activity.completedAt);
    }
  }, [workoutExerciseResults, lastRecorded, setLastRecorded]);

  const showCards = workoutExerciseResults?.length && !isPrevPageData;
  const showSkeleton = isFirstPageFirstFetch || (isFetching && isPrevPageData);
  const showEmpty = !showCards && !showSkeleton;

  return (
    <Grid2 container spacing={2} className={s.container}>
      {selectWorkout ? (
        <ClientWorkoutsChoose
          onClose={handleCloseWorkout}
          selectWorkout={selectWorkout}
        />
      ) : (
        <>
          {showCards
            ? workoutExerciseResults.map(({ activity, section }, index) => (
                <ClientWorkoutsItem
                  key={index}
                  index={index}
                  title={activity.component?.title}
                  date={activity.completedAt}
                  onClick={() => {
                    chooseWorkoutItem({
                      value: section.exercises,
                      session: sumSessions(section),
                      min: timeWorkout(activity.endWorkout),
                      lbs: sumLbs(section),
                      title: activity.component?.title,
                      date: activity.completedAt,
                    });
                  }}
                  session={sumSessions(section)}
                  min={timeWorkout(activity.endWorkout)}
                />
              ))
            : null}
          {showSkeleton
            ? Array.from({ length: pageSize }).map((_, i) => (
                <WorkoutResultsSkeletonCard key={i} />
              ))
            : null}
          {showEmpty ? (
            <Typography className={s.noText}>No workouts to show.</Typography>
          ) : null}
          {data?.totalPages > 1 && (
            <Box className={s.pagination}>
              <CardPagination
                page={currentPage}
                count={data?.totalPages}
                onChange={(_, page) => setCurrentPage(page)}
              />
            </Box>
          )}
        </>
      )}
    </Grid2>
  );
}

export default React.memo(ClientWorkouts);
