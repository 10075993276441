import clsx from "clsx";
import React from "react";
import { useMutation as useMutation } from "@tanstack/react-query";
import {
  DialogProps,
  ButtonProps,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";
import CheckInUploadClientPhoto from "../editor/components/checkin/CheckInUploadClientPhoto";
import { ProgressImageInfo } from "../editor/components/checkin/CheckInAnswerProgressPhotoView";
import dayjs from "dayjs";
import StorageService from "../../services/StorageService";
import ProgressPhotoService from "../../services/ProgressPhotoService";
import {
  IProgressPhotoDto,
  ProgressPhotoDto,
  ICreateProgressPhotoSetCommand,
  CreatedSetIds,
} from "@growth-machine-llc/stridist-api-client";

type ProgressPhotoView = "front" | "side" | "back";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  paper: {
    margin: theme.spacing(1),
  },

  description: {
    color: theme.palette.common.black,
  },
  viewLabel: {
    fontWeight: 500,
    marginBottom: theme.spacing(1.5),
  },
  container: {
    display: "grid",
    gridGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  modal: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 1200,
      flex: 1,
    },
  },

  button: {
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "bold",
    minWidth: "100%",
    height: theme.spacing(7),
  },

  cancelButton: {
    color: theme.palette.text.secondary,
    borderWidth: 2,
    margin: theme.spacing(2, 0),
  },

  okayButton: {
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
}));

export interface ConfirmActionDialogProps
  extends Omit<DialogProps, "title" | "id"> {
  title: string;
  description?: string;
  onCancel: ButtonProps["onClick"];
  onConfirm: ButtonProps["onClick"];
  disabled?: boolean;
  items?: any[];
  id: number;
  updatePhotosList: (
    id: CreatedSetIds,
    vars: ICreateProgressPhotoSetCommand,
  ) => void;
}

export function ConfirmActionProgressPhotos(props: ConfirmActionDialogProps) {
  const {
    className,
    title,
    description,
    onCancel,
    onConfirm,
    disabled,
    items,
    id: id,
    updatePhotosList,
    ...other
  } = props;
  const s = useStyles();

  const [photos, setPhotos] = React.useState({
    front: null,
    side: null,
    back: null,
  });
  const [uploadingPhotos, setUploadingPhotos] = React.useState<
    Record<ProgressPhotoView, boolean>
  >({
    front: null,
    side: null,
    back: null,
  });

  const options: Record<ProgressPhotoView, string> = {
    front: "Front view",
    side: "Side view",
    back: "Back view",
  };

  const { mutate: createPhoto, isPending: creating } = useMutation({
    mutationKey: ["create-progress-photo-set"],
    mutationFn: ProgressPhotoService.createPhotoSet,
  });

  const { mutate: createUrl, isPending: creatingUrl } = useMutation({
    mutationKey: ["create-signed-url"],
    mutationFn: StorageService.createSignedUrl,
  });

  const handleChange = React.useCallback(
    (name, value: ProgressImageInfo | ProgressImageInfo[]) => {
      setPhotos((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [photos],
  );

  const handleSave = React.useCallback(
    (event) => {
      const photosInterface: IProgressPhotoDto[] = Object.entries(photos).map(
        ([view, value]) => {
          if (value) {
            return {
              title: view,
              url: value.url,
              fileName: value.fileName,
              size: value.fileSize,
            };
          } else {
            return null;
          }
        },
      );
      const photosContent = photosInterface
        .filter((i) => !!i)
        .map((i) => ProgressPhotoDto.fromJS(i));
      const input = {
        photos: photosContent,
        name: "Progress Photos",
        clientId: id,
        takenOn: dayjs().format("YYYY-MM-DD"),
      };
      createPhoto(input, {
        onSuccess: (ids) => {
          onCancel(event);
          setPhotos({
            front: null,
            side: null,
            back: null,
          });
          updatePhotosList(ids, input);
        },
      });
    },
    [photos, setPhotos, updatePhotosList, createPhoto, onCancel],
  );

  const isUploading = Object.values(uploadingPhotos).some((x) => x);

  return (
    <BaseDialog
      className={clsx(s.root, s.modal)}
      title={title}
      description={description}
      onClose={onCancel}
      classes={{
        paper: s.paper,
        description: s.description,
      }}
      {...other}
    >
      <Box className={s.container}>
        {Object.entries(options).map(([name, label]) => (
          <Box key={name}>
            <Typography variant="body1" className={s.viewLabel}>
              {label}
            </Typography>
            <CheckInUploadClientPhoto
              name={name}
              value={photos[name]}
              onChange={handleChange}
              createUrl={createUrl}
              idEntityType="User"
              id={id.toString()}
              uploadingPhoto={uploadingPhotos[name]}
              setUploadingPhoto={(value: boolean) =>
                setUploadingPhotos((old) => {
                  old[name] = value;
                  return old;
                })
              }
              disabled={creatingUrl || creating}
            />
          </Box>
        ))}
      </Box>
      <Button
        className={clsx(s.button, s.cancelButton)}
        variant="outlined"
        children="Cancel"
        onClick={onCancel}
        disabled={disabled || creatingUrl || creating}
      />
      <Button
        className={clsx(s.button, s.okayButton)}
        variant="contained"
        children="OK"
        onClick={handleSave}
        disabled={disabled || creatingUrl || creating || isUploading}
      />
    </BaseDialog>
  );
}
