import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { generateId } from "../../../../../components/new-editor/utils/nodeUtil";
import { generateComponentDays } from "../../../../../utils/component";
import dayjs from "dayjs";

export const pasteComponentAction = {
  prepare(props: { weekId: number; componentId: number; day: number }) {
    const { weekId, componentId, day } = props;
    const newId = generateId();
    return {
      payload: {
        weekId,
        componentId,
        day,
        newId,
      },
    };
  },

  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      weekId: number;
      componentId: number;
      day: number;
      newId: number;
    }>,
  ) {
    const { weekId, componentId, day, newId } = action.payload;

    const componentToCopy = state.value.components[componentId];
    if (!componentToCopy) return;

    if (componentToCopy.baseComponentId) {
      throw new Error("Cannot duplicate a component that is an override");
    }

    const newComponent = {
      ...componentToCopy,
      id: newId,
      previousStatus: undefined,
      days: generateComponentDays(day),
      slug: `${newId}-tempslug`,
      updatedAt: dayjs(),
      weekId,
      overrides: [],
      baseComponentId: null,
    };

    state.value.weeks[weekId].components.push(newId);
    state.value.weeks[weekId].positions.push(newId.toString());
    state.value.components[newId] = newComponent;
  },
};
