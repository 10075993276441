import React, { useEffect, useState } from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { Box, Menu, MenuItem } from "@mui/material";

import SeverityInfoIcon from "../icons/severity/SeverityInfo.svg";
import SeveritySuccessIcon from "../icons/severity/SeveritySuccess.svg";
import SeverityWarningIcon from "../icons/severity/SeverityWarning.svg";
import SeverityDangerIcon from "../icons/severity/SeverityDanger.svg";
import { IElementChildrenProp } from "../other/commonInterfaces";
import { ReactEditor } from "slate-react";
import { colorSystem } from "../../../theme";

export const ELEMENT_CALLOUT = "callout";

export const calloutColor = {
  info: { main: colorSystem.blue1, background: `${colorSystem.blue1}0F` },
  success: { main: colorSystem.green2, background: `${colorSystem.green2}0F` },
  warning: { main: colorSystem.yellow, background: `${colorSystem.yellow}0F` },
  danger: { main: colorSystem.primary, background: `${colorSystem.primary}0F` },
};

enum CalloutStates {
  INFO = "info",
  DANGER = "danger",
  SUCCESS = "success",
  WARNING = "warning",
}

const calloutStatesAssets = {
  [CalloutStates.INFO]: {
    type: CalloutStates.INFO,
    icon: SeverityInfoIcon,
    mainColor: calloutColor.info.main,
    backgroundColor: calloutColor.info.background,
  },
  [CalloutStates.SUCCESS]: {
    type: CalloutStates.SUCCESS,
    icon: SeveritySuccessIcon,
    mainColor: calloutColor.success.main,
    backgroundColor: calloutColor.success.background,
  },
  [CalloutStates.WARNING]: {
    type: CalloutStates.WARNING,
    icon: SeverityWarningIcon,
    mainColor: calloutColor.warning.main,
    backgroundColor: calloutColor.warning.background,
  },
  [CalloutStates.DANGER]: {
    type: CalloutStates.DANGER,
    icon: SeverityDangerIcon,
    mainColor: calloutColor.danger.main,
    backgroundColor: calloutColor.danger.background,
  },
};

const statesArr = [
  calloutStatesAssets[CalloutStates.INFO],
  calloutStatesAssets[CalloutStates.SUCCESS],
  calloutStatesAssets[CalloutStates.WARNING],
  calloutStatesAssets[CalloutStates.DANGER],
];

interface ICalloutProps {
  severity: CalloutStates;
  setSeverity: React.Dispatch<React.SetStateAction<CalloutStates>>;
  isReadOnly: boolean;
}

export const Callout = ({
  children,
  severity,
  setSeverity,
  isReadOnly,
}: IElementChildrenProp & ICalloutProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSeverityChange = (type: CalloutStates) => {
    setSeverity(type);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1.5,
        borderLeft: "4px solid",
        borderColor: calloutStatesAssets[severity].mainColor,
        borderRadius: 1,
        background: calloutStatesAssets[severity].backgroundColor,
        padding: 2.5,
      }}
    >
      <Box contentEditable={false}>
        <Box onClick={handleOpenMenu} sx={{ mt: 0.25, width: "max-content" }}>
          <img src={calloutStatesAssets[severity].icon} />
        </Box>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            sx: {
              p: 0,
              background: calloutStatesAssets[severity].backgroundColor,
            },
            "aria-labelledby": "basic-button",
          }}
          sx={{
            ".MuiMenu-paper": {
              borderRadius: 4,
              borderWidth: 2,
              mt: -1.25,
              ml: -0.25,
              borderColor: calloutStatesAssets[severity].mainColor,
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {statesArr.map((s, index) => (
            <Box onClick={() => handleSeverityChange(s.type)} key={index}>
              <MenuItem onClick={handleCloseMenu} sx={{ padding: 1 }}>
                <img src={s.icon} />
              </MenuItem>
            </Box>
          ))}
        </Menu>
      </Box>
      <Box
        sx={{
          display: "block",
          wordBreak: "break-word",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const CalloutElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element } = props;
    const [severity, setSeverity] = useState<CalloutStates>(
      (element.severity as CalloutStates) ?? CalloutStates.INFO,
    );
    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    if (!path) return;
    const isReadOnly = ReactEditor.isReadOnly(editor as any);

    useEffect(() => {
      setNodes(editor, { severity: severity }, { at: path });
    }, [severity]);

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <Callout
          children={children}
          severity={severity}
          setSeverity={setSeverity}
          isReadOnly={isReadOnly}
        />
      </PlateElement>
    );
  },
);
