import React from "react";
import {
  Container,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ClientFormsCoachService from "../../services/ClientFormsCoachService";

import { polyfillCSS } from "../../utils/css";
import { ConnectionPagination } from "../pagination/ConnectionPagination";
import { PageSkeleton } from "../loading/PageSkeleton";

import { CoachClientsFormsListItem } from "./CoachClientsFormsListItem";
import TrackInfoTool from "../tools/TrackInfoTool";
import { InfoBox } from "../info/InfoBox";
import notesArt from "../../icons/notes.svg";
import { CreateClientFormAction } from "./CreateClientFormAction";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    marginRight: theme.spacing(10),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(10)} + var(--safe-area-inset-right)) !important`,
    ),
  },

  table: {
    borderRadius: theme.spacing(1.5),
    boxShadow: theme.shadows[7],
    marginTop: theme.spacing(3),

    "& th": {
      textTransform: "uppercase",
      color: theme.palette.text.secondary,
      fontSize: 14,
      fontWeight: "bold",

      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1.25),
    },

    "& th:nth-child(1)": {
      paddingLeft: theme.spacing(9),
    },

    "& tr:last-child td": {
      paddingBottom: theme.spacing(3),
    },
  },
  none: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
    },
  },

  title: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5),
    },
  },

  button: {
    fontSize: "larger",
    fontWeight: 700,
    borderRadius: 4,
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 15),
    },
  },
}));

export const CLIENT_FORMS_LIST_QUERY_KEY = "clientFormsData";

export function CoachClientsFormsListScreen() {
  const s = useStyles();
  const pageSize = 50;
  const [after, setAfter] = React.useState<number>(1);
  const { data: clientForms, isLoading: clientFormsQueryInFlight } = useQuery({
    queryKey: [CLIENT_FORMS_LIST_QUERY_KEY, after],
    queryFn: () => ClientFormsCoachService.getFormsList(after, pageSize),
  });

  const handlePageChange = React.useCallback((cursor: number) => {
    setAfter(cursor);
  }, []);

  return (
    <>
      <Container className={s.root} maxWidth="xl">
        {clientFormsQueryInFlight || !clientForms ? (
          <PageSkeleton fullWidth />
        ) : clientForms.items.length > 0 ? (
          <>
            <TableContainer className={s.table} component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell># of questions</TableCell>
                    <TableCell>Created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientForms.items.map((form) => (
                    <CoachClientsFormsListItem
                      key={form.id}
                      clientForm={form}
                      currentPage={after}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <ConnectionPagination
              gotoPage={handlePageChange}
              currentPage={after}
              pages={clientForms.totalPages}
              autoHide
            />
          </>
        ) : (
          <InfoBox
            image={<img src={notesArt} alt="Notes" />}
            className={s.none}
            classes={{
              title: s.title,
            }}
            title="No client forms found"
            subtitle="You can create a new form to collect important information from your clients."
            children={
              <CreateClientFormAction
                className={s.button}
                useCustomColorStyles={false}
              />
            }
          />
        )}
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Client Forms",
        }}
      />
    </>
  );
}
