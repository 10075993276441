import React from "react";
import {
  Card,
  CardProps,
  CardHeader,
  Typography,
  Paper,
  Box,
  CardActions,
  Button,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { CardAvatar } from "../card/CardAvatar";
import { ListMenu } from "../menu/ListMenu";
import { EnrollmentScore } from "../progress/EnrollmentScore";
import { colorSystem } from "../../theme";

import { useNavigate } from "react-router-dom";
import {
  COACH_CLIENT_OVERVIEW_ROUTE,
  COACH_CLIENT_PR0GRAMS_ROUTE,
} from "../../routes/routes";
import { PaginationContext } from "../../utils/paging";
import { EnrollmentDto } from "@growth-machine-llc/stridist-api-client";
import { useUnenrollMutation } from "../dialog/mutations/UnenrollMutation";
import { title } from "process";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.text.primary,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },

  header: {
    maxWidth: 440,
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },

  cardTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
  },

  cardSubheader: {
    fontSize: 16,
    color: theme.palette.text.secondary,
  },

  text: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },

  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },

  metrics: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "space-between",
    padding: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
    },
  },

  actions: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
  },

  menuButton: {
    minWidth: theme.spacing(5.5),
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    backgroundColor: colorSystem.secondaryGray,
  },
}));

export interface EnrollmentCardProps extends CardProps {
  enrollment: EnrollmentDto;
  score: number;
  scoreLoading: any;
}

export function EnrollmentCard(props: EnrollmentCardProps) {
  const { enrollment, score, scoreLoading, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const { programSlug, filter } = React.useContext(PaginationContext);

  const { id, user: client, startDate, endDate, viaGroup } = enrollment;

  const menuState = usePopupState({
    variant: "popover",
    popupId: "clientMenu",
  });
  const triggerProps = React.useMemo(() => bindTrigger(menuState), [menuState]);
  const menuProps = React.useMemo(() => bindMenu(menuState), [menuState]);

  const { mutate: unenrollClient, isPending: unenrollClientInFlight } =
    useUnenrollMutation("client", programSlug);

  const handleViewClient = React.useCallback(() => {
    navigate(
      COACH_CLIENT_OVERVIEW_ROUTE.replace(":username", client.username),
      {
        state: { title: client.displayName },
      },
    );
  }, [client]);

  const handleViewClientProgress = React.useCallback(() => {
    navigate(
      COACH_CLIENT_PR0GRAMS_ROUTE.replace(":username", client.username),
      {
        state: { title: client.displayName },
      },
    );
  }, [client]);

  const handleUnenrollClient = React.useCallback(() => {
    menuProps.onClose();

    unenrollClient(id);
  }, [id, menuProps, unenrollClient]);

  const menuOptions = [
    {
      label: "View Progress",
      onClick: handleViewClientProgress,
    },

    {
      label: "Remove from Program",
      onClick: handleUnenrollClient,
      disabled: viaGroup,
      tooltipText: viaGroup ? "Enrolled via group" : undefined,
    },

    {
      label: "View Client Profile",
      onClick: handleViewClient,
    },
  ];

  return (
    <Card {...other}>
      <Paper className={s.actionArea}>
        <CardHeader
          className={s.header}
          avatar={
            <CardAvatar
              className={s.avatar}
              alt={client.displayName}
              src={client.photoUrl}
              children={client.displayName.substring(0, 1).toUpperCase()}
            />
          }
          title={client.displayName}
          titleTypographyProps={{ variant: "h5", className: s.cardTitle }}
          subheader={client.email}
          subheaderTypographyProps={{ className: s.cardSubheader }}
        />
        <CardActions className={s.actions}>
          <Box className={s.metrics}>
            <Typography variant="body2" className={s.text}>
              {startDate} to {endDate}
            </Typography>
            {scoreLoading ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <EnrollmentScore score={score} />
            )}
          </Box>
          <Button
            {...triggerProps}
            className={s.menuButton}
            children={<MoreHoriz />}
          />
        </CardActions>
      </Paper>

      <ListMenu
        {...menuProps}
        options={menuOptions}
        disabled={unenrollClientInFlight}
      />
    </Card>
  );
}
