import React, { MouseEventHandler } from "react";
import { CopyPlus, Trash2, ChevronsUpDown } from "lucide-react";
import { Box, IconButton, useTheme } from "@mui/material";
import MinimizedTooltip, { TooltipLine } from "../tooltip/MinimizedTooltip";
import { ButtonProps } from "@ariakit/react";

export interface WeekActionItems {
  weekId: number;
  onOpenMenu?: ButtonProps["onClick"];
  onClickDelete: MouseEventHandler<HTMLElement>;
  onClickDuplicate: MouseEventHandler<HTMLElement>;
  canDuplicate?: boolean;
  canDelete?: boolean;
}

const WeekActionItems = (props: WeekActionItems) => {
  const {
    weekId,
    onOpenMenu,
    onClickDelete,
    onClickDuplicate,
    canDuplicate,
    canDelete,
  } = props;

  const theme = useTheme();

  let assets = [
    {
      icon: <CopyPlus size={18} />,
      onClick: onClickDuplicate,
      disabled: !canDuplicate,
      tooltip: (
        <>
          <b>Duplicate</b> week
        </>
      ),
    },
    {
      icon: <Trash2 size={18} />,
      disabled: !canDelete,
      onClick: onClickDelete,
      tooltip: (
        <>
          <b>Delete</b> week
        </>
      ),
    },
  ];

  if (onOpenMenu) {
    assets = [
      {
        icon: <ChevronsUpDown size={18} />,
        onClick: onOpenMenu,
        disabled: false,
        tooltip: (
          <>
            <b>Move</b> week
          </>
        ),
      },
      ...assets,
    ];
  }

  return (
    <Box
      sx={{
        display: "flex",
        color: theme.palette.primary.main,
        paddingInline: 1,
      }}
    >
      {assets.map((item, index) => (
        <MinimizedTooltip
          key={index}
          title={<TooltipLine>{item.tooltip}</TooltipLine>}
        >
          <IconButton
            color="inherit"
            disabled={item.disabled}
            data-id={weekId}
            onClick={(event) => {
              item.onClick(event);
            }}
          >
            {item.icon}
          </IconButton>
        </MinimizedTooltip>
      ))}
    </Box>
  );
};

export default WeekActionItems;
