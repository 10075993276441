import clsx from "clsx";
import React, { MouseEvent } from "react";
import {
  CardActionArea,
  CardMedia,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { groupBy } from "lodash";
import type { ProgressDialogState } from "./ProgressDialog";
import dayjs from "dayjs";
import { LoadMoreButton } from "../button/LoadMoreButton";

// distance between edge of the image group border and center of the closest photo
const halfPhotoDist = 78;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    maxWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  cards: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row-reverse",
    width: "100vw",
    gap: 5,
    overflowX: "scroll",
    overflowY: "hidden",
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: `calc(50% - ${halfPhotoDist}px)`,
    paddingRight: `calc(50% - ${halfPhotoDist}px)`,
  },
  cardAction: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    borderRadius: theme.shape.borderRadius,
    padding: 2,
    border: `2px solid transparent`,
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    "&$selected": {
      borderColor: "inherit",
    },
  },
  cardGroup: {
    position: "relative",
    padding: 5,
    marginTop: 12,
    marginBottom: 5,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardGroupSelected: {
    borderColor: theme.palette.grey.A400,
    color: theme.palette.grey.A400,
    border: `2px solid transparent`,
    fontWeight: "bold",
  },
  cardGroupActive: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  cardGroupDisabled: {
    fontWeight: "bold",
    color: theme.palette.grey.A400,
    borderColor: theme.palette.grey.A400,
    border: `2px dashed transparent`,
  },
  cardGroupPhotos: {
    display: "flex",
    border: "0",
    borderColor: "inherit",
  },
  cardGroupTitle: {
    fontWeight: "inherit",
    height: 15,
    position: "absolute",
    justifySelf: "center",
    backgroundColor: "white",
    color: "inherit",
    paddingLeft: 5,
    paddingRight: 5,
  },
  cardGroupTopTitle: {
    top: -12,
  },
  cardGroupBottomTitle: {
    height: 8,
    bottom: -4,
    lineHeight: 1.1,
  },
  cardMedia: {
    width: 98,
    height: 94,
    [theme.breakpoints.up("md")]: {
      width: 98,
      height: 94,
    },
  },
  selected: {},
  photoActions: {
    display: "flex",
    alignItems: "end",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },
  disabled: {
    filter: theme.filters.disabled,
  },
  settings: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export interface ProgressDialogActionsProps {
  onSelect: (event: MouseEvent<HTMLElement>, url: string) => void;
  loadNextPageRef: any;
  hasNextPage: boolean;
  isLoading: boolean;
  loadingInitial: boolean;
  fetchNextPage: () => void;
  photos: any;
  state: ProgressDialogState;
  setState: (_: any) => void;
}

const PHOTOS_PRELOAD_DISTANCE = 1;

export function ProgressDialogPhotoSelector(props: ProgressDialogActionsProps) {
  const {
    photos,
    state,
    setState,
    onSelect,
    isLoading,
    loadingInitial,
    loadNextPageRef,
    hasNextPage,
    fetchNextPage,
  } = props;

  const {
    selected,
    selectedCell,
    showWeight,
    comparison,
    timeAgoFormat,
    comparisonMode,
  } = state;

  const s = useStyles();

  const handleClick = React.useCallback(
    (event) => {
      onSelect(event, event.currentTarget.dataset.id);
    },
    [onSelect],
  );

  return (
    <Box className={clsx(s.root)}>
      <Box className={s.settings}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showWeight}
              defaultChecked
              onClick={() =>
                setState((prev: ProgressDialogState) => ({
                  ...prev,
                  showWeight: !prev.showWeight,
                }))
              }
            />
          }
          label="Show body weight"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={timeAgoFormat}
              onClick={() =>
                setState((prev: ProgressDialogState) => ({
                  ...prev,
                  timeAgoFormat: !prev.timeAgoFormat,
                }))
              }
            />
          }
          label="Time ago format"
        />
      </Box>
      <div className={s.cards}>
        {Object.entries(groupBy(photos, (photo: any) => photo.takenOn)).map(
          (groups, i) => {
            const [date, photosGroup] = groups;
            const leftSelectLock =
              selectedCell == 0 &&
              comparison[1] &&
              !dayjs(date).isBefore(dayjs(comparison[1]?.takenOn), "day");
            const rightSelectLock =
              selectedCell == 1 &&
              comparison[0] &&
              !dayjs(date).isAfter(dayjs(comparison[0]?.takenOn), "day");
            const imageLock =
              comparisonMode && (leftSelectLock || rightSelectLock);
            const cardBottomTitle =
              comparison[0]?.takenOn === date
                ? "Before"
                : comparison[1]?.takenOn === date
                  ? "After"
                  : null;
            return (
              <div
                className={clsx(
                  s.cardGroup,
                  comparison.some((comp) => date === comp?.takenOn)
                    ? s.cardGroupSelected
                    : s.cardGroupDisabled,
                  date === selected?.takenOn && s.cardGroupActive,
                )}
              >
                <div className={s.cardGroupPhotos}>
                  {photosGroup.map((photo) => (
                    <CardActionArea
                      key={photo.id}
                      className={clsx(s.cardAction, {
                        [s.selected]:
                          photo.id === selected?.id ||
                          comparison.some((comp) => photo.id === comp?.id),
                      })}
                      onClick={handleClick}
                      disabled={imageLock}
                      data-id={photo.id}
                    >
                      <CardMedia
                        className={clsx(s.cardMedia, imageLock && s.disabled)}
                        image={photo.url}
                      />
                    </CardActionArea>
                  ))}
                  {groups.length - i === PHOTOS_PRELOAD_DISTANCE &&
                    hasNextPage && <div ref={loadNextPageRef} />}
                </div>
                <Typography
                  className={clsx(s.cardGroupTitle, s.cardGroupTopTitle)}
                  variant="caption"
                  color="textSecondary"
                >
                  {dayjs(date).format("MMM DD, YYYY")}
                </Typography>
                {cardBottomTitle && (
                  <Typography
                    className={clsx(s.cardGroupTitle, s.cardGroupBottomTitle)}
                    variant="caption"
                    color="textSecondary"
                  >
                    {cardBottomTitle}
                  </Typography>
                )}
              </div>
            );
          },
        )}
        {hasNextPage ||
          (loadingInitial && (
            <LoadMoreButton
              loading={isLoading || loadingInitial}
              onClick={fetchNextPage}
            />
          ))}
      </div>
      {photos.length === 0 && !loadingInitial && "No photos"}
    </Box>
  );
}
