import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  Link,
  useTheme,
  Collapse,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AppLogo } from "../app/AppLogo";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  AcceptClientInviteInvalidReason,
  planClients,
  Plan,
  SignupStep,
} from "../../constants";
import { colorSystem } from "../../theme";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { deviceSupports } from "../../utils/device";
import {
  AuthTokenClientSignUpResponse,
  useAuthTokenClientSignUp,
} from "../../hooks/useAuthTokenClientSignUp";
import { iOSMobileApp } from "../../utils/mobile";

import { ClientAcceptInviteInvalid } from "./ClientAcceptInviteInvalid";
import { GoogleButton } from "./GoogleButton";
import { OrDivider } from "./OrDivider";
import { AuthButton } from "./AuthButton";
import { VerifyEmailDialog } from "./VerifyEmailDialog";
import { useSignInMutation } from "./mutations/SignIn";
import { AppleButton } from "./AppleButton";
import { ClientDetailedLinkInviteScreen } from "./ClientDetailedLinkInviteScreen";
import { SIGN_UP_CLIENT_COMPLETE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { urlConstants } from "../../constants/urlConstants";
import { ConsentBanner } from "../app/ConsentBanner";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import {
  EmailLinkResult,
  ValidatedInviteCodeDto,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation } from "@tanstack/react-query";
import InvitesService from "../../services/InvitesService";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { useForm } from "react-hook-form";
import {
  ClientSignupField,
  clientSignupSchema,
  ClientSignupSchema,
  clientStepFields,
} from "../../utils/clientSignupSchema";
import SignupSessionTokenService from "../../utils/signupToken";
import { useTimer } from "../../hooks/useTimer";
import SignUpService from "../../services/SignUpService";
import useAuth from "../../hooks/auth/useAuth";
import dayjs from "dayjs";
import { PlainTextButton } from "../button/PlainTextButton";
import { ArrowLeftIcon } from "lucide-react";
import PasswordField from "../fields/PasswordField";
import EmaiLVerificationCodeField from "../fields/EmailVerificationCodeField";
import { AnimatePresenceBox } from "../new-editor/elements/common/AnimatePresenceBox";
import LoadingActionButton from "../button/LoadingActionButton";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingStepper from "../loading/LoadingStepper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6, 3),
    backgroundColor: colorSystem.white2,

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4, 2),
    },
  },

  groupScreen: {
    width: "100%",
    height: "100%",

    "& $logo, & $poweredBy": {
      display: "none",
    },
  },

  logo: {
    marginBottom: theme.spacing(3),
  },

  content: {
    maxWidth: 457,
    textAlign: "center",
    width: "100%",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    marginTop: theme.spacing(1.5),
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },

  input: {
    marginTop: theme.spacing(1),
  },

  conditions: {
    margin: theme.spacing(2, "auto", 0),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    textAlign: "center",
    // override link underline color
    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
      "text-decoration-color": theme.palette.text.secondary,
    },
  },

  formLinkText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },
}));

export interface ClientSignupWithInviteCodeScreenProps extends BoxProps {
  invite: ValidatedInviteCodeDto;
  resetCode: () => void;
}

export function ClientSignupWithInviteCodeScreen(
  props: ClientSignupWithInviteCodeScreenProps,
) {
  const { className, invite, resetCode, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();
  const { brandName } = useCurrentBrand();

  const [step, setStep] = React.useState(SignupStep.EMAIL);
  const [stepperCompleted, setStepperCompleted] = React.useState(false);

  const onError = useGenericErrorHandler({
    disableBadRequestSnackbar: true,
    setFieldError: (field, message) => {
      if (field === "email" || field === "code") {
        setError(field, { message, type: "manual" });
      }
    },
  });
  const { showToastAlert } = useToastAlert();

  React.useLayoutEffect(() => {
    const currentSession = SignupSessionTokenService.getSessionToken();

    if (!currentSession) return;

    const isValid =
      SignupSessionTokenService.isSessionTokenValid(currentSession);
    const { email } =
      SignupSessionTokenService.decodeSessionToken(currentSession);

    if (isValid) {
      setValue("email", email);
      setStep(SignupStep.CONFIRMATION);
    } else {
      SignupSessionTokenService.removeSessionToken();
      showToastAlert("info", {
        message: "Your signup session has expired. Please try again.",
      });
    }
  }, []);

  const {
    register,
    getValues,
    setError,
    watch,
    setFocus,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<ClientSignupSchema>({
    resolver: zodResolver(clientSignupSchema),
  });

  const { mutate: signIn, isPending: signingIn } = useSignInMutation();

  const theme = useTheme();

  const clientLimitReached =
    invite?.coachInfo?.clientsCountNoSample >
    Number(planClients(invite?.coachInfo?.plan as Plan, true));

  React.useEffect(() => {
    if (clientLimitReached) {
      showToastAlert("error", {
        message: "Sorry, this coach can't add new clients.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signUpWithIdToken = React.useCallback(
    (error, response: AuthTokenClientSignUpResponse) => {
      if (error) {
        if (error.code) {
          showToastAlert("error", {
            message: error.message,
          });
        } else {
          onError(error);
        }
      } else {
        signIn(
          { idToken: response.fireBaseToken },

          {
            onSuccess: () => {
              navigate(SIGN_UP_CLIENT_COMPLETE);
            },
          },
        );
      }
    },
    [onError, signIn, showToastAlert],
  );

  const [handleSignIn, signInInFlight] = useAuthTokenClientSignUp(
    invite.code,
    signUpWithIdToken,
  );

  const handleSignupError = React.useCallback(
    (error: Error, variables: any, context: any) => {
      setStep(SignupStep.CONFIRMATION);
      setStepperCompleted(false);
      resetSignup();
      onError(error, variables, context);
    },
    [onError, setStep],
  );

  const { seconds, setCount } = useTimer();
  const { loginWithToken } = useAuth();

  const {
    mutate: completeSignup,
    isPending: completingSignup,
    data: { accessToken } = {},
    reset: resetSignup,
  } = useMutation({
    mutationKey: ["complete-client-sign-up"],
    mutationFn: SignUpService.clientSignUpWithInviteCode,
    onError: handleSignupError,
    retry: 0,
  });

  const { mutate: sentVerificationCode, isPending: sendingVerificationCode } =
    useMutation({
      mutationKey: ["pre-client-sign-up"],
      mutationFn: SignUpService.preClientSignUp,
      onError: onError,
      retry: 0,
    });

  const { mutate: verifyCode, isPending: verifyingCode } = useMutation({
    mutationFn: SignUpService.verifyCoachSignUpEmail,
    onError: onError,
  });

  const handleSendVerificationCode = React.useCallback(() => {
    const email = getValues("email");
    sentVerificationCode(
      { email },
      {
        onSuccess: ({ nextAttemptInSeconds }) => {
          setStep(SignupStep.EMAIL_VERIFICATION);
          setCount(nextAttemptInSeconds);
        },
      },
    );
  }, [getValues, sentVerificationCode]);

  const handleVerifyCode = React.useCallback(
    async (pasteCode?: string) => {
      const email = getValues("email");
      const code = pasteCode ?? getValues("code");

      verifyCode(
        { email, code },
        {
          onSuccess: ({ sessionToken }) => {
            SignupSessionTokenService.updateSessionToken(sessionToken);
            setStep(SignupStep.CONFIRMATION);
            setCount(undefined);
          },
        },
      );
    },
    [getValues, verifyCode, setStep, setCount],
  );

  const handleSignup = React.useCallback(async () => {
    const { email, password } = getValues();

    const sessionToken = SignupSessionTokenService.getSessionToken();

    // Set loading step first
    setStep(SignupStep.PROCESSING);

    completeSignup({
      email,
      password,
      timezone: dayjs.tz.guess(),
      sessionToken: sessionToken,
      coachInviteCode: invite.code,
    });
  }, [getValues, completeSignup]);

  const validateCurrentStep = React.useCallback(
    async (onValid: () => void) => {
      {
        if (await trigger(clientStepFields[step] as ClientSignupField[], {})) {
          onValid();
        }
      }
    },
    [setStep, step],
  );

  const handleStepSubmit = React.useCallback(() => {
    validateCurrentStep(() => {
      if (step === SignupStep.EMAIL) {
        handleSendVerificationCode();
      } else if (step === SignupStep.EMAIL_VERIFICATION) {
        handleVerifyCode();
      } else if (step === SignupStep.CONFIRMATION) {
        handleSignup();
      }
    });
  }, [setStep, step, validateCurrentStep]);

  const getStepLabel = React.useCallback(() => {
    if (step === SignupStep.EMAIL) {
      return "Continue with email";
    }
    if (step === SignupStep.EMAIL_VERIFICATION) {
      return "Verify email";
    }
    if (step === SignupStep.CONFIRMATION) {
      return "Complete";
    }

    return "Continue";
  }, [step]);

  const onFieldTouched = (field: ClientSignupField) => {
    if (errors?.[field]?.type === "manual") {
      clearErrors(field);
    }
  };

  const handleChangeEmail = React.useCallback(() => {
    reset();
    setStep(SignupStep.EMAIL);

    SignupSessionTokenService.removeSessionToken();
  }, [setValue, setStep]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLFormElement>) => {
      if (event.key === "Enter") {
        handleStepSubmit();
      }
    },
    [handleStepSubmit],
  );

  React.useEffect(() => {
    setTimeout(() => {
      switch (step) {
        case SignupStep.EMAIL:
          setFocus("email");
          break;
        case SignupStep.EMAIL_VERIFICATION:
          setFocus("code");
          break;
        case SignupStep.CONFIRMATION:
          setFocus("password");
          break;
      }
    }, 300); // Wait for animation to complete
  }, [step]);

  React.useEffect(() => {
    if (stepperCompleted && accessToken) {
      SignupSessionTokenService.removeSessionToken();
      loginWithToken(accessToken);
      navigate(SIGN_UP_CLIENT_COMPLETE);
    }
  }, [stepperCompleted, accessToken]);

  const pending =
    completingSignup ||
    verifyingCode ||
    sendingVerificationCode ||
    signingIn ||
    signInInFlight;

  if (user) {
    return (
      <ClientAcceptInviteInvalid
        reason={AcceptClientInviteInvalidReason.ANOTHER_USER_LOGGED}
        onLogout={resetCode}
      />
    );
  }

  const applyGroupScreen = Boolean(invite?.groupInfo || invite?.programInfo);

  return (
    <ClientDetailedLinkInviteScreen invite={invite} enabled={applyGroupScreen}>
      <Box
        className={clsx(s.root, className, applyGroupScreen && s.groupScreen)}
        {...other}
      >
        <Box className={s.content}>
          <AnimatePresenceBox
            variant="fadeInOutUp"
            visible={step < SignupStep.PROCESSING}
            sx={{
              [theme.breakpoints.up("md")]: {
                height: 400,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              },
            }}
          >
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <AppLogo className={s.logo} />
            </Box>
            <Typography className={s.title} variant="h1">
              {invite?.coachInfo.displayName} on {brandName}
            </Typography>
            <Typography className={s.subtitle} variant="h5">
              Create a free {brandName} account to join your coach.
            </Typography>

            {(deviceSupports.googleAuthClientSignUpEnabled ||
              deviceSupports.appleAuthClientSignUpEnabled) && (
              <Collapse in={step === SignupStep.EMAIL} timeout={300}>
                {deviceSupports.appleAuthClientSignUpEnabled && (
                  <AppleButton
                    onSuccess={handleSignIn}
                    disabled={pending || clientLimitReached}
                    children="Sign up with Apple"
                  />
                )}

                {deviceSupports.googleAuthClientSignUpEnabled && (
                  <GoogleButton
                    onSuccess={handleSignIn}
                    disabled={pending || clientLimitReached}
                    children="Sign up with Google"
                  />
                )}

                <Box
                  sx={{
                    width: "100%",
                    opacity: step === SignupStep.EMAIL ? 1 : 0,
                    // HACK: Added extra transition and `opacity`, since `absolute` positioned divider works badly with collapse
                    transition:
                      step === SignupStep.EMAIL
                        ? "opacity 0.3s"
                        : "transition: 2.5s",
                  }}
                >
                  <OrDivider className={s.divider} />
                </Box>
              </Collapse>
            )}

            {step >= SignupStep.EMAIL_VERIFICATION &&
              step !== SignupStep.PROCESSING && (
                <PlainTextButton
                  onClick={handleChangeEmail}
                  startIcon={<ArrowLeftIcon size={16} />}
                  sx={{ marginTop: 4 }}
                  disabled={pending}
                >
                  Change email
                </PlainTextButton>
              )}
          </AnimatePresenceBox>

          <AnimatePresenceBox
            sx={{ marginTop: 2 }}
            variant="fadeInOutUp"
            mode="popLayout"
            visible={step < SignupStep.PROCESSING}
          >
            <form
              onSubmit={(e) => e.preventDefault()}
              onKeyDown={handleKeyDown}
            >
              <TextField
                fullWidth
                name="email"
                type="email"
                label="Email"
                placeholder="Enter your email..."
                {...register("email", {
                  onChange: (e) => onFieldTouched("email"),
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message ?? " "}
                variant="filled"
                disabled={step > SignupStep.EMAIL}
              />

              {step === SignupStep.EMAIL_VERIFICATION && (
                <EmaiLVerificationCodeField
                  fullWidth
                  onValidCodePaste={handleVerifyCode}
                  onResendCode={handleSendVerificationCode}
                  nextResendInSeconds={seconds}
                  register={register("code", {
                    onChange: (e) => onFieldTouched("code"),
                  })}
                  error={Boolean(errors.code)}
                  helperText={errors.code?.message ?? " "}
                  variant="filled"
                  className={s.input}
                />
              )}

              {step >= SignupStep.CONFIRMATION && (
                <PasswordField
                  className={s.input}
                  fullWidth
                  name="password"
                  label="Password"
                  placeholder="Enter a secure password..."
                  register={register("password", {
                    onChange: (e) => clearErrors("password"),
                  })}
                  value={watch("password")}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message ?? " "}
                  variant="filled"
                />
              )}

              <Box sx={{ marginTop: 2 }}>
                <LoadingActionButton
                  fullWidth
                  onClick={handleStepSubmit}
                  children={getStepLabel()}
                  disabled={clientLimitReached}
                  loading={pending}
                />
              </Box>
            </form>
          </AnimatePresenceBox>

          <AnimatePresenceBox
            variant="fadeInOutUp"
            visible={step === SignupStep.PROCESSING}
            sx={{
              // center loading stepper
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <LoadingStepper
              interval={1.2}
              title={`Setting up your ${brandName} account`}
              steps={[
                "Verifying your information...",
                "Configuring your account...",
                "Almost there...",
              ]}
              onComplete={() => setStepperCompleted(true)}
            />
          </AnimatePresenceBox>

          {step !== SignupStep.PROCESSING && (
            <Typography className={s.conditions}>
              By signing up, you agree to our{" "}
              <Link
                href={urlConstants.terms}
                className={s.formLinkText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </Link>{" "}
              and{" "}
              <Link
                noWrap
                href={urlConstants.privacy}
                className={s.formLinkText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
              .
              {step === SignupStep.EMAIL && (
                <Typography className={s.formLinkText} sx={{ marginTop: 2 }}>
                  Already have an account? <Link href="/login">Log In</Link>
                </Typography>
              )}
            </Typography>
          )}
        </Box>
        <ConsentBanner />
      </Box>
    </ClientDetailedLinkInviteScreen>
  );
}
