import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { ComponentStatus } from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";

export const archiveComponentAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    weekId: number;
    componentId: number;
    componentStatusData: {
      status: ComponentStatus;
      previousStatus: ComponentStatus;
    };
  }>,
) => {
  const { componentId, componentStatusData } = action.payload;
  if (!componentId || !componentStatusData) return;

  const { status, previousStatus } = componentStatusData;
  if (!status) return;

  const newStatus =
    status === ComponentStatus.ARCHIVED
      ? previousStatus && previousStatus !== ComponentStatus.DRAFT
        ? previousStatus
        : ComponentStatus.DRAFT
      : ComponentStatus.ARCHIVED;

  if (state.value.components[componentId].baseComponentId) {
    throw new Error("Cannot archive an override component");
  }

  const lastState = state.value.components[componentId].status;

  state.value.components[componentId] = {
    ...state.value.components[componentId],
    status: newStatus,
    previousStatus: lastState,
    updatedAt: dayjs(),
  };

  const overrides = state.value.components[componentId].overrides;
  if (overrides) {
    overrides.forEach((overrideId) => {
      state.value.components[overrideId] = {
        ...state.value.components[overrideId],
        status: newStatus,
        previousStatus: lastState,
        updatedAt: dayjs(),
      };
    });
  }
};
