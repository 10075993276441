import clsx from "clsx";
import React, { useState, useEffect } from "react";
import {
  Box,
  ContainerProps,
  useMediaQuery,
  useTheme,
  LinearProgress,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { usePreview } from "../../hooks/usePreview";
import { useSearchParams } from "react-router-dom";
import { ProgramDetails } from "../program/ProgramDetails";
import {
  DEFAULT_PROGRAM_DETAILS_VIEW_MODE,
  ProgramDetailsViewButton,
  ProgramDetailsViewMode,
} from "../program/ProgramDetailsViewButton";
import {
  ComponentTypeFilter,
  defaultFilters,
  Filters,
  isComponentTypeFilterApplied,
  WEEK_DAYS,
} from "../program/filters/ComponentTypeFilter";
import {
  StartDateFilter,
  ProgramStartDateViewButtonProps,
} from "../program/filters/StartDateFilter";
import { ProgramStartDateViewDetails } from "../program/filters/StartDateFilter";
import { ToggleButton } from "../button/ToggleButton";
import { isMobileApp } from "../../utils/mobile";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import {
  ProgramWeekRangeDetails,
  WeeksFilter,
  ProgramWeekRangeSelectorProps,
} from "../program/filters/WeeksFilter";
import { getCurriculumViewStorageKey } from "../../routes/coach/program/curriculum/CoachProgramCurriculumRoute";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import {
  addComponent,
  addWeek,
  deleteWeek,
  duplicateWeek,
  moveWeek,
} from "../../redux/curriculum/curriculum-slice";
import { WeekMoveDirectionType } from "../../constants";
import {
  TEMPLATE_WORKOUT_TITLE,
  useGenerateTemplateWorkoutSection,
} from "../workout/utils";
import { ComponentRepeat, ComponentType } from "../../constants";
import { DEFAULT_COMPONENT_DAYS } from "../../utils/component";
import { NormalizedCurriculumProgram } from "../../redux/types";
import {
  isProgramEmpty,
  selectFirstWeek,
  selectFromWeekFirstVisibleComponent,
  selectProgramWeeks,
  selectWeeksComponents,
} from "../../redux/curriculum/selectors/curriculum";
import { ComponentStatus } from "@growth-machine-llc/stridist-api-client";
import { useHistoryBlock } from "../history-block/hooks";
import { selectGeneralLoading } from "../../redux/api/selectors";
import { isEqual } from "lodash";
import WeekDaysFilter, {
  isWeekDaysFilterApplied,
} from "../program/filters/WeekDaysFilter";
import { Archive, Image, PaletteIcon } from "lucide-react";
import CurriculumProviders from "./curriculum/CurriculumProviders";
import { DEFAULT_VIEW_OPTIONS } from "../../contexts/CurriculumViewOptionsContext";
import { CoachProgramPreviewRoute } from "../../routes/coach/programs/preview/program/CoachProgramPreviewRoute";
import FilterButton from "../program/filters/FilterButton";
import { SHEETS_MAX_WEEK_RANGE } from "../program/util";
import { ProgramDetailsSidebarModeButton } from "../program/ProgramDetailsSidebarModeButton";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import {
  DeleteConfirmationMode,
  useDeleteConfirmation,
} from "../program/modals/DeleteConfirmation.tsx/DeleteConfirmationModalContext";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  // TODO try to drop as much as possible @global styles
  "@global": {
    "#root": {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
  },

  rootWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },

  root: {
    position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginInline: "auto",
  },

  loader: {
    marginInline: "auto",
    marginTop: theme.spacing(-0.25),
    maxWidth: 1180,
    height: "2px",
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeOut,
    }),
  },

  previewWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(PREVIEW_BAR_SPACING),
    height: `calc(100vh - ${theme.spacing(PREVIEW_BAR_SPACING)})`,
    width: "100vw",
  },

  filters: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    paddingBlock: theme.spacing(1.5),
    paddingInline: theme.spacing(4),
    justifyContent: "space-between",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[300],

    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
      justifyContent: "center",
    },
  },

  filtersBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap-reverse",
      gap: theme.spacing(1),
      justifyContent: "center",
    },
  },

  filtersLeftBox: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
      marginLeft: "unset",
      justifyContent: "center",
    },
  },

  togglesBox: {
    display: "flex",
    flexWrap: "nowrap",
    gap: theme.spacing(1.2),
  },
}));

export interface CurriculumViewOptions {
  view: ProgramDetailsViewMode;
  dateDetails: ProgramStartDateViewDetails;
  // TODO decide what to do with obsolete values in users localstorage
  weekDetails?: ProgramWeekRangeDetails;
  visibleWeeks: number[];
  showCovers: boolean;
  enableColors: boolean;
  enableSidebar: boolean;
}

export interface CurriculumEditScreenProps
  extends Omit<ContainerProps, "children"> {
  program: NormalizedCurriculumProgram;
}

export const FILTER_ICON_SIZE = 20;

export function CurriculumEditScreen({ program }: CurriculumEditScreenProps) {
  const dispatch = useCurriculumDispatch();
  const isEmpty = useCurriculumSelector(isProgramEmpty);
  const firstWeek = useCurriculumSelector(selectFirstWeek);
  const theme = useTheme();
  const IS_MD = useMediaQuery(theme.breakpoints.down("md"));
  const IS_SM = useMediaQuery(theme.breakpoints.down("sm"));
  const s = useStyles();
  const [searchParams] = useSearchParams();
  const component = searchParams.get("component");
  const [preview] = usePreview();
  const [filters, setFilters] = React.useState(defaultFilters);
  const { defaultView, length } = program;
  // TODO_API_V2_CURRICULUM handle loading
  const [loading, _] = React.useState(false);
  const templateWorkoutSection = useGenerateTemplateWorkoutSection();
  const [unsafe, setDirty, dirty] = useHistoryBlock();
  const apiLoading = useCurriculumSelector(selectGeneralLoading);
  const [weekDaysFilter, setWeekDaysFilter] = useState<number[]>(WEEK_DAYS);
  useEffect(() => setDirty(apiLoading), [apiLoading]);
  const { openSidebar } = useSidebar();
  const { openDialog: openDeleteConfirmationDialog, popupState } =
    useDeleteConfirmation();
  const { showToastAlert } = useToastAlert();

  const PROGRAM_WEEKS = useCurriculumSelector(selectWeeksComponents);

  // Sheets value used since lowest one, for simplicity
  const MAX_WEEKS_PER_PAGE = SHEETS_MAX_WEEK_RANGE;

  const INITIAL_VIEW_OPTIONS = {
    ...DEFAULT_VIEW_OPTIONS,
    visibleWeeks: Array.from(
      { length: length > MAX_WEEKS_PER_PAGE ? MAX_WEEKS_PER_PAGE : length },
      (_, i) => i + 1,
    ),
    view: defaultView.toLowerCase() as ProgramDetailsViewMode,
  };

  const IS_CALENDAR_ENABLED = !IS_SM;

  const CURRICULUM_VIEW_STORAGE_KEY = getCurriculumViewStorageKey(
    program.slug,
    program.id.toString(),
  );

  // LOCAL STORAGE
  const [savedViewOptions, setSavedViewOptions] =
    useLocalStorage<CurriculumViewOptions>(
      CURRICULUM_VIEW_STORAGE_KEY,
      INITIAL_VIEW_OPTIONS,
    );

  const savedViewOptionsMiddleware = (
    savedViewOptions: CurriculumViewOptions,
  ) => {
    //
    // NORMALIZE possible mismatch
    // e.g. `visibleWeeks` storage value could be outdated after updates in other browser or similar...
    savedViewOptions.visibleWeeks = savedViewOptions?.visibleWeeks?.filter(
      (week) => week <= program.length,
    );

    //
    // NORMALIZE obsolete range
    // TODO drop after enough amount of time

    // return if already normalized
    if (savedViewOptions?.visibleWeeks?.length > 0) return savedViewOptions;

    // if don't exist use first as default
    const obsoleteWeeksDetails = savedViewOptions?.weekDetails ?? {
      startNumber: 1,
      endNumber: 1,
    };

    // create arr
    const visibleWeeksArr = Array.from(
      {
        length:
          obsoleteWeeksDetails.endNumber - obsoleteWeeksDetails.startNumber + 1,
      },
      (_, i) => obsoleteWeeksDetails.startNumber + i,
    );

    const result = {
      ...savedViewOptions,
      visibleWeeks: visibleWeeksArr,
      // clean up
      weekDetails: undefined,
    };

    return result;
  };

  // CURRICULUM MAIN VIEW STATE
  const [curriculumView, setCurriculumView] = useState<CurriculumViewOptions>({
    ...INITIAL_VIEW_OPTIONS,
    ...savedViewOptionsMiddleware(savedViewOptions),
  });

  const VIEW_OPTIONS = curriculumView;

  // change calendar view on small screens
  const VIEW_MODE: ProgramDetailsViewMode =
    !IS_CALENDAR_ENABLED &&
    VIEW_OPTIONS?.view === ProgramDetailsViewMode.CALENDAR
      ? DEFAULT_PROGRAM_DETAILS_VIEW_MODE
      : VIEW_OPTIONS.view;

  const START_DATE_VIEW_DETAILS = VIEW_OPTIONS.dateDetails;

  //
  // HELPERS

  // Get first visible component slug
  const getFirstVisibleComponentSlug = useCurriculumSelector(
    // First visible component - is first by position not archived component,
    // if needed, logic could be tweaked for best experience on every view
    selectFromWeekFirstVisibleComponent(
      VIEW_OPTIONS.visibleWeeks.sort((a, b) => a - b)[0],
    ),
  )?.slug;

  // Get week number by week id
  const getWeekNumberById = (id: number) => {
    return PROGRAM_WEEKS?.find((w) => w.id === id)?.week;
  };

  // Sync weeks filter on week action
  const updateVisibleWeeks = (
    v: CurriculumViewOptions,
    actionType: "add" | "delete" | "duplicate",
    targetWeekNumber = null,
  ) => {
    const arr = [...v.visibleWeeks];

    switch (actionType) {
      case "add":
        // Add a new week by incrementing the last visible week
        arr.push(arr[arr.length - 1] + 1);
        // Ensure the number of visible weeks doesn't exceed the max limit
        if (arr.length > MAX_WEEKS_PER_PAGE) arr.shift();
        break;

      case "delete":
        // If only one week is visible, decrement its value (if value is not 1)
        if (arr.length === 1) {
          if (arr[0] !== 1) arr[0] = arr[0] - 1;
        }
        // Otherwise, simply remove the last week
        else arr.pop();
        break;

      case "duplicate":
        // If the duplicated week is the last visible one, add a new week after it
        if (arr[arr.length - 1] === targetWeekNumber) {
          arr.push(arr[arr.length - 1] + 1);
          // Ensure the visible weeks stay within the max limit
          if (arr.length > MAX_WEEKS_PER_PAGE) arr.shift();
        }
        break;
    }

    return { ...VIEW_OPTIONS, visibleWeeks: arr };
  };

  //
  // WEEK CONTROLS

  // Week filter
  const handleFiltersChange = React.useCallback((filters: Filters) => {
    setFilters(filters);
  }, []);

  // View mode
  const handleViewModeChange = (view) => {
    setCurriculumView({ ...VIEW_OPTIONS, view });
  };

  // Start date
  const handleStartDateViewDetailsChange: ProgramStartDateViewButtonProps["onChange"] =
    (dateDetails) => {
      setCurriculumView({ ...VIEW_OPTIONS, dateDetails });
    };

  // Weeks range
  const handleWeeksDateRangeChange: ProgramWeekRangeSelectorProps["onChange"] =
    (visibleWeeks) => {
      setCurriculumView({ ...VIEW_OPTIONS, visibleWeeks });
    };

  // Show covers
  const handleShowCoversToggle = () => {
    setCurriculumView({
      ...VIEW_OPTIONS,
      showCovers: !VIEW_OPTIONS.showCovers,
    });
  };

  // Enable colors
  const handleEnableColorsToggle = () => {
    setCurriculumView({
      ...VIEW_OPTIONS,
      enableColors: !VIEW_OPTIONS.enableColors,
    });
  };

  // Enable sidebar
  const handleEnableSidebarToggleGroup = () => {
    if (!VIEW_OPTIONS.enableSidebar && getFirstVisibleComponentSlug) {
      openSidebar(getFirstVisibleComponentSlug);
    }

    setCurriculumView({
      ...VIEW_OPTIONS,
      enableSidebar: !VIEW_OPTIONS.enableSidebar,
    });
  };

  // Enable archived
  const handleEnableArchivedToggle = () => {
    setFilters((v) => {
      return { ...v, archived: !v.archived };
    });
  };

  //
  // WEEK ACTIONS

  const handleAddWeek = () => {
    dispatch(addWeek());

    // Sync filter
    setCurriculumView((v) => updateVisibleWeeks(v, "add"));

    showToastAlert("success", {
      message: "New week added successfully.",
    });
  };

  const handleDeleteWeek = (event, setMoreMenuEl) => {
    const id = Number(event.currentTarget.dataset.id);
    const weekToDelete = PROGRAM_WEEKS.find((w) => w.id === id);
    const weekComponents = weekToDelete?.components;

    const executeDeleteWeek = () => {
      // Sync filter
      setCurriculumView((v) => updateVisibleWeeks(v, "delete"));

      dispatch(deleteWeek(id));
      setMoreMenuEl(null);

      showToastAlert("success", {
        message: `Week ${weekToDelete.week} deleted successfully.`,
      });
    };

    if (weekComponents && weekComponents.length > 0) {
      openDeleteConfirmationDialog(
        DeleteConfirmationMode.WEEK,
        executeDeleteWeek,
      );
      popupState.open(event);
    } else {
      executeDeleteWeek();
    }
  };

  const handleDuplicateWeek = (event) => {
    dispatch(duplicateWeek(Number(event.currentTarget.dataset.id)));

    // Sync filter
    const targetWeekNumber = getWeekNumberById(
      Number(event.currentTarget.dataset.id),
    );
    setCurriculumView((v) =>
      updateVisibleWeeks(v, "duplicate", targetWeekNumber),
    );

    const targetWeek = PROGRAM_WEEKS.find(
      (w) => w.id === Number(event.currentTarget.dataset.id),
    );

    showToastAlert("success", {
      message: `Week ${targetWeek.week} duplicated successfully.`,
    });
  };

  const handleMove = (event, closeMoreMenu) => {
    const { id, direction: _direction } = event.currentTarget.dataset;
    const direction: WeekMoveDirectionType = _direction.toUpperCase();
    const input = {
      id: Number(id),
      direction,
    };
    const targetWeek = PROGRAM_WEEKS.find((w) => w.id === Number(id));

    dispatch(moveWeek(input));
    closeMoreMenu();
    showToastAlert("success", {
      message: `Week ${targetWeek.week} moved ${direction.toLowerCase()} successfully.`,
    });
  };

  // EMPTY SPREADSHEET INIT TEMPLATE
  useEffect(() => {
    if (VIEW_MODE === ProgramDetailsViewMode.SPREADSHEET && isEmpty) {
      const input = {
        type: ComponentType.WORKOUT,
        title: TEMPLATE_WORKOUT_TITLE,
        status: ComponentStatus.DRAFT,
        days: DEFAULT_COMPONENT_DAYS,
        repeat: ComponentRepeat.NONE,
        duration: 1,
        content: JSON.stringify([templateWorkoutSection]),
      };

      dispatch(
        addComponent({
          weekId: firstWeek.id,
          componentType: ComponentType.WORKOUT,
          initialComponentData: input,
        }),
      );
    }
  }, [VIEW_MODE, program]);

  // SYNC LOCAL STORAGE
  useEffect(() => {
    if (!isEqual(savedViewOptions, VIEW_OPTIONS)) {
      setSavedViewOptions(VIEW_OPTIONS);
    }
  }, [curriculumView]);

  // SHOW UI ELEMENTS
  const SHOW_DAYS_FILTER = VIEW_MODE === ProgramDetailsViewMode.SPREADSHEET;

  const SHOW_COVER_TOGGLE =
    VIEW_MODE === ProgramDetailsViewMode.LIST && !isMobileApp;

  // UI COMPONENTS
  const LoaderComponent = () => {
    return (
      // TODO integrate with new tabs layout
      <LinearProgress
        className={s.loader}
        sx={{ opacity: apiLoading ? 1 : 0 }}
      />
    );
  };

  const PreviewComponent = () => {
    return (
      <Box className={s.previewWrapper}>
        <PreviewBar />
        <PreviewBox>
          <CoachProgramPreviewRoute />
        </PreviewBox>
      </Box>
    );
  };

  const FiltersComponent = () => {
    const IS_COMPONENT_TYPE_FILTER_APPLIED =
      isComponentTypeFilterApplied(filters);
    const IS_WEEK_DAYS_FILTER_APPLIED = isWeekDaysFilterApplied(weekDaysFilter);

    const FiltersDivider = () => {
      return !IS_SM && <Divider orientation={"vertical"} flexItem />;
    };

    return (
      <Box className={s.filters}>
        {/* FILTERS RIGHT BOX */}
        <Box className={s.filtersBox}>
          <ProgramDetailsViewButton
            value={VIEW_MODE}
            onChange={handleViewModeChange}
            isCalendarEnabled={IS_CALENDAR_ENABLED}
            disabled={loading}
          />

          <FiltersDivider />

          {!IS_SM && (
            <StartDateFilter
              value={START_DATE_VIEW_DETAILS}
              onChange={handleStartDateViewDetailsChange}
              fullWidth={IS_SM}
              slug={program.slug}
            />
          )}

          {IS_SM && (
            <WeeksFilter
              view={VIEW_MODE}
              value={VIEW_OPTIONS.visibleWeeks}
              onChange={handleWeeksDateRangeChange}
              programLength={program.length}
              disabled={loading || program.length === 0}
            />
          )}
        </Box>

        {/* FILTERS LEFT BOX */}
        <Box className={clsx(s.filtersBox, s.filtersLeftBox)}>
          {IS_SM && (
            <StartDateFilter
              value={START_DATE_VIEW_DETAILS}
              onChange={handleStartDateViewDetailsChange}
              fullWidth={IS_SM}
              slug={program.slug}
            />
          )}

          {!IS_SM && (
            <WeeksFilter
              view={VIEW_MODE}
              value={VIEW_OPTIONS.visibleWeeks}
              onChange={handleWeeksDateRangeChange}
              programLength={program.length}
              disabled={loading || program.length === 0}
            />
          )}

          <FiltersDivider />

          {!IS_SM && SHOW_DAYS_FILTER && (
            <WeekDaysFilter
              weekDaysFilter={weekDaysFilter}
              setWeekDaysFilter={setWeekDaysFilter}
            />
          )}

          {SHOW_DAYS_FILTER && IS_WEEK_DAYS_FILTER_APPLIED && (
            <FiltersDivider />
          )}

          {!IS_SM && (
            <ComponentTypeFilter
              filters={filters}
              onChange={handleFiltersChange}
              disabled={loading}
            />
          )}

          {IS_COMPONENT_TYPE_FILTER_APPLIED && <FiltersDivider />}

          <FilterButton
            filters={filters}
            onChange={handleFiltersChange}
            weekDaysFilter={weekDaysFilter}
            setWeekDaysFilter={setWeekDaysFilter}
            showDaysFilter={SHOW_DAYS_FILTER}
          />

          <FiltersDivider />

          <Box className={s.togglesBox}>
            <ToggleButton
              value={"color"}
              icon={<PaletteIcon size={FILTER_ICON_SIZE} />}
              toggleFlag={VIEW_OPTIONS.enableColors}
              onToggle={handleEnableColorsToggle}
              tooltipTitle="Toggle view mode between grayscale and color"
            />

            {SHOW_COVER_TOGGLE && (
              <ToggleButton
                value={"cover"}
                icon={<Image size={FILTER_ICON_SIZE} />}
                toggleFlag={VIEW_OPTIONS.showCovers}
                onToggle={handleShowCoversToggle}
                tooltipTitle="Hide/show component covers"
              />
            )}

            <ToggleButton
              value={"archive"}
              icon={<Archive size={FILTER_ICON_SIZE} />}
              toggleFlag={filters.archived}
              onToggle={handleEnableArchivedToggle}
              tooltipTitle="Hide/show archived components"
            />
          </Box>

          {!IS_MD && <Divider orientation={"vertical"} flexItem />}

          {!IS_MD && (
            <ProgramDetailsSidebarModeButton
              value={VIEW_OPTIONS.enableSidebar}
              onChange={handleEnableSidebarToggleGroup}
            />
          )}
        </Box>
      </Box>
    );
  };

  const ProgramComponent = () => {
    return (
      <Box className={s.rootWrapper}>
        <Box className={s.root}>
          <ProgramDetails
            program={program}
            viewMode={VIEW_MODE}
            filters={filters}
            weeksFilter={VIEW_OPTIONS.visibleWeeks}
            enableSidebar={VIEW_OPTIONS.enableSidebar}
            weekDaysFilter={weekDaysFilter}
            startDate={START_DATE_VIEW_DETAILS.date}
            weeks={program.weeks}
            handleAddWeek={handleAddWeek}
            handleDeleteWeek={handleDeleteWeek}
            handleDuplicateWeek={handleDuplicateWeek}
            handleMove={handleMove}
            handleWeeksDateRangeChange={handleWeeksDateRangeChange}
          />
        </Box>
      </Box>
    );
  };

  return (
    <CurriculumProviders viewOptions={VIEW_OPTIONS}>
      {LoaderComponent()}
      {preview && !component ? (
        PreviewComponent()
      ) : (
        <>
          {FiltersComponent()}
          {ProgramComponent()}
        </>
      )}
    </CurriculumProviders>
  );
}
