import React from "react";
import { CurriculumComponent } from "../../redux/types";
import { ComponentType } from "../../constants";
import ReminderSettings from "./ReminderSettings";
import MessageSettings from "./MessageSettings";

export interface IComponentTimeSettingsProps {
  component: CurriculumComponent;
}

const ComponentTimeSettings = (props: IComponentTimeSettingsProps) => {
  const { component } = props;
  const IS_MESSAGE = component.type === ComponentType.MESSAGE;
  return IS_MESSAGE ? (
    <MessageSettings component={component} />
  ) : (
    <ReminderSettings component={component} />
  );
};

export default ComponentTimeSettings;
