import clsx from "clsx";
import React from "react";
import { Box, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getCloudFlareSources, isVideoStream } from "../../utils/component";
import ReactPlayer from "react-player";
import { useThumbnail } from "../../hooks/useThumbnail";
import { WorkoutMediaProps } from "../workout/WorkoutDemoVideoPreview";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "opacity 0.5s ease-in-out, visibility 0.5s ease-in-out",
    zIndex: 2,
  },

  hidden: {
    opacity: 0,
    visibility: "hidden",
  },

  thumbnail: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    cursor: "pointer",
  },

  playIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "3rem",
    pointerEvents: "none",
  },

  spinner: {
    width: 20,
    height: 20,
    position: "absolute",
    left: "50%",
    top: "50%",
    marginTop: -15,
    marginLeft: -15,
  },

  video: {
    width: "100%",
    height: "100%",
    minHeight: 200,
    maxHeight: 200,
    transition: "opacity 0.5s ease-in-out, visibility 0.5s ease-in-out",

    "& video": {
      width: "100%",
      height: "100%",
    },
  },
}));

export function WorkoutVideoDemoWithThumbnail(props: WorkoutMediaProps) {
  const { className, video, ...other } = props;
  const s = useStyles();
  const sources = getCloudFlareSources(video);
  const [isClicked, setIsClicked] = React.useState(false);
  const thumbnail = useThumbnail(video);
  const [isStarted, setIsStarted] = React.useState(false);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {isClicked && (
        <ReactPlayer
          className={s.video}
          controls
          url={isVideoStream(video) ? sources[2].src : video}
          playing={isClicked}
          onProgress={({ playedSeconds }) => {
            if (!isStarted && playedSeconds > 0) {
              setIsStarted(true);
            }
          }}
          progressInterval={500}
        />
      )}
      <Box className={clsx(s.overlay, isStarted && s.hidden)}>
        <img
          src={thumbnail}
          alt="Video thumbnail"
          className={s.thumbnail}
          onClick={() => setIsClicked(true)}
        />
        {!isClicked ? (
          <PlayCircleFilledIcon className={s.playIcon} />
        ) : (
          <CircularProgress color="inherit" className={s.spinner} size={25} />
        )}
      </Box>
    </Box>
  );
}
