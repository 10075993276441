import React from "react";
import {
  Menu,
  MenuProps,
  MenuItem,
  ListItemText,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useLogout } from "../../hooks/useLogout";
// import { isMobileApp } from "../../utils/mobile";

import { UserAccountMenuItem } from "./UserAccountMenuItem";
import {
  ADMIN_USERS_ROUTE,
  CLIENT_SETTING_PROFILE_ROUTE,
  COACH_PROFILE_ROUTE,
} from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { GetAnyHelpModal } from "./GetAnyHelpModal";
import { UserRole } from "../../constants";
import { usePopupState } from "material-ui-popup-state/hooks";
import AddClientDialogContext from "../../contexts/AddClientDialogContext";
import GetAnyHelpModalContext from "../../contexts/GetAnyHelpModalContext";

const useStyles = makeStyles((theme) => ({
  listItem: {
    minWidth: 150,
  },
  divider: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.selected.main,
  },
}));

export interface UserMenuProps extends MenuProps {
  onMenuClose: (closeAppDrawer: boolean) => void;
}

function UserMenu(props: UserMenuProps) {
  const navigate = useNavigate();
  const { className, onMenuClose, ...other } = props;
  const s = useStyles();
  const me = useCurrentUser();
  const logout = useLogout();
  const role = me?.role;
  const queryClient = useQueryClient();

  const getHelpContext = React.useContext(GetAnyHelpModalContext);
  const { setGetAnyHelpModal } = getHelpContext;

  const handleClose = React.useCallback(() => {
    setGetAnyHelpModal(false);
  }, []);

  const openSettings = React.useCallback(
    (event) => {
      onMenuClose(true);
      queryClient.clear();
      navigate(
        role === "COACH" ? COACH_PROFILE_ROUTE : CLIENT_SETTING_PROFILE_ROUTE,
      );
    },
    [onMenuClose, role],
  );

  const openAdmin = React.useCallback(
    (event) => {
      onMenuClose(true);
      navigate(ADMIN_USERS_ROUTE);
    },
    [onMenuClose],
  );

  const handleSignOut = React.useCallback(
    (event) => {
      onMenuClose(true);
      logout();
    },
    [logout, onMenuClose],
  );

  const handleOpenGetAnyHelp = React.useCallback(() => {
    setGetAnyHelpModal(true);
    onMenuClose(true);
  }, []);

  return (
    <>
      <Menu
        MenuListProps={{ dense: true }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={() => {
          onMenuClose(false);
        }}
        {...other}
      >
        {
          // !isMobileApp &&
          me?.accounts?.length > 1 &&
            me?.accounts.map((user) => (
              <UserAccountMenuItem
                key={user.id}
                user={user}
                onClose={handleClose}
              />
            ))
        }
        <MenuItem className={s.listItem} onClick={openSettings} dense>
          <ListItemText primary="Settings" />
        </MenuItem>
        {
          // !isMobileApp &&
          me?.admin && (
            <MenuItem className={s.listItem} onClick={openAdmin} dense>
              <ListItemText primary="Admin" />
            </MenuItem>
          )
        }
        <MenuItem className={s.listItem} onClick={handleSignOut} dense>
          <ListItemText primary="Log Out" />
        </MenuItem>
        {role == UserRole.COACH && (
          <>
            <Divider className={s.divider} />
            <MenuItem
              className={s.listItem}
              onClick={handleOpenGetAnyHelp}
              dense
            >
              <ListItemText primary="Need any help?" />
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}

export default UserMenu;
