import clsx from "clsx";
import React from "react";
import { DialogProps, Skeleton, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";
import { SearchField } from "../fields/SearchField";
import { isMobileApp } from "../../utils/mobile";

import { RecipientList, RecipientListProps } from "./RecipientList";
import { useInfiniteQuery } from "@tanstack/react-query";
import MessagesService from "../../services/MessagesService";
import { ThreadSkeleton } from "./ThreadSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    ...(!isMobileApp && {
      maxHeight: 800,
    }),
  },
}));

const RECIPIENT_DIALOG_PAGE_SIZE = 10;

export interface RecipientDialogProps
  extends Omit<DialogProps, "onSelect">,
    Pick<RecipientListProps, "onSelect"> {}

export function RecipientDialog(props: RecipientDialogProps) {
  const s = useStyles();
  const { onSelect, className, ...other } = props;
  const [query, setQuery] = React.useState("");
  const [search, setSearch] = React.useState("");

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
    },
    [],
  );

  React.useEffect(() => {
    const interval = setInterval(() => {
      setQuery(search);
    }, 300);

    return () => clearInterval(interval);
  }, [search]);

  const {
    data: recipientPages,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage: loading,
    isFetching: loadingInitial,
  } = useInfiniteQuery({
    queryKey: ["get-coach-recipients", query],
    queryFn: ({ pageParam }) =>
      MessagesService.getRecipients(
        pageParam ?? 1,
        RECIPIENT_DIALOG_PAGE_SIZE,
        query ? query : null,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.hasNextPage ? pages.length + 1 : undefined;
    },
  });

  const recipients = recipientPages?.pages.flatMap((page) => page.items) ?? [];

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      classes={{
        paper: s.paper,
      }}
      title="New message"
      {...other}
    >
      <SearchField
        variant="outlined"
        fullWidth
        placeholder="Search clients"
        value={search}
        onChange={handleChange}
      />
      {loadingInitial ? (
        <Stack
          spacing={2}
          sx={{
            mt: 3,
          }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <ThreadSkeleton />
          ))}
        </Stack>
      ) : (
        <RecipientList
          recipients={recipients}
          hasNext={hasNextPage}
          loading={loading}
          onLoadMore={fetchNextPage}
          onSelect={onSelect}
        />
      )}
    </BaseDialog>
  );
}
