import React, { useTransition } from "react";
import {
  Drawer,
  DrawerProps,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import { ClientMeasurementsCartDrawerEditView } from "./ClientMeasurementsCartDrawerEditView";
import {
  convertUnits,
  getDefaultUnit,
  getUnitLabel,
  measurementTypes,
  Units,
} from "../../constants";
import { ClientStepsDrawerHeader } from "../client-steps/ClientStepsDrawerHeader";
import {
  BodyMeasurementInfoVm,
  BodyMeasurementRecordDto,
  ClientBodyMeasurementsVm,
  ICreateBodyMeasurementCommand,
  MeasurementType,
  MeasurementUnit,
} from "@growth-machine-llc/stridist-api-client";
import BodyMeasurementService from "../../services/BodyMeasurementService";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { CLIENT_BODY_MEASUREMENT_LIST_QUERY_KEY } from "./ClientMeasurementsCard";
import {
  MeasurementDrawer,
  MeasurementStep,
  MetricType,
  stepFromBodyMeasurement,
} from "../client-generic-measurement/MeasurementDrawer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reverse, sortBy } from "lodash";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    padding: theme.spacing(5, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
  },
  header: {
    marginBottom: theme.spacing(4),
  },

  select: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },
  sortOption: {
    fontWeight: 500,
  },

  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),
  },

  sort: {},

  overlay: {},
}));

export interface ClientMeasurementsCartDrawerProps extends DrawerProps {
  today?: any;
  clientId: number;
  setSteps?: (steps: any[]) => void;
  steps?: any[];
  bodyMeasurementData?: BodyMeasurementInfoVm[];
  units: Units;
}

export function ClientMeasurementsCartDrawer(
  props: ClientMeasurementsCartDrawerProps,
) {
  const { onClose, open, bodyMeasurementData, units, clientId } = props;
  const s = useStyles();
  const [selectType, setSelectType] = React.useState<string>("bodyfat");
  const handleClose = React.useCallback(() => {
    onClose({}, "backdropClick");
  }, [onClose]);

  const items = React.useMemo(
    () =>
      measurementTypes
        .filter(({ label }) => label !== "Bodyweight")
        .map(({ label, name }) => ({ label, name })),
    [],
  );
  const handleTypeChangeInDrawer = (newType: string) => {
    setSelectType(newType);
  };
  const selectedType = measurementTypes.find(({ name }) => name === selectType);
  const { unitType } = selectedType;
  const unit = getDefaultUnit(unitType, units);

  const queryClient = useQueryClient();
  const bodyMeasurementQueryKey = [
    CLIENT_BODY_MEASUREMENT_LIST_QUERY_KEY,
    { clientId },
  ];
  //TODO: optimize mapping and updating last date
  const {
    mutate: createBodyMeasurement,
    isPending: isPendingCreate,
    variables: createVars,
  } = useMutation({
    mutationFn: BodyMeasurementService.createBodyMeasurement,
    onSuccess: (id: number, vars: ICreateBodyMeasurementCommand) => {
      queryClient.setQueryData(
        bodyMeasurementQueryKey,
        (oldData: ClientBodyMeasurementsVm) => {
          const updatedMeasurements = oldData.measurements.map(
            (measurement) => {
              if (measurement.measurementType === selectType.toUpperCase()) {
                return {
                  ...measurement,
                  records: [
                    {
                      ...vars,
                      id,
                    },
                    ...measurement.records,
                  ],
                };
              }
              return measurement;
            },
          );

          const updatedData = ClientBodyMeasurementsVm.fromJS({
            lastUpdatedAt:
              oldData.lastUpdatedAt != undefined &&
              oldData.lastUpdatedAt.isAfter(vars.measuredOn)
                ? oldData.lastUpdatedAt
                : vars.measuredOn,
            measurements: updatedMeasurements,
          });
          return updatedData;
        },
      );
    },
  });

  const { mutate: removeBodyMeasurement } = useOptimisticUpdateMutation({
    queryKey: bodyMeasurementQueryKey,
    mutationFn: BodyMeasurementService.deleteBodyMeasurement,
    optimisticUpdater: {
      updateFn: (oldData: ClientBodyMeasurementsVm, id: number) => {
        const updatedData = ClientBodyMeasurementsVm.fromJS({
          ...oldData,
          measurements: oldData.measurements.map((measurementInfo) => ({
            ...measurementInfo,
            records: measurementInfo.records.filter(
              (record) => record.id !== id,
            ),
          })),
        });
        return updatedData;
      },
    },
    invalidateQueryOptions: { enabled: true, refetchType: "none" },
  });

  const {
    mutate: updateBodyMeasurement,
    variables: updVars,
    isPending: isPendingUpdate,
  } = useOptimisticUpdateMutation({
    queryKey: bodyMeasurementQueryKey,
    mutationFn: BodyMeasurementService.updateBodyMeasurement,
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (oldData: ClientBodyMeasurementsVm, newData) => {
        const updatedData = ClientBodyMeasurementsVm.fromJS({
          ...oldData,
          measurements: oldData.measurements.map((measurementInfo) => ({
            ...measurementInfo,
            records: measurementInfo.records.map((record) => {
              if (record.id === newData.id) {
                return {
                  ...record,
                  value: newData.value,
                  measuredOn: newData.measuredOn,
                  unit: newData.unit,
                };
              }
              return record;
            }),
          })),
        });
        return updatedData;
      },
    },
    invalidateQueryOptions: { enabled: true, refetchType: "none" },
  });
  const updatingId = isPendingUpdate && updVars?.id;
  const creatingForDate =
    createVars?.measuredOn && isPendingCreate
      ? dayjs(createVars.measuredOn.format("MM-DD-YYYY"))
      : null;

  const genericDtoPreparedData = React.useMemo(() => {
    const selectedMeasurement = bodyMeasurementData.find(
      (measurement) => measurement.measurementType === selectType.toUpperCase(),
    );
    return selectedMeasurement.records
      .map(({ id, value, measuredOn, unit: metricUnit }) => {
        const convertedMeasurement = convertUnits(
          unitType,
          metricUnit.toLocaleUpperCase(),
          unit.toLocaleUpperCase(),
          value,
        );

        return BodyMeasurementRecordDto.fromJS({
          id,
          measuredOn,
          value: convertedMeasurement,
          unit,
        });
      })
      .sort((a, b) => (b.measuredOn.isBefore(a.measuredOn) ? -1 : 1))
      .map((i) => stepFromBodyMeasurement(i));
  }, [bodyMeasurementData, selectType]);

  const handleRemove = React.useCallback(
    ({ id }: MetricType) => {
      removeBodyMeasurement(id);
    },
    [removeBodyMeasurement],
  );
  const handleSave = React.useCallback(
    (
      { id, unit, date, name, measurement }: MetricType,
      onSettled?: () => void,
    ) => {
      if (id) {
        updateBodyMeasurement(
          {
            id,
            value: measurement,
            measuredOn: dayjs(date).startOf("day"),
            unit: unit as MeasurementUnit,
          },
          { onSettled },
        );
      } else {
        createBodyMeasurement(
          {
            clientId,
            name,
            type: selectType.toUpperCase() as MeasurementType,
            unit: unit as MeasurementUnit,
            measuredOn: dayjs(date).startOf("day"),
            value: measurement,
          },
          { onSettled },
        );
      }
    },
    [clientId, createBodyMeasurement, selectedType, updateBodyMeasurement],
  );
  return (
    <MeasurementDrawer
      open={open}
      steps={genericDtoPreparedData}
      clientId={clientId}
      disabled={false}
      title="Measurements"
      subtitle=""
      isBodyMeasurement={true}
      items={items}
      updatingId={updatingId}
      creating={isPendingCreate}
      creatingForDate={creatingForDate}
      onClose={handleClose}
      handleSave={handleSave}
      handleRemove={handleRemove}
      handleTypeChange={handleTypeChangeInDrawer}
      unit={unit}
      selectedType={selectedType}
    />
  );
}
