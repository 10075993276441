import React from "react";
import TrackInfoTool from "../../components/tools/TrackInfoTool";
import AdminStatsView from "../../components/admin/AdminStatsView";

export function AdminStatsRoute() {
  return (
    <>
      <AdminStatsView />
      <TrackInfoTool
        trackInfo={{
          name: "Admin - Db Stats",
        }}
      />
    </>
  );
}
