const numericCharacter = new RegExp("^(?=.*[0-9])");
const specialCharacter = new RegExp("^(?=.*[!@#\\\\$%^&*,.`()_+={}|:;<>?/])");
const alphabeticCharacter = new RegExp("^(?=.*[a-zA-Z])");

type PasswordRules = {
  [key: string]: {
    regex: RegExp;
    message: string;
  };
};
export const passwordRules: PasswordRules = {
  length: {
    regex: new RegExp("^(?=.{8,})"),
    message: "Enter at least 8 characters",
  },
  alphabeticalCharacter: {
    regex: alphabeticCharacter,
    message: "Enter at least 1 alphabetic character",
  },
  numericCharacter: {
    regex: numericCharacter,
    message: "Enter at least 1 numeric character",
  },
  specialCharacter: {
    regex: specialCharacter,
    message: "Enter at least 1 special character",
  },
};

export const validatePassword = (password: string) => {
  const errors = Object.values(passwordRules)
    .map((rule) => {
      if (!rule.regex.test(password)) {
        return rule.message;
      }
    })
    .filter(Boolean);

  return errors.length ? errors[0] : null;
};
