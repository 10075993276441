import React from "react";
import { alpha, Box, CircularProgress, IconButton } from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { AddComponentMenu } from "../../program/AddComponentMenu";
import { RowType } from "../../../constants";
import CopyWithArrow from "../../../icons/CopyWithArrow";
import { useTheme } from "@mui/styles";
import { SquarePlus } from "lucide-react";
import { ProgramWeekSchedule } from "../../../hooks/useProgramSchedule";
import { mapValues } from "lodash";
import { Filters } from "../../program/filters/ComponentTypeFilter";
import { addComponent } from "../../../redux/curriculum/curriculum-slice";
import { useCurriculumDispatch } from "../../../redux/hooks";
import { generateComponentDays } from "../../../utils/component";

const NoRowsOverlay = (props: any) => {
  const { dayOfWeek, weekId, duplicateByDirection, gridLoader, filters } =
    props;

  const dispatch = useCurriculumDispatch();
  const theme = useTheme();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "add-component-menu",
  });

  const copyIconEnabledStyle = {
    ":hover": {
      cursor: "pointer",
      background: alpha(theme.palette.common.black, 0.04),
    },
    stroke: theme.palette.common.black,
    pointerEvents: "all",
  };

  const copyIconBaseStyle = {
    p: 1,
    borderRadius: 2,
    stroke: theme.palette.divider,
    transition: theme.transitions.create(["background", "stroke"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    pointerEvents: "none",
  };

  const handleDuplicateClick = (data: ProgramWeekSchedule) => {
    data.forEach((item) => {
      const days = generateComponentDays(dayOfWeek);
      const input = {
        days,
        title: item.component.title,
        iconName: item.component.iconName,
        content: item.component.content,
      };
      dispatch(
        addComponent({
          weekId,
          componentType: item.component.type,
          initialComponentData: input,
        }),
      );
    });
  };

  return (
    <>
      <Box
        sx={{
          width: 1,
          height: 1,
          paddingBlock: 4,
          paddingInline: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            marginInline: "auto",
            ...copyIconBaseStyle,
            ...(!gridLoader &&
              duplicateByDirection.up && {
                ...copyIconEnabledStyle,
              }),
          }}
          onClick={() => handleDuplicateClick(duplicateByDirection.up)}
        >
          <CopyWithArrow direction="down" stroke={"inherit"} />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 1,
            marginBlock: "auto",
          }}
        >
          <Box
            sx={{
              marginRight: "auto",
              ...copyIconBaseStyle,
              ...(!gridLoader &&
                duplicateByDirection.left && {
                  ...copyIconEnabledStyle,
                }),
            }}
            onClick={() => handleDuplicateClick(duplicateByDirection.left)}
          >
            <CopyWithArrow direction="right" stroke={"inherit"} />
          </Box>

          <CircularProgress
            size={20}
            sx={{
              color: theme.palette.divider,
              position: "absolute",
              opacity: gridLoader ? 1 : 0,
              visibility: gridLoader ? 1 : 0,
              transition: theme.transitions.create(["opacity"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.complex,
              }),
            }}
          />
          <IconButton
            {...bindTrigger(menuState)}
            sx={{
              opacity: gridLoader ? 0 : 1,
              visibility: gridLoader ? 0 : 1,
              ...copyIconEnabledStyle,
              transition: theme.transitions.create(
                ["background", "stroke", "opacity"],
                {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                },
              ),
            }}
          >
            <SquarePlus
              color={theme.palette.common.black}
              size={32}
              strokeWidth={1}
            />
          </IconButton>

          <Box
            sx={{
              marginLeft: "auto",
              ...copyIconBaseStyle,
              ...(!gridLoader &&
                duplicateByDirection.right && {
                  ...copyIconEnabledStyle,
                }),
            }}
            onClick={() => handleDuplicateClick(duplicateByDirection.right)}
          >
            <CopyWithArrow direction="left" stroke={"inherit"} />
          </Box>
        </Box>

        <Box
          sx={{
            marginInline: "auto",
            ...copyIconBaseStyle,
            ...(!gridLoader &&
              duplicateByDirection.down && {
                ...copyIconEnabledStyle,
              }),
          }}
          onClick={() => handleDuplicateClick(duplicateByDirection.down)}
        >
          <CopyWithArrow direction="up" stroke={"inherit"} />
        </Box>
      </Box>
      {menuState.isOpen && (
        <AddComponentMenu
          weekId={weekId}
          dayOfWeek={dayOfWeek}
          withInitData
          disabledItems={{
            ...(mapValues(filters, (value) => !value) as Filters),
            [RowType.WORKOUT_SECTION]: true,
            [RowType.EXERCISE]: true,
          }}
          enableDetailedWorkout
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          {...bindMenu(menuState)}
        />
      )}
    </>
  );
};

export default NoRowsOverlay;
