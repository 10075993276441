import React from "react";
import { useDeleteConfirmation } from "./DeleteConfirmationModalContext";
import {
  alpha,
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  useTheme,
} from "@mui/material";
import { bindPopper } from "material-ui-popup-state/hooks";
import { Trash2, CircleX } from "lucide-react";

const DeleteConfirmationTooltip = () => {
  const { closeDialog, confirmDelete, popupState } = useDeleteConfirmation();

  const theme = useTheme();
  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        hideBackdrop={false}
        onClose={closeDialog}
        {...bindPopper(popupState)}
        sx={{
          ".MuiPaper-root": {
            borderRadius: 2,
          },
        }}
      >
        <ButtonGroup variant="outlined" size="small">
          <Button
            color="error"
            startIcon={<Trash2 />}
            sx={{
              color: theme.palette.error.contrastText,
              background: theme.palette.error.light,
              borderColor: theme.palette.error.light,
              ":hover": {
                background: alpha(theme.palette.error.light, 0.8),
                borderColor: alpha(theme.palette.error.light, 0.8),
                borderRightColor: alpha(theme.palette.error.light, 0.8),
              },
            }}
            style={{
              marginRight: "1px",
              borderRightWidth: "2px",
              borderRightColor: theme.palette.error.light,
            }}
            onClick={confirmDelete}
          >
            Confirm delete
          </Button>
          <IconButton
            size="small"
            sx={{
              borderRadius: "unset",
              color: theme.palette.common.black,
            }}
            onClick={closeDialog}
          >
            <CircleX />
          </IconButton>
        </ButtonGroup>
      </Popover>
    </>
  );
};

export default DeleteConfirmationTooltip;
