import React, { MouseEventHandler } from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  useProgramSchedule,
  ScheduleUpdate,
} from "../../hooks/useProgramSchedule";
import { ProgramCalendarWeekProps } from "../program-calendar/ProgramCalendarWeek";
import {
  ProgramSpreadsheetWeek,
  ProgramSpreadsheetWeekProps,
} from "./ProgramSpreadsheetWeek";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectCurriculum } from "../../redux/curriculum/selectors/curriculum";
import { denormalizeCurriculum } from "../../redux/normalize";
import { useDeepCompareMemoize } from "../../utils/memo";
import { ComponentClickHandlerType } from "../program/ProgramDetails";
import WeeksGap from "../curriculum/WeeksGap";
import { ProgramDefaultView } from "../../constants";

const useStyles = (columns: number) =>
  makeStyles((theme) => ({
    weeks: {
      flexDirection: "column",
      width: "100%",

      display: "grid",
      columnGap: 60,
      paddingBottom: theme.spacing(2),
    },
    week: {
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      margin: theme.spacing(3, 0, 4, 0),
    },
  }));

export interface ProgramSpreadsheetProps extends BoxProps {
  weekDaysFilter: number[];
  filters?: ProgramCalendarWeekProps["filters"];
  startDate?: string;
  weeks?: number[];
  onOpenMenu?: ProgramSpreadsheetWeekProps["onOpenMenu"];
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
  componentClickHandler: ComponentClickHandlerType;
  weeksByNumber: number[];
}

export function ProgramSpreadsheet(props: ProgramSpreadsheetProps) {
  const {
    filters,
    startDate,
    weeks,
    onOpenMenu,
    weeksActions,
    weekDaysFilter,
    componentClickHandler,
    weeksByNumber,
  } = props;

  const s = useStyles(weekDaysFilter.length)();

  const [scheduleUpdate, setScheduleUpdate] = React.useState<ScheduleUpdate>();

  const scheduleAssets = denormalizeCurriculum(
    useCurriculumSelector(selectCurriculum).value,
  );

  const [_, schedule] = useProgramSchedule(
    scheduleAssets,
    filters,
    scheduleUpdate,
  );

  const memoWeeks = useDeepCompareMemoize(weeks);

  const spreadsheetWeek = React.useMemo(
    () =>
      weeks.map((week, i) => {
        const isGap = weeksByNumber[i] - weeksByNumber[i - 1] > 1;
        return (
          <>
            <Box gridColumn={`span ${weekDaysFilter.length}`} ml={4}>
              {isGap && (
                <WeeksGap
                  startWeekNumber={weeksByNumber[i - 1] + 1}
                  endWeekNumber={weeksByNumber[i] - 1}
                  view={ProgramDefaultView.SPREADSHEET}
                />
              )}
            </Box>

            <ProgramSpreadsheetWeek
              className={s.week}
              key={week}
              weekId={week}
              schedule={schedule}
              weekDaysFilter={weekDaysFilter}
              filters={filters}
              startDate={startDate}
              onOpenMenu={onOpenMenu}
              weeksActions={weeksActions}
              componentClickHandler={componentClickHandler}
              sx={{
                // for hidden drag control
                pl: 4,
                // for every grid item set zIndex starting from last from 1 to *n
                zIndex: (weeks.length - i) * 7,
              }}
            />
          </>
        );
      }),
    [
      schedule,
      filters,
      startDate,
      memoWeeks,
      weekDaysFilter,
      componentClickHandler,
    ],
  );

  return <Box className={s.weeks}>{spreadsheetWeek}</Box>;
}
