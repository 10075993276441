import { Card, CardProps, Box, Skeleton, Grid2 } from "@mui/material";
import React from "react";

export interface ActiveProgramSkeletonCardProps extends CardProps {}

const createSkeletonGridItems = () => {
  return Array.from({ length: 2 }, (_, index) => (
    <Box
      key={`item${index + 1}`}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 170,
        width: 200,
        mt: { md: "24px" },
      }}
    >
      <Skeleton
        animation="wave"
        variant="rounded"
        height={15}
        width={index === 0 ? 200 : 170}
        sx={{ borderRadius: 6 }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={10}
        sx={{ mt: 2, borderRadius: 6 }}
        width={120}
      />
    </Box>
  ));
};

export function ActiveProgramSkeletonCard(
  props: ActiveProgramSkeletonCardProps,
) {
  const skeletonGridItems = createSkeletonGridItems();

  return (
    <Card {...props}>
      <Grid2
        container
        sx={{
          rowGap: { xs: 3, md: 0 },
        }}
      >
        <Grid2
          sx={{ flex: { xs: "1 1 100%", md: "1 1 0%" } }}
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              width: { xs: "100%", md: "90%" },
              height: { xs: 100, md: 250 },
              leftBorderRadius: 4,
              rightBorderRadius: { xs: 4, md: 0 },
            }}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 8,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              justifyContent: "space-around",
              padding: "24px",
              pl: { sm: 0 },
            }}
          >
            {skeletonGridItems}
          </Box>
        </Grid2>
      </Grid2>
    </Card>
  );
}
