"use client";

import React from "react";

import { Box, Divider, Typography, useTheme } from "@mui/material";

type InsertElementMenuGroupHeaderProps = {
  groupLabel: string;
};

export function InsertElementMenuGroupHeader({
  groupLabel,
}: InsertElementMenuGroupHeaderProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        background: theme.palette.common.white,
        zIndex: theme.zIndex.appBar,
        textTransform: "uppercase",
        fontWeight: "bold",
        p: 0,
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontWeight: "bold",
          pt: 1,
          px: 2,
        }}
      >
        {groupLabel}
      </Typography>
      <Divider sx={{ m: 0, width: 1 }} />
    </Box>
  );
}
