import React, { useState } from "react";

import { UserRole } from "../constants";
import { getFirstName, getLastName } from "../utils/user";
import { nativeIntercom } from "../utils/intercom";
import { isMobileApp } from "../utils/mobile";
import { useSSRAnalytics } from "../utils/useSSRAnalytics";

import { useCurrentUser, UserInfo } from "./useCurrentUser";
import { sendUnknownGoogleAnalyticsEvent } from "../utils/googleAnalytics";

export interface AnalyticsJS {
  identify(userId: string, traits: Object, callback?: () => void): void;
  track(event: string, properties?: Object, callback?: () => void): void;
  page(name?: string, properties?: Object, callback?: () => void): void;
  device?: (userId: string, deviceToken: string) => void;
}

const TRAITS_KEY = "traits";
let identified = false;

const identify = (analytics: AnalyticsJS, user: UserInfo) => {
  const {
    externalReferenceId,
    displayName,
    username,
    email,
    rawEmail,
    age = 0,
    role,
    photoUrl: avatar,
    phone,
    timeZone,
    gender,
    birthday,
    location,
    units,
    height,
    weight,
    created,
    coach,
    clientsCount,
    programsCount,
    trialExpiryDate,
    subscription,
    isImpersonating,
    isSample,
    accounts,
  } = user;

  const traits = {
    displayName,
    username,
    email: rawEmail || email,
    avatar,
    role,
    firstName: getFirstName(user),
    lastName: getLastName(user),
    phone,
    timeZone,
    created,
    trialExpiryDate: parseInt(trialExpiryDate?.toString()),
    plan: subscription ? "premium" : "trial",

    ...(role === UserRole.CLIENT &&
      !isSample && {
        age,
        gender,
        birthday,
        location,
        units,
        height,
        weight,
        coachName: coach?.displayName,
        coachAvatar: coach?.photoUrl,
        coachEmail: coach?.email,
      }),

    ...(role === UserRole.COACH && {
      clientsCount,
      programsCount,
    }),
  };

  if (isSample) {
    const coachAccount = accounts.find(({ isSample }) => !isSample);

    if (coachAccount) {
      Object.assign(traits, {
        displayName: coachAccount.displayName,
        username: coachAccount.username,
        email: coachAccount.email,
        avatar: coachAccount.photoUrl,
        role: coachAccount.role,
        firstName: getFirstName(user),
        lastName: getLastName(user),
        phone: coachAccount.phone,
        timeZone: coachAccount.timeZone,
        createdAt: coachAccount.created,
      });
    }
  }

  const stringifiedTraits = JSON.stringify(traits);

  if (
    !isImpersonating &&
    (!identified || localStorage.getItem(TRAITS_KEY) !== stringifiedTraits)
  ) {
    identified = true;
    localStorage.setItem(TRAITS_KEY, stringifiedTraits);
    analytics.identify(externalReferenceId, traits);
  }

  nativeIntercom.registerIdentifiedUser(
    user.externalReferenceId,
    user.rawEmail,
  );
};

const deepEqual = (left, right) =>
  JSON.stringify(left) === JSON.stringify(right);

export interface PageTrackInfo {
  name?: string;
  properties?: Object;
}

// Intercom doesn't always respond to "hide_default_launcher" update call
window["intercomSettings"] = Object.assign(window["intercomSettings"] || {}, {
  hide_default_launcher: true,
});

export function useAnalytics() {
  const user = useCurrentUser();
  const [previousPage, setPreviousPage] = useState<PageTrackInfo>();
  const isImpersonating = user ? user.isImpersonating : false;

  const ssrAnalytics = useSSRAnalytics(user?.externalReferenceId);

  const analytics = React.useMemo(() => {
    if (isMobileApp) {
      Object.assign(window, { analytics: ssrAnalytics });
    }

    return window["analytics"] ? (window["analytics"] as AnalyticsJS) : null;
  }, [ssrAnalytics]);

  const canTrack = analytics && !isImpersonating;

  if (analytics && user) {
    identify(analytics, user);
  }

  const trackPage: AnalyticsJS["page"] = React.useCallback(
    (name?: string, properties?: Object) => {
      const info = {
        name,
        properties,
      };

      if (canTrack && !deepEqual(previousPage, info)) {
        analytics.page(name, properties);
        setPreviousPage(info);
      }
    },
    [analytics, isImpersonating, previousPage],
  );

  const trackEvent: AnalyticsJS["track"] = React.useCallback(
    (...args) => {
      if (canTrack) {
        analytics.track(...args);

        const [eventName, params] = args;
        sendUnknownGoogleAnalyticsEvent(eventName, params);
      }
    },
    [analytics, isImpersonating],
  );

  const trackDevice: AnalyticsJS["device"] = React.useCallback(
    (...args) => {
      if (canTrack && analytics.device) {
        analytics.device(...args);
      }
    },
    [analytics, isImpersonating],
  );

  return [trackEvent, trackPage, trackDevice] as const;
}
