import React, { useContext, useEffect } from "react";
import { GroupSort, GroupStatus, NavigationItem } from "../../../constants";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_GROUPS_ARCHIVED_ROUTE,
} from "../../routes";
import { CreateGroupAction } from "../../../components/groups/CreateGroupAction";
import { GroupList } from "../../../components/groups/GroupList";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { useQuery } from "@tanstack/react-query";
import GroupsService from "../../../services/GroupsService";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { BasicSkeletonCard } from "../../../components/loading/BasicSkeletonCard";
import {
  useCoachTabsContext,
  CoachGroupsCount,
} from "../../../contexts/CoachTabsContext";
import InlineCounter from "../../../components/loading/InlineCounter";

export const groupsNavigation = ({
  active,
  archived,
}: CoachGroupsCount): NavigationItem[] => [
  {
    name: (
      <InlineCounter
        count={active}
        label="Active"
        loading={!active && active !== 0}
      />
    ),
    link: COACH_GROUPS_ACTIVE_ROUTE,
  },

  {
    name: (
      <InlineCounter
        count={archived}
        label="Archived"
        loading={!archived && archived !== 0}
      />
    ),
    link: COACH_GROUPS_ARCHIVED_ROUTE,
  },
];

const getStatusFromPath = (path: string) => {
  switch (path) {
    case COACH_GROUPS_ACTIVE_ROUTE:
      return GroupStatus.ACTIVE;
    case COACH_GROUPS_ARCHIVED_ROUTE:
      return GroupStatus.ARCHIVED;

    default:
      return GroupStatus.ACTIVE;
  }
};

export const COACH_GROUPS_LIST_QUERY_KEY = "coach-groups";
export const COACH_GROUPS_COUNT_QUERY_KEY = "coach-groups-count";
const pageSize = 5;

export function CoachGroupsListRoute() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const sortKey = searchParams.get("sort");
  const page = (searchParams.get("page") as unknown as number) ?? 1;
  const orderBy = sortKey ?? (GroupSort.NAME_ASC as GroupSort);
  const status = getStatusFromPath(location.pathname);

  const { setGroupsCount } = useCoachTabsContext();
  const { data, isLoading } = useQuery({
    queryKey: [COACH_GROUPS_LIST_QUERY_KEY, { page, status, orderBy }],
    queryFn: () =>
      GroupsService.getGroups(status, orderBy as GroupSort, page, pageSize),
  });

  const { data: count } = useQuery({
    queryKey: [COACH_GROUPS_COUNT_QUERY_KEY],
    queryFn: () => GroupsService.getGroupsCount(),
  });

  React.useEffect(() => {
    count && setGroupsCount(count);
  }, [count]);

  return (
    <>
      {!isLoading && (
        <>
          <GroupList groups={data} />
          <TrackInfoTool
            trackInfo={{
              name: "Coach - Groups",
            }}
          />
        </>
      )}
      {isLoading && (
        <PageSkeleton fullWidth ItemComponent={BasicSkeletonCard} />
      )}
    </>
  );
}
