import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FieldLabel } from "./FieldLabel";
import { FieldError } from "./FieldError";

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    padding: 0,
    marginBottom: theme.spacing(3),
  },

  inputs: {},
}));

export interface FieldsGroupProps {
  className?: string;
  children: React.ReactNode;
  label: string;
  error?: string;
  hideErrorIcon?: boolean;
  errorClassName?: string;
  labelClassName?: string;
}

export function FieldsGroup(props: FieldsGroupProps) {
  const {
    className,
    label,
    error,
    children,
    hideErrorIcon,
    errorClassName,
    labelClassName,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box component="fieldset" className={clsx(s.root, className)} {...other}>
      <FieldLabel className={labelClassName}>{label}</FieldLabel>
      <Box className={s.inputs}>{children}</Box>
      {Boolean(error) && (
        <FieldError hideIcon={hideErrorIcon} className={errorClassName}>
          {error}
        </FieldError>
      )}
    </Box>
  );
}
