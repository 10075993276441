import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Popover,
  Box,
  SvgIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ScheduledComponent } from "@growth-machine-llc/stridist-api-client";
import { useActivitiesPopover } from "./hooks/useActivitiesPopover";
import ComponentActivitiesTable from "./ComponentActivitiesTable";
import { DatabaseIcon, FlagTriangleRight } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    cursor: "pointer",
    padding: theme.spacing(1),
  },
  popoverContent: {
    padding: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  title: {
    fontWeight: 500,
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  counts: {
    fontSize: "0.875rem",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  activityCountMismatch: {
    color: theme.palette.error.dark,
    fontWeight: 600,
  },
  activityCount: {
    fontWeight: 600,
  },
  icon: {
    width: 16,
    height: 16,
  },
}));

interface ComponentCardProps {
  component: ScheduledComponent;
}

const ComponentCard: React.FC<ComponentCardProps> = ({ component }) => {
  const classes = useStyles();
  const { bind, handleMouseEnter, handleMouseLeave } = useActivitiesPopover(
    `component-popover-${component.componentId}`,
  );

  const isCountMismatch =
    component.actualActivityCount !== component.expectedActivityCount;

  return (
    <>
      <Card
        className={classes.card}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CardContent style={{ padding: 0 }}>
          <Box className={classes.header}>
            {component.isStartingWeek && (
              <SvgIcon
                component={DatabaseIcon}
                className={classes.icon}
                style={{ color: "#1976d2" }}
              />
            )}
            <Typography className={classes.title}>
              {component.title} - {component.componentId}
            </Typography>
            {component.isOverridingComponent && (
              <SvgIcon
                component={FlagTriangleRight}
                className={classes.icon}
                style={{ color: "#f57c00" }}
              />
            )}
          </Box>

          <Box className={classes.counts}>
            <Typography>
              <strong
                className={
                  isCountMismatch
                    ? classes.activityCountMismatch
                    : classes.activityCount
                }
              >
                {component.actualActivityCount}
              </strong>{" "}
              / <strong>{component.expectedActivityCount}</strong>
            </Typography>
            <Typography variant="caption" color="textSecondary">
              activities
            </Typography>
          </Box>
        </CardContent>
      </Card>
      {component.clients?.length > 0 && (
        <Popover {...bind}>
          <Box className={classes.popoverContent}>
            <Typography variant="subtitle2" gutterBottom>
              Activities for {component.title}
            </Typography>
            {component.clients && component.clients.length ? (
              <ComponentActivitiesTable activities={component.clients} />
            ) : (
              <Typography variant="body2">No activities available.</Typography>
            )}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default ComponentCard;
