import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Grid2,
  GridSize,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { EnrollmentStatus } from "../../constants";
import { ClientUpcomingProgramCard } from "../card/ClientUpcomingProgramCard";
import { ClientPastProgramCard } from "../card/ClientPastProgramCard";
import { ClientProgramProgressCard } from "../card/ClientProgramProgressCard";
import { useLocation } from "react-router-dom";
import {
  CLIENT_PROGRAMS_ACTIVE_ROUTE,
  CLIENT_PROGRAMS_PAST_ROUTE,
  CLIENT_PROGRAMS_UPCOMING_ROUTE,
} from "../../routes/routes";

import { ClientEnrollmentDto } from "@growth-machine-llc/stridist-api-client";
import { BasicSkeletonCard } from "../loading/BasicSkeletonCard";
import { PastProgramSkeletonCard } from "../loading/PastProgramSkeletonCard";
import { UpcomingProgramSkeletonCard } from "../loading/UpcomingProgramSkeletonCard";
import { ActiveProgramSkeletonCard } from "../loading/ActiveProgramSkeletonCard";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: theme.breakpoints.values.slg,
  },
  panel: {
    background: "transparent !important",
    boxShadow: "none !important",
    border: "none !important",
  },
  panelSummary: {
    "& .MuiExpansionPanelSummary-content": {
      alignItems: "center",
      padding: theme.spacing(2, 0),
    },
  },
  panelTitle: {
    color: theme.palette.common.black,
    fontSize: 20,
    fontWeight: "bold",
  },
  expand: {
    fontSize: 32,
    color: theme.palette.secondary.main,
    transform: "rotate(-90deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
    "&$open": {
      transform: "rotate(0)",
    },
  },
  open: {},
}));

export interface ProgramsProps extends Omit<ContainerProps, "children"> {
  enrollments: ClientEnrollmentDto[];
  isFetchingNextPage: boolean;
  isLoading: boolean;
  observeRef: (node: HTMLDivElement) => void;
}

const getStatusByLocation = (path: string) => {
  switch (path) {
    case CLIENT_PROGRAMS_ACTIVE_ROUTE:
      return EnrollmentStatus.ACTIVE;
    case CLIENT_PROGRAMS_UPCOMING_ROUTE:
      return EnrollmentStatus.UPCOMING;
    case CLIENT_PROGRAMS_PAST_ROUTE:
      return EnrollmentStatus.PAST;

    default:
      break;
  }
};

export function Programs(props: ProgramsProps) {
  const {
    className,
    enrollments,
    observeRef,
    isFetchingNextPage,
    isLoading,
    ...other
  } = props;
  const s = useStyles();
  const location = useLocation();
  const status = getStatusByLocation(location.pathname);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [xs, sm, md, EnrollmentCard, SkeletonComponent]: [
    GridSize,
    GridSize,
    GridSize,
    React.FC<any>,
    React.FC<any>,
  ] = React.useMemo(() => {
    switch (status) {
      case EnrollmentStatus.UPCOMING:
        return [
          12,
          6,
          4,
          ClientUpcomingProgramCard,
          UpcomingProgramSkeletonCard,
        ];
      case EnrollmentStatus.PAST:
        return [12, 6, 4, ClientPastProgramCard, PastProgramSkeletonCard];
      default:
        return [
          12,
          12,
          12,
          ClientProgramProgressCard,
          ActiveProgramSkeletonCard,
        ];
    }
  }, [status]);

  const skeletonCols = isMd ? 3 : 2;
  const isClientProgressCard = EnrollmentCard === ClientProgramProgressCard;
  const initialSkeletons = isClientProgressCard
    ? 3
    : skeletonCols * skeletonCols;
  const nextPageSkeletons = isClientProgressCard ? 1 : skeletonCols;

  const renderGridItem = (content, key = null, ref = null) => (
    <Grid2
      key={key}
      ref={ref}
      size={{
        xs: xs,
        sm: sm,
        md: md,
      }}
    >
      {content}
    </Grid2>
  );

  const renderSkeletons = (count) =>
    Array.from(new Array(count)).map((_, index) =>
      renderGridItem(<SkeletonComponent />, index),
    );

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <Grid2 container spacing={3} sx={{ mb: 4 }}>
        {isLoading
          ? renderSkeletons(initialSkeletons)
          : enrollments.flatMap((enrollment, index) =>
              renderGridItem(
                <EnrollmentCard enrollment={enrollment} />,
                enrollment?.id || index,
                index === enrollments.length - 1 ? observeRef : null,
              ),
            )}
        {isFetchingNextPage && renderSkeletons(nextPageSkeletons)}
      </Grid2>
    </Container>
  );
}
