import React from "react";
import { Add } from "@mui/icons-material";
import { ButtonProps } from "@mui/material";

import { HeaderButton } from "../../button/HeaderButton";

export const CreateProgramFolderButton: React.FC<ButtonProps> = (props) => {
  const { children, ...restProps } = props;
  return (
    <HeaderButton startIcon={<Add />} size="small" {...restProps}>
      Create a folder
      {children}
    </HeaderButton>
  );
};
