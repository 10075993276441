import {
  CurriculumDto,
  WeekDto,
} from "@growth-machine-llc/stridist-api-client";
import { NormalizedCurriculum } from "./curriculum/curriculum-slice";
import {
  CurriculumComponent,
  CurriculumProgram,
  NormalizedCurriculumComponent,
  NormalizedCurriculumProgram,
  NormalizedCurriculumWeek,
} from "./types";

export function normalizeCurriculum(
  curriculumData: CurriculumDto,
): NormalizedCurriculum {
  const sortedWeeks = curriculumData.weeks
    .sort((a, b) => a.weekNumber - b.weekNumber)
    .map((week) => week.id);
  const program: NormalizedCurriculumProgram = {
    ...curriculumData,
    weeks: sortedWeeks || [],
  };

  const weeks: Record<number, NormalizedCurriculumWeek> = {};
  const components: Record<number, NormalizedCurriculumComponent> = {};

  curriculumData.weeks?.forEach((week) => {
    weeks[week.id!] = {
      ...week,
      components: week.components?.map((component) => component.id!) || [],
    };

    week.components?.forEach((component) => {
      components[component.id!] = {
        ...component,
        overrides: component.overrides.map((override) => override.id),
      };
    });
  });

  return { program, weeks, components };
}

export function denormalizeCurriculum(
  curriculum: NormalizedCurriculum,
): CurriculumProgram {
  const weeks = curriculum.program.weeks.map((weekId) => {
    const week = curriculum.weeks[weekId];
    const components =
      week.components?.map((componentId) => {
        const component = curriculum.components[componentId];
        return component;
      }) || [];

    return {
      ...week,
      components,
    };
  });

  return {
    ...curriculum.program,
    weeks,
  };
}

export function normalizeCurriculumWeek(weekData: WeekDto): {
  components: Record<number, NormalizedCurriculumComponent>;
  normalizedWeek: NormalizedCurriculumWeek;
} {
  const components: Record<number, NormalizedCurriculumComponent> = {};
  const normalizedWeek: NormalizedCurriculumWeek = {
    ...weekData,
    components: weekData.components?.map((component) => component.id!) || [],
  };

  weekData.components?.forEach((component) => {
    components[component.id!] = {
      ...component,
      overrides: component.overrides.map((override) => override.id),
    };
  });

  return { normalizedWeek, components };
}
