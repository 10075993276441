import { useState, useRef, useEffect } from "react";

export const useActivitiesPopover = (popoverId: string) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = (event?: React.MouseEvent<HTMLElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setAnchorEl(event?.currentTarget || anchorEl);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setAnchorEl(null);
    }, 200);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const open = Boolean(anchorEl);

  const bind = {
    id: open ? popoverId : undefined,
    open,
    anchorEl,
    onClose: () => {
      setAnchorEl(null);
    },
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    PaperProps: {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    },
  };

  return {
    open,
    bind,
    handleMouseEnter,
    handleMouseLeave,
  };
};
