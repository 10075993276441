import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Link,
  TextField,
  Typography,
  ButtonGroup,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { motion, AnimatePresence } from "framer-motion";

import { AppLogo } from "../app/AppLogo";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { AuthButton } from "./AuthButton";
import { initStripe, stripeAction } from "../../stripe";
import {
  getSignupConfigByPlan,
  SignupStep,
  UserLocation,
  UserRole,
} from "../../constants";
import { colorSystem } from "../../theme";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import {
  CoachSignupField,
  CoachSignupSchema,
  stepFields,
  verificationCodeLength,
} from "../../utils/coachSignupSchema";
import { urlConstants } from "../../constants/urlConstants";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { PlainTextButton } from "../button/PlainTextButton";
import { ArrowLeftIcon } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import dayjs from "dayjs";
import LoadingStepper from "../loading/LoadingStepper";
import SignUpService from "../../services/SignUpService";
import TokenService from "../../utils/token";
import { useCreateSignupCheckoutSession } from "../../hooks/useCreateSignupCheckoutSession";
import SignupSessionTokenService from "../../utils/signupToken";
import { useTimer } from "../../hooks/useTimer";
import PasswordField from "../fields/PasswordField";
import { useNavigate } from "react-router-dom";
import { ENTER_INVITE_CODE_ROUTE } from "../../routes/routes";
import { BaseDialog } from "../dialog/BaseDialog";
import EmaiLVerificationCodeField from "../fields/EmailVerificationCodeField";
import {
  sendCoachSignupCompletedEvent,
  sendCoachSignupFailedEvent,
  sendCoachSignupStepCompletedEvent,
} from "../../utils/googleAnalytics";
import { isUserUsingUSLocale } from "../fields/BodyWeightHeightField";
import { UnitSystem } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  logo: {
    marginBottom: theme.spacing(8),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  subtitle: {
    marginTop: theme.spacing(1),
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "20px",
  },

  dialogTitle: {
    fontSize: 20,
    fontWeight: 600,
  },

  googleLogo: {
    marginRight: theme.spacing(2),
  },

  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  input: {
    marginTop: theme.spacing(1),

    "& .MuiFormHelperText-root": {
      fontWeight: 500,
      lineHeight: 0.9,
      marginTop: theme.spacing(0.8),
    },
  },

  advertisement: {
    marginBottom: theme.spacing(6),
  },

  buttonGroup: {
    marginTop: theme.spacing(2),
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& .MuiButton-root": {
      color: theme.palette.text.primary,
      fontWeight: "bold",

      border: "none",
      backgroundColor: theme.palette.common.white,
      boxShadow: `inset 0 0 0 1px ${theme.palette.grey[300]}`,
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },

      "&$selected": {
        backgroundColor: theme.palette.grey[100],
      },
    },
  },

  selected: {},

  passwordInput: {
    paddingRight: 0,
  },
  note: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: "bold",
    marginTop: theme.spacing(3),
  },
  noteText: {
    fontWeight: "normal",
  },
  select: {
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),
    marginTop: theme.spacing(3),
    color: "black",

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
    "$view [role=button] &": {
      display: "none !important",
    },
  },
  sort: {},
  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),
    "$sort [role=button] &": {
      color: "black",
    },
  },
  noVisible: {
    display: "none",
  },
  flexInput: {
    display: "flex",
  },
  separator: {
    margin: theme.spacing(0, 0.5),
  },

  info: {
    borderRadius: 0,
    boxShadow: "none",
    borderWidth: 1,
    borderColor: colorSystem.gray4,
    borderStyle: "solid",
    marginTop: theme.spacing(1),
    padding: theme.spacing(2.3, 1.75),
  },
  infoTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "20px",
    color: colorSystem.black,
    paddingBottom: 10,
  },
  infoText: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: colorSystem.gray3,
    paddingTop: 4,
  },
}));

const formVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

const fadeInOut = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

// Add container variants for orchestrating children
const containerVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.05,
      staggerDirection: -1,
      duration: 0.2,
    },
  },
};

export interface SignUpStartFormProps extends BoxProps {
  register: UseFormRegister<CoachSignupSchema>;
  getValues: UseFormGetValues<CoachSignupSchema>;
  trigger: UseFormTrigger<CoachSignupSchema>;
  errors: FieldErrors<CoachSignupSchema>;
  setError: UseFormSetError<CoachSignupSchema>;
  setValue: UseFormSetValue<CoachSignupSchema>;
  watch: UseFormWatch<CoachSignupSchema>;
  reset: UseFormReset<CoachSignupSchema>;
  clearErrors: UseFormClearErrors<CoachSignupSchema>;
  control: Control<CoachSignupSchema>;
  dirtyFields: string[];
  step: SignupStep;
  setStep: (step: SignupStep) => void;
  trialLength?: number;
  plan: string;
  referral?: string;
}
const SignUpStartForm = (props: SignUpStartFormProps) => {
  const {
    referral,
    className,
    step,
    setStep,
    register,
    getValues,
    trigger,
    reset,
    watch,
    errors,
    setError,
    clearErrors,
    setValue,
    control,
    dirtyFields,
    plan,
    trialLength = 14,
    ...other
  } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const isMobile = useMediaMobile();

  const location = isUserUsingUSLocale()
    ? UserLocation.NEW_YORK
    : UserLocation.LONDON;

  const units = isUserUsingUSLocale() ? UnitSystem.US : UnitSystem.METRIC;

  const [userRole, setUserRole] = React.useState(UserRole.COACH);
  const [roleDialogOpen, setRoleDialogOpen] = React.useState(false);

  const handleError = useGenericErrorHandler({
    disableBadRequestSnackbar: true,
    setFieldError: (field, message) => {
      if (field === "email" || field === "code") {
        setError(field, { message, type: "manual" });
      }
    },
  });

  const { showToastAlert } = useToastAlert();

  React.useLayoutEffect(() => {
    const currentSession = SignupSessionTokenService.getSessionToken();

    if (!currentSession) return;

    const isValid =
      SignupSessionTokenService.isSessionTokenValid(currentSession);
    const { email } =
      SignupSessionTokenService.decodeSessionToken(currentSession);

    if (isValid) {
      setValue("email", email);
      setStep(SignupStep.CONFIRMATION);
    } else {
      SignupSessionTokenService.removeSessionToken();
      showToastAlert("info", {
        message: "Your signup session has expired. Please try again.",
      });
    }
  }, []);

  const { seconds, isDone: canSend, setCount } = useTimer();

  const [signupComplete, setSignupComplete] = React.useState(false);

  const {
    createSignupCheckoutSession,
    creatingSignupCheckoutSession,
    sessionId,
  } = useCreateSignupCheckoutSession({ autoRedirect: false });

  const handleSignupError = React.useCallback(
    (error: Error, variables: any, context: any) => {
      setStep(SignupStep.CONFIRMATION);
      setSignupComplete(false);
      handleError(error, variables, context);
      sendCoachSignupFailedEvent(error?.message);
    },
    [handleError, setStep],
  );

  const { mutate: completeSignup, isPending: completingSignup } = useMutation({
    mutationKey: ["complete-coach-sign-up"],
    mutationFn: SignUpService.completeCoachSignUp,
    // NOTE: Callbacks are defined on a mutation hook instead of mutationFn,
    // since component that triggers the mutation may be unmounted while executing the mutation.
    onSuccess: ({ accessToken }) => {
      TokenService.updateLocalAccessToken(accessToken);
      SignupSessionTokenService.removeSessionToken();
      sendCoachSignupCompletedEvent();
      createSignupCheckoutSession({
        onError: handleSignupError,
      });
    },
    onError: handleSignupError,
    retry: 0,
  });

  const { mutate: sentVerificationCode, isPending: sendingVerificationCode } =
    useMutation({
      mutationKey: ["pre-coach-sign-up"],
      mutationFn: SignUpService.preCoachSignUp,
      onError: handleError,
      retry: 0,
    });

  const { mutate: verifyCode, isPending: verifyingCode } = useMutation({
    mutationFn: SignUpService.verifyCoachSignUpEmail,
    onError: handleError,
  });

  const handleSendVerificationCode = React.useCallback(() => {
    const email = getValues("email");
    sentVerificationCode(
      { email },
      {
        onSuccess: ({ nextAttemptInSeconds }) => {
          setStep(SignupStep.EMAIL_VERIFICATION);
          setCount(nextAttemptInSeconds);
        },
      },
    );
  }, [getValues, sentVerificationCode]);

  const handleVerifyCode = React.useCallback(
    async (pasteCode?: string) => {
      const email = getValues("email");
      const code = pasteCode ?? getValues("code");

      verifyCode(
        { email, code },
        {
          onSuccess: ({ sessionToken }) => {
            SignupSessionTokenService.updateSessionToken(sessionToken);
            setStep(SignupStep.CONFIRMATION);
            setCount(undefined);
          },
        },
      );
    },
    [getValues, verifyCode, setStep, setCount],
  );

  const handleStripeAction = React.useCallback(
    (id) => {
      stripeAction(id, showToastAlert);
    },
    [showToastAlert],
  );

  React.useEffect(() => {
    if (sessionId && signupComplete) {
      handleStripeAction(sessionId);
    }
  }, [sessionId, signupComplete]);

  const handleCompleteSignup = React.useCallback(async () => {
    const { email, fullName, password } = getValues();

    const sessionToken = SignupSessionTokenService.getSessionToken();

    // Set loading step first
    setStep(SignupStep.PROCESSING);

    completeSignup({
      email,
      displayName: fullName,
      password,
      timezone: dayjs.tz.guess(),
      referral,
      sessionToken: sessionToken,
      location: location,
      units: units,
    });
  }, [getValues, completeSignup, plan, stripeAction, showToastAlert, referral]);

  const validateCurrentStep = React.useCallback(
    async (onValid: () => void) => {
      {
        if (await trigger(stepFields[step] as CoachSignupField[], {})) {
          onValid();
          sendCoachSignupStepCompletedEvent(step);
        }
      }
    },
    [setStep, step],
  );

  const handleStepSubmit = React.useCallback(() => {
    validateCurrentStep(() => {
      if (step === SignupStep.EMAIL) {
        handleSendVerificationCode();
      } else if (step === SignupStep.EMAIL_VERIFICATION) {
        handleVerifyCode();
      } else if (step === SignupStep.CONFIRMATION) {
        handleCompleteSignup();
      }
    });
  }, [setStep, step, validateCurrentStep]);

  const getStepLabel = React.useCallback(() => {
    if (step === SignupStep.EMAIL) {
      return "Continue with email";
    }
    if (step === SignupStep.EMAIL_VERIFICATION) {
      return "Verify email";
    }
    if (step === SignupStep.CONFIRMATION) {
      return "Complete";
    }

    return "Continue";
  }, [step]);

  const handleChangeEmail = React.useCallback(() => {
    reset();
    setStep(SignupStep.EMAIL);

    SignupSessionTokenService.removeSessionToken();
  }, [setValue, setStep]);

  const handleUserRoleChange = React.useCallback((role: UserRole) => {
    if (role === UserRole.CLIENT) {
      setRoleDialogOpen(true);
    } else {
      setUserRole(role);
    }
  }, []);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLFormElement>) => {
      if (event.key === "Enter") {
        handleStepSubmit();
      }
    },
    [handleStepSubmit],
  );

  const onFieldTouched = (field: CoachSignupField) => {
    if (errors?.[field]?.type === "manual") {
      clearErrors(field);
    }
  };

  const emailRef = React.useRef<HTMLInputElement>(null);
  const codeRef = React.useRef<HTMLInputElement>(null);
  const fullNameRef = React.useRef<HTMLInputElement>(null);

  // Focus first field when step changes
  React.useEffect(() => {
    const focusMap = {
      [SignupStep.EMAIL]: emailRef,
      [SignupStep.EMAIL_VERIFICATION]: codeRef,
      [SignupStep.CONFIRMATION]: fullNameRef,
    };

    const ref = focusMap[step];
    setTimeout(() => {
      ref?.current?.focus();
    }, 300); // Wait for animation to complete
  }, [step]);

  const { title, subtitle, formLabel, logo, rolesSwitch } =
    getSignupConfigByPlan(plan, { trialLength, brandName });

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <BaseDialog
        maxWidth="xs"
        open={roleDialogOpen}
        onClose={() => setRoleDialogOpen(false)}
        title="We're excited to have you on board!"
        subtitle={
          <>
            {" "}
            Have a code?{" "}
            <Link href={ENTER_INVITE_CODE_ROUTE}>Enter it here</Link>
          </>
        }
        classNameTitle={s.dialogTitle}
        showClose={false}
      >
        <Box>
          <Typography
            sx={{ fontWeight: 500, width: "100%", textAlign: "center" }}
          >
            Ask your coach to send you an invite to join his workspace on{" "}
            <b>Stridist</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 2,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: colorSystem.black,
                color: colorSystem.black,
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: colorSystem.black,
                  color: colorSystem.blackOpacity,
                },
              }}
              onClick={() => setRoleDialogOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </BaseDialog>

      {logo && <AppLogo className={s.logo} main full width={160} height={37} />}

      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <AnimatePresence mode="wait" initial={false}>
          <motion.div initial="initial" animate="animate" variants={fadeInOut}>
            {step !== SignupStep.PROCESSING && (
              <Box className={s.advertisement}>
                <>
                  {title && (
                    <Typography className={s.title} variant="h1">
                      {title}
                    </Typography>
                  )}
                  {subtitle && (
                    <Typography className={s.subtitle} variant="h5">
                      Last Few Spaces Remaining
                    </Typography>
                  )}
                </>

                {rolesSwitch && step === SignupStep.EMAIL && (
                  <ButtonGroup
                    fullWidth
                    variant="outlined"
                    aria-label="user-role"
                    className={s.buttonGroup}
                  >
                    <Button
                      className={userRole === UserRole.COACH ? s.selected : ""}
                      onClick={() => handleUserRoleChange(UserRole.COACH)}
                    >
                      I'm a Coach
                    </Button>
                    <Button
                      className={userRole === UserRole.CLIENT ? s.selected : ""}
                      onClick={() => handleUserRoleChange(UserRole.CLIENT)}
                    >
                      I'm a Client
                    </Button>
                  </ButtonGroup>
                )}
              </Box>
            )}
          </motion.div>
        </AnimatePresence>

        <AnimatePresence mode="wait" initial={false}>
          {step === SignupStep.EMAIL ? (
            <motion.div variants={fadeInOut}>
              {formLabel && (
                <Typography className={s.subtitle} variant="h5">
                  {formLabel}
                </Typography>
              )}
            </motion.div>
          ) : step !== SignupStep.PROCESSING ? (
            <motion.div variants={formVariants}>
              <PlainTextButton
                onClick={handleChangeEmail}
                startIcon={<ArrowLeftIcon size={16} />}
                sx={{ marginY: 2 }}
              >
                Change email
              </PlainTextButton>
            </motion.div>
          ) : null}
        </AnimatePresence>
        <form onSubmit={(e) => e.preventDefault()} onKeyDown={handleKeyDown}>
          <Box sx={{ position: "relative" }}>
            <AnimatePresence mode="wait" initial={false}>
              <motion.div variants={fadeInOut}>
                {step !== SignupStep.PROCESSING && (
                  <TextField
                    inputRef={emailRef}
                    fullWidth
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Enter your email..."
                    {...register("email", {
                      onChange: (e) => onFieldTouched("email"),
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message ?? " "}
                    variant="filled"
                    disabled={step > SignupStep.EMAIL}
                    className={s.input}
                  />
                )}
              </motion.div>
            </AnimatePresence>

            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                key={step}
                variants={formVariants}
                style={{ position: "relative" }}
              >
                {step === SignupStep.EMAIL_VERIFICATION && (
                  <EmaiLVerificationCodeField
                    inputRef={codeRef}
                    fullWidth
                    onValidCodePaste={handleVerifyCode}
                    onResendCode={handleSendVerificationCode}
                    nextResendInSeconds={seconds}
                    register={register("code", {
                      onChange: (e) => onFieldTouched("code"),
                    })}
                    error={Boolean(errors.code)}
                    helperText={errors.code?.message ?? " "}
                    variant="filled"
                    className={s.input}
                  />
                )}

                {step === SignupStep.CONFIRMATION && (
                  <Box>
                    <TextField
                      inputRef={fullNameRef}
                      fullWidth
                      name="fullName"
                      type="text"
                      label="Full Name"
                      placeholder="Enter your full name..."
                      {...register("fullName")}
                      error={Boolean(errors.fullName)}
                      helperText={errors.fullName?.message ?? " "}
                      variant="filled"
                      className={s.input}
                    />

                    <PasswordField
                      fullWidth
                      name="password"
                      label="Password"
                      placeholder="Enter a secure password..."
                      register={register("password")}
                      value={watch("password")}
                      error={Boolean(errors.password)}
                      helperText={errors.password?.message ?? " "}
                      variant="filled"
                      className={s.input}
                    />
                  </Box>
                )}

                {step === SignupStep.PROCESSING && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 4,
                      py: 4,
                    }}
                  >
                    <LoadingStepper
                      title="Setting up your account"
                      steps={[
                        "Verifying your information...",
                        "Preparing your workspace...",
                        "Activating trial period...",
                      ]}
                      interval={1.2}
                      onComplete={() => setSignupComplete(true)}
                    />
                  </Box>
                )}
              </motion.div>
            </AnimatePresence>

            <AnimatePresence mode="wait" initial={false}>
              {step !== SignupStep.PROCESSING && (
                <motion.div variants={fadeInOut}>
                  <AuthButton
                    disabled={
                      verifyingCode ||
                      completingSignup ||
                      sendingVerificationCode ||
                      creatingSignupCheckoutSession ||
                      userRole !== UserRole.COACH
                    }
                    onClick={handleStepSubmit}
                  >
                    {getStepLabel()}
                  </AuthButton>
                </motion.div>
              )}
            </AnimatePresence>
          </Box>
        </form>
      </motion.div>
    </Box>
  );
};

export default React.memo(SignUpStartForm);
