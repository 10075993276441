import React, { useEffect } from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { Box, Fade, IconButton, Typography } from "@mui/material";
import { EmbedElementType, MoreMenu } from "./MoreMenu";
import ReactPlayer from "react-player";
import { MonitorPlay } from "lucide-react";
import { getCloudFlareSources, isVideoStream } from "../../../utils/component";
import { EmbedFileDialog } from "../modals/EmbedFileDialog";
import { useEditorCoverImage } from "../../../hooks/useEditorCoverImage";
import { useReadOnly } from "../hooks";
import { MediaElementMenuProps } from "../menus/MediaElementMenu";
import { useToastAlert } from "../../app/ToastAlert/ToastAlertProvider";

export const VIDEO = "video";

interface IMediaEmbedProps {
  handleChange: (
    url: string,
    name: string,
    size: number,
    mimeType: string,
  ) => void;
  handleRemoveNode: () => void;
  url: string;
  name: string;
  size: number | null;
  mimeType: string;
  element: any;
}

export const Video = ({
  handleChange,
  handleRemoveNode,
  url,
  name,
  size,
  mimeType,
  element,
}: IMediaEmbedProps) => {
  const empty = !url;

  const calculateSource = (url): string => {
    return !url
      ? null
      : isVideoStream(url)
        ? getCloudFlareSources(url)[0].src
        : url;
  };

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [source, setSource] = React.useState<string>(calculateSource(url));
  const [videoSourceIndex, setVideoSourceIndex] = React.useState<number>(0);
  const readOnly = useReadOnly();
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  const { showToastAlert } = useToastAlert();

  const handleClick = () => {
    if (!readOnly && !openDialog && empty) {
      setOpenDialog(true);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setSource(calculateSource(url));
  }, [url]);

  const onCoverChange = useEditorCoverImage();
  const handleCoverChange: MediaElementMenuProps["onCoverChange"] =
    React.useCallback(
      (event, value) => {
        onCoverChange(value);
        showToastAlert("success", {
          message: "Video set as cover.",
        });
      },
      [onCoverChange],
    );

  if (empty && readOnly) return <></>;

  return (
    <Box
      display={"flex"}
      gap={1.5}
      sx={{
        border: (theme) =>
          empty ? `${theme.shape.border.xs}px solid` : "none",
        borderRadius: 1,
        padding: empty ? 2.5 : 0,
        pt: 1.4,
        pr: 0,
        display: "flex",
        backgroundColor: (theme) =>
          empty ? theme.palette.selected.light : null,
        borderColor: (theme) => theme.palette.border.primary,
      }}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline",
          height: "100%",
          width: "100%",
        }}
        contentEditable={false}
      >
        {empty ? (
          <Box sx={{ pt: 1, display: "flex", gap: 1 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.text.disabled, p: 0 }}
            >
              <MonitorPlay />
            </IconButton>
            <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>
              Embed a video from YouTube, Vimeo, etc...
            </Typography>
          </Box>
        ) : (
          <Box sx={{ width: "100%", aspectRatio: "1.8" }}>
            <Box sx={{ width: "100%", height: "100%", aspectRatio: "1.8" }}>
              <ReactPlayer
                width="100%"
                height="100%"
                controls
                url={source}
                onError={(error, data) => {
                  if (isVideoStream(url)) {
                    const sources = getCloudFlareSources(url);
                    if (videoSourceIndex < sources.length - 1) {
                      const newSourceIndex = videoSourceIndex + 1;
                      setSource(sources[newSourceIndex].src);
                      setVideoSourceIndex(newSourceIndex);
                    }
                  }
                }}
              />
            </Box>
          </Box>
        )}
        {!readOnly && (
          <>
            <Fade in={isHovered}>
              <Box>
                <MoreMenu
                  element={element}
                  handleRemoveNode={handleRemoveNode}
                  empty={empty}
                  setOpenDialog={setOpenDialog}
                  handleCoverChange={handleCoverChange}
                  type={EmbedElementType.VIDEO}
                ></MoreMenu>
              </Box>
            </Fade>
          </>
        )}

        <EmbedFileDialog
          url={url}
          size={size}
          name={name}
          mimeType={mimeType}
          open={openDialog}
          type={EmbedElementType.VIDEO}
          title="Upload a Video"
          accept="video/mp4,video/x-m4v,video/*"
          onSubmit={handleChange}
          onClose={handleClose}
        ></EmbedFileDialog>
      </Box>
    </Box>
  );
};

export const VideoElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element } = props;
    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    if (!path) return;

    const handleChange = React.useCallback(
      (url: string, name: string, size: number, mimeType: string) => {
        setNodes(editor, { ...{}, url, name, size, mimeType }, { at: path });
      },
      [],
    );

    const handleRemoveNode = () => {
      removeNodes(editor, { at: path });
    };

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <Box sx={{ visibility: "hidden", height: 0 }} contentEditable={false}>
          {children}
        </Box>
        <Video
          element={element}
          size={element.size as number}
          name={element.name as string}
          url={element.url as string}
          mimeType={element.mimeType as string}
          handleChange={handleChange}
          handleRemoveNode={handleRemoveNode}
        />
      </PlateElement>
    );
  },
);
