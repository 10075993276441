import { useAdminCreateExerciseLibraryAsset } from "./useAdminCreateExerciseLibraryAsset";
import { useAdminUpdateExerciseLibraryAsset } from "./useAdminUpdateExerciseLibraryAsset";
import { WorkoutExercise } from "../../workout/types";
import {
  BodypartType,
  MovementPatternType,
} from "@growth-machine-llc/stridist-api-client";

export function useAdminSaveExerciseLibraryAsset(createNewAsset: boolean) {
  const { mutate: createAsset } = useAdminCreateExerciseLibraryAsset();
  const { mutate: updateAsset } = useAdminUpdateExerciseLibraryAsset();

  return ({
    id,
    exercise,
    bodypart,
    movementPattern,
  }: {
    id?: number;
    exercise: WorkoutExercise;
    bodypart: BodypartType;
    movementPattern: MovementPatternType;
  }) => {
    const content = JSON.stringify(exercise);
    return createNewAsset
      ? createAsset({
          name: exercise.title,
          content,
          bodypart,
          movementPattern,
        })
      : updateAsset({
          id,
          name: exercise.title,
          content,
          bodypart,
          movementPattern,
        });
  };
}
