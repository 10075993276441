import React from "react";
import { Card, CardContent, Grid2, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 1, 2, 3),
    [theme.breakpoints.up("sm")]: {
      boxShadow: "none",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colorSystem.gray7,
      borderRadius: 4,
    },
  },
  item: {
    padding: `${theme.spacing(0)} !important`,
  },
  title: {
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: "17px",
    color: colorSystem.gray,
  },
  text: {
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: "29px",
    color: colorSystem.black,
    paddingTop: theme.spacing(0.4),
  },
}));

const ClientExerciseChooseHistoryItem = (props: any) => {
  const { title, text } = props;
  const s = useStyles();
  return (
    <Grid2
      size={{
        xs: 6,
        sm: 6,
        md: 2,
      }}
    >
      <Card className={s.container}>
        <CardContent className={s.item}>
          <Typography className={s.title}>{title}</Typography>
          <Typography className={s.text}>{text}</Typography>
        </CardContent>
      </Card>
    </Grid2>
  );
};

export default ClientExerciseChooseHistoryItem;
