import clsx from "clsx";
import React from "react";
import {
  Drawer,
  DrawerProps,
  IconButton,
  Box,
  ClickAwayListener,
  Portal,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CloseIcon } from "../../icons/Close.svg";
import { ReactComponent as RemoveIcon } from "../../icons/Bin.svg";

import { AdminWorkoutEditExercise } from "./AdminWorkoutEditExercise";
import { AdminExerciseAssetDto } from "@growth-machine-llc/stridist-api-client";
import { useAdminDeleteExerciseAsset } from "./hooks/useAdminDeleteExerciseAsset";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    zIndex: "99999 !important" as any,
  },

  paper: {
    padding: theme.spacing(3, 4),
    width: "100%",

    "& h2": {
      fontSize: 32,
      margin: theme.spacing(2, 0),
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  header: {
    margin: theme.spacing(0, 0, 3, 0),
  },

  buttons: {
    display: "flex",
    alignItems: "center",

    "& > :last-child": {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
    },
  },

  title: {
    marginBottom: theme.spacing(2),
    cursor: "pointer",
  },

  titleText: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
  },

  headerButton: {
    color: theme.palette.primary.main,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 500,

    "& svg": {
      width: 24,
      height: 24,
    },
  },

  backButton: {
    marginLeft: theme.spacing(-2),
  },

  closeButton: {
    marginRight: theme.spacing(-2),
  },

  addButton: {
    borderRadius: 4,
    borderStyle: "solid",
    borderColor: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: "bold",
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

export interface WorkoutDrawerProps extends DrawerProps {
  exerciseAsset?: AdminExerciseAssetDto;
  onUpdate?: (workout: AdminExerciseAssetDto) => void;
  onCancel?: () => void;
  onRemove?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    exercise?: AdminExerciseAssetDto,
  ) => void;
}

export function AdminWorkoutDrawer(props: WorkoutDrawerProps) {
  const { className, exerciseAsset, onUpdate, onCancel, onRemove, ...other } =
    props;
  const { onClose } = props;
  const s = useStyles();

  /* TODO_EXERCISE_ASSET_ID: Uncomment this code and apply admin deleting after assetIds migration
  const { mutate: deleteAsset } = useAdminDeleteExerciseAsset();
  {
     const handleDeleteClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      deleteAsset(exerciseAsset.id);
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [exerciseAsset?.id, onClose, deleteAsset],
  );
  } */

  const handleClose = React.useCallback(
    (event) => {
      onClose(event, "backdropClick");
    },
    [onClose],
  );

  const handleClickAway = React.useCallback(
    (event) => {
      const el = event.target as HTMLDivElement;
      if (el && "closest" in el) {
        const withinMainRoot = el.closest("#root");

        if (withinMainRoot) {
          handleClose(event);
        }
      }
    },
    [handleClose],
  );

  return (
    <Portal>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Drawer
          className={clsx(s.root, className)}
          classes={{ paper: s.paper }}
          anchor="right"
          variant="persistent"
          {...other}
        >
          <Box className={s.buttons}>
            <Box>
              {/*  {exerciseAsset && (
              <IconButton
                className={s.headerButton}
                onClick={handleDeleteClick}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
            )} */}
              <IconButton
                className={clsx(s.headerButton, s.closeButton)}
                onClick={handleClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <AdminWorkoutEditExercise
            key={exerciseAsset?.id}
            exerciseAsset={exerciseAsset}
            onClose={handleClose}
            createNewAsset={!exerciseAsset}
          />
        </Drawer>
      </ClickAwayListener>
    </Portal>
  );
}
