import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import {
  Box,
  BoxProps,
  Card,
  Portal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ComponentType } from "../../constants";

import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
  Area,
  ReferenceLine,
  Label,
  ComposedChart,
} from "recharts";

import {
  NameType,
  ValueType,
  // eslint-disable-next-line import/no-unresolved
} from "recharts/types/component/DefaultTooltipContent";

// eslint-disable-next-line import/no-unresolved
import "chartjs-adapter-date-fns";
import { ClientEnrollmentDto } from "@growth-machine-llc/stridist-api-client";
import { colorSystem } from "../../theme";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    userSelect: "none",
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      minWidth: 300,
      marginTop: 0,
    },
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
    lineHeight: "29px",
    marginBottom: theme.spacing(4),
  },

  legend: {
    display: "flex",
    marginBottom: theme.spacing(3),
  },

  type: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,

    "&:not(:last-child)": {
      marginRight: theme.spacing(2.25),
    },

    "&::before": {
      content: "''",
      display: "inline-block",
      width: 8,
      height: 8,
      borderRadius: "50%",
      marginRight: theme.spacing(0.75),
    },

    "&$habit::before": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&$checkin::before": {
      backgroundColor: theme.palette.primary.main,
    },
    "&$lesson::before": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const items = [
  { type: ComponentType.HABIT, label: "Habits", dataKey: "habits" },
  { type: ComponentType.CHECKIN, label: "Check-ins", dataKey: "checkins" },
  { type: ComponentType.LESSON, label: "Lessons", dataKey: "lessons" },
  { type: ComponentType.WORKOUT, label: "Workouts", dataKey: "workouts" },
];

const CHART_LINE_DATA_PADDING = 2;

const ChartDot = (color: string) => {
  return ({ cx, cy }: { cx: number; cy: number }) => {
    return (
      <circle
        cx={cx}
        cy={cy}
        r={6}
        fill={color}
        stroke={colorSystem.white}
        strokeWidth={3}
      />
    );
  };
};

export interface ClientProgramActivityProps extends BoxProps {
  enrollment: ClientEnrollmentDto;
}

export function ClientProgramActivity(props: ClientProgramActivityProps) {
  const { className, enrollment, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  const {
    lessonsByWeeks,
    habitsByWeeks,
    checkinsByWeeks,
    workoutsByWeeks,
    currentDay,
    totalDays,
  } = enrollment;

  const [rootRefRect, setRootRefRect] = React.useState<DOMRect | null>(null);

  const rootRef = React.useRef(null);

  document.addEventListener("scroll", () => {
    if (rootRef.current) {
      setRootRefRect(rootRef.current.getBoundingClientRect());
    }
  });

  document.addEventListener("resize", () => {
    if (rootRef.current) {
      setRootRefRect(rootRef.current.getBoundingClientRect());
    }
  });

  useEffect(() => {
    if (rootRef.current) {
      setRootRefRect(rootRef.current.getBoundingClientRect());
    }
  }, []);

  const dataset = React.useMemo(
    () => [
      ...Array.from({ length: CHART_LINE_DATA_PADDING }, (_, index) => ({
        date: null,
        index: -(index + 1),
        lessons: 0,
        lessonsTotal: 0,
        habits: 0,
        habitsTotal: 0,
        checkins: 0,
        checkinsTotal: 0,
        workouts: 0,
        workoutsTotal: 0,
        sum: 0,
        sumTotal: 0,
      })),
      ...lessonsByWeeks.map((week, index) => ({
        date: week.startWeekDate.format("YYYY-MM-DD"),
        index: index,
        lessons: week.completed,
        lessonsTotal: week.total,
        habits: habitsByWeeks[index].completed,
        habitsTotal: habitsByWeeks[index].total,
        checkins: checkinsByWeeks[index].completed,
        checkinsTotal: checkinsByWeeks[index].total,
        workouts: workoutsByWeeks[index].completed,
        workoutsTotal: workoutsByWeeks[index].total,
        sum:
          habitsByWeeks[index].completed +
          checkinsByWeeks[index].completed +
          week.completed +
          workoutsByWeeks[index].completed,
        sumTotal:
          habitsByWeeks[index].total +
          checkinsByWeeks[index].total +
          week.total +
          workoutsByWeeks[index].total,
      })),
      ...Array.from({ length: CHART_LINE_DATA_PADDING }, (_, index) => ({
        date: null,
        index: lessonsByWeeks.length + index,
        lessons: 0,
        lessonsTotal: 0,
        habits: 0,
        habitsTotal: 0,
        checkins: 0,
        checkinsTotal: 0,
        workouts: 0,
        workoutsTotal: 0,
        sum: 0,
        sumTotal: 0,
      })),
    ],
    [enrollment],
  );

  const currentWeekNumber = React.useMemo(() => {
    return Math.ceil(currentDay / 7) + CHART_LINE_DATA_PADDING;
  }, [currentDay]);

  const chartMax = React.useMemo(() => {
    const max = Math.max(...dataset.map((week) => week.sumTotal));

    return Math.ceil(max);
  }, [dataset]);

  const tickFormatter = (value) => {
    return dayjs(value).format("MMM D");
  };

  const ChartTooltip = React.useCallback(
    ({
      active,
      payload,
      coordinate: { x, y },
    }: TooltipProps<ValueType, NameType>) => {
      const boundingBoxHeight = 120;
      const boundingBoxWidth = 240;

      if (!rootRefRect) return null;
      const { width, height, top, bottom, left, right } = rootRefRect;

      const y_pos =
        (y > height / 2 ||
          top + y + boundingBoxHeight * 2 >= window.innerHeight) && // constrain tooltip to screen bottom
        top + y - boundingBoxHeight > 0 // constrain tooltip to screen top
          ? top + y - boundingBoxHeight
          : top + y + boundingBoxHeight;

      if (active && payload && payload.length && payload[0].payload.date) {
        return (
          <Card
            sx={{
              width: "max-content",
              padding: 2,
              position: "fixed",
              zIndex: 1000,
              transition: "all 0.3s ease",
              top: y_pos,
              left: x > width / 2 ? left + x - boundingBoxWidth : left + x + 20,
            }}
          >
            <Typography lineHeight={0.8} variant="subtitle2">
              Completed activities of{" "}
            </Typography>
            <Typography paddingBottom={1} variant="subtitle1">
              {dayjs(payload[0].payload.date).format("MMM DD")} -{" "}
              {dayjs(payload[0].payload.date)
                .add(6, "days")
                .format("MMM DD, YYYY")}
            </Typography>
            {items.map(({ type, label, dataKey }) => (
              <Typography
                key={type}
                color={theme.palette.text.secondary}
                variant="body2"
              >
                {label} &bull;{" "}
                <Typography
                  color={
                    payload[0].payload[dataKey] !== 0
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary
                  }
                  fontWeight={
                    payload[0].payload[`${dataKey}Total`] === 0
                      ? "normal"
                      : "bold"
                  }
                  display="inline"
                  variant="body2"
                >
                  {payload[0].payload[dataKey]}
                </Typography>
                {" of "}
                <Typography
                  fontWeight={
                    payload[0].payload[`${dataKey}Total`] === 0
                      ? "normal"
                      : "bold"
                  }
                  display="inline"
                  variant="body2"
                >
                  {payload[0].payload[`${dataKey}Total`]}
                </Typography>
              </Typography>
            ))}
          </Card>
        );
      }

      return null;
    },
    [rootRefRect],
  );

  return (
    <Box className={clsx(s.root, className)} ref={rootRef} {...other}>
      <Typography className={s.title} variant="h6">
        Program activity
      </Typography>
      <ResponsiveContainer
        width="100%"
        style={{ overflow: "visible" }}
        height={200}
      >
        <ComposedChart
          margin={{ left: 20, bottom: 10, top: 25, right: 20 }}
          data={dataset}
        >
          <XAxis
            dataKey="date"
            interval={0}
            stroke="#bbb"
            strokeWidth={2}
            ticks={[
              dataset[CHART_LINE_DATA_PADDING].date,
              dataset[dataset.length - CHART_LINE_DATA_PADDING - 1].date,
            ]}
            tickFormatter={tickFormatter}
          />
          <YAxis domain={[0, chartMax]} hide />
          <Tooltip
            content={ChartTooltip}
            cursor={false}
            allowEscapeViewBox={{ x: true, y: true }}
          />
          <Area
            type="monotone"
            dataKey="sumTotal"
            fill="#eee"
            stroke="#ddd"
            strokeWidth={1}
            dot={false}
            activeDot={ChartDot("#ddd")}
          />
          <Area
            type="monotone"
            dataKey="sum"
            stackId="a"
            fill={theme.palette.primary.main}
            stroke={theme.palette.primary.main}
            strokeWidth={2}
            opacity={0.8}
            activeDot={ChartDot(theme.palette.primary.main)}
          />
          {currentDay && (
            <ReferenceLine
              x={currentWeekNumber - 1}
              stroke="#bbb"
              strokeWidth={3}
              strokeLinecap="round"
              strokeDasharray="3 6"
              label={
                <Label
                  position="top"
                  value="NOW"
                  stroke="#bbb"
                  fill="#bbb"
                  offset={10}
                />
              }
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}
