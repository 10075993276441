import React, { useMemo } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import clsx from "clsx";
import CoachClientsArrowsSort from "./CoachClientsArrowsSort";
import { ClientSort } from "../../constants";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import CoachClientsSortHeaderTagMenu from "./CoachClientsSortHeaderTagMenu";
import { TagDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 14,
    display: "flex",
    justifyContent: "flex-start",

    "&.active": {
      color: theme.palette.primary.main,
    },
  },
  buttonsContainer: {
    paddingLeft: theme.spacing(3),
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      paddingLeft: 0,
    },
  },
  nameButton: {
    width: "28%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  tagButton: {
    width: "17%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    "&.active": {
      color: theme.palette.primary.main,

      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
  },
  programButton: {
    width: "55%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

type CoachClientsSortHeaderProps = {
  sortKey: string;
  handleChangeSortNameProgram: (value: boolean) => void;
  tagList: TagDto[];
  onChangeFilterTagId: (tagId: number) => void;
  filterTag?: number;
  disabled: boolean;
};

const CoachClientsSortHeader = (props: CoachClientsSortHeaderProps) => {
  const {
    sortKey,
    handleChangeSortNameProgram,
    tagList,
    onChangeFilterTagId,
    filterTag,
    disabled,
  } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const xsDown = useMediaQuery(breakpoints.down("xs"));
  const [open, setOpen] = React.useState(false);

  const activeFilters = useMemo(
    () => ({
      tag: filterTag !== null,
      name: [ClientSort.NAME_ASC, ClientSort.NAME_DESC].includes(
        sortKey as ClientSort,
      ),
      program: [
        ClientSort.PROGRAM_END_ASC,
        ClientSort.PROGRAM_END_DESC,
      ].includes(sortKey as ClientSort),
    }),
    [sortKey, filterTag],
  );

  return (
    <Box className={s.buttonsContainer}>
      <Box className={s.nameButton}>
        <Button
          className={clsx(s.button, {
            active: activeFilters.name,
          })}
          children="Name"
          endIcon={
            <CoachClientsArrowsSort
              activeUp={sortKey === ClientSort.NAME_ASC}
              activeDown={sortKey === ClientSort.NAME_DESC}
            />
          }
          onClick={() => handleChangeSortNameProgram(true)}
        />
      </Box>
      {!disabled && (
        <>
          <Box className={clsx(s.tagButton, { active: activeFilters.tag })}>
            <CoachClientsSortHeaderTagMenu
              open={open}
              setOpen={setOpen}
              tagList={tagList}
              onChangeFilterTagId={onChangeFilterTagId}
              filterTag={filterTag}
              active={activeFilters.tag}
            />
          </Box>

          <Box className={s.programButton}>
            <Button
              className={clsx(s.button, {
                active: activeFilters.program,
              })}
              children={!xsDown ? "Due date programs" : "programs"}
              endIcon={
                <CoachClientsArrowsSort
                  activeUp={sortKey === ClientSort.PROGRAM_END_ASC}
                  activeDown={sortKey === ClientSort.PROGRAM_END_DESC}
                />
              }
              onClick={() => handleChangeSortNameProgram(false)}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CoachClientsSortHeader;
