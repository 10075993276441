import { programsClient } from "../api/ApiClients";
import {
  ILockProgramCommand,
  IUnlockProgramCommand,
  IUpdateProgramCommand,
  LockProgramCommand,
  ProgramSettingsDto,
  UnlockProgramCommand,
  UpdateProgramCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class ProgramsService extends ServiceBase {
  async getProgramSettings(slug: string): Promise<ProgramSettingsDto> {
    return programsClient.getProgramSettings(slug);
  }

  async updateProgram(data: IUpdateProgramCommand) {
    const command = new UpdateProgramCommand(data);
    return programsClient.updateProgram(command);
  }

  async getCurriculum(slug: string) {
    return programsClient.getProgramCurriculum(slug);
  }

  async lockProgram(data: ILockProgramCommand) {
    const command = new LockProgramCommand(data);
    return programsClient.lockProgram(command);
  }

  async unlockProgram(data: IUnlockProgramCommand) {
    const command = new UnlockProgramCommand(data);
    return programsClient.unlockProgram(command);
  }
}

export default new ProgramsService();
