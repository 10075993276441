import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { CurriculumComponent } from "../../redux/types";
import {
  ComponentRepeat,
  ComponentStatus,
  ProgramStartDateView,
} from "../../constants";
import {
  DAY_SEARCH_PARAM,
  WEEK_SEARCH_PARAM,
} from "../../contexts/CurriculumComponentDialogContext";
import { BaseDialog } from "../dialog/BaseDialog";
import RepeatsMap from "../curriculum-sidebar/tabs/RepeatsMap";
import {
  ProgramStartDateViewDetails,
  StartDateFilter,
} from "../program/filters/StartDateFilter";
import { useCurriculumSelector } from "../../redux/hooks";
import {
  selectOverridesSlugsByComponentId,
  selectProgramSlug,
} from "../../redux/curriculum/selectors/curriculum";
import dayjs from "dayjs";
import LoadingButton from "../button/LoadingButton";
import { FlagTriangleRight } from "lucide-react";
import { ComponentType } from "@growth-machine-llc/stridist-api-client";

export const isOverrrideCreationEnabled = (component: CurriculumComponent) => {
  return (
    // not message
    component.type !== ComponentType.MESSAGE &&
    // not archived
    component.status !== ComponentStatus.ARCHIVED &&
    // and repeated on weeks
    (component.repeat !== ComponentRepeat.NONE ||
      // or on days within a single week
      JSON.parse(component.days).filter((value) => value).length > 1)
  );
};

export const overrideCreationDisabledReason = (
  component: CurriculumComponent,
) => {
  if (component.type === ComponentType.MESSAGE) {
    return "Cannot create custom scheduled messages";
  }
  if (component.status === ComponentStatus.ARCHIVED) {
    return `Restore ${component.type.toString().toLowerCase()} to unlock this action`;
  }
  if (
    component.repeat === ComponentRepeat.NONE &&
    JSON.parse(component.days).filter((value) => value).length <= 1
  ) {
    return `Schedule ${component.type.toString().toLowerCase()} to repeat for multiple days to create custom days for it`;
  }
  return "";
};

type UrlClickedDay = null | { week: number; day: number };

export function useUrlClickedDay(): UrlClickedDay {
  const [searchParams] = useSearchParams();

  const WEEK_NUMBER_PARAM = searchParams.get(WEEK_SEARCH_PARAM);
  const DAY_PARAM = searchParams.get(DAY_SEARCH_PARAM);

  let initialSelectedDay: UrlClickedDay = null;
  if (DAY_PARAM && WEEK_NUMBER_PARAM) {
    initialSelectedDay = {
      day: parseInt(DAY_PARAM),
      week: parseInt(WEEK_NUMBER_PARAM),
    };
  }
  return initialSelectedDay;
}

export interface OverrideCreationDialogProps {
  component: Omit<CurriculumComponent, "overrides">;
  open: boolean;
  onConfirmPublishOverride: (selected: { week: number; day: number }) => void;
  onClose: () => void;
  initialSelectedDay: { week: number; day: number } | null;
  onOverrideReady?: (overrideSlug: string) => void;
}

const OverrideCreationDialog = ({
  component,
  open,
  onConfirmPublishOverride,
  onClose,
  initialSelectedDay,
  onOverrideReady,
}: OverrideCreationDialogProps) => {
  const theme = useTheme();

  const overrides = useCurriculumSelector(
    selectOverridesSlugsByComponentId(component.id),
  );
  const programSlug = useCurriculumSelector(selectProgramSlug);

  const [loading, setLoading] = React.useState(false);
  const initialOverrides = React.useRef(overrides);
  const [selectedDay, setSelectedDay] = React.useState<{
    day: number;
    week: number;
  } | null>(initialSelectedDay);

  const [viewStartDate, setViewStartDate] =
    React.useState<ProgramStartDateViewDetails>({
      view: ProgramStartDateView.ANY,
    });

  React.useEffect(() => {
    // track initial overrides on first render
    initialOverrides.current = overrides;
  }, []);

  React.useEffect(() => {
    if (
      initialOverrides.current &&
      overrides.length > initialOverrides.current.length
    ) {
      const oldOverrides = new Set(initialOverrides.current);
      const addedOverride = overrides.find((o) => !oldOverrides.has(o));
      setLoading(false);
      if (onOverrideReady) {
        onOverrideReady(addedOverride);
      }
      initialOverrides.current = null;
      onClose();
    }
  }, [overrides, initialOverrides.current]);

  const handleSelectionClick = (week: number, day: number) => {
    setSelectedDay({ week, day });
  };

  const handleConfirm = () => {
    setLoading(true);
    onConfirmPublishOverride(selectedDay);
  };

  const handleOnClose = () => {
    if (!loading) {
      onClose();
    }
  };

  const confirmButtonLabel =
    `Create custom ${component.type.toLowerCase()}` +
    (selectedDay ? ` on week ${selectedDay.week}, day ${selectedDay.day}` : "");

  return (
    <>
      <BaseDialog
        title={`Choose ${component.type.toLowerCase()} day to customize`}
        onClose={handleOnClose}
        showClose={!loading}
        open={open}
        sx={{ pb: 0 }}
        containerStyle={{ paddingBottom: 0, maxHeight: 700 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 6,
            pb: 2,
            pr: { xs: 0, sm: 3, md: 4 },
            flexWrap: {
              xs: "wrap",
              sm: "nowrap",
            },
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: {
                xs: "static",
                sm: "sticky",
              },
              top: {
                xs: "unset",
                sm: theme.spacing(3),
              },
              height: "fit-content",
            }}
          >
            <Typography variant="body1" sx={{ mb: 3 }} fontWeight={600}>
              Click to select program day to change
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Change program preview start date
            </Typography>
            <StartDateFilter
              value={viewStartDate}
              onChange={(value) => setViewStartDate(value)}
              fullWidth={true}
              slug={programSlug}
            />
          </Box>
          <RepeatsMap
            component={component}
            onSelectionClick={handleSelectionClick}
            initialSelectedDay={selectedDay}
            startDate={viewStartDate.date ? dayjs(viewStartDate.date) : null}
            size={28}
            labelProps={{ weeks: "head", days: "head" }}
            enabledIndices="self"
            selectionProps={{
              children: (
                <FlagTriangleRight
                  size={16}
                  color={theme.palette.common.black}
                  style={{
                    ...(viewStartDate.date && {
                      position: "absolute",
                      bottom: 14,
                      left: 14,
                    }),
                  }}
                />
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            paddingBottom: 4,
            background: theme.palette.common.white,
          }}
        >
          <LoadingButton
            fullWidth
            variant="contained"
            children={confirmButtonLabel}
            onClick={handleConfirm}
            disabled={selectedDay === null}
            loading={loading}
            sx={{
              height: theme.spacing(7),
              fontSize: 16,
              fontWeight: "bold",
              lineHeight: "20px",
              ":disabled": {
                background: theme.palette.grey[300],
              },
            }}
          />
        </Box>
      </BaseDialog>
    </>
  );
};

export default OverrideCreationDialog;
