import { useMutation } from "@tanstack/react-query";
import InvitesService from "../../../services/InvitesService";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { useToastAlert } from "../../app/ToastAlert/ToastAlertProvider";

export default function useResendInviteMutation() {
  const dialogDataQueryKey = COACH_CLIENTS_LIST_QUERY_KEY;
  const { showToastAlert } = useToastAlert();
  return useMutation({
    mutationFn: InvitesService.resendInvite,
    onSuccess: (response) => {
      const timeLabel = response.nextAttemptIn === 1 ? "second" : "seconds";
      if (response.sent === false) {
        showToastAlert("warning", { 
            message: `Resend invite failed. Please try again in ${response.nextAttemptIn} ${timeLabel}.`
        });
      }
      else{
        showToastAlert("success", {
          message: "Invitation has been resent",
        });
      }
    },
  });
}
