import Url from "url";

import React from "react";
import { Accept, DropzoneOptions, useDropzone } from "react-dropzone";
import { History } from "history";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Toast, ShowOptions } from "@capacitor/toast";
import { App } from "@capacitor/app";
import {
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import { SplashScreen } from "@capacitor/splash-screen";
import { Haptics } from "@capacitor/haptics";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { useAnalytics } from "../hooks/useAnalytics";
import { useCurrentUserExternalId } from "../hooks/useCurrentUser";
import {
  mobilePromptPhotoAccess,
  SOMETHING_WENT_WRONG,
  AppleAuthOptions,
} from "../constants";

import { isMobileApp, iOSMobileApp, androidMobileApp } from "./mobile";
import { AppleAuthSuccessResponse } from "../components/auth/AppleButton";
import { isLocalEnvironment, isStagingEnvironment } from "../utils";
import { useCurrentBrand } from "../hooks/useCurrentWorkspace";

if (isMobileApp) {
  SplashScreen.hide();
}

/* sentry on all devices */
const environment = isLocalEnvironment()
  ? "development"
  : isStagingEnvironment()
    ? "staging"
    : "production";

import("@sentry/react")
  .then((Sentry) => {
    const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

    Sentry.init({
      dsn: SENTRY_DSN,
      environment,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [new Sentry.Replay()],
    });
    window["Sentry"] = Sentry;
  })
  .catch((error) => {
    console.error("failed to override sentry", error);
  });

export const nativeGoogleAuthSignIn = () =>
  new Promise((resolve, reject) => {
    if (iOSMobileApp) {
      GoogleAuth.initialize();
    }
    GoogleAuth.signIn().then((user) =>
      user
        ? resolve({
            getAuthResponse: () => ({
              access_token: user.authentication.accessToken,
              id_token: user.authentication.idToken,
            }),

            getBasicProfile: () => ({
              getId: () => user.id,
              getName: () => user.name,
              getGivenName: () => user.givenName,
              getFamilyName: () => user.familyName,
              getImageUrl: () => user.imageUrl,
              getEmail: () => user.email,
            }),
          })
        : reject(new Error(SOMETHING_WENT_WRONG)),
    );
  });

export const nativeAppleAuthSignIn = () =>
  new Promise((resolve, reject) => {
    SignInWithApple.authorize(AppleAuthOptions)
      .then(
        ({ response: { familyName, givenName, email, identityToken } }) =>
          ({
            detail: {
              authorization: {
                id_token: identityToken,
              },
              user: {
                email: email,
                name: {
                  firstName: givenName,
                  lastName: familyName,
                },
              },
            },
          }) as AppleAuthSuccessResponse,
      )
      .then(resolve)
      .catch(reject);
  });

export const showToast = (options: ShowOptions) => Toast.show(options);

export const toast = (message: string) =>
  showToast({
    text: message,
    position: "top",
  });

export const deviceSupports = {
  emojiKeyboard: isMobileApp,
  arbitraryFileUpload: true,

  googleAuthSignInEnabled: true,
  googleAuthClientSignUpEnabled: true,
  googleAuthCoachSignUpEnabled: true,

  appleAuthSignInEnabled: !androidMobileApp,
  appleAuthClientSignUpEnabled: !androidMobileApp,
  appleAuthCoachSignUpEnabled: false,
} as const;

const takePicture = async (
  onDrop: DropzoneOptions["onDrop"],
  brandName: string,
) => {
  try {
    const image = await Camera.getPhoto({
      allowEditing: !androidMobileApp,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
      promptLabelHeader: mobilePromptPhotoAccess(brandName),
    });

    if (image) {
      const { dataUrl, format } = image;
      const blob = await (await fetch(dataUrl)).blob();

      const file = new File([blob], "fileName.jpg", {
        type: "image/" + format,
        lastModified: Date.now(),
      });

      onDrop([file], [], null as any);
    }
  } catch (e) {}
};

const acceptsOnlyImages = (accept: Accept) =>
  Object.keys(accept).every((key) => key.includes("image"));

export const useNativeDropzone: typeof useDropzone = (options) => {
  const state = useDropzone(options);
  const { brandName } = useCurrentBrand();

  const onClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      takePicture(options.onDrop, brandName);
    },
    [options.onDrop],
  );

  const getRootProps = React.useCallback(() => ({ onClick }), [onClick]);

  if (isMobileApp && acceptsOnlyImages(options.accept)) {
    Object.assign(state, { getRootProps });
  }

  return state;
};

// TODO drop obsolete History for deep links
export const setupDeepLinks = (history: History) => {
  if (isMobileApp) {
    App.addListener("appUrlOpen", ({ url }) =>
      history.push(Url.parse(url).path),
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (action) => {
        const url = action?.notification?.data?.url;

        if (url) {
          history.push(Url.parse(url).path);
        }
      },
    );
  }
};

export const vibrate = () => Haptics.vibrate();

type RequestPushNotificationCallback = (error: unknown, token?: string) => void;

export const requestPushNotifications = async (
  callback?: RequestPushNotificationCallback,
) => {
  if (!isMobileApp) {
    return;
  }

  try {
    PushNotifications.addListener("registration", (token) => {
      // eslint-disable-next-line
      console.log("token");
      // eslint-disable-next-line
      console.log(token);

      if (callback) {
        callback(null, token.value);
      } else {
        console.info(
          "Push registration success! Device Token: " + JSON.stringify(token),
        );
      }
    });

    PushNotifications.addListener("registrationError", (error) => {
      console.error(error);
      if (callback) {
        callback(error);
      } else {
        console.error("Error registering for Push: " + error);
      }
    });
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        // eslint-disable-next-line
        console.log(notification);
      },
    );

    const request = await PushNotifications.requestPermissions();

    if (request.receive) {
      await PushNotifications.register();
    } else {
      console.error("APNS request rejected");
    }
  } catch (e) {
    console.error(e);
  }
};

export const requestPushNotificationsOnce = (
  userId: string,
  callback?: RequestPushNotificationCallback,
) => {
  const key = "APNS_REQUESTED";
  const previousUserId = localStorage.getItem(key) || "";

  if (previousUserId !== userId) {
    if (previousUserId) {
      // TODO: Probably remove previous device id
    }

    localStorage.setItem(key, userId.toString());
    requestPushNotifications(callback);
  }
};

export function useRequestPushNotifications() {
  const [, , trackDevice] = useAnalytics();
  const externalUserId = useCurrentUserExternalId();

  React.useEffect(
    () =>
      requestPushNotificationsOnce(externalUserId, (error, token) => {
        if (error) {
          console.error(error);
        } else if (trackDevice) {
          trackDevice(externalUserId, token);
        }
      }),
    [trackDevice, externalUserId],
  );
}
