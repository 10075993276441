import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { ProgramDefaultView } from "../../constants";

interface IWeeksGapProps {
  startWeekNumber: number;
  endWeekNumber: number;
  view: ProgramDefaultView;
}

const WeeksGap = (props: IWeeksGapProps) => {
  const { startWeekNumber, endWeekNumber, view } = props;
  const theme = useTheme();

  const IS_SHEETS = view === ProgramDefaultView.SPREADSHEET;

  return (
    <Box
      sx={{
        marginBlock: 4,
        height: 60,
        border: 1,
        borderRadius: 2,
        borderColor: theme.palette.divider,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        backgroundImage: `repeating-linear-gradient(
            45deg, transparent, 
            transparent 13px, 
            ${theme.palette.grey[100]} 13px, 
            ${theme.palette.grey[100]} 26px)`,
      }}
    >
      <Typography
        variant="subtitle2"
        color={theme.palette.grey[500]}
        fontWeight={"bold"}
        fontStyle={"italic"}
        sx={{
          ...(IS_SHEETS && {
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 0)",
          }),
        }}
      >
        {startWeekNumber === endWeekNumber ? (
          <>Week {startWeekNumber}</>
        ) : (
          <>
            Weeks {startWeekNumber} - {endWeekNumber}
          </>
        )}
      </Typography>
    </Box>
  );
};

export default WeeksGap;
