import clsx from "clsx";
import React, { useContext, useEffect, useRef } from "react";
import {
  AppBar,
  AppBarProps,
  Toolbar,
  IconButton,
  Tooltip,
  Typography,
  Box,
  BoxProps,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz, VisibilityOutlined } from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import {
  CoachComponentSavingState,
  ComponentStatus,
  ComponentType,
} from "../../constants";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { usePreview, PreviewType } from "../../hooks/usePreview";
import { EditorMenu } from "../../components/menu/EditorMenu";
import { ComponentLibraryButton } from "../../components/component-library/ComponentLibraryButton";
import { EditorMenuProps } from "../../components/menu/EditorMenu";
import { IMPERSONATION_BANNER_SPACING } from "../app/ImpersonationBanner";
import { PREVIEW_BAR_SPACING } from "../preview/PreviewBar";
import { ComponentPublishButton } from "../program/ComponentPublishButton";

import dayjs from "dayjs";
import ComponentBarHeightContext from "../../contexts/ComponentBarHeightContext";
import { CircleX, History, Undo2 } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { CurriculumComponent } from "../../redux/types";
import CoachComponentBarOverride from "./CoachComponentBarOverride";
import { colorSystem } from "../../theme";

export const COMPONENT_STATUS_ASSETS = {
  [ComponentStatus.DRAFT]: { title: "Draft", color: colorSystem.gray4 },
  [ComponentStatus.PUBLISHED]: { title: "Published", color: colorSystem.green },
  [ComponentStatus.ARCHIVED]: { title: "Archived", color: colorSystem.gray4 },
};

interface StyleArgs {
  impersonating: boolean;
  preview: PreviewType;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: ({ impersonating, preview }: StyleArgs) =>
      impersonating || preview
        ? theme.spacing(
            impersonating ? IMPERSONATION_BANNER_SPACING : PREVIEW_BAR_SPACING,
          )
        : "initial",
    boxShadow: "none",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.quote,
  },

  header: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",

    "& > div": {
      overflow: "hidden",
    },
  },

  title: {
    fontWeight: 700,
    fontSize: 16,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  bar: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    backgroundColor: theme.palette.selected.light,
    zIndex: 1300,
    minHeight: theme.spacing(9.5),
    paddingLeft: theme.spacing(1.5),
    paddingBlock: theme.spacing(1.5),
  },

  closeButton: {
    color: theme.palette.text.primary,

    "&:hover": {
      color: theme.palette.text.primary,
    },

    padding: theme.spacing(1.5),
  },

  rightGroup: {
    "& > button:first-child": {
      marginRight: "auto",
    },

    width: "fit-content",
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "end",
    alignItems: "center",
    gap: theme.spacing(2),
    marginLeft: "auto",
  },

  buttonsGroup: {
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
    },
  },

  status: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: 14,
  },

  publishButton: {
    fontWeight: "bold",
    padding: theme.spacing(0.75, 4),

    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.75, 2.5),
    },
  },

  iconButton: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",

    backgroundColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    minHeight: 48,
    minWidth: 48,

    "& svg": {
      color: theme.palette.common.black,
    },

    "&.Mui-disabled": {
      backgroundColor: theme.palette.quote,
      opacity: 0.4,
    },
  },

  mobileHeader: {
    top: "30px",
  },
}));

type GetMainStatusTextArgs = {
  status: ComponentStatus;
  dates: {
    publishedAt?: string;
    updatedAt?: string;
  };
  saving: boolean;
};

const getOptionalLabelToMainStatus = (
  args: GetMainStatusTextArgs,
  ignoreSavingForPublished: boolean,
) => {
  const { status, dates, saving } = args;

  const publishedOrEmpty = dates.publishedAt
    ? `• ${dayjs(dates.publishedAt).fromNow()}`
    : "";
  const updatedOrEmpty = dates.updatedAt
    ? `• ${dayjs(dates.updatedAt).fromNow()}`
    : "";

  // ignore saving status for published component
  if (ignoreSavingForPublished && status === ComponentStatus.PUBLISHED) {
    return publishedOrEmpty;
  }

  // show loading status instead of updated time if saving
  if (saving) {
    return "• Saving...";
  }

  return updatedOrEmpty;
};

const getMainStatusWithOptionalLabel = (
  args: GetMainStatusTextArgs,
  short: boolean,
  ignoreSavingForPublished: boolean,
) => {
  const { status, saving } = args;

  let statusTitle = COMPONENT_STATUS_ASSETS[status].title;

  if (saving && short && status !== ComponentStatus.PUBLISHED) {
    statusTitle = "Saving...";
  }

  return short
    ? statusTitle
    : `${statusTitle} ${getOptionalLabelToMainStatus(args, ignoreSavingForPublished)}`;
};

const getLabelToPendingChangesIndicator = (
  dates: {
    updatedAt?: string;
  },
  saving: boolean,
  short: boolean,
) => {
  // handle short status only case
  if (short) {
    if (saving) {
      return "working...";
    } else {
      return "Pending ";
    }
  }

  // handle full label case
  if (saving) {
    return "Pending changes • working...";
  }

  const updatedOrEmpty = dates.updatedAt
    ? `• ${dayjs(dates.updatedAt).fromNow()}`
    : "";

  return `Pending changes ${updatedOrEmpty}`;
};

interface DisabledActionWaitTooltipProps extends BoxProps {
  title: string;
  waiting: boolean;
  reasonForWaiting?: string;
}

const DisabledActionWaitTooltip = (props: DisabledActionWaitTooltipProps) => {
  const { title, waiting, reasonForWaiting, children, ...other } = props;
  return (
    <Tooltip
      arrow
      placement="bottom"
      title={
        waiting ? (
          <>
            Just a sec.
            <br />
            {reasonForWaiting}
          </>
        ) : (
          title
        )
      }
    >
      <Box {...other}>{children}</Box>
    </Tooltip>
  );
};

export interface CoachComponentBarProps
  extends Omit<AppBarProps, "component">,
    Pick<EditorMenuProps, "editor"> {
  componentData: Omit<CurriculumComponent, "overrides">;
  onSaveClick: () => void;
  onCloseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  savingState: CoachComponentSavingState;
  isEmpty: boolean;
  isDirty: boolean;
  repeatsAmount: number;
  onConfirmPublishOverride: (selected: { week: number; day: number }) => void;
  onOverrideReady: (overrideSlug: string) => void;
  lastPublishedContent: string;
  handleDiscardPendingChanges: (content: string) => void;
}

export const CoachComponentBar = (props: CoachComponentBarProps) => {
  const {
    className,
    componentData,
    onSaveClick,
    onCloseClick,
    savingState,
    isEmpty,
    isDirty,
    editor,
    repeatsAmount,
    onConfirmPublishOverride,
    onOverrideReady,
    handleDiscardPendingChanges,
    lastPublishedContent,
    ...other
  } = props;
  const [_, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const user = useCurrentUser();
  const [preview, setPreview] = usePreview();
  const s = useStyles({ impersonating: user?.isImpersonating, preview });
  const component = componentData;
  const [trackEvent] = useAnalytics();
  const iconsMenuState = usePopupState({
    variant: "popover",
    popupId: "editor-menu",
  });

  const handlePreviewClick = React.useCallback(() => {
    trackEvent("Coach - Preview Component");
    setSearchParams((searchParams) => {
      searchParams.set("preview", "true");
      return searchParams;
    });
    setPreview(PreviewType.MOBILE);
  }, [trackEvent, setPreview]);

  const IS_SAVING_OR_DIRTY =
    savingState === CoachComponentSavingState.SAVING || isDirty;

  const isPublished = component.status === ComponentStatus.PUBLISHED;

  const isPendingChangesShowed = component.draftExist && isPublished;

  const heightContext = useContext(ComponentBarHeightContext);

  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!elementRef.current) return;

    const resizeObserver = new ResizeObserver((callback) => {
      heightContext.setComponentBarHeight(
        elementRef.current?.clientHeight ?? 0,
      );
    });

    resizeObserver.observe(elementRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <AppBar ref={elementRef} className={clsx(s.root, className)} {...other}>
      <Box>
        <Toolbar className={s.bar}>
          <Box className={s.header}>
            <DisabledActionWaitTooltip
              title=""
              waiting={IS_SAVING_OR_DIRTY}
              reasonForWaiting="Saving..."
              sx={{ marginRight: 1 }}
            >
              <IconButton
                className={s.closeButton}
                onClick={onCloseClick}
                children={<CircleX size={30} />}
                size="large"
                disabled={IS_SAVING_OR_DIRTY}
              />
            </DisabledActionWaitTooltip>
            <Box>
              <Typography className={s.title}>
                {component.title || "New document"}
              </Typography>
              <Typography
                className={s.status}
                component="div"
                display={"flex"}
                alignItems={"center"}
                gap={0.5}
                minHeight={30}
              >
                <Box
                  width={6}
                  height={6}
                  borderRadius={"50%"}
                  sx={{
                    background: COMPONENT_STATUS_ASSETS[component.status].color,
                  }}
                />
                {getMainStatusWithOptionalLabel(
                  {
                    status: component.status,
                    dates: {
                      updatedAt: component.updatedAt?.toString(),
                      publishedAt: component.lastPublished?.toString(),
                    },
                    saving: IS_SAVING_OR_DIRTY,
                  },
                  !isMd,
                  isPendingChangesShowed,
                )}
                <Box
                  gap={0.5}
                  ml={2}
                  alignItems={"center"}
                  sx={{
                    display: isPendingChangesShowed ? "flex" : "none",
                  }}
                >
                  <Box
                    width={6}
                    height={6}
                    borderRadius={"50%"}
                    sx={{
                      background: (theme) =>
                        theme.palette.attentionPoint.warning,
                    }}
                  />
                  {getLabelToPendingChangesIndicator(
                    { updatedAt: component.updatedAt?.toString() },
                    IS_SAVING_OR_DIRTY,
                    !isMd,
                  )}
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleDiscardPendingChanges(lastPublishedContent)
                    }
                    sx={{ p: 0.5, color: theme.palette.grey[600] }}
                  >
                    <Undo2 size={22} />
                  </IconButton>
                </Box>
              </Typography>
            </Box>
          </Box>

          {!isSm && (
            <CoachComponentBarOverride
              component={component}
              repeatsAmount={repeatsAmount}
              onConfirmPublishOverride={onConfirmPublishOverride}
              onOverrideReady={onOverrideReady}
            />
          )}

          <div className={s.rightGroup}>
            {isSm && (
              <CoachComponentBarOverride
                component={component}
                repeatsAmount={repeatsAmount}
                onConfirmPublishOverride={onConfirmPublishOverride}
                onOverrideReady={onOverrideReady}
              />
            )}
            <Box className={s.buttonsGroup}>
              <ComponentPublishButton
                className={s.publishButton}
                componentData={component}
                onClick={onSaveClick}
                empty={isEmpty}
                dirty={isDirty}
              />

              <IconButton
                children={<MoreHoriz />}
                aria-haspopup="true"
                className={s.iconButton}
                {...bindTrigger(iconsMenuState)}
                size="large"
              />

              {iconsMenuState.open && (
                <EditorMenu
                  componentData={component}
                  isEmpty={isEmpty}
                  isDirty={isDirty}
                  editor={editor}
                  {...bindMenu(iconsMenuState)}
                />
              )}

              {component.type !== ComponentType.MESSAGE && (
                <>
                  <ComponentLibraryButton className={s.iconButton} />
                  <DisabledActionWaitTooltip
                    title="Preview"
                    waiting={IS_SAVING_OR_DIRTY}
                    reasonForWaiting="Saving..."
                  >
                    <IconButton
                      className={s.iconButton}
                      onClick={handlePreviewClick}
                      children={<VisibilityOutlined />}
                      size="large"
                      disabled={IS_SAVING_OR_DIRTY}
                    />
                  </DisabledActionWaitTooltip>
                </>
              )}
            </Box>
          </div>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
