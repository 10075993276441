import {
  ComponentRepeat,
  ComponentStatus,
  ComponentType,
} from "@growth-machine-llc/stridist-api-client";
import { generateId } from "../../../../../components/new-editor/utils/nodeUtil";
import { capitalize } from "@mui/material";
import { DEFAULT_COMPONENT_DAYS } from "../../../../../utils/component";
import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumComponent } from "../../../../types";
import { CurriculumState } from "../../../curriculum-slice";
import dayjs from "dayjs";

export const addComponentAction = {
  prepare(props: {
    weekId: number;
    componentType: ComponentType;
    initialComponentData?: CurriculumComponent;
  }) {
    const { weekId, componentType, initialComponentData } = props;

    const newComponentId = generateId();
    const newComponentTitle =
      "Untitled" + " " + capitalize(componentType.toLowerCase());

    // TODO_V2_CURRICULUM move to utils or similar
    const newComponent: CurriculumComponent = {
      id: newComponentId,
      type: componentType,
      status: ComponentStatus.DRAFT,
      previousStatus: null,
      weekId: weekId,
      locked: true,
      iconName: componentType,
      content: initialComponentData?.content ?? `[]`,
      title: initialComponentData?.title ?? newComponentTitle,
      slug: `${newComponentId}-tempslug`,
      days: initialComponentData?.days ?? DEFAULT_COMPONENT_DAYS,
      repeat: ComponentRepeat.NONE,
      duration: 1,
      reminderType: null,
      reminderTime: null,
      messageTime: null,
      messageContent: "[]",
      baseComponentId: null,
      overrides: [],
      updatedAt: dayjs(),
      draftExist: false,
    };

    if (componentType === ComponentType.MESSAGE) {
      newComponent.messageTime = "9:00 AM";
    }

    return {
      payload: {
        weekId,
        newComponent,
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      weekId: number;
      newComponent: CurriculumComponent;
    }>,
  ) {
    const { weekId, newComponent } = action.payload;
    if (!weekId || !newComponent) return;

    state.value.weeks[weekId].components.push(newComponent.id);
    state.value.weeks[weekId].positions.push(newComponent.id.toString());
    state.value.components[newComponent.id] = newComponent;
  },
};
