import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Grid2,
  Slide,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TrainingSummaryExercise } from "./TrainingSummaryExercise";
import { TrainingSummaryWellBegin } from "./TrainingSummaryWellBegin";
import { Node } from "slate";
import { ActivityBriefDto } from "@growth-machine-llc/stridist-api-client";
import TrainingShare from "./TrainingShare";
import { ComponentType, UserRole } from "../../constants";
import { ActivityBar } from "../activity/ActivityBar";
import ExitWorkoutButton from "../coach-programs/buttons/ExitWorkoutButton";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { CLIENT_PROGRAMS_ACTIVE_ROUTE } from "../../routes/routes";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => {
  return {
    root: {
      paddingTop: spacing(7),
      paddingBottom: spacing(2),
      maxWidth: breakpoints.values.slg,
    },
  };
});

export interface ActivityProps extends Omit<ContainerProps, "children"> {
  activity: ActivityBriefDto;
  clientUsername: string;
  coach?: boolean;
}

export function TrainingSummary(props: ActivityProps) {
  const { activity, clientUsername } = props;
  const s = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const user = useCurrentUser();

  const isCoach = user.role === UserRole.COACH;

  const triggerTreshold =
    activity.component.type === ComponentType.WORKOUT && isMd ? 170 : 100;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: triggerTreshold,
  });

  const originalContent: Node[] = React.useMemo(
    () => JSON.parse(activity?.content) || [],
    [activity.content],
  );

  const startWorkout: Node[] = React.useMemo(
    () => JSON.parse(activity?.startWorkout) || [],
    [activity.startWorkout],
  );
  const endWorkout: Node[] = React.useMemo(
    () => JSON.parse(activity?.endWorkout) || [],
    [activity.endWorkout],
  );

  const workoutSection = React.useMemo(() => {
    return originalContent?.filter((i: any) => i.type === "workout_section");
  }, [originalContent]);

  return (
    <Container className={clsx(s.root)}>
      <Slide
        appear={false}
        direction="down"
        in={trigger}
        style={{
          zIndex: theme.zIndex.modal,
        }}
      >
        <ActivityBar
          subtitle={"Workout summary"}
          title={"Program"}
          action={isUpMd && <ExitWorkoutButton fullWidth={false} />}
          onCloseClick={() => {
            isCoach ? navigate(-1) : navigate(CLIENT_PROGRAMS_ACTIVE_ROUTE);
          }}
        />
      </Slide>

      <Grid2 container spacing={2}>
        <Grid2
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          {!isUpMd && (
            <TrainingShare
              endWorkout={endWorkout}
              workoutSection={workoutSection}
              workoutDate={activity.date}
            />
          )}
          {workoutSection.map(({ workout }: any) =>
            workout.exercises.map((exercise, index) => (
              <TrainingSummaryExercise
                key={index}
                exercise={exercise}
                clientUsername={clientUsername}
              />
            )),
          )}
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            lg: 6,
          }}
        >
          {isUpMd && (
            <TrainingShare
              endWorkout={endWorkout}
              workoutSection={workoutSection}
              workoutDate={activity.date}
            />
          )}
          <TrainingSummaryWellBegin
            startWorkout={startWorkout}
            endWorkout={endWorkout}
          />
        </Grid2>
      </Grid2>
    </Container>
  );
}
