import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { CurriculumComponent } from "../../../../types";
import { TempIdsMap } from "../../../../api/api-slice";

// Conditional type for the action payload
type ActionWithConditionalTempId =
  | {
      componentId: number;
      data: Partial<CurriculumComponent>;
      tempId: number; // tempId is required if apiHandled is true
      tempWeeksIdsMap: TempIdsMap;
      apiHandled: true;
    }
  | {
      componentId: number;
      data: Partial<CurriculumComponent>;
      tempId?: never; // tempId should not exist if apiHandled is false
      tempWeeksIdsMap: TempIdsMap;
      apiHandled?: false | undefined; // apiHandled can be false or undefined
    };

export const updateComponentPropsAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<ActionWithConditionalTempId>,
) => {
  const {
    componentId,
    data: dirtyData,
    tempId,
    tempWeeksIdsMap,
  } = action.payload;

  // crean up CurriculmComponent data
  const data = { ...dirtyData, shouldApplyDraft: undefined };

  const realComponent = state.value.components[componentId];
  const tempComponent = tempId ? state.value.components[tempId] : undefined;

  // check if new real id belong to temp week in store
  const newRealWeekId = data.weekId;
  const tempWeekIdForNewReal = Number(
    Object.keys(tempWeeksIdsMap).find(
      (k) => tempWeeksIdsMap[k] === newRealWeekId,
    ) ?? undefined,
  );
  const checkedNewWeekId = tempWeekIdForNewReal
    ? tempWeekIdForNewReal
    : newRealWeekId;

  // COMPONENT WITH TEMP ID
  if (tempComponent) {
    const tempComponentCurrentWeekId = tempComponent.weekId;
    let newPreviousStatus = tempComponent.previousStatus;
    if (data.status && tempComponent.status !== data.status) {
      newPreviousStatus = tempComponent.status;
    }
    // DATA UPDATE
    state.value.components[tempId] = {
      ...tempComponent,
      ...data,
      previousStatus: newPreviousStatus,
      weekId: checkedNewWeekId,
      // Ignore real component id to sync temp store items
      id: tempId,
    };

    // POSITIONS SYNC
    if (tempComponentCurrentWeekId != checkedNewWeekId) {
      // check old week id
      const temp = Number(
        Object.keys(tempWeeksIdsMap).find(
          (k) => tempWeeksIdsMap[k] === tempComponentCurrentWeekId,
        ) ?? undefined,
      );
      const checked = temp ? temp : tempComponentCurrentWeekId;

      const oldWeek = state.value.weeks[checked];
      const newWeek = state.value.weeks[checkedNewWeekId];

      if (oldWeek) {
        oldWeek.positions = oldWeek.positions.filter(
          (id) => id !== tempId.toString(),
        );
      }

      if (newWeek) {
        newWeek.positions.push(tempId.toString());
      }
    }
  }

  // COMPONENT WITH REAL ID
  if (realComponent) {
    const realComponentCurrentWeekId = realComponent.weekId;
    let newPreviousStatus = realComponent.previousStatus;
    if (data.status && realComponent.status !== data.status) {
      newPreviousStatus = realComponent.status;
    }

    // DATA UPDATE
    state.value.components[componentId] = {
      ...realComponent,
      ...data,
      previousStatus: newPreviousStatus,
      weekId: checkedNewWeekId,
    };

    // POSITIONS SYNC
    if (realComponentCurrentWeekId != checkedNewWeekId) {
      // check old week id
      const temp = Number(
        Object.keys(tempWeeksIdsMap).find(
          (k) => tempWeeksIdsMap[k] === realComponentCurrentWeekId,
        ) ?? undefined,
      );
      const checked = temp ? temp : realComponentCurrentWeekId;

      const oldWeek = state.value.weeks[checked];
      const newWeek = state.value.weeks[checkedNewWeekId];

      if (oldWeek) {
        oldWeek.positions = oldWeek.positions.filter(
          (id) => id !== componentId.toString(),
        );
      }

      if (newWeek) {
        newWeek.positions.push(componentId.toString());
      }
    }
  }
};
