import React from "react";
import clsx from "clsx";
import {
  Box,
  Typography,
  Button,
  useTheme,
  lighten,
  ContainerProps,
} from "@mui/material";
import { AppLogo } from "../app/AppLogo";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { ReactComponent as BinIcon } from "../../icons/Bin2.svg";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import Sitting from "../../icons/Sitting";
import { useLogout } from "../../hooks/useLogout";
import { useMutation } from "@tanstack/react-query";
import ClientsService from "../../services/ClientsService";
import { makeStyles } from "@mui/styles";
import { CompactContainer } from "../container/CompactContainer";

const useStyles = makeStyles((theme) => ({
  root: {},

  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  button: {
    fontSize: 14,
    fontWeight: "bold",
    margin: theme.spacing(2),
  },
}));

export interface ArchivedClientScreenProps extends ContainerProps {}

export function ArchivedClientScreen(props: ArchivedClientScreenProps) {
  const { className } = props;
  const { isBrandedAppPublished } = useCurrentBrand();
  const theme = useTheme();
  const logout = useLogout();

  const s = useStyles();

  return (
    <>
      <CompactContainer
        className={clsx(s.root, className)}
        classes={{
          body: s.body,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            padding: 3,
            paddingTop: 4,
          }}
        >
          <AppLogo
            main={!isBrandedAppPublished}
            appStoreLogo={isBrandedAppPublished}
            full
            size={!isBrandedAppPublished ? 160 : 80}
            width={160}
          ></AppLogo>
          <Box
            sx={{
              maxWidth: "300px",
              height: "auto",
              marginY: 3,
            }}
          >
            <Sitting fill={theme.palette.primary.main} />
          </Box>

          <Typography variant="h6" gutterBottom>
            Your account has been archived by your coach...
          </Typography>
          <Button
            className={clsx(s.button)}
            onClick={() => {
              logout();
            }}
            variant="text"
          >
            Logout
          </Button>
        </Box>
      </CompactContainer>
    </>
  );
}
