import React, { useEffect, useRef, useState } from "react";
import {
  ButtonProps,
  Typography,
  lighten,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from "lucide-react";
import { ProgramDetailsViewMode } from "../ProgramDetailsViewButton";
import { getWeekPerPageMaxValue } from "../util";
import FilterBadge from "./components/FilterBadge";
import FilterSubtitle from "./components/FilterSubtitle";
import FilterMenuItem from "./components/FilterMenuItem";
import MinimizedTooltip from "../../tooltip/MinimizedTooltip";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: lighten(theme.palette.text.secondary, 0.8),
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.75, 2),
    borderRadius: theme.spacing(0.5),
  },

  text: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },

  avatar: {
    width: 32,
    height: 32,
    fontSize: 16,
    fontWeight: "bold",
  },

  avatarSeparator: {
    display: "flex",
    alignItems: "end",
    position: "relative",
    right: 1,
  },

  wrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    height: "fit-content",

    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(0.25),
    },
  },

  box: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginLeft: "auto",
    color: theme.palette.text.secondary,
    height: "fit-content",
    "&:hover": { cursor: "pointer" },
  },
}));

export interface ProgramWeekRangeDetails {
  startNumber: number;
  endNumber: number;
}

export interface ProgramWeekRangeSelectorProps
  extends Omit<ButtonProps, "value" | "onChange"> {
  view: ProgramDetailsViewMode;
  value?: number[];
  onChange?: (value: number[]) => void;
  programLength: number;
}

export function WeeksFilter(props: ProgramWeekRangeSelectorProps) {
  const { view, programLength, value: visibleWeeksArr, onChange } = props;
  const s = useStyles();
  const theme = useTheme();
  const IS_MD = useMediaQuery(theme.breakpoints.down("md"));

  const chooseWeeksRef = useRef();
  const chooseWeeksMenuState = usePopupState({
    variant: "popover",
    popupId: "choose-weeks",
  });

  const handleChooseWeeksMenuChange = (
    event: React.MouseEvent<
      HTMLLIElement | HTMLDivElement | HTMLLabelElement,
      MouseEvent
    >,
    directClickValue?: number,
  ) => {
    let value;

    if (directClickValue) value = directClickValue;
    else {
      const {
        dataset: { week },
      } = event.currentTarget;

      value = Number(week);
    }

    if (visibleWeeksArr.includes(value)) {
      // prevent unselect last
      if (visibleWeeksArr.length === 1) return onChange(visibleWeeksArr);
      onChange(visibleWeeksArr.filter((item) => item !== value));
    } else {
      onChange([...visibleWeeksArr, value]);
    }
  };

  const handleDirectClick = (
    event: React.MouseEvent<
      HTMLLIElement | HTMLDivElement | HTMLLabelElement,
      MouseEvent
    >,
    value: number,
  ) => {
    visibleWeeksArr.length !== 1 && event.stopPropagation();
    handleChooseWeeksMenuChange(event, value);
  };

  const handlePrevClick = () => {
    onChange(visibleWeeksArr.map((w) => w - 1));
  };

  const handleNextClick = () => {
    onChange(visibleWeeksArr.map((w) => w + 1));
  };

  // Hide weeks if more than max per page
  useEffect(() => {
    if (getWeekPerPageMaxValue(view) < visibleWeeksArr.length)
      onChange(
        visibleWeeksArr.slice(
          0,
          getWeekPerPageMaxValue(view) - visibleWeeksArr.length,
        ),
      );
  }, [view]);

  return (
    <>
      <MinimizedTooltip
        title="Choose best combination of shown weeks"
        placement="top"
      >
        <Box className={s.wrapper} {...bindTrigger(chooseWeeksMenuState)}>
          {!IS_MD && <Typography variant="subtitle1">Weeks</Typography>}

          <IconButton
            size="small"
            disabled={Math.min(...visibleWeeksArr) === 1}
            onClick={(e) => {
              e.stopPropagation();
              handlePrevClick();
            }}
            sx={{
              opacity: chooseWeeksMenuState.isOpen ? 0.1 : 1,
              transition: theme.transitions.create(["opacity"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            <ChevronLeft />
          </IconButton>
          <Box className={s.box} ref={chooseWeeksRef}>
            <Box sx={{ display: "flex", gap: 0.5 }}>
              {visibleWeeksArr
                .sort((a, b) => a - b)
                .map((w, index) => {
                  const isGap = w - visibleWeeksArr[index - 1] > 1;
                  return (
                    <>
                      {isGap && (
                        <Box sx={{ borderLeft: 2, borderStyle: "dashed" }} />
                      )}
                      <FilterBadge value={w} component={<>{w}</>} />
                    </>
                  );
                })}
            </Box>
          </Box>
          <IconButton
            size="small"
            disabled={Math.max(...visibleWeeksArr) === programLength}
            onClick={(e) => {
              e.stopPropagation();
              handleNextClick();
            }}
            sx={{
              opacity: chooseWeeksMenuState.isOpen ? 0.1 : 1,
              transition: theme.transitions.create(["opacity"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </MinimizedTooltip>

      <Menu
        {...bindMenu(chooseWeeksMenuState)}
        anchorEl={chooseWeeksRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          disabled={Math.min(...visibleWeeksArr) === 1}
          onClick={handlePrevClick}
          sx={{
            height: theme.spacing(3),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ChevronUp />
        </MenuItem>

        {[...Array(programLength)].map((_, index) => (
          <FilterMenuItem
            item={index}
            preLabel={"Week"}
            label={`${index + 1}`}
            onClick={(e) => handleDirectClick(e, index + 1)}
            checked={visibleWeeksArr.includes(index + 1)}
            disabled={
              getWeekPerPageMaxValue(view) === visibleWeeksArr.length &&
              !visibleWeeksArr.includes(index + 1)
            }
          />
        ))}

        <MenuItem
          disabled={Math.max(...visibleWeeksArr) === programLength}
          onClick={handleNextClick}
          sx={{
            height: theme.spacing(3),
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ChevronDown />
        </MenuItem>
      </Menu>
    </>
  );
}
