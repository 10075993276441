import React, { useMemo, useState } from "react";
import {
  DialogProps,
  Box,
  TextField,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Typography,
  Dialog,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ProgramEnrollmentDto } from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 524,
  },

  input: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },

  list: {
    maxHeight: 300,
    overflowY: "auto",
  },

  noEnrollments: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

export interface RegenerateActivitiesDialogProps extends DialogProps {
  enrollments: ProgramEnrollmentDto[];
  onRegenerate: (selectedEnrollments: ProgramEnrollmentDto[]) => void;
}

export function RegenerateActivitiesDialog(
  props: RegenerateActivitiesDialogProps,
) {
  const { open, onClose, enrollments, onRegenerate, ...other } = props;
  const s = useStyles();
  const [filter, setFilter] = useState("");
  const [selectedEnrollments, setSelectedEnrollments] = useState<
    ProgramEnrollmentDto[]
  >([]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleToggle = (enrollment: ProgramEnrollmentDto) => () => {
    const currentIndex = selectedEnrollments.findIndex(
      (e) => e.id === enrollment.id,
    );
    const newSelected = [...selectedEnrollments];

    if (currentIndex === -1) {
      newSelected.push(enrollment);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedEnrollments(newSelected);
  };

  const handleSubmit = () => {
    onRegenerate(selectedEnrollments);
    handleClose();
  };

  const handleClose = () => {
    onClose && onClose({}, "backdropClick");
    setSelectedEnrollments([]);
    setFilter("");
  };

  const filteredEnrollments = useMemo(() => {
    return enrollments.filter(
      (enrollment) =>
        enrollment.user?.displayName
          ?.toLowerCase()
          .includes(filter.toLowerCase()) ||
        enrollment.user?.username
          ?.toLowerCase()
          .includes(filter.toLowerCase()) ||
        enrollment.user?.email?.toLowerCase().includes(filter.toLowerCase()),
    );
  }, [enrollments, filter]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <Box p={2}>
        <Typography variant="h6">Regenerate Activities</Typography>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Select enrollments to regenerate activities
        </Typography>
        <Divider />

        {/* Filter Input */}
        <TextField
          className={s.input}
          variant="outlined"
          fullWidth
          value={filter}
          onChange={handleFilterChange}
          label="Filter by name or email"
        />

        {/* Enrollment List */}
        {filteredEnrollments.length === 0 ? (
          <Typography className={s.noEnrollments}>
            No enrollments match your search.
          </Typography>
        ) : (
          <List className={s.list}>
            {filteredEnrollments.map((enrollment) => {
              const labelId = `checkbox-list-label-${enrollment.id}`;
              return (
                <ListItem
                  key={enrollment.id}
                  role={undefined}
                  onClick={handleToggle(enrollment)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedEnrollments.some(
                        (e) => e.id === enrollment.id,
                      )}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={
                      enrollment.user?.displayName ||
                      enrollment.user?.username ||
                      "Unknown User"
                    }
                    secondary={
                      enrollment.startDate
                        ? dayjs(enrollment.startDate.toISOString()).format(
                            "MMM DD, YYYY",
                          )
                        : "No start date"
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}

        {/* Action Buttons */}
        <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={selectedEnrollments.length === 0}
          >
            Regenerate
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default RegenerateActivitiesDialog;
