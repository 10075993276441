import React from "react";
import {
  COACH_CLIENT_SETTINGS_ROUTE,
  COACH_MESSAGE_ROUTE,
} from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { ListMenu } from "../menu/ListMenu";
import {
  IBriefClientInfo,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { DeleteClientDialog } from "../settings/dialogs/DeleteUserDialog";
import { UserRole } from "../../constants";

interface MenuOption {
  label: string;
  onClick: (event: any) => void;
  visible: boolean;
}

interface ClientMenuProps {
  anchorEl: any;
  handleClose: (event?: any) => void;
  archivingClient: boolean;
  onArchivedChange: (archived: boolean) => void;
  deletingClient: boolean;
  onDelete: () => void;
  user: IBriefClientInfo;
  isSample: boolean;
}

const ClientContextMenu = (props: ClientMenuProps) => {
  const {
    anchorEl,
    handleClose,
    archivingClient,
    onArchivedChange,
    deletingClient,
    onDelete,
    user,
    isSample,
  } = props;
  const navigate = useNavigate();
  const archived = user.status === UserInviteStatus.ARCHIVED;
  const currentUser = useCurrentUser();
  const handleSendMessage = React.useCallback(() => {
    handleClose();
    navigate(COACH_MESSAGE_ROUTE.replace(":recipient", user.username));
  }, [handleClose]);

  const handleEditSettings = React.useCallback(() => {
    handleClose();
    navigate(COACH_CLIENT_SETTINGS_ROUTE.replace(":username", user.username));
  }, [handleClose]);

  const handleArchiveToggle = React.useCallback(() => {
    onArchivedChange(!archived);
    handleClose();
  }, [onArchivedChange, archived, handleClose]);

  const handleDeleteClient = React.useCallback(() => {
    onDelete();
    confirmDeleteClientDialogState.close;
  }, [onDelete, handleClose]);

  const confirmDeleteClientDialogState = usePopupState({
    variant: "popover",
    popupId: "delete-client",
  });

  const handleDeleteClientClick = React.useCallback(() => {
    handleClose();
    confirmDeleteClientDialogState.open();
  }, [handleClose, confirmDeleteClientDialogState]);

  const switchUser = useSwitchUser();

  const handleLoginAs = React.useCallback(
    (event) => {
      switchUser(user.id);
    },
    [switchUser, user],
  );

  const menuOptions: MenuOption[] = [
    {
      label: "Send message",
      onClick: handleSendMessage,
      visible: !(user.status === UserInviteStatus.ARCHIVED),
    },

    {
      label: "Login as Client",
      onClick: handleLoginAs,
      visible: !(user.status === UserInviteStatus.ARCHIVED),
    },
    {
      label: "Edit settings",
      onClick: handleEditSettings,
      visible: !(user.status === UserInviteStatus.ARCHIVED),
    },
    {
      label: archived ? "Unarchive" : "Archive",
      onClick: handleArchiveToggle,
      visible: true,
    },

    {
      label: "Delete",
      onClick: handleDeleteClientClick,
      visible: user.status === UserInviteStatus.ARCHIVED && !isSample,
    },
  ];

  return (
    <>
      <ListMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        options={menuOptions.filter(({ visible }) => visible)}
        onClose={handleClose}
        disabled={archivingClient || deletingClient}
      />
      {confirmDeleteClientDialogState.isOpen && (
        <DeleteClientDialog
          userDisplayName={user.displayName}
          userRole={UserRole.COACH}
          onClose={() => confirmDeleteClientDialogState.close()}
          onDelete={handleDeleteClient}
        />
      )}
    </>
  );
};

export default ClientContextMenu;
