import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Plan, planStatus } from "../../constants";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { BaseDialog } from "../dialog/BaseDialog";
import {
  Button,
  DialogProps,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DefaultLoader } from "../loading/DefaultLoader";
import { Celebration } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import clsx from "clsx";
import { UpgradedDialog } from "../dialog/payments/UpgradedDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",

    "& .MuiDialog-paper": {
      minWidth: "240px",
      minHeight: "180px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  svg: {
    width: "100%",
    maxWidth: 345,
    margin: theme.spacing(4, 0),
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
  },
}));

export interface SubscriptionUpdatePollingProps extends DialogProps {
  expectedPlan: string;
}

export function SubscriptionUpdatePolling(
  props: SubscriptionUpdatePollingProps,
) {
  const { className, onClose, open, expectedPlan, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const { showToastAlert } = useToastAlert();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user?.plan === expectedPlan) {
      setIsLoading(false);
    }
  }, [user, expectedPlan]);

  useEffect(() => {
    if (!open) return;

    setIsLoading(true);

    let count = 0;
    const maxAttempts = 30;

    const intervalId = setInterval(() => {
      if (user?.plan === expectedPlan) {
        clearInterval(intervalId);
        setIsLoading(false);
        onClose({}, "backdropClick");
        return;
      }

      if (count >= maxAttempts) {
        clearInterval(intervalId);
        setIsLoading(false);
        showToastAlert("error", {
          message: "Unable to fetch updated subscription data.",
        });
        onClose({}, "backdropClick");
        return;
      }

      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
      count++;
    }, 3000);

    return () => clearInterval(intervalId);
  }, [open, user, onClose, queryClient, expectedPlan, showToastAlert]);

  return (
    <>
      <BaseDialog
        open={open}
        className={clsx(s.root, className)}
        showClose={false}
        {...other}
      >
        {isLoading && (
          <Stack spacing={1.5} mt={3} direction="column">
            <DefaultLoader />
            <Typography variant="caption">Processing...</Typography>
          </Stack>
        )}
      </BaseDialog>
    </>
  );
}
