import React, { createContext, useContext, useState, useEffect } from "react";

interface CopyCalendarContextProps {
  shiftDown: boolean;
  copiedComponentId: number;
  setCopiedComponentId: React.Dispatch<React.SetStateAction<number>>;
}

const CopyPasteComponentContext = createContext<
  CopyCalendarContextProps | undefined
>(undefined);

export const CopyPasteComponentProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Copy data state
  const [copiedComponentId, setCopiedComponentId] = useState<number>();

  // `Shift` key state
  const [shiftDown, setShiftDown] = useState(false);

  // `Shift` event handlers
  const keyDownListener = (event: KeyboardEvent) => {
    if (event.key === "Shift" && !shiftDown) {
      setShiftDown(true);
    }
  };

  const keyUpListener = (event: KeyboardEvent) => {
    if (event.key === "Shift" && shiftDown) {
      setShiftDown(false);
    }
  };

  // `Shift` event listeners
  useEffect(() => {
    window.addEventListener("keydown", keyDownListener);
    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  }, [keyDownListener]);

  useEffect(() => {
    window.addEventListener("keyup", keyUpListener);
    return () => {
      window.removeEventListener("keyup", keyUpListener);
    };
  }, [keyUpListener]);

  return (
    <CopyPasteComponentContext.Provider
      value={{ shiftDown, copiedComponentId, setCopiedComponentId }}
    >
      {children}
    </CopyPasteComponentContext.Provider>
  );
};

export function useCopyPasteComponent() {
  const context = useContext(CopyPasteComponentContext);
  if (!context) {
    throw new Error(
      "useCopyPasteComponent must be used within a CopyPasteComponentProvider",
    );
  }
  return context;
}
