import clsx from "clsx";
import React, { useRef } from "react";
import {
  Box,
  BoxProps,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Units, UnitsLabels, floatNumberInputProps } from "../../constants";
import { polyfillCSS } from "../../utils/css";

import { RadioGroupField } from "./RadioGroupField";
import { FieldsGroup } from "./FieldsGroup";
import {
  FieldErrors,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { FieldError as FieldErrorComponent } from "./FieldError";

const useStyles = makeStyles((theme) => ({
  root: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  inputs: {
    display: "flex",
    marginRight: theme.spacing(-2),
    "& > *": {
      backgroundColor: theme.palette.background.paper,

      width: polyfillCSS(`calc(100% / 2 - ${theme.spacing(2)} * 2)`),
      margin: theme.spacing(0, 2, 0, 0),
      flexGrow: 1,
      flexShrink: 0,
    },
  },

  adornment: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 0,
    marginRight: 0,

    [theme.breakpoints.up("sm")]: {
      marginLeft: "initial",
    },
  },

  radioLabel: {
    fontWeight: 500,
  },

  radioDisplay: {
    flexDirection: "row",
  },

  inputGroups: {
    display: "block",

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      "& > *": {
        flexGrow: 1,
      },

      "& > *:last-child": {
        marginLeft: theme.spacing(1.75),
      },
    },
  },

  error: {
    position: "absolute",
  },

  controlLabel: {
    marginBottom: 0,
  },
}));

export type BodyWeightHeightValue = {
  units: Units;
  height: string;
  weight: string;
};

type MaybeNumber = number | "";

type State = {
  units: Units;
  heightCm: MaybeNumber;
  heightFt: MaybeNumber;
  heightIn: MaybeNumber;
  weightLbs: MaybeNumber;
  weightKg: MaybeNumber;
};

const toMaybeNumber = (x: string, max = 999): MaybeNumber => {
  const num = parseFloat(x);

  return num ? Math.min(num, max) : 0;
};

const round = (x: number, p = 0) => {
  const d = Math.pow(10, p);

  return Math.round(x * d) / d;
};

const fromMaybeNumber = (x: MaybeNumber) => x || 0;

const parseValue = ({
  units,
  height,
  weight,
}: BodyWeightHeightValue): State => {
  let heightCm: MaybeNumber = "",
    heightFt: MaybeNumber = "",
    heightIn: MaybeNumber = "",
    weightLbs: MaybeNumber = "",
    weightKg: MaybeNumber = "";

  if (units === Units.METRIC) {
    weightKg = toMaybeNumber(weight);
    heightCm = toMaybeNumber(height);
  } else {
    const heightUS = toMaybeNumber(height);

    if (heightUS) {
      heightFt = Math.floor(heightUS / 12);
      heightIn = round(heightUS - heightFt * 12, 2);
    }

    weightLbs = toMaybeNumber(weight);
  }

  return {
    units,
    heightCm,
    heightFt,
    heightIn,
    weightLbs,
    weightKg,
  };
};

interface BodyWeightHeightField {
  height?: number;
  weight?: number;
  units?: string;
}

export interface BodyWeightHeightFieldProps<T = BodyWeightHeightField>
  extends Omit<BoxProps, "onChange" | "error"> {
  disabled?: boolean;
  errors: FieldErrors<T>;
  onHeightBlur?: () => void;
  onWeightBlur?: () => void;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
}

export const isUserUsingUSLocale = () => navigator.language === "en-US";

export function BodyWeightHeightField(props: BodyWeightHeightFieldProps) {
  const {
    className,
    disabled,
    errors,
    onHeightBlur,
    onWeightBlur,
    watch,
    setValue,
    trigger,
    ...other
  } = props;
  const s = useStyles();
  const {
    weight: weightError,
    height: heightError,
    units: unitsError,
  } = errors;

  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const height = watch("height");
  const weight = watch("weight");
  const units = watch("units") as Units;
  const [state, setState] = React.useState<State>(
    parseValue({
      height: height && String(height),
      weight: weight && String(weight),
      units,
    }),
  );

  const heightRef = useRef<HTMLInputElement | null>(null);
  const heightInRef = useRef<HTMLInputElement | null>(null);
  const weightRef = useRef<HTMLInputElement | null>(null);

  const handleChange = async ({
    units,
    heightFt,
    heightIn,
    heightCm,
    weightLbs,
    weightKg,
  }: State) => {
    const height =
      units === Units.US
        ? 12 * fromMaybeNumber(heightFt) + fromMaybeNumber(heightIn)
        : heightCm;
    const weight = units === Units.US ? weightLbs : weightKg;

    setValue("height", +height, { shouldDirty: true });
    setValue("weight", +weight, { shouldDirty: true });
    setValue("units", units, { shouldDirty: true });

    if (heightError) await trigger("height", { shouldFocus: false });
    if (weightError) await trigger("weight", { shouldFocus: false });
    if (unitsError) await trigger("units", { shouldFocus: false });
  };

  const updateState = React.useCallback(
    (update) => {
      const newState = {
        ...state,
        ...update,
      };

      setState(newState);
      handleChange(newState);
    },
    [handleChange, state],
  );

  const heightLabel = React.useMemo(() => {
    if (mdUp) {
      return state.units === Units.US ? "feet" : "centimeters";
    }

    return state.units === Units.US ? "ft" : "cm";
  }, [mdUp, state.units]);

  const inchesLabel = React.useMemo(() => {
    return mdUp ? "inches" : "in";
  }, [mdUp]);

  const weightLabel = React.useMemo(() => {
    if (mdUp) {
      return state.units === Units.US ? "pounds" : "kilograms";
    }

    return state.units === Units.US ? "lbs" : "kg";
  }, [mdUp, state.units]);

  const heightValue = React.useMemo(
    () => (state.units === Units.US ? state.heightFt : state.heightCm) || 0,
    [state.heightCm, state.heightFt, state.units],
  );

  const weightValue = React.useMemo(
    () => (state.units === Units.US ? state.weightLbs : state.weightKg) || 0,
    [state.units, state.weightKg, state.weightLbs],
  );

  const handleUnitsChange = React.useCallback(
    ({ target: { value } }) => {
      const units = value;
      const newState = { ...state, units };

      if (value === Units.US) {
        if (state.weightKg) {
          newState.weightLbs = Math.round(2.205 * state.weightKg);
        }

        if (state.heightCm) {
          const heightFt = state.heightCm / 30.48;

          newState.heightFt = Math.floor(heightFt);
          newState.heightIn = round((heightFt - newState.heightFt) * 12, 2);
        }
      } else {
        if (state.weightLbs) {
          newState.weightKg = round(state.weightLbs / 2.205, 1);
        }

        if (state.heightFt) {
          let newHeightCm = state.heightFt * 30.48;

          if (state.heightIn) {
            newHeightCm += state.heightIn * 2.52;
          }

          newState.heightCm = Math.round(newHeightCm);
        }
      }

      updateState(newState);
    },
    [state, updateState],
  );

  const handleHeightChange = React.useCallback(
    ({ target: { value } }) => {
      const num = toMaybeNumber(value);
      const key = state.units === Units.US ? "heightFt" : "heightCm";

      updateState({ [key]: num });
    },
    [state, updateState],
  );

  const handleHeightExtraChange = React.useCallback(
    ({ target: { value } }) => {
      const num = toMaybeNumber(value);

      updateState({
        heightIn: num,
      });
    },
    [updateState],
  );

  const handleWeightChange = React.useCallback(
    ({ target: { value } }) => {
      const num = toMaybeNumber(value);
      const key = state.units === Units.US ? "weightLbs" : "weightKg";

      updateState({ [key]: num });
    },
    [state, updateState],
  );

  const unitOptions = React.useMemo(() => {
    return Object.keys(Units)
      .map((unit) => ({
        value: unit,
        label: UnitsLabels[unit],
      }))
      .sort((a) => (a.value === Units.METRIC ? -1 : 1));
  }, []);

  const handleAdornmentClick = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.focus();
  };

  const heightIn = state.heightIn || 0;

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <RadioGroupField
        className={s.radioDisplay}
        label="Unit of Measurement"
        value={state.units}
        options={unitOptions}
        controlLabelStyle={s.controlLabel}
        onChange={handleUnitsChange}
        disabled={disabled}
      />
      <Box className={s.inputGroups}>
        <FieldsGroup label="Height">
          <Box className={s.inputs}>
            <TextField
              inputRef={heightRef}
              variant="outlined"
              type="number"
              value={heightValue || ""}
              onChange={handleHeightChange}
              onBlur={onHeightBlur}
              error={Boolean(heightError)}
              disabled={disabled}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment
                      className={s.adornment}
                      position="end"
                      children={heightLabel}
                      onClick={() => handleAdornmentClick(heightRef)}
                    />
                  ),
                  inputProps: floatNumberInputProps,
                },
              }}
            />
            {state.units === Units.US && (
              <TextField
                variant="outlined"
                type="number"
                inputRef={heightInRef}
                value={`${heightIn}`}
                onChange={handleHeightExtraChange}
                onBlur={onHeightBlur}
                error={Boolean(heightError)}
                disabled={disabled}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment
                        className={s.adornment}
                        position="end"
                        children={inchesLabel}
                        onClick={() => handleAdornmentClick(heightInRef)}
                      />
                    ),
                    inputProps: {
                      ...floatNumberInputProps,
                      step: 0.01,
                    },
                  },
                }}
              />
            )}
          </Box>
          {heightError && (
            <FieldErrorComponent hideIcon={true} className={s.error}>
              {heightError.message}
            </FieldErrorComponent>
          )}
        </FieldsGroup>

        <FieldsGroup label="Weight">
          <Box className={s.inputs}>
            <TextField
              variant="outlined"
              type="number"
              inputRef={weightRef}
              value={weightValue || ""}
              onChange={handleWeightChange}
              onBlur={onWeightBlur}
              error={Boolean(weightError)}
              disabled={disabled}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment
                      className={s.adornment}
                      position="end"
                      children={weightLabel}
                      onClick={() => handleAdornmentClick(weightRef)}
                    />
                  ),
                  inputProps: floatNumberInputProps,
                },
              }}
            />
          </Box>
          {weightError && (
            <FieldErrorComponent hideIcon={true} className={s.error}>
              {weightError.message}
            </FieldErrorComponent>
          )}
        </FieldsGroup>
      </Box>
    </Box>
  );
}
