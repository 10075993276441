import React from "react";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import ComponentDuplicatesViewer from "../../../components/admin/ComponentDuplicatesViewer";

export function DuplicatesViewerRoute() {
  return (
    <>
      <ComponentDuplicatesViewer />
      <TrackInfoTool
        trackInfo={{
          name: "Admin - Duplicates Viewer",
        }}
      />
    </>
  );
}
