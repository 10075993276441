import clsx from "clsx";
import React from "react";
import { BoxProps, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ForgotPasswordFormProps } from "../login/ForgotPasswordForm";
import { ActionButton } from "../button/ActionButton";
import { polyfillCSS } from "../../utils/css";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",

    "& p": {
      textAlign: "center",
    },
  },

  field: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
  },

  submit: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },

  back: {
    position: "absolute",
    top: polyfillCSS(`max(${theme.spacing(2)}, var(--safe-area-inset-top))`),
    left: polyfillCSS(`max(${theme.spacing(2)}, var(--safe-area-inset-left))`),
  },
}));

export interface ClientPortalForgotPasswordFormProps
  extends ForgotPasswordFormProps {}

export function ClientPortalForgotPasswordForm(
  props: ClientPortalForgotPasswordFormProps,
) {
  const { className, state, loading, onChange, onResend, onCancel, ...other } =
    props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();

  return (
    <form className={clsx(s.root, className)} {...other}>
      {state.sent ? (
        <>
          <Typography sx={{ mb: 2, mt: 1 }}>
            If you have a {brandName} account, we'll email you a a link to reset
            your password.
          </Typography>

          <ActionButton
            className={s.submit}
            disabled={loading}
            children="Resend recovery link"
            onClick={onResend}
            fullWidth
          />
        </>
      ) : (
        <>
          <Typography>
            Type your email here and we'll send you a reset link
          </Typography>

          <TextField
            className={s.field}
            variant="outlined"
            name="email"
            type="email"
            label="Email Address"
            placeholder="email@example.com"
            value={state.email}
            onChange={onChange}
            disabled={loading}
            error={Boolean(state.error)}
            helperText={state.error || " "}
            fullWidth
          />

          <ActionButton
            className={s.submit}
            type="submit"
            disabled={!state.email || loading}
            children="Send reset link"
            fullWidth
          />
        </>
      )}
    </form>
  );
}
