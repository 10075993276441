import {
  Box,
  FormControl,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import GenericScheduleSelect from "../curriculum-sidebar/components/schedule/generic/GenericScheduleSelect";
import { ReminderType } from "@growth-machine-llc/stridist-api-client";
import { TimePicker } from "@mui/x-date-pickers";
import GenericScheduleTitle from "../curriculum-sidebar/components/schedule/generic/GenericScheduleTitle";
import { CurriculumComponent } from "../../redux/types";
import dayjs from "dayjs";
import { Schedule } from "../schedule/types";
import { useCurriculumDispatch } from "../../redux/hooks";
import { updateComponentSchedule } from "../../redux/curriculum/curriculum-slice";
import { parseComponentDays } from "../../utils/component";
import { ComponentRepeat } from "../../constants";
import OneTimeExpandable from "./OneTimeExpandable";
import { useTimeFormat } from "../../hooks/useTimeFormat";

const DEFAULT_TIME = "12:00";

interface IComponentReminderProps {
  component: CurriculumComponent;
}

const ReminderSettings = (props: IComponentReminderProps) => {
  const { component } = props;
  const theme = useTheme();
  const timeFormat = useTimeFormat();
  const dispatch = useCurriculumDispatch();
  const firstAutoToggled = React.useRef<boolean>(false);
  const toggled = Boolean(component.reminderType);

  // Turn reminder on if it's not already configured and sidebar was opened
  useEffect(() => {
    let timeout = null;

    if (!toggled && !firstAutoToggled.current) {
      timeout = setTimeout(() => {
        onReminderToggle(true);
      }, 300);
    }

    firstAutoToggled.current = true;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [component.id, toggled]);

  const updateSchedule = (
    updates: Partial<Schedule>,
    options?: { nullifyReminder?: boolean },
  ) => {
    dispatch(
      updateComponentSchedule({
        componentId: component.id,
        nullifyReminder: options?.nullifyReminder,
        schedule: {
          weekId: component.weekId,
          repeat: component.repeat,
          days: parseComponentDays(component.days),
          duration: component.duration,
          reminderType: component.reminderType,
          reminderTime: component.reminderTime,
          messageTime: component.messageTime,
          ...updates,
        },
      }),
    );
  };

  //
  // Handlers

  const onReminderToggle = (value: boolean) => {
    updateSchedule(
      {
        reminderType: value ? ReminderType.CUSTOM : null,
        reminderTime: value ? DEFAULT_TIME : null,
      },
      {
        nullifyReminder: value ? undefined : true,
      },
    );
  };

  const handleTypeSelectChange = (newValue: ReminderType) => {
    updateSchedule({
      reminderType: newValue,
      reminderTime: DEFAULT_TIME,
    });
  };

  const handleTimePickerChange = (newValue: dayjs.Dayjs) => {
    const result = newValue?.isValid() ? newValue : dayjs();
    updateSchedule({
      reminderType: ReminderType.CUSTOM,
      reminderTime: result.format("HH:mm"),
    });
  };

  //
  // UI Labels

  const TIME_VALUE = dayjs(component.reminderTime, "HH:mm");

  let mainInfo = "";
  let mainLearnMore = "";
  if (component.reminderType === ReminderType.CUSTOM && TIME_VALUE.isValid()) {
    mainInfo = `Reminder to complete “${component.title}” ${component.type.toString().toLowerCase()} will be sent at ${TIME_VALUE.format(timeFormat)} if client hasn’t completed it already.`;
    mainLearnMore = "";
  } else if (
    component.reminderType === ReminderType.AUTOMATIC &&
    JSON.parse(component.days).filter((value) => value).length <= 1 &&
    component.repeat === ComponentRepeat.NONE
  ) {
    mainInfo = `Automatic reminder to complete “${component.title}” ${component.type.toString().toLowerCase()} if a client hasn’t done so yet 30 minutes later his regular time.`;
    mainLearnMore = `Current ${component.type.toString().toLowerCase()} occurs only once for each clients. Reminder is automatically set to ${dayjs("12:00", "HH:mm").format(timeFormat)}`;
  } else if (component.reminderType === ReminderType.AUTOMATIC) {
    mainInfo = `Automatic reminder to complete “${component.title}” ${component.type.toString().toLowerCase()} if a client hasn’t done so yet 30 minutes later his regular time.`;
    mainLearnMore =
      "If the client has never completed current " +
      component.type.toString().toLowerCase() +
      ` yet, he will receive notification at ${dayjs("12:00", "HH:mm").format(timeFormat)} in their local time.`;
  }

  return (
    <>
      <Box paddingInline={2}>
        <Typography
          variant="h6"
          sx={{
            marginTop: 3,
            marginBottom: 4,
            borderBottom: 2,
            borderColor: theme.palette.text.secondary,
          }}
        >
          {"Reminder"}
        </Typography>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box>
            <GenericScheduleTitle
              title="Reminder notifications"
              reverse={true}
              checked={toggled}
              enableSwitch={true}
              handleSwitchOnChange={onReminderToggle}
            />
          </Box>
          {toggled ? (
            <Typography variant="caption" color={theme.palette.text.secondary}>
              Clients will receive email, SMS, and push notification based on
              their notification settings
              <OneTimeExpandable label="Learn more">
                <Box>
                  <Typography fontWeight={"bold"}>
                    Notification channels
                  </Typography>
                  <Box mt={1}>
                    <Box>
                      <b>Email:</b> if enabled in client settings.
                    </Box>
                    <Box mt={1}>
                      <b>Push:</b> if enabled in client settings. Client has to
                      give the app permission to send notifications.
                    </Box>
                    <Box mt={1}>
                      <b>SMS:</b> if enabled in client settings.
                    </Box>
                  </Box>
                  <Typography my={2} variant="caption" display="block">
                    Clients can adjust these settings under the Reminders
                    section of Notification settings tab on the Settings page.
                  </Typography>
                </Box>
              </OneTimeExpandable>
            </Typography>
          ) : (
            <>
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
              >
                Clients will not receive any reminder notifications to complete
                “Weekly check-in” check-in.
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
              >
                Email, SMS and push notifications are available based on client
                notification settings
              </Typography>
            </>
          )}
          {toggled && (
            <GenericScheduleSelect
              title="Type"
              enableSwitch={false}
              value={component.reminderType}
              onChange={handleTypeSelectChange}
            >
              <MenuItem value={ReminderType.AUTOMATIC}>Automatic</MenuItem>
              <MenuItem value={ReminderType.CUSTOM}>Specific time</MenuItem>
            </GenericScheduleSelect>
          )}

          {component.reminderType === ReminderType.CUSTOM && (
            <FormControl>
              <GenericScheduleTitle title={"Time"} enableSwitch={false} />
              <TimePicker
                value={TIME_VALUE.isValid() ? TIME_VALUE : null}
                slotProps={{
                  textField: { size: "small" },
                }}
                timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                onChange={handleTimePickerChange}
              />
            </FormControl>
          )}

          <Typography variant="caption" color={theme.palette.text.secondary}>
            {mainInfo}
            {mainLearnMore && (
              <OneTimeExpandable label="Learn more">
                <Box>{mainLearnMore}</Box>
              </OneTimeExpandable>
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ReminderSettings;
