import React, { useState } from "react";
import {
  Box,
  FormControl,
  OutlinedInput,
  Theme,
  createTheme,
  useMediaQuery,
} from "@mui/material";

import { colorSystem } from "../../theme";
import { wholeNumberInputProps } from "../../constants";
import { getRange } from "../workout/utils";

export interface CoachSetRangeInputProps {
  reps: number | string;
  onChange: (value: string) => void;
  disabled?: boolean;
  dense?: boolean;
}

function CoachSetRangeInput(props: CoachSetRangeInputProps) {
  const { reps, onChange, disabled, dense = false } = props;
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const inputRange = reps.toString().split("-");
  const rangeStart = inputRange[0]?.trim();
  const rangeEnd = inputRange[1]?.trim();

  const theme = createTheme();

  const commonStyles = {
    "& input::placeholder": {
      color: theme.palette.text.disabled,
    },

    "& input:focus::placeholder": {
      color: "transparent",
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  };

  const handleRangeChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = event.currentTarget;

    if (value.length < 4) {
      const range = getRange(name, value, rangeStart, rangeEnd);
      onChange(range);
    }
  };

  const [isRangeHovered, setIsRangeHovered] = useState(false);

  return (
    <>
      <>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <FormControl sx={{ flexDirection: "row" }}>
            <OutlinedInput
              value={rangeStart}
              onChange={handleRangeChange}
              name="rangeStart"
              placeholder="0"
              sx={{
                ...commonStyles,
                minWidth: isSm ? "40px" : "100px",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  border: isRangeHovered && 1,
                  borderRight: "unset",
                  borderLeftColor: !isRangeHovered && colorSystem.border2,
                  borderRadius: 0,
                },
                ".MuiOutlinedInput-input": {
                  paddingLeft: dense ? "68px" : "78px",
                  paddingRight: "unset",
                  textAlign: "center",

                  ...(isSm && {
                    paddingLeft: "15%",
                    paddingTop: "40px",
                  }),

                  ":focus::placeholder": {
                    color: "transparent",
                  },
                },
              }}
              inputProps={wholeNumberInputProps}
              type="number"
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              onMouseEnter={() => setIsRangeHovered(true)}
              onMouseLeave={() => setIsRangeHovered(false)}
              disabled={disabled}
            />
            <OutlinedInput
              startAdornment={
                <Box
                  sx={{
                    marginTop: isSm ? "22px" : 0,
                  }}
                >
                  -
                </Box>
              }
              value={rangeEnd}
              onChange={handleRangeChange}
              name="rangeEnd"
              placeholder="0"
              sx={{
                ...commonStyles,
                minWidth: isSm ? "50px" : "60px",
                paddingLeft: "unset",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  border: isRangeHovered && 1,
                  borderLeft: "unset",
                  borderRadius: 0,
                },
                ".MuiOutlinedInput-input": {
                  paddingLeft: "unset",
                  paddingRight: dense ? "20px" : "25px",
                  maxWidth: isSm ? "unset" : "130px",
                  textAlign: "center",

                  ...(isSm && {
                    paddingTop: "40px",
                  }),

                  ":focus::placeholder": {
                    color: "transparent",
                  },
                },
              }}
              inputProps={wholeNumberInputProps}
              type="number"
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              onMouseEnter={() => setIsRangeHovered(true)}
              onMouseLeave={() => setIsRangeHovered(false)}
              disabled={disabled}
            />
          </FormControl>
        </Box>
      </>
    </>
  );
}

export default CoachSetRangeInput;
