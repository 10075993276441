import clsx from "clsx";
import React from "react";
import { DialogContent, Button, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

import { FullScreenDialog, FullScreenDialogProps } from "./FullScreenDialog";
import LoadingButton from "../button/LoadingButton";

const useStyles = makeStyles((theme) => ({
  root: {
    '& [role="dialog"]': {
      paddingTop: theme.spacing(2.5),
    },

    '& [role="dialog"] > button': {
      top: theme.spacing(3.5),
    },

    '& [role="dialog"] > div': {
      paddingBottom: theme.spacing(3),
    },

    '& [data-dialog-title="true"] h2': {
      fontSize: 24,
      fontWeight: 600,
      marginLeft: theme.spacing(-3),
    },

    padding: theme.spacing(7, 0, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(7, 5, 0),
    },
  },

  content: {
    maxWidth: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 0,
    height: "calc(100vh - 202px)",

    [theme.breakpoints.up("md")]: {
      width: "50vw",
      height: "calc(100vh - 228px)",
    },
  },

  wrapper: {
    overflowY: "auto",
    width: "100%",
    height: "100%",
  },

  submit: {
    fontSize: 16,
    fontWeight: 700,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5),
  },
}));

export type EditorDialogClassKey =
  | "root"
  | "content"
  | "submit"
  | "wrapper"
  | "container";
export interface EditorDialogProps
  extends Omit<FullScreenDialogProps, "onSubmit"> {
  submitLabel?: React.ReactNode;
  onSubmit?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  submitDisabled?: boolean;
  // TODO_API_V2: Added `pending` as a separate prop to avoid other components error.
  // Should replace old `submitDisabled` with `submitPending` after migration
  submitPending?: boolean;
  buttonRef?: React.MutableRefObject<HTMLButtonElement>;
  classes?: Partial<ClassNameMap<EditorDialogClassKey>>;
}

export function EditorDialog(props: EditorDialogProps) {
  const {
    className,
    children,
    onSubmit,
    submitLabel = "Save",
    submitDisabled = false,
    submitPending = false,
    buttonRef,
    classes = {},
    ...other
  } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));

  return (
    <FullScreenDialog
      className={clsx(s.root, className, classes.root)}
      classes={{ container: classes.container }}
      maxWidth="lg"
      fullScreen={!mdUp}
      hideModalToolbar
      disableEnforceFocus
      {...other}
    >
      <DialogContent className={clsx(s.content, classes.content)}>
        <Box className={clsx(s.wrapper, classes.wrapper)}>{children}</Box>
        <Box
          sx={{
            paddingTop: 2,
          }}
        >
          {/*TODO_API_V2_GROUP_POSTS: Replace with loading button. Currently skipped because LoadingButton not working correctly with ref */}
          <Button
            ref={buttonRef}
            className={clsx(s.submit, classes.submit)}
            onClick={onSubmit}
            variant="contained"
            type="submit"
            children={submitLabel}
            disabled={submitDisabled}
            fullWidth
          />
        </Box>
      </DialogContent>
    </FullScreenDialog>
  );
}
