import clsx from "clsx";
import React from "react";
import { Button, Container, ContainerProps, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InfoBox } from "../../components/info/InfoBox";
import { GroupCard } from "../../components/groups/GroupCard";
import { ManageGroupDialogProps } from "../../components/groups/ManageGroupDialog";
import { Pagination } from "../../components/pagination/Pagination";
import { getStatusFromPath, PaginationContext } from "../../utils/paging";

import {
  GroupStatus,
  IPaginatedListOfGroupDto,
} from "@growth-machine-llc/stridist-api-client";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";
import art from "../../../src/icons/UndrawWorkChart.svg";
import { CreateGroupAction } from "./CreateGroupAction";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  card: {
    marginBottom: theme.spacing(3),
  },

  button: {
    fontSize: "larger",
    fontWeight: 700,
    borderRadius: 4,
    marginTop: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 15),
    },
  },

  title: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5),
    },
  },

  none: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
    },
  },
}));

export interface GroupListProps extends Omit<ContainerProps, "children"> {
  groups: IPaginatedListOfGroupDto;
}

export function GroupList(props: GroupListProps) {
  const { className, groups, ...other } = props;
  const s = useStyles();
  const { first } = React.useContext(PaginationContext);
  const status = getStatusFromPath(location.pathname);

  return (
    <Container className={clsx(s.root, className)} maxWidth="lg" {...other}>
      {groups.totalCount ? (
        <>
          {groups.items.map((group) => (
            <OptimisticUpdateContainer id={group.id}>
              <GroupCard className={s.card} key={group.id} group={group} />
            </OptimisticUpdateContainer>
          ))}
          <Pagination totalCount={groups.totalCount} perPage={first} />
        </>
      ) : status == GroupStatus.ACTIVE ? (
        <InfoBox
          image={<img src={art} alt="Group people image" />}
          className={s.none}
          title="Create a client group"
          subtitle="Organize and manage your clients efficiently."
          classes={{
            title: s.title,
          }}
        >
          <CreateGroupAction className={s.button} />
        </InfoBox>
      ) : (
        <InfoBox
          className={s.none}
          title="No archived groups found"
          subtitle="You don't have archived groups yet."
          classes={{
            title: s.title,
          }}
        />
      )}
    </Container>
  );
}
