import React, { FC, useMemo, useState, useTransition } from "react";
import {
  Box,
  Grid2,
  Button,
  Popover,
  useTheme,
  MenuItem,
  Typography,
  Select,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CalendarToday as CalendarIcon } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { bindPopover, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";

import { nutritionTargetsArray } from "../../constants";
import { colorSystem } from "../../theme";
import { BaseDialog, BaseDialogProps } from "../dialog/BaseDialog";
import { DatePicker } from "../fields/DatePicker";

import ClientAutoNutritionItem from "./ClientAutoNutritionItem";
import EmptyText from "./EmptyText";
import LastSync from "./LastSync";
import DistributionChart from "./DistributionChart";
import dayjs from "dayjs";
import {
  NutritionEntryDto,
  NutritionTargetDto,
} from "@growth-machine-llc/stridist-api-client";
import { useQuery } from "@tanstack/react-query";
import { CLIENT_NUTRITION_ENTRIES_REFETCH_QUERY_KEY } from "../client-nutrition-targets/ClientNutritionCard";
import NutritionEntriesService from "../../services/NutritionEntriesService";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: theme.breakpoints.values.slg,
  },
  dateButton: {
    padding: theme.spacing(0, 1),
    fontSize: "unset",
    fontWeight: "unset",
    textDecoration: "underline",
    textDecorationColor: theme.palette.primary.main,
  },
  selectLabel: {
    fontSize: "1rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
}));

export interface ClientAutoNutritionDetailsProps extends BaseDialogProps {
  autoNutritionMacroTarget: NutritionTargetDto[];
  autoNutritionLastSync: string;
  client: { clientId?: number; username?: string };
}

const ClientAutoNutritionDetails: FC<ClientAutoNutritionDetailsProps> = (
  props,
) => {
  const {
    autoNutritionMacroTarget,
    autoNutritionLastSync,
    children,
    client,
    ...restProps
  } = props;

  const { clientId, username } = client;

  const { data: autoNutritionEntries } = useQuery({
    queryKey: [CLIENT_NUTRITION_ENTRIES_REFETCH_QUERY_KEY, { username }],
    queryFn: () => NutritionEntriesService.getNutritionEntries(clientId),
  });

  const theme = useTheme();
  const s = useStyles(props);
  const [date, setDate] = useState(() => {
    return dayjs(new Date()).startOf("day");
  });

  const datePickerState = usePopupState({
    variant: "popover",
    popupId: "auto-nutrition-details-date",
  });

  const [currentTargetId, setCurrentTargetId] = useState<number | null>(() =>
    autoNutritionMacroTarget?.length ? autoNutritionMacroTarget[0].id : null,
  );
  const currentTarget = useMemo(() => {
    return (
      autoNutritionMacroTarget?.find(({ id }) => id === currentTargetId) || null
    );
  }, [autoNutritionEntries, currentTargetId]);

  const [isPending, startTransition] = useTransition();

  const nutritionRecordHistory = autoNutritionEntries?.map((target) => ({
    date: target.date ? target.date.format("YYYY-MM-DD") : null,
  }));

  const nutritionsOfSpecificDate = useMemo(() => {
    return autoNutritionEntries?.filter(
      (target) => target.date && target.date.isSame(date, "day"),
    );
  }, [autoNutritionEntries, date]);

  const nutritionRecord =
    nutritionsOfSpecificDate && nutritionsOfSpecificDate.length > 0
      ? nutritionsOfSpecificDate.reduce(
          (totals, entry) => {
            return Object.entries(entry).reduce((result, [key, value]) => {
              if (key in result) {
                return {
                  ...result,
                  [key]: result[key] + (+value || 0),
                };
              }
              return result;
            }, totals);
          },
          {
            calories: 0,
            carbs: 0,
            fat: 0,
            protein: 0,
            sugar: 0,
            fiber: 0,
            sodium: 0,
            alcohol: 0,
          },
        )
      : null;

  return (
    <BaseDialog
      fullWidth
      maxWidth="lg"
      PaperProps={{
        className: s.dialogPaper,
      }}
      title={
        <>
          <Grid2
            container
            sx={{
              alignItems: "center",
            }}
          >
            <span>Nutrition -&nbsp;</span>
            <Button
              startIcon={<CalendarIcon color="primary" />}
              className={s.dateButton}
              {...bindTrigger(datePickerState)}
            >
              {date.format("MMM DD, YYYY")}
            </Button>
          </Grid2>
          <LastSync value={autoNutritionLastSync} />
          <Popover
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            {...bindPopover(datePickerState)}
          >
            <DatePicker
              value={date.toDate()}
              onChange={(newDate) => {
                startTransition(() => {
                  setDate(dayjs(newDate).startOf("day"));
                });
                datePickerState.close();
              }}
              highlightDays={nutritionRecordHistory?.map(({ date }) =>
                dayjs(date).toDate(),
              )}
            />
          </Popover>
        </>
      }
      {...restProps}
    >
      <>
        {nutritionsOfSpecificDate ? (
          nutritionRecord ? (
            <>
              {!!autoNutritionMacroTarget?.length && (
                <Box
                  sx={{
                    pb: 2,
                  }}
                >
                  <Typography variant="h4" className={s.selectLabel}>
                    Nutrition target
                  </Typography>
                  <Select
                    variant="outlined"
                    value={currentTargetId || ""}
                    onChange={(event) => {
                      setCurrentTargetId(event.target.value as any);
                    }}
                  >
                    {autoNutritionMacroTarget.map(({ id, targetType }) => {
                      const label =
                        nutritionTargetsArray.find(
                          ({ value }) => value === targetType,
                        )?.label || targetType;
                      return (
                        <MenuItem key={id} value={id}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              )}
              <Grid2 container spacing={2}>
                <Grid2 size={6}>
                  <ClientAutoNutritionItem
                    value={nutritionRecord.calories}
                    targetValue={currentTarget?.calories}
                    label="Calories"
                  />
                </Grid2>
                <Grid2 size={6}>
                  <ClientAutoNutritionItem
                    value={nutritionRecord.carbs}
                    targetValue={currentTarget?.carbs}
                    label="Carbs"
                    unit="g"
                  />
                </Grid2>
                <Grid2 size={6}>
                  <ClientAutoNutritionItem
                    value={nutritionRecord.fat}
                    targetValue={currentTarget?.fat}
                    label="Fat"
                    unit="g"
                  />
                </Grid2>
                <Grid2 size={6}>
                  <ClientAutoNutritionItem
                    value={nutritionRecord.protein}
                    targetValue={currentTarget?.protein}
                    label="Protein"
                    unit="g"
                  />
                </Grid2>
              </Grid2>
              <Box
                sx={{
                  py: 4,
                }}
              >
                <Grid2 container spacing={2}>
                  <Grid2
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <DistributionChart
                      title="Macro Distribution"
                      values={[
                        {
                          value: Math.floor(nutritionRecord.protein) || 0,
                          label: "Protein eaten",
                          color: colorSystem.green2,
                        },
                        {
                          value: Math.floor(nutritionRecord.carbs) || 0,
                          label: "Carbs eaten",
                          color: theme.palette.primary.main,
                        },
                        {
                          value: Math.floor(nutritionRecord.fat) || 0,
                          label: "Fat eaten",
                          color: colorSystem.blue1,
                        },
                      ]}
                    />
                  </Grid2>
                  <Grid2
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <DistributionChart
                      title="Other nutrients eaten"
                      values={[
                        {
                          value: nutritionRecord.sugar
                            ? Math.floor(nutritionRecord.sugar) || 0
                            : 0,
                          label: "Sugars",
                          color: colorSystem.green2,
                        },
                        {
                          value: nutritionRecord.fiber
                            ? Math.floor(nutritionRecord.fiber) || 0
                            : 0,
                          label: "Dietary Fiber",
                          color: theme.palette.primary.main,
                        },
                        {
                          value: nutritionRecord.sodium
                            ? Math.floor(nutritionRecord.sodium) || 0
                            : 0,
                          label: "Sodium",
                          color: colorSystem.blue1,
                        },
                        {
                          value: nutritionRecord.alcohol
                            ? Math.floor(nutritionRecord.alcohol) || 0
                            : 0,
                          label: "Alcohol",
                          color: colorSystem.orange,
                        },
                      ]}
                    />
                  </Grid2>
                </Grid2>
              </Box>
            </>
          ) : (
            <EmptyText />
          )
        ) : (
          <Skeleton />
        )}
        {children}
      </>
    </BaseDialog>
  );
};

export default ClientAutoNutritionDetails;
