import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import dayjs from "dayjs";

export const lockComponentAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    currentState: boolean;
  }>,
) => {
  const { componentId, currentState } = action.payload;
  const component = state.value.components[componentId];
  if (!component) return;

  const newValue = !currentState;

  state.value.components[componentId] = {
    ...component,
    locked: newValue,
    updatedAt: dayjs(),
  };

  const overrides = state.value.components[componentId].overrides;
  if (overrides) {
    overrides.forEach((overrideId) => {
      state.value.components[overrideId] = {
        ...state.value.components[overrideId],
        locked: newValue,
        updatedAt: dayjs(),
      };
    });
  }
};
