import React from "react";
import { matchPath } from "react-router-dom";

import {
  CLIENT_GROUP_POST_ROUTE,
  COACH_GROUP_MEMBERS_ROUTE,
  COACH_GROUP_POST_ROUTE,
} from "../../../routes/routes";
import { GroupPostsAddButton } from "../../../components/group-posts/GroupPostsAddButton";
import { ActionButton } from "../../../components/button/ActionButton";
import { TGroupRoute } from "../../../contexts/GroupContext";
import { HeaderButton } from "../../../components/button/HeaderButton";

type GetActionForGroupParams = {
  pathname: string;
  groupRouteQuery: TGroupRoute;
  setManageGroupDialogOpen: (open: boolean) => void;
  userId: number;
};
export const getActionForGroup = ({
  pathname,
  groupRouteQuery,
  setManageGroupDialogOpen,
  userId,
}: GetActionForGroupParams) => {
  const group = groupRouteQuery;
  const canPost =
    group &&
    // Explicitly check for `false` to prevent `undefined` from being treated as `true`
    (group.membersCanNotPost === false || group?.coachId === userId);

  const canManageMembers = group?.coachId === userId;

  if (
    matchPath(COACH_GROUP_POST_ROUTE, pathname) ||
    matchPath(CLIENT_GROUP_POST_ROUTE, pathname)
  ) {
    return (
      canPost && [
        <GroupPostsAddButton groupId={group?.id} children="New post" />,
      ]
    );
  }

  if (matchPath(COACH_GROUP_MEMBERS_ROUTE, pathname)) {
    return (
      canManageMembers && [
        <HeaderButton onClick={() => setManageGroupDialogOpen(true)}>
          Manage members
        </HeaderButton>,
      ]
    );
  }
  return [<></>];
};
