import { CustomCellRendererProps } from "@ag-grid-community/react";
import React, { useEffect, useRef } from "react";
import { Icons } from "../../../plate-ui/Icons/icons";
import { alpha, Box, Button, Stack, useTheme } from "@mui/material";
import MinimizedTooltip, {
  TooltipLine,
} from "../../../tooltip/MinimizedTooltip";
import { WeekComponentMenu } from "../../../menu/WeekComponentMenu";
import { RowType } from "../../../../constants";
import { usePopupState } from "material-ui-popup-state/hooks";
import { Filters } from "../../../program/filters/ComponentTypeFilter";
import WeekWorkoutMenu from "../../../menu/WeekWorkoutMenu";
import { InsertItemComponent } from "./InsertItemComponent";
import { colorSystem } from "../../../../theme";
import { ProgramDetailsViewMode } from "../../../program/ProgramDetailsViewButton";
import { ExerciseDrawerData } from "../../utils";
import { CurriculumComponent } from "../../../../redux/types";

export interface IDragCellRendererProps {
  component: CurriculumComponent;
  day: number;
  weekId: number;
  positions: string[];
  filters: Filters;
  setExerciseDrawerData: React.Dispatch<
    React.SetStateAction<ExerciseDrawerData>
  >;
  setGridLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const iconsHoverStyling = (theme, border: boolean = true) => ({
  border: border && `1px solid ${alpha(theme.palette.common.black, 0)}`,
  color: colorSystem.gray4,
  ":hover": {
    color: alpha(theme.palette.common.black, 1),
    background: alpha(theme.palette.common.black, 0.02),
    border: border && `1px solid ${alpha(theme.palette.common.black, 0.08)}`,
  },

  transition: `${theme.transitions.create(
    ["border", "color", "background", "opacity"],
    {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    },
  )} !important`,
});

const DragCellRenderer = (
  props: CustomCellRendererProps & IDragCellRendererProps,
) => {
  const { component, day, weekId, filters, setExerciseDrawerData } = props;
  const theme = useTheme();
  const CURRENT_ROW_TYPE = props.data.rowType;

  // COMPONENT MENU
  const [componentMenuAnchorEl, setComponentMenuAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const handleOpenComponentMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    setComponentMenuAnchorEl(event.currentTarget);
  };

  const closeComponentMenu = () => {
    setComponentMenuAnchorEl(null);
  };

  // WORKOUT MENU
  const [workoutMenuAnchorEl, setWorkoutMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openComponentMenu = Boolean(workoutMenuAnchorEl);

  const handleOpenWorkoutMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setWorkoutMenuAnchorEl(event.currentTarget);
  };

  const closeWorkoutMenu = () => {
    setWorkoutMenuAnchorEl(null);
  };

  const handleDragIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    [RowType.WORKOUT_SECTION, RowType.EXERCISE].includes(CURRENT_ROW_TYPE)
      ? handleOpenWorkoutMenu(event)
      : handleOpenComponentMenu(event);
  };

  const dragHandleRef = useRef(null);
  useEffect(() => {
    props.registerRowDragger(dragHandleRef.current!);
  });

  const menuState = usePopupState({
    variant: "popover",
    popupId: "add-component-menu",
  });

  const firstLineMenuState = usePopupState({
    variant: "popover",
    popupId: "add-first-line-component-menu",
  });

  const openWorkoutDrawer = () => {
    setExerciseDrawerData({
      componentId: props.data.componentId,
      componentContent: props.data.componentContent,
      workoutSection: props.data.workoutSectionData,
      exercise: props.data.exerciseData,
    });
  };

  const componentId = props.data?.componentId;
  const workoutSectionId = props.data?.workoutSectionData?.id;
  const exerciseId = props.data?.exerciseData?.id;

  return (
    <Box className="drag-cell-renderer flex justify-center">
      <Box
        sx={{
          opacity: 0,
          ":hover": {
            opacity: 1,
          },
          transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeOut,
          }),
        }}
      >
        {props.node.rowIndex === 0 &&
          component &&
          InsertItemComponent({
            weekId: weekId,
            componentId,
            workoutSectionId,
            exerciseId,
            dayOfWeek: day,
            firstLine: true,
            iconsHoverStyling: iconsHoverStyling(theme),
            menuState,
            firstLineMenuState,
            currentRowType: CURRENT_ROW_TYPE,
            filters,
          })}
      </Box>

      <Box
        className={`flex cursor-grab h-full flex-col justify-center opacity-0 group-hover:opacity-100`}
      >
        <MinimizedTooltip
          title={
            <Stack direction="column">
              <TooltipLine>
                <b>Drag</b> to move
              </TooltipLine>
              <TooltipLine>
                <b>Click</b> to action
              </TooltipLine>
            </Stack>
          }
        >
          <Button
            onClick={handleDragIconClick}
            sx={{
              minWidth: 0,
              p: 0.4,
              m: "auto",
              pr: { xs: 0, sm: 0.4 },
              cursor: "grab",
              ...iconsHoverStyling(theme),
            }}
          >
            <div ref={dragHandleRef}>
              <Icons.dragHandle className="h-4 w-4" />
            </div>
          </Button>
        </MinimizedTooltip>
      </Box>

      {component &&
        InsertItemComponent({
          weekId: weekId,
          componentId,
          workoutSectionId,
          exerciseId,
          dayOfWeek: day,
          firstLine: false,
          iconsHoverStyling: iconsHoverStyling(theme),
          menuState,
          firstLineMenuState,
          currentRowType: CURRENT_ROW_TYPE,
          filters,
        })}

      {component && (
        <WeekComponentMenu
          anchorEl={componentMenuAnchorEl}
          componentData={component}
          open={Boolean(componentMenuAnchorEl)}
          onClose={closeComponentMenu}
          view={ProgramDetailsViewMode.SPREADSHEET}
          containerWeekId={weekId}
        />
      )}

      <WeekWorkoutMenu
        anchorEl={workoutMenuAnchorEl}
        open={openComponentMenu}
        onClose={closeWorkoutMenu}
        type={CURRENT_ROW_TYPE}
        cellData={props.data}
        handleEditExerciseClick={openWorkoutDrawer}
        weekId={component?.weekId}
        componentId={component?.id}
      />
    </Box>
  );
};
export default DragCellRenderer;
