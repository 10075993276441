import clsx from "clsx";
import React from "react";
import { Typography, TypographyProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { humanizeUserPlan, splitPlanText } from "../../utils/user";
import { planStatus, planStatusLabel } from "../../constants";
import { SubscriptionBriefDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },

  status: {
    fontSize: 20,
  },

  statusLabel: {
    padding: theme.spacing(0, 0.5),
    borderRadius: 2,
    color: theme.palette.common.white,
  },

  highlightedPlan: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.radius.md,
  },
}));

export interface CoachSettingsPlanStatusTextProps extends TypographyProps {
  plan: string;
  subscription: SubscriptionBriefDto;
}

export function CoachSettingsPlanStatusText(
  props: CoachSettingsPlanStatusTextProps,
) {
  const { className, plan, subscription, ...other } = props;
  const s = useStyles();

  const text = React.useMemo(() => {
    if (!plan) {
      return "14 days trial plan";
    }

    if (!subscription) {
      const { number, text } = splitPlanText(humanizeUserPlan(plan));
      return (
        <>
          You are on{" "}
          <span className={s.highlightedPlan}>
            {text === "unlimited" ? text : number}
          </span>{" "}
          {text === "unlimited" ? "" : text} plan
        </>
      );
    }

    if (subscription?.status === planStatus.TRIALING) {
      return "You are on Free Trial plan.";
    }

    if (subscription?.status === planStatus.ACTIVE) {
      const { number, text } = splitPlanText(humanizeUserPlan(plan));
      return (
        <>
          You are on{" "}
          <span className={s.highlightedPlan}>
            {text === "unlimited" ? text : number}
          </span>{" "}
          {text === "unlimited" ? "" : text} plan
        </>
      );
    }

    return <span className={s.status}>Your updated plan is in </span>;
  }, [subscription, plan, s.highlightedPlan, s.status]);

  const status = React.useMemo(() => {
    const color = () => {
      switch (subscription?.status) {
        case planStatus.PAST_DUE:
        case planStatus.INCOMPLETE:
          return "#F2994A";
        case planStatus.CANCELED:
        case planStatus.INCOMPLETE_EXPIRED:
        case planStatus.UNPAID:
          return "#EB5757";
        default:
          return "#ffffff";
      }
    };
    return (
      <span
        className={clsx(s.status, s.statusLabel)}
        style={{ backgroundColor: color() }}
      >
        {planStatusLabel[subscription?.status]}
      </span>
    );
  }, [subscription, s.status, s.statusLabel]);

  return (
    <Typography className={clsx(s.title, className)} variant="h2" {...other}>
      {text}
      {subscription?.status !== planStatus.ACTIVE &&
        subscription?.status !== planStatus.TRIALING &&
        status}
    </Typography>
  );
}
