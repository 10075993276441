import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useEnableColors } from "../../../hooks/useEnableColors";
import { Ellipsis } from "lucide-react";
import { X } from "lucide-react";
import { useSidebar } from "../../../contexts/CurriculumSidebarContext";
import { ComponentType } from "../../../constants";
import { getColorByComponentType } from "../../program-calendar/utils/common";
import { SIDEBAR_HEADING_HEIGHT } from "../Sidebar";
import { CurriculumComponent } from "../../../redux/types";
import { WeekComponentMenu } from "../../menu/WeekComponentMenu";
import { bindMenu } from "material-ui-popup-state";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useCurriculumViewOption } from "../../../contexts/CurriculumViewOptionsContext";

interface ICalendarSidebarTitleProps {
  title: string;
  componentSlug: string;
  type: ComponentType;
  componentData: CurriculumComponent;
}

const CalendarSidebarTitle = (props: ICalendarSidebarTitleProps) => {
  const { title, type, componentData } = props;
  const theme = useTheme();
  const enableColors = useEnableColors();
  const { closeSidebar } = useSidebar();
  const color = getColorByComponentType(type);
  const { view } = useCurriculumViewOption();

  const menuState = usePopupState({
    variant: "popover",
    popupId: "component-menu",
  });

  const IS_OVERRIDE = !!componentData?.baseComponentId;

  return (
    <>
      <Box
        sx={{
          height: SIDEBAR_HEADING_HEIGHT,
          position: "sticky",
          top: 0,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          zIndex: 1,
          display: "flex",
          borderBottom: "1px solid",
          color: theme.palette.common.white,
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.common.black,

          ...(enableColors && {
            color: theme.palette.common.white,
            borderColor: color,
            backgroundColor: color,
          }),
        }}
      >
        <IconButton
          sx={{ visibility: IS_OVERRIDE ? "hidden" : "visible" }}
          color="inherit"
          size="small"
          {...bindTrigger(menuState)}
        >
          <Ellipsis />
        </IconButton>
        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            width: "100%",
            alignContent: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {title}
        </Typography>
        <IconButton onClick={closeSidebar} color="inherit" size="small">
          <X />
        </IconButton>
      </Box>

      {componentData && (
        <WeekComponentMenu
          {...bindMenu(menuState)}
          componentData={componentData}
          view={view}
          containerWeekId={componentData?.weekId}
        />
      )}
    </>
  );
};

export default CalendarSidebarTitle;
