import { Box, IconButton, Typography } from "@mui/material";
import { Compass } from "lucide-react";
import React from "react";

export const EmptyWebBookmark = () => {
  return (
    <Box sx={{ pt: 1, display: "flex", gap: 1 }}>
      <IconButton sx={{ color: (theme) => theme.palette.text.disabled, p: 0 }}>
        <Compass />
      </IconButton>
      <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>
        Add a web bookmark
      </Typography>
    </Box>
  );
};
