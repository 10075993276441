import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FieldsGroup } from "../fields/FieldsGroup";
import { BodyWeightHeightField } from "../fields/BodyWeightHeightField";
import { LocationField } from "../fields/LocationField";
import { BirthdayField } from "../fields/BirthdayField";
import { GenderField } from "../fields/GenderField";
import { ClientSignupStep } from "../../constants";

import { UserInfo } from "../../hooks/useCurrentUser";
import { IBriefClientInfo } from "@growth-machine-llc/stridist-api-client";

import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import {
  ClientSignupField,
  ClientCompleteSignUpSchema,
  clientStepFields,
} from "../../utils/clientCompleteSignupSchema";
import { AnimatePresence, motion } from "framer-motion";
import { BackButton } from "../button/BackButton";
import { SignupClientCompletePhotoScreen } from "../screen/SignupClientCompletePhotoScreen";
import LoadingActionButton from "../button/LoadingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {},
  caretDownIcon: {
    width: 11,
  },
  textField: {},
  input: {
    paddingRight: 16,
  },
  error: {
    position: "absolute",
  },

  formHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    height: 30,
  },

  stepCounter: {
    color: theme.palette.text.secondary,
    marginLeft: "auto",
    padding: theme.spacing(1),
  },

  backButton: {
    padding: theme.spacing(1),
  },

  button: {
    marginTop: theme.spacing(4, 0),
  },

  form: {
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },

  bdayGenderStep: {
    display: "flex",
    flexDirection: "column",
  },
}));

const fadeInOut = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

interface ReactHookFormProps<T> {
  errors: FieldErrors<T>;
  setError: UseFormSetError<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  clearErrors: UseFormClearErrors<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
}

export type ClientSettings = UserInfo | IBriefClientInfo;
export interface ClientSignUpFormProps
  extends Omit<BoxProps, "disabled" | "onSubmit">,
    ReactHookFormProps<ClientCompleteSignUpSchema> {
  user: ClientSettings;
  disabled?: boolean;
  errors: FieldErrors<ClientCompleteSignUpSchema>;
  setError: UseFormSetError<ClientCompleteSignUpSchema>;
  register: UseFormRegister<ClientCompleteSignUpSchema>;
  control: Control<ClientCompleteSignUpSchema>;
  clearErrors: UseFormClearErrors<ClientCompleteSignUpSchema>;
  watch: UseFormWatch<ClientCompleteSignUpSchema>;
  setValue: UseFormSetValue<ClientCompleteSignUpSchema>;
  trigger: UseFormTrigger<ClientCompleteSignUpSchema>;
  onSubmit: React.FormEventHandler<HTMLFormElement | HTMLButtonElement>;
}

export function ClientSignUpForm(props: ClientSignUpFormProps) {
  const {
    className,
    user,
    disabled,
    onChange,
    errors,
    register,
    control,
    setValue,
    clearErrors,
    watch,
    trigger,
    onSubmit,
    ...other
  } = props;
  const s = useStyles();

  const [step, setStep] = React.useState(ClientSignupStep.NAME_LOCATION);

  const validateCurrentStep = React.useCallback(async () => {
    return await trigger(clientStepFields[step] as ClientSignupField[], {
      shouldFocus: false,
    });
  }, [step, trigger]);

  const steps = Object.values(ClientSignupStep).filter(
    (value) => typeof value === "number",
  );

  const handleNext = React.useCallback(async () => {
    const isValid = await validateCurrentStep();
    if (isValid) {
      setStep((prevStep) => {
        const currentIndex = steps.indexOf(prevStep);
        return currentIndex < steps.length - 1
          ? (steps[currentIndex + 1] as ClientSignupStep)
          : prevStep;
      });
    }
  }, [steps, validateCurrentStep]);

  const handleBack = React.useCallback(() => {
    const currentIndex = steps.indexOf(step);
    if (currentIndex > 0) {
      setStep(steps[currentIndex - 1] as ClientSignupStep);
    }
  }, [steps, setStep, step]);

  const getStepLabel = React.useCallback(() => {
    if (step === ClientSignupStep.PICTURE) {
      return "Finish";
    }

    return "Next";
  }, [step]);

  const stepIndex = steps.indexOf(step) + 1;

  const handleSubmit = React.useCallback(
    (event: React.MouseEvent<HTMLFormElement | HTMLButtonElement>) => {
      onSubmit(event);
    },
    [handleNext, step, onSubmit],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLFormElement>) => {
      if (event.key === "Enter") {
        if (step === ClientSignupStep.PICTURE) {
          const target = event.target as HTMLButtonElement;
          if (
            (target && target.getAttribute("name") === "saveButton") ||
            target.getAttribute("name") === "skipAndSaveButton"
          ) {
            onSubmit(event);
          }
        } else {
          handleNext();
        }
      }
    },
    [handleNext, step, onSubmit],
  );

  return (
    <form onSubmit={(e) => e.preventDefault()} onKeyDown={handleKeyDown}>
      <Box className={clsx(s.root, className)} {...other}>
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            key={step}
            variants={fadeInOut}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Box className={s.formHeader}>
              {step !== ClientSignupStep.NAME_LOCATION && (
                <BackButton onClick={handleBack} className={s.backButton} />
              )}
              <Box className={s.stepCounter}>
                {stepIndex}/{steps.length}
              </Box>
            </Box>
            <Box className={s.form}>
              {step === ClientSignupStep.NAME_LOCATION && (
                <>
                  <FieldsGroup
                    label="Full name"
                    error={errors.fullName?.message}
                    hideErrorIcon
                    errorClassName={s.error}
                  >
                    <TextField
                      variant="outlined"
                      name="fullName"
                      {...register("fullName")}
                      disabled={disabled}
                      placeholder={"Please add your name"}
                      fullWidth
                    />
                  </FieldsGroup>
                  <LocationField
                    disabled={disabled}
                    register={register}
                    errorMessage={errors.location?.message}
                  />
                </>
              )}

              {step === ClientSignupStep.METRICS && (
                <BodyWeightHeightField
                  disabled={disabled}
                  watch={watch}
                  errors={errors}
                  setValue={setValue}
                  trigger={trigger}
                />
              )}

              {step === ClientSignupStep.BDAY_GENDER && (
                <>
                  <Box className={s.bdayGenderStep}>
                    <GenderField
                      disabled={disabled}
                      error={errors.gender}
                      clearErrors={clearErrors}
                      control={control}
                    />
                    <BirthdayField
                      disabled={disabled}
                      errorMessage={errors.birthday?.message}
                      watch={watch}
                      control={control}
                      clearErrors={clearErrors}
                    />
                  </Box>
                </>
              )}
              {step === ClientSignupStep.PICTURE && (
                <SignupClientCompletePhotoScreen
                  user={user}
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  disabled={disabled}
                  error={errors.picture?.message}
                  handleSubmit={handleSubmit}
                />
              )}
            </Box>
            {step !== ClientSignupStep.PICTURE && (
              <>
                <LoadingActionButton
                  className={s.button}
                  onClick={handleNext}
                  size="large"
                  type="button"
                  fullWidth
                >
                  {getStepLabel()}
                </LoadingActionButton>
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
    </form>
  );
}
