import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatDateToLongString } from "../../utils/date";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  link: {
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    marginTop: theme.spacing(1),
  },

  icon: {
    fontSize: theme.typography.fontSize * 1.5,
    position: "relative",
    top: theme.spacing(-0.2),
    marginLeft: theme.spacing(0.3),
  },

  value: {
    fontSize: 14,
    fontWeight: 500,
  },

  subscriptionText: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export interface StripeCustomerInfoProps extends BoxProps {
  link?: string;
  subscriptionPeriodEnd?: string;
}

export function StripeCustomerInfo(props: StripeCustomerInfoProps) {
  const { className, link, subscriptionPeriodEnd, ...other } = props;
  const s = useStyles();

  let subscriptionMessage: string;
  if (subscriptionPeriodEnd) {
    const today = dayjs();
    const subscriptionEndDate = dayjs(subscriptionPeriodEnd, "DD.MM.YYYY");

    subscriptionMessage = subscriptionEndDate.isBefore(today)
      ? `Subscription ended on ${formatDateToLongString(subscriptionEndDate)}`
      : `Subscription ends on ${formatDateToLongString(subscriptionEndDate)}`;
  } else {
    subscriptionMessage = "Subscription not found";
  }

  return (
    <Box className={clsx(s.linkContainer, className)} {...other}>
      <Typography className={clsx(s.subscriptionText)}>
        {subscriptionMessage}
      </Typography>
      {link ? (
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx(s.link)}
        >
          <Typography className={clsx(s.value)}>Stripe Customer</Typography>
          <OpenInNewIcon className={clsx(s.icon)} />
        </Link>
      ) : (
        <Typography className={clsx(s.value)}>Customer not found</Typography>
      )}
    </Box>
  );
}
