import React, { useState } from "react";
import {
  ProgramDetailsAdminDto,
  ProgramEnrollmentDto,
} from "@growth-machine-llc/stridist-api-client";
import { makeStyles } from "@mui/styles";
import { Typography, Paper, Box, Button, Avatar } from "@mui/material";
import { format } from "date-fns";

import ComponentCard from "./ComponentCard";
import OutOfScheduleActivities from "./OutOfScheduleActivities";
import RegenerateActivitiesDialog from "./RegenerateActivitiesDialog";
import { DefaultLoader } from "../loading/DefaultLoader";
import AdminProgramMetric from "./AdminProgramMetric";
import DuplicatedComponentsAccordion from "./DuplicatedComponentsAccordion";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  groupedSectionContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  sectionHeader: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(0.5),
  },
  userCard: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  activityCountsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    maxWidth: 300,
  },
  button: {
    height: theme.spacing(5),
    marginTop: theme.spacing(5),
    padding: theme.spacing(0, 2),
  },
  scheduledWeek: {
    marginBottom: theme.spacing(3),
  },
  dayGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: theme.spacing(1),
  },
  cell: {
    border: "1px solid #ccc",
    borderRadius: theme.shape.borderRadius,
    minHeight: 80,
    width: "200px",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
}));

export interface AdminProgramActivityDetailsProps {
  program: ProgramDetailsAdminDto;
  onRegenerateActivities: (enrollmentIds: number[]) => void;
  onSyncLength: () => void;
  updating: boolean;
}

export function AdminProgramActivityDetails(
  props: AdminProgramActivityDetailsProps,
) {
  const { program, onRegenerateActivities, onSyncLength, updating } = props;
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleRegenerate = (selectedEnrollments: ProgramEnrollmentDto[]) => {
    const enrollmentIds = selectedEnrollments
      .map((enrollment) => enrollment.id)
      .filter((id): id is number => id !== undefined);

    onRegenerateActivities(enrollmentIds);
    setIsDialogOpen(false);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h2" className={classes.sectionHeader}>
        {program.name}
      </Typography>
      {/* Author Section */}
      <Box className={classes.section}>
        <Typography variant="h6" className={classes.sectionHeader}>
          Author
        </Typography>
        {program.author ? (
          <Box className={classes.userCard}>
            <Avatar
              src={program.author.photoUrl}
              alt={program.author.displayName || program.author.username}
              className={classes.smallAvatar}
            />
            <Box>
              <Typography variant="body2">
                {program.author.displayName || program.author.username}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {program.author.email}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography variant="body2">
            No author information available.
          </Typography>
        )}
      </Box>

      {updating && <DefaultLoader />}

      {/* Activity Counts and Program Info Sections */}
      <Box className={classes.groupedSectionContainer}>
        {/* Activity Counts Section */}
        <Box className={classes.section}>
          <Typography variant="h6" className={classes.sectionHeader}>
            Activity Counts
          </Typography>
          <Box className={classes.activityCountsContainer}>
            <AdminProgramMetric
              label="Enrollments"
              value={`${program.enrollmentCount ?? 0}`}
            />
            <AdminProgramMetric
              label="Actual Activity Count"
              value={`${program.actualActivityCount ?? 0}`}
            />
            <AdminProgramMetric
              label="Minimum Expected"
              value={`${program.miniumExpectedActivityCount ?? 0}`}
            />
            <AdminProgramMetric
              label="Completed"
              value={`${program.completedActivityCount ?? 0}`}
            />
            <AdminProgramMetric
              label="Displayed"
              value={`${program.displayedActivityCount ?? 0}`}
            />
            <AdminProgramMetric
              label="Enrollment Related Activities"
              value={`${program.enrollmentRelatedActivities?.length || 0}`}
            />
            <AdminProgramMetric
              label="Component Related Activities"
              value={`${program.componentRelatedActivities?.length || 0}`}
            />
            <AdminProgramMetric
              label="Activities Without Component"
              value={`${program.activitiesWithoutComponent?.length || 0}`}
            />
            <AdminProgramMetric
              label="Activities Without Enrollment"
              value={`${program.activitiesWithoutEnrollment?.length || 0}`}
            />

            <Box>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleOpenDialog}
              >
                Regenerate Activities
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Program Information Section */}
        <Box className={classes.section}>
          <Typography variant="h6" className={classes.sectionHeader}>
            Program Information
          </Typography>
          <Box className={classes.activityCountsContainer}>
            <AdminProgramMetric label="ID" value={`${program.id || "N/A"}`} />
            <AdminProgramMetric
              label="Status"
              value={`${program.status || "N/A"}`}
            />
            <AdminProgramMetric
              label="Slug"
              value={`${program.slug || "N/A"}`}
            />
            <AdminProgramMetric
              label="Name"
              value={`${program.name || "N/A"}`}
            />
            <AdminProgramMetric
              label="Length"
              value={`${program.length || "N/A"}`}
            />
            <AdminProgramMetric
              label="Weeks Count"
              value={`${program.weeksCount || "N/A"}`}
            />
            <AdminProgramMetric
              label="Legacy Entity ID"
              value={`${program.legacyEntityId || "N/A"}`}
            />
            <AdminProgramMetric
              label="Created"
              value={
                program.created
                  ? format(program.created.toDate(), "PPpp")
                  : "N/A"
              }
            />
            <AdminProgramMetric
              label="Last Modified"
              value={
                program.lastModified
                  ? format(program.lastModified.toDate(), "PPpp")
                  : "N/A"
              }
            />

            {program.weeksCount !== program.length && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={onSyncLength}
                >
                  Set Length to {program.weeksCount}
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        {/* Metadata Section */}
        <Box className={classes.section}>
          <Typography variant="h6" className={classes.sectionHeader}>
            Metadata
          </Typography>
          <Box className={classes.activityCountsContainer}>
            <AdminProgramMetric
              label="Component Duplicates Due Repeat Count"
              value={`${program.componentDuplicatesDueRepeatCount ?? 0}`}
            />
            <AdminProgramMetric
              label="Component Duplicates Same Week Count"
              value={`${program.componentDuplicatesSameWeekCount ?? 0}`}
            />
          </Box>
        </Box>
      </Box>

      {/* Component Duplicates Section */}
      <Box className={classes.section}>
        <Typography variant="h6" className={classes.sectionHeader}>
          Component Duplicates
        </Typography>

        {program.componentDuplicates.baseCount > 0 ? (
          <>
            {program.componentDuplicates.sameWeek.length > 0 && (
              <DuplicatedComponentsAccordion
                title="Same Week Duplicates"
                duplicates={program.componentDuplicates.sameWeek}
              />
            )}
            {program.componentDuplicates.dueRepeat.length > 0 && (
              <DuplicatedComponentsAccordion
                title="Due Repeat Duplicates"
                duplicates={program.componentDuplicates.dueRepeat}
              />
            )}
          </>
        ) : (
          <Typography variant="body2">
            No duplicate information available.
          </Typography>
        )}
      </Box>

      {/* Scheduled Weeks Section */}
      <Box className={classes.section}>
        {updating && <DefaultLoader />}
        <Typography variant="h6" className={classes.sectionHeader}>
          Scheduled Weeks
        </Typography>
        {program.scheduledWeeks && program.scheduledWeeks.length > 0 ? (
          program.scheduledWeeks.map((week, weekIndex) => (
            <Box key={weekIndex} className={classes.scheduledWeek}>
              <Typography variant="subtitle1" gutterBottom>
                Week {week.weekNumber} {week.id && `(ID: ${week.id})`}
              </Typography>

              {/* Out of Schedule Activities */}
              {week.outOfScheduleActivities &&
                week.outOfScheduleActivities.length > 0 && (
                  <OutOfScheduleActivities
                    activities={week.outOfScheduleActivities}
                    weekNumber={week.weekNumber}
                  />
                )}

              {/* Scheduled Components Grid */}
              <Box className={classes.dayGrid}>
                {Array.from({ length: 7 }).map((_, dayIndex) => {
                  // Filter the components for the current day
                  const scheduledComponents =
                    week.scheduledComponents?.filter(
                      (comp) => comp.day === dayIndex,
                    ) || [];

                  return (
                    <Box key={dayIndex} className={classes.cell}>
                      <Typography variant="subtitle2" gutterBottom>
                        Day {dayIndex + 1}
                      </Typography>
                      {scheduledComponents.length > 0 ? (
                        scheduledComponents.map((comp, compIndex) => (
                          <ComponentCard key={compIndex} component={comp} />
                        ))
                      ) : (
                        <Typography variant="caption">
                          No scheduled component.
                        </Typography>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ))
        ) : (
          <Typography variant="body2">No scheduled weeks available.</Typography>
        )}
      </Box>

      {/* Regenerate Activities Dialog */}
      <RegenerateActivitiesDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        enrollments={program.enrollments || []}
        onRegenerate={handleRegenerate}
      />
    </Paper>
  );
}

export default AdminProgramActivityDetails;
