import React from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CheckCheck, Slash, X } from "lucide-react";
import { CurriculumComponent } from "../../../redux/types";
import DevicePreview, { DeviceForPreview } from "../../preview/DevicePreview";

export enum PendingPreviewType {
  LATEST = "latest",
  CLIENT = "client",
}

interface IPendingDialogProps {
  open: boolean;
  handleClose: () => void;
  component: CurriculumComponent;
  handlePublish: () => void;
  lastPublishedContent: string;
  handleDiscardPendingChanges: (content: string) => void;
}

const PendingDialog = (props: IPendingDialogProps) => {
  const {
    open,
    handleClose,
    handlePublish,
    component,
    lastPublishedContent,
    handleDiscardPendingChanges,
  } = props;

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  // Preview
  const [previewType, setPreviewType] = React.useState<PendingPreviewType>(
    PendingPreviewType.LATEST,
  );
  const handleChange = (value: PendingPreviewType) => {
    setPreviewType(value);
  };

  const handleDiscardAndClose = () => {
    handleDiscardPendingChanges(lastPublishedContent);
    handleClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: theme.palette.common.white,
            borderRadius: 2,

            p: { xs: 2, sm: 2, md: 4 },
            width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" },
            maxWidth: 950,
            maxHeight: "90vh",
            overflow: "scroll",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: { xs: theme.spacing(0), sm: theme.spacing(2) },
              right: { xs: theme.spacing(0), sm: theme.spacing(2) },
              zIndex: theme.zIndex.modal,
            }}
          >
            <X />
          </IconButton>

          {/* Content wrapper */}
          <Box
            display={"flex"}
            gap={{ xs: 4, sm: 2, md: 4 }}
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* Preview section */}
            <Box order={{ xs: 2, sm: 0 }}>
              <DevicePreview
                device={DeviceForPreview.IPHONE}
                mode="component"
                component={component}
                customContent={
                  previewType === PendingPreviewType.CLIENT
                    ? lastPublishedContent
                    : component.content
                }
              />
            </Box>

            {/* Actions section */}
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              paddingTop={5}
              paddingBottom={6}
              order={{ xs: 1, sm: 0 }}
            >
              <Typography variant="h4">
                Preview your <br /> pending changes
              </Typography>

              {/* Preview toggle */}
              <ToggleButtonGroup
                value={previewType}
                onChange={(_, v) => handleChange(v)}
                orientation={isSm ? "horizontal" : "vertical"}
                color="primary"
                size="small"
                exclusive
                fullWidth
              >
                <ToggleButton value={PendingPreviewType.LATEST}>
                  Latest version
                </ToggleButton>
                <ToggleButton value={PendingPreviewType.CLIENT}>
                  Client version
                </ToggleButton>
              </ToggleButtonGroup>

              <Typography mt={"auto"}>
                You made changes to Published workout that are not yet visible
                to clients.
              </Typography>
              <Typography>
                You have an option to discard them and continue to the program
                or continue editing & publish when ready.
              </Typography>

              {/* Action buttons */}
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  rowGap={2}
                >
                  <Button variant="outlined" onClick={handleDiscardAndClose}>
                    Discard pending & exit
                  </Button>
                  <Slash
                    style={{
                      rotate: "-22deg",
                      marginInline: theme.spacing(1),
                    }}
                  />
                  <Typography
                    textAlign={"center"}
                    fontWeight={"bold"}
                    marginRight={2}
                  >
                    or
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  size="large"
                  style={{ fontSize: 20 }}
                  endIcon={<CheckCheck />}
                  onClick={handlePublish}
                >
                  Publish
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PendingDialog;
