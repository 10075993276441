import { Box, Button, Divider, useTheme } from "@mui/material";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";

interface IWeekNavigationProps {
  visibleWeeks: number[];
  handleWeeksDateRangeChange: (value: number[]) => void;
  programLength: number;
}

const WeekNavigation = (props: IWeekNavigationProps) => {
  const { visibleWeeks, handleWeeksDateRangeChange, programLength } = props;
  const theme = useTheme();

  const handlePrevClick = () => {
    handleWeeksDateRangeChange(visibleWeeks.map((w) => w - 1));
  };

  const handleNextClick = () => {
    handleWeeksDateRangeChange(visibleWeeks.map((w) => w + 1));
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          bottom: theme.spacing(3),
          justifyContent: "center",
        }}
      >
        <Button
          startIcon={<ChevronLeft />}
          disabled={Math.min(...visibleWeeks) === 1}
          onClick={handlePrevClick}
          sx={{ fontWeight: "bold" }}
        >
          Prev
        </Button>
        <Divider
          flexItem
          orientation="vertical"
          sx={{ m: 1, borderColor: theme.palette.primary.main }}
        />
        <Button
          endIcon={<ChevronRight />}
          disabled={Math.max(...visibleWeeks) === programLength}
          onClick={handleNextClick}
          sx={{ fontWeight: "bold" }}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default WeekNavigation;
