import React from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
  MenuProps,
} from "@mui/material";
import clsx from "clsx";
import {
  BodypartType,
  MovementPatternType,
} from "@growth-machine-llc/stridist-api-client";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  label: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: 16,
    margin: theme.spacing(2.5, 0, 2, 0),
  },

  select: {
    backgroundColor: theme.palette.background.paper,
    ...filterInputHeightProps,
  },
  viewList: {
    padding: theme.spacing(1),
  },
}));

export const filterInputHeightProps = {
  minHeight: 40,
  height: 40,
};

const defaultMenuProps = {
  anchorOrigin: { vertical: "center" as const, horizontal: "left" as const },
  transformOrigin: {
    vertical: "center" as const,
    horizontal: "right" as const,
  },
  classes: { list: "viewList" },
  sx: { zIndex: 99999, maxHeight: 300 },
};

interface CategorySelectProps {
  label: string;
  value: string | number;
  onChange: (
    event: SelectChangeEvent<BodypartType | MovementPatternType>,
    child: React.ReactNode,
  ) => void;
  options: string[] | number[];
  labelMap: Record<string, string>;
  classes?: Partial<Record<"label" | "select" | "container", string>>;
  menuProps?: Partial<MenuProps>;
  disabled?: boolean;
}

const CategorySelect = (props: CategorySelectProps) => {
  const {
    label,
    value,
    onChange,
    options,
    labelMap,
    classes,
    menuProps,
    disabled,
  } = props;
  const s = useStyles();
  return (
    <Box className={classes?.container}>
      <Typography variant="h6" className={clsx(s.label, classes?.label)}>
        {label}
      </Typography>
      <Select
        className={clsx(s.select)}
        variant="outlined"
        MenuProps={menuProps ?? defaultMenuProps}
        value={value}
        onChange={(event, child) =>
          onChange(
            event as SelectChangeEvent<BodypartType | MovementPatternType>,
            child,
          )
        }
        displayEmpty
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemText primary={labelMap[option as string]} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
export default CategorySelect;
