import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ReactTimePicker, {
  TimePickerProps as ReactTimePickerProps,
} from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment"; //The only allowed use of moment to keep the underlying picker working

const useStyles = makeStyles((theme) => ({
  root: {
    "& > input": {
      width: theme.spacing(7.5),
      fontSize: 16,
      fontWeight: 500,
      border: 0,
      outline: 0,
      color: theme.palette.text.primary,
      fontFamily: "'Montserrat', sans-serif",
      background: "transparent",
      paddingLeft: 1,
      paddingRight: 0,
      paddingBottom: theme.spacing(1),
    },

    "& .rc-time-picker-clear": {
      display: "none",
    },
  },

  timePickerPopup: {
    zIndex: "9999 !important" as any,

    "& .rc-time-picker-panel-input-wrap": {
      display: "none",
    },

    "& .rc-time-picker-panel-inner": {
      marginTop: theme.spacing(4),
    },
  },
}));

const format = "HH:mm";

const timeToMoment = (time: string) => (time ? moment(time, format) : null);

const momentToTime = (time: moment.Moment) =>
  time ? time.format(format) : null;

export interface TimePickerProps
  extends Omit<ReactTimePickerProps, "value" | "defaultValue" | "onChange"> {
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

//TODO: replace underlying rc-time-picker to avoid using momentjs
export const TimePicker = (props: TimePickerProps) => {
  const { className, popupClassName, value, defaultValue, onChange, ...other } =
    props;
  const s = useStyles();

  const handleChange = React.useCallback(
    (time: moment.Moment) => {
      if (onChange) {
        onChange(momentToTime(time));
      }
    },
    [onChange],
  );

  return (
    <ReactTimePicker
      className={clsx(s.root, className)}
      popupClassName={clsx(s.timePickerPopup, popupClassName)}
      showSecond={false}
      clearIcon={false}
      use12Hours={false}
      value={timeToMoment(value)}
      defaultValue={timeToMoment(defaultValue)}
      onChange={handleChange}
      {...other}
    />
  );
};
