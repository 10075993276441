import { Dialog, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { Suspense, useEffect } from "react";
import { CommonElementId } from "../../constants";
import { CoachProgramComponent } from "../../routes/coach/programs/preview/component/CoachProgramComponentPreviewRoute";
import { useSearchParams } from "react-router-dom";
import { useDialog } from "../../contexts/CurriculumComponentDialogContext";
import ComponentDialogSkeleton from "../skeletons/ComponentDialogSkeleton";

export const ComponentDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ComponentDialog = () => {
  const { closeDialog, isOpen, componentSlug } = useDialog();
  const [searchParams, _] = useSearchParams();
  const componentSearchParam = searchParams.get("component");

  useEffect(() => {
    !componentSearchParam && closeDialog();
  }, [componentSearchParam]);

  return (
    <Dialog
      id={CommonElementId.COMPONENT_DIALOG}
      open={isOpen}
      TransitionComponent={ComponentDialogTransition}
      onClose={closeDialog}
      keepMounted
      hideBackdrop
      PaperProps={{
        sx: {
          width: "100vw",
          height: "100vh",
          maxHeight: "unset",
          maxWidth: "unset",
          margin: "unset",
        },
      }}
    >
      <Suspense fallback={<ComponentDialogSkeleton backClick={closeDialog} />}>
        <CoachProgramComponent
          key={componentSlug}
          componentSlugProp={componentSlug}
        />
      </Suspense>
    </Dialog>
  );
};

export default ComponentDialog;
