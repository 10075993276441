import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  IconButton,
  Theme,
  alpha,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

type StyleProps = {
  disabled: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    columnGap: theme.spacing(3),

    [theme.breakpoints.down("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(6, 1fr)",
    },

    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      columnGap: theme.spacing(2),
    },
  },
}));

export const INIT_EMOJI_VALUES = ["☹️", "🙁", "😐", "🙂", "😃"];

const EmojiStyles = (theme: Theme, editable?: boolean, selected?: boolean) => {
  return {
    // backgroundImage: `radial-gradient(${theme.palette.grey[400]} 1px, transparent 1px)`,
    backgroundColor: theme.palette.grey[100],
    ":hover": {
      cursor: "pointer",
      ...(!editable && {
        backgroundColor: alpha(theme.palette.primary.light, 0.3),
      }),
    },
    ...(selected && {
      backgroundColor: alpha(theme.palette.primary.light, 0.5),
      ":hover": {
        backgroundColor: alpha(theme.palette.primary.light, 0.5),
      },
    }),
  };
};

export interface EmojiRatingProps extends Omit<BoxProps, "onSelect"> {
  value?: string;
  options?: string[];
  onSelect?: (value: number) => void;
  onDelete?: (value: number) => void;
  disabled?: boolean;
  editable?: boolean;
}

export function EmojiRating(props: EmojiRatingProps) {
  const {
    className,
    value,
    onSelect,
    onDelete,
    disabled = false,
    options = INIT_EMOJI_VALUES,
    editable = false,
    ...other
  } = props;

  const s = useStyles();

  const handleEmojiClick = React.useCallback(
    (index) => {
      if (onSelect) {
        onSelect(index);
      }
    },
    [onSelect, options],
  );

  const handleEmojiDelete = React.useCallback(
    (index: number) => {
      if (onDelete) {
        onDelete(index);
      }
    },
    [onDelete, options],
  );

  const theme = useTheme();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {options.map((emoji, index) => (
        <Box
          key={index}
          onClick={() => (disabled ? undefined : handleEmojiClick(index))}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: 45, sm: 65, "@xs": 45, "@sm": 65 },
            position: "relative",
            marginBlock: 1,
            aspectRatio: 1,
            fontSize: { xs: 35, sm: 45, "@xs": 35, "@sm": 45 },
            borderRadius: "100%",
            backgroundSize: "4px 4px",
            ...EmojiStyles(theme, editable, value === emoji),

            ...(disabled && {
              pointerEvents: "none",
              filter: (theme) => theme.filters.disabled,
            }),
          }}
        >
          {editable && (
            <RemoveCircleOutlineIcon
              onClick={() => handleEmojiDelete(index)}
              sx={{
                color: theme.palette.grey[600],
                position: "absolute",
                top: -5,
                right: -5,
                ":hover": {
                  cursor: "pointer",
                  color: theme.palette.grey[800],
                },
              }}
            />
          )}
          {emoji}
        </Box>
      ))}
    </Box>
  );
}
