import { NormalizedCurriculum } from "./curriculum/curriculum-slice";
import { TempIdsMap } from "./api/api-slice";
import {
  NormalizedCurriculumComponent,
  NormalizedCurriculumWeek,
} from "./types";

export const SLICE_NAMES = {
  CURRICULUM: "curriculum",
  API: "api",
};

export enum AsyncStatus {
  /**
   * The request has not started yet.
   * This is the default or initial state.
   */
  Idle = "idle",

  /**
   * The request is in progress.
   * Indicates that the operation has started but has not yet finished.
   */
  Pending = "pending",

  /**
   * The request was successful.
   * Data has been successfully retrieved or the operation was completed.
   */
  Succeeded = "succeeded",

  /**
   * The request failed.
   * There may be an error message or failure details.
   */
  Failed = "failed",
}

export interface AsyncState<T> {
  value: T;
  status: AsyncStatus;
  error: string | null;
}

export const createInitAsyncState = <T>(value: T): AsyncState<T> => {
  return {
    value,
    status: AsyncStatus.Idle,
    error: null,
  };
};

export const handlePending = <T>(state: AsyncState<T>) => {
  state.status = AsyncStatus.Pending;
  state.error = null;
};

export const handleRejected = <T>(state: AsyncState<T>, action) => {
  state.status = AsyncStatus.Failed;
  state.error = action.error.message ?? "Unknown Error";
};

export const handleFulfilled = <T>(state: AsyncState<T>) => {
  state.status = AsyncStatus.Succeeded;
  state.error = null;
};

export type IdSelector = (id: number, map: TempIdsMap) => number | undefined;
export type CheckedIdSelector = (id: number, map: TempIdsMap) => number;

export const getRealId: IdSelector = (id, map) => map[id];

export const getTempId: IdSelector = (id, map) => {
  const entry = Object.entries(map).find(([tempId, realId]) => realId === id);
  return entry ? Number(entry[0]) : undefined;
};

export const getCheckedRealId: CheckedIdSelector = (id, map) =>
  getRealId(id, map) ?? id;
export const getTempIdIfExists: CheckedIdSelector = (id, map) =>
  getTempId(id, map) ?? id;

export const replaceCurriculumIds = (
  curriculum: NormalizedCurriculum,
  weekMap: TempIdsMap,
  componentMap: TempIdsMap,
  idSelector: CheckedIdSelector,
): NormalizedCurriculum => {
  const newCurriculum = { ...curriculum };
  const weeks = Object.values(newCurriculum.weeks).map((week) => {
    const newWeek = { ...week };
    newWeek.id = idSelector(week.id, weekMap);
    newWeek.components = week.components.map((id) => {
      return idSelector(id, componentMap);
    });
    newWeek.positions = week.positions.map((id) => {
      return idSelector(Number(id), componentMap).toString();
    });
    return newWeek;
  });
  const components = Object.values(newCurriculum.components).map(
    (component) => {
      const newComponent = { ...component };
      newComponent.id = idSelector(component.id, componentMap);
      newComponent.baseComponentId = idSelector(
        component.baseComponentId,
        componentMap,
      );
      newComponent.weekId = idSelector(component.weekId, weekMap);
      return newComponent;
    },
  );

  const weekRecords: Record<number, NormalizedCurriculumWeek> = weeks.reduce(
    (acc, week) => {
      acc[week.id] = week;
      return acc;
    },
    {},
  );
  const componentRecords: Record<number, NormalizedCurriculumComponent> =
    components.reduce((acc, component) => {
      acc[component.id] = component;
      return acc;
    }, {});

  const program = {
    ...newCurriculum.program,
    weeks: newCurriculum.program.weeks.map((wId) => idSelector(wId, weekMap)),
  };

  newCurriculum.components = componentRecords;
  newCurriculum.weeks = weekRecords;
  newCurriculum.program = program;
  return newCurriculum;
};

type ReplaceIdArgs = [
  curriculum: NormalizedCurriculum,
  weekMap: TempIdsMap,
  componentMap: TempIdsMap,
];

export const replaceCurriculumIdsWithTempIds = (
  ...args: ReplaceIdArgs
): NormalizedCurriculum => {
  return replaceCurriculumIds(...args, getTempIdIfExists);
};

export const replaceCurriculumIdsWithRealIds = (
  ...args: ReplaceIdArgs
): NormalizedCurriculum => {
  return replaceCurriculumIds(...args, getCheckedRealId);
};
