import React from "react";
import { Box, Button, IconButton, Skeleton, useTheme } from "@mui/material";
import { CircleX } from "lucide-react";

interface ICoachComponentBarLoader {
  backClick: () => void;
}

const CoachComponentBarLoader = ({ backClick }: ICoachComponentBarLoader) => {
  const theme = useTheme();

  const StyledIconButton = () => {
    return (
      <IconButton
        size="large"
        disabled
        sx={{
          "&.Mui-disabled": {
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.quote,
            opacity: 0.4,
            padding: theme.spacing(1),
            height: 44,
            width: 44,
          },
        }}
      >
        <CircleX size={30} color="transparent" />
      </IconButton>
    );
  };

  return (
    <>
      <Box
        sx={{
          minHeight: { sm: 112, md: 76 },
          borderBottom: 1,
          borderColor: theme.palette.quote,
          display: "flex",
          width: "100%",
          alignItems: "center",
          pl: 3,
          pr: 3,
          flexWrap: { sm: "wrap", md: "unset" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <IconButton
            children={<CircleX size={30} />}
            size="large"
            sx={{ color: theme.palette.common.black, mr: 1 }}
            onClick={backClick}
          />
          <Box
            sx={{
              width: 1,
            }}
          >
            <Skeleton width={"25%"} />
            <Skeleton width={"15%"} />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            width: 1,
            padding: { sm: "6px 16px", md: "unset" },
            justifyContent: "end",
          }}
        >
          <Button
            disabled
            color="primary"
            variant="contained"
            size="large"
            sx={{ minWidth: 120, marginRight: { sm: "auto", md: "unset" } }}
          />
          <StyledIconButton />
          <StyledIconButton />
          <StyledIconButton />
        </Box>
      </Box>
    </>
  );
};

export default CoachComponentBarLoader;
