import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { Card, CardProps, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { TextButton } from "../button/TextButton";
import { useAnalytics } from "../../hooks/useAnalytics";

import { CardMedia } from "./CardMedia";
import { useNavigate } from "react-router-dom";
import { COACH_PROGRAM_CURRICULUM_ROUTE } from "../../routes/routes";
import {
  IUseProgramTemplateCommand,
  ProgramTemplateCategory,
  ProgramTemplateDto,
  ProgramTemplatesVm,
} from "@growth-machine-llc/stridist-api-client";
import ProgramTemplatesService from "../../services/ProgramTemplatesService";
import { useQueryClient } from "@tanstack/react-query";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../coach-programs/CoachProgramsListScreen";
import { PROGRAM_TEMPLATES_QUERY_KEY } from "../screen/ProgramTemplatesScreen";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  media: {
    width: "100%",
    height: 141,
    flexShrink: 0,
  },
  content: {
    margin: theme.spacing(2, 2, 1),
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
    height: 155,
  },
  category: {
    textTransform: "uppercase",
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.secondary.main}74`,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0, 0.5, 0.5, 0),
    fontSize: 14,
    fontWeight: 700,
    position: "absolute",
    left: 0,
    top: theme.spacing(3.5),
  },
  button: {
    margin: theme.spacing("auto", 2, 2),
    backgroundColor: theme.palette.common.white,
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.4,
    paddingBottom: theme.spacing(0.2),
    color: theme.palette.secondary.main,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  description: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    flex: 1,
    fontSize: 14,
  },
}));

export interface CardProgramTemplateProps extends CardProps {
  template: ProgramTemplateDto;
}

export function CardProgramTemplate(props: CardProgramTemplateProps) {
  const { className, template, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [trackEvent] = useAnalytics();
  const queryClient = useQueryClient();
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLDivElement | null>(null);
  const containerContentRef = useRef<HTMLDivElement | null>(null);

  const [lines, setLines] = useState<number>(0);

  const calculateLines = () => {
    if (titleRef.current && descriptionRef.current) {
      const containerHeight = containerContentRef.current.clientHeight - 20;

      const lineHeight = parseInt(
        window.getComputedStyle(descriptionRef.current).lineHeight,
        10,
      );

      const availableHeight = containerHeight - titleRef.current.clientHeight;

      const visibleLines = Math.floor(availableHeight / lineHeight);
      setLines(visibleLines);
    }
  };

  useEffect(() => {
    calculateLines();
    window.addEventListener("resize", calculateLines);
    return () => {
      window.removeEventListener("resize", calculateLines);
    };
  }, []);

  const { mutate: createProgram } = useOptimisticUpdateMutation({
    queryKey: [PROGRAM_TEMPLATES_QUERY_KEY],
    mutationFn: ProgramTemplatesService.useProgramTemplate,
    optimisticUpdater: {
      updateFn: (
        oldData: ProgramTemplatesVm,
        newData: IUseProgramTemplateCommand,
      ) => {
        if (!oldData || !oldData.programTemplates) return oldData;

        const updatedData = ProgramTemplatesVm.fromJS({
          ...oldData,
          programTemplates: oldData.programTemplates.map((template) =>
            template.id === newData.id
              ? { ...template, used: (template.used ?? 0) + 1 }
              : template,
          ),
        });

        return updatedData;
      },
    },
    successToastMessage: "Successfully created new program using template.",
    options: {
      onSuccess: (slug) => {
        queryClient.invalidateQueries({
          queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
        });

        trackEvent("Coach - Use Program Template", {
          name,
          templateId,
        });
      },
    },
  });

  const {
    id: templateId,
    image: imageURL,
    name,
    description,
    length: programLength,
    category,
    used,
  } = template;

  const handleClick = React.useCallback(
    (event) => {
      event.preventDefault();
      createProgram(
        { id: templateId },
        {
          onSuccess: (slug) => {
            navigate(COACH_PROGRAM_CURRICULUM_ROUTE.replace(":slug", slug));
          },
        },
      );
    },
    [createProgram, templateId, navigate],
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <CardMedia className={s.media} image={imageURL} />
      {category && category != ProgramTemplateCategory.NONE && (
        <Typography className={s.category}>{category}</Typography>
      )}
      <Box className={s.content} ref={containerContentRef}>
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography className={s.title} variant="subtitle1" ref={titleRef}>
            {name}
          </Typography>
          <Typography className={s.subtitle}>
            {programLength} week{programLength > 1 && "s"}
            {" • "}
            Used {used} time{used > 1 && "s"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "hidden",
              maxHeight: `${lines * 24}px`,
            }}
            ref={descriptionRef}
          >
            <Typography
              className={s.description}
              style={{
                WebkitLineClamp: lines,
              }}
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </Box>
      <TextButton className={s.button} onClick={handleClick}>
        Use template
      </TextButton>
    </Card>
  );
}
