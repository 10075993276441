import React from "react";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { ProgramsTable } from "../../../components/admin/ProgramsTable";

export function AdminProgramsRoute() {
  return (
    <>
      <ProgramsTable />
      <TrackInfoTool
        trackInfo={{
          name: "Admin - Programs",
        }}
      />
    </>
  );
}
