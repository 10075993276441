import React from "react";
import clsx from "clsx";
import { Box, BoxProps, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useAnalytics } from "../../hooks/useAnalytics";
import { Link } from "../link/Link";
import { AcceptClientInviteInvalidReason } from "../../constants";
import { colorSystem } from "../../theme";
import { AppLogo } from "../app/AppLogo";

import { AuthButton } from "./AuthButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { useLogout } from "../../hooks/useLogout";
import { useQueryClient } from "@tanstack/react-query";
import { LOGIN_ROUTE } from "../../routes/routes";
import { SendUsMessageLink } from "../app/SendUsMessageLink";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },

  wrapper: {
    height: "100vh",
    display: "flex",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
    margin: theme.spacing(4, 0, 1.5),
    textAlign: "center",
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },

  button: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5, 8),
    fontSize: 16
  },

  split: {
    border: 0,
    borderTop: `1px solid ${theme.palette.border.primary}`,
    margin: theme.spacing(2, 0, 3),
    width: "100%",
  },

  notFoundText: {
    fontSize: 16,
    color: theme.palette.common.black,
  },
}));

export interface ClientAcceptInviteInvalidProps extends BoxProps {
  reason: AcceptClientInviteInvalidReason;
  title?: string;
  onLogout?: () => void;
}

export function ClientAcceptInviteInvalid(
  props: ClientAcceptInviteInvalidProps,
) {
  const { className, reason, title, onLogout, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const signOut = useLogout({ immediate: true });
  const [trackEvent] = useAnalytics();
  const location = useLocation();
  const handleLogOut = React.useCallback(() => {
    const returnUrl = location.pathname + location.search;

    signOut(() => {
      navigate(returnUrl);
      onLogout && onLogout();
    });
  }, [signOut, trackEvent]);

  const content = React.useMemo(() => {
    switch (reason) {
      case AcceptClientInviteInvalidReason.ANOTHER_USER_LOGGED:
        return (
          <>
            <Typography className={s.text}>
              You’ll need to log out of {brandName} before you can accept an
              invitation.
            </Typography>

            <AuthButton
              className={s.button}
              onClick={handleLogOut}
              children="Log Out"
              fullWidth={false}
            />
          </>
        );
      case AcceptClientInviteInvalidReason.INVITATION_REVOKED:
        return (
          <>
            <Typography className={s.text}>
              Sorry, but it looks like that invitation has been revoked.
            </Typography>
            <Typography className={s.text}>
              Contact your coach if you think this was a mistake.
            </Typography>
          </>
        );
      case AcceptClientInviteInvalidReason.LINK_EXPIRED:
        return (
          <>
            <Typography className={s.text}>
              Please contact your coach to send you a new confirmation email,{" "}
              <br />
              or check your inbox for a more recent email.
            </Typography>

            <Button
              className={s.button}
              variant="contained"
              children="Back"
              fullWidth={false}
              onClick={() => {
                navigate(LOGIN_ROUTE);
              }}
            />
          </>
        );
      case AcceptClientInviteInvalidReason.INVITATION_ACCEPTED:
      default:
        return (
          <>
            <Typography className={s.text}>
              Sorry, but it looks like that invitation has already been
              accepted.
            </Typography>
            <Typography className={s.text}>
              Did you mean to <Link href="/login">sign in</Link> instead?
            </Typography>
          </>
        );
    }
  }, [handleLogOut, reason, s.button, s.text]);

  return (
    <Box className={s.wrapper}>
      <Box className={clsx(s.root, className)} {...other}>
        <AppLogo full width={160} height={37} />

        <Typography className={s.title} variant="h1">
          {title || "Oops, that didn’t work"}
        </Typography>

        {content}

        <hr className={s.split} />
        <Typography className={s.notFoundText}>
          Need help? <SendUsMessageLink />
        </Typography>
      </Box>
    </Box>
  );
}
