import clsx from "clsx";
import React from "react";
import { DialogProps, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";

import { BaseDialog } from "./BaseDialog";
import { BaseInviteCode } from "@growth-machine-llc/stridist-api-client";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { AppLogo } from "../app/AppLogo";
import { InviteCodeDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    maxWidth: 650,
    width: 650,
  },

  title: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
  },

  cards: {
    display: "flex",
    margin: theme.spacing(4, 0, 2),

    "& > div": {
      borderRadius: theme.spacing(1),
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.quote,
      padding: theme.spacing(3, 4),
      height: theme.spacing(22.5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    "& > div:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
  },

  logo: {
    flexShrink: 0,
    margin: theme.spacing(2.5),
    padding: theme.spacing(0.5),
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[5],
  },

  code: {
    fontSize: 24,
    fontWeight: 600,
    backgroundColor: colorSystem.blue4,
    padding: theme.spacing(2, 6),
    margin: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    letterSpacing: theme.spacing(0.5),
  },
}));

export interface InviteCodePreviewDialogProps extends DialogProps {
  inviteCode: BaseInviteCode;
}

export function InviteCodePreviewDialog(props: InviteCodePreviewDialogProps) {
  const { className, inviteCode, ...other } = props;
  const s = useStyles();
  const { brandName, isBrandedWorkspace } = useCurrentBrand();

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title="Share your invite code with clients"
      classes={{
        paper: s.paper,
      }}
      {...other}
    >
      <Box className={s.cards}>
        <Box>
          <Typography className={s.text}>
            Download the <strong>{brandName}</strong> for clients app.
          </Typography>

          <AppLogo main={!isBrandedWorkspace} color className={s.logo} />
        </Box>

        <Box>
          <Typography className={s.text}>
            Tap “I have an invite code” and enter:
          </Typography>
          <Typography className={s.code}>
            {inviteCode.code.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    </BaseDialog>
  );
}
