import React, { ReactNode } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en-gb";
import "dayjs/locale/en";

import { useUserHourCycle } from "../hooks/useUserHourCycle";

export const MuiDayjsLocalizationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const hourCycle = useUserHourCycle();
  const locale = hourCycle === "12" ? "en" : "en-gb";

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};
