"use client";

import React from "react";
import { cn, withRef } from "@udecode/cn";
import { PlateElement } from "@udecode/plate-common";
import { Box, Typography } from "@mui/material";

export const BlockquoteElement = withRef<typeof PlateElement>(
  ({ className, children, ...props }, ref) => {
    return (
      <PlateElement
        ref={ref}
        asChild
        className={cn("my-1 border-l-2 pl-6 italic", className)}
        {...props}
      >
        <blockquote>
          <Typography
            component="span"
            sx={{
              fontWeight: 400,
              fontSize: 21,
            }}
          >
            <Box
              component="p"
              sx={{
                display: "block",
              }}
            >
              {children}
            </Box>
          </Typography>
        </blockquote>
      </PlateElement>
    );
  },
);
