import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  Box,
  Typography,
  Button,
  CardContent,
  CardActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import {
  usePopupState,
  bindMenu,
  bindTrigger,
} from "material-ui-popup-state/hooks";
import { colorSystem } from "../../theme";

import { StackedClientsAvatars } from "../coach-clients/StackedClientsAvatars";
import { ListMenu } from "../menu/ListMenu";

import { ManageGroupDialog } from "./ManageGroupDialog";
import { GroupNameDialog } from "./GroupNameDialog";
import { useDeleteGroupMutation } from "./mutations/DeleteGroup";
import { useUpdateGroupArchivedMutation } from "./mutations/UpdateGroupArchived";
import { useNavigate } from "react-router-dom";
import { COACH_GROUP_POST_ROUTE } from "../../routes/routes";
import { GroupDto2 } from "@growth-machine-llc/stridist-api-client";

const useStyles = (archived: boolean) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(4, 3),
    },

    content: {
      padding: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexGrow: 1,
      cursor: archived ? "default" : "pointer",
    },

    left: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },

    name: {
      fontSize: 24,
      fontWeight: 600,
      color: theme.palette.common.black,
      wordBreak: "break-word",
    },

    count: {
      fontSize: 16,
      color: theme.palette.text.secondary,
    },

    right: {
      display: "flex",
      alignItems: "center",
    },

    actions: {
      padding: 0,
    },

    menuButton: {
      minWidth: theme.spacing(5.5),
      width: theme.spacing(5.5),
      height: theme.spacing(5.5),
      marginLeft: theme.spacing(3),
      backgroundColor: colorSystem.secondaryGray,
    },
  }));

export interface GroupCardProps extends CardProps {
  group: GroupDto2;
}

export function GroupCard(props: GroupCardProps) {
  const navigate = useNavigate();
  const { className, group, ...other } = props;
  const s = useStyles(group.archived)();

  const { name, slug, members: clients } = group;
  const id = group.id;

  const { mutate: deleteGroup, isPending: deleteInFlight } =
    useDeleteGroupMutation();
  const {
    mutate: updateGroupArchived,
    isPending: updateGroupArchivedInFlight,
  } = useUpdateGroupArchivedMutation();
  const menuState = usePopupState({
    variant: "popover",
    popupId: "groupMenu",
  });

  const triggerProps = React.useMemo(() => bindTrigger(menuState), [menuState]);
  const menuProps = React.useMemo(() => bindMenu(menuState), [menuState]);
  const [manageGroupDialogOpen, setManageGroupDialogOpen] =
    React.useState(false);
  const [groupNameDialogOpen, setGroupNameDialogOpen] = React.useState(false);

  const handleContentClick = React.useCallback(() => {
    navigate(COACH_GROUP_POST_ROUTE.replace(":slug", slug), {
      state: { title: name },
    });
  }, [slug]);

  const handleCloseManageGroupDialog = React.useCallback(() => {
    setManageGroupDialogOpen(false);
  }, []);

  const handleCloseGroupNameDialog = React.useCallback(() => {
    setGroupNameDialogOpen(false);
  }, []);

  const handleManageClick = React.useCallback(() => {
    menuProps.onClose();
    setManageGroupDialogOpen(true);
  }, [menuProps]);

  const handleRenameClick = React.useCallback(() => {
    menuProps.onClose();
    setGroupNameDialogOpen(true);
  }, [menuProps]);

  const handleDeleteClick = React.useCallback(() => {
    deleteGroup(id);
  }, [deleteGroup, id]);

  const handleUpdateArchivedClick = React.useCallback(() => {
    updateGroupArchived({
      id,
      archived: !group.archived,
    });
  }, [group.archived, id, updateGroupArchived]);

  const menuOptions = React.useMemo(
    () =>
      group.archived
        ? [
            { label: "Restore group", onClick: handleUpdateArchivedClick },
            { label: "Delete group", onClick: handleDeleteClick },
          ]
        : [
            { label: "Manage clients", onClick: handleManageClick },
            { label: "Rename group", onClick: handleRenameClick },

            { label: "Archive group", onClick: handleUpdateArchivedClick },
          ],
    [
      group.archived,
      handleDeleteClick,
      handleManageClick,
      handleRenameClick,
      handleUpdateArchivedClick,
    ],
  );

  return (
    <>
      <Card className={clsx(s.root, className)} {...other}>
        <CardContent
          className={s.content}
          onClick={!group.archived ? handleContentClick : undefined}
        >
          <Box className={s.left}>
            <Typography className={s.name} variant="h5" children={name} />
            <Typography
              className={s.count}
              variant="body1"
              children={`${clients.length} member${
                clients.length !== 1 ? "s" : ""
              }`}
            />
          </Box>
          <Box className={s.right}>
            <StackedClientsAvatars clients={clients} />
          </Box>
        </CardContent>

        <CardActions className={s.actions}>
          <Button
            {...triggerProps}
            className={s.menuButton}
            children={<MoreHoriz color="secondary" />}
          />
          <ListMenu
            {...menuProps}
            options={menuOptions}
            disabled={deleteInFlight || updateGroupArchivedInFlight}
          />
        </CardActions>
      </Card>
      {manageGroupDialogOpen && (
        <ManageGroupDialog
          open={true}
          onClose={handleCloseManageGroupDialog}
          group={group}
        />
      )}

      {groupNameDialogOpen && (
        <GroupNameDialog
          open={true}
          onClose={handleCloseGroupNameDialog}
          group={group}
        />
      )}
    </>
  );
}
