import React, { useState } from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  TElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { Box, Fade, IconButton, Typography } from "@mui/material";
import { EmbedElementType, MoreMenu } from "./MoreMenu";
import { Compass } from "lucide-react";
import { EmbedUrlDialog } from "../modals/EmbedUrlDialog";
import { useReadOnly } from "../hooks";

export const EMBED_WEBSITE = "embed_website";

interface IMediaEmbedProps {
  element: TElement;
  handleChange: any;
  handleRemoveNode: any;
}

export const MediaEmbed = ({
  element,
  handleChange,
  handleRemoveNode,
}: IMediaEmbedProps) => {
  const empty = !element.url;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const readOnly = useReadOnly();

  const handleClick = () => {
    if (!readOnly && !openDialog) {
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    if (openDialog) {
      setOpenDialog(false);
    }
  };

  if (empty && readOnly) return <></>;

  return (
    <Box
      display={"flex"}
      gap={1.5}
      sx={{
        border: empty ? "1px solid" : "none",
        borderRadius: 1,
        padding: 2.5,
        pt: 1.4,
        px: 0,
        display: "flex",
        backgroundColor: (theme) =>
          empty ? theme.palette.selected.light : null,
        borderColor: (theme) => theme.palette.border.primary,
      }}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline",
          height: "100%",
          width: "100%",
          aspectRatio: empty ? "none" : "1.6",
        }}
        contentEditable={false}
      >
        {empty ? (
          <Box sx={{ pt: 1, display: "flex", gap: 1, pl: 2.5 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.text.disabled, p: 0 }}
            >
              <Compass />
            </IconButton>
            <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>
              Embed a PDF or website.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ width: "100%", aspectRatio: "1.8" }}>
            <iframe width="100%" height="100%" src={element.url as string} />
          </Box>
        )}
        {!readOnly && (
          <>
            <Fade in={isHovered}>
              <Box>
                <MoreMenu
                  handleRemoveNode={handleRemoveNode}
                  empty={empty}
                  setOpenDialog={setOpenDialog}
                  type={EmbedElementType.EMBED}
                  setType={undefined}
                ></MoreMenu>
              </Box>
            </Fade>
            <EmbedUrlDialog
              title="Embed link"
              open={openDialog}
              url={element.url as string}
              onSubmit={handleChange}
              onClose={handleClose}
            ></EmbedUrlDialog>
          </>
        )}
      </Box>
    </Box>
  );
};

export const MediaEmbedElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element } = props;
    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    if (!path) return;

    const handleSetNode = (dataToSet: object) => {
      setNodes(editor, dataToSet, { at: path });
    };

    const handleChange = React.useCallback((url: string) => {
      handleSetNode({ url: url });
    }, []);

    const handleRemoveNode = () => {
      removeNodes(editor, { at: path });
    };

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <Box sx={{ visibility: "hidden", height: 0 }} contentEditable={false}>
          {children}
        </Box>
        <MediaEmbed
          element={element}
          handleChange={handleChange}
          handleRemoveNode={handleRemoveNode}
        />
      </PlateElement>
    );
  },
);
