import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextFieldProps,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Alert } from "@mui/material";
import { AlertProps } from "@mui/lab";

import { WorkoutEditExercise } from "../workout/WorkoutEditExercise";
import { ExerciseLibraryExercisesList } from "../exercise-library/ExerciseLibraryExercisesList";
import { SearchField } from "../fields/SearchField";
import { ExerciseAsset } from "../exercise-library/types";
import { LibrarySection } from "../component-library/constants";
import { ComponentDrawerBackButton } from "../component-library/ComponentDrawerBackButton";
import { SOMETHING_WENT_WRONG } from "../../constants";

import {
  createEmptyExercise,
  normalizeObsoleteExercise,
} from "../workout/utils";

const useStyles = makeStyles((theme) => ({
  root: {},

  button: {
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 700,
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(3),
  },

  search: {
    margin: theme.spacing(3, 0, 2, 0),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  alert: {
    padding: theme.spacing(1.5, 2),
    borderWidth: 1,
    borderStyle: "solid",

    "& div": {
      fontSize: 16,
      fontWeight: 500,
    },
  },

  alertSuccess: {
    borderColor: theme.palette.progress.green,
    "& div": {
      color: theme.palette.progress.green,
    },
  },

  alertError: {
    borderColor: theme.palette.progress.red,
    "& div": {
      color: theme.palette.progress.red,
    },
  },
}));

type AlertData = {
  severity: AlertProps["severity"];
  message: string;
};

export interface ExerciseLibraryProps extends BoxProps {
  onSectionChange?: (section: LibrarySection) => void;
}

export function ExerciseLibrary(props: ExerciseLibraryProps) {
  const { className, onSectionChange, ...other } = props;
  const s = useStyles();
  const [query, setQuery] = React.useState<string>("");

  const [exerciseAsset, setExerciseAsset] = React.useState<ExerciseAsset>();

  const handleQueryChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setQuery(event.currentTarget.value);
    },
    [],
  );
  const handleCreateClick = React.useCallback(() => {
    setExerciseAsset({
      exercise: createEmptyExercise(),
    });
  }, []);

  const handleCancelEdit = React.useCallback(() => {
    setExerciseAsset(null);
  }, []);

  const handleFinishEdit = React.useCallback(() => {
    setExerciseAsset(null);
  }, [exerciseAsset]);

  const handleExerciseClick = React.useCallback((_, value: ExerciseAsset) => {
    setExerciseAsset(normalizeObsoleteExercise(value));
  }, []);

  const handleCloseLibrary = React.useCallback(() => {
    if (onSectionChange) {
      onSectionChange(null);
    }
  }, [onSectionChange]);
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box>
        <ComponentDrawerBackButton
          variant={exerciseAsset ? "close" : "back"}
          onClick={exerciseAsset ? handleCancelEdit : handleCloseLibrary}
        />
      </Box>

      <Box className={s.header}>
        <Typography variant="h2">
          {exerciseAsset
            ? exerciseAsset.assetId
              ? "Edit exercise"
              : "Create exercise"
            : "Exercise Library"}
        </Typography>
      </Box>

      {exerciseAsset ? (
        <WorkoutEditExercise
          exercise={exerciseAsset.exercise}
          onUpdate={handleFinishEdit}
          onCancel={handleCancelEdit}
          isAssetLibrary
        />
      ) : (
        <Box>
          <Button
            className={s.button}
            variant="contained"
            fullWidth
            onClick={handleCreateClick}
          >
            Create exercise
          </Button>

          <SearchField
            className={s.search}
            variant="outlined"
            fullWidth
            placeholder="Search"
            value={query}
            onChange={handleQueryChange}
            hideSearchIcon
          />

          <ExerciseLibraryExercisesList
            query={query}
            onClick={handleExerciseClick}
          />
        </Box>
      )}
    </Box>
  );
}
