import clsx from "clsx";
import React from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { IUserInfoDto2 } from "@growth-machine-llc/stridist-api-client";
import { lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { ReactComponent as GHLIconWhite } from "../../icons/GoHighLevelWhite.svg";
import { ReactComponent as BinIcon } from "../../icons/Bin2.svg";
import BlockIcon from "@mui/icons-material/Block";
import ErrorIcon from "@mui/icons-material/Error";
import { GoHighLevelAccountStatus } from "../../constants";
import { useMutation } from "@tanstack/react-query";
import BillingService from "../../services/BillingService";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { useTimer } from "../../hooks/useTimer";

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },

  addButton: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },

  pauseButton: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },

  unpauseButton: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    },
  },

  deleteButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: lighten(theme.palette.error.main, 0.2),
    },
  },

  ghlActionsBox: {
    display: "flex",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  errorIcon: {
    color: theme.palette.error.main,
    verticalAlign: "middle",
    fontSize: 30,
    marginLeft: theme.spacing(1),
  },
}));

export interface GoHighLevelAccountStateProps {
  user: IUserInfoDto2;
  ghlDisabled: boolean;
  handleCreateSubAccount: () => void;
  handleToggleSubAccount: (pause: boolean) => void;
  handleDeleteSubAccount: () => void;
}

export function GoHighLevelAccountState(props: GoHighLevelAccountStateProps) {
  const {
    user,
    ghlDisabled,
    handleCreateSubAccount,
    handleToggleSubAccount,
    handleDeleteSubAccount,
  } = props;
  const s = useStyles();
  const { showToastAlert } = useToastAlert();

  const { seconds, isDone, setCount } = useTimer(0);

  const { mutate: resendEmail, isPending: resendingEmail } = useMutation({
    mutationFn: BillingService.resendGoHighLevelEmail,
    onSuccess: () => {
      showToastAlert("success", { message: "Reset password email sent" });
      setCount(60);
    },
  });

  const renderAddButton = () => (
    <Button
      className={clsx(s.button, s.addButton)}
      variant="text"
      startIcon={<GHLIconWhite />}
      onClick={handleCreateSubAccount}
      disabled={ghlDisabled}
    >
      Add GHL account
    </Button>
  );

  const renderDeleteButton = () => (
    <Button
      className={clsx(s.button, s.deleteButton)}
      variant="text"
      startIcon={<BinIcon />}
      onClick={handleDeleteSubAccount}
      disabled={ghlDisabled}
    >
      Delete GHL account
    </Button>
  );

  const renderPauseUnpauseButtons = (isPaused: boolean) => (
    <Button
      className={clsx(s.button, isPaused ? s.unpauseButton : s.pauseButton)}
      variant="text"
      startIcon={isPaused ? <RestartAltIcon /> : <BlockIcon />}
      onClick={() => handleToggleSubAccount(!isPaused)}
      disabled={ghlDisabled}
    >
      {isPaused ? "Unpause Account" : "Pause Account"}
    </Button>
  );

  const renderResendEmailButton = () => (
    <Button
      variant="outlined"
      size="large"
      onClick={() => resendEmail({ coachId: user.id })}
      disabled={!isDone || resendingEmail}
    >
      {!isDone ? (
        <>
          Reset password in{" "}
          <span
            style={{
              display: "inline-block",
              minWidth: "2ch",
              marginLeft: "2px",
            }}
          >
            {seconds}
          </span>
        </>
      ) : (
        "Reset password"
      )}
    </Button>
  );

  if (user.isUserExistsInGHL === false) {
    return (
      <Box className={s.ghlActionsBox}>
        {renderAddButton()}
        {user.goHighLevelAccountState &&
          user.goHighLevelAccountState !== GoHighLevelAccountStatus.NONE && (
            <Tooltip
              title="There is no GHL account linked to this email, but a record exists in the database."
              placement="top"
            >
              <ErrorIcon className={s.errorIcon} />
            </Tooltip>
          )}
      </Box>
    );
  }

  if (
    user.isUserExistsInGHL === true &&
    user.goHighLevelAccountState === GoHighLevelAccountStatus.NONE
  ) {
    return (
      <Box className={s.ghlActionsBox}>
        {renderDeleteButton()}
        {renderResendEmailButton()}
        <Tooltip
          title="A GHL account exists, but the database does not reflect its status."
          placement="top"
        >
          <ErrorIcon className={s.errorIcon} />
        </Tooltip>
      </Box>
    );
  }

  switch (user.goHighLevelAccountState) {
    case GoHighLevelAccountStatus.NONE:
      return renderAddButton();
    case GoHighLevelAccountStatus.ACTIVE:
      return (
        <Box className={s.ghlActionsBox}>
          {renderPauseUnpauseButtons(false)}
          {renderDeleteButton()}
          {renderResendEmailButton()}
        </Box>
      );
    case GoHighLevelAccountStatus.PAUSED:
      return (
        <Box className={s.ghlActionsBox}>
          {renderPauseUnpauseButtons(true)}
          {renderDeleteButton()}
          {renderResendEmailButton()}
        </Box>
      );
    default:
      return null;
  }
}
