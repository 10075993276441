import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import dayjs from "dayjs";

export const updateComponentTitleAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    title: string;
    apiHandled?: boolean;
  }>,
) => {
  const { componentId, title } = action.payload;
  const component = state.value.components[componentId];
  if (!component) return;

  state.value.components[componentId] = {
    ...component,
    title,
    draftExist: true,
    updatedAt: dayjs(),
  };
};
