import { Paper, useTheme } from "@mui/material";
import React from "react";
import { getCommonTransition } from "../../../../../utils/mui";

interface IOverridesPaperButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const OverridesPaperButton = (props: IOverridesPaperButtonProps) => {
  const { onClick, children } = props;
  const theme = useTheme();
  return (
    <>
      <Paper
        onClick={onClick}
        elevation={0}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingBlock: 0.5,
          paddingLeft: 1.5,
          paddingRight: 0.5,
          borderRadius: 3,
          cursor: "pointer",
          backgroundColor: theme.palette.grey[100],
          gap: 0.75,
          border: 1,
          borderColor: theme.palette.grey[600],
          borderStyle: "dashed",
          ":hover": {
            backgroundColor: theme.palette.grey[200],
          },
          transition: getCommonTransition(theme, "background-color"),
        }}
      >
        {children}
      </Paper>
    </>
  );
};

export default OverridesPaperButton;
