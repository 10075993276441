import {
  IGroupsCountDto,
  IProgramsCountDto,
} from "@growth-machine-llc/stridist-api-client";
import React from "react";
import { createContext } from "react";

export interface CoachClientsCount {
  pending: number;
}

export interface CoachProgramsCount extends IProgramsCountDto {}

export interface CoachGroupsCount extends IGroupsCountDto {}

export interface CoachTabsContextType {
  clientsCount: CoachClientsCount;
  programsCount: CoachProgramsCount;
  groupsCount: CoachGroupsCount;

  setClientsCount: (count: CoachClientsCount) => void;
  setProgramsCount: (count: CoachProgramsCount) => void;
  setGroupsCount: (count: CoachGroupsCount) => void;
}

export const CoachTabsContext = createContext<CoachTabsContextType | undefined>(
  {
    clientsCount: { pending: undefined },
    programsCount: { draft: undefined },
    groupsCount: { active: undefined, archived: undefined },

    setClientsCount: () => {},
    setProgramsCount: () => {},
    setGroupsCount: () => {},
  },
);

export const useCoachTabsContext = () => {
  const context = React.useContext(CoachTabsContext);
  if (context === undefined) {
    throw new Error(
      "useCoachTabsContext must be used within a CoachTabsProvider",
    );
  }
  return context;
};

export const getGroupsCountFromAction = (
  count: CoachGroupsCount,
  action: "archive" | "unarchive" | "delete",
): IGroupsCountDto => {
  switch (action) {
    case "archive":
      return {
        active: count.active - 1,
        archived: count.archived + 1,
      };
    case "unarchive":
      return {
        active: count.active + 1,
        archived: count.archived - 1,
      };
    case "delete":
      return {
        active: count.active,
        archived: count.archived - 1,
      };
    default:
      return count;
  }
};
