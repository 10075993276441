import React from "react";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { ProgramTemplatesPage } from "../../../components/admin/ProgramTemplatesPage";

export function AdminProgramTemplateRoute() {
  return (
    <>
      <ProgramTemplatesPage />
      <TrackInfoTool
        trackInfo={{
          name: "Admin - Exercises",
        }}
      />
    </>
  );
}
