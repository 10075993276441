import React from "react";
import { Box, TextField, TextFieldProps } from "@mui/material";
import { verificationCodeLength } from "../../utils/coachSignupSchema";
import { PlainTextButton } from "../button/PlainTextButton";

type Props = TextFieldProps & {
  register?: any;
  loading?: boolean;
  nextResendInSeconds: number;

  onValidCodePaste: (code: string) => void;
  onResendCode: () => void;
};

const EmaiLVerificationCodeField = ({
  register,
  loading: sendingVerificationCode,
  onValidCodePaste,
  onResendCode: handleSendVerificationCode,
  nextResendInSeconds: seconds,
  ...props
}: Props) => {
  const handleCodePaste = React.useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const clipboardData = event.clipboardData;
      const pastedText = clipboardData.getData("text");

      if (pastedText.length === verificationCodeLength) {
        onValidCodePaste(pastedText);
      }
    },
    [onValidCodePaste],
  );

  const disableResend = sendingVerificationCode || seconds > 0;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
      }}
    >
      <TextField
        {...props}
        {...(register || {})}
        name="code"
        type="text"
        label="Verification Code"
        placeholder="Enter your code from the email..."
        onPaste={handleCodePaste}
      />
      <PlainTextButton
        onClick={handleSendVerificationCode}
        disabled={disableResend}
        underline="hover"
        fontSize={14}
      >
        Resend Code{" "}
        {seconds > 0 ? (
          <>
            {"("}
            <span
              style={{
                display: "inline-block",
                minWidth: "2ch",
                textAlign: "center",
              }}
            >
              {seconds}
            </span>
            {" seconds)"}
          </>
        ) : (
          ""
        )}
      </PlainTextButton>
    </Box>
  );
};

export default EmaiLVerificationCodeField;
