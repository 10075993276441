import { enrollmentsClient } from "../api/ApiClients";
import {
  BatchEnrollClientsCommand,
  BatchEnrollGroupsCommand,
  ChangeEnrollmentStartDateCommand,
  ClientEnrollmentDto,
  CreatePreviewEnrollmentCommand,
  EnrollmentsForCalendarDto,
  EnrollmentsStatusCountDto,
  EnrollmentStatus,
  HighlightsPeriod,
  IBatchEnrollClientsCommand,
  IBatchEnrollGroupsCommand,
  IChangeEnrollmentStartDateCommand,
  ICreatePreviewEnrollmentCommand,
  IEnrollmentsVm,
  IPaginatedListOfClientEnrollmentDto,
  IUnenrollEnrollClientCommand,
  UnenrollEnrollClientCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

interface GetEnrollmentsClientParams {
  status: EnrollmentStatus;
  pageNumber: number;
  pageSize: number;
  clientId?: number;
}

interface GetProgramEnrollmentsParams {
  programSlug: string;
}

class EnrollmentsClientService extends ServiceBase {
  async getClientEnrolments(
    params: GetEnrollmentsClientParams,
  ): Promise<IPaginatedListOfClientEnrollmentDto> {
    return enrollmentsClient.getClientEnrollments(
      params.status,
      params.pageNumber,
      params.pageSize,
      params?.clientId,
    );
  }

  async getEnrollments(
    params: GetProgramEnrollmentsParams,
  ): Promise<IEnrollmentsVm> {
    return enrollmentsClient.getEnrollments(params.programSlug);
  }

  async getProgramEnrollmentInfo(slug: string, avatarsToTake: number) {
    return enrollmentsClient.getProgramEnrollmentInfo(slug, avatarsToTake);
  }

  async getClientEnrollmentsList(
    clientId: number,
  ): Promise<ClientEnrollmentDto[]> {
    return enrollmentsClient.getClientEnrollmentsList(clientId);
  }
  async getClientEnrolmentsCount(): Promise<EnrollmentsStatusCountDto> {
    return enrollmentsClient.getClientEnrollmentsStatusCount();
  }

  async getEnrollmentsStats(ids: number[]) {
    return enrollmentsClient.getEnrollmentsStats(ids);
  }

  async getClientEnrollmentWeek(
    programSlug: string,
    week: number,
    clientId?: number,
  ) {
    return enrollmentsClient.getClientEnrollmentWeek(
      programSlug,
      week,
      clientId,
    );
  }

  async getClientEnrollmentProgram(programSlug: string, clientId?: number) {
    return enrollmentsClient.getClientEnrollmentProgram(programSlug, clientId);
  }

  async getClientEnrollmentsForCalendar(
    clientUsername: string,
  ): Promise<EnrollmentsForCalendarDto[]> {
    return enrollmentsClient.getClientEnrollmentsForCalendar(clientUsername);
  }

  async getGroupsForEnrollment(
    pageNumber: number,
    pageSize: number,
    searchQuery?: string,
  ) {
    return enrollmentsClient.getGroupsForEnrollment(
      pageNumber,
      pageSize,
      searchQuery,
    );
  }

  async batchEnrollClients(data: IBatchEnrollClientsCommand) {
    const command = BatchEnrollClientsCommand.fromJS(data);
    return enrollmentsClient.batchEnrollClients(command);
  }

  async batchEnrollGroups(data: IBatchEnrollGroupsCommand) {
    const command = BatchEnrollGroupsCommand.fromJS(data);
    return enrollmentsClient.batchEnrollGroups(command);
  }

  async unenrollClient(clientId: number) {
    return enrollmentsClient.unenrollClient(clientId);
  }

  async unenrollGroup(groupId: number) {
    return enrollmentsClient.unenrollGroup(groupId);
  }

  async unenrollEnrollClient(data: IUnenrollEnrollClientCommand) {
    const command = UnenrollEnrollClientCommand.fromJS(data);
    return enrollmentsClient.unenrollEnrollClient(command);
  }

  async changeEnrollmentStartDate(data: IChangeEnrollmentStartDateCommand) {
    const command = ChangeEnrollmentStartDateCommand.fromJS(data);
    return enrollmentsClient.changeEnrollmentStartDate(command);
  }

  async createEnrollmentPreview(data: ICreatePreviewEnrollmentCommand) {
    const command = CreatePreviewEnrollmentCommand.fromJS(data);
    return enrollmentsClient.createPreviewEnrollment(command);
  }

  async getWorkoutHighlights(period: HighlightsPeriod, clientId: number) {
    return enrollmentsClient.getProfileHighlights(period, clientId);
  }
}

export default new EnrollmentsClientService();
