import React from "react";
import { Box, Grid2 } from "@mui/material";
import ClientExerciseChooseHistoryItem from "./ClientExerciseChooseHistoryItem";
import { Bar } from "react-chartjs-2";
import { colorSystem } from "../../theme";
import makeStyles from "@mui/styles/makeStyles";
import { WorkoutSection } from "../workout/types";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// eslint-disable-next-line import/no-unresolved
import "chartjs-adapter-date-fns";
import { fixCompletedRepsTypoInWorkoutSection } from "../editor/utils/common";
import { parseWorkoutResultValueJsonToWorkoutSection } from "../workout/utils";
import { useQuery } from "@tanstack/react-query";
import WorkoutResultsService from "../../services/WorkoutResultsService";
import ClientExerciseChooseSkeleton from "../loading/ClientExerciseChooseSkeleton";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 280,
    width: "100%",
    paddingLeft: theme.spacing(1),
  },
  blueCircle: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: colorSystem.blue1,
  },
  redCircle: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: colorSystem.primary,
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px",
    color: colorSystem.gray,
    paddingLeft: theme.spacing(1),
  },
}));

type ClientExerciseChooseHistoryProps = {
  name: string;
  id: any;
};

export const CLIENT_EXERCISE_CHOOSE_HISTORY_QUERY_KEY =
  "client-exercise-choose-history";

const ClientExerciseChooseHistory = ({
  name,
  id,
}: ClientExerciseChooseHistoryProps) => {
  const s = useStyles();

  const { data, isLoading: isLoadingInitial } = useQuery({
    queryKey: [
      CLIENT_EXERCISE_CHOOSE_HISTORY_QUERY_KEY,
      { clientId: id, exerciseTitle: name },
    ],
    queryFn: () =>
      WorkoutResultsService.getUserExerciseHistory(id, null, name, null),
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const resultSets = React.useMemo(() => {
    const sets = [];
    data?.items
      .map(({ value, ...dto }) => ({
        ...dto,
        section: parseWorkoutResultValueJsonToWorkoutSection(value),
      }))
      .forEach((i) =>
        i.section.exercises.forEach(
          (i) =>
            i.title === name && Array.isArray(i.result) && sets.push(i.result),
        ),
      );
    return sets;
  }, [data, name]);

  const minMaxValue = React.useMemo(() => {
    const weight = [];
    const reps = [];
    resultSets.forEach((i) => {
      if (Array.isArray(i)) {
        i.forEach((i) => {
          if (i.weight === "-" || "") {
            weight.push(0);
          } else weight.push(+i.weight);
          reps.push(+i?.completedReps || +i.reps);
        });
      }
    });
    const minWeight = Math.min(...weight);
    const maxWeight = Math.max(...weight);
    const minReps = Math.min(...reps);
    const maxReps = Math.max(...reps);
    return { minWeight, maxWeight, minReps, maxReps };
  }, [resultSets]);

  const valuesChart = React.useMemo(() => {
    const values = [];
    resultSets.forEach((i) => {
      const reps = i
        .map((i) => i?.completedReps || i.reps)
        .reduce((prev, curr) => +prev + +curr, 0);
      const minWeight = Math.min(
        ...i.map((item) => (isNaN(item.weight) ? 0 : +item.weight)),
      );
      const maxWeight = Math.max(
        ...i.map((item) => (isNaN(item.weight) ? 0 : +item.weight)),
      );
      values.push({ reps, minWeight, maxWeight });
    });
    return values;
  }, [resultSets]);

  const labels = React.useMemo(() => {
    return valuesChart.map((item) => [
      item?.completedReps || item.reps,
      "reps",
    ]);
  }, [valuesChart]);

  const dataCharts = {
    labels,
    datasets: [
      {
        label: "",
        data: valuesChart.map(({ minWeight }) => minWeight),
        backgroundColor: colorSystem.primary,
        barPercentage: 0.8,
        categoryPercentage: 0.39,
      },
      {
        label: "",
        data: valuesChart.map(({ maxWeight }) => maxWeight),
        backgroundColor: colorSystem.blue1,
        barPercentage: 0.8,
        categoryPercentage: 0.39,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          padding: 10,
        },
      },

      x: {
        grid: {
          display: false,
        },
        ticks: {},
      },
    },

    responsive: true,
    legend: {
      display: false,
    },
  };

  return isLoadingInitial ? (
    <ClientExerciseChooseSkeleton />
  ) : (
    <Grid2 container spacing={2}>
      <ClientExerciseChooseHistoryItem
        title="Min Weight"
        text={`${minMaxValue.minWeight} kg`}
      />
      <ClientExerciseChooseHistoryItem
        title="Max Weight"
        text={`${minMaxValue.maxWeight} kg`}
      />
      <ClientExerciseChooseHistoryItem
        title="Min Reps"
        text={minMaxValue.minReps}
      />
      <ClientExerciseChooseHistoryItem
        title="Max Reps"
        text={minMaxValue.maxReps}
      />
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 8,
        }}
      >
        <Bar height={150} data={dataCharts} options={options} />
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 8,
        }}
      >
        <Box className={s.container}>
          <Box className={s.item}>
            <Box className={s.redCircle} />
            <Box className={s.text}>Min Weight</Box>
          </Box>
          <Box className={s.item}>
            <Box className={s.blueCircle} />
            <Box className={s.text}>Max Weight</Box>
          </Box>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default ClientExerciseChooseHistory;
