import {
  IClientsCountDto,
  PaginatedListOfClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import ClientsService from "../../../services/ClientsService";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { useQueryClient } from "@tanstack/react-query";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../../../wrappers/router/coach/CoachClientProfileWrapper";
import { COACH_PENDING_CLIENTS_COUNT_QUERY_KEY } from "../../../wrappers/router/coach/CoachClientsWrapper";

const useDeleteClientsMutation = (status?: UserInviteStatus) => {
  const queryClient = useQueryClient();
  return useOptimisticUpdateMutation({
    queryKey: [
      COACH_CLIENTS_LIST_QUERY_KEY,
      { status: [UserInviteStatus.ARCHIVED] },
    ],
    mutationFn: ClientsService.batchDeleteClients,
    optimisticUpdater: {
      updateFn: (oldData: PaginatedListOfClientInfoDto, { clientIds }) => {
        oldData.items = oldData.items.filter(
          (client) => !clientIds.some((id) => id === client.id),
        );
        return oldData;
      },
    },
    options: {
      onSuccess: () => {
        // NOTE: Updating count only if `ADDED` client is deleted to update `PENDING` tab count
        if (status === UserInviteStatus.ADDED) {
          queryClient.setQueryData<IClientsCountDto>(
            [COACH_PENDING_CLIENTS_COUNT_QUERY_KEY],
            (prev) => (prev ? { ...prev, count: prev.count - 1 } : prev),
          );
        }
      },
    },
  });
};

export default useDeleteClientsMutation;
