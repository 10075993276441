import {
  programFoldersClient,
  programsClient,
  programTemplatesClient,
} from "../api/ApiClients";
import {
  CreateProgramCommand,
  ICreateProgramCommand,
  ProgramOrderBy,
  ArchiveProgramCommand,
  CreateProgramFolderCommand,
  DuplicateProgramCommand,
  HandleProgramFolderCommand,
  IArchiveProgramCommand,
  ICreateProgramFolderCommand,
  IDuplicateProgramCommand,
  IHandleProgramFolderCommand,
  IRestoreProgramCommand,
  IUpdateProgramFolderCommand,
  RestoreProgramCommand,
  UpdateProgramFolderCommand,
} from "@growth-machine-llc/stridist-api-client";
import { ProgramSort, ProgramStatus } from "../constants";
import ServiceBase from "./ServiceBase";

interface GetProgramParams {
  status: ProgramStatus[];
  orderBy: ProgramSort;
  pageNumber: number;
  pageSize: number;
  folderId?: number;
  ungrouped?: boolean;
  query?: string;
  clientsToTake?: number;
}

class CoachProgramsService extends ServiceBase {
  async getPrograms(params: GetProgramParams) {
    return programsClient.getCoachPrograms(
      params.status,
      params.orderBy as unknown as ProgramOrderBy,
      params.clientsToTake,
      params.pageNumber,
      params.pageSize,
      params.folderId,
      params.ungrouped,
      params.query,
    );
  }

  async getProgramsCount() {
    return programsClient.getProgramsCount();
  }

  async getProgramWeeks(programId: number) {
    return programsClient.getProgramWeeks(programId);
  }

  async getProgramFolders() {
    return programFoldersClient.getProgramFolders();
  }

  async createProgram(variables: ICreateProgramCommand) {
    const command = CreateProgramCommand.fromJS(variables);
    return programsClient.createProgram(command);
  }

  async changeProgramFolder(variables: IHandleProgramFolderCommand) {
    const command = HandleProgramFolderCommand.fromJS(variables);
    return programsClient.handleProgramFolder(command);
  }

  async archiveProgram(variables: IArchiveProgramCommand) {
    const command = ArchiveProgramCommand.fromJS(variables);
    return programsClient.archiveProgram(command);
  }

  async duplicateProgram(variables: IDuplicateProgramCommand) {
    const command = DuplicateProgramCommand.fromJS(variables);
    return programsClient.duplicateProgram(command);
  }

  async restoreProgram(variables: IRestoreProgramCommand) {
    const command = RestoreProgramCommand.fromJS(variables);
    return programsClient.restoreProgram(command);
  }

  async deleteProgramFolder(folderId: number) {
    return programFoldersClient.deleteProgramFolder(folderId);
  }

  async createProgramFolder(variables: ICreateProgramFolderCommand) {
    const command = CreateProgramFolderCommand.fromJS(variables);
    return programFoldersClient.createProgramFolder(command);
  }

  async updateProgramFolder(variables: IUpdateProgramFolderCommand) {
    const command = UpdateProgramFolderCommand.fromJS(variables);
    return programFoldersClient.updateProgramFolder(variables.id, command);
  }

  async getPublishedPrograms(
    pageNumber: number,
    pageSize: number,
    searchQuery?: string,
  ) {
    return programsClient.getPublishedPrograms(
      pageNumber,
      pageSize,
      searchQuery,
    );
  }
}

export default new CoachProgramsService();
