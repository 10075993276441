import React from "react";
import { ClientSettingsProfile } from "../../../../components/settings/ClientSettingsProfile";
import { useClient } from "../../../../hooks/useClient";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { TAGS_QUERY_KEY } from "../../../../wrappers/router/coach/CoachClientsWrapper";
import TagsService from "../../../../services/TagsService";
import FbUsersService from "../../../../services/FbUsersService";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../../../../wrappers/router/coach/CoachClientProfileWrapper";
import {
  IBriefClientInfo,
  TagDto,
} from "@growth-machine-llc/stridist-api-client";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../../../../components/coach-clients/CoachClientsListScreen";

export function CoachClientSettingsRoute() {
  const client = useClient();
  const { displayName, id: clientId } = client;

  // TODO_API_V2_TAGS STR-1338: Currently settings first tag as default, since UI is not supporting multiple tags.
  const defaultTag = client.tags[0];
  const [tag, setTag] = React.useState(defaultTag?.title || "");

  const {
    data: allTags,
    isLoading: tagsLoading,
    isPending: tagsPending,
  } = useQuery({
    // Using the same `TAGS_QUERY_KEY` as in `CoachClientsWrapper.tsx` to share the cache.
    queryKey: [TAGS_QUERY_KEY],
    queryFn: TagsService.getTags,
  });

  const { mutate: createTag, isPending: createTagInFlight } = useMutation({
    mutationFn: TagsService.createTag,
    onSuccess: (id, { title }) =>
      onTagsChange((prev) => [new TagDto({ id, title }), ...prev]),
  });

  const { mutate: assignTagToClient, isPending: assignTagToClientInFlight } =
    useMutation({
      mutationFn: TagsService.assignTagsToClient,
      onSuccess: (_data, { tagIds }) => onTagAssignmentChange(tagIds),
    });

  const { mutate: deleteTag, isPending: deleteTagInFlight } = useMutation({
    mutationFn: TagsService.deleteTag,
    onSuccess: (_, tagId) => {
      onTagsChange((prev) => prev.filter((tag) => tag.id !== tagId));

      if (defaultTag.id === tagId) {
        onTagAssignmentChange([]);
      }
    },
  });

  const { mutate: unAssignClientTag, isPending: unAssigningClientTagInFlight } =
    useMutation({
      onSuccess: (_data) => onTagAssignmentChange([]),
      mutationFn: (variables: { clientId: number }) =>
        TagsService.assignTagsToClient({ ...variables, tagIds: [] }),
    });

  const tagDirty = tag !== defaultTag?.title;

  const disabled =
    createTagInFlight ||
    assignTagToClientInFlight ||
    deleteTagInFlight ||
    unAssigningClientTagInFlight;

  const queryClient = useQueryClient();

  const onTagsChange = React.useCallback(
    (updateFn: (oldData: TagDto[]) => TagDto[]) => {
      queryClient.setQueryData<TagDto[]>([TAGS_QUERY_KEY], updateFn);
    },
    [queryClient],
  );

  const onTagAssignmentChange = React.useCallback(
    (newClientTagIds: number[]) => {
      if (newClientTagIds) {
        const newTags = allTags.filter((tag) =>
          newClientTagIds?.includes(tag.id),
        );
        queryClient.setQueryData<IBriefClientInfo>(
          [COACH_CLIENT_GENERIC_QUERY_KEY, { username: client.username }],
          (prev) => ({
            ...prev,
            tags: newTags,
          }),
        );
        queryClient.invalidateQueries({
          queryKey: [COACH_CLIENTS_LIST_QUERY_KEY, { isDialog: false }],
        });
      }
    },
    [queryClient, allTags, client.username],
  );

  const handleTagChange = React.useCallback((tag: string) => {
    setTag(tag);
  }, []);

  //TODO_TAGS: Refactor creating tags, handle messaging after creating tags
  const handleCreateTag = React.useCallback(async () => {
    const tagIndex = allTags.findIndex(
      (item) => item?.title.toUpperCase() === tag.toUpperCase(),
    );
    if (
      (defaultTag?.title.toUpperCase() !== tag.toUpperCase() && tag) ||
      (defaultTag?.title.toUpperCase() === tag.toUpperCase() &&
        tag &&
        tagIndex === -1)
    ) {
      if (tagIndex === -1) {
        createTag(
          { title: tag },
          {
            onSuccess(newTagId) {
              assignTagToClient({
                tagIds: [newTagId],
                clientId,
              });
            },
          },
        );
      } else {
        const tagId = allTags[tagIndex].id;

        assignTagToClient(
          { tagIds: [tagId], clientId },
          {
            onSuccess: () => {},
          },
        );
      }
    } else if (defaultTag?.title && !tag) {
      unAssignClientTag(
        {
          clientId,
        },
        {
          onSuccess() {},
        },
      );
    }
  }, [
    createTag,
    allTags,
    tag,
    defaultTag,
    assignTagToClient,
    clientId,
    tagsLoading,
    unAssignClientTag,
    tagsPending,
  ]);

  const handleDeleteTag = React.useCallback(
    (tagId, tagTitle) => {
      deleteTag(tagId, {
        onSuccess(_, errors) {
          if (tagTitle === tag) {
            handleTagChange("");
          }
        },
      });
    },
    [deleteTag, tag, handleTagChange],
  );

  return (
    client && (
      <>
        <ClientSettingsProfile
          user={client}
          coachEditMode={true}
          allTags={allTags}
          tag={tag}
          tagDirty={tagDirty}
          tagsLoading={tagsLoading}
          onChangeTag={handleTagChange}
          onCreateTag={handleCreateTag}
          onDeleteTag={handleDeleteTag}
          disabled={disabled}
        />
        <TrackInfoTool
          trackInfo={{
            name: "Coach - Client Settings",
            properties: {
              clientUsername: client.username,
            },
          }}
        />
      </>
    )
  );
}
