import React, { ReactNode, useEffect, useState } from "react";
import { SidebarProvider } from "../../../contexts/CurriculumSidebarContext";
import { EditorProgramContext } from "../../new-editor/hooks";
import { ShowCoversContext } from "../../../hooks/useShowCovers";
import { ProgramViewContext } from "../../../hooks/useProgramView";
import { useCurriculumSelector } from "../../../redux/hooks";
import {
  selectCurriculum,
  selectProgramWeeks,
} from "../../../redux/curriculum/selectors/curriculum";
import { CurriculumViewOptions } from "../CurriculumEditScreen";
import { EnableColorsContext } from "../../../hooks/useEnableColors";
import { ProgramWeeksContext } from "../../../hooks/useProgramWeeks";
import { DialogProvider } from "../../../contexts/CurriculumComponentDialogContext";
import { ProgramDetailsViewMode } from "../../program/ProgramDetailsViewButton";
import { SpreadsheetInfoDrawerContext } from "../../../hooks/useSpreadsheetInfoDrawer";
import { ColumnField } from "../../program-workout-spreadsheet/utils";
import { CurriculumViewOptionsProvider } from "../../../contexts/CurriculumViewOptionsContext";
import { CopyPasteComponentProvider } from "../../../contexts/CopyPasteComponentProvider";

interface ICurriculumProvidersProps {
  children: ReactNode;
  viewOptions: CurriculumViewOptions;
}
const CurriculumProviders = (props: ICurriculumProvidersProps) => {
  const { children, viewOptions } = props;

  const programId = useCurriculumSelector(selectCurriculum).value.program.id;
  const programWeeks = useCurriculumSelector(selectProgramWeeks);

  const [infoDrawerColumnField, setInfoDrawerColumnField] =
    useState<ColumnField>();

  const [copiedCalendarComponentId, setCopiedCalendarComponentId] =
    useState<number>();

  useEffect(() => {
    if (
      copiedCalendarComponentId === undefined &&
      viewOptions.view !== ProgramDetailsViewMode.CALENDAR
    ) {
      setCopiedCalendarComponentId(undefined);
    }
  }, [copiedCalendarComponentId, viewOptions.view]);

  // TODO decide to move view specific context closer
  return (
    <DialogProvider>
      <ProgramWeeksContext.Provider value={programWeeks}>
        {/* TODO drop single viewOption contexts in favor of generic CurriculumViewOptionsProvider */}
        <EnableColorsContext.Provider value={viewOptions.enableColors}>
          <EditorProgramContext.Provider value={{ programId }}>
            <ShowCoversContext.Provider value={viewOptions.showCovers}>
              <ProgramViewContext.Provider value={viewOptions.view}>
                <CurriculumViewOptionsProvider viewOptions={viewOptions}>
                  <CopyPasteComponentProvider>
                    <SpreadsheetInfoDrawerContext.Provider
                      value={[infoDrawerColumnField, setInfoDrawerColumnField]}
                    >
                      {children}
                    </SpreadsheetInfoDrawerContext.Provider>
                  </CopyPasteComponentProvider>
                </CurriculumViewOptionsProvider>
              </ProgramViewContext.Provider>
            </ShowCoversContext.Provider>
          </EditorProgramContext.Provider>
        </EnableColorsContext.Provider>
      </ProgramWeeksContext.Provider>
    </DialogProvider>
  );
};

export default CurriculumProviders;
