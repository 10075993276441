import React from "react";
import {
  EditorProgramContext,
  EditorComponentContext,
} from "../../../../../components/new-editor/hooks";
import { HistoryBlock } from "../../../../../components/history-block/HistoryBlock";
import CoachComponent from "../../../../../components/program-component/CoachComponent";
import { RefreshSlug } from "../../../../../components/routes/RefreshSlug";
import { useParams } from "react-router-dom";
import TrackInfoTool from "../../../../../components/tools/TrackInfoTool";
import { useCurriculumSelector } from "../../../../../redux/hooks";
import {
  selectComponentBySlug,
  selectComponentBySlugId,
  selectProgram,
} from "../../../../../redux/curriculum/selectors/curriculum";
import { Box } from "@mui/material";
import { useDialog } from "../../../../../contexts/CurriculumComponentDialogContext";
import ComponentDialogSkeleton from "../../../../../components/skeletons/ComponentDialogSkeleton";
import { extractSlugId } from "../../../../../utils/slug";

interface ICoachProgramComponentPreviewRouteProps {
  programSlugProp?: string;
  componentSlugProp?: string;
}

export function CoachProgramComponent(
  inputProps: ICoachProgramComponentPreviewRouteProps,
) {
  const { componentSlugProp: componentSlug } = inputProps;
  const params = useParams();
  const programSlug = params.slug;
  const { closeDialog } = useDialog();

  const program = useCurriculumSelector(selectProgram);

  // currently, no program id is fetched for preview (not a problem since it readonly)
  // should be fixed after refactoring
  const PROGRAM_ID = program?.id ?? -1;

  // enable query only when componentSlug is present and not a temp slug
  const enabled =
    Boolean(componentSlug) && !componentSlug?.includes("-tempslug");

  const componentSlugId = extractSlugId(componentSlug);

  const componentData = useCurriculumSelector(
    selectComponentBySlugId(componentSlugId),
  );

  return enabled ? (
    <>
      <RefreshSlug
        slugInfo={[
          {
            slug: componentData?.slug,
            slugId: componentData?.slugId,
          },
          // TODO_API_V2_REFRESH_SLUG: Consider retrieving slug from program.
          // Currently not urgent since user can not trigger slug change on this page.
          // {
          //   slug: program?.slug,
          //   slugId: program?.slugId,
          // },
        ]}
      />
      <EditorProgramContext.Provider value={{ programId: PROGRAM_ID }}>
        <EditorComponentContext.Provider value={componentData}>
          <HistoryBlock>
            <Box sx={{ containerType: "inline-size" }}>
              <CoachComponent
                componentData={componentData}
                readOnly={false}
                programName={program?.name}
              />
            </Box>
          </HistoryBlock>
        </EditorComponentContext.Provider>
      </EditorProgramContext.Provider>
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Program Component",
          properties: {
            programId: PROGRAM_ID,
            programName: program?.name,
            programSlug: programSlug,
            componentId: componentData?.id,
            componentTitle: componentData?.title,
            componentSlug: componentData?.slug,
          },
        }}
      />
    </>
  ) : (
    <ComponentDialogSkeleton backClick={closeDialog} />
  );
}
