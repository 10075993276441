import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import BillingService from "../../services/BillingService";
import { LoadingPage } from "../loading/LoadingPage";
import { GoHighLevelUrl } from "../../constants";
import ErrorPage from "../app/ErrorPage";
import { HttpError } from "found";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { CoachMarketingInstructions } from "./CoachMarketingInstructions";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
    padding: theme.spacing(2),
  },
  label: {
    fontWeight: 700,
    fontSize: 16,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}));

export interface CoachMarketingScreenProps {}

export function CoachMarketingScreen(props: CoachMarketingScreenProps) {
  const s = useStyles();
  const [showInstructions, setShowInstructions] = useState(false);
  const currentUser = useCurrentUser();

  const {
    mutate: createSubAccount,
    isPending: creatingSubAccount,
    isError,
  } = useMutation({
    mutationFn: BillingService.createSubAccount,
    onSuccess: (data) => {
      if (data?.alreadyExists) {
        window.location.href = GoHighLevelUrl;
      } else {
        setShowInstructions(true);
      }
    },
  });

  useEffect(() => {
    createSubAccount({ coachId: currentUser.id });
  }, [createSubAccount]);

  return (
    <>
      {creatingSubAccount && <LoadingPage />}
      {isError && <ErrorPage error={new HttpError(500)} />}
      {showInstructions && <CoachMarketingInstructions user={currentUser} />}
      {!creatingSubAccount && !isError && !showInstructions && <Container />}
    </>
  );
}
