import React from "react";
import clsx from "clsx";
import { MutateOptions } from "@tanstack/react-query";
import {
  ICreateSignedUrlCommand,
  SignedUrlVm,
  AssetType,
} from "@growth-machine-llc/stridist-api-client";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { uploadFile, getMimeTypes } from "../../../../utils/file";
import { useNativeDropzone } from "../../../../utils/device";
import { CloudUpload } from "@mui/icons-material";
import {
  CheckInAnswerProgressPhotoView,
  ProgressImageInfo,
} from "./CheckInAnswerProgressPhotoView";
import { ElementType } from "../../types/elements";
import { useToastAlert } from "../../../app/ToastAlert/ToastAlertProvider";
import { CircularLoader } from "../../../loading/CircularLoader";
import { maybePluralize } from "../../../../utils/text";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.depressed,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed",
    borderColor: theme.palette.border.primary,
    padding: theme.spacing(2.75),
    borderRadius: theme.spacing(1),
    height: 300,
    width: "100%",
  },

  icon: {
    backgroundColor: theme.palette.avatar,
    color: theme.palette.selected.main,
    borderRadius: "50%",
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    padding: theme.spacing(2.25),
    marginBottom: theme.spacing(-2),
  },

  iconMargin: {
    marginBottom: 0,
  },

  text: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },

  button: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5, 5),
    border: "2px solid",
    borderColor: theme.palette.common.black,
    borderRadius: "6px",
    fontWeight: "bold",
  },

  multipleRoot: {
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },

  multipleBox: {
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    height: "min-content",
    gap: theme.spacing(2),
  },

  multiplePhotoContainer: {
    alignItems: "center",
    display: "flex",
    height: theme.spacing(20),
    width: theme.spacing(20),
  },

  multiplePhoto: {
    height: theme.spacing(20),
    width: theme.spacing(20),
  },
}));

type CreateUrlOptions = MutateOptions<
  SignedUrlVm,
  unknown,
  ICreateSignedUrlCommand,
  unknown
>;

type CheckInUploadClintPhotoProps = {
  name: string;
  value?: ProgressImageInfo | ProgressImageInfo[];
  onChange: (
    name: string,
    value: (ProgressImageInfo | null) | ProgressImageInfo[],
  ) => void;
  createUrl: (
    variables: ICreateSignedUrlCommand,
    options?: CreateUrlOptions,
  ) => void;
  id: string;
  idEntityType?: string;
  uploadingPhoto: boolean;
  setUploadingPhoto: (_: boolean) => void;
  disabled: boolean;
  multiple?: boolean;
  icon?: any;
  photoLimit?: number;
};

const CheckInUploadClientPhoto = (props: CheckInUploadClintPhotoProps) => {
  const {
    name,
    value,
    createUrl,
    id,
    onChange,
    uploadingPhoto,
    setUploadingPhoto,
    multiple = false,
    icon = <CloudUpload />,
    photoLimit = 9999,
  } = props;
  const idEntityType = props.idEntityType ?? "User";
  const ref = React.useRef<HTMLDivElement>(null);
  const s = useStyles();
  const { showToastAlert } = useToastAlert();
  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: ([file], errors) => {
      if (errors.length) {
        const message = errors
          .map((error) => error?.errors.map((e) => e.message).join(", ") ?? "")
          .join("; ");
        showToastAlert("error", { message });
        console.error(errors);
        return;
      } else if (!file) {
        return;
      }

      if (multiple && Array.isArray(value) && value.length >= photoLimit) {
        showToastAlert("info", {
          message: `You can only upload up to ${maybePluralize(props.photoLimit, "photo")}`,
        });
        return;
      }

      setUploadingPhoto(true);

      createUrl(
        {
          refId: parseInt(id, 10),
          refType: idEntityType,
          file: file.name,
          assetType: AssetType.PROGRAM_CHECKIN_PHOTO,
        },
        {
          onError: (error, variables, context) => {
            console.error(error);
            const message = errors
              .map(
                (error) => error?.errors.map((e) => e.message).join(", ") ?? "",
              )
              .join("; ");
            showToastAlert("error", { message });
          },
          onSuccess: ({ url }) => {
            uploadFile(url, file)
              .then(() => {
                const urlInstance = new URL(
                  `https:/${url.substr(url.indexOf("/", 8))}`,
                );
                urlInstance.search = "";
                const uploadUrl = String(urlInstance);

                onChange(
                  name,
                  multiple
                    ? [
                        ...(Array.isArray(value) ? value : []),
                        {
                          url: uploadUrl,
                          fileName: file.name,
                          fileSize: file.size,
                        },
                      ]
                    : {
                        url: uploadUrl,
                        fileName: file.name,
                        fileSize: file.size,
                      },
                );
              })
              .catch((err) => {
                console.error(err);
                const message = errors
                  .map(
                    (error) =>
                      error?.errors.map((e) => e.message).join(", ") ?? "",
                  )
                  .join("; ");
                showToastAlert("error", { message });
              })
              .finally(() => {
                setUploadingPhoto(false);
              });
          },
        },
      );
    },
    accept: getMimeTypes(ElementType.IMAGE),
    maxFiles: !multiple && 1,
  });

  const handleRemove = React.useCallback(
    (index: number) => {
      if (!index && index !== 0) {
        onChange(name, null);
      } else {
        if (Array.isArray(value)) {
          onChange(name, [...value.slice(0, index), ...value.slice(index + 1)]);
        }
      }
    },
    [name, onChange],
  );

  const renderDND = () => (
    <>
      <Box
        className={clsx(
          s.icon,
          (!multiple || (Array.isArray(value) && value.length)) && s.iconMargin,
        )}
      >
        {uploadingPhoto ? (
          <CircularLoader
            size={25}
            sx={{
              color: "white",
              alignSelf: "center",
              justifySelf: "center",
            }}
          />
        ) : (
          icon
        )}
      </Box>
      {!multiple && (
        <>
          <Typography className={s.text} variant="body2">
            Drag and drop here
          </Typography>
          <Typography className={s.text} variant="body2">
            or
          </Typography>
          <Button
            className={s.button}
            variant="outlined"
            disabled={uploadingPhoto}
          >
            {uploadingPhoto ? "Uploading…" : "Upload"}
          </Button>
        </>
      )}
      <input disabled={uploadingPhoto} type="file" {...getInputProps()} />
    </>
  );

  const renderImage = (image: ProgressImageInfo, index?: number) =>
    multiple ? (
      <div className={s.multiplePhoto}>
        <CheckInAnswerProgressPhotoView
          square
          className={s.multiplePhoto}
          image={image}
          onRemove={(e) => {
            e.stopPropagation();
            handleRemove(index);
          }}
        />
      </div>
    ) : (
      <CheckInAnswerProgressPhotoView
        image={image}
        onRemove={(e) => {
          handleRemove(index);
        }}
      />
    );

  return (
    <Box
      className={clsx(s.root, multiple && s.multipleRoot)}
      {...getRootProps({ ref })}
    >
      {multiple ? (
        <>
          {renderDND()}
          <Box className={s.multipleBox}>
            {Array.isArray(value) && value.map(renderImage)}
          </Box>
        </>
      ) : value && !Array.isArray(value) ? (
        renderImage(value)
      ) : (
        renderDND()
      )}
    </Box>
  );
};

export default CheckInUploadClientPhoto;
