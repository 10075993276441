import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Grid2,
  Typography,
  Button,
  Box,
  TextField,
  useMediaQuery,
  IconButton,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { CardAvatar } from "../card/CardAvatar";
import { ReactComponent as ChangePasswordIcon } from "../../icons/LockClosedOutlined.svg";
import { TimezoneField } from "../fields/TimezoneField";
import {
  terraCapacitorEnabled,
  TerraSdkOptions,
  useTerraSdk,
} from "../../utils/terra-sdk";
import {
  Units,
  Gender,
  SOMETHING_WENT_WRONG,
  PHONE_NUMBER_VERIFICATION_CODE_SENT,
  UserRole,
} from "../../constants";
import { SettingsCard } from "../card/SettingsCard";
import { UploadAvatarProps, UploadAvatar } from "../fields/UploadAvatar";
import { BirthdayField, DATE_BIRTHDAY_FORMAT } from "../fields/BirthdayField";
import { FieldsGroup } from "../fields/FieldsGroup";
import { BodyWeightHeightField } from "../fields/BodyWeightHeightField";
import { FieldLabel } from "../fields/FieldLabel";
import { PhoneField, PhoneFieldProps } from "../fields/PhoneField";
import { GenderField } from "../fields/GenderField";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";
import { useCurrentUser, UserInfo } from "../../hooks/useCurrentUser";
import { useLogout } from "../../hooks/useLogout";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";

import { DeleteClientDialog } from "./dialogs/DeleteUserDialog";
import { ChangeEmailDialog } from "./dialogs/ChangeEmailDialog";
import { ChangePasswordDialog } from "./dialogs/ChangePasswordDialog";
import PreLoader from "../loading/PreLoader";
import TerraConnectButton from "./TerraConnectButton";

import {
  IUpdateUserProfileCommand,
  TagDto,
  UnitSystem,
} from "@growth-machine-llc/stridist-api-client";
import { colorSystem } from "../../theme";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import TerraService from "../../services/TerraService";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import useAuth from "../../hooks/auth/useAuth";
import { useForm } from "react-hook-form";
import {
  clientSettingsSchema,
  ClientSettingsSchema,
} from "../../utils/clientSettingsSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { LocationField } from "../fields/LocationField";
import TagField from "../fields/TagField";
import { DefaultLoader } from "../loading/DefaultLoader";
import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../../wrappers/router/coach/CoachClientProfileWrapper";
import { usePopupState } from "material-ui-popup-state/hooks";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import useArchiveOrDeleteSingleClientMutation from "../coach-clients/mutations/useArchiveSingleClientMutation";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useDeleteSingleClientMutation from "../coach-clients/mutations/useDeleteSingleClientMutation";
import dayjs from "dayjs";
import { US_DATE_FORMAT } from "../../utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  },

  card: {
    padding: theme.spacing(3),
  },

  subheaderText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
      fontWeight: 500,
      textAlign: "initial",
    },
  },

  restrictCoachInfo: {
    color: theme.palette.text.secondary,
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 3),

    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
    },

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1.75, 3),
    },
  },

  verifyButtonBox: {
    height: "100%",
  },

  verifyButton: {
    height: theme.spacing(7),
    minWidth: "50%",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    width: "100%",
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4.25),
      width: "auto",
    },
  },

  photoUrl: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",

    [theme.breakpoints.down("sm")]: {
      "& > div:nth-child(2)": {
        flexGrow: 1,
      },
    },
  },

  avatar: {
    borderRadius: theme.spacing(0.5),
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(4),
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
  },

  fields: {
    display: "grid",

    margin: theme.spacing(3, 0, 0, 0),
    alignItems: "start",

    gridGap: 0,
    gridTemplateColumns: "1fr",

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5, 0, 0, 0),
      gridGap: theme.spacing(3),
      gridTemplateColumns: "1fr 1fr",
    },
  },

  passwordField: {
    gridColumn: 1,

    [theme.breakpoints.down("sm")]: {
      "& > h4": {
        marginBottom: theme.spacing(-1),
      },

      "& > div": {
        display: "none",
      },
    },
  },

  emailField: {
    [theme.breakpoints.up("sm")]: {
      position: "relative",

      "& > button": {
        position: "absolute",
        left: 0,
        bottom: theme.spacing(-5),
      },
    },
  },

  changeEmailButton: {
    marginBottom: theme.spacing(-2.5),
  },

  changePasswordButton: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 1),
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(-1),

    "& svg": {
      marginRight: theme.spacing(1),
    },
  },

  terraConnectButton: {
    marginBottom: theme.spacing(2),
  },

  dialog: {
    "& .MuiDialog-paperWidthSm": {
      [theme.breakpoints.up("sm")]: {
        minWidth: 600,
      },
    },
  },

  radioLabel: {
    fontWeight: 500,
  },

  deleteAccount: {
    color: colorSystem.primary,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  archiveAccount: {
    color: colorSystem.black,
  },

  dangerActionButton: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat, sans-serif",
    fontSize: 18,
    letterSpacing: "0.01em",
    fontWeight: 600,

    "& span": {
      marginLeft: theme.spacing(1),
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  dangerActionButtonContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  terraContainer: {
    gap: theme.spacing(2),
  },

  error: {
    position: "absolute",
  },

  caretDownIcon: {
    width: 11,
  },
  textField: {},
  input: {
    paddingRight: 16,
  },
}));

export interface ClientSettingsProfileProps
  extends Omit<ContainerProps, "children"> {
  user: UserInfo;
  coachEditMode?: boolean;
  onChangeTag?: (value: string) => void;
  tag?: string;
  allTags?: TagDto[];
  tagsLoading?: boolean;
  onCreateTag?: () => void;
  onDeleteTag?: (id: number, title: string) => void;
  connectTerra?: boolean;
  disabled?: boolean;
  tagDirty?: boolean;
}

export const CLIENT_TERRA_AUTH_TOKEN_QUERY_KEY = "client-terra-auth-token";

export function ClientSettingsProfile(props: ClientSettingsProfileProps) {
  const {
    className,
    user,
    connectTerra = false,
    coachEditMode = false,
    tag,
    onChangeTag,
    allTags,
    tagsLoading,
    onDeleteTag,
    onCreateTag,
    disabled,
    tagDirty,
    ...other
  } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const { showToastAlert } = useToastAlert();
  const logout = useLogout();
  const theme = useTheme();
  const archived = user.archived;

  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty: isFormDirty },
    setError,
    watch,
    reset,
    setValue,
    clearErrors,
    trigger,
    control,
  } = useForm<ClientSettingsSchema>({
    mode: "onBlur",
    resolver: zodResolver(clientSettingsSchema),
    defaultValues: {
      ...user,
      fullName: user.displayName,
      gender: user.gender as Gender,
      picture: user.photoUrl,
      phoneCode: user.phoneCode || "us",
      birthday: user.birthday
        ? dayjs(user.birthday).format(US_DATE_FORMAT)
        : "",
    },
  });

  const { isImpersonating, role: currentUserRole } = useCurrentUser();
  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = React.useState(false);

  const confirmDeleteClientDialogState = usePopupState({
    variant: "popover",
    popupId: "delete-client",
  });

  const { data: terraOptions } = useQuery({
    queryKey: [CLIENT_TERRA_AUTH_TOKEN_QUERY_KEY],
    queryFn: () => TerraService.generateAuthToken(),
    enabled: !coachEditMode,
  });
  const [isUploadingAvatar, setIsUploadingAvatar] = React.useState(false);
  const onError = useGenericErrorHandler({});

  const { connected, init: initTerraSdk } = useTerraSdk(terraOptions);
  const { refreshToken } = useAuth();
  const queryClient = useQueryClient();

  const updateTagInput = React.useCallback(
    (event, text: string) => {
      if (event && onChangeTag) {
        onChangeTag(text);
      }
    },
    [onChangeTag],
  );

  const {
    mutate: updateUser,
    isPending: updateUserInFlight,
    status: updateUserStatus,
  } = useMutation({
    mutationKey: ["update-user-account-settings"],
    mutationFn: FbUsersService.updateUser,

    onSuccess: (_, variables) => {
      const queryKey = coachEditMode
        ? [COACH_CLIENT_GENERIC_QUERY_KEY, { username: user.username }]
        : [CURRENT_USER_QUERY_KEY];

      queryClient.invalidateQueries({ queryKey: queryKey });
      if (variables.timezone != user.timeZone) {
        refreshToken();
      }
      reset({
        ...variables,
        fullName: variables.displayName,
        picture: variables.photoUrl,
      });
    },
    onError: (error) => onError(error),
  });

  const { mutate: deleteUser, isPending: deleteUserInFlight } =
    useDeleteSingleClientMutation(currentUserRole as UserRole);

  const { mutate: toggleArchiveClient, isPending: archivingClient } =
    useArchiveOrDeleteSingleClientMutation(user.username);

  const onArchivedChange = React.useCallback(
    (archived: boolean) => {
      toggleArchiveClient(
        {
          clientId: +user.id,
          archived,
        },
        {
          onSuccess: () => {
            const message = archived ? "Client archived" : "Client restored";
            showToastAlert("success", { message });
          },
        },
      );
    },
    [showToastAlert, toggleArchiveClient],
  );

  const handleError = React.useCallback(
    (message: string) => {
      showToastAlert("error", {
        message,
      });
    },
    [showToastAlert],
  );

  const handleChangePassword = React.useCallback(() => {
    setPasswordDialogOpen(true);
  }, []);

  const handlePasswordDialogClose = React.useCallback(() => {
    setPasswordDialogOpen(false);
  }, []);

  const handleOpenEmailDialog = React.useCallback(() => {
    setEmailDialogOpen(true);
  }, []);

  const handleCloseEmailDialog = React.useCallback(() => {
    setEmailDialogOpen(false);
  }, []);

  const handleUploadingAvatar: UploadAvatarProps["onUploading"] =
    React.useCallback((value) => {
      setIsUploadingAvatar(value);
    }, []);

  const handleDeleteUser = React.useCallback(() => {
    confirmDeleteClientDialogState.close();
    deleteUser(user.id);
  }, [deleteUser, logout, confirmDeleteClientDialogState]);

  const handleSave = React.useCallback(
    (data: ClientSettingsSchema) => {
      if (onCreateTag) onCreateTag();

      updateUser(
        {
          ...data,
          id: user.id,
          displayName: data.fullName,
          photoUrl: data.picture,
          units: data.units as UnitSystem,
        } as IUpdateUserProfileCommand,
        {
          onError: (error) => onError(error),
        },
      );
    },
    [onCreateTag],
  );

  const inFlight = updateUserInFlight || archivingClient || deleteUserInFlight;
  const isDirty = isFormDirty || tagDirty;

  const validationErrorMessages = Object.values(errors)
    .map((error) => error?.message)
    .join(".  ");

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={isDirty} />
      <SettingsCard
        header={coachEditMode ? "Edit client settings" : "Profile"}
        ButtonProps={{
          disabled:
            !isDirty ||
            isUploadingAvatar ||
            archivingClient ||
            deleteUserInFlight,
          onClick: handleSubmit(handleSave, () =>
            showToastAlert("error", {
              message: validationErrorMessages,
              title: "Some entered data doesn't pass form validation.",
            }),
          ),
          status: updateUserStatus,
        }}
      >
        <FieldLabel>Profile image</FieldLabel>
        <Box className={s.photoUrl}>
          <CardAvatar className={s.avatar} src={watch("picture")} />

          <UploadAvatar
            setValue={setValue}
            setError={setError}
            onError={handleError}
            value={watch("picture")}
            disabled={inFlight || isUploadingAvatar}
            onUploading={handleUploadingAvatar}
          >
            <Button
              variant="contained"
              className={s.button}
              disabled={inFlight}
              fullWidth
            >
              Upload new picture
            </Button>
          </UploadAvatar>
        </Box>
        <Box className={s.fields}>
          <FieldsGroup
            label="Full name"
            hideErrorIcon={true}
            error={errors.fullName?.message}
            errorClassName={s.error}
          >
            <TextField
              variant="outlined"
              name="fullName"
              {...register("fullName")}
              placeholder={"Please add your name"}
              fullWidth
              disabled={inFlight}
            />
          </FieldsGroup>

          {coachEditMode && (
            <Box>
              <TagField
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    className={s.textField}
                    placeholder={"Add client's tag"}
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        disabled: tagsLoading,
                        className: s.input,
                        endAdornment: tagsLoading ? (
                          <Box
                            sx={{
                              marginRight: 2,
                            }}
                          >
                            <DefaultLoader size="small" />
                          </Box>
                        ) : (
                          <CaretDownIcon
                            className={s.caretDownIcon}
                            color="grey"
                          />
                        ),
                      },
                    }}
                  />
                )}
                allTags={allTags}
                inputValue={tag}
                onInputChange={updateTagInput}
                onDeleteTag={onDeleteTag}
                disabled={tagsLoading || inFlight}
              />
            </Box>
          )}

          <FieldsGroup className={s.emailField} label="Email address">
            <TextField
              variant="outlined"
              fullWidth
              type="helperText"
              value={user.email}
              disabled
            />
            {isImpersonating || coachEditMode ? (
              <>
                <Typography variant="caption" className={s.restrictCoachInfo}>
                  You can't edit client's email
                </Typography>
              </>
            ) : (
              <Button
                className={s.changeEmailButton}
                variant="text"
                color="primary"
                onClick={handleOpenEmailDialog}
              >
                Change email address
              </Button>
            )}
          </FieldsGroup>

          <FieldsGroup label="Password">
            <TextField
              variant="outlined"
              type="password"
              value="••••••••••••"
              fullWidth
              disabled
            />
            {isImpersonating || coachEditMode ? (
              <>
                <Typography variant="caption" className={s.restrictCoachInfo}>
                  You can't edit client's password
                </Typography>
              </>
            ) : (
              <>
                <Button
                  variant="text"
                  onClick={handleChangePassword}
                  className={s.changePasswordButton}
                  disabled={inFlight}
                >
                  <ChangePasswordIcon />
                  Change password
                </Button>

                <Typography variant="body1" className={s.subheaderText}>
                  {user?.passwordUpdatedAt &&
                    `Last changed ${user?.passwordUpdatedAt?.format("MMM DD, YYYY")}`}
                </Typography>
              </>
            )}
          </FieldsGroup>
          {!coachEditMode && (
            <PhoneField
              register={register}
              watch={watch}
              setValue={setValue}
              setError={setError}
              trigger={trigger}
              control={control}
              handleBlur={() => trigger("phone")}
              clearErrors={clearErrors}
              errorMessagePhone={errors.phone?.message}
              errorMessagePhoneCode={errors.phoneCode?.message}
              disabled={inFlight}
            />
          )}

          <BodyWeightHeightField
            watch={watch}
            errors={errors}
            onHeightBlur={() => trigger("height")}
            onWeightBlur={() => trigger("weight")}
            setValue={setValue}
            trigger={trigger}
            disabled={inFlight}
          />
          <Box />

          <LocationField
            register={register}
            errorMessage={errors.location?.message}
            disabled={inFlight}
          />
          <TimezoneField
            errorMessage={errors.timezone?.message}
            control={control}
            disabled={inFlight}
          />
          {!coachEditMode && (
            <BirthdayField
              errorMessage={errors.birthday?.message}
              watch={watch}
              handleBlur={() => trigger("birthday")}
              control={control}
              clearErrors={clearErrors}
              disabled={inFlight}
            />
          )}
          {!coachEditMode && <Box></Box>}
          {!coachEditMode && (
            <GenderField
              error={errors.gender}
              clearErrors={clearErrors}
              control={control}
            />
          )}

          <FieldsGroup label="Connect your nutrition or activity tracker">
            {!isImpersonating && !coachEditMode && (
              <Grid2 container className={s.terraContainer}>
                {terraCapacitorEnabled && (
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={connected}
                    onClick={() => {
                      void initTerraSdk();
                    }}
                  >
                    {connected
                      ? "Apple Health Authorized"
                      : "Authorize Apple Health"}
                  </Button>
                )}
                <TerraConnectButton
                  className={s.terraConnectButton}
                  connected={user.terraConnected}
                />
              </Grid2>
            )}
            {user.terraConnected ? (
              <Typography variant="caption">
                Connected services:{" "}
                {user.terraProviders
                  .map((provider) => provider.provider.toLowerCase())
                  .join(", ")}
              </Typography>
            ) : (
              (isImpersonating || coachEditMode) && (
                <Typography variant="caption">
                  There are no connected services
                </Typography>
              )
            )}
          </FieldsGroup>
        </Box>
        <Divider />
        <Box className={s.dangerActionButtonContainer}>
          {!user.isSample && (
            <>
              <IconButton
                className={clsx(s.dangerActionButton, s.deleteAccount)}
                color="secondary"
                onClick={() => confirmDeleteClientDialogState.open()}
                size="large"
                disabled={inFlight}
              >
                <DeleteOutlineOutlinedIcon />
                <span>Delete account</span>
              </IconButton>
            </>
          )}
          {coachEditMode && (
            <IconButton
              className={clsx(s.dangerActionButton, s.archiveAccount)}
              onClick={() => onArchivedChange(!archived)}
              size="large"
              disabled={inFlight}
            >
              {archived ? <UnarchiveOutlinedIcon /> : <ArchiveOutlinedIcon />}
              <span>{archived ? "Unarchive" : "Archive"} account</span>
            </IconButton>
          )}
        </Box>
      </SettingsCard>

      {emailDialogOpen && (
        <ChangeEmailDialog
          className={s.dialog}
          id={user.id}
          email={user.email}
          open={true}
          onClose={handleCloseEmailDialog}
          fullScreen={!mdUp}
        />
      )}

      {confirmDeleteClientDialogState.isOpen && (
        <DeleteClientDialog
          userDisplayName={user.displayName}
          userRole={user.role as UserRole}
          onClose={() => confirmDeleteClientDialogState.close()}
          onDelete={handleDeleteUser}
        />
      )}

      {deleteUserInFlight && (
        <PreLoader
          text={`We are currently deleting out your ${brandName} account and data`}
        />
      )}

      {passwordDialogOpen && (
        <ChangePasswordDialog
          id={user.id}
          open={true}
          onClose={handlePasswordDialogClose}
          fullScreen={!mdUp}
        />
      )}
    </Container>
  );
}
