import { useMutation, useQueryClient } from "@tanstack/react-query";
import FbUsersService from "../services/FbUsersService";
import { UserInfo } from "./useCurrentUser";
import { CURRENT_USER_QUERY_KEY } from "../wrappers/current-user/CurrentUserWrapper";
import { calculateAge } from "../utils/date";

// TODO_API_V2_CURRENT_USER: Reuse this hook for all other user update mutations.
export function useUpdateCurrentUserMutation() {
  const updateCache = useUpdateCurrentUserCache();
  return useMutation({
    mutationKey: ["update-current-user"],
    mutationFn: FbUsersService.updateUser,
    onSuccess: (_, variables) => {
      updateCache(variables);
    },
  });
}

export const useUpdateCurrentUserCache = () => {
  const queryClient = useQueryClient();

  const updateCache = (
    variables: Partial<UserInfo> | ((prev: UserInfo) => UserInfo),
  ) => {
    queryClient.setQueryData<UserInfo>([CURRENT_USER_QUERY_KEY], (prev) => {
      const updatedData =
        typeof variables === "function" ? variables(prev) : variables;

      return {
        ...prev,
        ...updatedData,
        age: calculateAge(updatedData?.birthday),
        id: prev?.id,
      };
    });
  };

  return updateCache;
};

export type NotificationsCounter = keyof Pick<
  UserInfo,
  "notificationUnreadCount" | "unreadMessagesCount" | "unreadActivitiesCount"
>;

export const useUpdateNotificationsCounter = (
  defaultType: NotificationsCounter = "notificationUnreadCount",
) => {
  const updateCache = useUpdateCurrentUserCache();
  return (
    action: "increment" | "decrement" | "reset",
    type: NotificationsCounter = defaultType,
  ) => {
    return updateCache((prev) => {
      if (action === "reset") {
        return {
          ...prev,
          unreadMessagesCount: 0,
          unreadActivitiesCount: 0,
          notificationUnreadCount: 0,
        };
      } else {
        const difference = action === "increment" ? 1 : -1;
        const counter = prev[type] + difference;
        const totalCounter = prev.notificationUnreadCount + difference;
        return {
          ...prev,
          ...(counter > 0 && { notificationsActive: true }),
          notificationUnreadCount: Math.max(0, totalCounter),
          [type]: Math.max(0, counter),
        };
      }
    });
  };
};
