import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { generateId } from "../../../../../components/new-editor/utils/nodeUtil";
import { CurriculumState } from "../../../curriculum-slice";
import dayjs, { Dayjs } from "dayjs";

export const duplicateComponentToWeekAction = {
  prepare(props: { weekId: number; componentId: number }) {
    const { weekId, componentId } = props;
    const newComponentId = generateId();
    const updatedAt = dayjs();
    return {
      payload: {
        weekId,
        componentId,
        newComponentId,
        updatedAt,
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      weekId: number;
      componentId: number;
      newComponentId: number;
      updatedAt: Dayjs;
    }>,
  ) {
    const { weekId, componentId, newComponentId, updatedAt } = action.payload;
    if (!weekId || !componentId) return;

    // Get target component
    const targetComponent = state.value.components[componentId];
    if (!targetComponent) return;

    if (targetComponent.baseComponentId) {
      throw new Error("Cannot duplicate a component that is an override");
    }

    // Generate new component
    const newComponent = {
      ...targetComponent,
      id: newComponentId,
      previousStatus: undefined,
      weekId,
      slug: `${newComponentId}-tempslug`,
      updatedAt,
      title: `${targetComponent.title}`,
      overrides: [],
      baseComponentId: null,
    };

    // Add to weeks.components arr
    state.value.weeks[weekId].components.push(newComponent.id);
    // Add to weeks.positions arr
    state.value.weeks[weekId].positions.push(newComponent.id.toString());
    // Add to components
    state.value.components[newComponent.id] = newComponent;
  },
};
