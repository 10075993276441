import {
  alpha,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import GenericScheduleTitle from "./generic/GenericScheduleTitle";
import { GenericScheduleSwitch } from "./generic/GenericScheduleSwitch";

interface IDaysComponentProps {
  days: boolean[];
  color: string;
  handleDaysChange: (days: boolean[]) => void;
  singleDayMode?: boolean;
}
const DaysComponent = (props: IDaysComponentProps) => {
  const {
    days,
    color,
    handleDaysChange,
    singleDayMode: SINGLE_DAY_MODE,
  } = props;
  const theme = useTheme();

  const [checked, setChecked] = React.useState(!days.includes(false));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleDaysChange(
      event.target.checked
        ? [...Array(7)].map(() => true)
        : [...Array(7)].map((_, index) => index === 0),
    );
    setChecked(event.target.checked);
  };

  // Sync switch with manual interactions
  useEffect(() => {
    setChecked(!days.includes(false));
  }, [days]);

  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ color: color }}>
      <Box display={"flex"} alignItems={"center"}>
        <GenericScheduleTitle title="Days" />

        {!SINGLE_DAY_MODE && (
          <>
            <Typography
              variant="subtitle2"
              color={theme.palette.text.secondary}
              sx={{ ml: "auto", mb: 0.5, mr: 1 }}
            >
              Every day
            </Typography>
            <GenericScheduleSwitch
              checked={checked}
              customColor={color}
              sx={{ mb: 0.5 }}
              onChange={handleChange}
            />
          </>
        )}
      </Box>

      <ToggleButtonGroup size="small">
        {Array.from({ length: days.length }).map((_, index) => (
          <ToggleButton
            value={index}
            key={index}
            selected={days[index]}
            fullWidth
            sx={{
              "&.Mui-selected": {
                background: alpha(color, 0.2),

                ":hover": {
                  background: alpha(color, 0.4),
                },
              },
            }}
            onClick={() => {
              const updatedDays = SINGLE_DAY_MODE
                ? // Set all values as false
                  Array.from({ length: days.length }).map((_) => false)
                : // Get all prev values
                  [...days];

              // Update clicked
              updatedDays[index] = !days[index];
              handleDaysChange(updatedDays);
            }}
          >
            {index + 1}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default DaysComponent;
