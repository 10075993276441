import clsx from "clsx";
import React from "react";
import { Box, BoxProps, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ColorPicker } from "./ColorPicker";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },

  option: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },

  custom: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export interface ColorPickerGroupProps extends Omit<BoxProps, "onChange"> {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  colors?: Record<string, string>;
}

export function ColorPickerGroup(props: ColorPickerGroupProps) {
  const theme = useTheme();

  const defaultColors = React.useMemo(
    () => ({
      red: theme.palette.progress.red,
      blue: theme.palette.progress.blue,
      green: theme.palette.progress.green,
      custom: theme.palette.common.black,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    className,
    onChange,
    disabled,
    colors = defaultColors,
    ...other
  } = props;
  const s = useStyles();
  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleSelect = React.useCallback(
    (color: string) => {
      setValue(color);

      if (onChange) {
        onChange(color);
      }
    },
    [onChange],
  );

  if (!Object.values(colors).includes(value)) {
    colors.custom = value;
  }

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {Object.keys(colors).map((name) => (
        <ColorPicker
          key={name}
          onSelect={handleSelect}
          className={clsx(s.option, { [s.custom]: name === "custom" })}
          label={name}
          value={colors[name]}
          checked={value === colors[name]}
          dynamic={name === "custom"}
          disabled={disabled}
        />
      ))}
    </Box>
  );
}
