import React, { useContext, useEffect, useRef } from "react";
import { Button, Box, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { usePopupState } from "material-ui-popup-state/hooks";
import { maybePluralize } from "../../utils/text";
import { polyfillCSS } from "../../utils/css";
import { useDebounce } from "../../hooks/useDebounce";
import {
  CLIENTS_ALERT_THRESHOLD,
  Plan,
  REACTIVATE_PROMO_QUERY,
  planClients,
} from "../../constants";

import { ClientPendingCard } from "../card/ClientPendingCard";
import { GroupActions } from "../filters/GroupActions";
import { ActionButton } from "../button/ActionButton";
import { EmptyResults } from "../coach-programs/EmptyResults";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { PageSkeleton } from "../loading/PageSkeleton";
import { ClientCard } from "../card/ClientCard";
import { ConnectionPagination } from "../pagination/ConnectionPagination";
import { SendEmailDialog } from "../dialog/SendEmailDialog";
import { NewClientAction } from "./NewClientAction";
import { CoachMessageClientsDialog } from "./CoachMessageClientsDialog";

import { CoachClientFiltersContext } from "../../contexts/CoachClientFiltersContext";
import TrackInfoTool from "../tools/TrackInfoTool";
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import ClientsService from "../../services/ClientsService";
import {
  ClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import useInviteAddedClientsMutation from "./mutations/useInviteAddedClientsMutation";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import useArchiveClientsMutation from "./mutations/useArchiveClientsMutation";
import useDeleteClientsMutation from "./mutations/useDeleteClientsMutation";
import { useInvalidateCoachProgramAndGroupQueries } from "../../hooks/useInvalidateCoachProgramAndGroupQueries";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import { ClientSkeletonCard } from "../card/ClientSkeletonCard";
import { useQueryParam } from "../../hooks/useQueryParam";
import { ActivatedDialog } from "../dialog/payments/ActivatedDialog";
import {
  ActiveClientThresholdAlert,
  AlertDisplayMode,
} from "./ActiveClientThresholdAlert";
import {
  getClientExceededThresholdAlertText,
  getNextHigherPlan,
} from "../../utils/user";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(11),
    paddingLeft: polyfillCSS(
      `calc(${theme.spacing(4)} + var(--safe-area-inset-left)) !important`,
    ),
    paddingRight: polyfillCSS(
      `calc(${theme.spacing(10)} + var(--safe-area-inset-right)) !important`,
    ),
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
    },
  },

  filters: {
    marginBottom: theme.spacing(3),
  },

  card: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(3),
    },
  },

  empty: {
    marginTop: theme.spacing(20),
  },

  inviteAllButtonContainer: {
    display: "flex",
    justifyContent: "right",
    marginBottom: theme.spacing(2.5),
  },
  inviteAllButton: {
    color: theme.palette.common.black,
  },
}));

export interface CoachClientsListScreenProps {
  status: UserInviteStatus[];
}

export const COACH_CLIENTS_LIST_QUERY_KEY = "coach-clients-list";
export const INVITED_ADDED_CLIENTS_QUERY_KEY = "coach-invited-added-clients";

export function CoachClientsListScreen(props: CoachClientsListScreenProps) {
  const { status } = props;
  const queryClient = useQueryClient();
  const [upgraded, setUpgraded] = useQueryParam(
    REACTIVATE_PROMO_QUERY,
    undefined,
  );
  const { page, setPage, query, setQuery, filterTag, sortKey } = useContext(
    CoachClientFiltersContext,
  );
  const { showToastAlert } = useToastAlert();
  const { data: inviteAddedClientsList } = useQuery({
    queryKey: [INVITED_ADDED_CLIENTS_QUERY_KEY],
    queryFn: () =>
      ClientsService.getClientsFiltered(1, 0, [
        UserInviteStatus.ADDED,
        UserInviteStatus.PENDING,
      ]),
  });
  const inviteAddedClientsCount = inviteAddedClientsList?.totalCount;

  const { data: clientsData } = useQuery({
    queryKey: [
      COACH_CLIENTS_LIST_QUERY_KEY,
      { after: page, status, sortKey, query, filterTag, isDialog: false },
    ],
    queryFn: () =>
      ClientsService.getClientsFiltered(
        page,
        pageSize,
        status,
        sortKey,
        query,
        filterTag,
      ),
  });

  useEffect(() => {
    setPage(1);
  }, []);

  React.useEffect(() => {
    // TODO_API_V2_REACT_QUERY_V5: Consider better solution as replacement for removed `onSuccess`
    if (clientsData) {
      refetchClientStats();
    }
  }, [clientsData]);

  const clientIds = clientsData?.items?.map((client) => client.id).sort() || [];

  const { data: clientsStats, refetch: refetchClientStats } = useQuery({
    queryKey: ["client-stats"],
    queryFn: async () => {
      const cachedIds = clientsStats.map((cache) => cache.clientId);
      const idsToFetch = clientIds.filter((id) => !cachedIds.includes(id));
      if (!idsToFetch.length) {
        return clientsStats;
      }
      return [...clientsStats, ...(await ClientsService.getStats(idsToFetch))];
    },
    initialData: [],
    placeholderData: keepPreviousData,
    enabled: !!clientsData,
  });

  React.useEffect(() => {
    refetchClientStats();
  }, [clientsData]);

  const user = useCurrentUser();

  const nextPlan = getNextHigherPlan(user.plan as Plan);
  const currentPlanLimit = Number(planClients(user.plan as Plan, true));
  const planNumber = Number(planClients(nextPlan, true));

  const { mutate: inviteAddedClients, isPending: invitingAddedClients } =
    useInviteAddedClientsMutation();

  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const pageSize = 10;

  const [initialStatus, setInitialStatus] = React.useState(status[0]);
  const [selectedClients, setSelectedClients] = React.useState<ClientInfoDto[]>(
    [],
  );

  const [emailDialogIsOpen, setEmailDialogIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const messageClientsDialogState = usePopupState({
    variant: "popover",
    popupId: "message-many-clients",
  });

  const confirmArchiveClientsDialogState = usePopupState({
    variant: "popover",
    popupId: "archive-many-clients",
  });

  const confirmDeleteClientsDialogState = usePopupState({
    variant: "popover",
    popupId: "delete-many-clients",
  });

  const tagIdRefetch = React.useMemo(() => {
    return status.includes(UserInviteStatus.ACTIVE) ? filterTag : "";
  }, [status, filterTag]);

  const delayedQuery = useDebounce(query, 500);
  const handleLoaded = React.useCallback(() => {
    setLoading(false);
  }, []);

  const {
    mutate: toggleManyClientsArchived,
    isPending: toggleManyClientsArchivedInFlight,
  } = useArchiveClientsMutation(status);

  const {
    mutate: toggleManyClientsDeleted,
    isPending: toggleManyClientsDeletedInFlight,
  } = useDeleteClientsMutation();

  React.useEffect(() => {
    if (initialStatus !== status[0]) {
      setInitialStatus(status[0]);
      setQuery("");
      setSelectedClients([]);
    }
  }, [initialStatus, status]);

  const clientNodes = React.useMemo(
    () =>
      clientsData?.items
        ? clientsData?.items?.filter(
            (client) =>
              !status.includes(UserInviteStatus.PENDING) ||
              Boolean(client.invite),
          )
        : [],
    [clientsData?.items, status],
  );

  const allClientNodes = React.useMemo(
    () =>
      clientsData?.items && !status.includes(UserInviteStatus.PENDING)
        ? clientsData?.items?.map((client) => client)
        : [],
    [clientsData?.items, status],
  );

  const unselectedClients = clientNodes.filter(({ id }) =>
    selectedClients.every((it) => it.id !== id),
  );

  useEffect(() => {
    selectedClients
      .filter(
        ({ id, invite }) => clientNodes.every((it) => it.id !== id) && invite,
      )
      .forEach((it) => clientNodes.unshift(it));
  }, [selectedClients, clientNodes]);

  const handleClientSelect = React.useCallback(
    (clientId: number, selected: boolean) => {
      if (user) {
        setSelectedClients((value) =>
          selected
            ? [...value, clientNodes.find(({ id }) => id === clientId)]
            : value.filter(({ id }) => id !== clientId),
        );
      }
    },
    [
      clientNodes,
      user,
      showToastAlert,
      selectedClients,
      status,
      inviteAddedClientsCount,
    ],
  );

  const total = allClientNodes.length;
  const selectedTotal = selectedClients.length;
  const selectedAll = clientNodes.length > 0 && unselectedClients.length === 0;

  const sampleClients = user.accounts.filter((a) => a.isSample);

  const handleSelectAll = React.useCallback(() => {
    setSelectedClients(clientNodes);
  }, [clientNodes]);

  const isClientAdded = React.useMemo(() => {
    return clientNodes.filter((i) => i.status === UserInviteStatus.ADDED);
  }, [clientNodes]);

  const handleSelectAllPages = React.useCallback(() => {
    if (user) {
      setSelectedClients(allClientNodes as any[]);
    }
  }, [allClientNodes, user, showToastAlert, status, inviteAddedClientsCount]);

  const handleClearSelection = React.useCallback(
    () => setSelectedClients([]),
    [],
  );

  const handlePageChange = React.useCallback(
    (cursor: number) => {
      handleClearSelection();
      setPage(cursor);
    },
    [selectedClients, handleClearSelection],
  );

  const handleArchiveSelected = React.useCallback(() => {
    toggleManyClientsArchived({
      clients: selectedClients,
      archived: status.includes(UserInviteStatus.ACTIVE),
    });
    confirmArchiveClientsDialogState.close();
    setSelectedClients([]);
  }, [
    confirmArchiveClientsDialogState,
    selectedClients,
    status,
    toggleManyClientsArchived,
  ]);

  const handleDeleteSelected = React.useCallback(() => {
    toggleManyClientsDeleted({
      clientIds: selectedClients.map(({ id }) => id),
    });
    confirmDeleteClientsDialogState.close();
    setSelectedClients([]);
  }, [
    confirmDeleteClientsDialogState,
    selectedClients,
    status,
    toggleManyClientsDeleted,
  ]);

  const handleToggleSelected = React.useCallback(() => {
    if (status.includes(UserInviteStatus.ACTIVE)) {
      confirmArchiveClientsDialogState.open();
    } else {
      handleArchiveSelected();
    }
  }, [confirmArchiveClientsDialogState, handleArchiveSelected, status]);

  const handleMessageSelected = React.useCallback(() => {
    messageClientsDialogState.open();
  }, [messageClientsDialogState]);

  const handleMessagesSent = React.useCallback(() => {
    setSelectedClients([]);
    messageClientsDialogState.close();
  }, [messageClientsDialogState]);

  const handleSendEmail = React.useCallback(
    (message) => {
      const addedUsersIds = clientNodes
        .filter((i) => i.status === UserInviteStatus.ADDED && i.id)
        .map((i) => i.id);
      inviteAddedClients(
        {
          ids: addedUsersIds,
          message: message,
        },
        {
          onSuccess: () => {
            setEmailDialogIsOpen(false);
            showToastAlert("success", {
              message: "Invitation has been send",
            });
          },
        },
      );
    },
    [inviteAddedClients, showToastAlert, clientNodes],
  );

  const groupActionsDisabled =
    !selectedTotal ||
    toggleManyClientsArchivedInFlight ||
    toggleManyClientsDeletedInFlight;

  const handleCloseUpgradedDialog = React.useCallback(() => {
    setUpgraded(undefined);
  }, [setUpgraded]);

  return (
    <>
      <NewClientAction
        handleNewClientAction={() =>
          queryClient.invalidateQueries({
            queryKey: [COACH_CLIENTS_LIST_QUERY_KEY],
          })
        }
      />

      {!!isClientAdded.length &&
        (status.includes(UserInviteStatus.PENDING) ||
          status.includes(UserInviteStatus.ADDED)) && (
          <Box className={s.inviteAllButtonContainer}>
            <Button
              variant="outlined"
              children="Invite all"
              className={s.inviteAllButton}
              onClick={() => setEmailDialogIsOpen(true)}
            />
          </Box>
        )}

      {loading || !clientsData ? (
        <Stack direction="column" spacing={4}>
          <ClientSkeletonCard />
          <ClientSkeletonCard />
        </Stack>
      ) : (
        <>
          {clientNodes.length > 0 ? (
            clientNodes.map((client) =>
              status.includes(UserInviteStatus.PENDING) ||
              status.includes(UserInviteStatus.ADDED) ? (
                <ClientPendingCard
                  key={client.id}
                  className={s.card}
                  client={client}
                />
              ) : (
                <ClientCard
                  key={client.id}
                  className={s.card}
                  client={client}
                  stats={clientsStats?.find(
                    (stat) => stat.clientId === client.id,
                  )}
                  selectable
                  selected={selectedClients.some(({ id }) => id === client.id)}
                  isSample={sampleClients.some(
                    (account) => client.id === account.id,
                  )}
                  onSelect={handleClientSelect}
                />
              ),
            )
          ) : (
            <EmptyResults
              className={s.empty}
              title="No clients found"
              description={`You don't have ${status[0].toLocaleLowerCase()} clients${
                query ? " matching your query" : ""
              }`}
            />
          )}
          <ConnectionPagination
            currentPage={page || 1}
            pages={clientsData?.totalPages || 1}
            gotoPage={handlePageChange}
            autoHide
          />
        </>
      )}

      <GroupActions
        selectedTotal={selectedTotal}
        total={total}
        unitLabel="client"
        selectedAll={selectedAll}
        onSelectAll={handleSelectAll}
        onSelectAllPages={handleSelectAllPages}
        onClearSelection={handleClearSelection}
      >
        {status.includes(UserInviteStatus.ARCHIVED) &&
          currentPlanLimit - (user.clientsCountNoSample + selectedTotal) <=
            CLIENTS_ALERT_THRESHOLD && (
            <ActiveClientThresholdAlert
              alertTitle={`Keep your client list up to date`}
              fullText={getClientExceededThresholdAlertText(planNumber)}
              mode={AlertDisplayMode.DIALOG}
              dialogTitle={`Keep your client list up to date to prevent overcharging`}
            />
          )}
        <ActionButton
          variant="outlined"
          disabled={groupActionsDisabled}
          onClick={handleToggleSelected}
        >
          {status.includes(UserInviteStatus.ACTIVE) ? "Archive" : "Restore"}
        </ActionButton>
        {status.includes(UserInviteStatus.ARCHIVED) && (
          <ActionButton
            variant="outlined"
            disabled={groupActionsDisabled}
            onClick={() => confirmDeleteClientsDialogState.open()}
          >
            Delete
          </ActionButton>
        )}
        {status.includes(UserInviteStatus.ACTIVE) && (
          <ActionButton
            variant="contained"
            disabled={groupActionsDisabled}
            onClick={handleMessageSelected}
          >
            Send Message
          </ActionButton>
        )}
      </GroupActions>

      {messageClientsDialogState.isOpen && (
        <CoachMessageClientsDialog
          clients={selectedClients}
          onClose={messageClientsDialogState.close}
          onMessagesSent={handleMessagesSent}
          open
        />
      )}

      {confirmArchiveClientsDialogState.isOpen && (
        <ConfirmActionDialog
          title={`Are you sure you want to archive ${maybePluralize(
            selectedClients.length,
            "client",
          )}?`}
          description={`Archived clients no longer have access to ${brandName}.`}
          open
          onClose={confirmArchiveClientsDialogState.close}
          onCancel={confirmArchiveClientsDialogState.close}
          onConfirm={handleArchiveSelected}
        />
      )}
      {confirmDeleteClientsDialogState.isOpen && (
        <ConfirmActionDialog
          title={`Are you sure you want to delete ${maybePluralize(
            selectedClients.length,
            "client",
          )}?`}
          description={`This action is permanent and cannot be undone.`}
          open
          onClose={confirmDeleteClientsDialogState.close}
          onCancel={confirmDeleteClientsDialogState.close}
          onConfirm={handleDeleteSelected}
        />
      )}
      <SendEmailDialog
        sendingEmail={invitingAddedClients}
        sendEmail={handleSendEmail}
        onClose={() => setEmailDialogIsOpen(false)}
        open={emailDialogIsOpen}
      />
      {upgraded !== null && (
        <ActivatedDialog
          open={Boolean(upgraded)}
          onClose={handleCloseUpgradedDialog}
        />
      )}
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Clients",
          properties: {
            status,
          },
        }}
      />
    </>
  );
}
