import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  ButtonProps,
  Grid2,
  Button,
  Typography,
} from "@mui/material";
import { lighten } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog } from "../dialog/BaseDialog";
import { WorkoutMedia } from "./WorkoutMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "99999 !important" as any,
  },

  paper: {
    width: 524,
    margin: theme.spacing(1),
    zIndex: 999,
  },

  description: {
    color: theme.palette.common.black,
    zIndex: 999,
  },

  button: {
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "bold",
    width: `calc(50% - ${theme.spacing(1)})`,
    height: theme.spacing(7),
  },

  cancelButton: {
    color: theme.palette.text.secondary,
    borderWidth: 2,
    marginRight: theme.spacing(2),
  },
  subtitle: {
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 500,
  },

  okayButton: {
    backgroundColor: "black",

    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.2),
    },
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
}));

export interface ConfirmActionDialogProps extends Omit<DialogProps, "title"> {
  title: string;
  description?: string;
  onCancel: ButtonProps["onClick"];
  onConfirm: ButtonProps["onClick"];
  disabled?: boolean;
  items?: any[];
  selectItemIndex?: number;
  image?: any;
}

export function WorkoutActionsDelete(props: ConfirmActionDialogProps) {
  const {
    className,
    title,
    description,
    onCancel,
    onConfirm,
    disabled,
    items,
    selectItemIndex,
    image,
    ...other
  } = props;
  const s = useStyles();

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={title}
      description={description}
      onClose={onCancel}
      classes={{
        paper: s.paper,
        description: s.description,
      }}
      {...other}
    >
      <Grid2>
        <Typography className={s.subtitle}>
          Are you sure you want to delete the item
        </Typography>
        {image && <WorkoutMedia image={image} modal />}
        <>
          <Button
            className={clsx(s.button, s.cancelButton)}
            variant="outlined"
            children="Cancel"
            onClick={onCancel}
            disabled={disabled}
          />
          <Button
            className={clsx(s.button, s.okayButton)}
            variant="contained"
            children="OK"
            onClick={onConfirm}
            disabled={disabled}
          />
        </>
      </Grid2>
    </BaseDialog>
  );
}
