import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ComponentDuplicatesGroup } from "@growth-machine-llc/stridist-api-client";

interface DuplicatedComponentsAccordionProps {
  title: string;
  duplicates: ComponentDuplicatesGroup[] | undefined;
}

const DuplicatedComponentsAccordion: React.FC<
  DuplicatedComponentsAccordionProps
> = ({ title, duplicates }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {title} ({duplicates?.length ?? 0})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {duplicates && duplicates.length > 0 ? (
          duplicates.map((group, index) => (
            <Box key={index} mb={2}>
              <Typography variant="subtitle2">
                Title: {group.title ?? "N/A"}
              </Typography>
              <Typography variant="body2">
                Days: {group.days ?? "N/A"}
              </Typography>
              <Typography variant="body2">Duplicated on weeks:</Typography>
              {group.duplicatesOnWeeks && group.duplicatesOnWeeks.length > 0 ? (
                <ul>
                  {group.duplicatesOnWeeks.map((w, wIndex) => (
                    <li key={wIndex}>
                      Week: {w.week}, Count: {w.count}
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography variant="body2">
                  No specific week duplicates listed.
                </Typography>
              )}
            </Box>
          ))
        ) : (
          <Typography variant="body2">No duplicates found.</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default DuplicatedComponentsAccordion;
