import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { generateId } from "../../../../../components/new-editor/utils/nodeUtil";
import { CurriculumState } from "../../../curriculum-slice";
import dayjs from "dayjs";
import { ComponentRepeatValues } from "../../../../../constants";
import { TILL_THE_END_OF_THE_PROGRAM } from "../../../../../components/program-component/utils";

export const splitRepeatAction = {
  prepare(props: { weekId: number; componentId: number, toastId: string | number }) {
    const { weekId, componentId, toastId } = props;
    const newComponentId = generateId();
    return {
      payload: {
        weekId,
        componentId,
        newComponentId,
        toastId: toastId,
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      weekId: number;
      componentId: number;
      newComponentId: number;
      toastId: string | number;
    }>,
  ) {
    const { weekId, componentId, newComponentId } = action.payload;
    if (!weekId || !componentId) return;

    // Get target component
    const targetComponent = state.value.components[componentId];
    if (!targetComponent) return;

    const originalWeek = state.value.weeks[targetComponent.weekId];
    if (!originalWeek) return;

    const targetWeek = state.value.weeks[weekId];
    if (!targetWeek) return;

    if (
      targetComponent.duration < 2 &&
      targetComponent.duration !== TILL_THE_END_OF_THE_PROGRAM
    )
      return;

    if (originalWeek.id === targetWeek.id) return;

    if (ComponentRepeatValues[targetComponent.repeat] < 1) return;

    // Generate new component
    const newComponent = {
      ...targetComponent,
      overrides: [],
      id: newComponentId,
      previousStatus: undefined,
      weekId,
      slug: `${newComponentId}-tempslug`,
      updatedAt: dayjs(),
      title: targetComponent.title,
      isRecentSplitResult: true,
    };

    // handle split logic with 'duration' updates:
    let durationOfTargetAtSplitWeek = 0;
    for (
      let i = originalWeek.weekNumber;
      i < targetWeek.weekNumber;
      i += ComponentRepeatValues[targetComponent.repeat]
    ) {
      durationOfTargetAtSplitWeek++;
    }
    const durationOfTargetTotal = targetComponent.duration;
    // update target component duration:
    state.value.components[componentId] = {
      ...targetComponent,
      duration: durationOfTargetAtSplitWeek,
      updatedAt: dayjs(),
    };
    newComponent.duration =
      durationOfTargetTotal === TILL_THE_END_OF_THE_PROGRAM
        ? TILL_THE_END_OF_THE_PROGRAM
        : durationOfTargetTotal - durationOfTargetAtSplitWeek;

    // Add to weeks.components arr
    state.value.weeks[weekId].components = [
      ...state.value.weeks[weekId].components,
      newComponent.id,
    ];

    // Add to weeks.positions arr
    state.value.weeks[weekId].positions = [
      ...state.value.weeks[weekId].positions,
      newComponent.id.toString(),
    ];
    // Add to components
    state.value.components[newComponent.id] = newComponent;
  },
};
