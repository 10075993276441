import React from "react";
import {
  Box,
  Pagination,
  PaginationProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3.4),
  },
  paginationItem: {
    "& .Mui-selected:hover": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
    "& .Mui-selected": {
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface CardPaginationProps
  extends Omit<PaginationProps, "shape" | "color" | "className"> {}

export const CardPagination = (props: CardPaginationProps) => {
  const s = useStyles();
  const { breakpoints } = useTheme();
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const mdUp = useMediaQuery(breakpoints.up("md"));

  return (
    <Box className={s.pagination}>
      <Pagination
        shape="rounded"
        color={"primary"}
        siblingCount={smUp ? 2 : 1}
        boundaryCount={mdUp ? 2 : smUp ? 1 : 0}
        className={s.paginationItem}
        {...props}
      />
    </Box>
  );
};
