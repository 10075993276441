import React, { Suspense, useState } from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Box,
  debounce,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { last, Node } from "slate";
import { EditorCoverImageContext } from "../../hooks/useEditorCoverImage";
import { usePreview } from "../../hooks/usePreview";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  EditorComponentItemDisabledContext,
  CheckInComponentContext,
  useNewEditor,
} from "../new-editor/hooks";
import {
  getComponentSpecifics,
  getComponentDefaultTitle,
} from "../../utils/component";
import { HabitPromptEdit } from "../habit-prompt/HabitPromptEdit";
import { PREVIEW_BAR_SPACING, PreviewBar } from "../preview/PreviewBar";
import { PreviewBox } from "../preview/PreviewBox";
import { ComponentPublishButton } from "../program/ComponentPublishButton";
import { useHistoryBlock } from "../history-block/hooks";
import { CoachComponentTitle } from "../program-component/CoachComponentTitle";
import {
  ComponentType,
  CoachComponentSavingState,
  ComponentRepeat,
  ReminderType,
  SidebarTabs,
} from "../../constants";
import { Schedule } from "../schedule/types";
import { colorSystem } from "../../theme";
import { ComponentTemplate } from "../component-template/ComponentTemplateDialog";
import { AccordionProps } from "../accordion/Accordion";
import { EditorDirty } from "../../hooks/useSetDirty";

import { CoachComponentBar } from "./CoachComponentBar";
import { MessageComponentPanel } from "./MessageComponentPanel";
import {
  getEmptyNode,
  isEmptyContent,
  unfixLegacyContent,
} from "../editor/utils/common";
import { useLocation } from "react-router-dom";
import { WorkoutAddButton } from "../workout/WorkoutAddButton";
import { UseComponentTemplate } from "../component-template/UseComponentTemplate";
import { EditorElementView } from "../new-editor/utils/editorUtils";
import { menuItemDisabled } from "../new-editor/utils/menuItemUtil";
import ComponentBarHeightContext from "../../contexts/ComponentBarHeightContext";
import { countScheduleRepeats, getCookie } from "./utils";
import JsonEditor from "../editor/JsonEditor";
import { useProgramWeeks } from "../../hooks/useProgramWeeks";
import {
  IUpdateComponentCommand,
  UpdateComponentCommand,
} from "@growth-machine-llc/stridist-api-client";
import { ComponentStatus } from "@growth-machine-llc/stridist-api-client";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import {
  overrideComponent,
  updateComponentProps,
} from "../../redux/curriculum/curriculum-slice";
import {
  selectComponentTempIdsMap,
  selectWeekTempIdsMap,
} from "../../redux/api/selectors";
import ComponentScheduleReminder from "../schedule/ComponentScheduleReminder";
import { CurriculumComponent } from "../../redux/types";
import CoachComponentPreview from "./CoachComponentPreview";
import { CURRICULUM_SIDEBAR_WIDTH } from "../curriculum-sidebar/Sidebar";
import { getCommonTransition } from "../../utils/mui";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import dayjs from "dayjs";
import PendingDialog from "../program/pendingDialog/PendingDialog";
import { useNavigationGuard } from "../../hooks/useNavigationGuard";
import { useDialog } from "../../contexts/CurriculumComponentDialogContext";
import { useQuery } from "@tanstack/react-query";
import ComponentsService from "../../services/ComponentsService";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";
import { selectOverridesWithCoordinates } from "../../redux/curriculum/selectors/curriculum";

const LAST_PUBLISHED_CONTENT_QUERY_KEY = "last-published-content";

const COMPONENT_MAX_WIDTH = 1100;

const ComponentEditor = React.lazy(
  () => import("../new-editor/ComponentEditor"),
);

const useStyles = makeStyles((theme) => {
  return {
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },

    root: {
      paddingTop: theme.spacing(16),
      paddingBottom: theme.spacing(8),
    },

    settings: {
      margin: theme.spacing(4, 0),
      position: "relative",
      backgroundColor: theme.palette.background.paper,
    },

    spinner: {
      color: theme.palette.activity.checkin,
      marginTop: theme.spacing(0.25),
    },

    publishing: {
      "& .MuiAlert-root": {
        boxShadow: theme.shadows[4],
        borderRadius: theme.spacing(0.5),
        paddingRight: theme.spacing(10),
        color: theme.palette.activity.checkin,
        backgroundColor: theme.palette.common.white,
      },
    },

    previewBarPublishButton: {
      fontWeight: "bold",
      color: theme.palette.common.white,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: `${theme.palette.primary.main}4D`,
      marginRight: theme.spacing(2),

      "&.Mui-disabled": {
        color: `${colorSystem.white}4D`,
      },
    },

    previewContainer: {
      padding: theme.spacing(2),
      maxWidth: 1100,
      marginInline: "auto",
      pointerEvents: "none",
    },
  };
});

export type OverridesWithCoordinatesType = {
  id: number;
  day: number;
  weekNumber: number;
};

export type PublishOverrideArgs = {
  day: number;
  weekNumber: number;
};

type SaveInput = Omit<UpdateComponentCommand, "days" | "init" | "toJSON"> & {
  status?: ComponentStatus;
  days?: Schedule["days"];
};

export interface CoachComponentProps extends Omit<ContainerProps, "children"> {
  componentData: CurriculumComponent;
  readOnly?: boolean;
  autoSaveDelay?: number;
  programName?: string;
  previewMode?: boolean;
}

export default function CoachComponent(props: CoachComponentProps) {
  //
  //
  // DATA

  const {
    componentData: component,
    readOnly,
    autoSaveDelay = 1500,
    previewMode: previewQueryParam,
  } = props;

  const {
    weekId,
    days,
    duration,
    repeat,
    reminderType,
    reminderTime,
    messageTime,
  } = component;

  const initSchedule = {
    weekId: weekId,
    days: JSON.parse(days),
    duration,
    repeat: repeat as ComponentRepeat,
    reminderType: reminderType as ReminderType,
    reminderTime: reminderTime,
    messageTime: messageTime,
  };

  const devCookie = getCookie("stabilization");
  const autoSaveEnabled = !readOnly;
  const componentContent = component.content
    ? JSON.parse(component.content)
    : null;

  const IS_PENDING_CHANGES =
    component.draftExist && component.status === ComponentStatus.PUBLISHED;

  //
  //
  // CONTEXTS

  const dispatch = useCurriculumDispatch();
  const { openSidebar } = useSidebar();
  const theme = useTheme();
  const tempWeeksIdsMap = useCurriculumSelector(selectWeekTempIdsMap);
  const tempComponentsMap = useCurriculumSelector(selectComponentTempIdsMap);
  const user = useCurrentUser();
  const componentType = component.type as ComponentType;
  const defaultTitle = getComponentDefaultTitle(componentType);
  const location = useLocation();
  const s = useStyles();
  const editor = useNewEditor();
  const programWeeks = useProgramWeeks();
  const { typeName } = getComponentSpecifics(componentType);
  const { closeDialog } = useDialog();

  //
  //
  // STATES

  const [version, setVersion] = React.useState(Date.now());
  const [componentBarHeight, setComponentBarHeight] = React.useState(78); // default init desktop header height
  const [validationErrors, setValidationErrors] = React.useState<any>(null);
  const [dirty, setDirty] = React.useState(false);
  const [saved, setSaved] = React.useState(true);
  const [preview, setPreview] = usePreview();
  const [unsafe, historyBlockUpdate] = useHistoryBlock();
  const [schedulePanelOpen, setSchedulePanelOpen] = React.useState(false);
  const [promptPanelOpen, setPromptPanelOpen] = React.useState(false);
  const [messagePanelOpen, setMessagePanelOpen] = React.useState(false);
  const [habitPrompt, setHabitPrompt] = React.useState(component.habitPrompt);
  const [schedule, setSchedule] = React.useState<Schedule>(initSchedule);
  const [title, setTitle] = React.useState(
    defaultTitle === component.title ? "" : component.title,
  );
  const [savingState, setSavingState] = React.useState(
    CoachComponentSavingState.EMPTY,
  );
  const [messageContent, setMessageContent] = React.useState<Node[] | null>(
    JSON.parse(component.messageContent) || [],
  );
  const [content, setContent] = React.useState<Node[] | null>(
    componentContent &&
      !(Array.isArray(componentContent) && componentContent.length === 0)
      ? componentContent
      : [getEmptyNode()],
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPendingDialogOpen, setIsPendingDialogOpen] = React.useState(false);

  const checkedRealId = React.useMemo(
    () => tempComponentsMap[component.id] ?? component.id,
    [tempComponentsMap, component.id],
  );

  //
  //
  // Get last published content

  const { data: componentVersions, isLoading } = useQuery({
    queryKey: [LAST_PUBLISHED_CONTENT_QUERY_KEY],
    queryFn: () => ComponentsService.getLastPublishedContent(checkedRealId),
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  const LAST_PUBLISHED_CONTENT =
    componentVersions?.lastPublishedValues?.content;

  //
  //
  // USE_EFFECTS

  React.useEffect(() => {
    setSchedule(initSchedule);
  }, [component]);

  React.useEffect(() => {
    if (promptPanelOpen && (schedulePanelOpen || messagePanelOpen)) {
      setPromptPanelOpen(false);
    }
  }, [schedulePanelOpen, messagePanelOpen]);

  React.useEffect(() => {
    if (schedulePanelOpen && (promptPanelOpen || messagePanelOpen)) {
      setSchedulePanelOpen(false);
    }
  }, [promptPanelOpen, messagePanelOpen]);

  React.useEffect(() => {
    if (messagePanelOpen && (schedulePanelOpen || promptPanelOpen)) {
      setMessagePanelOpen(false);
    }
  }, [schedulePanelOpen, promptPanelOpen]);

  React.useEffect(() => {
    historyBlockUpdate(dirty);
  }, [dirty, historyBlockUpdate]);

  //
  //
  // SAVE DATA

  // Main function to dispatch component update
  const SaveComponentData = React.useCallback(
    (data: SaveInput, publish?: boolean) => {
      setSavingState(CoachComponentSavingState.SAVING);
      setDirty(false);
      setSaved(true);
      setDirty(false);
      setValidationErrors(null);
      historyBlockUpdate(false);

      data.status === ComponentStatus.PUBLISHED && closeAfterSave();

      const UPDATED_COMPONENT: IUpdateComponentCommand & {
        draftExist: boolean;
      } = {
        ...data,
        days: JSON.stringify(data.days),
        weekId: tempWeeksIdsMap[data.weekId] ?? data.weekId,
        title: data.title || defaultTitle,
        shouldApplyDraft: publish,
        draftExist: !publish,
      };

      const actionData = {
        ...UPDATED_COMPONENT,
        updatedAt: dayjs(),
        ...(publish ? { lastPublished: dayjs() } : {}),
      };

      dispatch(
        updateComponentProps({
          componentId: component.id,
          data: actionData,
          tempWeeksIdsMap,
        }),
      );

      setSavingState(CoachComponentSavingState.SAVED);
    },
    [],
  );

  const NoPublishWrapper = React.useCallback(
    (data: SaveInput) => {
      SaveComponentData(data);
    },
    [SaveComponentData],
  );

  // Debounce for main save function
  const handleDebounceSave = React.useMemo(
    () => debounce(NoPublishWrapper, autoSaveDelay),
    [],
  );

  // Alternative function to publish draft content as override
  const PublishAsOverride = React.useCallback(
    (data: SaveInput, selected: { week: number; day: number }) => {
      const overrideWeekId = programWeeks.find(
        (w) => w.week === selected.week,
      )?.id;

      const overrideWeekDayPattern = JSON.stringify(
        Array.from({ length: 7 }, (_, i) => i + 1 === selected.day),
      );

      const requestData: Partial<CurriculumComponent> = {
        ...data,
        baseComponentId: data.id,
        days: overrideWeekDayPattern,
        weekId: overrideWeekId,
      };

      setSavingState(CoachComponentSavingState.SAVING);
      setDirty(false);
      setSaved(true);
      setDirty(false);
      setValidationErrors(null);
      historyBlockUpdate(false);

      dispatch(
        overrideComponent({
          baseComponentId: component.id,
          weekId: overrideWeekId,
          days: overrideWeekDayPattern,
          data: requestData,
        }),
      );
    },
    [programWeeks, component],
  );

  // Helper function for updated data preprocessing
  const getDataToSave = React.useCallback(
    (update: SaveInput = {}) => {
      const isContentUpdated = !!update.content;
      if (isContentUpdated) {
        update.content = JSON.stringify(
          unfixLegacyContent(JSON.parse(update.content)),
        );
      }
      return {
        ...schedule,
        messageTimeString: schedule.messageTime || null,
        reminderTimeString: schedule.reminderTime || null,
        title,
        habitPrompt,
        ...(!isContentUpdated && {
          content: JSON.stringify(unfixLegacyContent(content)),
        }),
        ...update,
      };
    },
    [content, schedule, title, habitPrompt],
  );

  //
  //
  // HANDLERS

  const handleOverrideReady = (slug: string) => {
    openSidebar(slug, SidebarTabs.SCHEDULE);
    setSavingState(CoachComponentSavingState.SAVED);
    closeAfterSave();
  };

  // Generic change handler
  const handleChange = React.useCallback(
    (update: SaveInput = {}) => {
      setDirty(true);
      setSaved(false);
      if (autoSaveEnabled) {
        const data = getDataToSave(update);
        handleDebounceSave(data);
      }
    },
    [autoSaveEnabled, getDataToSave, handleDebounceSave],
  );

  const handlePublish = React.useCallback(() => {
    const data = getDataToSave({
      status: ComponentStatus.PUBLISHED as ComponentStatus,
    });
    SaveComponentData(data, true);
  }, [s.spinner, s.publishing, SaveComponentData, getDataToSave]);

  const handlePublishOverride = React.useCallback(
    (selected: { week: number; day: number }) => {
      const data = getDataToSave({
        status: ComponentStatus.PUBLISHED as ComponentStatus,
      });
      PublishAsOverride(data, selected);
    },
    [s.spinner, s.publishing, getDataToSave, PublishAsOverride],
  );

  const handleTitleChange = React.useCallback(
    (event) => {
      const title = event.target.value;

      setTitle(title);
      handleChange({ title });
    },
    [handleChange],
  );

  const handleHabitPromptChange = React.useCallback(
    (habitPrompt: string) => {
      setDirty(true);
      setHabitPrompt(habitPrompt);

      handleChange({ habitPrompt });
    },
    [handleChange],
  );

  const handleMessageContentChange = React.useCallback(
    (value: Node[] | null) => {
      setDirty(true);
      setMessageContent(value);
      handleChange({ messageContent: JSON.stringify(value) });
    },
    [handleChange],
  );

  const handleContentChange = React.useCallback(
    (value: Node[] | null) => {
      setContent(value);
      handleChange({ content: JSON.stringify(value) });
    },
    [handleChange],
  );

  const handleCoverImageChange = React.useCallback(
    (image: string) => {
      setDirty(true);
      handleChange({ image });
    },
    [handleChange],
  );

  const handleContentTemplate = React.useCallback(
    (template: ComponentTemplate) => {
      setTitle(template.title);
      setHabitPrompt(template.habitPrompt);
      setContent(JSON.parse(template.content));
      setVersion(Date.now());

      handleChange(template);
    },
    [handleChange],
  );

  const handleDiscardPendingChanges = (content: string) => {
    const data = getDataToSave({
      status: ComponentStatus.PUBLISHED as ComponentStatus,
      content,
    });
    SaveComponentData(data, true);
  };

  //
  //
  // UI HANDLERS

  // Prompt panel
  const handlePromptPanelChange: AccordionProps["onChange"] = React.useCallback(
    (_, expanded) => {
      if (promptPanelOpen !== expanded) {
        setPromptPanelOpen(expanded);
      }
    },
    [promptPanelOpen],
  );

  // Message panel
  const handleMessagePanelChange: AccordionProps["onChange"] =
    React.useCallback(
      (_, expanded) => {
        if (messagePanelOpen !== expanded) {
          setMessagePanelOpen(expanded);
        }
      },
      [messagePanelOpen],
    );

  // Close component dialog after save
  const closeAfterSave = React.useCallback(() => {
    setPreview(null);
    closeDialog();
  }, [location.pathname, setPreview, typeName, unsafe]);

  // Close component dialog common handler
  const handleCloseDialog = () => {
    IS_PENDING_CHANGES ? handleOpenPendingDialog() : closeDialog();
  };

  // Open pending changes dialog
  const handleOpenPendingDialog = () => setIsPendingDialogOpen(true);

  // Close pending changes dialog
  const handleClosePendingDialog = () => setIsPendingDialogOpen(false);

  const isEmpty = React.useMemo(() => isEmptyContent(content), [content]);

  const overrides = useCurriculumSelector(
    selectOverridesWithCoordinates(component.overrides),
  );

  const repeatsAmount = countScheduleRepeats(
    schedule,
    programWeeks.find((w) => w.id === weekId).week,
    programWeeks.length,
    overrides,
  );

  // Prevent browser navigation
  useNavigationGuard(
    IS_PENDING_CHANGES,
    "Component still has pending changes",
    "Publish or discard pending changes",
  );

  return (
    <ComponentBarHeightContext.Provider
      value={{
        componentBarHeight,
        setComponentBarHeight,
      }}
    >
      <EditorDirty.Provider value={setDirty}>
        <EditorCoverImageContext.Provider value={handleCoverImageChange}>
          <EditorComponentItemDisabledContext.Provider
            value={(type: string) => menuItemDisabled(type, content)}
          >
            <CheckInComponentContext.Provider
              value={{
                view:
                  preview || previewQueryParam
                    ? EditorElementView.Preview
                    : EditorElementView.Coach,
              }}
            >
              {preview && !previewQueryParam ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pt: theme.spacing(PREVIEW_BAR_SPACING),
                    height: "100vh",
                    width: "100%",
                    my: "auto",
                  }}
                >
                  <PreviewBar
                    actions={
                      <ComponentPublishButton
                        className={s.previewBarPublishButton}
                        componentData={{ ...component }}
                        onClick={handlePublish}
                        empty={isEmpty}
                        dirty={dirty && !saved}
                      />
                    }
                  />
                  <PreviewBox>
                    <Box className={s.previewContainer}>
                      <CoachComponentPreview component={component} />
                    </Box>
                  </PreviewBox>
                </Box>
              ) : (
                <>
                  <Container
                    maxWidth="md"
                    className={s.root}
                    style={{
                      maxWidth: isSidebarOpen
                        ? COMPONENT_MAX_WIDTH + CURRICULUM_SIDEBAR_WIDTH
                        : COMPONENT_MAX_WIDTH,
                      transition: getCommonTransition(theme, "max-width"),
                      paddingTop: `calc(${componentBarHeight}px + ${theme.spacing(8)})`,
                    }}
                  >
                    <Box
                      sx={{
                        mr: isSidebarOpen && `${CURRICULUM_SIDEBAR_WIDTH}px`,
                        transition: getCommonTransition(theme, "margin-right"),
                      }}
                    >
                      {!previewQueryParam && (
                        <>
                          <CoachComponentBar
                            componentData={component}
                            onSaveClick={handlePublish}
                            onCloseClick={handleCloseDialog}
                            savingState={savingState}
                            isEmpty={isEmpty}
                            isDirty={dirty}
                            editor={editor}
                            repeatsAmount={repeatsAmount}
                            onConfirmPublishOverride={handlePublishOverride}
                            onOverrideReady={handleOverrideReady}
                            handleDiscardPendingChanges={
                              handleDiscardPendingChanges
                            }
                            lastPublishedContent={LAST_PUBLISHED_CONTENT}
                          />
                          <Typography color="textSecondary" gutterBottom>
                            {typeName}
                          </Typography>
                          <CoachComponentTitle
                            component={component}
                            error={Boolean(
                              validationErrors && validationErrors.title,
                            )}
                            onChange={handleTitleChange}
                            placeholder={`Give Your ${typeName} a Title`}
                            defaultValue={defaultTitle}
                          />
                          <div className={s.settings}>
                            <ComponentScheduleReminder
                              component={component}
                              repeatsAmount={repeatsAmount}
                              setIsSidebarOpen={setIsSidebarOpen}
                            />
                          </div>
                          {component.type === ComponentType.HABIT && (
                            <div className={s.settings}>
                              <HabitPromptEdit
                                prompt={habitPrompt}
                                onChange={handleHabitPromptChange}
                                open={promptPanelOpen}
                                onPanelChange={handlePromptPanelChange}
                              />
                            </div>
                          )}
                          {component.type === ComponentType.MESSAGE && (
                            <div className={s.settings}>
                              <MessageComponentPanel
                                componentId={component.id}
                                content={messageContent}
                                onChange={handleMessageContentChange}
                                open={messagePanelOpen}
                                onPanelChange={handleMessagePanelChange}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {componentType !== ComponentType.MESSAGE && (
                        <>
                          <Suspense>
                            <ComponentEditor
                              key={version}
                              editor={editor}
                              value={content}
                              placeholder="Add your content here"
                              onChange={handleContentChange}
                              readOnly={readOnly || previewQueryParam}
                              disabled={Boolean(previewQueryParam)}
                              inlineToolbar
                              sidebar
                              deserialize
                              preNormalize
                              multiline
                              autoFocus
                              // STR-1896: Zero-delayed editor (BaseEditor) has unexpected behavior in CoachComponent
                              delay={1}
                              topToolbar={user.topToolbar}
                              useStickyTop
                            >
                              {component.type === ComponentType.WORKOUT &&
                                !previewQueryParam && <WorkoutAddButton />}
                              {component.type !== ComponentType.MESSAGE &&
                                !previewQueryParam &&
                                isEmptyContent(content) && (
                                  <UseComponentTemplate
                                    onApplyTemplate={handleContentTemplate}
                                    componentType={
                                      component.type as ComponentType
                                    }
                                  />
                                )}
                            </ComponentEditor>
                          </Suspense>
                          {!previewQueryParam && devCookie === "true" && (
                            <JsonEditor
                              content={content}
                              onContentChange={(newContent) => {
                                handleContentChange(newContent);
                                setVersion(Date.now());
                              }}
                            />
                          )}
                        </>
                      )}
                    </Box>
                  </Container>
                </>
              )}

              <PendingDialog
                component={component}
                open={isPendingDialogOpen}
                handlePublish={handlePublish}
                handleClose={handleClosePendingDialog}
                handleDiscardPendingChanges={handleDiscardPendingChanges}
                lastPublishedContent={LAST_PUBLISHED_CONTENT}
              />
            </CheckInComponentContext.Provider>
          </EditorComponentItemDisabledContext.Provider>
        </EditorCoverImageContext.Provider>
      </EditorDirty.Provider>
    </ComponentBarHeightContext.Provider>
  );
}
