import React from "react";
import { Box, Button, Collapse, Typography } from "@mui/material";
import ErrorBoundary from "./ErrorBoundary";
import { Outlet } from "react-router-dom";
import { getErrorMessage } from "../../hooks/useGenericErrorHandler";

interface ErrorScreenProps {
  error?: Error;
  onReset?: () => void;
}

const decodeError = (error: any) => {
  if (error.response) {
    const innerMessage = getErrorMessage(error.response);
    return typeof innerMessage === "string"
      ? innerMessage
      : Object.values(innerMessage).join(",");
  } else {
    return error.message || "Unknown error";
  }
};

export function ErrorScreen(props: ErrorScreenProps) {
  const { error, onReset } = props;

  const [showDetails, setShowDetails] = React.useState(false);

  const message = error && decodeError(error);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        textAlign: "center",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Something went wrong
      </Typography>
      {message && (
        <Box
          sx={{
            paddingBottom: 2,
          }}
        >
          <Collapse in={showDetails}>
            <Typography variant="body2" color="textSecondary">
              {message}
            </Typography>
          </Collapse>
          <Typography
            onClick={() => setShowDetails(!showDetails)}
            variant="body2"
            color="textSecondary"
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            See {showDetails ? "less" : "more"}
          </Typography>
        </Box>
      )}
      {onReset && (
        <Button variant="outlined" color="primary" onClick={onReset}>
          Try Again
        </Button>
      )}
    </Box>
  );
}

export default function ErrorBoundaryScreen({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorScreen}
      children={children || <Outlet />}
    />
  );
}
