import clsx from "clsx";
import React from "react";
import {
  DialogProps,
  Box,
  Button,
  Dialog,
  Typography,
  Container,
  IconButton,
  ListItemIcon,
  useTheme,
  useMediaQuery,
  ClassNameMap,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Close } from "@mui/icons-material";
import { polyfillCSS } from "../../utils/css";
import { colorSystem } from "../../theme";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PeopleIcon from "@mui/icons-material/People";
import { SvgIconComponent } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {},

  content: {
    padding: theme.spacing(1, 4, 2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 2, 1),
    },
  },

  paper: {
    maxWidth: 550,
  },

  header: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    top: 0,
    position: "sticky",
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    minHeight: 70,
  },

  headerText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },

  modalTitle: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: theme.spacing(0.7),
  },

  close: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    padding: 0,
    color: theme.palette.text.secondary,

    "&:hover": {
      color: theme.palette.text.primary,
    },
  },

  itemContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 15,
    padding: theme.spacing(1, 2, 2),
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      padding: theme.spacing(1.7, 2),
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "initial",
    "& hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },

  primaryText: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  secondaryText: {
    textAlign: "left",
    fontSize: 14,
  },

  itemIcon: {
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
    },

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  link: {
    fontWeight: 500,
    textDecoration: "underline",
  },

  itemHeader: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  },

  itemContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
}));

export enum InfoItemName {
  COMMUNITY = "Active community",
  RESOURCE_CENTER = "Resource Centre",
  EMAIL_SUPPORT = "Email Support",
  ONBOARDING_CALL = "Onboarding Call",
}

export type InfoItemClassKey =
  | "item"
  | "icon"
  | "primaryText"
  | "secondaryText";
interface InfoItemProps {
  icon: SvgIconComponent;
  iconColor: string;
  iconSize?: number;
  title: string;
  description: any;
  link?: any;
  classes?: Partial<ClassNameMap<InfoItemClassKey>>;
}

const InfoItem = ({
  icon: Icon,
  iconColor = colorSystem.blue1,
  iconSize = 40,
  title,
  description,
  classes = {},
  link,
}: InfoItemProps) => {
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdDown = useMediaQuery(breakpoints.down("md"));
  return (
    <Box
      className={clsx(s.itemContainer, classes?.item)}
      sx={{ border: ` 2px solid ${iconColor}`, borderRadius: 3 }}
    >
      {!mdDown && (
        <ListItemIcon className={clsx(s.itemIcon, classes?.icon)}>
          <Icon style={{ color: iconColor, fontSize: iconSize }} />
        </ListItemIcon>
      )}
      <Box className={s.itemContent}>
        <Box className={s.itemHeader}>
          {mdDown && (
            <ListItemIcon className={clsx(s.itemIcon, classes?.icon)}>
              <Icon style={{ color: iconColor, fontSize: iconSize }} />
            </ListItemIcon>
          )}
          <Typography className={clsx(s.primaryText, classes?.primaryText)}>
            {title}
          </Typography>
        </Box>
        {description && (
          <>
            <Typography
              className={clsx(s.secondaryText, classes?.secondaryText)}
            >
              {description}
            </Typography>
          </>
        )}
        {link && (
          <Typography className={clsx(s.secondaryText, classes?.secondaryText)}>
            {link}
          </Typography>
        )}
      </Box>{" "}
    </Box>
  );
};

export interface GetAnyHelpModal extends DialogProps {
  items?: InfoItemName[];
}

export function GetAnyHelpModal(props: GetAnyHelpModal) {
  const { className, onClose, items, ...other } = props;
  const s = useStyles();

  const defaultInfoItems = [
    {
      type: InfoItemName.COMMUNITY,
      icon: PeopleIcon,
      iconColor: colorSystem.orange,
      title: "Active community",
      description:
        "Join our community - ask questions, share your experience, and meet new people.",
      link: (
        <a
          href="https://skool.com/stridist-3989"
          title="Go to Stridist Community"
          target="_blank"
          className={s.link}
        >
          https://skool.com/stridist-3989
        </a>
      ),
    },
    {
      type: InfoItemName.RESOURCE_CENTER,
      icon: LibraryBooksIcon,
      iconColor: colorSystem.green2,
      title: "Resource Centre",
      description:
        "Head to our resource centre - here you will find pre-recorded videos for every feature of Stridist.",
      link: (
        <a
          href="https://stridist.tawk.help"
          title="Go to Stridist Resource Center"
          target="_blank"
          className={s.link}
        >
          https://stridist.tawk.help
        </a>
      ),
    },
    {
      type: InfoItemName.EMAIL_SUPPORT,
      icon: EmailOutlinedIcon,
      iconColor: colorSystem.purple2,
      title: "Email Support",
      description:
        "Send us an email – tell us what you need help with and the team will be back in touch ASAP!",
      link: (
        <a
          href="mailto:accounts@stridist.com"
          title="Send an email to Stridist support"
          className={s.link}
        >
          accounts@stridist.com
        </a>
      ),
    },
    {
      type: InfoItemName.ONBOARDING_CALL,
      icon: LocalPhoneOutlinedIcon,
      iconColor: colorSystem.yellow,
      title: "Onboarding Call",
      description:
        "Meet the Stridist team - get a personalized walkthrough of Stridist and all its features.",
      link: (
        <a
          href="https://cal.com/team/stridist/vip-onboarding"
          target="_blank"
          rel="noopener noreferrer"
          title="Book a meeting time"
          className={s.link}
        >
          Book meeting time
        </a>
      ),
    },
  ];

  const infoItems = items
    ? defaultInfoItems.filter(({ type }) => items.includes(type))
    : defaultInfoItems;

  const handleCloseButtonClick = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  return (
    <Dialog
      className={clsx(s.root, className)}
      onClose={onClose}
      fullWidth
      PaperProps={{ className: s.paper }}
      {...other}
    >
      <Container className={clsx(s.content)}>
        <Box className={clsx(s.header)}>
          <IconButton
            className={s.close}
            onClick={handleCloseButtonClick}
            children={<Close />}
            size="large"
          />
          <Box className={s.headerText}>
            <Typography
              variant="h2"
              className={s.modalTitle}
              children="Need any help?"
            />
          </Box>
        </Box>

        {infoItems.map((item, index) => (
          <InfoItem key={index} {...item} />
        ))}
      </Container>
    </Dialog>
  );
}
