"use client";

import React from "react";

import { Box, Typography, useTheme } from "@mui/material";

type InsertElementMenuItemProps = {
  itemLabel: string;
  itemDescription: string;
  Icon: any;
};

export function InsertElementMenuItem({
  itemLabel,
  itemDescription,
  Icon,
}: InsertElementMenuItemProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 1.2,
        px: 1,
        py: 0.5,
      }}
    >
      <Box
        sx={{
          p: 1,
          borderRadius: 1,
          border: "1px solid",
          background: theme.palette.common.white,
          aspectRatio: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon className="h-5 w-5" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "700", lineHeight: 1.4 }}>
          {itemLabel}
        </Typography>
        <Typography
          variant="caption"
          component={"p"}
          sx={{
            color: theme.palette.grey[600],
            lineHeight: 1.1,
          }}
        >
          {itemDescription}
        </Typography>
      </Box>
    </Box>
  );
}
