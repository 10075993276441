import clsx from "clsx";
import React, { ReactNode } from "react";
import { Box, Fade, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Image, SvgIconComponent, GetApp } from "@mui/icons-material";
import { Crop } from "react-image-crop";
import { UPLOADED_FILE_NAME_REGEX } from "../../../../constants";
import { ImageDimensions, ResizableImage } from "../../../image/ResizableImage";
import { humanReadableFileSize } from "../../../../utils/file";
import { useEditorCoverImage } from "../../../../hooks/useEditorCoverImage";
import { CropImageDialog } from "../../../dialog/CropImageDialog";
import {
  PlateElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { withRef } from "@udecode/cn";
import { ReactEditor, useSelected } from "slate-react";
import { node } from "slate";
import { CropScaled } from "../../../editor/types/legacy";
import { ElementType, MediaElementType } from "../../../editor/types/elements";
import { EmbedFileDialog } from "../../modals/EmbedFileDialog";
import { Image as ImageIcon } from "lucide-react";
import { useReadOnly } from "../../hooks";
import {
  MediaAlign,
  MediaElementMenuProps,
} from "../../menus/MediaElementMenu";
import { MediaAlignBox } from "../../components/containers/MediaAlignBox";
import { EmbedElementType, MoreMenu } from "../MoreMenu";
import { useToastAlert } from "../../../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 56,
    borderRadius: theme.shape.borderRadius * 2,
    "&:hover": {
      cursor: "pointer",
    },
    "&$empty": {
      width: "100%",
      display: "block",
      backgroundColor: theme.palette.depressed,
    },

    "&:hover button": {
      opacity: 1,
    },
  },
  wrapper: {
    border: "1px solid transparent",
    boxSizing: "border-box",
    borderRadius: theme.shape.borderRadius * 2,
    position: "relative",
    "&$empty": {
      position: "inherit",
      display: "flex",
      alignItems: "baseline",
      justifyContent: "flex-start",
      width: "100%",
      height: "100%",
    },
  },
  file: {
    width: "100%",
  },
  attachFileIcon: {
    transform: "rotate(45deg)",
  },

  fileButton: {
    display: "flex",
    whiteSpace: "nowrap",
    width: "100%",
    padding: theme.spacing(2),
    margin: "auto",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing(1),
  },
  filePreview: {
    display: "flex",
    width: "100%",
    whiteSpace: "nowrap",
  },

  fileIcon: {
    color: theme.palette.secondary.main,
  },
  fileName: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    marginLeft: 21,
    marginRight: "auto",
    maxWidth: "80%",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  },
  fileSize: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 1.5),
  },

  audioPlayer: {
    "& audio": {
      minHeight: 40,
    },
  },
  videoPlayerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
  },
  videoPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  text: {
    fontSize: 14,
    marginLeft: theme.spacing(3),
    color: theme.palette.text.secondary,
  },
  empty: {},
  video: {},
  audio: {},
  selected: {},
  image: {
    "&:not($empty)": {
      padding: 2,
    },
    "&$selected:not($empty)": {
      boxShadow: `0px 0px 0px 2px ${theme.palette.primary.main}`,
      borderRadius: theme.spacing(0.5),
    },
  },
  processing: {
    display: "flex",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.depressed,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2, 4),
    "& $text": {
      color: theme.palette.common.black,
      marginLeft: theme.spacing(2),
      fontWeight: 500,
    },
  },
  spinner: {
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
  },
  hidden: {
    display: "none",
  },
}));

export interface Media {
  url: string;
  size?: number;
  name?: string;
  dimensions?: ImageDimensions;
  crop?: CropScaled;
  mimeType?: string;
  align?: string;
}

export interface MediaElementProps /*extends RenderElementProps */ {
  editor: any;
  element;
  children;
  path;
  attributes;
  handleChange: (
    url: string,
    name: string,
    size: number,
    mimeType: string,
    dimensions: ImageDimensions,
    crop: CropScaled,
    align: string,
  ) => void;
  handleRemoveNode: () => void;
  ref: any;
}

const emptyMedia = (): Media => ({
  url: "",
});

const mediaFromElement = (element): Media => {
  const url = (element.url as string) || "";
  const name = (element.name as string) || null;
  const size = (element.size as number) || null;
  const mimeType = (element.mimeType as string) || null;
  const dimensions = (element.dimensions as ImageDimensions) || null;
  const crop = (element.crop as CropScaled) || null;
  const align = (element.align as string) || null;

  return url
    ? {
        url,
        name,
        size,
        mimeType,
        dimensions,
        crop,
        align,
      }
    : emptyMedia();
};

export const MediaElement = (props: MediaElementProps) => {
  const {
    element,
    attributes,
    children,
    editor,
    path,
    handleRemoveNode,
    handleChange,
  } = props;
  const media = mediaFromElement(element);
  //const [media, setMedia] = React.useState<Media>(mediaFromElement(element));
  const [previousMedia, setPreviousMedia] = React.useState<Media>(emptyMedia());
  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLElement | null>(
    null,
  );
  const s = useStyles();
  const readOnly = useReadOnly();
  const isSelected = useSelected();
  const level = path.length;
  const myRef = React.useRef(null);
  const isUploaded = React.useMemo(() => {
    return media.url && UPLOADED_FILE_NAME_REGEX.test(media.url);
  }, [media]);
  const [cropOpen, setCropOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const { showToastAlert } = useToastAlert();

  React.useEffect(() => {
    setPopoverAnchor((!media.url && attributes.ref.current) || null);
  }, [media.url, attributes.ref]);

  // React.useEffect(() => {
  //   if ((element as any).undo) {
  //     setMedia(previousMedia);
  //     setPreviousMedia(media);
  //     Transforms.setNodes(editor, { undo: false }, { at });
  //   }
  // }, [(element as any).undo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBlockClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!media.url && !openDialog) {
        setOpenDialog(true);
      }
    },
    [media, openDialog],
  );

  const handleMediaChange = React.useCallback(
    (newMedia: Media) => {
      if (newMedia.url !== media.url) {
        setPreviousMedia(media);
        handleChange(
          newMedia.url,
          newMedia.name,
          newMedia.size,
          newMedia.mimeType,
          newMedia.dimensions,
          newMedia.crop,
          newMedia.align,
        );
      }
    },
    [media],
  );

  const handleChangeClick = React.useCallback(
    (event: React.MouseEvent) => {
      if (isUploaded) {
        handleMediaChange(emptyMedia());
      }

      setOpenDialog(true);
    },
    [media, isUploaded, handleMediaChange, myRef.current],
  );

  const handleCropClick = React.useCallback(() => {
    setCropOpen(true);
  }, []);

  const handleCrop = React.useCallback(
    (crop: Crop & CropScaled) => {
      const dimensions = { width: crop.width, height: crop.height };
      const newMedia = { ...media, crop, dimensions };

      handleChange(
        newMedia.url,
        newMedia.name,
        newMedia.size,
        newMedia.mimeType,
        newMedia.dimensions,
        newMedia.crop,
        newMedia.align,
      );
    },
    [media],
  );

  const handleAlign = React.useCallback(
    (align: string) => {
      handleChange(
        media.url,
        media.name,
        media.size,
        media.mimeType,
        media.dimensions,
        media.crop,
        align,
      );
    },
    [media],
  );

  const handleCloseCrop = React.useCallback(() => {
    setCropOpen(false);
  }, []);

  const handleResize = React.useCallback(
    (event, dimensions) => {
      const newMedia = {
        ...media,
        dimensions,
      };

      handleChange(
        newMedia.url,
        newMedia.name,
        newMedia.size,
        newMedia.mimeType,
        newMedia.dimensions,
        newMedia.crop,
        newMedia.align,
      );
    },
    [media],
  );

  const handleClose = () => {
    setOpenDialog(false);
  };

  const renderFileBlock = React.useMemo(() => {
    return (
      <>
        <GetApp className={s.fileIcon} />
        <Typography component="span" className={s.fileName}>
          {media.name || media.url}
        </Typography>
        {media.size && (
          <Typography component="span" className={s.fileSize}>
            {humanReadableFileSize(media.size)}
          </Typography>
        )}
      </>
    );
  }, [media.name, media.size, media.url]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleImageClick = React.useCallback(
    (event) => {
      const caption = !readOnly && element.children[0];
      const node = caption && ReactEditor.toDOMNode(editor, caption);

      if (node) {
        const range = new Range();

        range.setStart(node, 0);
        range.setEnd(node, 0);

        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);
      }
    },
    [editor, element.children, readOnly],
  );

  const getMediaSpecifics = (
    type: MediaElementType,
  ): [SvgIconComponent, ReactNode, ReactNode] => {
    switch (type) {
      case ElementType.IMAGE:
        return [
          Image,
          "Upload or embed an image",

          <ResizableImage
            src={media.url}
            onClick={handleImageClick}
            onResize={handleResize}
            dimensions={media.dimensions || ({} as ImageDimensions)}
            crop={media.crop || ({} as CropScaled)}
            readOnly={readOnly || level !== 1}
          />,
        ];
    }
  };

  const getAlignmentVariantIndex = (variant: string) => {
    switch (variant) {
      case MediaAlign.LEFT:
        return 0;
      case MediaAlign.CENTER:
        return 1;
      case MediaAlign.RIGHT:
        return 2;
      case MediaAlign.FULL_WIDTH:
        return 3;
      default:
        return 1;
    }
  };

  const [Icon, text, renderMedia] = getMediaSpecifics(
    (element as any).type as MediaElementType,
  );

  const onCoverChange = useEditorCoverImage();
  const handleCoverChange: MediaElementMenuProps["onCoverChange"] =
    React.useCallback(
      (event, value) => {
        onCoverChange(value);
        showToastAlert("success", {
          message: "Image set as cover.",
        });
      },
      [onCoverChange],
    );

  const isNonEmptyImage =
    (element as any).type === ElementType.IMAGE && media.url;
  const Wrapper = isNonEmptyImage ? MediaAlignBox : React.Fragment;
  const wrapperAttrs = isNonEmptyImage
    ? {
        element,
        editor,
        node,
        path,
        onSetAlign: handleAlign,
        onRemove: handleRemoveNode,
      }
    : {};

  if (!media?.url && readOnly) return <></>;

  return (
    <Box
      ref={myRef}
      sx={{
        width: "100vw",
        display: "flex",
        flexWrap: "wrap",
        gap: 1.5,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: !isNonEmptyImage ? 2.5 : 0,
        pt: 1.4,
        pr: 0,
        pl: !isNonEmptyImage ? 2.5 : 0,
        "&:hover button": {
          opacity: 1,
        },
        border: (theme) =>
          !isNonEmptyImage ? `${theme.shape.border.xs}px solid` : "none",
        borderRadius: 1,

        backgroundColor: (theme) =>
          !isNonEmptyImage ? theme.palette.selected.light : null,
        borderColor: (theme) => theme.palette.border.primary,
      }}
      onClick={handleBlockClick}
    >
      <Wrapper key={(element as any).id as number} {...(wrapperAttrs as any)}>
        <div
          contentEditable={false}
          className={clsx(s.wrapper, {
            [s.empty]: !media.url,
            [s.image]: (element as any).type === ElementType.IMAGE,
            [s.video]: (element as any).type === ElementType.VIDEO,
            [s.audio]: (element as any).type === ElementType.AUDIO,
            [s.file]: (element as any).type === ElementType.FILE && !readOnly,
            [s.selected]: isSelected,
          })}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {!readOnly && media && (
            <Box visibility={isHovered ? "visible" : "hidden"}>
              <MoreMenu
                empty={!isNonEmptyImage}
                element={element}
                handleRemoveNode={handleRemoveNode}
                handleChangeClick={handleChangeClick}
                handleCrop={handleCropClick}
                handleCoverChange={handleCoverChange}
                type={EmbedElementType.IMAGE}
                handleAlignChange={handleAlign}
                activeIndex={getAlignmentVariantIndex(
                  (element as any).align as MediaAlign,
                )}
                isMenuOpen={isHovered}
              ></MoreMenu>
            </Box>
          )}
          {media.url ? (
            renderMedia
          ) : (
            <Box sx={{ pt: 1, display: "flex", gap: 1 }}>
              <IconButton
                sx={{ color: (theme) => theme.palette.text.disabled, p: 0 }}
              >
                <ImageIcon />
              </IconButton>
              <Typography
                sx={{ color: (theme) => theme.palette.text.disabled }}
              >
                {text}
              </Typography>
            </Box>
          )}
        </div>
        <div
          className={clsx({
            [s.hidden]: !media.url,
          })}
        >
          {children}
        </div>
      </Wrapper>

      {!readOnly && (
        <EmbedFileDialog
          url={element.url as string}
          size={element.size as number}
          name={element.name as string}
          mimeType={element.mimeType as string}
          open={openDialog}
          type={EmbedElementType.IMAGE}
          title="Upload an Image"
          accept="image/*"
          onSubmit={(
            url: string,
            name: string,
            size: number | null,
            mimeType: string,
          ) => {
            handleChange(
              url,
              name,
              size,
              mimeType,
              {} as ImageDimensions,
              {} as CropScaled,
              media.align,
            );
          }}
          onClose={handleClose}
        ></EmbedFileDialog>
      )}
      {!readOnly && (element as any).type === ElementType.IMAGE && cropOpen && (
        <CropImageDialog
          open={cropOpen}
          src={(element as any).url as string}
          crop={media.crop}
          onCrop={handleCrop}
          onClose={handleCloseCrop}
        />
      )}
    </Box>
  );
};

export const ImageMediaElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element, attributes } = props;
    const editor = useEditorRef();
    const path = findNodePath(editor, element);

    if (!path) return;

    const handleRemoveNode = () => {
      removeNodes(editor, { at: path });
    };
    const handleChange = React.useCallback(
      (url, name, size, type, dimensions, crop, align) => {
        const media: Media = {
          url,
          name,
          size,
          mimeType: type,
          crop,
          dimensions,
          align,
        };
        setNodes(editor, { ...media }, { at: path });
      },
      [],
    );

    return (
      <PlateElement
        ref={ref}
        {...props}
        style={{
          width: "auto",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          borderRadius: 1,
          justifyContent: "center",
        }}
      >
        <MediaElement
          editor={editor}
          element={element}
          attributes={attributes}
          children={children}
          handleRemoveNode={handleRemoveNode}
          handleChange={handleChange}
          path={path}
          ref={ref}
        />
      </PlateElement>
    );
  },
);
