import React from "react";
import { Add } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { COACH_PROGRAM_CREATE_ROUTE } from "../../../routes/routes";

import { Link } from "../../link/Link";
import { HeaderButton } from "../../button/HeaderButton";

export function CreateButton(props: ButtonProps) {
  return (
    <HeaderButton
      startIcon={<Add />}
      component={Link}
      href={COACH_PROGRAM_CREATE_ROUTE}
      children="Create a program"
      {...props}
    />
  );
}
