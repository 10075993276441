import clsx from "clsx";
import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardProps,
  Select,
  SelectProps,
  Typography,
  Switch,
  capitalize,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  BillingPlan,
  billingPlanPrice,
  monthlyPlansInOrder,
  Plan,
  planClients,
  planOrder,
  planStatus,
  PlanTier,
  SOMETHING_WENT_WRONG,
  tierIndex,
  yearlyPlansInOrder,
} from "../../constants";

import { useQueryParam } from "../../hooks/useQueryParam";
import { initStripe, stripeAction } from "../../stripe";
import { LinkButton } from "../button/LinkButton";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../../hooks/useCurrentUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import BillingsService from "../../services/BillingsService";
import { COACH_PLAN_ROUTE } from "../../routes/routes";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import {
  getNextHigherPlan,
  getPlanAction,
  humanizeUserPlan,
  isPlanYearly,
  monthlyBillingPlan,
} from "../../utils/user";
import { SelectClientsDialog } from "../dialog/SelectClientsDialog";
import ClientsService from "../../services/ClientsService";
import useArchiveClientsMutation from "../coach-clients/mutations/useArchiveClientsMutation";
import {
  ClientInfoDto,
  CustomerPortalFlowType,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { SubscriptionUpdatePolling } from "./SubscriptionUpdatePolling";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useMediaMobile } from "../../hooks/useMediaMobile";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 3),

    [theme.breakpoints.up("md")]: {
      width: 562,
      padding: theme.spacing(6, 5),
    },
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(0.5),
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },

  field: {
    marginBottom: theme.spacing(3),

    "& > $label": {
      marginBottom: theme.spacing(2),
    },
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },

  billingPlans: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(5),
  },

  billingPlan: {
    display: "none",
  },

  save: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: 0.75,
    lineHeight: "16px",
    padding: theme.spacing(1, 1.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.progress.green,
    backgroundColor: "#E5F6EC", // since alpha makes it transparent
    textAlign: "center",
    width: 114,
    height: 30,
    position: "absolute",
    top: -15,
    left: theme.spacing(3),
  },

  price: {
    marginTop: theme.spacing(2),
  },

  bottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  amount: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  unit: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    letterSpacing: 0.75,
  },

  totalWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    position: "relative",
  },

  totalDescription: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },

  changeCard: {
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "underline",
    paddingLeft: 0,
    paddingRight: 0,
  },

  changeCardBox: {
    position: "relative",
    flexDirection: "column",
    top: 20,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },

  button: {
    height: 56,
    padding: theme.spacing(2),
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.common.white,
      opacity: 0.5,
    },

    [theme.breakpoints.up("sm")]: {
      width: 300,
    },
  },
  titleModal: {
    lineBreak: "anywhere",
  },
  icon: {
    fontSize: theme.typography.fontSize * 1.5,
  },

  highlightedText: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 1.5, 0.5, 0.8),
    borderRadius: theme.shape.radius.md,
    marginLeft: theme.spacing(1),
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: "1.5",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
      padding: theme.spacing(0.5, 1.5, 0.5, 0.8),
    },
  },

  crossedOutPrice: {
    position: "absolute",
    top: -20,
    left: 0,
    textDecoration: "line-through",
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: "bold",
  },

  hidden: {
    display: "none",
  },

  planUpgradeInfo: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));

export interface ProUpgradeCardProps extends CardProps {
  user: UserInfo;
  onPlanChange?: (plan: Plan) => void;
  setShowTrialingDialogOpen?: (value) => void;
}

enum UpgradeStatus {
  SUCCESS = "success",
  ERROR = "error",
}
export function ProUpgradeCard(props: ProUpgradeCardProps) {
  const { className, user, onPlanChange, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const isMobile = useMediaMobile();
  const [newPlan] = useQueryParam("plan", "");
  const [downgraded] = useQueryParam("downgraded", "");
  const [prevStatus] = useQueryParam("prevStatus", "");
  const [planUpdateStatus] = useQueryParam<UpgradeStatus>("upgrade");
  const { showToastAlert } = useToastAlert();

  const [
    downgradeSelectClientsDialogOpen,
    setDowngradeSelectClientsDialogOpen,
  ] = useState(false);

  const nextHigherPlan = React.useMemo(() => {
    const userPlan = user.plan as Plan;
    return getNextHigherPlan(userPlan);
  }, [user.plan]);

  const defaultPlan = React.useMemo(() => {
    if (
      user?.subscription?.status === planStatus.CANCELED ||
      user?.subscription?.status === planStatus.INCOMPLETE_EXPIRED
    ) {
      return user.plan;
    }
    return newPlan ? (newPlan as Plan) : nextHigherPlan;
  }, [user, newPlan, nextHigherPlan]);

  const [selectedPlan, setSelectedPlan] = React.useState<Plan>(
    defaultPlan as Plan,
  );

  const [billingCycle, setBillingCycle] = React.useState<"monthly" | "annual">(
    user.plan.includes("YEARLY") ? "annual" : "monthly",
  );

  const handleBillingCycleToggle = () => {
    if (!user.plan.includes("YEARLY")) {
      const newBillingCycle = billingCycle === "monthly" ? "annual" : "monthly";
      setBillingCycle(newBillingCycle);

      // Map the selected plan to the corresponding plan in the other list
      let newPlan;
      if (selectedPlan === Plan.UP_TO_UNLIMITED) {
        newPlan =
          newBillingCycle === "annual"
            ? Plan.YEARLY_UNLIMITED
            : Plan.UP_TO_UNLIMITED;
      } else if (selectedPlan === Plan.YEARLY_UNLIMITED) {
        newPlan =
          newBillingCycle === "annual"
            ? Plan.YEARLY_UNLIMITED
            : Plan.UP_TO_UNLIMITED;
      } else {
        newPlan =
          newBillingCycle === "annual"
            ? selectedPlan.replace("UP_TO_", "YEARLY_UP_TO_")
            : selectedPlan.replace("YEARLY_UP_TO_", "UP_TO_");
      }
      setSelectedPlan(newPlan as Plan);
    }
  };

  const [showTrialingDialogOpen, setShowTrialingDialogOpen] =
    React.useState(false);

  const [showUpsellYearlyPlan, setShowUpsellYearlyPlan] = React.useState(false);
  const [upsellYearlyFee, setUpsellYearlyFee] = React.useState(0);
  React.useEffect(() => {
    // should we show this modal?
    const shouldShow =
      window.location.href.toLowerCase().includes("promoyearly") &&
      user?.plan.includes("YEARLY") === false &&
      user?.planTier == PlanTier.DEFAULT;
    if (shouldShow) {
      setShowUpsellYearlyPlan(shouldShow);
      // get anual cost
      // Map the selected plan to the corresponding plan in the other list
      let newPlan;
      if (selectedPlan === Plan.UP_TO_UNLIMITED) {
        newPlan = Plan.YEARLY_UNLIMITED;
      } else {
        newPlan = selectedPlan.replace("UP_TO_", "YEARLY_UP_TO_");
      }

      setUpsellYearlyFee(
        billingPlanPrice[newPlan][tierIndex[PlanTier.DEFAULT]],
      );
      setSelectedPlan(newPlan as Plan);
    }
  }, []);

  const cancelYearlyUpsell = () => {
    setSelectedPlan(user?.plan as Plan);
    setShowUpsellYearlyPlan(false);
  };

  const isDowngrade = React.useMemo(() => {
    if (
      user?.subscription?.status === planStatus.CANCELED ||
      user?.subscription?.status === planStatus.INCOMPLETE_EXPIRED
    )
      return false;

    const storedCurrentPlan = localStorage.getItem("currentPlan");
    const storedSelectedPlan = localStorage.getItem("selectedPlan");

    const currentPlanEffective = storedCurrentPlan || user.plan;
    const selectedPlanEffective = storedSelectedPlan || selectedPlan;

    const currentPlanIndex = planOrder.indexOf(currentPlanEffective as Plan);
    const selectedPlanIndex = planOrder.indexOf(selectedPlanEffective as Plan);

    return selectedPlanIndex < currentPlanIndex;
  }, [user.plan, selectedPlan]);

  const queryClient = useQueryClient();
  const [openPolling, setOpenPolling] = useState(false);

  const {
    mutate: createCheckoutSession,
    isPending: creatingCheckoutSession,
    isSuccess: createdCheckoutSession,
  } = useMutation({
    mutationKey: ["create-checkout-session"],
    mutationFn: BillingsService.createCheckoutSession,
  });

  const {
    mutate: updateUserSubscription,
    isPending: updatingUserSubscription,
  } = useMutation({
    mutationKey: ["update-user-subscription"],
    mutationFn: BillingsService.updateUserSubscription,
  });

  const {
    mutate: createCustomerPortal,
    isPending: creatingCustomerPortal,
    isSuccess: createdCustomerPortal,
  } = useMutation({
    mutationKey: ["create-customer-portal"],
    mutationFn: BillingsService.createCustomerPortal,
  });

  const handleClosePolling = useCallback(() => {
    setOpenPolling(false);
    localStorage.removeItem("pollingOpen");
    localStorage.removeItem("selectedPlan");
    localStorage.removeItem("currentPlan");

    const isUpgrade = prevStatus || !isDowngrade;

    if (isUpgrade) {
      const choosePlan =
        user?.subscription?.status === planStatus.TRIALING
          ? ""
          : `&plan=${selectedPlan}`;
      const path = COACH_PLAN_ROUTE + "?upgraded=true" + choosePlan;

      showToastAlert("success", {
        message: "Plan upgraded successfully.",
      });
      navigate(path, { replace: true });
    } else {
      showToastAlert("success", {
        message: "Plan downgraded.",
      });
      navigate(COACH_PLAN_ROUTE, { replace: true });
    }
  }, [navigate, selectedPlan, showToastAlert, user?.subscription?.status]);

  React.useEffect(() => {
    if (openPolling) {
      localStorage.setItem("pollingOpen", "true");
      localStorage.setItem("selectedPlan", selectedPlan);
      localStorage.setItem("currentPlan", user.plan);
    }
  }, [openPolling, selectedPlan, user.plan]);

  React.useEffect(() => {
    const storedPollingOpen = localStorage.getItem("pollingOpen");
    const storedSelectedPlan = localStorage.getItem("selectedPlan");
    if (storedPollingOpen === "true" && storedSelectedPlan) {
      setOpenPolling(true);
      setSelectedPlan(storedSelectedPlan as Plan);
    }
  }, []);

  React.useEffect(() => {
    if (
      user?.subscription?.status === planStatus.PAST_DUE ||
      user?.subscription?.status === planStatus.INCOMPLETE ||
      user?.subscription?.status === planStatus.UNPAID
    ) {
      setSelectedPlan(user.plan as Plan);
    }
  }, [user?.subscription?.status, user.plan]);

  const { mutate: batchArchiveClients, isPending: batchingArchiveClients } =
    useArchiveClientsMutation([UserInviteStatus.ACTIVE]);

  const { mutate: getHostedInvoiceUrl, isPending: getHostedInvoiceUrlIn } =
    useMutation({
      mutationKey: ["get-hosted-invoice-url"],
      mutationFn: BillingsService.getHostedInvoiceUrl,
    });

  const { mutate: continueOldPlan, isPending: continueOldPlanIn } = useMutation(
    {
      mutationKey: ["continue-old-plan"],
      mutationFn: BillingsService.continueOldPlan,
    },
  );

  const isCanceled = user?.subscription?.status === planStatus.CANCELED;
  const successUrlParam = isDowngrade
    ? "downgraded=true"
    : `upgrade=${UpgradeStatus.SUCCESS}${isCanceled ? `&prevStatus=${user?.subscription?.status}` : ""}`;

  React.useEffect(() => {
    if (
      newPlan &&
      (downgraded === "true" || planUpdateStatus === UpgradeStatus.SUCCESS)
    ) {
      user?.plan === newPlan ? handleClosePolling() : setOpenPolling(true);
    }
  }, [
    downgraded,
    planUpdateStatus,
    newPlan,
    user?.plan,
    user?.subscription?.status,
    navigate,
    showToastAlert,
  ]);

  const returnDataMutation = React.useCallback(
    (status: string) => {
      if (user?.planTier === PlanTier.DEFAULT) {
        return status === planStatus.ACTIVE || status === planStatus.TRIALING;
      } else return status === planStatus.ACTIVE;
    },
    [user],
  );

  const checkStatus = React.useCallback((status: string) => {
    return (
      status === planStatus.INCOMPLETE ||
      status === planStatus.INCOMPLETE_EXPIRED ||
      status === planStatus.PAST_DUE ||
      status === planStatus.UNPAID ||
      status === planStatus.INCOMPLETE ||
      status === planStatus.INCOMPLETE_EXPIRED ||
      status === planStatus.PAST_DUE ||
      status === planStatus.UNPAID
    );
  }, []);

  React.useEffect(() => {
    if (onPlanChange) {
      onPlanChange(selectedPlan);
    }
  }, [onPlanChange, selectedPlan]);

  const checkPlanStatus = useCallback(
    (plan) => {
      return user.clientsCountNoSample > Number(planClients(plan, true));
    },
    [user.clientsCountNoSample],
  );
  const handlePlanChange: SelectProps["onChange"] = React.useCallback(
    (event) => {
      setSelectedPlan(event.target.value as Plan);
    },
    [],
  );

  const handleStripeAction = React.useCallback(
    (id) => {
      stripeAction(id, showToastAlert);
    },
    [showToastAlert],
  );

  const createCheckout = React.useCallback(() => {
    createCheckoutSession(
      {
        plan: selectedPlan,
        successUrl: `${window.location.href}?${successUrlParam}&plan=${selectedPlan}`,
        cancelUrl: `${window.location.href}?upgrade=${UpgradeStatus.ERROR}`,
      },
      {
        onSuccess: ({ sessionId }) => {
          return handleStripeAction(sessionId);
        },
      },
    );
  }, [createCheckoutSession, showToastAlert, selectedPlan, stripeAction]);

  const doContinue = React.useCallback(() => {
    if (
      user?.subscription?.status === planStatus.CANCELED ||
      user?.subscription?.status === planStatus.INCOMPLETE_EXPIRED ||
      !user?.subscription?.status
    ) {
      createCheckout();
    } else if (
      user?.subscription?.status === planStatus.INCOMPLETE ||
      user?.subscription?.status === planStatus.PAST_DUE ||
      user?.subscription?.status === planStatus.UNPAID
    ) {
      getHostedInvoiceUrl(undefined, {
        onSuccess: ({ hostedInvoiceUrl }) => {
          window.location.href = `${hostedInvoiceUrl}`;
        },
      });
    } else if (user?.subscription?.status === planStatus.TRIALING) {
      updateUserSubscription(
        {
          plan: selectedPlan,
        },
        {
          onSuccess: ({ status }) => {
            if (returnDataMutation(status)) {
              setOpenPolling(true);
            }
          },
        },
      );
    } else {
      createCustomerPortal(
        {
          customerPortalFlowType:
            CustomerPortalFlowType.Subscription_update_confirm,
          plan: selectedPlan,
          successUrl: `${window.location.href}?${successUrlParam}&plan=${selectedPlan}`,
        },
        {
          onSuccess: (sessionUrlDto) => {
            const { sessionUrl } = sessionUrlDto;
            if (sessionUrl) {
              window.location.href = sessionUrl;
            } else {
              showToastAlert("error", {
                message: "Something went wrong while creating Customer Portal.",
              });
            }
          },
        },
      );
    }
  }, [
    showToastAlert,
    selectedPlan,
    createCheckout,
    getHostedInvoiceUrl,
    checkStatus,
    returnDataMutation,
    user,
  ]);

  const handleContinueClick = React.useCallback(() => {
    localStorage.setItem("currentPlan", user.plan);
    localStorage.setItem("selectedPlan", selectedPlan);

    if (
      user?.subscription?.status === planStatus.TRIALING &&
      !showTrialingDialogOpen
    ) {
      setShowTrialingDialogOpen(true);
    } else if (
      selectedPlan !== Plan.UP_TO_UNLIMITED &&
      user.clientsCountNoSample > Number(planClients(selectedPlan, true))
    ) {
      setDowngradeSelectClientsDialogOpen(true);
      setShowTrialingDialogOpen(false);
    } else {
      doContinue();
      setShowTrialingDialogOpen(false);
    }
  }, [
    doContinue,
    selectedPlan,
    user,
    setShowTrialingDialogOpen,
    showTrialingDialogOpen,
  ]);

  const monthlyPlanList = React.useMemo(() => {
    const monthlyPlans = Object.values(Plan);

    return monthlyPlans
      .filter((plan) => {
        return ![
          Plan.PRO,
          Plan.STARTER,
          Plan.UP_TO_5,
          Plan.UP_TO_15,
          Plan.UP_TO_30,
          Plan.UP_TO_75,
          Plan.UP_TO_INFINITY,
          Plan.PROMO,
          Plan.YEARLY_UP_TO_50,
          Plan.YEARLY_UP_TO_100,
          Plan.YEARLY_UP_TO_200,
          Plan.YEARLY_UNLIMITED,
        ].includes(plan);
      })
      .map((plan) => {
        return {
          value: plan !== Plan.FREE && plan,
          disabled: checkPlanStatus(plan),
        };
      });
  }, [checkPlanStatus]);

  const yearlyPlanList = React.useMemo(() => {
    const yearlyPlans = Object.values(Plan);

    return yearlyPlans
      .filter((plan) => {
        return ![
          Plan.PRO,
          Plan.STARTER,
          Plan.UP_TO_5,
          Plan.UP_TO_15,
          Plan.UP_TO_30,
          Plan.UP_TO_75,
          Plan.UP_TO_INFINITY,
          Plan.PROMO,
          Plan.UP_TO_50,
          Plan.UP_TO_100,
          Plan.UP_TO_200,
          Plan.UP_TO_UNLIMITED,
        ].includes(plan);
      })
      .map((plan) => {
        return {
          value: plan !== Plan.FREE && plan,
          disabled: checkPlanStatus(plan),
        };
      });
  }, [checkPlanStatus]);

  const action = getPlanAction(user?.plan, selectedPlan);

  const subscriptionDaysDiff = React.useMemo(() => {
    if (
      (action !== "upgrade" && user.plan !== selectedPlan) ||
      isPlanYearly(user.plan) !== isPlanYearly(selectedPlan)
    ) {
      return null;
    }

    return user?.subscription?.status === planStatus.ACTIVE &&
      user?.plan !== Plan.UP_TO_UNLIMITED &&
      user?.plan !== Plan.YEARLY_UNLIMITED ? (
      <Typography className={s.planUpgradeInfo}>
        Your plan will automatically upgrade once you exceed{" "}
        {planClients(user?.plan as Plan)} active clients. You can downgrade
        anytime.
      </Typography>
    ) : null;
  }, [action, user?.subscription?.status, user.plan, selectedPlan]);

  const monthlyPlan = monthlyBillingPlan(selectedPlan);
  const monthlyEquivalentPrice =
    billingPlanPrice[monthlyPlan]?.[tierIndex[PlanTier.DEFAULT]] ?? 0;
  const crossedOutPrice = monthlyEquivalentPrice * 12;

  const pending =
    creatingCheckoutSession ||
    createdCheckoutSession ||
    updatingUserSubscription ||
    continueOldPlanIn ||
    getHostedInvoiceUrlIn ||
    batchingArchiveClients ||
    creatingCustomerPortal ||
    createdCustomerPortal;

  const originalPrice = (upsellYearlyFee / 10) * 12;
  const savings = originalPrice - upsellYearlyFee;

  const DialogDescription = () => (
    <div>
      Upgrade to an annual subscription today and get 12 months for the price of
      10
      <br />
      <br />
      <strong>AND</strong>
      <br />
      <br />
      Get upgraded to a custom branded app at no extra cost for the year saving
      over $500!!
      <br />
      <br />
      <strong>
        Upgrade now for ${upsellYearlyFee} amount and save ${savings} on your
        subscription and save upto £500 for the custom branded app upgrade
      </strong>
    </div>
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h3">
        {Plan[user.plan] === Plan.FREE
          ? "Upgrade your plan to"
          : "Choose your plan"}
      </Typography>
      <Typography className={s.subtitle} variant="body1">
        {Plan[user.plan] === Plan.FREE ||
        user?.subscription?.status === "trialing" ? (
          <>
            You can choose a plan without waiting for the end of the trial
            period
          </>
        ) : (
          <>
            Change the plan to the one that suits you best <br />
            If you decide this plan isn't right for you, it's easy to cancel.
          </>
        )}
      </Typography>
      <Box className={s.field}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!isPlanYearly(user.plan) && (
            <>
              <Typography>Monthly</Typography>
              <Switch
                checked={billingCycle === "annual"}
                onChange={handleBillingCycleToggle}
                color="primary"
              />
            </>
          )}
          <Typography>Annually</Typography>
          {!isPlanYearly(user.plan) && (
            <Tooltip
              title="Save 2 months with annual billing!"
              placement="top"
              arrow
            >
              <Typography className={s.highlightedText}>
                <AutoAwesomeIcon className={s.icon} sx={{ marginRight: 0.5 }} />
                GET 2 MONTH FREE
              </Typography>
            </Tooltip>
          )}
        </Box>
        {!isPlanYearly(user.plan) && billingCycle === "monthly" && (
          <Select
            native
            variant="outlined"
            fullWidth
            value={selectedPlan}
            onChange={handlePlanChange}
          >
            {monthlyPlanList.map(
              ({ value }) =>
                value && (
                  <option key={value} value={value}>
                    Up to {planClients(value)} clients
                  </option>
                ),
            )}
          </Select>
        )}

        {(isPlanYearly(user.plan) || billingCycle === "annual") && (
          <Select
            native
            variant="outlined"
            fullWidth
            value={selectedPlan}
            onChange={handlePlanChange}
          >
            {yearlyPlanList.map(
              ({ value }) =>
                value && (
                  <option key={value} value={value}>
                    Up to {planClients(value)} clients
                  </option>
                ),
            )}
          </Select>
        )}
      </Box>
      <Box className={s.bottom}>
        <Box className={s.totalWrapper}>
          {!isPlanYearly(user.plan) && (
            <Typography
              className={clsx(s.crossedOutPrice, {
                [s.hidden]: billingCycle !== "annual",
              })}
            >
              ${crossedOutPrice}
            </Typography>
          )}
          <Typography
            className={s.amount}
            children={`$${
              billingPlanPrice[selectedPlan]
                ? billingPlanPrice[selectedPlan][tierIndex[PlanTier.DEFAULT]]
                : 0 // Fallback value
            }`}
          />
          <Typography
            className={s.totalDescription}
            children={
              monthlyPlansInOrder.includes(user?.plan as Plan)
                ? "Billed monthly"
                : yearlyPlansInOrder.includes(user?.plan as Plan)
                  ? "Billed annually"
                  : "Billed today" // Fallback value
            }
          />
        </Box>
        {(() => {
          const isSubscriptionCanceled =
            user?.subscription?.status === planStatus.CANCELED ||
            user?.subscription?.status === planStatus.INCOMPLETE_EXPIRED;
          const isPastDueOrIncomplete =
            user?.subscription?.status === planStatus.PAST_DUE ||
            user?.subscription?.status === planStatus.INCOMPLETE ||
            user?.subscription?.status === planStatus.UNPAID;

          const isSamePlan = selectedPlan === user.plan;
          const currentPlanIndex = planOrder.indexOf(user.plan as Plan);
          const selectedPlanIndex = planOrder.indexOf(selectedPlan);
          const isSwitchingBetweenMonthlyAndYearly =
            isPlanYearly(user?.plan) !== isPlanYearly(selectedPlan);

          const conditions = [
            {
              condition: isPastDueOrIncomplete && isSamePlan,
              text: "Continue",
            },
            {
              condition: isSubscriptionCanceled && isSamePlan,
              text: "Reactivate",
            },
            {
              condition:
                isSubscriptionCanceled && isSwitchingBetweenMonthlyAndYearly,
              text: "Reactivate & Change Plan",
            },
            {
              condition:
                isSubscriptionCanceled && selectedPlanIndex < currentPlanIndex,
              text: "Reactivate & Downgrade",
            },
            {
              condition:
                isSubscriptionCanceled && selectedPlanIndex > currentPlanIndex,
              text: "Reactivate & Upgrade",
            },
            { condition: isSamePlan, text: "Current Plan" },
            {
              condition: isSwitchingBetweenMonthlyAndYearly,
              text: "Change Plan",
            },
            { condition: action === "upgrade", text: "Upgrade" },
            { condition: true, text: "Downgrade" },
          ];

          let buttonText = conditions.find((item) => item.condition).text;

          if (isMobile && isSubscriptionCanceled) {
            buttonText = "Reactivate";
          }

          const isCurrentPlan = !action && !isSubscriptionCanceled;
          const isButtonDisabled =
            (isPastDueOrIncomplete && !isCurrentPlan) ||
            (!isPastDueOrIncomplete && isCurrentPlan) ||
            pending;

          return (
            <Button
              className={s.button}
              onClick={
                isPastDueOrIncomplete && !isCurrentPlan
                  ? undefined
                  : handleContinueClick
              }
              children={buttonText}
              disabled={isButtonDisabled}
            />
          );
        })()}

        <SelectClientsDialog
          open={downgradeSelectClientsDialogOpen}
          onClose={() => setDowngradeSelectClientsDialogOpen(false)}
          title={`Downgrade to the ${capitalize(selectedPlan)} Plan`}
          onConfirm={(clients) => {
            batchArchiveClients(
              {
                clients: clients,
                archived: true,
              },
              {
                onSuccess: () => {
                  doContinue();
                },
              },
            );
          }}
          minSelected={
            user.clientsCountNoSample -
            (planClients(selectedPlan, true) as number)
          }
          user={user}
          selectedPlan={selectedPlan}
        />
      </Box>
      <Box className={s.changeCardBox}>
        {/* 
        
        This button is sending existing users to stripe checkout and creating a new / duplicate subscription which we don't want.

        <Box>
          {isActiveContinueCurrentPlanButton &&
            user?.planTier !== PlanTier.DEFAULT && (
              <LinkButton
                href={null}
                onClick={handleContinueOldPlan}
                className={clsx(s.changeCard)}
              >
                Continue current plan
              </LinkButton>
            )}
        </Box> */}

        <Box>{subscriptionDaysDiff}</Box>
      </Box>
      <ConfirmActionDialog
        title={
          action === "upgrade"
            ? "Upgrade while Free Trial?"
            : "Downgrade while Free Trial?"
        }
        description={
          <>
            {action === "upgrade" ? (
              <>
                We got you! You will continue Free Trial with{" "}
                <b style={{ whiteSpace: "nowrap" }}>no additional fees</b> for
                upgrade. <br />
                <br />
                After your trial ends, you will be automatically billed on the
                chosen plan.
              </>
            ) : (
              <>
                You will continue Free Trial on the downgraded plan. <br />
                <br />
                After your trial ends, you will be automatically billed on the
                chosen plan.
              </>
            )}
          </>
        }
        onCancel={() => setShowTrialingDialogOpen(false)}
        confirmButtonText={action === "upgrade" ? "Upgrade" : "Downgrade"}
        onConfirm={handleContinueClick}
        activity={!showTrialingDialogOpen}
        open={showTrialingDialogOpen}
        classNameTitle={s.titleModal}
      />

      {/* Upsell Anual Plans */}
      <ConfirmActionDialog
        title={`STRIDIST BLACK FRIDAY DEAL!!`}
        description={<DialogDescription />}
        onCancel={() => cancelYearlyUpsell()}
        onConfirm={handleContinueClick}
        confirmButtonText="Upgrade to Annual"
        activity={showTrialingDialogOpen}
        open={showUpsellYearlyPlan}
        classNameTitle={s.titleModal}
      />

      <SubscriptionUpdatePolling
        open={openPolling}
        onClose={handleClosePolling}
        expectedPlan={selectedPlan.toString()}
      />

      {/* Upsell Anual Plans END */}
    </Card>
  );
}
