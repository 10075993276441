import {
  UserRole,
  PHONE_REGEX,
  RequiresUpgradeReason,
  Plan,
  planClients,
  monthlyPlansInOrder,
  yearlyPlansInOrder,
  BillingPlan,
  planToBillingPlan,
} from "../constants";
import dayjs from "dayjs";
import { UserInfo } from "../hooks/useCurrentUser";

/**
 * Extracts first name from display name. For example:
 *
 *   "John Wick" => "John"
 *   "Mr. John Wick" => "John"
 */
export function getFirstName(user, fallback = "Guest") {
  const name = (user && user.displayName) || fallback || "";
  const parts = name
    .split(" ")
    .map((x) => x.trim())
    .filter(Boolean);

  return parts[0].length < 5 && parts[0].endsWith(".")
    ? parts[1] || parts[0] || ""
    : parts[0] || "";
}

export function getLastName(user): string | null {
  const name = (user && user.displayName) || "";
  const parts = name
    .split(" ")
    .map((x) => x.trim())
    .filter(Boolean);

  return parts.length > 1 ? parts[parts.length - 1] : null;
}

export function validateEmail(value: string) {
  return /^.+@.+\..+$/.test(value);
}

export function validatePhone(value: string) {
  return PHONE_REGEX.test(value);
}

export function getUserRole(pathname: string): UserRole {
  return pathname === "/coach" || pathname.startsWith("/coach/")
    ? UserRole.COACH
    : UserRole.CLIENT;
}

// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export function isSubscriptionCanceled(
  subscriptionStatus: string | null = null,
) {
  return ["canceled", "unpaid"].includes(subscriptionStatus);
}

export function getRequiresUpgrade(
  trialExpired = false,
  plan: Plan | null = null,
  subscriptionStatus: string | null = null,
): [boolean, RequiresUpgradeReason] {
  if (trialExpired && !plan) {
    return [true, RequiresUpgradeReason.TRAIL_EXPIRED];
  } else if (
    plan &&
    subscriptionStatus &&
    isSubscriptionCanceled(subscriptionStatus)
  ) {
    return [true, RequiresUpgradeReason.SUBSCRIPTION_CANCELED];
  } else {
    return [false, null];
  }
}

export function humanizeDate(
  date: string | number | Date,
  timeZone: string = dayjs.tz.guess(),

  format: string = "MMMM DD, YYYY",
) {
  const formatter = new Intl.DateTimeFormat("en-US", { timeZone });

  return dayjs(formatter.format(new Date(date))).format(format);
}

export function humanizeUserPlan(plan: string) {
  switch (plan) {
    case Plan.UP_TO_5:
      return "5 clients";
    case Plan.UP_TO_15:
      return "15 clients";
    case Plan.UP_TO_30:
      return "30 clients";
    case Plan.UP_TO_50:
      return "50 clients";
    case Plan.UP_TO_75:
      return "75 clients";
    case Plan.UP_TO_100:
      return "100 clients";
    case Plan.UP_TO_200:
      return "200 clients";
    case Plan.FREE:
      return "Free";
    case Plan.STARTER:
      return "Starter";
    case Plan.PRO:
      return "Pro";
    case Plan.YEARLY_UP_TO_50:
      return "50 clients";
    case Plan.YEARLY_UP_TO_100:
      return "100 clients";
    case Plan.YEARLY_UP_TO_200:
      return "200 clients";
    case Plan.YEARLY_UNLIMITED:
      return "unlimited";

    case Plan.UP_TO_INFINITY:
    default:
      return "unlimited";
  }
}

export const splitPlanText = (planText: string) => {
  const match = planText.match(/^(\d+)\s+(.*)$/);
  if (match) {
    return { number: match[1], text: match[2] };
  }
  return { number: null, text: planText };
};

export function isClientSignUpComplete(user: UserInfo) {
  // NOTE: This condition was moved from `SignupClientCompleteScreen` component and matches fields required there.
  // Consider adding more fields if needed.
  return (
    user?.displayName &&
    user?.gender &&
    user?.birthday &&
    user?.location &&
    user?.height > 0 &&
    user?.weight > 0
  );
}

export const isPlanYearly = (plan) => plan.includes("YEARLY");

export function monthlyBillingPlan(plan: Plan): BillingPlan {
  switch (plan) {
    case Plan.YEARLY_UP_TO_50:
      return BillingPlan.UP_TO_50;
    case Plan.YEARLY_UP_TO_100:
      return BillingPlan.UP_TO_100;
    case Plan.YEARLY_UP_TO_200:
      return BillingPlan.UP_TO_200;
    case Plan.YEARLY_UNLIMITED:
      return BillingPlan.UP_TO_UNLIMITED;
    default:
      return planToBillingPlan[plan];
  }
}

export function getNextHigherPlan(currentPlan: Plan): Plan {
  const isYearly = currentPlan.includes("YEARLY");
  const planList = isYearly ? yearlyPlansInOrder : monthlyPlansInOrder;

  const currentIndex = planList.indexOf(currentPlan);
  if (currentIndex > -1 && currentIndex < planList.length - 1) {
    return planList[currentIndex + 1];
  } else {
    return planList[planList.length - 1];
  }
}

export function getPlanAction(
  currentPlanString: string | null,
  selectedPlan: Plan,
): "upgrade" | "downgrade" | null {
  if (!currentPlanString) {
    throw new Error(`Unknown plan: ${currentPlanString}`);
  }

  const currentPlan = currentPlanString as Plan;

  const currentIsYearly = currentPlan.includes("YEARLY");
  const selectedIsYearly = selectedPlan.includes("YEARLY");

  const currentPlansOrder = currentIsYearly
    ? yearlyPlansInOrder
    : monthlyPlansInOrder;

  if (currentIsYearly !== selectedIsYearly) {
    return !currentIsYearly && selectedIsYearly ? "upgrade" : "downgrade";
  }

  const currentIndex = currentPlansOrder.indexOf(currentPlan);
  const selectedIndex = currentPlansOrder.indexOf(selectedPlan);

  if (currentIndex === selectedIndex) {
    return null; // Current Plan
  }

  return selectedIndex > currentIndex ? "upgrade" : "downgrade";
}

export function getPlanFromClientsCount(count: number): Plan {
  if (count <= 50) {
    return Plan.UP_TO_50;
  } else if (count <= 100) {
    return Plan.UP_TO_100;
  } else if (count <= 200) {
    return Plan.UP_TO_200;
  } else {
    return Plan.UP_TO_UNLIMITED;
  }
}

export const getClientExceededThresholdAlertText = (planNumber: number) => {
  return `Soon, you will reach your membership active clients threshold. When that happens, you will be automatically upgraded to ${planNumber} clients Stridist plan.\n
Note that archived clients are not counted in the threshold, so keep your active clients list up to date. Let's go!`;
};

export const getClientExceededAlertTitle = (
  isBrandedWorkspace: boolean,
  brandName: string,
) => {
  return `Horray! ${isBrandedWorkspace ? brandName : "Your business"} is growing!`;
};

export const openEmailInbox = (email: string, showDialog: () => void) => {
  if (email.endsWith("gmail.com")) {
    window.open("https://mail.google.com", "_blank");
  } else if (email.endsWith("yahoo.com")) {
    window.open("https://mail.yahoo.com", "_blank");
  } else if (email.endsWith("outlook.com") || email.endsWith("hotmail.com")) {
    window.open("https://outlook.live.com/mail/", "_blank");
  } else {
    showDialog();
  }
};
