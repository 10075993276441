import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { ComponentIcon } from "./ComponentIcon";
import { HabitPrompt } from "../habit-prompt/HabitPrompt";
import { ComponentType } from "../../constants";
import { CurriculumComponent } from "../../redux/types";
import { getComponentSpecifics } from "../../utils/component";
import ComponentEditor from "../new-editor/ComponentEditor";

const useStyles = makeStyles((theme) => {
  return {
    previewHeader: {
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(2, 0, 1.5),
    },

    icon: {
      marginRight: theme.spacing(1),
    },

    previewComponentType: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },

    previewTitle: {
      fontSize: 24,
      fontWeight: "bold",
      color: theme.palette.common.black,
      wordBreak: "break-word",
    },
  };
});

interface ICoachComponentPreviewProps {
  component: CurriculumComponent;
  customContent?: string;
}

const CoachComponentPreview = (props: ICoachComponentPreviewProps) => {
  const { component, customContent } = props;
  const s = useStyles();

  const [contentVersion, setContentVersion] = useState(0);

  const content: Node[] = React.useMemo(() => {
    // Due to unknown reason content update don't trigger editor render,
    // so for this reason `key` + `contentVersion` used
    setContentVersion((v) => (v += 1));

    return JSON.parse(customContent ? customContent : component.content) || [];
  }, [component.content, customContent]);

  return (
    <>
      <Box className={s.previewHeader}>
        <ComponentIcon
          className={s.icon}
          componentData={component}
          variant="icon"
        />
        <Typography
          className={s.previewComponentType}
          variant="body1"
          component="span"
          children={getComponentSpecifics(component.type).typeName}
        />
      </Box>
      <Typography
        className={s.previewTitle}
        variant="h1"
        children={component.title}
      />
      {component.type === ComponentType.HABIT && (
        <HabitPrompt completed={false} prompt={component.habitPrompt} />
      )}

      <Box sx={{ containerType: "inline-size" }}>
        {component && (
          <ComponentEditor
            key={`${component.id}-${contentVersion}`}
            value={content}
            readOnly={true}
            disabled={true}
            delay={0}
          />
        )}
      </Box>
    </>
  );
};

export default CoachComponentPreview;
