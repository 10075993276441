import React, { ReactElement } from "react";
import { Box, BoxProps } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

const fadeInOut = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

const fadeInOutUp = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
      ease: "easeIn",
    },
  },
};

const fadeInOutDown = {
  initial: {
    opacity: 0,
    y: -20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
    },
  },
};

const variants = {
  fadeInOut,
  fadeInOutUp,
  fadeInOutDown,
};

export interface AnimatePresenceBoxProps extends BoxProps {
  initial?: boolean;
  mode?: "wait" | "sync" | "popLayout";
  variant?: keyof typeof variants;
  children?: Array<ReactElement> | ReactElement;
  visible?: boolean;
}

export const AnimatePresenceBox = ({
  children,
  initial = false,
  mode = "wait",
  variant = "fadeInOut",
  visible = true,
  ...props
}: AnimatePresenceBoxProps) => {
  const content = Array.isArray(children) ? children : [children];
  return (
    <AnimatePresence initial={initial} mode={mode}>
      {visible && (
        <motion.div variants={variants[variant]}>
          <Box {...props}>
            {content.map((child, index) => (
              <React.Fragment key={index}>{child}</React.Fragment>
            ))}
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
