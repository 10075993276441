import { z } from "zod";
import { clientCompleteSignUpSchema } from "./clientCompleteSignupSchema";
import { validatePhone } from "./user";

export const clientSettingsSchema = clientCompleteSignUpSchema().extend({
  timezone: z.string().min(1, "Please select a timezone"),
  phone: z
    .string()
    .nullable()
    .optional()
    .refine((val) => !val || val.length <= 5 || validatePhone(val), {
      message: "Please enter a valid phone number",
    }),
  phoneCode: z.string().nullable().optional(),
  tag: z.string().nullable().optional(),
});

export type ClientSettingsSchema = z.infer<typeof clientSettingsSchema>;
