import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  CardHeader,
  Chip,
  IconButton,
  Typography,
  Paper,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import { colorSystem } from "../../theme";
import { ClientPendingMenu } from "../menu/ClientPendingMenu";
import { CardAvatar } from "./CardAvatar";
import { SendEmailDialog } from "../dialog/SendEmailDialog";
import {
  ClientInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import useInviteAddedClientsMutation from "../coach-clients/mutations/useInviteAddedClientsMutation";
import dayjs from "dayjs";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  actionArea: {
    color: theme.palette.text.primary,
    alignItems: "center",
    position: "relative",

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
    },
  },

  header: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },

  cardTitle: {
    fontSize: 18,
    display: "block",
  },
  
  cardSubheader: {
    fontSize: 13,
    alignItems: "center",
    marginTop: theme.spacing(0.5),
  },

  avatar: {
    width: 48,
    height: 48,

    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  },

  text: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down("sm")]: {
      float: "right",
      marginTop: theme.spacing(1),
    },
  },

  moreButton: {
    marginRight: theme.spacing(3),
    marginLeft: "auto",
    visibility: "hidden",

    "$actionArea:hover &": {
      visibility: "visible",
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      visibility: "visible",
      top: 5,
      right: -20,
    },
  },

  chip: {
    fontWeight: "bold",
    fontSize: 11,
    color: theme.palette.common.white,
    textTransform: "uppercase",
    padding: theme.spacing(1, 4.5),
    margin: theme.spacing(0, 2),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  chipInvited: {
    backgroundColor: theme.palette.activity.habit,
  },
  chipAdded: {
    backgroundColor: colorSystem.orange2,
  },
}));

export interface ClientPendingProps extends CardProps {
  client: ClientInfoDto;
}

export function ClientPendingCard(props: ClientPendingProps) {
  const { client, ...other } = props;
  const s = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [emailDialogIsOpen, setEmailDialogIsOpen] = React.useState(false);
  const open = Boolean(anchorEl);

  const isPending = client?.status === UserInviteStatus.PENDING;
  const label = isPending ? "Invited" : "Added";

  const { showToastAlert } = useToastAlert();

  const onNotification = React.useCallback(
    (message: string) => {
      showToastAlert("success", {
        message,
      });
    },
    [showToastAlert],
  );

  const { mutate: inviteAddedClient, isPending: invitingAddedClientMutation } =
    useInviteAddedClientsMutation();

  const name = client.email.replace(/@.*$/, "");
  const handleMoreClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl],
  );

  const handleSendEmail = React.useCallback(
    (message) => {
      inviteAddedClient(
        {
          ids: [client?.id],
          message: message,
        },
        {
          onSuccess: () => {
            setEmailDialogIsOpen(false);
            if (onNotification) {
              onNotification("Invitation has been send");
            }
          },
        },
      );
    },
    [client, inviteAddedClient, onNotification],
  );

  const handleMoreClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseEmailDialog = React.useCallback(() => {
    setEmailDialogIsOpen(false);
  }, [setEmailDialogIsOpen]);

  const handleOpenEmailDialog = React.useCallback(() => {
    setEmailDialogIsOpen(true);
    setAnchorEl(null);
  }, [setEmailDialogIsOpen]);

  return (
    <Card {...other}>
      <Paper className={s.actionArea}>
        <CardHeader
          className={s.header}
          avatar={
            <CardAvatar className={s.avatar} alt={name}>
              {name[0]?.toUpperCase()}
            </CardAvatar>
          }
          title={name}
          titleTypographyProps={{ variant: "h5", className: s.cardTitle }}
          subheader={client.email}
          subheaderTypographyProps={{ className: s.cardSubheader }}
        />

        <Chip
          className={clsx(s.chip, isPending ? s.chipInvited : s.chipAdded)}
          label={label}
        />
        <Typography variant="body2" className={s.text}>
          {label}{" "}
          {dayjs(client.invite.inivitedAt.toString()).from(dayjs().utc())}
        </Typography>
        <IconButton
          className={s.moreButton}
          aria-controls="program-menu"
          aria-haspopup="true"
          onClick={handleMoreClick}
          size="large"
        >
          <MoreHoriz />
        </IconButton>
      </Paper>

      <ClientPendingMenu
        inviteId={client.invite.id}
        open={open}
        onClose={handleMoreClose}
        isPending={isPending}
        anchorEl={anchorEl}
        clientId={client?.id}
        onSendInvite={handleOpenEmailDialog}
      />

      <SendEmailDialog
        open={emailDialogIsOpen}
        onClose={handleCloseEmailDialog}
        sendingEmail={invitingAddedClientMutation}
        sendEmail={handleSendEmail}
      />
    </Card>
  );
}
