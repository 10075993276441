import { createSlice } from "@reduxjs/toolkit";
import { AsyncState, createInitAsyncState, SLICE_NAMES } from "../utils";
import {
  addWeekActions,
  deleteWeekActions,
  duplicateWeekActions,
  moveWeekActions,
  setDuplicatedWeekAction,
  updateDescriptionAction,
  updatePositionsAction,
  updateWaitingForApiStatusAction,
} from "./actions/week/week";
import { setCurriculumAction } from "./actions/curriculum";
import { addComponentAction } from "./actions/component/add/add";
import {
  updateComponentDefaultIconAction,
  updateComponentIconAction,
} from "./actions/component/updateIcon/update-icon";
import { duplicateComponentAction } from "./actions/component/duplicate/duplicate";
import { archiveComponentAction } from "./actions/component/archive/archive";
import { removeComponentAction } from "./actions/component/remove/remove";
import { updateComponentWeekAction } from "./actions/component/updateWeek/update-week";
import { updateComponentCoverAction } from "./actions/component/updateCover/update-cover";
import { lockComponentAction } from "./actions/component/updateLock/update-lock";
import { pasteComponentAction } from "./actions/component/paste/paste";
import {
  NormalizedCurriculumComponent,
  NormalizedCurriculumProgram,
  NormalizedCurriculumWeek,
} from "../types";
import { updateComponentPositionAction } from "./actions/component/updatePositions/update-position";
import { updateComponentContentAction } from "./actions/component/update-content/update-content";
import { publishComponentAction } from "./actions/component/publish/publish";
import { addWorkoutSectionAction } from "./actions/workout-sections/add/add";
import { deleteWorkoutSectionAction } from "./actions/workout-sections/delete/delete";
import { duplicateWorkoutSectionAction } from "./actions/workout-sections/duplicate/duplicate";
import { addWorkoutExerciseAction } from "./actions/exercise/add/reducer";
import { deleteWorkoutExerciseAction } from "./actions/exercise/delete/reducer";
import { duplicateWorkoutExerciseAction } from "./actions/exercise/duplicate/reducer";
import { updateComponentDaysAction } from "./actions/component/updateDays/update-days";
import { updateComponentPropsAction } from "./actions/component/update-prop/update-prop";
import { updateComponentScheduleAction } from "./actions/component/update-schedule/action";
import { setComponentSlugAction } from "./actions/component/setSlug/setSlug";
import { lockAllComponentsAction } from "./actions/program/lock-all/action";
import { syncComponentDataAction } from "./actions/component/sync-data/sync-data";
import { updateComponentTitleAction } from "./actions/component/update-title/update-title";
import { duplicateComponentToWeekAction } from "./actions/component/duplicate-to-week/duplicate-to-week";
import { splitRepeatAction } from "./actions/component/split-repeat/split-repeat";
import { overrideComponentAction } from "./actions/component/create-override/overrideComponent";
import { setOverrideDataAction } from "./actions/component/create-override/setOverrideData";
import { removeOverrideAction } from "./actions/component/remove-override/remove-override";
import { removeComponentSplitMarksAction } from "./actions/component/split-repeat/removeComponentSplitMarks";

export interface NormalizedCurriculum {
  program: NormalizedCurriculumProgram;
  weeks: Record<number, NormalizedCurriculumWeek>;
  components: Record<number, NormalizedCurriculumComponent>;
}

export interface CurriculumState extends AsyncState<NormalizedCurriculum> {}
const initialState: CurriculumState = createInitAsyncState(null);

// TODO_API_V2_CURRICULUM handle undo/redo

export const curriculumSlice = createSlice({
  name: SLICE_NAMES.CURRICULUM,
  initialState,
  reducers: {
    // curriculum
    setCurriculum: setCurriculumAction,
    lockAllComponents: lockAllComponentsAction,

    // week
    addWeek: addWeekActions,
    deleteWeek: deleteWeekActions,
    duplicateWeek: duplicateWeekActions,
    moveWeek: moveWeekActions,
    updatePositions: updatePositionsAction,
    updateDescription: updateDescriptionAction,
    updateWaitingForApiStatus: updateWaitingForApiStatusAction,
    setDuplicatedWeek: setDuplicatedWeekAction,

    // component
    overrideComponent: overrideComponentAction,
    removeOverride: removeOverrideAction,
    setOverrideData: setOverrideDataAction,
    setComponentSlug: setComponentSlugAction,
    addComponent: addComponentAction,
    updateComponentIcon: updateComponentIconAction,
    updateComponentDefaultIcon: updateComponentDefaultIconAction,
    duplicateComponent: duplicateComponentAction,
    archiveComponent: archiveComponentAction,
    removeComponent: removeComponentAction,
    updateComponentWeek: updateComponentWeekAction,
    updateComponentCover: updateComponentCoverAction,
    lockComponent: lockComponentAction,
    pasteComponent: pasteComponentAction,
    updateComponentPosition: updateComponentPositionAction,
    updateComponentContent: updateComponentContentAction,
    updateComponentDays: updateComponentDaysAction,
    publishComponent: publishComponentAction,
    updateComponentProps: updateComponentPropsAction,
    updateComponentSchedule: updateComponentScheduleAction,
    updateComponentTitle: updateComponentTitleAction,
    syncComponentData: syncComponentDataAction,
    duplicateComponentToWeek: duplicateComponentToWeekAction,
    splitRepeat: splitRepeatAction,
    removeComponentSplitMarks: removeComponentSplitMarksAction,

    // workout-section
    addWorkoutSection: addWorkoutSectionAction,
    deleteWorkoutSection: deleteWorkoutSectionAction,
    duplicateWorkoutSection: duplicateWorkoutSectionAction,

    // exercise
    addWorkoutExercise: addWorkoutExerciseAction,
    deleteWorkoutExercise: deleteWorkoutExerciseAction,
    duplicateWorkoutExercise: duplicateWorkoutExerciseAction,
  },
  extraReducers: () => {},
});

export const {
  setCurriculum,
  addWeek,
  deleteWeek,
  duplicateWeek,
  moveWeek,
  addComponent,
  addWorkoutSection,
  deleteWorkoutSection,
  duplicateWorkoutSection,
  addWorkoutExercise,
  deleteWorkoutExercise,
  duplicateWorkoutExercise,
  updateComponentIcon,
  updateComponentDefaultIcon,
  duplicateComponent,
  archiveComponent,
  removeComponent,
  updateComponentWeek,
  updateComponentCover,
  lockComponent,
  pasteComponent,
  updateComponentPosition,
  updatePositions,
  updateComponentContent,
  publishComponent,
  updateDescription,
  updateComponentDays,
  updateComponentProps,
  updateComponentSchedule,
  setComponentSlug,
  lockAllComponents,
  updateWaitingForApiStatus,
  setDuplicatedWeek,
  syncComponentData,
  updateComponentTitle,
  duplicateComponentToWeek,
  splitRepeat,
  overrideComponent,
  setOverrideData,
  removeOverride,
  removeComponentSplitMarks,
} = curriculumSlice.actions;

export default curriculumSlice.reducer;
