import React from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { Box, Fade, IconButton, Typography } from "@mui/material";

import { EmbedElementType, MoreMenu } from "./MoreMenu";
import { humanReadableFileSize } from "../utils/validationUtil";
import { Icons } from "../../plate-ui/Icons/icons";
import { Compass } from "lucide-react";
import { EmbedFileDialog } from "../modals/EmbedFileDialog";
import { Download } from "@mui/icons-material";
import { useReadOnly } from "../hooks";

export const FILE = "file";

interface IMediaEmbedProps {
  handleChange: (
    url: string,
    name: string,
    size: number,
    mimeType: string,
  ) => void;
  handleRemoveNode: () => void;
  url: string;
  name: string;
  size: number | null;
  mimeType: string;
  element: any;
}

export const FileUploader = ({
  handleChange,
  handleRemoveNode,
  url,
  name,
  size,
  mimeType,
  element,
}: IMediaEmbedProps) => {
  const empty = !url;
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const readOnly = useReadOnly();
  const [isHovered, setIsHovered] = React.useState<boolean>(false);

  const handleFileButtonClick = () => {
    window.open(url, "_blank");
  };

  const handleClick = () => {
    if (!readOnly && !openDialog) {
      setOpenDialog(true);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  if (empty && readOnly) return <></>;

  return (
    <Box
      display={"flex"}
      gap={1.5}
      sx={{
        border: (theme) =>
          empty ? `${theme.shape.border.xs}px solid` : "none",
        borderRadius: 1,
        padding: empty ? 2.5 : 0,
        pt: 2,
        pr: 0,
        display: "flex",
        backgroundColor: (theme) =>
          empty ? theme.palette.selected.light : null,
        borderColor: (theme) => theme.palette.border.primary,
        cursor: "pointer",
      }}
      onClick={empty ? handleClick : handleFileButtonClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline",
          width: "100%",
        }}
        contentEditable={false}
      >
        {empty ? (
          <Box sx={{ pt: 1, display: "flex", gap: 1 }}>
            <IconButton
              sx={{ color: (theme) => theme.palette.text.disabled, p: 0 }}
            >
              <Icons.paperclip />
            </IconButton>

            <Typography
              sx={{
                color: (theme) => theme.palette.text.disabled,
              }}
            >
              Upload or embed a file attachment
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              borderRadius: 1,
              p: 2,
              display: "flex",
              gap: 1,
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              border: (theme) => `${theme.shape.border.xs}px solid`,
              borderColor: (theme) => theme.palette.border.primary,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", pl: 1, gap: 1 }}>
              <IconButton
                sx={{ color: (theme) => theme.palette.text.disabled, p: 0 }}
              >
                <Download />
              </IconButton>
              <Typography sx={{ maxWidth: 1400 }} component="span">
                {name}
              </Typography>
            </Box>
            <Box sx={{ pr: 1.4 }}>
              {size && (
                <Typography component="span">
                  {humanReadableFileSize(size)}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {!readOnly && (
          <>
            <Fade in={isHovered}>
              <Box>
                <MoreMenu
                  element={element}
                  handleRemoveNode={handleRemoveNode}
                  empty={empty}
                  setOpenDialog={setOpenDialog}
                  type={EmbedElementType.FILE}
                ></MoreMenu>
              </Box>
            </Fade>
            <EmbedFileDialog
              url={url}
              size={size}
              name={name}
              mimeType={mimeType}
              open={openDialog}
              type={EmbedElementType.FILE}
              title="Upload a File"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              onSubmit={handleChange}
              onClose={handleClose}
            ></EmbedFileDialog>
          </>
        )}
      </Box>
    </Box>
  );
};

export const FileUploaderElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element } = props;
    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    if (!path) return;

    const handleChange = React.useCallback(
      (url: string, name: string, size: number, mimeType: string) => {
        setNodes(editor, { ...{}, url, name, size, mimeType }, { at: path });
      },
      [],
    );

    const handleRemoveNode = () => {
      removeNodes(editor, { at: path });
    };

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <Box sx={{ visibility: "hidden", height: 0 }} contentEditable={false}>
          {children}
        </Box>
        <FileUploader
          element={element}
          size={element.size as number}
          name={element.name as string}
          url={element.url as string}
          mimeType={element.mimeType as string}
          handleChange={handleChange}
          handleRemoveNode={handleRemoveNode}
        />
      </PlateElement>
    );
  },
);
