import clsx from "clsx";
import React, { FormEventHandler, useState } from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  Link,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { deviceSupports } from "../../utils/device";
import { AuthButtonProps } from "../../utils/auth";

import { AuthButton } from "./AuthButton";
import { OrDivider } from "./OrDivider";
import { EmailNoAccount } from "./EmailNoAccount";
import { GoogleButton } from "./GoogleButton";
import { AppleButton } from "./AppleButton";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { useNavigate } from "react-router-dom";
import { ENTER_INVITE_CODE_ROUTE } from "../../routes/routes";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 457,
    textAlign: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  emailNoAccount: {
    marginTop: theme.spacing(3),
  },

  googleButton: {
    marginTop: theme.spacing(3),
  },

  divider: {
    marginTop: theme.spacing(3),
  },

  email: {
    marginTop: theme.spacing(3),
  },

  password: {
    marginTop: theme.spacing(2),
  },

  submitButton: {
    marginTop: theme.spacing(3),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },

  forgotPassword: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  footerLinks: {
    marginInline: theme.spacing(-1),
    marginBottom: theme.spacing(10),
  },
}));

export type LoginCredentials = {
  email: string;
  password: string;
};

export interface LoginFormPropsX {
  className?: string;
  credentials: LoginCredentials;
  errors: LoginCredentials;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  disabled?: boolean;
  noAccountEmail?: string;
  onSignIn?: AuthButtonProps["onSuccess"];
  onJoinViaCode?: () => void;
}

export function LoginFormX(props: LoginFormPropsX) {
  const {
    className,
    credentials,
    errors,
    onSubmit,
    onChange,
    disabled = false,
    noAccountEmail,
    onSignIn,
    ...other
  } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleJoinViaCode = () => {
    navigate(ENTER_INVITE_CODE_ROUTE);
  };

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h1">
        Log in to {brandName}
      </Typography>

      {noAccountEmail && (
        <EmailNoAccount className={s.emailNoAccount} email={noAccountEmail} />
      )}

      {(deviceSupports.googleAuthSignInEnabled ||
        deviceSupports.appleAuthSignInEnabled) && (
        <>
          {deviceSupports.appleAuthSignInEnabled && (
            <AppleButton
              onSuccess={onSignIn}
              disabled={disabled}
              children="Sign in with Apple"
            />
          )}

          {deviceSupports.googleAuthSignInEnabled && (
            <GoogleButton
              onSuccess={onSignIn}
              disabled={disabled}
              children="Sign in with Google"
            />
          )}

          <OrDivider className={s.divider} />
        </>
      )}

      <Box component={"form"} onSubmit={onSubmit}>
        <TextField
          className={s.email}
          variant="filled"
          name="email"
          type="email"
          label="Email address"
          placeholder="Email address"
          value={credentials.email}
          onChange={onChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
          disabled={disabled}
          fullWidth
        />

        <TextField
          className={s.password}
          variant="filled"
          name="password"
          type={passwordVisible ? "text" : "password"}
          label="Password"
          placeholder="Password"
          value={credentials.password}
          onChange={onChange}
          error={Boolean(errors.password)}
          helperText={errors.password}
          disabled={disabled}
          fullWidth
          slotProps={{
            input: {
              endAdornment: (
                <IconButton
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  edge="end"
                  size="small"
                >
                  {passwordVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            },
          }}
        />

        <AuthButton
          className={s.submitButton}
          type="submit"
          children="Log in"
          disabled={disabled || !credentials.email || !credentials.password}
        />
      </Box>

      <Typography className={clsx(s.text, s.forgotPassword)}>
        <Link href="/forgot-password">Forgot password?</Link>
      </Typography>

      <Box className={s.footerLinks}>
        <Typography className={s.text}>
          Don’t have an account? <Link href="/signup">Sign Up as Coach</Link>
        </Typography>
        <Typography className={s.text}>
          Invited by a coach?{" "}
          <Link component="button" onClick={handleJoinViaCode}>
            Join via code
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
