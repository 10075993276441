import React, { useEffect, useMemo, useState } from "react";
import {
  Outlet,
  matchPath,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AppLayout from "../../components/app/AppLayout";
import { programsNavigation } from "../../components/coach-programs/ProgramsNavigation";
import {
  COACH_CLIENTS_LIST,
  COACH_PROGRAMS_LIST,
  ALL_ROUTES,
  COACH_GROUPS_ROUTES_LIST,
  COACH_PROGRAM_CREATE_ROUTE,
  COACH_PROGRAMS_FOLDERS_ROUTE,
  COACH_ACTIVITY_ROUTES_LIST,
  COACH_MESSAGE_ROUTES_LIST,
  COACH_CLIENTS_FORMS_ROUTE,
  COACH_GROUPS_ROUTE,
  COACH_CLIENT_ROUTES_LIST,
  COACH_CLIENT_OVERVIEW_ROUTE,
  COACH_PROGRAM_ENROLLMENTS_ROUTE,
  COACH_PROGRAM_CURRICULUM_ROUTE,
  COACH_PROGRAM_SETTING_ROUTE,
  COACH_PROGRAM_COMPONENT_ROUTE,
  COACH_PROGRAM_PREVIEW_ROUTE,
  COACH_SETTINGS_ROUTES_LIST,
  COACH_PLAN_ROUTE,
  COACH_PROGRAM_COMPONENT_CLIENT_SUMMARY,
  COACH_CLIENT_PORTAL_ROUTES_LIST,
} from "../../routes/routes";
import { CreateButton } from "../../components/coach-programs/buttons/CreateProgram";
import { coachActivityNavigation } from "../../components/activity/CoachActivity";
import ProgramsFoldersButtonContext from "../../contexts/ProgramsFoldersPopupContext";
import { useCurrentUser, useCurrentUserRole } from "../../hooks/useCurrentUser";
import { NewMessageAction } from "../../components/messages/NewMessageAction";
import { clientsNavigation } from "../../components/coach-clients/СlientsNavigation";
import AddClientButtonContext from "../../contexts/AddClientContext";
import { CreateClientFormAction } from "../../components/coach-client-forms/CreateClientFormAction";
import { groupsNavigation } from "../../routes/coach/groups/CoachGroupsListRoute";
import { GroupsFilters } from "../../components/filters/GroupsFilters";
import { CreateGroupAction } from "../../components/groups/CreateGroupAction";
import {
  CoachTabsContext,
  CoachGroupsCount,
  CoachClientsCount,
  CoachProgramsCount,
  CoachTabsContextType,
} from "../../contexts/CoachTabsContext";
import { groupNavigation } from "../../routes/coach/group/GroupNavigation";
import {
  GroupSort,
  GroupStatus,
  Plan,
  UserRole,
  planClients,
} from "../../constants";
import GroupContext, { TGroupRoute } from "../../contexts/GroupContext";
import { createPathObjects } from "../../utils/router";
import { getActionForGroup } from "./app-layout-helpers/common";
import {
  SendMessage,
  clientNavigation,
  clientsBreadcrumb,
} from "../../routes/coach/client/utils/common";
import { ClientContext } from "../../contexts/ClientContext";
import {
  coachProgramNavigation,
  programsBreadcrumb,
} from "../../routes/coach/program/utils/CoachProgramNavigation";
import { EnrollmentsFilters } from "../../components/filters/EnrollmentsFilters";
import { EnrolledAvatars } from "../../components/coach-programs/EnrolledAvatars";
import { ProgramContext } from "../../contexts/ProgramContext";
import LayoutLoadingContext from "../../contexts/LayoutLoadingContext";
import { Box, Skeleton } from "@mui/material";
import {
  coachClientPortalNavigation,
  coachSettingsNavigation,
} from "../../routes/coach/settings/utils/common";
import { CreateProgramFolderButton } from "../../components/coach-programs/buttons/CreateProgramFolder";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import ManageGroupDialogContext from "../../contexts/ManageGroupDialogContext";
import { ActionButton } from "../../components/button/ActionButton";
import AddIcon from "@mui/icons-material/Add";
import AddClientDialogContext from "../../contexts/AddClientDialogContext";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import {
  getAfter,
  getStatusFromPath,
  PaginationContext,
  perPage,
} from "../../utils/paging";
import {
  ProgramEnrollmentsAvatarsVm,
  IProgramsCountDto,
  ProgramProfileDto,
} from "@growth-machine-llc/stridist-api-client";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { EditorProgramContext } from "../../components/new-editor/hooks";
import { PreviewProgramButton } from "../../components/coach-programs/buttons/PreviewProgramButton";
import { EnrollAction } from "../../components/coach-programs/EnrollAction";
import { Provider as ReduxStoreProvider } from "react-redux";
import { store } from "../../redux/store";
import PreventLeavingBeforeSaveWrapper from "../redux/PreventLeavingBeforeSaveWrapper";
import { HeaderButton } from "../../components/button/HeaderButton";
import ExitWorkoutButton from "../../components/coach-programs/buttons/ExitWorkoutButton";

const DEFAULT_CONFIG = {
  title: undefined,
  subtitle: undefined,
  actions: [],
  tabsNavigation: undefined,
  appBar: true,
  appDrawer: true,
  filters: undefined,
  breadcrumbs: [],
  hideUpgradeBanner: false,
  appBarHideOnMobile: false,
};

const AppLayoutWrapper = () => {
  const { slug, username, date, component, client, recipient } = useParams();
  const role = useCurrentUserRole();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isBrandedWorkspace } = useCurrentBrand();
  const user = useCurrentUser();
  const unreadMessagesCount = user?.unreadMessagesCount ?? 0;
  const navigate = useNavigate();
  const isMobile = useMediaMobile("md", false);

  // PORTALS
  const addClientButtonPortal = React.useRef<HTMLDivElement>(null);

  // GROUPS CONTEXT
  const [coachGroups, setCoachGroups] = useState<CoachGroupsCount>({
    active: undefined,
    archived: undefined,
  });

  // PROGRAMS CONTEXT
  const [coachPrograms, setCoachPrograms] = useState<CoachProgramsCount>({
    draft: undefined,
  });

  // CLIENTS CONTEXT
  const [coachClients, setCoachClients] = useState<CoachClientsCount>({
    pending: undefined,
  });

  // COACH TABS UNIFIED CONTEXT
  const coachTabsContextValue: CoachTabsContextType = useMemo(
    () => ({
      clientsCount: coachClients,
      programsCount: coachPrograms,
      groupsCount: coachGroups,

      setClientsCount: setCoachClients,
      setProgramsCount: setCoachPrograms,
      setGroupsCount: setCoachGroups,
    }),
    [coachGroups, coachPrograms],
  );

  // GROUP CONTEXT
  const groupContextLoadingState = {
    name: "...",
    totalMembersCount: undefined,
  };
  const [groupBriefInfo, setGroupBriefInfo] = useState<TGroupRoute>(
    groupContextLoadingState,
  );

  const groupContextValue = useMemo(
    () => ({
      groupBriefInfo,
      setGroupBriefInfo,
      resetGroupBriefInfo: () => setGroupBriefInfo(groupContextLoadingState),
    }),
    [groupBriefInfo],
  );

  // CLIENT CONTEXT
  const coachClientLoadingState = { displayName: "...", username: "..." };
  const [coachClientQuery, setCoachClientQuery] = useState<any>({
    client: coachClientLoadingState,
  });

  const clientContextValue = useMemo(
    () => ({
      coachClientQuery,
      setCoachClientQuery,
      resetCoachClientQuery: () =>
        setCoachClientQuery({
          client: coachClientLoadingState,
        }),
    }),
    [coachClientQuery],
  );

  // PROGRAM CONTEXT
  const programLoadingState = new ProgramEnrollmentsAvatarsVm({
    enrollmentAvatars: [],
    totalEnrollmentsCount: undefined,
    programInfo: new ProgramProfileDto({
      name: "...",
    }),
  });
  const [programQuery, setProgramQuery] =
    useState<ProgramEnrollmentsAvatarsVm>(programLoadingState);

  const programContextValue = useMemo(
    () => ({
      programQuery,
      setProgramQuery,
      resetProgramQuery: () => setProgramQuery(programLoadingState),
    }),
    [programQuery],
  );

  const clientDisplayName =
    clientContextValue.coachClientQuery?.client?.displayName;
  const clientUserName = clientContextValue.coachClientQuery?.client?.username;

  const enrollmentsAvatars =
    programContextValue.programQuery?.enrollmentAvatars;
  const totalEnrollmentsCount =
    programContextValue.programQuery?.totalEnrollmentsCount;
  const program = programContextValue.programQuery?.programInfo;
  const clients = {
    totalCount: programContextValue.programQuery?.totalClientsCount,
  };
  const groups = {
    totalCount: programContextValue.programQuery?.totalGroupsCount,
  };

  // LOADING CONTEXT
  const [loading, setLoading] = useState(false);

  const loadingContextValue = useMemo(
    () => ({ loading, setLoading }),
    [loading],
  );

  // ACTION BUTTONS
  const programsFoldersButtonState = usePopupState({
    variant: "popover",
    popupId: "program-folder-rename",
  });

  // MANAGE GROUP DIALOG
  const [manageGroupDialogOpen, setManageGroupDialogOpen] =
    React.useState(false);

  const manageGroupDialogOpenContextValue = {
    manageGroupDialogOpen,
    setManageGroupDialogOpen,
  };

  // ADD CLIENT DIALOG

  const [addClientDialog, setAddClientDialog] = useState(false);

  const addClientDialogContextValue = {
    addClientDialog,
    setAddClientDialog,
  };

  // CONFIGS
  const getAppLayoutConfigs = (pathname: string) => {
    try {
      const [{ route }] = matchRoutes(createPathObjects(ALL_ROUTES), pathname);
      if (
        COACH_PROGRAM_COMPONENT_CLIENT_SUMMARY.replace(":slug", slug)
          .replace(":date", date)
          .replace(":component", component)
          .replace(":client", client) === location.pathname
      )
        return {
          ...DEFAULT_CONFIG,
          title: "Program",
          subtitle: "End workout",
          breadcrumbs: [{ name: "" }],
          appBar: true,
          appDrawer: true,
          actions: [<ExitWorkoutButton />],
        };

      if (COACH_PROGRAMS_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Programs",
          breadcrumbs: [
            {
              name: "Programs",
            },
          ],
          actions:
            pathname === COACH_PROGRAMS_FOLDERS_ROUTE
              ? [
                  <CreateProgramFolderButton
                    {...bindTrigger(programsFoldersButtonState)}
                  />,
                ]
              : [<CreateButton />],
          tabsNavigation:
            pathname === COACH_PROGRAM_CREATE_ROUTE
              ? undefined
              : programsNavigation(coachPrograms),
          appBar: pathname === COACH_PROGRAM_CREATE_ROUTE ? false : true,
          appDrawer: pathname === COACH_PROGRAM_CREATE_ROUTE ? false : true,
        };

      if (COACH_CLIENTS_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Clients",
          tabsNavigation: clientsNavigation(coachClients),
          breadcrumbs: [
            {
              name: "Clients",
            },
          ],
          actions:
            pathname === COACH_CLIENTS_FORMS_ROUTE
              ? [<CreateClientFormAction />]
              : [
                  <HeaderButton
                    startIcon={<AddIcon />}
                    children="Add a client"
                    onClick={() => {
                      setAddClientDialog(true);
                    }}
                  />,
                ],
        };

      if (COACH_GROUPS_ROUTES_LIST.includes(route.path)) {
        const status = getStatusFromPath(location.pathname);
        const count =
          status === GroupStatus.ACTIVE
            ? coachGroups?.active
            : coachGroups?.archived;
        return {
          ...DEFAULT_CONFIG,
          title: "Groups",
          tabsNavigation: groupsNavigation(coachGroups),
          filters: count > 0 && <GroupsFilters />,
          breadcrumbs: [
            {
              name: "Groups",
            },
          ],
          actions: [<CreateGroupAction />],
        };
      }
      if (COACH_ACTIVITY_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Client Activity",
          tabsNavigation: coachActivityNavigation,
          breadcrumbs: [
            {
              name: "Activity",
            },
          ],
        };

      if (COACH_MESSAGE_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: !isMobile ? "Messages" : null,
          subtitle: !isMobile
            ? `${unreadMessagesCount} new message${
                unreadMessagesCount === 1 ? "" : "s"
              }`
            : null,
          breadcrumbs: [
            {
              name: "Messages",
            },
          ],
          actions: !isMobile && [<NewMessageAction Button={HeaderButton} />],
          appBarHideOnMobile: recipient ? true : false,
        };

      if (route.path.includes(COACH_GROUPS_ROUTE)) {
        return {
          ...DEFAULT_CONFIG,
          title: groupBriefInfo.name,
          tabsNavigation: groupNavigation(
            slug,
            role as UserRole,
            groupBriefInfo.totalMembersCount,
          ),
          breadcrumbs: [
            {
              name: "Groups",
              link: COACH_GROUPS_ROUTE,
            },
          ],
          actions: getActionForGroup({
            pathname,
            groupRouteQuery: groupBriefInfo,
            userId: user?.id,
            setManageGroupDialogOpen,
          }),
        };
      }
      if (COACH_CLIENT_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: clientDisplayName,
          breadcrumbs: [clientsBreadcrumb],
          tabsNavigation: clientNavigation(username),
          actions: matchPath(COACH_CLIENT_OVERVIEW_ROUTE, pathname) && [
            <SendMessage username={username} />,
          ],
        };

      if (COACH_PROGRAM_ENROLLMENTS_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: program.name,
          tabsNavigation: coachProgramNavigation(
            program.slug,
            totalEnrollmentsCount,
          ),
          breadcrumbs: [programsBreadcrumb],

          actions: [
            <EnrolledAvatars
              enrollments={enrollmentsAvatars}
              totalEnrollmentsCount={totalEnrollmentsCount}
              programSlug={program.slug}
            />,
            <EnrollAction
              program={program}
              clientsCount={clients.totalCount}
              groupsCount={groups.totalCount}
            />,
          ],
          filters: <EnrollmentsFilters />,
        };

      if (COACH_PROGRAM_CURRICULUM_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: program.name,
          tabsNavigation: coachProgramNavigation(
            program.slug,
            totalEnrollmentsCount,
          ),
          breadcrumbs: [programsBreadcrumb],

          actions: [
            <EnrolledAvatars
              enrollments={enrollmentsAvatars}
              totalEnrollmentsCount={totalEnrollmentsCount}
              programSlug={program.slug}
            />,
            <EnrollAction
              program={program}
              clientsCount={clients.totalCount}
              groupsCount={groups.totalCount}
            />,
            <EditorProgramContext.Provider value={{ programId: program.id }}>
              <PreviewProgramButton />
            </EditorProgramContext.Provider>,
          ],
        };

      if (COACH_PROGRAM_SETTING_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: program.name,
          tabsNavigation: coachProgramNavigation(
            program.slug,
            totalEnrollmentsCount,
          ),
          breadcrumbs: [programsBreadcrumb],

          actions: [
            <EnrolledAvatars
              enrollments={enrollmentsAvatars}
              totalEnrollmentsCount={totalEnrollmentsCount}
              programSlug={program.slug}
            />,
            <EnrollAction
              program={program}
              clientsCount={clients.totalCount}
              groupsCount={groups.totalCount}
            />,
          ],
        };

      if (COACH_PROGRAM_COMPONENT_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          appBar: false,
          appDrawer: false,
          hideUpgradeBanner: true,
        };

      if (COACH_PROGRAM_PREVIEW_ROUTE.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          appBar: false,
          appDrawer: false,
        };

      if (COACH_SETTINGS_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Settings",
          tabsNavigation: coachSettingsNavigation(true),
          breadcrumbs: [{ name: "Settings" }],
        };

      if (COACH_CLIENT_PORTAL_ROUTES_LIST.includes(route.path))
        return {
          ...DEFAULT_CONFIG,
          title: "Client portal",
          breadcrumbs: [],
          tabsNavigation: coachClientPortalNavigation(isBrandedWorkspace),
        };

      return DEFAULT_CONFIG;
    } catch (error) {
      return DEFAULT_CONFIG;
    }
  };

  const AppLayoutData = getAppLayoutConfigs(location.pathname);

  const page = searchParams.get("page");
  const sortKey = searchParams.get("sort");
  const paginationContextValue = {
    after: getAfter(perPage, Number(page ?? "1")),
    first: perPage,
    orderBy: sortKey ?? GroupSort.NAME_ASC,
    status: getStatusFromPath(location.pathname),
  };

  const stateTitle = location.state?.title;

  useEffect(() => {
    setLoading(false);
  }, [location.pathname, location.state]);

  const appLayoutTitle = useMemo(() => {
    if (loading || AppLayoutData.title === "...")
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 28,
          }}
        >
          {stateTitle ?? <Skeleton variant="text" height={20} width={350} />}
        </Box>
      );

    return AppLayoutData.title;
  }, [loading, AppLayoutData.title]);

  return (
    <ReduxStoreProvider store={store}>
      <PreventLeavingBeforeSaveWrapper>
        <ProgramsFoldersButtonContext.Provider
          value={programsFoldersButtonState}
        >
          <AddClientButtonContext.Provider value={addClientButtonPortal}>
            <CoachTabsContext.Provider value={coachTabsContextValue}>
              <GroupContext.Provider value={groupContextValue}>
                <ClientContext.Provider value={clientContextValue}>
                  <ProgramContext.Provider value={programContextValue}>
                    <LayoutLoadingContext.Provider value={loadingContextValue}>
                      <PaginationContext.Provider
                        value={paginationContextValue}
                      >
                        <AddClientDialogContext.Provider
                          value={addClientDialogContextValue}
                        >
                          <ManageGroupDialogContext.Provider
                            value={manageGroupDialogOpenContextValue}
                          >
                            <AppLayout
                              title={appLayoutTitle}
                              subtitle={AppLayoutData.subtitle}
                              tabsNavigation={AppLayoutData.tabsNavigation}
                              actions={AppLayoutData.actions}
                              appBar={AppLayoutData.appBar}
                              appDrawer={AppLayoutData.appDrawer}
                              filters={AppLayoutData.filters}
                              breadcrumbs={AppLayoutData.breadcrumbs}
                              hideUpgradeBanner={
                                AppLayoutData.hideUpgradeBanner
                              }
                              appBarHideOnMobile={
                                AppLayoutData.appBarHideOnMobile
                              }
                            >
                              <Outlet />
                            </AppLayout>
                          </ManageGroupDialogContext.Provider>
                        </AddClientDialogContext.Provider>
                      </PaginationContext.Provider>
                    </LayoutLoadingContext.Provider>
                  </ProgramContext.Provider>
                </ClientContext.Provider>
              </GroupContext.Provider>
            </CoachTabsContext.Provider>
          </AddClientButtonContext.Provider>
        </ProgramsFoldersButtonContext.Provider>
      </PreventLeavingBeforeSaveWrapper>
    </ReduxStoreProvider>
  );
};

export default AppLayoutWrapper;
