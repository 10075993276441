import React, { useState } from "react";
import { ComponentRepeat, ComponentType } from "../../../constants";
import { Box, Grid2 as Grid, useTheme } from "@mui/material";
import { CurriculumComponent } from "../../../redux/types";
import RepeatsMap from "./RepeatsMap";
import StartWeekSelect from "../components/schedule/StartWeekSelect";
import EndWeekSelect from "../components/schedule/EndWeekSelect";
import RepeatsSelect from "../components/schedule/RepeatsSelect";
import DaysComponent from "../components/schedule/DaysComponent";
import { Map } from "lucide-react";
import { useProgramWeeks } from "../../../hooks/useProgramWeeks";
import { parseComponentDays } from "../../../utils/component";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../../redux/hooks";
import { updateComponentSchedule } from "../../../redux/curriculum/curriculum-slice";
import {
  getScheduleDuration,
  getScheduleEndWeek,
} from "../../program-component/utils";
import { Schedule } from "../../schedule/types";
import OverridesComponent from "../components/schedule/OverridesComponent";
import {
  selectComponentById,
  selectComponentOverrides,
} from "../../../redux/curriculum/selectors/curriculum";
import SidebarTabTitle from "../components/SidebarTabTitle";

type ScheduleParams = {
  startWeek: number;
  endWeek: number;
  repeats: ComponentRepeat;
};

interface ISidebarScheduleTabProps {
  componentData: CurriculumComponent;
  color: string;
  visibleWeeks?: number[];
}

const ScheduleWithMap = (props: ISidebarScheduleTabProps) => {
  const { componentData: component, color, visibleWeeks } = props;
  const WEEKS_LIST = useProgramWeeks();
  const dispatch = useCurriculumDispatch();
  const theme = useTheme();

  //
  // UI STATES
  const [minimizeMap, setMinimizeMap] = useState(false);
  const [flashInputs, setFlashInputs] = useState(false);
  const flashTimeoutRef = React.useRef<number | null>(null);

  //
  // UI HANDLERS
  const handleMapIconClick = () => {
    setMinimizeMap((v) => !v);
  };

  const handleFlashInputs = () => {
    if (flashTimeoutRef.current) {
      clearTimeout(flashTimeoutRef.current);
    }
    setFlashInputs(true);
    flashTimeoutRef.current = window.setTimeout(() => {
      setFlashInputs(false);
      flashTimeoutRef.current = null;
    }, 1000);
  };

  //
  // DATA
  const DAYS = parseComponentDays(component.days);
  const START_WEEK = component.weekId;
  const END_WEEK = getScheduleEndWeek(
    component.weekId,
    component.duration,
    WEEKS_LIST,
    component.repeat,
  );
  const REPEAT = component.repeat;
  const OVERRIDES = useCurriculumSelector(
    selectComponentOverrides(component.id),
  );
  const baseComponent = useCurriculumSelector(
    selectComponentById(component.baseComponentId),
  );

  //
  // HELPERS
  const updateSchedule = (updates: Partial<Schedule>) => {
    dispatch(
      updateComponentSchedule({
        componentId: component.id,
        schedule: {
          weekId: component.weekId,
          repeat: component.repeat,
          days: parseComponentDays(component.days),
          duration: component.duration,
          reminderType: component.reminderType,
          reminderTime: component.reminderTime,
          messageTime: component.messageTime,
          ...updates,
        },
      }),
    );
  };

  const calculateDuration = (overrides: Partial<ScheduleParams> = {}) => {
    const params = {
      startWeek: START_WEEK,
      endWeek: END_WEEK,
      repeats: REPEAT,
      ...overrides,
    };
    return getScheduleDuration(
      params.startWeek,
      params.repeats,
      params.endWeek,
      WEEKS_LIST,
    );
  };

  //
  // DATA HANDLERS
  // Important: duration should be recalculated on
  // any schedule related update with actual params

  // Start
  const handleStartWeekChange = (result: number) =>
    updateSchedule({
      duration: IS_OVERRIDE ? 1 : calculateDuration({ startWeek: result }),
      weekId: result,
    });

  // Repeats
  const handleRepeatsChange = (result: ComponentRepeat) =>
    updateSchedule({
      duration: calculateDuration({ repeats: result }),
      repeat: result,
    });

  // End
  const handleEndWeekChange = (result: number) =>
    updateSchedule({
      duration: calculateDuration({ endWeek: result }),
    });

  // Toggle Repeats
  const handleRepeatsToggle = (result: ComponentRepeat, duration: number) =>
    updateSchedule({
      duration,
      repeat: result,
    });

  // Toggle End (direct `duration` update, no `endWeek` logic)
  const handleEndWeekToggle = (duration: number) =>
    updateSchedule({
      duration,
    });

  // Days
  const handleDaysChange = (days: boolean[]) => {
    // Prevent unselect last day
    !days.every((item) => item === false) &&
      updateSchedule({
        duration: calculateDuration(),
        days,
      });
  };

  //
  // UI
  const IS_LESSON = component.type === ComponentType.LESSON;
  const IS_REPEATED = REPEAT !== ComponentRepeat.NONE;
  const IS_OVERRIDE = !!component.baseComponentId;
  const OVERRIDES_ASSETS = OVERRIDES.map((override) => {
    return {
      id: override.id,
      slug: override.slug,
      dayNumber:
        parseComponentDays(override.days).findIndex((d) => d === true) + 1,
      weekNumber: WEEKS_LIST.find((w) => w.id === override.weekId).week,
    };
  });

  const baseComponentAsset = baseComponent
    ? {
        id: baseComponent.id,
        slug: baseComponent.slug,
      }
    : null;

  return (
    <>
      <Box p={2} pt={0}>
        <SidebarTabTitle
          title="Schedule"
          icon={<Map />}
          showIcon={false}
          handleIconClick={handleMapIconClick}
        />
        <Grid container spacing={2}>
          <Box position={"relative"} display={"flex"} gap={2}>
            <Grid
              size={"grow"}
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              position={"sticky"}
              top={theme.spacing(1.5)}
              height={"fit-content"}
              minWidth={145}
            >
              <StartWeekSelect
                value={START_WEEK}
                onChange={handleStartWeekChange}
                weeks={WEEKS_LIST}
                endWeek={END_WEEK}
                repeats={IS_REPEATED}
                flashInputs={flashInputs}
              />

              <RepeatsSelect
                value={REPEAT}
                onChange={handleRepeatsChange}
                handleRepeatsToggle={handleRepeatsToggle}
                weeks={WEEKS_LIST}
                disabled={IS_LESSON || IS_OVERRIDE}
                flashInputs={flashInputs}
                tooltipTitle={
                  IS_LESSON ? (
                    <>
                      Repeated lessons are not supported for better client
                      experience. <br />
                      <b>Tip:</b> duplicate lesson instead
                    </>
                  ) : IS_OVERRIDE ? (
                    <>
                      Custom day can not be repeated. <b>Tip:</b> create another
                      custom day or a brand new repeated{" "}
                      {component?.type?.toLocaleLowerCase()}
                    </>
                  ) : undefined
                }
              />

              <EndWeekSelect
                value={END_WEEK}
                onChange={handleEndWeekChange}
                weeks={WEEKS_LIST}
                startWeek={START_WEEK}
                repeat={REPEAT}
                handleEndWeekToggle={handleEndWeekToggle}
                hidden={!IS_REPEATED}
                disabled={IS_LESSON || IS_OVERRIDE}
                flashInputs={flashInputs}
              />
            </Grid>
            <Grid
              size={"auto"}
              position={"sticky"}
              top={theme.spacing(1.5)}
              height={"fit-content"}
              sx={{
                overflow: "visible",
              }}
            >
              <RepeatsMap
                component={component}
                enabledIndices={"none"}
                onContainerClick={handleFlashInputs}
                highlightWeeks={visibleWeeks}
              />
            </Grid>
          </Box>

          <Grid
            size={12}
            sx={{
              position: "sticky",
              bottom: 0,
              background: theme.palette.common.white,
              pb: 1,
              pt: 0.5,
              "&::before": {
                content: '""',
                display: "inline",
                backgroundColor: theme.palette.common.white,
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                zIndex: -1,
                left: theme.spacing(2),
              },
            }}
          >
            <DaysComponent
              days={DAYS}
              color={color}
              handleDaysChange={handleDaysChange}
              singleDayMode={IS_OVERRIDE || IS_LESSON}
            />
          </Grid>

          <Grid size={12}>
            <OverridesComponent
              overrides={OVERRIDES_ASSETS}
              component={component}
              baseComponent={baseComponentAsset}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ScheduleWithMap;
