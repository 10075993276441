import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Button, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GroupPostsListItem } from "./GroupPostsListItem";
import { GroupPostsEmpty } from "./GroupPostsEmpty";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import { LoadMoreButton } from "../button/LoadMoreButton";
import { GroupPostSkeleton } from "../loading/GroupPostSkeleton";
import GroupPostsService from "../../services/GroupPostsService";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupPostsListProps extends BoxProps {
  groupId: number;
}

export const GROUP_POSTS_LIST_QUERY_KEY = "group-posts-list";
const GROUP_POSTS_LIST_PAGE_SIZE = 5;
const FETCH_NEXT_PAGE_WHEN_REMAINING = 2;
export function GroupPostsList(props: GroupPostsListProps) {
  const { className, groupId, ...other } = props;
  const {
    data: postsData,
    ref: groupListItemRef,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingInitial,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteScrollQuery({
    queryKey: [GROUP_POSTS_LIST_QUERY_KEY, { groupId }],
    queryFn: ({ pageParam }) =>
      GroupPostsService.getGroupPosts(
        groupId,
        GROUP_POSTS_LIST_PAGE_SIZE,
        pageParam ? Number(pageParam) : null,
      ),
    retry: false,
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage?.nextCursor,
  });

  const s = useStyles();

  const posts = postsData?.pages.flatMap((page) => page.items) ?? [];
  const isLoading = isLoadingInitial || (isFetching && !isFetchingNextPage);

  const handleMoreClick = React.useCallback(() => {
    if (!isLoading) {
      fetchNextPage();
    }
  }, [isLoading, fetchNextPage]);

  return isLoading ? (
    <Box className={clsx(s.root, className)} {...other}>
      <Stack
        spacing={3}
        sx={{
          mb: 4,
        }}
      >
        {Array.from({ length: 2 }).map((_, index) => (
          <GroupPostSkeleton key={`group-post-skeleton-${index}`} />
        ))}
      </Stack>
    </Box>
  ) : posts.length ? (
    <Box className={clsx(s.root, className)} {...other}>
      {posts.map((post, index) => (
        <>
          <GroupPostsListItem key={post.id} post={post} />
          {posts.length - index === FETCH_NEXT_PAGE_WHEN_REMAINING && (
            <div ref={groupListItemRef} />
          )}
        </>
      ))}

      {hasNextPage && (
        <LoadMoreButton
          onClick={handleMoreClick}
          disabled={isLoading}
          loading={isFetchingNextPage}
          fullWidth
        >
          Show older posts
        </LoadMoreButton>
      )}
    </Box>
  ) : (
    <GroupPostsEmpty groupId={groupId} />
  );
}
