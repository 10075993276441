import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import {
  CurriculumDto,
  CurriculumVm,
  EnrollmentProgramDto,
  ICurriculumVm,
  IEnrollmentsVm,
  IProgramEnrollmentsAvatarsVm,
  IProgramsCountDto,
  ProgramProfileDto,
  ProgramSettingsDto,
  ProgramStatus,
  UpdateProgramCommand,
} from "@growth-machine-llc/stridist-api-client";
import { PROGRAM_SETTINGS_QUERY_KEY } from "../../../routes/coach/program/settings/CoachProgramSettingsRoute";
import ProgramsService from "../../../services/ProgramsService";
import { extractSlugId, refreshSlug } from "../../../utils/slug";
import { useQueryClient } from "@tanstack/react-query";
import { PROGRAM_ENROLLMENTS_QUERY_KEY } from "../../../routes/coach/program/enrollments/CoachProgramEnrollmentsRoute";
import { CURRICULUM_VIEW_KEY } from "../../../routes/coach/program/curriculum/CoachProgramCurriculumRoute";
import { COACH_PROGRAMS_WRAPPER_QUERY_KEY } from "../../../wrappers/router/coach/CoachProgramsWrapper";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../../coach-programs/CoachProgramsListScreen";
import { COACH_PROGRAMS_COUNT_QUERY_KEY } from "../../../wrappers/router/coach/CoachProgramsListWrapper";

interface UpdateProgramMutationProps {
  programSlug: string;
  disableToastAlerts?: boolean;
}

export const useUpdateProgramMutation = ({
  programSlug,
  disableToastAlerts,
}: UpdateProgramMutationProps) => {
  const slugId = extractSlugId(programSlug);
  const queryClient = useQueryClient();
  return useOptimisticUpdateMutation({
    queryKey: [PROGRAM_SETTINGS_QUERY_KEY, { slugId }],
    mutationFn: ProgramsService.updateProgram,
    disableToastAlerts,
    optimisticUpdater: {
      updateFn: (data: ProgramSettingsDto, variables) => {
        Object.keys(variables).forEach((key) => {
          data[key] = variables[key];
        });
        if ("name" in variables) {
          data.slug = refreshSlug(programSlug, variables.name);
        }
        return data;
      },
    },
    // TODO_API_V2_PROGRAM invalidate dependant program queries after they are implemented
    options: {
      onSuccess: (_, variables) => {
        if ("name" in variables) {
          const newSlug = refreshSlug(programSlug, variables.name);
          queryClient.setQueryData<IEnrollmentsVm>(
            [PROGRAM_ENROLLMENTS_QUERY_KEY, { slugId }],
            (prev) =>
              prev
                ? {
                    ...prev,
                    program: new EnrollmentProgramDto({
                      ...prev.program,
                      slug: newSlug,
                    }),
                  }
                : prev,
          );

          queryClient.setQueryData<IProgramEnrollmentsAvatarsVm>(
            [COACH_PROGRAMS_WRAPPER_QUERY_KEY, { slugId }],
            (prev) =>
              prev
                ? {
                    ...prev,
                    programInfo: new ProgramProfileDto({
                      ...prev.programInfo,
                      name: variables.name,
                      slug: newSlug,
                    }),
                  }
                : prev,
          ),
            queryClient.setQueryData<ICurriculumVm>(
              [CURRICULUM_VIEW_KEY, { slugId }],
              (prev) =>
                prev
                  ? {
                      ...prev,
                      curriculum: new CurriculumDto({
                        ...prev.curriculum,
                        name: variables.name,
                        slug: newSlug,
                      }),
                    }
                  : prev,
            );

          queryClient.invalidateQueries({
            queryKey: [COACH_PROGRAMS_LIST_QUERY_KEY],
            exact: false,
          });
        }

        if (variables.status === ProgramStatus.DRAFT) {
          queryClient.setQueryData<IProgramsCountDto>(
            [COACH_PROGRAMS_COUNT_QUERY_KEY],
            (prev) => (prev ? { draft: prev.draft + 1 } : prev),
          );
        }
      },
    },
  });
};
