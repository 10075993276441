import { NavigationItem } from "../../../../constants";
import {
  COACH_BRANDED_APP_ROUTE,
  COACH_CLIENT_PORTAL_ROUTE,
  COACH_PLAN_ROUTE,
  COACH_PROFILE_ROUTE,
  COACH_SETTING_NOTIFICATIONS_ROUTE,
  COACH_THEME_ROUTE,
} from "../../../routes";

export const coachClientPortalNavigation = (
  branded?: boolean,
): NavigationItem[] => [
  {
    name: "Settings",
    link: COACH_CLIENT_PORTAL_ROUTE,
  },
  {
    name: "Theme",
    link: COACH_THEME_ROUTE,
  },

  ...(branded
    ? [
        {
          name: "Branded app",
          link: COACH_BRANDED_APP_ROUTE,
        },
      ]
    : []),
];

export const coachSettingsNavigation = (
  subscribed?: boolean,
): NavigationItem[] => [
  {
    name: "Profile",
    link: COACH_PROFILE_ROUTE,
  },
  {
    name: "Notifications",
    link: COACH_SETTING_NOTIFICATIONS_ROUTE,
  },
  {
    name: subscribed ? "Plan" : "Upgrade",
    link: COACH_PLAN_ROUTE,
  },
];
