import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { HabitCompleteButton } from "./HabitCompleteButton";

export const defaultPromptValue = "Did you follow your habit today?";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0),

    [theme.containerQueries.up("md")]: {
      border: "1px solid",
      borderColor: theme.palette.border.primary,
      borderRadius: theme.spacing(1),
      boxShadow: theme.shadows[4],

      padding: theme.spacing(4, 2, 2),
      margin: theme.spacing(5, 0),
    },

    [theme.breakpoints.up("md")]: {
      border: "1px solid",
      borderColor: theme.palette.border.primary,
      borderRadius: theme.spacing(1),
      boxShadow: theme.shadows[4],

      padding: theme.spacing(4, 2, 2),
      margin: theme.spacing(5, 0),
    },
  },

  prompt: {
    fontSize: 18,
    fontWeight: 500,
    margin: theme.spacing(0, 0, 3, 0),
  },

  button: {
    border: "1px solid",
    borderColor: theme.palette.border.primary,
    padding: theme.spacing(1.75, 7.5),
  },
}));

export interface HabitPromptProps {
  prompt?: string;
  completed?: boolean;
  submitted?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export function HabitPrompt(props: HabitPromptProps) {
  const {
    className,
    completed,
    submitted,
    disabled = Boolean(props.submitted),
    prompt,
    onClick,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography variant="h5" className={s.prompt}>
        {prompt || defaultPromptValue}
      </Typography>

      <HabitCompleteButton
        completed={completed}
        onClick={onClick}
        disabled={disabled}
      />
    </Box>
  );
}
