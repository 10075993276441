import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  TextField,
  Button,
  useTheme,
  Collapse,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";

import InputPlusMinus from "../input-plus-minus/InputPlusMinus";
import CoachSets from "../coach-sets/CoachSets";
import {
  ExerciseTypeExtra,
  ExerciseTypeReps,
  ExerciseTypeSet,
  UnitsTime,
} from "../../constants";

import { WorkoutExercise, WorkoutExerciseImage } from "../workout/types";
import { WorkoutMedia } from "../workout/WorkoutMedia";
import { WorkoutUploadMedia } from "../workout/WorkoutUploadMedia";
import {
  DEFAULT_NUMBER_OF_EXERCISE_SETS,
  DEFAULT_VALUE_OF_EXERCISE_REPS,
  MAX_EXERCISE_SETS_COUNT,
  createEmptyExercise,
  createEmptySet,
  getSetFieldMaxLength,
  normalizeObsoleteExercise,
} from "../workout/utils";
import { WorkoutDemoVideo } from "../workout/WorkoutDemoVideo";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { WorkoutActionsDelete } from "../workout/WorkoutActionsDelete";
import WorkoutExerciseSettings from "../workout/WorkoutExerciseSettings";

import { useAddExercise } from "../../hooks/useAddExercise";
import Settings from "../../icons/Settings";

import { AdminExerciseAutocomplete } from "./AdminExerciseAutocomplete";
import { useAdminSaveExerciseLibraryAsset } from "./hooks/useAdminSaveExerciseLibraryAsset";
import {
  AdminExerciseAssetDto,
  BodypartType,
  MovementPatternType,
} from "@growth-machine-llc/stridist-api-client";
import {
  BodypartTypeLabels,
  MovementPatternTypeLabels,
} from "../exercise-library/constants";
import CategorySelect from "./CategorySelect";

const useStyles = makeStyles((theme) => ({
  root: {},

  wrapper: {
    paddingBottom: theme.spacing(10),
  },

  workoutEditTitle: {
    color: theme.palette.secondary.main,
    textTransform: "capitalize",
    margin: `${theme.spacing(2.5, 0, 4, 0)}!important`,
  },

  setsContainerInput: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2.5, 0),
  },

  setsTopContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  settingsButton: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    fontSize: 16,
  },

  label: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontSize: 16,
    margin: theme.spacing(2.5, 0, 2, 0),
  },

  setsLabel: {
    marginRight: 10,
  },

  textField: {},

  input: {
    fontWeight: 500,
  },

  caretDownIcon: {
    width: 11,
  },

  images: {
    display: "flex",
    flexWrap: "wrap",
  },

  actions: {
    display: "flex",
    position: "sticky",
    zIndex: 999,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0),
    bottom: theme.spacing(-3),
    right: 0,
    left: 0,
  },

  button: {
    borderWidth: "2px !important",
    borderStyle: "solid",
    padding: theme.spacing(1.25, 0),
    fontWeight: 700,
    fontSize: 14,
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },

    "&:not([disabled])": {
      borderColor: theme.palette.secondary.main,
    },

    "&[disabled]": {
      borderColor: theme.palette.text.secondary,
    },
  },

  cancelButton: {
    "&[disabled]": {
      color: theme.palette.text.secondary,
    },
  },

  saveButton: {
    "&[disabled]": {
      backgroundColor: theme.palette.text.secondary,
      color: theme.palette.common.white,
    },
  },

  checkboxLabel: {
    color: theme.palette.text.primary,
    fontWeight: "500 !important" as any,
  },
  addToLibraryText: {
    color: theme.palette.text.secondary,
    lineHeight: "17px",
    marginTop: 20,
  },
}));

export interface AdminWorkoutEditExerciseProps extends BoxProps {
  exerciseAsset?: AdminExerciseAssetDto;
  onUpdate?: (exercise: WorkoutExercise) => void;
  onClose?: (event: any) => void;
  onExistedChange?: (existed: boolean) => void;
  createNewAsset?: boolean;
}

export function AdminWorkoutEditExercise(props: AdminWorkoutEditExerciseProps) {
  const {
    className,
    exerciseAsset,
    onUpdate,
    onClose,
    onExistedChange,
    createNewAsset,
    ...other
  } = props;
  const s = useStyles();
  const defaultValue = exerciseAsset
    ? (JSON.parse(exerciseAsset.content) as WorkoutExercise)
    : (createEmptyExercise(
        DEFAULT_NUMBER_OF_EXERCISE_SETS,
        DEFAULT_VALUE_OF_EXERCISE_REPS,
      ) as WorkoutExercise);
  const readOnlyView = !!exerciseAsset?.coachId;
  const user = useCurrentUser();
  const defaultNormalizedExercise = normalizeObsoleteExercise({
    ...(defaultValue ? defaultValue : {}),
    title: defaultValue?.title,
    subtitle: defaultValue?.subtitle,
    instructions: defaultValue?.instructions,
    images: defaultValue?.images,
    video: defaultValue?.video,
  });
  const [exercise, setExercise] = React.useState(defaultNormalizedExercise);
  const [isMatchedOption, setIsMatchedOption] = React.useState<boolean>();
  const saveExercise = useAdminSaveExerciseLibraryAsset(createNewAsset);
  const [exercisesFetching, setExercisesFetching] = React.useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = React.useState(false);
  const [chooseMedia, setChooseMedia] = React.useState(null);
  const [openExerciseSettings, setOpenExerciseSettings] = React.useState(false);
  const [addMedia, setAddMedia] = React.useState<
    WorkoutExerciseImage[] | WorkoutExerciseImage
  >(null);
  const [addOwnVideo, setAddOwnVideo] = React.useState(null);
  const [isAddingVideoOrMedia, setIsAddingVideoOrMedia] = React.useState(false);
  const typeReps = exercise?.typeReps || ExerciseTypeReps.WHOLE;
  const typeSet = exercise?.typeSet || ExerciseTypeSet.WEIGHT;
  const [selectedBodypart, setSelectedBodypart] = React.useState<BodypartType>(
    exerciseAsset?.bodypart || BodypartType.NONE,
  );
  const [selectedMovementPatternType, setSelectedMovementPatternType] =
    React.useState<MovementPatternType>(
      exerciseAsset?.movementPattern || MovementPatternType.NONE,
    );

  const getDefaultUnits = (type: ExerciseTypeSet) =>
    type === ExerciseTypeSet.TIME ? UnitsTime.MINUTES : user.units;

  const handleExercisesFetching = React.useCallback((value: boolean) => {
    setExercisesFetching(value);
  }, []);

  const handleOpenModal = React.useCallback((media) => {
    setVisibleDeleteModal(true);
    setChooseMedia(media);
  }, []);

  const handleClickSettings = React.useCallback(() => {
    setOpenExerciseSettings((value) => !value);
  }, [setOpenExerciseSettings]);

  const handleCloseModal = React.useCallback(() => {
    setVisibleDeleteModal(false);
    setChooseMedia(null);
  }, []);

  const handleRemoveMedia = React.useCallback(
    (image: WorkoutExerciseImage) => {
      const images = exercise.images.filter((it) => it !== image);
      setExercise({
        ...exercise,
        images,
      });
    },
    [exercise],
  );

  const handleRemoveDemoVideo = React.useCallback(() => {
    setExercise({
      ...exercise,
      video: null,
    });
  }, [exercise]);

  const confirmAction = React.useCallback(() => {
    if (!chooseMedia?.isOwnVideo) {
      handleRemoveMedia(chooseMedia?.media);
      setVisibleDeleteModal(false);
    } else {
      handleRemoveDemoVideo();
      setVisibleDeleteModal(false);
    }
  }, [
    chooseMedia,
    handleRemoveMedia,
    setVisibleDeleteModal,
    handleRemoveDemoVideo,
  ]);

  const handleToggleCoverMedia = React.useCallback(
    (image: WorkoutExerciseImage) => {
      const images = exercise.images.map((it) => ({
        ...it,
        cover: !it.cover && it === image,
      }));

      setExercise({
        ...exercise,
        images,
      });
    },
    [exercise],
  );
  const handleAutocompleteTitleChange = React.useCallback(
    (_, title: string, options: string[]) => {
      setExercise({
        ...exercise,
        title,
      });
      checkMatchedOption(title, options);
    },
    [exercise],
  );

  const handleTextFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { name, value } = event.currentTarget;

      setExercise({
        ...exercise,
        [name]: value,
      });
    },
    [exercise],
  );

  const handleSetFieldChange = React.useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      index: number,
    ) => {
      // eslint-disable-next-line prefer-const
      let { name, value } = event.currentTarget;

      const maxLength = getSetFieldMaxLength(name, typeReps, typeSet);
      if (value.replace(".", "").length <= maxLength || value === "") {
        const sets = [...exercise.sets];
        sets[index] = {
          ...sets[index],
          [name]: value,
        };
        setExercise({
          ...exercise,
          sets: [...sets],
        });
      }
    },
    [exercise],
  );

  const handleExerciseSettingsChange = React.useCallback(
    (field, value) => {
      if (field === "units") {
        setExercise({
          ...exercise,
          units: value,
        });
      } else if (field === "typeExtraMeasurement") {
        setExercise({
          ...exercise,
          [field]: value,
          sets: exercise.sets.map((item) => ({
            ...item,
            extraMeasurement: "",
          })),
        });
      } else if (field === "typeReps") {
        setExercise({
          ...exercise,
          [field]: value,
          sets: exercise.sets.map((item) => ({
            ...item,
            reps: "",
          })),
        });
      } else if (field === "typeSet") {
        setExercise({
          ...exercise,
          [field]: value,
          sets: exercise.sets.map((item) => ({
            ...item,
            weight: "",
          })),
          units: getDefaultUnits(value),
        });
      } else {
        setExercise({
          ...exercise,
          [field]: value,
        });
      }
    },
    [setExercise, exercise, user.units],
  );

  const handleSetsNumberPlus = React.useCallback(() => {
    if (exercise.sets.length < MAX_EXERCISE_SETS_COUNT) {
      setExercise({
        ...exercise,
        sets: [...exercise.sets, createEmptySet()],
      });
    }
  }, [exercise]);

  const handleSetsNumberMinus = React.useCallback(() => {
    if (exercise.sets.length > 1) {
      const sets = [...exercise.sets];
      sets.pop();
      setExercise({
        ...exercise,
        sets: [...sets],
      });
    }
  }, [exercise]);

  const handleDiscard = React.useCallback(() => {
    setExercise(defaultNormalizedExercise);
  }, []);

  const handleClose = React.useCallback(
    (event: any) => {
      if (onClose) {
        onClose(event);
      }
    },
    [onClose],
  );

  const handleSave = React.useCallback(
    (event) => {
      const defaultExerciseConfig = {
        typeSet: ExerciseTypeSet.WEIGHT,
        typeReps: ExerciseTypeReps.WHOLE,
        units: getDefaultUnits(exercise.typeSet),
      };
      saveExercise({
        id: exerciseAsset?.id,
        exercise: {
          ...defaultExerciseConfig,
          ...exercise,
        } as WorkoutExercise,
        bodypart: selectedBodypart,
        movementPattern: selectedMovementPatternType,
      });
      handleClose(event);
    },
    [exercise, onUpdate, saveExercise],
  );

  const handleUpload = React.useCallback(
    (image: WorkoutExerciseImage[] | WorkoutExerciseImage) => {
      if (Array.isArray(image)) {
        const images = [...exercise.images];
        image.map((item, index) =>
          images.push({
            ...item,
            cover: !exercise.images.length && !index,
          }),
        );
        setExercise({
          ...exercise,
          images: [...images],
        });
      } else {
        const images = [
          ...exercise.images,
          {
            ...image,
            cover: !exercise.images.length,
          },
        ];

        setExercise({
          ...exercise,
          images,
        });
      }
    },
    [exercise],
  );

  const handleUploadVideo = React.useCallback(
    (video: any) => {
      setExercise({
        ...exercise,
        video: video.url,
      });
    },
    [exercise],
  );

  const checkMatchedOption = (inputValue: string, options: string[]) => {
    const matchedOption = options.find((title) => title === inputValue);
    matchedOption ? setIsMatchedOption(true) : setIsMatchedOption(false);
  };
  const handleInitialExercise = React.useCallback((exercise: any) => {
    setIsMatchedOption(!!exercise);
  }, []);

  const inputProps = {
    id: "admin-workout-exercise-autocomplete",
    name: "title",
    placeholder: !isMatchedOption && "ex. Alternating bodyweight lunge",
    fullWidth: true,
  };
  const theme = useTheme();
  React.useEffect(() => {
    if (addMedia) {
      handleUpload(addMedia);
      setAddMedia(null);
    }
    if (addOwnVideo) {
      handleUploadVideo(addOwnVideo);
      setAddOwnVideo(null);
    }
  }, [addMedia, handleUpload, addOwnVideo, handleUploadVideo]);

  const handleCloseAutocomplete = React.useCallback(
    (options: string[]) => {
      checkMatchedOption(exercise.title, options);
    },
    [exercise.title],
  );
  return (
    <>
      <Box className={clsx(s.root, className)} {...other}>
        <Typography variant="h5">
          {readOnlyView
            ? "View private"
            : createNewAsset
              ? "Create library"
              : "Update library"}{" "}
          asset
        </Typography>
        <Typography variant="h6" className={s.label}>
          Exercise name
        </Typography>
        {exerciseAsset?.id ? (
          <>
            <TextField
              className={s.input}
              variant="outlined"
              fullWidth
              disabled
              value={exercise.title}
            />
          </>
        ) : (
          <AdminExerciseAutocomplete
            renderCustomInput={(
              params: AutocompleteRenderInputParams,
              isLoading,
            ) => (
              <TextField
                {...params}
                {...inputProps}
                variant="outlined"
                className={s.textField}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    className: s.input,
                    endAdornment: isLoading && (
                      <CircularProgress
                        size={18}
                        sx={{ color: (theme) => theme.palette.text.primary }}
                      />
                    ),
                  },
                }}
              />
            )}
            fullWidth
            inputValue={exercise.title}
            value={
              [exercise?.title as any].filter((e) => e.title !== "") as any
            }
            onInitialExercise={handleInitialExercise}
            onInputChange={handleAutocompleteTitleChange}
            onCloseAutocomplete={handleCloseAutocomplete}
            onFetchingExercise={handleExercisesFetching}
          />
        )}

        <CategorySelect
          label="Bodypart"
          value={selectedBodypart}
          onChange={(event) =>
            setSelectedBodypart(event.target.value as BodypartType)
          }
          options={Object.values(BodypartType)}
          labelMap={BodypartTypeLabels}
          disabled={readOnlyView}
        />
        <CategorySelect
          label="Movement pattern"
          value={selectedMovementPatternType}
          onChange={(event) =>
            setSelectedMovementPatternType(
              event.target.value as MovementPatternType,
            )
          }
          options={Object.values(MovementPatternType)}
          labelMap={MovementPatternTypeLabels}
          disabled={readOnlyView}
        />

        {((readOnlyView && exercise.video) || !readOnlyView) && (
          <>
            <Typography variant="h6" className={s.label}>
              Add your own video demo
            </Typography>

            <WorkoutDemoVideo
              onUpload={setAddOwnVideo}
              video={exercise.video}
              onRemoveVideo={handleRemoveDemoVideo}
              handleOpenModal={handleOpenModal}
              readonly={readOnlyView}
              onAddingVideoOrMedia={setIsAddingVideoOrMedia}
            />
          </>
        )}

        <Typography variant="h6" className={s.label}>
          Instructions
        </Typography>
        <TextField
          className={s.textField}
          name="instructions"
          value={exercise.instructions}
          onChange={handleTextFieldChange}
          placeholder={!readOnlyView && "ex. Make sure to brace your core"}
          variant="outlined"
          fullWidth
          rows={5}
          multiline
          disabled={readOnlyView}
          slotProps={{
            input: {
              className: s.input,
            },
          }}
        />
        <>
          <div className={s.setsTopContent}>
            <Box className={s.setsContainerInput}>
              <Typography variant="h6" className={clsx(s.label, s.setsLabel)}>
                Sets
              </Typography>
              <InputPlusMinus
                value={exercise.sets.length}
                onClickMinus={handleSetsNumberMinus}
                onClickPlus={handleSetsNumberPlus}
                disabledMin={exercise.sets.length <= 1 || readOnlyView}
                disabledMax={
                  exercise.sets.length >= MAX_EXERCISE_SETS_COUNT ||
                  readOnlyView
                }
              />
            </Box>
            {!readOnlyView && (
              <Box>
                <Button
                  className={s.settingsButton}
                  startIcon={<Settings fill={theme.palette.primary.main} />}
                  children="Settings"
                  onClick={handleClickSettings}
                />
              </Box>
            )}
          </div>
          <Collapse in={openExerciseSettings}>
            <WorkoutExerciseSettings
              extraMeasurement={
                exercise.typeExtraMeasurement || ExerciseTypeExtra.NONE
              }
              typeSet={exercise.typeSet || ExerciseTypeSet.WEIGHT}
              typeReps={exercise.typeReps || ExerciseTypeReps.WHOLE}
              units={exercise?.units || user.units}
              onChangeSettings={handleExerciseSettingsChange}
            />
          </Collapse>
          <CoachSets
            sets={exercise.sets}
            onChangeSet={handleSetFieldChange}
            units={exercise?.units || user.units}
            typeExtraMeasurement={
              exercise.typeExtraMeasurement || ExerciseTypeExtra.NONE
            }
            typeSet={exercise.typeSet || ExerciseTypeSet.WEIGHT}
            typeReps={exercise.typeReps || ExerciseTypeReps.WHOLE}
            disabled={readOnlyView}
          />
        </>

        {readOnlyView && !!exercise?.images?.length && (
          <Typography variant="h6" className={s.label}>
            Media
          </Typography>
        )}
        {!readOnlyView && (
          <Typography variant="h6" className={s.label}>
            Add media
          </Typography>
        )}

        <Box className={s.images}>
          {!!exercise?.images?.length &&
            exercise.images.map((image, index) => (
              <WorkoutMedia
                key={index}
                image={image}
                onRemove={handleRemoveMedia}
                onToggleCover={handleToggleCoverMedia}
                handleOpenModal={handleOpenModal}
                modal={readOnlyView}
              />
            ))}
          {!readOnlyView && (
            <WorkoutUploadMedia
              onUpload={setAddMedia}
              onAddingVideoOrMedia={setIsAddingVideoOrMedia}
            />
          )}
        </Box>
        {!readOnlyView && (
          <Box className={s.actions}>
            <Button
              size="small"
              variant="outlined"
              className={clsx(s.button, s.cancelButton)}
              onClick={handleDiscard}
              fullWidth
            >
              Discard
            </Button>
            <Button
              variant="contained"
              size="small"
              className={clsx(s.button, s.saveButton)}
              fullWidth
              onClick={handleSave}
              disabled={
                !exercise.title ||
                isAddingVideoOrMedia ||
                exercisesFetching ||
                isMatchedOption ||
                (exerciseAsset?.id && exerciseAsset?.id < 0)
              }
            >
              Save exercise
            </Button>
          </Box>
        )}
        <WorkoutActionsDelete
          title={"Delete item"}
          onCancel={handleCloseModal}
          onConfirm={confirmAction}
          open={visibleDeleteModal}
          image={chooseMedia?.media}
        />
      </Box>
    </>
  );
}
