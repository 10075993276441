import React, { ReactNode, useContext } from "react";
import {
  Avatar,
  Box,
  ButtonProps,
  Menu,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import {
  ProgramEnrollmentStartDateViewDialog,
  ProgramEnrollmentStartDateViewDialogProps,
} from "../../dialog/ProgramEnrollmentStartDateViewDialog";
import {
  ProgramSpecificStartDateViewDialog,
  ProgramSpecificStartDateViewDialogProps,
} from "../../dialog/ProgramSpecificStartDateViewDialog";
import dayjs from "dayjs";
import { ProgramContext } from "../../../contexts/ProgramContext";
import { Calendar, CalendarDays, UserRound } from "lucide-react";
import { ProgramStartDateView } from "../../../constants";
import FilterMenuItem, { FilterMenuTitle } from "./components/FilterMenuItem";
import MinimizedTooltip from "../../tooltip/MinimizedTooltip";

export interface ProgramStartDateViewDetails {
  view: ProgramStartDateView;
  date?: string;
  clientName?: string;
  clientEmail?: string;
  photoUrl?: string;
}

const programStartDateViewLabel: Record<
  ProgramStartDateView,
  { title: string; icon: ReactNode; label: string }
> = {
  [ProgramStartDateView.ANY]: {
    title: "Anytime",
    icon: <Calendar size={20} />,
    label: "View by any start date",
  },
  [ProgramStartDateView.CLIENT]: {
    title: "By client",
    icon: <UserRound size={20} />,
    label: "View by client start date",
  },
  [ProgramStartDateView.SPECIFIC]: {
    title: "By date",
    icon: <CalendarDays size={20} />,
    label: "View by specific date",
  },
};

export interface ProgramStartDateViewButtonProps
  extends Omit<ButtonProps, "value" | "onChange"> {
  value?: ProgramStartDateViewDetails;
  onChange?: (value: ProgramStartDateViewDetails) => void;
  slug?: string;
}

export function StartDateFilter(props: ProgramStartDateViewButtonProps) {
  const {
    value = { view: ProgramStartDateView.ANY },
    onChange = () => {},
    slug,
  } = props;
  const theme = useTheme();
  const IS_SM = useMediaQuery(theme.breakpoints.down("sm"));

  const menuState = usePopupState({
    variant: "popover",
    popupId: "program-details-view",
  });

  const [enrollmentDialogOpen, setEnrollmentDialogOpen] = React.useState(false);
  const [dateDialogOpen, setDateDialogOpen] = React.useState(false);
  const context = useContext(ProgramContext);
  const enrollmentsCount = context?.programQuery?.totalEnrollmentsCount;
  const handleItemClick = (view: ProgramStartDateView) => {
    if (view) {
      switch (view) {
        case ProgramStartDateView.CLIENT:
          setEnrollmentDialogOpen(true);
          break;
        case ProgramStartDateView.SPECIFIC:
          setDateDialogOpen(true);
          break;
        default:
          onChange({ view: ProgramStartDateView.ANY });
      }
    }

    menuState.close();
  };

  const handleSelectEnrollment: ProgramEnrollmentStartDateViewDialogProps["onSelect"] =
    React.useCallback(
      (date, clientName, clientEmail, photoUrl) => {
        onChange({
          view: ProgramStartDateView.CLIENT,
          date,
          clientName,
          clientEmail,
          photoUrl,
        });
      },
      [onChange],
    );

  const handleSelectDate: ProgramSpecificStartDateViewDialogProps["onSelect"] =
    React.useCallback(
      (date) => {
        onChange({
          view: ProgramStartDateView.SPECIFIC,
          date,
        });
      },
      [onChange],
    );

  const handleEnrollmentDialogClose = React.useCallback(() => {
    setEnrollmentDialogOpen(false);
  }, []);

  const handleDateDialogClose = React.useCallback(() => {
    setDateDialogOpen(false);
  }, []);

  const getIcon = () => {
    return value.photoUrl ? (
      <Avatar
        src={value.photoUrl}
        variant="square"
        sx={{ width: 28, height: 28, borderRadius: "6px" }}
      />
    ) : (
      programStartDateViewLabel[value.view].icon
    );
  };

  return (
    <>
      <MinimizedTooltip
        title="View program starting on a specific date"
        placement="top"
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
            fontSize: 14,
            cursor: "pointer",
            gap: 1,
            height: "fit-content",
          }}
          {...bindTrigger(menuState)}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box
              children={
                <Box display={"flex"} alignItems={"center"} gap={1} width={1}>
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      height: 1,
                      aspectRatio: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 2,
                    }}
                  >
                    {getIcon()}
                  </Box>
                  {!IS_SM && (
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography style={{ fontSize: 12 }}>
                        <b>Starting</b>
                      </Typography>

                      <Typography style={{ fontSize: 10, fontWeight: 500 }}>
                        {value.view !== ProgramStartDateView.ANY ? (
                          <>{dayjs(value.date).format("DD MMM,YYYY")}</>
                        ) : (
                          <>Anytime</>
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
              }
              sx={{
                display: "flex",
                flexDirection: "row",
                fontSize: 14,
                width: "auto",
                height: 32,
                gap: 1,
              }}
            />
          </Box>
        </Box>
      </MinimizedTooltip>

      <Menu
        {...bindMenu(menuState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FilterMenuTitle text="Start date" />
        {Object.entries(programStartDateViewLabel).map(([view, item]) => {
          if (view === ProgramStartDateView.CLIENT && !enrollmentsCount) {
            return false;
          }
          return (
            <FilterMenuItem
              item={value}
              label={item.label}
              checked={view === value.view}
              onClick={() => handleItemClick(view as ProgramStartDateView)}
              endIcon={programStartDateViewLabel[view].icon}
            />
          );
        })}
      </Menu>

      <ProgramEnrollmentStartDateViewDialog
        open={enrollmentDialogOpen}
        slug={slug}
        onSelect={handleSelectEnrollment}
        onClose={handleEnrollmentDialogClose}
      />
      <ProgramSpecificStartDateViewDialog
        open={dateDialogOpen}
        onSelect={handleSelectDate}
        onClose={handleDateDialogClose}
      />
    </>
  );
}
