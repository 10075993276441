import { ComponentType, ComponentRepeat } from "../../constants";
import type { ProgramWeekSchedule } from "../../hooks/useProgramSchedule";
import { TILL_THE_END_OF_THE_PROGRAM } from "../program-component/utils";

export const getDaysSpan = (days: boolean[], index: number): number => {
  if (days[index - 1]) {
    return 0;
  }

  let acc = 0;

  for (let i = index; i < days.length; i++) {
    if (days[i]) {
      acc += 1;
    } else {
      break;
    }
  }

  return acc;
};

export const setSpan = (
  days: boolean[],
  offset: number,
  span: number,
  value: boolean,
) => {
  for (let i = offset; i < offset + span && i < 7; i++) {
    days[i] = value;
  }
};

export const componentComparator = ({ type }: { type: ComponentType }) => {
  switch (type) {
    case ComponentType.HABIT:
      return 1;
    case ComponentType.CHECKIN:
      return 2;
    case ComponentType.WORKOUT:
      return 3;
    case ComponentType.LESSON:
      return 4;
    case ComponentType.MESSAGE:
      return 5;
    default:
      return 6;
  }
};

export const canScheduleComponentAtWeek = (
  startingWeek: number,
  week: number,
  type: ComponentType,
  repeat: ComponentRepeat,
  duration: number,
) => {
  const weekOffset = week - startingWeek;

  if (startingWeek >= week) {
    return startingWeek === week;
  } else {
    switch (type) {
      case ComponentType.HABIT:
      case ComponentType.WORKOUT:
      case ComponentType.CHECKIN:
      case ComponentType.MESSAGE:
      case ComponentType.LESSON:
        // Pattern:
        // By `Frequency` AND
        // By `Duration` or `Till the end of programs`
        switch (repeat) {
          case ComponentRepeat.WEEKLY:
            return (
              weekOffset < duration || duration === TILL_THE_END_OF_THE_PROGRAM
            );
          case ComponentRepeat.BIWEEKLY:
            return (
              weekOffset % 2 === 0 &&
              (weekOffset / 2 < duration ||
                duration === TILL_THE_END_OF_THE_PROGRAM)
            );
          case ComponentRepeat.EVERY_3_WEEKS:
            return (
              weekOffset % 3 === 0 &&
              (weekOffset / 3 < duration ||
                duration === TILL_THE_END_OF_THE_PROGRAM)
            );
          case ComponentRepeat.EVERY_4_WEEKS:
            return (
              weekOffset % 4 === 0 &&
              (weekOffset / 4 < duration ||
                duration === TILL_THE_END_OF_THE_PROGRAM)
            );
          default:
            return false;
        }
    }
  }
};

export function generateWeekDaysMap(
  days: boolean[],
  weeks: number[],
  overrides: {
    week: number;
    dayIndex: number;
  }[],
) {
  const weekMap = new Map<number, boolean[]>();

  weeks.forEach((week) => {
    const weekDays = days.slice(0, 7);

    overrides?.forEach((override) => {
      if (override.week === week) {
        weekDays[override.dayIndex] = false;
      }
    });

    weekMap.set(week, weekDays);
  });

  return weekMap;
}

type CalendarColumn = {
  span: number;
  component?: ProgramWeekSchedule[0]["component"];
  id?: number;
};

const canFitSlots = (slots: number[], columns: CalendarColumn[][]) =>
  slots.length === 1 ||
  slots.map((index) => columns[index].length).every((x, i, xs) => xs[0] === x);

export const calcScheduleGrid = (
  schedule: ProgramWeekSchedule,
  weekNumber: number,
) => {
  const columns: CalendarColumn[][] = [...Array(7)].map(() => []);

  const justify = () => {
    const size = Math.max(...columns.map((column) => column.length));

    for (const column of columns) {
      for (; column.length < size; ) {
        column.push({ span: 1 });
      }
    }
  };

  for (const { daysForWeeksMap, component } of schedule) {
    const days = daysForWeeksMap.get(weekNumber);
    const slots = days
      .map((scheduled, index) => (scheduled ? index : false))
      .filter((index) => index !== false) as number[];

    if (!canFitSlots(slots, columns)) {
      justify();
    }

    slots.forEach((i) =>
      columns[i].push({
        span: 1,
        component,
        id: component.id,
      }),
    );
  }

  justify();

  columns[0].forEach((_, rowIndex) => {
    for (let i = 6; i > 0; i--) {
      const right = columns[i][rowIndex];
      const left = columns[i - 1][rowIndex];

      if (left.component && right.component && left.id === right.id) {
        left.span += right.span;
        right.component = null;
        right.span = 0;
      }
    }
  });

  return columns;
};
