import { CustomCellRendererProps } from "@ag-grid-community/react";
import {
  Typography,
  TypographyProps,
  Box,
  SxProps,
  Stack,
} from "@mui/material";
import React from "react";
import { chunk as chunkArray, isEqual } from "lodash";
import { ColumnField, CustomProgramCellEditorProps } from "../../utils";
import {
  ExerciseTypeSet,
  getExerciseUnits,
  RowType,
} from "../../../../constants";
import { isPositiveNumber } from "../../../workout/utils";
import SetCellSelect from "./SetCellSelect";
import SetCellText from "./SetCellText";
import { rangeRegex } from "../../../../utils/regex";

function getFirstRepetitiveSetIndex(arr) {
  for (let i = 1; i < arr.length; i++) {
    const chunkedArray = chunkArray(arr, i);

    const leftSet = chunkedArray[0];
    for (let j = 1; j < chunkedArray.length; j++) {
      const rightSet = chunkedArray[j];

      if (!isEqual(leftSet.slice(0, rightSet.length), rightSet)) {
        break;
      }

      if (j === chunkedArray.length - 1) {
        return i;
      }
    }
  }

  return arr.length;
}

const VALUES_SEPARATOR = ", ";

const SetCellRenderer = (
  props: CustomCellRendererProps & CustomProgramCellEditorProps,
) => {
  const valuesArray = props.value;
  const key = props.colDef.field;

  if (props.data.rowType !== RowType.EXERCISE) {
    return <></>;
  }

  const isValueValid = (value: string) => {
    if (props.colDef.field === ColumnField.REPS) {
      const repsRangeRegex = /^\s*(\d{1,3})\s*-\s*(\d{1,3})\s*$/;
      return isPositiveNumber(value) || repsRangeRegex.test(value);
    }

    return isPositiveNumber(value);
  };

  const validValuesArray = valuesArray.map((v) => (isValueValid(v) ? v : 0));
  const firstRepetitiveSetIndex = getFirstRepetitiveSetIndex(validValuesArray);

  const sanitizedValuesArray = validValuesArray.map((v) => {
    if (v === 0) {
      return "-";
    }
    const match = v?.match(rangeRegex);
    if (match && match[1] === match[2]) {
      return match[1];
    }
    return v;
  });
  const mainString = sanitizedValuesArray
    .slice(0, firstRepetitiveSetIndex)
    .join(VALUES_SEPARATOR);
  const repetitiveValuesSuffix = sanitizedValuesArray
    .slice(firstRepetitiveSetIndex)
    .join(VALUES_SEPARATOR);

  if (
    !valuesArray ||
    !Array.isArray(valuesArray) ||
    valuesArray.filter((v) => isValueValid(v)).length === 0
  ) {
    const isHeaderSameType =
      props.colDef.headerName.toUpperCase().split(" ")[0] ===
      props.data.typeSet;

    const isHeaderSameUnit =
      props.colDef.headerName
        ?.toUpperCase()
        ?.split(" ")[1]
        ?.replace("(", "")
        ?.replace(")", "")
        ?.toLowerCase() ===
      getExerciseUnits(props.data.typeSet, props.data.units);

    return (
      <Stack direction={"row"}>
        <SetCellText secondary sx={{ width: "100%" }}>
          -
        </SetCellText>
        {key === ColumnField.WEIGHT && (
          <SetCellSelect
            inputKey={key}
            data={props.data}
            handleExerciseUpdate={props.handleExerciseUpdate}
          />
        )}

        {key === ColumnField.EXTRA_MEASUREMENT &&
          props.data.typeExtraMeasurement &&
          props.data.typeExtraMeasurement !== "None" && (
            <SetCellSelect
              inputKey={key}
              data={props.data}
              handleExerciseUpdate={props.handleExerciseUpdate}
            />
          )}
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: (theme) => theme.palette.divider,
        }}
      >
        <SetCellText>{mainString}</SetCellText>
        {repetitiveValuesSuffix && (
          <SetCellText secondary>
            {VALUES_SEPARATOR + repetitiveValuesSuffix}
          </SetCellText>
        )}
      </Box>
      {(key === ColumnField.WEIGHT ||
        key === ColumnField.EXTRA_MEASUREMENT) && (
        <SetCellSelect
          inputKey={key}
          data={props.data}
          handleExerciseUpdate={props.handleExerciseUpdate}
        />
      )}
    </Box>
  );
};

export default SetCellRenderer;
