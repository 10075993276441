import clsx from "clsx";
import React from "react";
import {
  ListItemButton,
  ListItemProps,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  useTheme,
  darken,
  Collapse,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { UsersIcon as GroupsIcon } from "lucide-react";
import { Link } from "../link/Link";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { LocalStorageKeys, UserRole } from "../../constants";

import { ChevronDownIcon as CaretDownIcon } from "lucide-react";

import { GroupNavItemList } from "./GroupNavItemList";
import { useLocation, useNavigate } from "react-router-dom";
import { COACH_GROUPS_ACTIVE_ROUTE } from "../../routes/routes";
import { useDrawer } from "../../hooks/useDrawer";
import { getLocalStorageMinimizedDrawerValue } from "../../contexts/MinimizedDrawerContext";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 50,
    position: "sticky",
    top: theme.spacing(-1),
    background: "white",
    zIndex: theme.zIndex.mobileStepper,
    "&:hover": {
      background: darken(theme.palette.common.white, 0.05),
    },
  },

  active: {
    "& $icon, & $text": {
      color: theme.palette.primary.main,
    },
  },

  icon: {
    color: theme.palette.text.secondary,
  },

  text: {
    color: theme.palette.text.secondary,
    fontSize: 19,
    display: "flex",
    alignItems: "center",
  },

  caret: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(2.75),
    height: theme.spacing(2.75),
    marginTop: theme.spacing(0.1),
    "$expanded &": {
      transform: "rotate(180deg)",
    },
  },

  expanded: {},
}));

export interface GroupNavItemProps extends ListItemProps {
  minimized?: boolean;
  setDrawerMinimized?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GroupNavItem(props: GroupNavItemProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { className, minimized, setDrawerMinimized, ...other } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const [, setDrawerOpen] = useDrawer();
  const location = useLocation();
  const active = location.pathname.includes("/groups");
  const [expanded, setExpanded] = React.useState(active);

  const totalCount = user?.groupsCount;

  const handleToggle = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setExpanded((value) => !value);
  }, []);

  const handleClick = React.useCallback(
    (event) => {
      event.preventDefault();

      if (user?.role === UserRole.COACH) {
        navigate(COACH_GROUPS_ACTIVE_ROUTE);
        setDrawerOpen(false);
      } else {
        handleToggle(event);
        setDrawerMinimized(false);
        localStorage.setItem(LocalStorageKeys.IS_DRAWER_MINIMIZED, "false");
      }
    },
    [handleToggle, user?.role],
  );

  React.useEffect(() => {
    if (active) {
      setExpanded(true);
    }
  }, [active]);

  React.useEffect(() => {
    minimized && setExpanded(false);
  }, [minimized]);

  React.useEffect(() => {
    !active && minimized && setExpanded(false);
    setDrawerMinimized &&
      setDrawerMinimized(getLocalStorageMinimizedDrawerValue());
  }, []);

  const open = !(minimized || !expanded);

  return (
    (user?.role === UserRole.COACH || totalCount > 0) && (
      <>
        <ListItemButton
          className={clsx(
            s.root,
            className,
            active && s.active,
            expanded && s.expanded,
          )}
          {...other}
          component={Link}
          underline="none"
          onClick={handleClick}
          sx={{
            ...(!minimized && {
              p: 0,
              paddingLeft: theme.spacing(4),
            }),
            transition: theme.transitions.create(["padding-left"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: minimized ? 30 : 40,
            }}
          >
            <GroupsIcon size={24} className={clsx(s.icon)} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            sx={{
              visibility: minimized ? "hidden" : "visible",
              opacity: minimized ? 0 : 1,

              transition: theme.transitions.create(["visibility", "opacity"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }}
          >
            <Typography variant="h5" className={s.text}>
              Groups
              {totalCount > 0 && (
                <CaretDownIcon className={s.caret} onClick={handleToggle} />
              )}
            </Typography>
          </ListItemText>
        </ListItemButton>
        <Collapse in={open} timeout="auto">
          <GroupNavItemList />
        </Collapse>
      </>
    )
  );
}
