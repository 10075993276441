import clsx from "clsx";
import React, { MouseEvent } from "react";
import {
  Typography,
  Button,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { ProgressDialogState, SelectedPhoto } from "./ProgressDialog";
import { useClient } from "../../hooks/useClient";

import CompareIcon from "@mui/icons-material/Compare";
import ImageIcon from "@mui/icons-material/Image";

import dayjs from "dayjs";
import {
  useCurrentBrand,
  useCurrentWorkspace,
} from "../../hooks/useCurrentWorkspace";
import {
  convertUrl,
  downloadCanvas,
  drawImageWithText,
  generateWatermakedCanvasImage,
  getImageBitmap,
  mergeImages,
} from "../../utils/canvas";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import watermark from "../app/logo-full-mono.png";
import { formatDateToLongString } from "../../utils/date";
import { isMobileApp } from "../../utils/mobile";
import { CircularLoader } from "../loading/CircularLoader";
import { useAnalytics } from "../../hooks/useAnalytics";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: theme.spacing(-2.5),
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
  },
  desktopVisible: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  iconButton: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "transparent",

    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),

    "& svg": {
      color: theme.palette.text.primary,
    },
  },
  active: {
    borderColor: theme.palette.primary.main,

    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  wrapper: {
    display: "flex",
    gap: 5,
    flexDirection: "row-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  selected: {},
  noWrap: { whiteSpace: "nowrap", height: 44 },
  button: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  canvas: {
    display: "none",
  },
  photoActions: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    alignItems: "end",
    gap: 5,
  },
  shareButton: {
    marginRight: theme.spacing(1),
    width: theme.spacing(24),
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },
  },
  loader: {
    color: "inherit",
  },
}));

export interface ProgressDialogActionsProps {
  onSelect: (event: MouseEvent<HTMLElement>, url: string) => void;
  onSwitchMode: any;
  state: ProgressDialogState;
  setState: (_: any) => void;
}

export function ProgressDialogActions(props: ProgressDialogActionsProps) {
  const { state, setState, onSelect, onSwitchMode } = props;

  const { comparison, selected, timeAgoFormat, showWeight, comparisonMode } =
    state;

  const s = useStyles();
  const clientDisplayName = useClient()?.displayName;
  const userDisplayName = useCurrentUser().displayName;
  const displayName = clientDisplayName || userDisplayName;
  const canvasRef = React.useRef();
  const { isBrandedWorkspace } = useCurrentBrand();
  const { logoUrl } = useCurrentWorkspace();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [trackEvent] = useAnalytics();

  const handleClick = React.useCallback(
    (event) => {
      onSelect(event, event.currentTarget.dataset.id);
    },
    [onSelect],
  );

  const currentWatermark = isBrandedWorkspace
    ? convertUrl(logoUrl) || watermark
    : watermark;
  const handleSaveComparison = React.useCallback(async () => {
    setIsProcessing(true);
    const img1 = await getImageBitmap(convertUrl(comparison[0].url));
    const img2 = await getImageBitmap(convertUrl(comparison[1].url));
    let date1: string;
    let date2: string;
    if (timeAgoFormat) {
      const timeDiff1 = dayjs.duration(
        dayjs(comparison[0].takenOn).diff(dayjs()),
      );
      const timeDiff2 = dayjs.duration(
        dayjs(comparison[1].takenOn).diff(dayjs()),
      );
      date1 = timeDiff1.humanize(true);
      date2 =
        Math.abs(timeDiff2.asDays()) < 7 ? "NOW" : timeDiff2.humanize(true);
    } else {
      date1 = dayjs(comparison[0].takenOn).format("MMM DD, YYYY");
      date2 = dayjs(comparison[1].takenOn).format("MMM DD, YYYY");
    }

    await generateWatermakedCanvasImage(
      mergeImages(
        img1,
        img2,
        showWeight && comparison[0].weight && comparison[0].weightUnit
          ? `${Math.round((+comparison[0].weight + Number.EPSILON) * 10) / 10} ${comparison[0].weightUnit?.toLowerCase()}\n${date1}`
          : date1,
        showWeight && comparison[1].weight && comparison[1].weightUnit
          ? `${Math.round((+comparison[1].weight + Number.EPSILON) * 10) / 10} ${comparison[1].weightUnit?.toLowerCase()}\n${date2}`
          : date2,
      ),
      canvasRef,
      currentWatermark,
    );
    trackEvent("Progress photo share");
    setIsProcessing(false);
    await downloadCanvas(
      canvasRef,
      `${displayName} Progress ${dayjs(state.comparison[0].takenOn).format("DD/MM/YYYY")} - ${dayjs(state.comparison[1].takenOn).format("DD/MM/YYYY")}`,
    );
  }, [generateWatermakedCanvasImage, comparison, showWeight, timeAgoFormat]);

  const handleSaveImage = React.useCallback(async () => {
    let date: string;
    if (timeAgoFormat) {
      date = dayjs
        .duration(dayjs(selected.takenOn).diff(dayjs()))
        .humanize(true);
    } else {
      date = formatDateToLongString(dayjs(selected.takenOn));
    }
    const img = await getImageBitmap(selected?.url);
    await generateWatermakedCanvasImage(
      drawImageWithText(
        img,
        showWeight && selected.weight && selected.weightUnit
          ? `${selected.weight} ${selected.weightUnit?.toLowerCase()}\n${date}`
          : date,
        1,
      ),
      canvasRef,
      currentWatermark,
    );
    trackEvent("Progress photo share");
    await downloadCanvas(
      canvasRef,
      `${displayName} Progress ${formatDateToLongString(dayjs(state.comparison[0].takenOn))} - ${formatDateToLongString(dayjs(state.comparison[1].takenOn))}`,
    );
  }, [generateWatermakedCanvasImage, selected, showWeight, timeAgoFormat]);

  return (
    <Box className={clsx(s.root)}>
      <div className={s.wrapper}>
        <Typography className={s.title} variant="h6">
          {displayName ? displayName + "’s" : "My"} progress
        </Typography>
      </div>

      <div className={s.wrapper}>
        <FormControlLabel
          className={s.desktopVisible}
          control={
            <Checkbox
              checked={showWeight}
              defaultChecked
              onClick={() =>
                setState((prev: ProgressDialogState) => ({
                  ...prev,
                  showWeight: !prev.showWeight,
                }))
              }
            />
          }
          label="Show body weight"
        />
        <FormControlLabel
          className={s.desktopVisible}
          control={
            <Checkbox
              checked={timeAgoFormat}
              onClick={() =>
                setState((prev: ProgressDialogState) => ({
                  ...prev,
                  timeAgoFormat: !prev.timeAgoFormat,
                }))
              }
            />
          }
          label="Time ago format"
        />
        <Box className={s.photoActions}>
          <Button
            className={clsx(s.shareButton, s.noWrap)}
            component="a"
            variant="contained"
            disabled={
              isProcessing ||
              !comparisonMode ||
              comparison?.some((photo) => photo === undefined)
            }
            onClick={handleSaveComparison}
            target="_blank"
          >
            {isProcessing ? (
              <CircularLoader className={s.loader} />
            ) : isMobileApp ? (
              "Share Comparison "
            ) : (
              "Save Comparison "
            )}
          </Button>
        </Box>
      </div>
      <canvas ref={canvasRef} className={s.canvas} />
    </Box>
  );
}
