import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, Input } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ComponentType } from "../../constants";
import { useSlateWithSchema } from "../editor/normalizers/withSchema";
import { getComponentTemplateTitle } from "../../utils/component_template";
import { ComponentIcon } from "../program-component/ComponentIcon";
import { ComponentEditor } from "../new-editor/ComponentEditor";
import { HabitPromptEdit } from "../habit-prompt/HabitPromptEdit";
import { HabitPrompt } from "../habit-prompt/HabitPrompt";
import { getContentType } from "../../utils/component";

import { ComponentTemplatePreviewEmpty } from "./ComponentTemplatePreviewEmpty";
import { useEditorRef } from "@udecode/plate-common";
import {
  CheckInTypes,
  menuItemDisabled,
} from "../new-editor/utils/menuItemUtil";
import { EditorElementView } from "../new-editor/utils/editorUtils";
import {
  CheckInComponentContext,
  EditorComponentItemDisabledContext,
} from "../new-editor/hooks";
import { ComponentTemplateDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },

  preview: {
    width: "100%",
    margin: theme.spacing(0, 2),
  },

  componentType: {
    color: theme.palette.quote,
    fontSize: 18,
    fontWeight: 500,
    textTransform: "capitalize",
  },

  title: {
    fontSize: 32,
    fontWeight: 700,
  },

  icon: {
    width: 28,
    height: 28,
    float: "left",
    marginRight: theme.spacing(1),
  },

  promptEdit: {
    margin: theme.spacing(4, 0),
  },
}));

export interface ComponentTemplateUpdate {
  title: string;
  content: string;
  habitPrompt?: string;
  image?: string;
}

export interface ComponentTemplatePreviewProps extends BoxProps {
  template: ComponentTemplateDto;
  readOnly?: boolean;
  onUpdate?: (update: ComponentTemplateUpdate) => void;
}

export function ComponentTemplatePreview(props: ComponentTemplatePreviewProps) {
  const { className, template, readOnly = true, onUpdate, ...other } = props;
  const s = useStyles();
  const initialContent = React.useMemo(
    () => (template ? JSON.parse(template.content) : null),
    [template],
  );
  const editor = useEditorRef();
  const [content, setContent] = React.useState(initialContent);
  const [title, setTitle] = React.useState(
    getComponentTemplateTitle(template.title),
  );
  const [habitPrompt, setHabitPrompt] = React.useState(template.habitPrompt);

  const handleTitleChange = React.useCallback((event) => {
    setTitle(event.target.value);
  }, []);

  React.useEffect(() => {
    if (onUpdate) {
      onUpdate({
        title,
        content: JSON.stringify(content),
        habitPrompt,
      });
    }
  }, [content, habitPrompt, onUpdate, title]);

  const icon = (
    <ComponentIcon
      className={s.icon}
      variant="icon"
      size="small"
      componentData={template}
    />
  );

  const isDisabledMenuItem = (type: string) => menuItemDisabled(type, content);

  return (
    // NOTE: These 2 contexts are also present in parent `CoachComponent`.
    // They were overridden here due to the different dependencies and editor content states
    <EditorComponentItemDisabledContext.Provider value={isDisabledMenuItem}>
      <CheckInComponentContext.Provider
        value={{
          view: readOnly ? EditorElementView.Preview : EditorElementView.Coach,
        }}
      >
        <Box className={clsx(s.root, className)} {...other}>
          {template ? (
            <Box className={s.preview}>
              <Typography className={s.componentType}>
                {getContentType(template.type as ComponentType)}
              </Typography>

              {readOnly ? (
                <Typography
                  className={s.title}
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {icon}
                  {getComponentTemplateTitle(template.title)}
                </Typography>
              ) : (
                <Input
                  disableUnderline
                  inputProps={{ className: s.title }}
                  fullWidth
                  startAdornment={icon}
                  value={title}
                  placeholder="Give Your Template a Title"
                  onChange={handleTitleChange}
                />
              )}

              {template.type === ComponentType.HABIT &&
                (readOnly ? (
                  <HabitPrompt prompt={habitPrompt} completed />
                ) : (
                  <HabitPromptEdit
                    className={s.promptEdit}
                    prompt={habitPrompt}
                    onChange={setHabitPrompt}
                  />
                ))}

              <ComponentEditor
                editor={editor}
                value={content}
                onChange={setContent}
                readOnly={readOnly}
                delay={0}
              />
            </Box>
          ) : (
            <ComponentTemplatePreviewEmpty />
          )}
        </Box>
      </CheckInComponentContext.Provider>
    </EditorComponentItemDisabledContext.Provider>
  );
}
