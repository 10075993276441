import clsx from "clsx";
import React from "react";
import { IconButton, IconButtonProps, Portal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/hooks";

import { ReactComponent as SidebarIcon } from "../../icons/AddSquare.svg";

import { ComponentLibraryDrawer } from "./ComponentLibraryDrawer";

const useStyles = makeStyles((theme) => ({
  root: {},
  active: {
    borderColor: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}4D`,

    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  out: {
    position: "absolute",
    zIndex: 99999,
  },
}));

export interface ComponentLibraryButtonProps extends IconButtonProps {}

export function ComponentLibraryButton(props: ComponentLibraryButtonProps) {
  const { className, children, ...other } = props;
  const s = useStyles();
  const toggleState = usePopupState({
    variant: "popover",
    popupId: "explore",
  });

  return (
    <>
      <IconButton
        className={clsx(s.root, className, toggleState.isOpen && s.active)}
        {...bindToggle(toggleState)}
        {...other}
        size="large"
      >
        <SidebarIcon />
        {children}
      </IconButton>

      {toggleState.isOpen && (
        <Portal>
          <div className={s.out}>
            <ComponentLibraryDrawer
              {...bindPopper(toggleState)}
              onClose={toggleState.close}
            />
          </div>
        </Portal>
      )}
    </>
  );
}
