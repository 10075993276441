import clsx from "clsx";
import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AppLogo } from "../app/AppLogo";
import { EnterInviteCode } from "./EnterInviteCode";
import { polyfillCSS } from "../../utils/css";
import { ReactComponent as ArrowBackIcon } from "../../icons/ArrowBack.svg";
import { LOGIN_ROUTE } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "#root": {
      padding: `0 !important`,
    },
  },
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },
  appLogo: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  backButton: {
    fontSize: 18,
    fontWeight: 600,
    padding: theme.spacing(1),
    margin: theme.spacing(-1, 0, 0.5, -1),
    "& svg": {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      marginRight: theme.spacing(0.5),
    },
    position: "absolute",
    top: polyfillCSS(`calc(${theme.spacing(4)} + var(--safe-area-inset-top))`),
    left: polyfillCSS(
      `calc(${theme.spacing(3)} + var(--safe-area-inset-left))`,
    ),
  },
  section: {
    width: "100%",
    maxWidth: 450,
    paddingLeft: 0,
    paddingRight: 0,
  },
  header: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    margin: theme.spacing(1.5, 0, 1),
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      margin: theme.spacing(3, 0, 1.5),
    },
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(2),
  },
}));

export function EnterInviteCodeScreen() {
  const s = useStyles();
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));

  const handleBackClick = () => {
    navigate(LOGIN_ROUTE);
  };

  return (
    <Box className={s.root}>
      <Box className={clsx(s.header, s.section)}>
        <Button className={s.backButton} onClick={handleBackClick}>
          <ArrowBackIcon />
          Back to login
        </Button>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <AppLogo
            className={s.appLogo}
            {...(!mdUp && { full: true, width: 160, height: 37 })}
          />
        </Box>
        <Typography className={s.title} variant="h1">
          Please, enter your invite code.
        </Typography>
        <Typography className={s.subtitle} variant="h2">
          Ask your coach for a code, then enter it below.
        </Typography>
        <EnterInviteCode />
      </Box>
    </Box>
  );
}
