import React, { Suspense } from "react";
import { Outlet, useParams } from "react-router-dom";
import { PageSkeleton } from "../../components/loading/PageSkeleton";
import AdminLayout from "../../components/app/AdminLayout";
import { TableSkeleton } from "../../components/loading/TableSkeleton";
import { ADMIN_PROGRAMS_ROUTE, ADMIN_USERS_ROUTE } from "../../routes/routes";

const breadcrumbsTable = {
  users: [
    { name: "Users", link: ADMIN_USERS_ROUTE },
  ],
  programs: [
    { name: "Programs", link: ADMIN_PROGRAMS_ROUTE },
  ],
}

const AdminWrapper = () => {
  const { userId, program } = useParams();

  let breadcrumbs;

  if (userId) {
    breadcrumbs = breadcrumbsTable.users;
  } else if (program) {
    breadcrumbs = breadcrumbsTable.programs;
  }

  return (
    <>
      <AdminLayout
        title={"Admin"}
        breadcrumbs={breadcrumbs}
      >
        <Suspense fallback={<TableSkeleton rowsPerPage={5} />}>
          <Outlet />
        </Suspense>
      </AdminLayout>
    </>
  );
};

export default AdminWrapper;
