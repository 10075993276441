import clsx from "clsx";
import React from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

import { useUploadFile } from "../../hooks/useUploadFile";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { AssetType } from "../../constants";
import { useNativeDropzone } from "../../utils/device";
import { Accept } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: theme.spacing(1),
    backgroundColor: `${theme.palette.quote}4F`,
    padding: theme.spacing(1.5),
  },

  label: {
    display: "block",
  },

  buttonText: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
  },

  buttonLink: {
    color: theme.palette.primary.main,
  },
}));

export type DropzoneUploadButtonClassKey =
  | "root"
  | "buttonText"
  | "buttonLink"
  | "text";

export interface DropzoneUploadButtonProps
  extends Omit<ButtonProps, "onChange" | "classes"> {
  onChange?: (value: any) => void;
  assetType: AssetType;
  mimeType?: Accept;
  inputName: string;
  assetDescription?: string;
  text?: React.ReactNode;
  textClassName?: string;
  classes?: Partial<ClassNameMap<DropzoneUploadButtonClassKey>>;
  upload?: boolean;
  onUploading?: (value: boolean) => void;
}

export function DropzoneUploadButton(props: DropzoneUploadButtonProps) {
  const {
    className,
    onChange,
    assetType,
    mimeType,
    inputName,
    assetDescription = "a file",
    text,
    textClassName,
    onUploading,
    classes,
    upload = true,
    ...other
  } = props;
  const s = useStyles();
  const user = useCurrentUser();
  const [uploadingError, setUploadingError] = React.useState<string>();

  const [uploadFile, _, isUploading] = useUploadFile({
    id: user.id,
    getAssetType: () => assetType,
    onUpload: ({ url }) => {
      if (onChange) {
        onChange(url);
      }
    },
  });

  React.useEffect(() => {
    if (onUploading) {
      onUploading(isUploading);
    }
  }, [isUploading]);

  const onUpload = React.useCallback(
    (file) => {
      setUploadingError("");
      const uploadedFile = file[0];
      const img = new Image();
      img.src = window.URL.createObjectURL(uploadedFile);
      img.onload = function () {
        const width = img.naturalWidth,
          height = img.naturalHeight;

        window.URL.revokeObjectURL(img.src);
        if (width >= 400 && height >= 400) {
          upload ? uploadFile(file[0]) : onChange(img);
        } else {
          setUploadingError("Image resolution should be 400x400 or bigger");
        }
      };
    },
    [onChange, uploadFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useNativeDropzone({
    onDrop: onUpload,
    accept: mimeType,
  });
  return (
    <>
      <Button
        {...(getRootProps() as any)}
        color="inherit"
        variant="outlined"
        className={clsx(s.root, className, classes?.root)}
        classes={{ label: s.label }}
        fullWidth
        {...other}
      >
        <input type="file" name={inputName} {...getInputProps()} />

        <Typography
          component="span"
          className={clsx(s.buttonText, classes?.buttonText)}
        >
          {isUploading ? (
            "Uploading..."
          ) : isDragActive ? (
            "Drop the file here..."
          ) : text ? (
            <Typography
              className={clsx(s.buttonText, textClassName, classes?.text)}
            >
              {text}
            </Typography>
          ) : (
            <>
              Drag {assetDescription} or{" "}
              <Typography
                component="span"
                className={clsx(
                  s.buttonText,
                  s.buttonLink,
                  classes?.buttonLink,
                )}
              >
                upload instead.
              </Typography>
            </>
          )}
        </Typography>
      </Button>
      <Typography color={"error"}>{uploadingError}</Typography>
    </>
  );
}
