import clsx from "clsx";
import React, { ReactNode, MouseEvent } from "react";
import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  Container,
  Typography,
  AppBar,
  Toolbar,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BackButton } from "../button/BackButton";
import { polyfillCSS } from "../../utils/css";

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    paddingTop: polyfillCSS("var(--safe-area-inset-top)"),
    paddingRight: polyfillCSS("var(--safe-area-inset-right)"),
    paddingLeft: polyfillCSS("var(--safe-area-inset-left)"),
  },
  paper: {
    paddingTop: theme.spacing(8),
  },
  paperCenter: {
    alignItems: "center",
    justifyContent: "center",
  },
  spacer: {
    flexGrow: 1,
  },
  iconButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    "&:last-child": {
      marginRight: theme.spacing(-1),
    },
  },
  center: {
    width: "100%",
    overflowY: "auto",
    maxHeight: "100%",
    paddingBottom: "10vh",
  },
  actions: {
    top: "auto",
    bottom: 0,
    paddingRight: "0 !important",
  },
  footerToolbar: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 2),
    },
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(-1),
    zIndex: 1,
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  },
}));

export interface FullScreenDialogProps
  extends Omit<DialogProps, "fullScreen" | "fullWidth"> {
  title?: string;
  align?: "top" | "center";
  actions?: ReactNode;
  topBarActions?: ReactNode;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  fullScreen?: boolean;
  backButtonTooltip?: ReactNode;
  hideModalToolbar?: boolean;
  hideAppToolbar?: boolean;
}

export function FullScreenDialog(props: FullScreenDialogProps) {
  const {
    className,
    classes = {},
    title,
    actions,
    topBarActions,
    fullScreen = true,
    hideAppToolbar = false,
    showBackButton = true,
    hideModalToolbar = false,
    showCloseButton,
    maxWidth = "sm",
    align = "center",
    onClose,
    children,
    backButtonTooltip,
    ...other
  } = props;
  const s = useStyles();

  function close(event: MouseEvent<HTMLButtonElement>): void {
    onClose(event, "escapeKeyDown");
  }

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{
        root: clsx(s.root, className),
        paper: clsx(s.paper, { [s.paperCenter]: align === "center" }),
      }}
      maxWidth={maxWidth}
      onClose={onClose}
      fullWidth={fullScreen}
      fullScreen={fullScreen}
      {...other}
    >
      {fullScreen && !hideAppToolbar && (
        <AppBar
          className={s.appBar}
          position="fixed"
          color="inherit"
          elevation={0}
        >
          <Toolbar>
            {showBackButton && (
              <BackButton
                className={s.iconButton}
                onClick={close}
                tooltip={backButtonTooltip}
              />
            )}
            <span className={s.spacer} />
            {topBarActions}
            {showCloseButton && (
              <Button
                className={s.iconButton}
                color="primary"
                onClick={close}
                endIcon={<Close />}
              >
                Close
              </Button>
            )}
          </Toolbar>
        </AppBar>
      )}
      {!fullScreen && (
        <Button
          className={clsx(s.iconButton, s.closeButton)}
          color="primary"
          onClick={close}
          children={<Close />}
        />
      )}
      <div className={clsx({ [s.center]: align === "center" })}>
        <Container
          maxWidth={maxWidth}
          className={clsx(classes.container, s.container)}
          sx={{ paddingX: 0 }}
        >
          {!fullScreen && !hideModalToolbar && <Toolbar />}
          {title && (
            <DialogTitle data-dialog-title="true">
              <Typography component="h2" variant="h5">
                {title}
              </Typography>
            </DialogTitle>
          )}
          {children}
        </Container>
      </div>
      {actions && (
        <AppBar
          className={s.actions}
          position="fixed"
          color="inherit"
          elevation={12}
          component="footer"
        >
          <Toolbar className={s.footerToolbar}>{actions}</Toolbar>
        </AppBar>
      )}
    </Dialog>
  );
}
