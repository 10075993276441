import React from "react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { UserRole } from "../../constants";
import TokenService from "../../utils/token";
import {
  ExchangeRefreshCommand,
  ISignInAsCommand,
  ISignInCommand,
  SignInAsCommand,
  SignInCommand,
  SignInWithAuthTokenCommand,
} from "@growth-machine-llc/stridist-api-client";
import { fbUsersClient } from "../../api/ApiClients";
import dayjs from "dayjs";

export interface DecodedJwt extends JwtPayload {
  user_id: string | null;
  user_email: string | null;
  user_role: UserRole | null;
  fb_user_id: string | null;
  root_user_id: string | null;
  root_user_email: string | null;
  root_user_role: UserRole | null;
  root_user_fb_user_id: string | null;
  is_admin: string | null;
}
export interface AuthContextInterface {
  userRole: UserRole | null;
  userId: string | null;
  admin: boolean | null;
  authorized: boolean;
  login: (command: ISignInCommand) => Promise<UserRole>;
  loginWithToken: (accessToken: string) => UserRole;
  exchangeOAuthToken: (token: string) => Promise<string>;
  loginAs: (command: ISignInAsCommand) => Promise<AuthContextInterface>;
  refreshToken: () => Promise<string | null>;
  logout: () => Promise<void>;
  setState: (token: string | null) => AuthContextInterface;
}

export interface AuthContextProviderProps {
  children: React.ReactNode;
}

const defaultContextState = {
  userRole: null,
  userId: null,
  admin: false,
} as AuthContextInterface;

const authContext =
  React.createContext<AuthContextInterface>(defaultContextState);

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const getJwtPayload = (token: string | null) => {
    if (!token) {
      return {
        userRole: null,
        userId: null,
        admin: false,
      };
    }

    const decodedJwt = jwtDecode<DecodedJwt>(token);
    return {
      userRole: decodedJwt?.["user_role"],
      userId: decodedJwt?.["user_id"],
      admin: decodedJwt?.["is_admin"] === "true",
    };
  };

  const localAccessToken = TokenService.getLocalAccessToken();
  const payload = getJwtPayload(localAccessToken);
  const [userRole, setUserRole] = React.useState(payload.userRole);
  const [userId, setUserId] = React.useState(payload.userId);
  const [admin, setAdmin] = React.useState(false);

  const setState = (token: string | null) => {
    const tokenPayload = getJwtPayload(token);
    setUserRole(tokenPayload.userRole);
    setUserId(tokenPayload.userId);
    setAdmin(tokenPayload.admin);

    return tokenPayload as AuthContextInterface;
  };

  const auth = {
    userRole,
    userId,
    admin,

    authorized: !!userRole,

    async login(command: ISignInCommand) {
      const { accessToken } = await fbUsersClient.signIn(
        new SignInCommand(command),
      );

      TokenService.updateLocalAccessToken(accessToken);

      const { userRole } = setState(accessToken);

      return userRole;
    },

    loginWithToken(accessToken: string) {
      TokenService.updateLocalAccessToken(accessToken);

      const { userRole } = setState(accessToken);

      return userRole;
    },

    async exchangeOAuthToken(token: string) {
      const { customToken } = await fbUsersClient.signInWithAuthToken(
        SignInWithAuthTokenCommand.fromJS({
          token,
          timezone: dayjs.tz.guess(),
        }),
      );

      return customToken;
    },

    async loginAs(command: ISignInAsCommand) {
      const { accessToken } = await fbUsersClient.signInAs(
        new SignInAsCommand(command),
      );

      TokenService.updateLocalAccessToken(accessToken);

      return setState(accessToken);
    },

    async refreshToken() {
      const { accessToken } = await fbUsersClient.refresh(
        new ExchangeRefreshCommand({
          expiredToken: TokenService.getLocalAccessToken(),
        }),
      );
      TokenService.updateLocalAccessToken(accessToken);

      const payload = setState(accessToken);
      return payload.userId;
    },

    async logout() {
      try {
        await fbUsersClient.signOut();
      } finally {
        TokenService.removeLocalAccessToken();
        setState(null);
      }
    },

    setState,
  };

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function useAuth() {
  return React.useContext(authContext);
}
