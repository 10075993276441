import clsx from "clsx";
import React from "react";
import { Card, CardProps, Typography, Box, Pagination } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ClientFormCardItem } from "./ClientFormCardItem";
import { useQuery } from "@tanstack/react-query";
import ClientFormsClientService from "../../services/ClientFormsClientService";
import { ClientFormCardItemSkeleton } from "./ClientFormCardItemSkeleton";
import { CardPagination } from "../pagination/CardPagination";
import { ReactComponent as FormsIcon } from "../../icons/profile-emojis/Forms.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top",
    marginBottom: theme.spacing(4),
  },

  headerIcon: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
  },

  noText: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(4, 0),
    textAlign: "center",
  },
}));

export interface ClientFormsCardProps extends CardProps {
  clientId?: number;
}

export const CLIENT_FORMS_CARD_QUERY_KEY = "client-forms-profile-card-query";
const PROFILE_FORMS_CARD_PAGE_SIZE = 4;

export function ClientFormsCard(props: ClientFormsCardProps) {
  const { className, clientId, ...other } = props;
  const s = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const { data: clientFormsData, isLoading: clientFormsQueryInFlight } =
    useQuery({
      queryKey: [CLIENT_FORMS_CARD_QUERY_KEY, clientId, currentPage],
      queryFn: () =>
        ClientFormsClientService.getFormsList(
          currentPage,
          PROFILE_FORMS_CARD_PAGE_SIZE,
          clientId,
        ),
    });

  const clientForms = clientFormsData?.items ?? [];

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Box className={s.headerIcon}>
          <FormsIcon />
          <Typography variant="h6" className={s.title} children="Forms" />
        </Box>
      </Box>

      {clientForms.length ? (
        clientForms.map((clientForm) => (
          <ClientFormCardItem key={clientForm.formId} clientForm={clientForm} />
        ))
      ) : clientFormsQueryInFlight ? (
        Array.from({ length: 3 }).map((_, i) => (
          <ClientFormCardItemSkeleton key={i} />
        ))
      ) : (
        <Typography className={s.noText}>No forms to show.</Typography>
      )}

      {clientFormsData?.totalPages > 1 && (
        <CardPagination
          page={currentPage}
          count={clientFormsData?.totalPages}
          onChange={(_, page) => setCurrentPage(page)}
        />
      )}
    </Card>
  );
}
