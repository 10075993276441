import React from "react";
import {
  ButtonProps,
  Box,
  useTheme,
  Menu,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ComponentType, componentTypes, RowType } from "../../../constants";
import { getContentType } from "../../../utils/component";
import {
  CalendarCheck,
  Dumbbell,
  GraduationCap,
  ListTodo,
  Mail,
} from "lucide-react";
import { getColorByComponentType } from "../../program-calendar/utils/common";
import FilterBadge from "./components/FilterBadge";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import FilterMenuItem, { FilterMenuTitle } from "./components/FilterMenuItem";
import MinimizedTooltip from "../../tooltip/MinimizedTooltip";

export const isComponentTypeFilterApplied = (filters: Filters) => {
  return Object.entries(filters)
    .filter(([key]) => key !== "archived")
    .some(([, value]) => value === false);
};

export const ComponentTypesAssets = {
  [ComponentType.WORKOUT]: {
    icon: <Dumbbell size={20} />,
    description: "Add a workout component",
  },
  [ComponentType.CHECKIN]: {
    icon: <ListTodo size={20} />,
    description: "Single or repeating survey",
  },
  [ComponentType.HABIT]: {
    icon: <CalendarCheck size={20} />,
    description: "Add a daily practice",
  },
  [ComponentType.LESSON]: {
    icon: <GraduationCap size={20} />,
    description: "Introduce a new concept",
  },
  [ComponentType.MESSAGE]: {
    icon: <Mail size={20} />,
    description: "Send an automated message",
  },
};

export type Filters = Record<ComponentType, boolean> & {
  archived: boolean;
  [RowType.WORKOUT_SECTION]: boolean;
  [RowType.EXERCISE]: boolean;
};

export const defaultFilters: Filters = {
  [ComponentType.LESSON]: true,
  [ComponentType.HABIT]: true,
  [ComponentType.CHECKIN]: true,
  [ComponentType.WORKOUT]: true,
  [RowType.WORKOUT_SECTION]: true,
  [RowType.EXERCISE]: true,
  [ComponentType.MESSAGE]: true,
  archived: false,
};

export const WEEK_DAYS = [1, 2, 3, 4, 5, 6, 7];

export interface ProgramDetailsFiltersProps
  extends Omit<ButtonProps, "onChange"> {
  filters?: Filters;
  onChange?: (filters: Filters) => void;
  disabledArchived?: boolean;
}

export function ComponentTypeFilter(props: ProgramDetailsFiltersProps) {
  const { onChange, filters = defaultFilters } = props;
  const theme = useTheme();
  const IS_MD = useMediaQuery(theme.breakpoints.down("md"));

  const menuState = usePopupState({
    variant: "popover",
    popupId: "type-filter",
  });

  const handleDirectClick = (
    event: React.MouseEvent<
      HTMLDivElement | HTMLLIElement | HTMLLabelElement,
      MouseEvent
    >,
    type: ComponentType,
  ) => {
    event.stopPropagation();
    if (onChange) {
      const newFilter = {
        ...filters,
        [type]: !filters[type],
      };
      onChange(newFilter);
    }
  };

  const IS_FILTER_APPLIED = isComponentTypeFilterApplied(filters);

  if (!IS_FILTER_APPLIED) return <></>;
  return (
    <>
      <MinimizedTooltip
        title="Filter visible components by type"
        placement="top"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
            fontSize: 14,
            gap: 1,
            ":hover": {
              cursor: "pointer",
            },
          }}
          {...bindTrigger(menuState)}
        >
          {!IS_MD && <Typography variant="subtitle1">Type</Typography>}
          <Box sx={{ display: "flex", gap: 1 }}>
            {componentTypes.map(
              (type) =>
                filters[type] && (
                  <FilterBadge
                    value={type}
                    component={ComponentTypesAssets[type].icon}
                    selected={filters[type]}
                    color={{
                      selected: getColorByComponentType(type, 1),
                      hovered: getColorByComponentType(type, 0.75),
                      unselected: getColorByComponentType(type, 0.5),
                    }}
                  />
                ),
            )}
          </Box>
        </Box>
      </MinimizedTooltip>

      <Menu
        {...bindMenu(menuState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FilterMenuTitle text="Type" />
        {componentTypes.map((type) => (
          <FilterMenuItem
            item={type}
            label={`${getContentType(type)}s`}
            checked={filters[type]}
            onClick={(e) => handleDirectClick(e, type)}
          />
        ))}
      </Menu>
    </>
  );
}
