import { styled } from "@mui/material/styles";
import Switch, { switchClasses, SwitchProps } from "@mui/material/Switch";

const pxToRem = (px: number, oneRemPx = 17) => `${px / oneRemPx}rem`;

interface IGenericScheduleSwitchPros extends SwitchProps {
  customColor?: string;
}

export const GenericScheduleSwitch = styled(Switch)<IGenericScheduleSwitchPros>(
  ({ theme, customColor }) => {
    const borderWidth = 2;
    const width = pxToRem(38);
    const height = pxToRem(24);
    const size = pxToRem(12);
    const gap = (24 - 12) / 2;
    return {
      width,
      height,
      padding: 0,
      margin: 0,
      overflow: "clip",
      [`& .${switchClasses.switchBase}`]: {
        padding: pxToRem(gap),
        [`&.${switchClasses.checked}`]: {
          color: "#fff",
          transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
          [`& + .${switchClasses.track}`]: {
            backgroundColor: customColor,
            opacity: 1,
            border: "none",
          },
          [`& .${switchClasses.thumb}`]: {
            backgroundColor: "#fff",
          },
        },
        [`&.${switchClasses.disabled}`]: {
          [`& .${switchClasses.thumb}`]: {
            backgroundColor: theme.palette.grey[200],
          },
        },
      },
      [`& .${switchClasses.thumb}`]: {
        boxShadow: "none",
        backgroundColor: theme.palette.grey[400],
        width: size,
        height: size,
      },
      [`& .${switchClasses.track}`]: {
        borderRadius: 40,
        border: `solid ${theme.palette.grey[400]}`,
        borderWidth,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
        boxSizing: "border-box",
      },
    };
  },
);
