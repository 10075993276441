import React from "react";
import { Box, BoxProps, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { colorSystem } from "../../theme";
import {
  BillingPlan,
  getSignupConfigByPlan,
  SignupStep,
} from "../../constants";
import clsx from "clsx";
import { useQueryParam } from "../../hooks/useQueryParam";

import { SignUpBenefits } from "./SignUpBenefits";
import SignUpStartForm from "./SignUpStartForm";
import useLocalScript from "../../hooks/useLocalScript";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CoachSignupSchema,
  coachSignupSchema,
} from "../../utils/coachSignupSchema";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { urlConstants } from "../../constants/urlConstants";
import { sendCoachSignupStartedEvent } from "../../utils/googleAnalytics";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: colorSystem.white2,
    minHeight: "100vh",
  },
  benefitsContainer: {
    flex: "0 0 40%",
    backgroundColor: theme.palette.common.white,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  formWrapper: {
    flex: "0 0 60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    minHeight: 500,
    padding: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      flex: "1 1 100%",
      padding: theme.spacing(2),
    },

    "&$fullWidth": {
      flex: "1 1 100%",
    },
  },
  formContainer: {
    width: "100%",
    maxWidth: 450,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      flexGrow: 0,
    },

    "&$mobile": {
      height: "100%",
      alignItems: "center",
    },
  },

  formLinks: {
    maxWidth: 450,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(1),

    padding: theme.spacing(1, 0, 6, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0, 0, 0),
      textAlign: "center",
      gap: theme.spacing(2),
    },

    // override link underline color
    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "underline",
      "text-decoration-color": theme.palette.text.secondary,
    },
  },

  formLinkText: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },

  mobile: {},
  fullWidth: {},
}));

export interface SignUpStartScreenProps extends BoxProps {}

export function SignUpStartScreen(props: SignUpStartScreenProps) {
  useLocalScript("meta-pixel-checkout.js");

  const { className, ...other } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();

  const {
    register,
    trigger,
    setError,
    setValue,
    control,
    getValues,
    clearErrors,
    reset,
    watch,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<CoachSignupSchema>({
    resolver: zodResolver(coachSignupSchema),
  });
  const [referral, setReferral] = React.useState("");
  const [step, setStep] = React.useState(SignupStep.EMAIL);

  const isMobile = useMediaMobile();
  const [plan] = useQueryParam("plan", BillingPlan.UP_TO_50);

  const showBenefits = getSignupConfigByPlan(plan, {}).benefits;

  React.useEffect(() => {
    sendCoachSignupStartedEvent();

    window.rewardful("ready", () => {
      if (window.Rewardful.referral) {
        setReferral(window.Rewardful.referral);
      }
    });
  }, []);

  React.useEffect(() => {
    const validPlan = [
      BillingPlan.UP_TO_50,
      BillingPlan.UP_TO_100,
      BillingPlan.UP_TO_200,
      BillingPlan.UP_TO_UNLIMITED,
      BillingPlan.PROMO,
      BillingPlan.THREE_X_SEVEN,
      BillingPlan.YEARLY_UP_TO_50,
      BillingPlan.YEARLY_UP_TO_100,
      BillingPlan.YEARLY_UP_TO_200,
      BillingPlan.YEARLY_UNLIMITED,
    ].includes(plan);
    if (!validPlan) window.location.href = "https://stridist.com/#pricing";
  }, [plan]);

  return (
    <Box className={s.root} {...other}>
      {!isMobile && showBenefits && (
        <Box className={s.benefitsContainer}>
          <SignUpBenefits plan={plan} />
        </Box>
      )}
      <Box className={clsx(s.formWrapper, !showBenefits && s.fullWidth)}>
        <Box className={clsx(s.formContainer, isMobile && {})}>
          <SignUpStartForm
            referral={referral}
            step={step}
            setStep={setStep}
            register={register}
            trigger={trigger}
            getValues={getValues}
            clearErrors={clearErrors}
            reset={reset}
            watch={watch}
            errors={errors}
            setError={setError}
            setValue={setValue}
            control={control}
            dirtyFields={Object.keys(dirtyFields)}
            plan={plan}
          />
        </Box>
        {step !== SignupStep.PROCESSING && (
          <Box className={s.formLinks}>
            <Typography className={s.formLinkText}>
              By signing up, you agree to our{" "}
              <Link
                sx={{ color: "inherit" }}
                href={urlConstants.terms}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </Link>{" "}
              and{" "}
              <Link
                sx={{ color: "inherit" }}
                href={urlConstants.privacy}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </Typography>
            <Typography className={s.formLinkText}>
              Already have an account? <Link href="/login">Log In</Link>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
