import { Card, CardProps, CardHeader, Grid2 } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  action: {
    alignSelf: "auto",
    marginTop: 0,
    width: "40%",
  },
  skeleton: {
    "margin-right": theme.spacing(6),
  },
}));

export interface SkeletonCardProps extends CardProps {
  // TODO:
  // variant?: 'client' | 'program'
}

export function SkeletonCard(props: SkeletonCardProps) {
  const s = useStyles();

  return (
    <Card {...props}>
      <CardHeader
        classes={{ action: s.action }}
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={60}
            height={60}
          />
        }
        title={
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <Skeleton animation="wave" />
          </Grid2>
        }
        subheader={
          <Grid2
            size={{
              xs: 6,
              md: 3,
            }}
          >
            <Skeleton animation="wave" />
          </Grid2>
        }
        action={
          <Grid2
            container
            direction="row"
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Grid2
              sx={{ display: { xs: "none", sm: "block" } }}
              size={{
                md: 5,
              }}
            >
              <Skeleton className={s.skeleton} animation="wave" />
            </Grid2>
            <Grid2
              size={{
                xs: 6,
                md: 6,
              }}
            >
              <Skeleton animation="wave" />
            </Grid2>
          </Grid2>
        }
      />
    </Card>
  );
}
