import React from "react";
import { AppLayout } from "../../../../components/app/AppLayout";
import { programsBreadcrumb } from "../utils/ProgramsNavigation";
import { RefreshSlug } from "../../../../components/routes/RefreshSlug";
import { ClientProgram } from "../../../../components/program/ClientProgram";
import { useParams, useSearchParams } from "react-router-dom";
import { DefaultLoader } from "../../../../components/loading/DefaultLoader";

import { useQuery } from "@tanstack/react-query";
import EnrollmentsClientService from "../../../../services/EnrollmentsClientService";
import { useCurrentUserId } from "../../../../hooks/useCurrentUser";
import { Box } from "@mui/material";

export const CLIENT_ENROLLMENT_PROGRAM_QUERY_KEY = "client-enrollment-program";

export function ClientProgramsProgramRoute() {
  const params = useParams();
  const slug = params.program;
  const userId = useCurrentUserId();
  const { data: enrollmentProgram, isLoading } = useQuery({
    queryKey: [CLIENT_ENROLLMENT_PROGRAM_QUERY_KEY, { slug }],
    queryFn: () =>
      EnrollmentsClientService.getClientEnrollmentProgram(slug, userId),
  });

  return isLoading ? (
    <DefaultLoader fillScreen />
  ) : (
    <AppLayout
      appDrawer={false}
      title={enrollmentProgram?.name || "Program"}
      subtitle={enrollmentProgram?.description}
      trackInfo={{
        name: "Client - Program",
      }}
      breadcrumbs={[programsBreadcrumb]}
      cover={enrollmentProgram?.image}
    >
      <RefreshSlug
        slugInfo={{
          slug: enrollmentProgram?.slug,
          slugId: enrollmentProgram?.slugId,
        }}
      />
      <Box sx={{ containerType: "inline-size" }}>
        <ClientProgram slug={slug} program={enrollmentProgram} />
      </Box>
    </AppLayout>
  );
}
