import clsx from "clsx";
import React, { useEffect } from "react";
import {
  Box,
  BoxProps,
  Typography,
  MenuItem,
  ListItemText,
  Button,
  ListItemIcon,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindToggle,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { Menu } from "../menu/Menu";
import { colorSystem } from "../../theme";

import { ReactComponent as CloseMenu } from "../../icons/CloseWhite.svg";
import { ReactComponent as DropZone } from "../../icons/OvalCloudUpload.svg";
import { ReactComponent as Dots } from "../../icons/horizontalDotsWhite.svg";
import { ReactComponent as Video } from "../../icons/Video.svg";

import { ElementType } from "../editor/types/elements";
import { useEditorProgram } from "../new-editor/hooks";
import { WorkoutExerciseDemoVideo } from "../../components/workout/types";
import { EmbedUrlDialog } from "../../components/dialog/EmbedUrlDialog";
import { getMimeTypes, humanReadableFileSize } from "../../utils/file";
import { useNativeDropzone } from "../../utils/device";
import {
  AssetType,
  workoutMaxUploadFileSize,
  SOMETHING_WENT_WRONG,
} from "../../constants";
import { useTranscodeVideo } from "../../hooks/useTranscodeVideo";
import { WorkoutDemoVideoPreview } from "./WorkoutDemoVideoPreview";
import { ReactComponent as UploadIcon } from "../../icons/NavigationUpCircle.svg";
import { ReactComponent as EmbedIcon } from "../../icons/PlaylistUpload.svg";
import { useAddExercise } from "../../hooks/useAddExercise";
import { useUploadFile } from "../../hooks/useUploadFile";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: theme.palette.text.secondary,
    borderWidth: 1,
    borderStyle: "solid",
    backgroundColor: colorSystem.white2,
    borderColor: colorSystem.secondaryGray,
    padding: theme.spacing(4, 1, 4, 3),
  },

  text: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 500,
    lineHeight: "16px",
    fontSize: 14,
    color: colorSystem.gray,
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },

  menuButton: {
    width: 32,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colorSystem.secondary,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.white2,
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: "99999 !important" as any,
  },

  button: {
    borderColor: colorSystem.black,
    color: colorSystem.black,
    fontWeight: 700,
    padding: theme.spacing(0.8, 5),
  },

  menu: {
    zIndex: "99999 !important" as any,
  },

  videoContainer: {
    position: "relative",
  },

  dropzoneVideo: {},

  videoIcon: {
    margin: theme.spacing(2.5, 0),
  },
}));

export interface WorkoutDemoVideoProps extends BoxProps {
  onUpload?: (media?: WorkoutExerciseDemoVideo) => void;
  onRemoveVideo?: () => void;
  video?: any;
  handleOpenModal?: (data) => void;
  readonly?: boolean;
  onAddingVideoOrMedia?: (value: boolean) => void;
}

export function WorkoutDemoVideo(props: WorkoutDemoVideoProps) {
  const {
    className,
    onUpload,
    video,
    onRemoveVideo,
    handleOpenModal,
    onAddingVideoOrMedia,
    readonly = false,
    ...other
  } = props;
  const s = useStyles();
  const addExercise = useAddExercise();

  const editMenuState = usePopupState({
    variant: "popover",
    popupId: "workout-add-media",
  });
  const uploadMenuState = usePopupState({
    variant: "popover",
    popupId: "workout-add-media",
  });

  const [embed, setEmbed] = React.useState(false);
  const { programId } = useEditorProgram();
  const [transcodeVideo, isTranscoding] = useTranscodeVideo();
  const { showToastAlert } = useToastAlert();

  const [uploadFile, _, isUploading] = useUploadFile({
    id: programId,
    refType: "Program",
    getAssetType: () => AssetType.WORKOUT_MEDIA,
  });

  useEffect(() => {
    if (onAddingVideoOrMedia) {
      onAddingVideoOrMedia(isUploading || isTranscoding);
    }
  }, [isUploading, isTranscoding]);

  const handleUploadClick = React.useCallback(() => {
    editMenuState.close();
  }, [editMenuState]);

  const handleCloseEmbed = React.useCallback(() => {
    setEmbed(false);
  }, []);

  const handleEmbedClick = React.useCallback(() => {
    uploadMenuState.close();
    setEmbed(true);
  }, [uploadMenuState]);

  const handleUpload = React.useCallback(
    (media: WorkoutExerciseDemoVideo) => {
      if (onUpload) {
        onUpload(media);
      }
    },
    [onUpload],
  );

  const handleEmbedUrl = React.useCallback(
    (url: string) => {
      handleUpload({
        name: url,
        url,
      });
    },
    [handleUpload],
  );

  const { getRootProps, getInputProps } = useNativeDropzone({
    onDrop: (files, errors) => {
      editMenuState.close();
      uploadMenuState.close();

      if (errors.length) {
        showToastAlert("error", {
          message: SOMETHING_WENT_WRONG,
        });
        return false;
      } else if (!files[0]) {
        return false;
      }

      files.forEach((file, index) => {
        const maxFileSize = workoutMaxUploadFileSize;
        const isVideo = /^video\//.test(file.type);

        if (file.size > maxFileSize) {
          showToastAlert("error", {
            message: `File size is too big. Maximum file size is ${humanReadableFileSize(
              workoutMaxUploadFileSize,
            )}`,
          });
          return false;
        }

        uploadFile(file)
          .then(async (uploadedFile) => {
            return isVideo
              ? { ...uploadedFile, url: await transcodeVideo(uploadedFile.url) }
              : uploadedFile;
          })
          .then(({ url, file }) => {
            handleUpload({
              url,
              name: file.name,
            });
          });
      });
    },
    multiple: false,
    accept: getMimeTypes(ElementType.VIDEO),
  });

  return (
    <>
      {video && !(isUploading || isTranscoding) ? (
        <div
          className={s.videoContainer}
          {...(getRootProps({
            onClick: (event) => event.stopPropagation(),
          }) as any)}
        >
          <WorkoutDemoVideoPreview video={video} />
          {!readonly && (
            <div className={s.menuButton} {...bindToggle(editMenuState)}>
              {editMenuState.isOpen ? <CloseMenu /> : <Dots />}
            </div>
          )}
        </div>
      ) : (
        <div
          {...(getRootProps({
            className: s.dropzoneVideo,
            onClick: (event) => event.stopPropagation(),
          }) as any)}
        >
          <Box className={clsx(s.root, className)} {...other}>
            {addExercise ? (
              <Video className={s.videoIcon} width={70} height={70} />
            ) : (
              <>
                <DropZone />
                <Typography className={s.text}>
                  {isUploading
                    ? "Uploading..."
                    : isTranscoding
                      ? "Processing..."
                      : "Drag and drop here"}
                  <br />
                  {!isUploading && !isTranscoding && "or"}
                </Typography>
                <Button
                  variant="outlined"
                  className={s.button}
                  disabled={isUploading || isTranscoding}
                  {...bindToggle(uploadMenuState)}
                >
                  Upload
                </Button>
              </>
            )}
          </Box>
        </div>
      )}
      {!readonly && (
        <>
          <Menu
            className={s.menu}
            {...bindMenu(editMenuState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              disabled={addExercise}
              onClick={handleUploadClick}
              {...(getRootProps() as any)}
            >
              <ListItemText>Add your own video demo</ListItemText>
            </MenuItem>
            <MenuItem disabled={addExercise} onClick={handleEmbedClick}>
              <ListItemText>Embed video link</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenModal({
                  media: { type: "video", url: video, cover: false },
                  isOwnVideo: true,
                });
                editMenuState.close();
              }}
              disabled={addExercise}
            >
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
          <input type="file" {...getInputProps()} />

          <Menu
            className={s.menu}
            {...bindMenu(uploadMenuState)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={handleUploadClick} {...(getRootProps() as any)}>
              <ListItemIcon>
                <UploadIcon />
              </ListItemIcon>
              <ListItemText>Upload file</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleEmbedClick}>
              <ListItemIcon>
                <EmbedIcon />
              </ListItemIcon>
              <ListItemText>Embed video link</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
      {embed && (
        <EmbedUrlDialog
          onUpdate={handleEmbedUrl}
          open
          onClose={handleCloseEmbed}
        />
      )}
    </>
  );
}
