import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  TextField,
  Typography,
  InputBaseComponentProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as WarningIcon } from "../../icons/Warning.svg";
import { RATIONAL_NUMBER_REGEX } from "../../constants";

import { ClientMealLoggingQuestionProps } from "./types";
import { MealLoggingQuestionNumber } from "../meal-logging-settings/types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
  },

  field: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  error: {
    fontSize: 13,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),

    "& svg": {
      marginRight: theme.spacing(0.5),
    },
  },
}));

export interface ClientMealLoggingNumberQuestionProps
  extends BoxProps,
    ClientMealLoggingQuestionProps<MealLoggingQuestionNumber> {}

export function ClientMealLoggingNumberQuestion(
  props: ClientMealLoggingNumberQuestionProps,
) {
  const s = useStyles();
  const { className, option, onUpdate, ...other } = props;
  const [value, setValue] = React.useState(option.answer || "");
  const [error, setError] = React.useState(false);

  const handleChange = React.useCallback(
    ({
      currentTarget: { value: inputValue },
    }: React.ChangeEvent<HTMLTextAreaElement>) => {
      const valid = RATIONAL_NUMBER_REGEX.test(inputValue);

      setError(!valid);
      setValue(inputValue);

      if (valid && onUpdate) {
        onUpdate({ ...option, answer: inputValue });
      }
    },
    [onUpdate, option],
  );

  const handleKeyDown: InputBaseComponentProps["onKeyDown"] = React.useCallback(
    (event) => {
      const valid = /[0-9.,-]/.test(event.key);
      const isSpecialKey = event.key.length > 1;

      if (!valid && !isSpecialKey) {
        event.preventDefault();
      }
    },
    [],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <TextField
        className={s.field}
        variant="outlined"
        fullWidth
        placeholder="Enter answer"
        value={value}
        onChange={handleChange}
        helperText={
          error ? (
            <Typography component="span" className={s.error} color="error">
              <WarningIcon />
              Numbers only please
            </Typography>
          ) : undefined
        }
        error={error}
        slotProps={{
          htmlInput: {
            onKeyDown: handleKeyDown,
          },
        }}
      />
    </Box>
  );
}
