import {
  IBriefClientInfo,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";

import ClientsService from "../../../services/ClientsService";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { COACH_CLIENT_GENERIC_QUERY_KEY } from "../../../wrappers/router/coach/CoachClientProfileWrapper";
import { useInvalidateCoachProgramAndGroupQueries } from "../../../hooks/useInvalidateCoachProgramAndGroupQueries";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";

const useArchiveSingleClientMutation = (username: string) => {
  const queryClient = useQueryClient();

  const invalidateCoachProgramAndGroupQueries =
    useInvalidateCoachProgramAndGroupQueries();
  return useMutation({
    mutationFn: ClientsService.archiveClient,
    onSuccess: (_, { archived }) => {
      queryClient.setQueryData<IBriefClientInfo>(
        [COACH_CLIENT_GENERIC_QUERY_KEY, { username }],
        (prev) => ({
          ...prev,
          status: archived
            ? UserInviteStatus.ARCHIVED
            : UserInviteStatus.ACTIVE,
          archived: archived,
        }),
      );
      queryClient.resetQueries({
        queryKey: [COACH_CLIENTS_LIST_QUERY_KEY],
        exact: false,
      });

      queryClient.invalidateQueries({
        queryKey: [CURRENT_USER_QUERY_KEY],
        refetchType: "all",
      });

      invalidateCoachProgramAndGroupQueries();
    },
  });
};
export default useArchiveSingleClientMutation;
