import clsx from "clsx";
import React, { useTransition } from "react";
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { Close as CloseIcon } from "@mui/icons-material";

import { getComponentTemplateTitle } from "../../utils/component_template";
import { SOMETHING_WENT_WRONG, ComponentType } from "../../constants";

import { ComponentTemplatePreview } from "./ComponentTemplatePreview";
import { ComponentTemplateLibrary } from "./ComponentTemplateLibrary";
import { ComponentTemplatePreviewEmpty } from "./ComponentTemplatePreviewEmpty";
import { ComponentTemplateUpdate } from "./ComponentTemplatePreview";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ComponentsService from "../../services/ComponentsService";
import {
  ComponentTemplateBriefDto,
  ComponentTemplateDto,
  PaginatedListOfComponentTemplateBriefDto,
} from "@growth-machine-llc/stridist-api-client";
import { DefaultLoader } from "../loading/DefaultLoader";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    containerType: "inline-size",
  },

  paper: {
    paddingTop: theme.spacing(10),
    [theme.containerQueries.up("md")]: {
      paddingTop: "initial",
      minWidth: "80vw",
      height: "80vh",
      margin: theme.spacing(15),
    },

    borderRadius: theme.spacing(0.5),
  },

  content: {
    padding: "0 !important",

    [theme.containerQueries.up("md")]: {
      height: "100%",
      display: "flex",
      flexWrap: "nowrap",
    },
  },

  library: {
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.1)",
    flex: "0 1 350px",
    overflowX: "auto",
  },

  preview: {
    flex: "1 1 auto",
    marginTop: theme.spacing(11.5),
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.containerQueries.up("md")]: {
      overflowY: "auto",
    },
  },

  actions: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",

    [theme.containerQueries.up("md")]: {
      padding: 0,
      top: theme.spacing(3),
      right: theme.spacing(3),
      boxShadow: "initial",
      left: "initial",
    },
  },

  actionButton: {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 700,
    padding: theme.spacing(1, 4.5),
  },

  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  closeButton: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const formatTemplate = ({
  title,
  habitPrompt,
  content,
  image,
}: ComponentTemplateDto): ComponentTemplate => ({
  title: getComponentTemplateTitle(title),
  habitPrompt,
  content,
  image,
});

export interface ComponentTemplate {
  title: string;
  habitPrompt?: string;
  content: string;
  image?: string;
}

export interface ComponentTemplateDialogProps
  extends Omit<DialogProps, "onSelect"> {
  onSelectTemplate?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ComponentTemplate,
  ) => void;
  componentType?: ComponentType;
}

export const COMPONENT_TEMPLATES_LIST_QUERY_KEY = "component-list-templates";
export const COMPONENT_TEMPLATE_QUERY_KEY = "component-template";

export function ComponentTemplateDialog(props: ComponentTemplateDialogProps) {
  const { className, onClose, onSelectTemplate, componentType, ...other } =
    props;

  const { data: componentTemplatesData, isLoading: isLoadingTemplates } =
    useQuery({
      queryKey: [COMPONENT_TEMPLATES_LIST_QUERY_KEY, { componentType }],
      queryFn: () => ComponentsService.getComponentTemplates(componentType),
    });

  const templates = componentTemplatesData?.items.map((item) => item);

  const s = useStyles();
  const { containerQueries } = useTheme();
  const mdUp = useMediaQuery(containerQueries.up("md"));

  const [templateId, setTemplateId] = React.useState<string>();
  const [readOnly, setReadOnly] = React.useState(true);
  const [templateUpdate, setTemplateUpdate] =
    React.useState<ComponentTemplateUpdate>(null);
  const queryClient = useQueryClient();

  const { mutate: updateTemplate, isPending: updatingTemplate } =
    useOptimisticUpdateMutation({
      successToastMessage: "Template updated.",
      queryKey: [COMPONENT_TEMPLATE_QUERY_KEY, { templateId }],
      mutationFn: ComponentsService.updateComponentTemplate,
      optimisticUpdater: {
        updateFn: (oldData: ComponentTemplateDto, newData, _) => {
          const updatedData = ComponentTemplateDto.fromJS({
            ...oldData,
            ...newData,
          });
          return updatedData;
        },
      },
      options: {
        onSuccess: (_, variables) => {
          queryClient.setQueryData(
            [COMPONENT_TEMPLATES_LIST_QUERY_KEY, { componentType }],
            (oldData: PaginatedListOfComponentTemplateBriefDto) => ({
              ...oldData,
              items: oldData.items.map((item) =>
                item.id === templateId
                  ? ComponentTemplateBriefDto.fromJS({
                      ...item,
                      title: variables.title,
                    })
                  : item,
              ),
            }),
          );
        },
      },
    });

  const { data: template, isLoading: isLoadingTemplate } = useQuery({
    queryKey: [COMPONENT_TEMPLATE_QUERY_KEY, { templateId }],
    queryFn: () => ComponentsService.getComponentTemplate(templateId),
    enabled: !!templateId,
  });

  const handleTemplateSelect = React.useCallback(
    (event, templateId: string) => {
      setTemplateId(templateId);
      setReadOnly(true);
      setTemplateUpdate(null);
    },
    [],
  );

  const handleClose = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "escapeKeyDown");
      }
    },
    [onClose],
  );

  const handleSubmit = React.useCallback(
    (event) => {
      if (onClose) {
        onClose(event, "escapeKeyDown");
      }

      if (onSelectTemplate) {
        onSelectTemplate(event, formatTemplate(template));
      }
    },
    [onClose, onSelectTemplate, template],
  );

  const handleEditClick = React.useCallback(() => {
    if (!readOnly && templateUpdate) {
      const input = {
        ...templateUpdate,
        habitPrompt:
          templateUpdate.habitPrompt?.trim() === ""
            ? null
            : templateUpdate.habitPrompt,
        image:
          templateUpdate.image?.trim() === "" ? null : templateUpdate.image,
        id: templateId,
      };
      updateTemplate(input, {
        onSuccess: () => {
          setReadOnly(true);
        },
      });
    } else {
      setReadOnly(!readOnly);
    }
  }, [readOnly, templateId, templateUpdate, updateTemplate]);

  const disabled = updatingTemplate;

  return (
    <Dialog
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      fullScreen={!mdUp}
      onClose={onClose}
      {...other}
    >
      <DialogContent className={s.content}>
        <ComponentTemplateLibrary
          className={s.library}
          templates={templates}
          onSelect={handleTemplateSelect}
          isLoadingTemplatesList={isLoadingTemplates}
        />
        {template && !isLoadingTemplate ? (
          <ComponentTemplatePreview
            key={template.id}
            className={s.preview}
            template={template}
            onUpdate={setTemplateUpdate}
            readOnly={readOnly}
          />
        ) : isLoadingTemplate ? (
          <Box className={clsx(s.loading, s.preview)}>
            <DefaultLoader />
          </Box>
        ) : (
          <ComponentTemplatePreviewEmpty />
        )}
      </DialogContent>

      <DialogActions className={s.actions}>
        {template?.private && (
          <Button
            className={clsx(s.actionButton)}
            onClick={handleEditClick}
            fullWidth
            variant="outlined"
            color="secondary"
            disabled={disabled}
          >
            {readOnly ? "Edit" : "Done"}
          </Button>
        )}
        {template && (
          <Button
            className={s.actionButton}
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            Use as template
          </Button>
        )}

        <IconButton
          className={clsx(s.closeButton)}
          onClick={handleClose}
          children={<CloseIcon />}
          size="large"
        />
      </DialogActions>
    </Dialog>
  );
}
