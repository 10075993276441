import React from "react";
import clsx from "clsx";
import {
  useCurrentBrand,
  useCurrentWorkspace,
} from "../../hooks/useCurrentWorkspace";
import {
  convertUrl,
  drawExerciseList,
  drawImageWithStats,
  drawStatsGrid,
  generateWatermakedCanvasImage,
  WorkoutStat,
} from "../../utils/canvas";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CircularLoader } from "../loading/CircularLoader";
import watermark from "../app/logo-full-mono.png";

const useStyles = makeStyles(({ shape, spacing, breakpoints }) => ({
  invisible: {
    display: "none",
  },

  picture: {
    width: "90%",
    justifySelf: "center",
    alignSelf: "center",
    aspectRatio: "1/1",
    boxShadow: "5px 5px 10px 2px rgba(0,0,0,0.2)",
    margin: spacing(2),
    borderRadius: shape.borderRadius * 2,
    overflow: "hidden",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [breakpoints.up("md")]: {
      width: 500,
      maxWidth: "100%",
    },
  },

  navigationBlock: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
  },

  loader: {},

  canvas: {
    width: "100%",
    height: "100%",
  },
}));

interface TrainingShareImageProps {
  photoUrl?: string;
  workout?: any;
  setCanvas?: (canvas: React.RefObject<HTMLCanvasElement>) => void;
  stats: WorkoutStat[];
}

export default function TrainingShareImage(props: TrainingShareImageProps) {
  const { photoUrl, workout, setCanvas, stats } = props;
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const { isBrandedWorkspace } = useCurrentBrand();
  const { logoUrl } = useCurrentWorkspace();
  const [generating, setGenerating] = React.useState(false);
  const s = useStyles();

  const currentWatermark = isBrandedWorkspace
    ? convertUrl(logoUrl) || watermark
    : watermark;
  const drawStatsImage = React.useCallback(async () => {
    const displayStats = [stats[1], stats[0], stats[2]];

    await generateWatermakedCanvasImage(
      await drawImageWithStats(convertUrl(photoUrl), displayStats),
      canvasRef,
      currentWatermark,
    );
  }, [currentWatermark, photoUrl, stats]);

  const drawWorkoutList = React.useCallback(async () => {
    const displayStats = [stats[1], stats[0], stats[2]];

    await generateWatermakedCanvasImage(
      await drawExerciseList(workout, displayStats),
      canvasRef,
      currentWatermark,
    );
  }, [currentWatermark, workout, stats]);

  const drawGridStatsImage = React.useCallback(async () => {
    await generateWatermakedCanvasImage(
      await drawStatsGrid(stats),
      canvasRef,
      currentWatermark,
    );
  }, [currentWatermark, stats]);

  React.useEffect(() => {
    setCanvas?.(canvasRef);
    let timeout: any;
    setGenerating(true);

    (photoUrl
      ? drawStatsImage()
      : workout
        ? drawWorkoutList()
        : drawGridStatsImage()
    ).then(() => {
      timeout = setTimeout(() => {
        setGenerating(false);
      }, 300);
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box className={s.picture}>
      {generating && <CircularLoader className={s.loader} />}
      <canvas
        className={clsx(s.canvas, generating && s.invisible)}
        ref={canvasRef}
        width="1000"
        height="1000"
      />
    </Box>
  );
}
