import { useGridCellEditor, CustomCellEditorProps } from "ag-grid-react";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  CustomExerciseTitleCellEditorProps,
  getDefaultExerciseCellTitle,
  isComponentRow,
} from "../utils";
import { RowType } from "../../../constants";
import { Box, IconButton, useTheme } from "@mui/material";
import { useCurriculumDispatch } from "../../../redux/hooks";
import { updateComponentTitle } from "../../../redux/curriculum/curriculum-slice";
import { useSidebar } from "../../../contexts/CurriculumSidebarContext";
import { ComponentCellIcon } from "../SpreadsheetCellRenderers/ExerciseCellRenderer";

const useStyles = makeStyles((theme) => ({
  input: {
    paddingInline: theme.spacing(1.8),
    border: "none",
    outline: "none",
    width: "100%",
    color: theme.palette.common.black,
  },
}));

export default memo(
  ({
    api,
    initialValue,
    onValueChange,
    data,
    saveWorkoutContent,
    componentClickHandler,
  }: CustomCellEditorProps & CustomExerciseTitleCellEditorProps) => {
    const s = useStyles();
    const theme = useTheme();
    const dispatch = useCurriculumDispatch();
    const { isOpen: isSidebarOpen, componentSlug: currentSidebarComponent } =
      useSidebar();

    const inputRef = useRef<HTMLInputElement>(null);

    const IS_OVERRIDE = !!data?.baseComponentId;

    const initialSanitizedValue =
      initialValue === getDefaultExerciseCellTitle(data.rowType)
        ? ""
        : initialValue;
    const [value, setValue] = useState(initialSanitizedValue);

    const onChange = ({ target: { value } }) => {
      setValue(value);
    };

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const saveComponentTitle = (componentId: number, newTitle: string) => {
      dispatch(updateComponentTitle({ componentId, title: newTitle }));
    };

    const saveWorkoutSectionTitle = (
      componentId: string,
      componentContent: any,
      workoutSectionId: string,
      newTitle: string,
    ) => {
      componentContent.map((node) => {
        if (node.id === workoutSectionId) {
          node.workout.title = newTitle;
        }
      });

      saveWorkoutContent(componentId, componentContent);
    };

    const isCancelAfterEnd = useCallback(() => {
      if (initialSanitizedValue === value) {
        return true;
      }

      if (isComponentRow(data.rowType)) {
        const valueWithDefault = value
          ? value
          : getDefaultExerciseCellTitle(data.rowType);
        data.exercise = valueWithDefault;
        onValueChange(valueWithDefault);

        saveComponentTitle(data.componentId, value);
        return false;
      }

      if (data.rowType === RowType.WORKOUT_SECTION) {
        data.exercise = value;
        onValueChange(value);

        saveWorkoutSectionTitle(
          data.componentId,
          data.componentContent,
          data.workoutSectionData?.id,
          value,
        );
        return false;
      }

      return false;
    }, [value]);

    useGridCellEditor({
      isCancelAfterEnd,
    });

    const handleOpenModalClick = () => {
      api.stopEditing();
      componentClickHandler(data.slug);
    };
    return (
      <>
        <input
          className={s.input}
          ref={inputRef}
          type="text"
          value={value}
          onChange={onChange}
        />
        {data.rowType !== RowType.WORKOUT_SECTION && (
          <Box
            sx={{
              background: theme.palette.common.white,
              pr: 0.5,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={handleOpenModalClick}
              sx={{
                marginBottom: 0.5,
                borderRadius: "unset",
                height: "100%",
                marginRight: -0.75,
                aspectRation: 1,
              }}
            >
              {ComponentCellIcon(
                isSidebarOpen,
                currentSidebarComponent === data.slug,
              )}
            </IconButton>
          </Box>
        )}
      </>
    );
  },
);
