import React, { useEffect } from "react";
import { Box, BoxProps, useMediaQuery, useTheme } from "@mui/material";
import { PreviewType, usePreview } from "../../hooks/usePreview";
import { PREVIEW_BAR_SPACING } from "./PreviewBar";
import DevicePreview, { DeviceForPreview } from "./DevicePreview";

export interface PreviewBoxProps extends BoxProps {
  src?: string;
  wrapperPosition?: string;
}

export function PreviewBox(props: PreviewBoxProps) {
  const { children, wrapperPosition } = props;
  const [preview, setPreview] = usePreview();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Set WEB as default preview for small screens
  useEffect(() => {
    isSm && setPreview(PreviewType.WEB);
  }, [isSm]);

  const getPreviewByType = (type: PreviewType) => {
    switch (type) {
      case PreviewType.MOBILE:
        return (
          <DevicePreview
            device={DeviceForPreview.IPHONE}
            mode="wrapper"
            wrapperPosition={wrapperPosition}
          >
            {children}
          </DevicePreview>
        );
      case PreviewType.TABLET:
        return (
          <DevicePreview
            device={DeviceForPreview.IPAD}
            mode="wrapper"
            wrapperPosition={wrapperPosition}
          >
            {children}
          </DevicePreview>
        );
      case PreviewType.WEB:
        return (
          <Box width={1} height={1}>
            {children}
          </Box>
        );

      default:
        break;
    }
  };

  return (
    <Box
      width={"100vw"}
      height={`calc(100vh - ${theme.spacing(PREVIEW_BAR_SPACING)})`}
    >
      <Box
        p={3}
        width={1}
        height={"100%"}
        margin={"auto"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {getPreviewByType(preview)}
      </Box>
    </Box>
  );
}
