import { Typography, useTheme } from "@mui/material";
import React from "react";
import { GenericScheduleSwitch } from "./GenericScheduleSwitch";

export type ITitleSwitchProps = {
  checked?: boolean;
  enableSwitch?: boolean;
  handleSwitchOnChange?: (checked: boolean) => void;
  reverse?: boolean;
};

interface IGenericScheduleTitleProps {
  title: string;
  color?: string;
  disabled?: boolean;
}

const GenericScheduleTitle = (
  props: IGenericScheduleTitleProps & ITitleSwitchProps,
) => {
  const {
    title,
    color,
    checked,
    enableSwitch,
    handleSwitchOnChange,
    disabled,
    reverse,
  } = props;
  const theme = useTheme();

  return (
    <Typography
      variant="subtitle2"
      fontWeight={"bold"}
      textTransform={"uppercase"}
      color={disabled ? theme.palette.divider : theme.palette.text.secondary}
      sx={{
        ...(reverse ? { flexDirection: "row-reverse", lineHeight: 1 } : {}),
        display: "flex",
        alignItems: "center",
        justifyContent: reverse ? "start" : "space-between",
        mb: 0.5,
      }}
    >
      {title}
      <GenericScheduleSwitch
        checked={checked}
        customColor={color}
        sx={{
          visibility: enableSwitch ? "inherit" : "hidden",
          maxWidth: enableSwitch ? "unset" : 0,
          m: 0,
          mr: reverse ? 1 : 0,
        }}
        onChange={(e) =>
          handleSwitchOnChange && handleSwitchOnChange(e.target.checked)
        }
      />
    </Typography>
  );
};

export default GenericScheduleTitle;
