import clsx from "clsx";
import React from "react";
import {
  Card,
  CardProps,
  Box,
  Typography,
  Button,
  Portal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardRounded } from "@mui/icons-material";
import omit from "lodash.omit";
import {
  usePopupState,
  bindToggle,
  bindPopper,
} from "material-ui-popup-state/hooks";
import { ReactComponent as ArrowIcon } from "../../icons/arrowGray.svg";

import { colorSystem } from "../../theme";
import { ClientGoalsDrawer } from "../client-goals/ClientGoalsDrawer";
import { ClientGoal } from "../client-goals/ClientGoal";
import ClientGoalsClient from "../../services/ClientGoalsClientService";
import { ReactComponent as GoalsIcon } from "../../icons/Goals.svg";

import { useQuery } from "@tanstack/react-query";
import { ClientGoalsCardSkeleton } from "../loading/ClientGoalsCardSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginBottom: 4,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  setButton: {
    backgroundColor: colorSystem.gray1,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    height: 44,
    padding: theme.spacing(1.5, 7.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  updateButton: {
    color: theme.palette.secondary.main,
    backgroundColor: "transparent",
    fontSize: 16,
    fontWeight: 600,
    boxShadow: "none",
    height: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  updateButtonIcon: {
    color: theme.palette.primary.main,
    fontSize: "25px!important",
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  goals: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    marginTop: theme.spacing(4.5),

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },

  goal: {
    justifyContent: "space-between",
    marginRight: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(0),
    },

    "&:nth-child(n+2)": {
      marginTop: theme.spacing(3),
    },

    [theme.breakpoints.up("sm")]: {
      "&:nth-child(n+2)": {
        marginTop: "initial",
      },
      "&:nth-child(n+3)": {
        marginTop: theme.spacing(3),
      },
    },

    [theme.breakpoints.up("md")]: {
      "&:nth-child(n+3)": {
        marginTop: "initial",
      },
      "&:nth-child(n+4)": {
        marginTop: theme.spacing(3),
      },
    },
  },

  goalsTopContainer: {
    display: "flex",
    alignItems: "center",
  },

  goalsContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: theme.spacing(2),
  },

  empty: {
    "&:not(:last-child)": {
      paddingRight: theme.spacing(1),
    },
  },
}));

export interface ClientGoalsCardProps extends CardProps {
  clientId: number;
}

export const CLIENT_GOALS_LIST_QUERY_KEY = "client-goals-list";

export function ClientGoalsCard(props: ClientGoalsCardProps) {
  const { className, clientId, ...other } = props;

  const s = useStyles();
  const { breakpoints } = useTheme();
  const [isOpenContent, setIsOpenContent] = React.useState(true);

  const smUp = useMediaQuery(breakpoints.up("sm"));
  const smOnly = useMediaQuery(breakpoints.only("sm"));
  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-goals",
  });
  const itemPerRow = smOnly ? 2 : 3;
  const cardPageSize = itemPerRow * 3;

  const { data: goalsData, isLoading } = useQuery({
    queryKey: [CLIENT_GOALS_LIST_QUERY_KEY, { clientId }],
    queryFn: () => ClientGoalsClient.getAllClientGoals(clientId),
  });
  const goals = goalsData?.items;
  const cardClientGoals = goals?.slice(0, cardPageSize);
  const latestModifiedGoal = [...(goals ?? [])]
    .filter((goal) => goal.lastModified)
    .sort((a, b) => b.lastModified.valueOf() - a.lastModified.valueOf())[0];

  React.useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);
  return (
    <>
      {!isLoading ? (
        <Card className={clsx(s.root, className)} {...other}>
          <Box className={s.header}>
            <Box className={s.goalsTopContainer}>
              <GoalsIcon />
              <div className={s.goalsContent}>
                <Typography variant="h6" className={s.title} children="Goals" />
                {Boolean(cardClientGoals?.length) && (
                  <Typography variant="body1" className={s.subtitle}>
                    Last recorded{" "}
                    {latestModifiedGoal.lastModified.format("MMM DD, YYYY")}
                  </Typography>
                )}
              </div>
            </Box>
            {!smUp && (
              <ArrowIcon
                className={isOpenContent ? "" : s.arrowIconDown}
                onClick={() => setIsOpenContent(!isOpenContent)}
              />
            )}
            {goals?.length && smUp ? (
              <Button
                className={s.updateButton}
                endIcon={<ArrowForwardRounded className={s.updateButtonIcon} />}
                children="See more"
                {...bindToggle(drawerState)}
              />
            ) : (
              smUp && (
                <Button
                  className={s.setButton}
                  variant="contained"
                  children="Set Goals"
                  {...bindToggle(drawerState)}
                />
              )
            )}
          </Box>
          {isOpenContent && (
            <>
              <Box className={s.goals}>
                {cardClientGoals?.length ? (
                  cardClientGoals.map((goal) => (
                    <ClientGoal
                      key={goal.id}
                      className={s.goal}
                      goal={goal}
                      clientId={clientId}
                    />
                  ))
                ) : (
                  <Typography
                    sx={{ color: (theme) => theme.palette.text.secondary }}
                  >
                    No goals to show. You can set new goals.
                  </Typography>
                )}
              </Box>
              {goals?.length && !smUp ? (
                <Button
                  className={s.updateButton}
                  endIcon={
                    <ArrowForwardRounded className={s.updateButtonIcon} />
                  }
                  children="See more"
                  {...bindToggle(drawerState)}
                />
              ) : (
                !smUp && (
                  <Button
                    className={s.setButton}
                    variant="contained"
                    children="Set Goals"
                    {...bindToggle(drawerState)}
                  />
                )
              )}
            </>
          )}
        </Card>
      ) : (
        <ClientGoalsCardSkeleton />
      )}
      {drawerState.isOpen && (
        <Portal>
          <ClientGoalsDrawer
            {...omit(bindPopper(drawerState), ["anchorEl"])}
            onClose={drawerState.close}
            goalsData={goalsData}
            clientId={clientId}
          />
        </Portal>
      )}
    </>
  );
}
