import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuth from "../../hooks/auth/useAuth";
import { REQUIRE_LOGOUT_ROUTE } from "../routes";

export const RequireUnauthorized = ({ authorized }) => {
  const location = useLocation();

  return authorized ? (
    <Navigate to={REQUIRE_LOGOUT_ROUTE} state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};
