import clsx from "clsx";
import React, { useState } from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Message } from "./Message";
import { MessageItemDto } from "@growth-machine-llc/stridist-api-client";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";
import { ThreadWithMessages } from "./Messages";
import { DefaultLoader } from "../loading/DefaultLoader";
import { LoadMoreButton } from "../button/LoadMoreButton";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 0,

    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(2.5),
    },
  },

  wrapper: {
    display: "flex",
    flexDirection: "column-reverse",
    alignContent: "flex-end",
    padding: theme.spacing(2, 0),
  },
}));

const FETCH_NEXT_PAGE_WHEN_REMAINING = 10;

const renderMessage = (
  message: MessageItemDto,
  index: number,
  messages: MessageItemDto[],
  lastMessageRef: (node: HTMLDivElement) => void,
) => {
  const nextNode = index > 0 && messages[index - 1];
  const prevNode = messages.length > index + 1 && messages[index + 1];
  const displayDate =
    !prevNode || prevNode.formattedDate !== message.formattedDate;
  const displayAvatar =
    !nextNode ||
    nextNode.messageAuthor.username !== message.messageAuthor.username;
  const displayUserName =
    !prevNode ||
    prevNode.messageAuthor.username !== message.messageAuthor.username;
  return (
    <OptimisticUpdateContainer
      key={message.id ? `${message.id}-container` : index}
      id={message.id}
      transition={false}
    >
      {messages.length - index === FETCH_NEXT_PAGE_WHEN_REMAINING && (
        <div ref={lastMessageRef} />
      )}
      <Message
        key={message.id ? `${message.id}-message` : index}
        message={message}
        displayDate={displayDate}
        displayUserName={displayUserName}
        displayAvatar={displayAvatar}
      />
    </OptimisticUpdateContainer>
  );
};

export interface MessagesListProps extends BoxProps {
  messages?: ThreadWithMessages["messages"];
  lastMessageRef: (node: HTMLDivElement) => void;
  isFetchingNextPage?: boolean;
}

export function MessagesList(props: MessagesListProps) {
  const { className, messages, isFetchingNextPage, lastMessageRef } = props;
  const s = useStyles();
  const endScrollRef = React.useRef<HTMLInputElement>();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const renderedMessages =
    messages &&
    messages
      ?.slice(0)
      .map((msg, index, array) =>
        renderMessage(msg, index, array, lastMessageRef),
      );
  const hasMessages = renderedMessages && renderedMessages.length > 0;

  const [count, setCount] = React.useState(renderedMessages?.length ?? 0);

  // scroll to first message on initial load
  React.useEffect(() => {
    if (isFirstLoad && endScrollRef.current) {
      endScrollRef.current.scrollIntoView();
      setIsFirstLoad(false);
    } else if (count !== renderedMessages?.length) {
      const endScrollEl = endScrollRef.current;
      const scrollEl = endScrollEl?.parentElement;
      const containerEl = scrollEl?.parentElement?.parentElement;

      if (endScrollEl && scrollEl && containerEl) {
        const { scrollTop, scrollHeight, clientHeight } = containerEl;

        const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
        if (distanceFromBottom < 400) {
          endScrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }

      setCount(renderedMessages?.length ?? 0);
    }
  }, [renderedMessages]);

  return (
    <Box className={clsx(s.root, className)}>
      {hasMessages ? (
        <Box className={s.wrapper}>
          <div ref={endScrollRef} />
          {renderedMessages}
          {isFetchingNextPage && (
            <Box sx={{ display: "flex", width: "100%", my: 2 }}>
              <DefaultLoader fullWidth size="large" />
            </Box>
          )}
        </Box>
      ) : null}
    </Box>
  );
}
