import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { LabeledSpinner } from "../../../loading/LabeledSpinner";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

interface ILoadingWebBookmarkProps {
  url: string;
}

export const LoadingWebBookmark = ({ url }: ILoadingWebBookmarkProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        border: "1px solid",
        borderRadius: 1,
        padding: 2.5,
        borderColor: theme.palette.border.primary,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 1,
        }}
      >
        <LabeledSpinner label="Fetching preview" size={16} />
      </Box>
      <Box
        sx={{
          display: "flex",

          flexDirection: "row",
          alignItems: "center",
          gap: 0.5,
        }}
      >
        <InsertLinkIcon
          fontSize="small"
          sx={{ color: (theme) => theme.palette.primary.main }}
        ></InsertLinkIcon>
        <Typography
          width={"100%"}
          sx={{ pr: 1, color: (theme) => theme.palette.primary.main }}
        >
          {url}
        </Typography>
      </Box>
    </Box>
  );
};
