import clsx from "clsx";
import React, { useMemo } from "react";
import {
  Menu,
  MenuProps,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { NotificationBox } from "./NotificationBox";

import { DefaultLoader } from "../loading/DefaultLoader";
import { LoadMoreButton } from "../button/LoadMoreButton";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import NotificationsService from "../../services/NotificationsService";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import {
  createInfinitePaginatedDataUpdater,
  INFINITE_PAGINATED_DATA_UPDATERS,
} from "../../utils/optimisticUpdate";
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  ICursorPaginatedListOfNotificationBriefDto,
  INotificationDto2,
} from "@growth-machine-llc/stridist-api-client";
import LoadingButton from "../button/LoadingButton";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { UserInfo } from "../../hooks/useCurrentUser";
import { useUpdateNotificationsCounter } from "../../hooks/useUpdateCurrentUser";
import { useReadNotificationMutation } from "../app/mutations/useReadNotificationMutation";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: "9999 !important" as any,
  },

  paper: {
    padding: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 2),
    },
  },

  list: {
    width: "calc(100vw - 32px)",
    maxHeight: 440,
    padding: 0,

    "@media (min-width: 472px)": {
      width: 400,
    },
  },

  item: {
    padding: 0,
  },

  button: {
    margin: theme.spacing(1, 0),
  },
}));

export interface NotificationsMenuProps extends MenuProps {
  className?: string;
  onClose: () => void;
  id: string;
  userId: number;
  role: string;
  unreadNotificationsCount: number;
}
export const NOTIFICATIONS_MENU_LIST_QUERY_KEY = "notifications";
export function NotificationsMenu(props: NotificationsMenuProps) {
  const { className, userId, onClose, unreadNotificationsCount, ...other } =
    props;

  const s = useStyles();

  const NOTIFICATIONS_MENU_PAGE_SIZE = 10;
  const FETCH_NEXT_PAGE_WHEN_REMAINING = 3;

  const {
    data: notificationsData,
    ref: notificationsElementRef,
    isLoading: isLoadingInitial,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [NOTIFICATIONS_MENU_LIST_QUERY_KEY],
    queryFn: ({ pageParam }) =>
      NotificationsService.getNotifications(
        pageParam ? Number(pageParam) : null,
        NOTIFICATIONS_MENU_PAGE_SIZE,
      ),
    initialPageParam: null,
    getNextPageParam: (lastPage) => {
      return lastPage?.nextCursor;
    },
  });

  const notifications = useMemo(() => {
    return notificationsData?.pages.flatMap((page) => page.items) ?? [];
  }, [notificationsData]);

  const totalCount = notificationsData?.pages[0].totalCount;

  const updateNotificationsCount = useUpdateNotificationsCounter();

  const { mutate: readAllNotifications, isPending: readingAllNotifications } =
    useOptimisticUpdateMutation({
      disableToastAlerts: true,
      queryKey: [NOTIFICATIONS_MENU_LIST_QUERY_KEY],
      mutationFn: () => NotificationsService.readAllNotifications(),
      optimisticUpdater: {
        updateFn: createInfinitePaginatedDataUpdater<INotificationDto2, void>(
          (items) =>
            items.map((notification) => ({
              ...notification,
              read: true,
            })),
        ),
      },
      options: {
        onSuccess: () => {
          updateNotificationsCount("reset");
        },
      },
    });

  const { mutate: notificationDelete, isPending: inFlight } =
    useOptimisticUpdateMutation({
      disableToastAlerts: true,
      queryKey: [NOTIFICATIONS_MENU_LIST_QUERY_KEY],
      mutationFn: ({ id, wasRead }: { id: number; wasRead: boolean }) =>
        NotificationsService.deleteNotification({ id }),
      optimisticUpdater: {
        updateFn: (
          prev: InfiniteData<ICursorPaginatedListOfNotificationBriefDto>,
          { id },
        ) => INFINITE_PAGINATED_DATA_UPDATERS.filterOutItem(prev, id),
      },
      options: {
        onSuccess: (_data, { wasRead }) => {
          !wasRead && updateNotificationsCount("decrement");
        },
      },
    });

  const { mutate: notificationRead } = useReadNotificationMutation<
    InfiniteData<ICursorPaginatedListOfNotificationBriefDto>
  >({
    queryKey: [NOTIFICATIONS_MENU_LIST_QUERY_KEY],
    readUpdater: (prev, variables) =>
      INFINITE_PAGINATED_DATA_UPDATERS.updateItemProperties(
        prev,
        variables.id,
        { read: true },
      ),
  });

  const handleLoadMoreClick = React.useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleReadAllNotificationsClick = React.useCallback(() => {
    readAllNotifications();
  }, [readAllNotifications]);

  const handleClickNotification = React.useCallback(
    (id: number, wasRead: boolean) => {
      if (wasRead === false) {
        const notification = notifications.find((n) => n.id === id);
        // Add other ids to recognize the notification type. Needed to update unread counters.
        notificationRead({
          id,
          messageId: notification?.message?.id,
          activityFeedbackId: notification?.activityFeedback?.id,
          clientFormId: notification?.clientForm?.id,
          groupPostCommentId: notification?.groupComment?.id,
          groupPostId: notification?.groupPost?.id,
        });
      }
      onClose();
    },
    [notificationRead, notifications, onClose],
  );

  const handleDeleteClick = React.useCallback(
    (id: number, wasRead: boolean) => {
      notificationDelete({ id, wasRead });
    },
    [notificationDelete],
  );

  return (
    <Menu
      className={s.root}
      PaperProps={{
        className: clsx(s.paper),
      }}
      MenuListProps={{
        className: clsx(s.list, className),
        dense: true,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      {...other}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
          paddingX: 2,
        }}
      >
        <Typography variant="h6">Notifications</Typography>
        <LoadingButton
          variant="text"
          color="primary"
          disabled={unreadNotificationsCount < 1}
          loading={readingAllNotifications}
          onClick={handleReadAllNotificationsClick}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "medium",
            }}
          >
            {`Mark all as read (${unreadNotificationsCount ?? 0})`}
          </Typography>
        </LoadingButton>
      </Box>
      {isLoadingInitial ? (
        <DefaultLoader fillParent />
      ) : totalCount > 0 ? (
        notifications?.map((item, index) => (
          <ListItem
            key={item.id}
            className={s.item}
            dense
            style={{ outline: "none" }}
          >
            {notifications.length - index ===
              FETCH_NEXT_PAGE_WHEN_REMAINING && (
              <div ref={notificationsElementRef} />
            )}
            <NotificationBox
              notification={item}
              onClickDelete={handleDeleteClick}
              onClickBox={handleClickNotification}
              index={index}
            />
          </ListItem>
        ))
      ) : (
        <ListItem className={s.item} dense style={{ outline: "none" }}>
          <ListItemText primary="You have no notifications." />
        </ListItem>
      )}
      {hasNextPage && (
        <LoadMoreButton
          onClick={handleLoadMoreClick}
          disabled={isLoadingInitial}
          loading={isFetchingNextPage}
          fullWidth
        >
          Load more
        </LoadMoreButton>
      )}
    </Menu>
  );
}
