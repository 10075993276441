export const isLocalEnvironment = () => {
  const { hostname } = window.location;
  return (
    hostname === "local.stridist.com" ||
    hostname === "localhost" ||
    hostname.startsWith("dev.") ||
    hostname.match(/^\d+\.\d+\.\d+\.\d+/)
  );
};

export const isStagingEnvironment = () => {
  const { hostname } = window.location;
  return (
    hostname.startsWith("stage.app.") ||
    hostname.match(/^\d+stage\./) ||
    hostname === "stage.app.stridist.com" ||
    hostname === "test.127.0.0.1.nip.io"
  );
};
