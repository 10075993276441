import React, { useEffect, useState } from "react";
import { DialogProps, Typography, Button, Box, useTheme } from "@mui/material";
import { motion } from "framer-motion";

import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../../api/ReactQueryConfig";
import { useCreateSignupCheckoutSession } from "../../../hooks/useCreateSignupCheckoutSession";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import BillingsService from "../../../services/BillingsService";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import BlockingDialog from "../BlockingDialog";
import { SignupCheckoutStatus } from "../../../constants";
import Loader from "../../../icons/loader.gif";
import { useNavigate } from "react-router-dom";
import { COACH_PROGRAMS_PUBLISHED_ROUTE } from "../../../routes/routes";
import { useCurrentUserId } from "../../../hooks/useCurrentUser";
import { sendCoachSignupSubscriptionSucceededEvent } from "../../../utils/googleAnalytics";

interface CompleteSignupBillingDialogProps extends DialogProps {
  onClose?: () => void;
  type: SignupCheckoutStatus;
}

export function CompleteSignupBillingDialog({
  type,
  ...props
}: CompleteSignupBillingDialogProps) {
  const dialogMap = {
    [SignupCheckoutStatus.SUCCEEDED]: <SubscriptionPendingDialog {...props} />,
    [SignupCheckoutStatus.INCOMPLETE]: (
      <CheckoutSessionDialog
        {...props}
        title="One Last Step"
        content="To activate your account and start using all features, please complete the payment process. You won't be charged during your trial period."
        actionLabel="Continue to Payment"
      />
    ),
    [SignupCheckoutStatus.FAILED]: (
      <CheckoutSessionDialog
        {...props}
        title="Payment Not Completed"
        content="The payment process was interrupted. Don't worry - your account is saved and you can continue where you left off."
        actionLabel="Continue Payment"
      />
    ),
  };

  const userId = useCurrentUserId();

  useEffect(() => {
    if (type === SignupCheckoutStatus.SUCCEEDED) {
      sendCoachSignupSubscriptionSucceededEvent(userId);
    } else if (type === SignupCheckoutStatus.FAILED) {
      sendCoachSignupSubscriptionSucceededEvent(userId);
    }
  }, [type]);

  return dialogMap[type] || null;
}

const CheckoutSessionDialog = ({
  title,
  content,
  actionLabel,
  ...props
}: DialogProps & { actionLabel: string }) => {
  const {
    createSignupCheckoutSession,
    creatingSignupCheckoutSession,
    sessionCreated,
  } = useCreateSignupCheckoutSession();

  return (
    <BlockingDialog
      {...props}
      title={title}
      maxWidth="sm"
      titleProps={{ textAlign: "center" }}
      contentProps={{ sx: { textAlign: "center", py: 4 } }}
      children={<Typography color="text.secondary">{content}</Typography>}
      actionsAlignment="center"
      actions={
        <Button
          onClick={() => createSignupCheckoutSession()}
          disabled={creatingSignupCheckoutSession || sessionCreated}
          variant="contained"
          size="large"
        >
          {actionLabel}
        </Button>
      }
    />
  );
};

const SubscriptionPendingDialog = ({
  open,
  onClose,
  ...props
}: DialogProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [attemptsLeft, setAttemptsLeft] = useState(5);

  const { data: subscriptionStatus, isFetching } = useQuery({
    queryKey: ["user-subscription-status"],
    queryFn: () => {
      setAttemptsLeft((prev) => prev - 1);
      return BillingsService.getUserSubscription();
    },
    retry: 0,
    refetchInterval: 5000,
    select: (data) => data?.status,
    enabled: open && attemptsLeft > 0,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });

  useEffect(() => {
    const refetchUserInfo = async () => {
      await queryClient.invalidateQueries({
        queryKey: [CURRENT_USER_QUERY_KEY],
      });
      handleClose();
    };

    if (subscriptionStatus) {
      refetchUserInfo();
    }
  }, [subscriptionStatus]);

  const handleTryAgain = () => setAttemptsLeft(5);
  const handleClose = () => {
    onClose?.({}, "backdropClick");
  };

  const isFailed = attemptsLeft === 0;

  const isLoading = !isFailed;

  return (
    <BlockingDialog
      {...props}
      fullWidth
      maxWidth="sm"
      titleProps={{ textAlign: "center" }}
      contentProps={{ sx: { textAlign: "center" } }}
      open={open}
      title={isFailed ? "Verification Issue" : "Finalizing Your Account..."}
      showLogout={!isLoading}
      showNeedHelp={!isLoading}
      children={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={3}
          py={2}
        >
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isLoading && (
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    height: 46,
                    width: 70,
                  }}
                />
              )}
              <Typography color="text.secondary">
                {isFailed
                  ? `We're having trouble confirming your subscription status. This might be due to a temporary delay.`
                  : "This usually takes less than a minute."}
              </Typography>
            </Box>
          </>
        </Box>
      }
      actionsAlignment="center"
      actions={
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {isFailed && (
            <Button
              onClick={handleTryAgain}
              variant="contained"
              color="primary"
              size="large"
            >
              Check Again
            </Button>
          )}
        </Box>
      }
    />
  );
};
