import dayjs from "dayjs";
import { groupBy, chunk } from "lodash";

export const interpolateDataContinuous = (
  data: any[],
  noPadding?: boolean,
  averagingWindow: "day" | "week" | "month" | "year" = "day",
) => {
  const roundValue = (value: number) =>
    Math.round(value * 10 + Number.EPSILON) / 10;

  const result: {
    x: string;
    y?: number;
    year: string;
    interpolated?: boolean;
  }[] = [];
  const start = dayjs(data[0]?.x).startOf("year");
  const end = dayjs(data[data.length - 1]?.x).endOf("year");

  const timePeriod = averagingWindow === "week" ? "isoWeek" : averagingWindow;

  for (
    let d = start;
    d.isBefore(end, "day") || d.isSame(end, "day");
    d = d.add(1, "day")
  ) {
    const week = data.filter((week) => week.x.isSame(d, timePeriod));

    const isDataStart =
      (!noPadding && d.isSame(start, "day")) ||
      (noPadding && d.isSame(data[0]?.x, "day"));

    if (week.length) {
      result.push({
        x: d.startOf("day").format("YYYY-MM-DD"),
        y: roundValue(
          week.reduce((acc, curr) => acc + curr.y, 0) / week.length,
        ),
        year:
          d.isSame(d.startOf("year"), "day") || isDataStart
            ? d.format("YYYY")
            : undefined,
        interpolated: !week.find((day) => day.x.isSame(d, "day")),
      });
    } else {
      const last_el = result[result.length - 1];
      const prev = last_el && last_el.y ? last_el : null;
      const next = data.find((day) => day.x.isAfter(d, "day"));

      if (prev && next) {
        const trueFactor =
          d.diff(prev.x, averagingWindow) /
          (next.x.diff(prev.x, averagingWindow) || 1);
        const factor = Math.min(1, Math.max(0, trueFactor));
        const interpolatedValue = prev.y + factor * (next.y - prev.y);

        result.push({
          x: d.startOf("day").format("YYYY-MM-DD"),
          y: roundValue(interpolatedValue),
          year:
            d.isSame(d.startOf("year"), "day") || isDataStart
              ? d.format("YYYY")
              : undefined,
          interpolated: true,
        });
      } else if (!noPadding) {
        result.push({
          x: d.startOf("day").format("YYYY-MM-DD"),
          year:
            d.isSame(d.startOf("year"), "day") || isDataStart
              ? d.format("YYYY")
              : undefined,
          interpolated: true,
        });
      }
    }
  }

  return result;
};

export const interpolateDataDiscrete = (
  data: any[],
  noPadding = false,
  averagingWindow: "day" | "week" | "month" | "year" = "day",
) => {
  const roundValue = (value: number) =>
    Math.round(value * 10 + Number.EPSILON) / 10;

  const result: {
    x: string;
    y?: number;
    year: string;
  }[] = [];
  const start = dayjs(data[0]?.x).startOf("year");
  const end = dayjs(data[data.length - 1]?.x).endOf("year");

  const timePeriod = averagingWindow === "week" ? "isoWeek" : averagingWindow;

  for (
    let d = start;
    d.isBefore(end, "day") || d.isSame(end, "day");
    d = d.add(1, averagingWindow)
  ) {
    const week = data.filter((week) => week.x.isSame(d, timePeriod));

    const isDataStart =
      (!noPadding && d.isSame(start, averagingWindow)) ||
      (noPadding && d.isSame(data[0]?.x, averagingWindow));

    if (week.length) {
      result.push({
        x: d.startOf(averagingWindow).format("YYYY-MM-DD"),
        y: roundValue(week.reduce((acc, curr) => acc + curr.y, 0)),
        year:
          d.isSame(d.startOf("year"), averagingWindow) || isDataStart
            ? d.format("YYYY")
            : undefined,
      });
    } else {
      const noPrev = result.length === 0;
      const noNext = !data.find((day) => day.x.isAfter(d));
      if ((!noPrev && !noNext) || ((noPrev || noNext) && !noPadding)) {
        result.push({
          x: d.startOf("day").format("YYYY-MM-DD"),
          y: undefined,
          year:
            d.isSame(d.startOf("year"), averagingWindow) || isDataStart
              ? d.format("YYYY")
              : undefined,
        });
      }
    }
  }

  return result;
};

export const chunkBy = (data: any[], units: string[]) => {
  const weeks = groupBy(data, (item) =>
    units.map((unit) => dayjs(item.x)[unit]().toString()).join("-"),
  );
  return Object.values(weeks)
    .filter((week) => !week.every((day) => !day.y))
    .map((week) => {
      return week.sort((a, b) => dayjs(a.x).diff(b.x));
    })
    .sort((a, b) => dayjs(a[0].x).diff(b[0].x));
};

export const chunkKeepTail = (data: any[], count: number) => {
  const chunks = chunk(data.toReversed(), count).filter(
    (week) => !week.every((day) => !day.y),
  );
  return chunks.map((chunk) => chunk.toReversed()).toReversed();
};
