import clsx from "clsx";
import React from "react";
import {
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  FormGroup,
  FormControlLabel,
  Popover,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ChevronDownIcon as ArrowDropDown } from "lucide-react";

import {
  BodypartType,
  MovementPatternType,
} from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 15,
  },

  active: {
    color: theme.palette.primary.main,
  },

  inactive: {
    color: theme.palette.text.secondary,
  },

  paper: {
    borderRadius: theme.spacing(1.5),
    minWidth: theme.spacing(32),
    padding: theme.spacing(2, 0),

    "& > :not($divider)": {
      margin: theme.spacing(0, 2),
    },
  },
  divider: {
    margin: theme.spacing(1.5, 0),
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  header: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },
}));

export enum ExerciseCategoryFiltersType {
  BODYPART_TYPE = "BodypartType",
  MOVEMENT_PATTERN_TYPE = "MovementPatternType",
}

export const exerciseCategoryFiltersTypeLabels = {
  [ExerciseCategoryFiltersType.BODYPART_TYPE]: "Bodypart",
  [ExerciseCategoryFiltersType.MOVEMENT_PATTERN_TYPE]: "Movement pattern",
};

export const createInitialFilters = <
  T extends BodypartType | MovementPatternType,
>(
  enumType: Record<string, T>,
) => {
  return Object.values(enumType).reduce<Record<T, boolean>>(
    (acc, type) => {
      acc[type] = true;
      return acc;
    },
    {} as Record<T, boolean>,
  );
};

type ExerciseCategoriesFilterProps<
  T extends BodypartType | MovementPatternType,
> = {
  className?: string;
  active?: boolean;
  filterType: T;
  filters: Record<T, boolean>;
  onChange: (filters: Record<T, boolean>) => void;
  filterTypesLabels: Record<T, string>;
  labelType: string;
};

export function ExerciseCategoriesFilter<
  T extends BodypartType | MovementPatternType,
>(props: ExerciseCategoriesFilterProps<T>) {
  const {
    className,
    filters,
    onChange,
    active,
    filterType,
    filterTypesLabels,
    labelType,
    ...other
  } = props;
  const s = useStyles();
  const ref = React.useRef<HTMLButtonElement>();
  const [open, setOpen] = React.useState(false);
  const enumValues = Object.values(filterType);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange: CheckboxProps["onChange"] = React.useCallback(
    (event, checked) => {
      const updatedFilters = {
        ...filters,
        [event.target.value]: checked,
      };

      const none = Object.values(updatedFilters).filter(Boolean).length === 0;

      if (none) {
        onChange({
          ...updatedFilters,
          ["NONE"]: true,
        });
      } else {
        onChange(updatedFilters);
      }
    },
    [filters, onChange],
  );

  const handleAllChange = (checked: boolean) => {
    const updatedFilters = Object.keys(filters).reduce(
      (acc, key) => {
        acc[key as keyof Record<T, boolean>] = checked;
        return acc;
      },
      {} as Record<T, boolean>,
    );

    if (!checked) {
      updatedFilters["NONE"] = true;
    }

    onChange(updatedFilters);
  };

  const filterStatusText = React.useMemo(() => {
    const checkedFilters = Object.values(filters).filter(Boolean).length;
    const totalFilters = Object.keys(filters).length;
    if (checkedFilters === totalFilters) {
      return "All types";
    } else {
      return `${checkedFilters} selected`;
    }
  }, [filters]);

  return (
    <>
      <Button
        ref={ref}
        className={clsx(s.root, active ? s.active : s.inactive, className)}
        onClick={handleOpen}
        {...other}
      >
        Filter by {labelType} : {filterStatusText}
        <ArrowDropDown size={20} style={{ marginLeft: 2 }} />
      </Button>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        classes={{ paper: s.paper }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          height: 500,
        }}
      >
        <Typography className={s.header} variant="body2">
          {`Filter by ${labelType}`}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={Object.values(filters).every(Boolean)}
                onChange={(e) => handleAllChange(e.target.checked)}
              />
            }
            label={
              <Typography variant="body1" className={s.label}>
                All
              </Typography>
            }
          />
        </FormGroup>

        {enumValues.map((type) => (
          <FormGroup key={type}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={type}
                  checked={filters[type]}
                  onChange={handleChange}
                />
              }
              label={
                <Typography variant="body1" className={s.label}>
                  {filterTypesLabels[type]}
                </Typography>
              }
            />
          </FormGroup>
        ))}
      </Popover>
    </>
  );
}
