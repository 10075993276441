import React from "react";
import { alpha, Box, Typography, useTheme } from "@mui/material";
import { CurriculumComponent } from "../../../redux/types";
import { useProgramWeeks } from "../../../hooks/useProgramWeeks";
import { ComponentRepeat, ComponentRepeatValues } from "../../../constants";
import { parseComponentDays } from "../../../utils/component";
import { useEnableColors } from "../../../hooks/useEnableColors";
import { getColorByComponentType } from "../../program-calendar/utils/common";
import { TILL_THE_END_OF_THE_PROGRAM } from "../../program-component/utils";
import { useCurriculumSelector } from "../../../redux/hooks";
import {
  selectComponentById,
  selectComponentOverrides,
} from "../../../redux/curriculum/selectors/curriculum";
import dayjs from "dayjs";
import { FlagTriangleRight } from "lucide-react";
import { getCommonTransition } from "../../../utils/mui";

interface RepeatsMapProps {
  component: CurriculumComponent;
  size?: number;
  labelProps?: LabelProps;
  enabledIndices?: "all" | "none" | IndicesType | IndicesType[];
  selectionProps?: SelectionProps;
  startDate?: dayjs.Dayjs;
  initialSelectedDay?: { week: number; day: number } | null;
  onSelectionClick?: (week: number, day: number) => void;
  highlightWeeks?: number[];
  onContainerClick?: () => void;
}

type LabelProps = {
  weeks: "none" | "head" | "in-cell";
  days: "none" | "head";
};

type IndicesType = "empty" | "self" | "overrides" | "base";

type SelectionProps = {
  children?: React.ReactNode;
};

type ScheduleEvent = {
  startWeek: number;
  days: boolean[];
  duration: number;
  repeat: ComponentRepeat;
};

type ScheduleEventOverride = {
  week: number;
  day: number;
};

const DAYS = 7;
const DEFAULT_DAY_NAMES = ["D1", "D2", "D3", "D4", "D5", "D6", "D7"];

// Get week day names
const getDayNames = (startDate: dayjs.Dayjs) => {
  const days = [];
  for (let i = 0; i < DAYS; i++) {
    days.push(startDate.clone().add(i, "days").format("ddd"));
  }
  return days;
};

// Calculate event indices
const getEventIndices = (events: ScheduleEvent[], weeksLength: number) => {
  const totalCells = weeksLength * DAYS;
  const indices = new Set();

  events.forEach(({ startWeek, days, repeat, duration }) => {
    const repeatInterval = ComponentRepeatValues[repeat] || 0;

    for (
      let occurrence = 0;
      occurrence < duration || duration === TILL_THE_END_OF_THE_PROGRAM;
      occurrence++
    ) {
      const weekOffset = startWeek - 1 + occurrence * repeatInterval;
      if (weekOffset >= weeksLength) break;

      days.forEach((isActive, dayIndex) => {
        if (isActive) {
          const index = weekOffset * DAYS + dayIndex;
          if (index < totalCells) {
            indices.add(index);
          }
        }
      });
    }
  });

  return indices;
};

// Calculate overrides indices
const getOverridesIndices = (overrides: ScheduleEventOverride[]) => {
  const indices = new Set();

  overrides.forEach((override) => {
    indices.add((override.week - 1) * DAYS + override.day);
  });

  return indices;
};

// Get highlighted rows range
const getHighlightRowsRanges = (rows: number[]) => {
  if (rows.length === 0) return [];
  const ranges: { start: number; end: number }[] = [];
  let start = rows[0];

  for (let i = 1; i < rows.length; i++) {
    if (rows[i] !== rows[i - 1] + 1) {
      ranges.push({ start, end: rows[i - 1] });
      start = rows[i];
    }
  }
  ranges.push({ start, end: rows[rows.length - 1] });

  return ranges;
};

const RepeatsMap = ({
  component,
  size = 12,
  labelProps = { weeks: "head", days: "none" },
  enabledIndices = "none",
  selectionProps = {},
  startDate,
  initialSelectedDay,
  onSelectionClick,
  onContainerClick,
  highlightWeeks = [],
}: RepeatsMapProps) => {
  const theme = useTheme();
  const programWeeks = useProgramWeeks();
  const enableColors = useEnableColors();

  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(
    initialSelectedDay
      ? (initialSelectedDay.week - 1) * DAYS + initialSelectedDay.day - 1
      : null,
  );

  const IS_OVERRIDE = !!component.baseComponentId;
  const WEEKS = programWeeks.length;
  const TOTAL_CELLS = DAYS * WEEKS;
  const COMPONENT_DAYS = parseComponentDays(component.days);

  const COMPONENT_OVERRIDES = useCurriculumSelector(
    selectComponentOverrides(component.id),
  );
  const COMPONENT_BASE_COMPONENT = useCurriculumSelector(
    selectComponentById(component.baseComponentId),
  );

  const events: ScheduleEvent[] = [
    {
      startWeek: programWeeks?.find((w) => w.id === component.weekId)?.week,
      days: COMPONENT_DAYS,
      repeat: component.repeat,
      duration: component.duration,
    },
  ];

  const overrides = COMPONENT_OVERRIDES.map((item) => {
    return {
      week: programWeeks?.find((w) => w.id === item.weekId)?.week,
      day: parseComponentDays(item.days).findIndex((item) => item === true),
    };
  });

  const baseEvents: ScheduleEvent[] = [
    {
      startWeek: programWeeks?.find(
        (w) => w.id === COMPONENT_BASE_COMPONENT?.weekId,
      )?.week,
      days: parseComponentDays(COMPONENT_BASE_COMPONENT?.days),
      repeat: COMPONENT_BASE_COMPONENT?.repeat,
      duration: COMPONENT_BASE_COMPONENT?.duration,
    },
  ];

  const EVENT_INDICES = getEventIndices(events, programWeeks.length);
  const OVERRIDES_INDICES = getOverridesIndices(overrides);
  const BASE_EVENT_INDICES = getEventIndices(baseEvents, programWeeks.length);

  //
  // HANDLERS
  const handleSelectionClick = (index: number) => {
    if (!getIndiceEnabled(index)) return;

    setSelectedIndex(index);
    if (onSelectionClick) {
      onSelectionClick(
        getCurrentWeekByIndex(index),
        getCurrentDayByIndex(index),
      );
    }
  };

  const handleContainerClick = () => {
    if (onContainerClick) {
      onContainerClick();
    }
  };

  //
  // HELPERS
  const isEventOn = (index: number) => {
    return EVENT_INDICES.has(index);
  };

  const isEventOverrideOn = (index: number) => {
    return OVERRIDES_INDICES.has(index);
  };

  const isBaseComponentEventOn = (index: number) => {
    return BASE_EVENT_INDICES.has(index);
  };

  const getCurrentWeekByIndex = (index: number) => {
    return Math.floor(index / 7) + 1;
  };

  const getCurrentDayByIndex = (index: number) => {
    return (index % 7) + 1;
  };

  const getDateByStart = (index: number) => {
    return startDate?.clone().add(index, "days");
  };

  const isWeekNumberShownOn = (index: number) => {
    const componentFirstDayIndex = COMPONENT_DAYS.findIndex((value) => value);
    return (index + (DAYS - componentFirstDayIndex)) % DAYS === 0;
  };

  const getIndiceType = (index: number): IndicesType => {
    if (isEventOn(index) && !isEventOverrideOn(index)) return "self";
    if (isEventOverrideOn(index)) return "overrides";
    if (isBaseComponentEventOn(index)) return "base";
    return "empty";
  };

  const getIndiceEnabled = (index: number) => {
    if (index === selectedIndex) return false;
    if (enabledIndices === "all") return true;
    if (enabledIndices === "none") return false;
    if (Array.isArray(enabledIndices)) {
      return enabledIndices.includes(getIndiceType(index));
    }
    return enabledIndices === getIndiceType(index);
  };

  //
  // UI
  const COLOR = enableColors
    ? getColorByComponentType(component.type)
    : theme.palette.grey[500];

  const getEventBackground = (index: number) => {
    if (isEventOverrideOn(index)) return alpha(COLOR, 0.45);
    if (isEventOn(index)) return IS_OVERRIDE ? alpha(COLOR, 0.6) : COLOR;
    if (isBaseComponentEventOn(index)) return alpha(COLOR, 0.3);
    return theme.palette.grey[200];
  };

  const DAY_NAMES = startDate ? getDayNames(startDate) : DEFAULT_DAY_NAMES;
  const HIGHLIGH_ROWS =
    highlightWeeks && getHighlightRowsRanges(highlightWeeks);

  const IS_DAYS_LABEL = labelProps.days === "head";

  return (
    <Box
      display={"flex"}
      width={"fit-content"}
      height={"fit-content"}
      onClick={handleContainerClick}
      minWidth={"fit-content"}
    >
      {HIGHLIGH_ROWS?.map(({ start, end }, index) => (
        <Box
          key={index}
          sx={{
            border: 2,
            borderRadius: 1,
            position: "absolute",
            background: alpha(theme.palette.primary.main, 0.03),
            borderColor: alpha(theme.palette.primary.main, 0.3),

            transition: getCommonTransition(theme, [
              "border-color",
              "background",
              "top",
            ]),

            //
            // X direction

            // full width + inline spacing
            width: `calc(100% + ${theme.spacing(2)})`,
            // inline spacing / -2
            ml: -1,

            //
            // Y direction

            // start row * (item size + grid gap) - half grid gap
            top: (start - 1) * (size + 6) - 4,
            // range length * (item size + grid gap) + half grid gap
            height: (end - start + 1) * (size + 6) + 2,
          }}
        />
      ))}

      {labelProps.weeks === "head" && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `1fr`,
            gap: 0.75,
            marginRight: 0.75,
          }}
        >
          {Array.from({
            length: WEEKS + (IS_DAYS_LABEL ? 1 : 0),
          }).map((_, index) => (
            <Typography
              key={"w" + index}
              variant="caption"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontWeight: "bold",
                gap: "1px",
                pr: IS_DAYS_LABEL ? theme.spacing(2) : 0,
                width: size,
                height: size,
                borderRight: IS_DAYS_LABEL ? (index > 0 ? 1 : 0) : "unset",
                borderColor: theme.palette.grey[400],
                color:
                  highlightWeeks.includes(index + 1) || IS_DAYS_LABEL
                    ? theme.palette.common.black
                    : theme.palette.grey[400],
                transition: getCommonTransition(theme, "color"),
              }}
            >
              {IS_DAYS_LABEL ? (
                index > 0 ? (
                  <>
                    <span>W</span>
                    <span>{index}</span>
                  </>
                ) : (
                  ""
                )
              ) : (
                index + 1
              )}
            </Typography>
          ))}
        </Box>
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${DAYS}, 1fr)`,
          rowGap: 0.75,
          columnGap: 0.5,
        }}
      >
        {labelProps.days === "head" &&
          DAY_NAMES.map((day) => (
            <Typography
              key={day}
              variant="caption"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontWeight: "bold",
                gap: 0.75,
                width: size,
                height: size,
                borderBottom: 1,
                borderColor: theme.palette.grey[400],
                letterSpacing: 1,
              }}
            >
              {day}
            </Typography>
          ))}
        {Array.from({ length: TOTAL_CELLS }).map((_, index) => (
          <Box
            key={index}
            onClick={() => handleSelectionClick(index)}
            sx={{
              width: size,
              height: size,
              position: "relative",
              backgroundColor: getEventBackground(index),
              border:
                index === selectedIndex
                  ? `${size / 8}px solid ${alpha(theme.palette.common.black, 0.6)}`
                  : `${size / 8}px solid ${getEventBackground(index)}`,
              borderRadius: size / 18.6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: getIndiceEnabled(index) ? "pointer" : "default",
              transition: theme.transitions.create(["box-shadow", "border"], {
                duration: theme.transitions.duration.standard,
              }),
              ":hover": {
                boxShadow: getIndiceEnabled(index)
                  ? "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                  : "none",
              },
            }}
          >
            {((isEventOn(index) && IS_OVERRIDE) ||
              isEventOverrideOn(index)) && <FlagTriangleRight size={16} />}

            {/* Dates */}
            {startDate &&
              (getIndiceEnabled(index) || index === selectedIndex) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="overline"
                    fontWeight={600}
                    style={{
                      fontSize: 12,
                      lineHeight: 1,
                      textAlign: "center",
                    }}
                  >
                    {getDateByStart(index).format("D")}
                  </Typography>
                  <Typography
                    variant="overline"
                    fontWeight={600}
                    style={{ fontSize: 7, lineHeight: 1, textAlign: "center" }}
                  >
                    {getDateByStart(index).format("MMM")}
                  </Typography>
                </Box>
              )}

            {/* Optional children node */}
            {index === selectedIndex && selectionProps.children && (
              <>{selectionProps.children}</>
            )}

            {/* Optional week number label */}
            {labelProps.weeks === "in-cell" &&
              isEventOn(index) &&
              isWeekNumberShownOn(index) && (
                <Typography
                  variant="overline"
                  fontWeight={600}
                  style={{ fontSize: 8 }}
                >
                  {getCurrentWeekByIndex(index)}
                </Typography>
              )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default RepeatsMap;
