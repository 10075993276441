import clsx from "clsx";
import React, { MouseEventHandler, useMemo, useState } from "react";
import {
  Card,
  CardProps,
  CardContent,
  Typography,
  Box,
  Button,
  ButtonProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForward } from "@mui/icons-material";
import { ReactComponent as CaretDownIcon } from "../../icons/caret-down.svg";
import { ComponentStatus, componentTypes } from "../../constants";
import { EditorValue } from "../program/EditorValue";
import { ProgramWeekSchedule } from "../../hooks/useProgramSchedule";
import { Filters } from "../program/filters/ComponentTypeFilter";
import { ProgramWeekDateRange } from "../program/ProgramWeekDateRange";
import { WeekAddComponent } from "./WeekAddComponent";
import { WeekComponentList } from "./WeekComponentList";
import { WeekDrawer } from "./WeekDrawer";
import useDependantKey from "../../hooks/useDependantKey";
import {
  useCurriculumDispatch,
  useCurriculumSelector,
} from "../../redux/hooks";
import { updatePositions } from "../../redux/curriculum/curriculum-slice";
import {
  selectCantMoveWeeksExceptSelf,
  selectProgram,
  selectWeekById,
} from "../../redux/curriculum/selectors/curriculum";
import WeekActionItems from "./WeekActionItems";
import { DefaultLoader } from "../loading/DefaultLoader";
import { selectGeneralLoading } from "../../redux/api/selectors";
import { ComponentClickHandlerType } from "../program/ProgramDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3.25, 0),
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.quote,
    borderRadius: 12,
    position: "relative",
    marginBottom: 24,

    "&:first-of-type": {
      marginTop: 32,
    },
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  content: {
    padding: 0,
  },
  header: {
    display: "flex",
    flexFlow: "wrap",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 0,
    cursor: "pointer",
    height: 44,
    alignItems: "center",
    display: "flex",
  },
  description: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    marginLeft: theme.spacing(3.5),
    fontSize: 16,
    fontWeight: 500,
  },
  moreButton: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& svg path[stroke]": {
      stroke: theme.palette.primary.main,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.main,
    },
  },
  detailsButton: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),
    fontSize: 16,
    marginLeft: "auto",
  },
  addComponent: {
    marginTop: theme.spacing(2),
  },
  weeks: {},
  collapsed: {
    "& $weeks": {
      display: "none",
    },
  },
  caret: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    width: 16,
    height: 16,

    "$collapsed &": {
      transform: "rotate(-90deg)",
    },
  },
  summary: {
    marginTop: theme.spacing(1),
    marginLeft: "auto",
  },

  summaryText: {
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    fontSize: 16,
  },

  summaryDateRange: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
}));

export interface WeekCardProps extends CardProps {
  initSchedule: ProgramWeekSchedule;
  filters?: Filters;
  startDate?: string;
  onOpenMenu?: ButtonProps["onClick"];
  open?: boolean;
  weekId: number;
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
  componentClickHandler: ComponentClickHandlerType;
  componentDoubleClickHandler: ComponentClickHandlerType;
}

export function WeekCard(props: WeekCardProps) {
  const {
    className,
    onOpenMenu,
    initSchedule,
    filters,
    startDate,
    weekId,
    weeksActions,
    componentClickHandler,
    componentDoubleClickHandler,
    ...other
  } = props;
  const dispatch = useCurriculumDispatch();
  const week = useCurriculumSelector(selectWeekById(weekId));
  const cantMove = useCurriculumSelector(selectCantMoveWeeksExceptSelf(weekId));
  const program = useCurriculumSelector(selectProgram);
  const loading = useCurriculumSelector(selectGeneralLoading);

  const weekNumber = week.weekNumber;

  const schedule = initSchedule.filter(({ weeks }) =>
    weeks.includes(week.weekNumber),
  );

  const s = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = React.useCallback((event) => {
    event.preventDefault();
    setDrawerOpen(true);
  }, []);

  const handleDrawerClose = React.useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const components = schedule.map(({ component }) => component);
  // TODO save to local storage
  const [open, setOpen] = useState(true);

  const [rawPositions, setRawPositions] = React.useState(week.positions);

  const positions = React.useMemo(() => {
    const combined = [
      ...rawPositions.filter((id) =>
        components.some((it) => String(it.id) === id),
      ),
      ...components
        .map(({ id }) => String(id))
        .filter((id) => !rawPositions.includes(id)),
    ];

    return Array.from(new Set(combined)); // Ensure uniqueness
  }, [rawPositions, components]);

  const handleOnMove = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newValue = [...positions];
      newValue.splice(dragIndex, 0, newValue.splice(hoverIndex, 1)[0]);
      setRawPositions(newValue);
    },
    [setRawPositions, positions],
  );

  const handleOnMoveEnd = React.useCallback(() => {
    dispatch(updatePositions({ id: weekId, positions }));
  }, [positions, weekNumber]);

  const componentsByType = React.useMemo(
    () =>
      componentTypes
        .filter((componentType) => !filters || filters[componentType])
        .map((componentType) => ({
          components: components
            .filter(({ type }) => type === componentType)
            .filter(
              ({ status }) =>
                status !== ComponentStatus.ARCHIVED ||
                (filters && filters.archived),
            ),
          componentType,
        })),
    [filters, components],
  );

  const handleToggleExpanded = React.useCallback(() => {
    setOpen((open) => !open);
  }, [open, setOpen]);

  const descriptionKey = useDependantKey(week.description);

  const upperIsDisabled = useMemo(
    () => cantMove.includes(week.weekNumber - 1),
    [cantMove, week.weekNumber],
  );

  const lowerIsDisabled = useMemo(
    () => cantMove.includes(week.weekNumber + 1),
    [cantMove, week.weekNumber],
  );

  // TODO refactor code duplication (all views),
  // handle waitingForApiResponse for component click and other states
  const weeksData = {
    canDuplicate: !week.waitingForApiResponse,
    canDelete: program.length > 1 && !week.waitingForApiResponse,
  };

  return (
    <Card className={clsx(s.root, className, !open && s.collapsed)} {...other}>
      <WeekDrawer
        open={drawerOpen}
        week={week}
        onClose={handleDrawerClose}
        weekId={weekId}
        weekNumber={weekNumber}
      />
      <CardContent className={s.content}>
        <Box className={s.header}>
          <CaretDownIcon className={s.caret} onClick={handleToggleExpanded} />

          <Typography
            variant="h5"
            className={s.title}
            onClick={handleToggleExpanded}
            gutterBottom
          >
            Week {weekNumber}
            {week.waitingForApiResponse ? " - creating..." : ""}
          </Typography>

          <WeekActionItems
            weekId={week.id}
            onOpenMenu={onOpenMenu}
            onClickDelete={weeksActions.onClickDelete}
            onClickDuplicate={weeksActions.onClickDuplicate}
            canDuplicate={weeksData.canDuplicate}
            canDelete={weeksData.canDelete}
          />
          {loading && <DefaultLoader />}
          {open && !startDate ? (
            <Button
              className={s.detailsButton}
              endIcon={<ArrowForward />}
              onClick={handleDrawerOpen}
              disabled={week.waitingForApiResponse}
            >
              View details
            </Button>
          ) : (
            <Box className={s.summary}>
              <Typography className={s.summaryText}>
                {components.length} item{components.length !== 1 && "s"}
              </Typography>
              {startDate && (
                <ProgramWeekDateRange
                  className={s.summaryDateRange}
                  startDate={startDate}
                  week={weekNumber}
                />
              )}
            </Box>
          )}
        </Box>

        {!week.waitingForApiResponse ? (
          <Typography
            component="div"
            className={s.description}
            key={descriptionKey}
          >
            <EditorValue
              value={week.description}
              defaultValue="Add an optional description in the details."
              onClick={handleDrawerOpen}
            />
          </Typography>
        ) : null}

        <Box className={s.weeks}>
          {!week.waitingForApiResponse &&
            componentsByType.map(
              ({ componentType, components }) =>
                components.length > 0 && (
                  <WeekComponentList
                    key={componentType}
                    components={components}
                    componentType={componentType}
                    positions={positions}
                    week={weekNumber}
                    onMove={handleOnMove}
                    onMoveEnd={handleOnMoveEnd}
                    weekId={weekId}
                    componentClickHandler={componentClickHandler}
                    componentDoubleClickHandler={componentDoubleClickHandler}
                  />
                ),
            )}

          <WeekAddComponent
            className={s.addComponent}
            weekId={weekId}
            disabled={week.waitingForApiResponse}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
