import { call, put, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import {
  selectComponentTempIdsMap,
  selectWeekTempIdsMap,
} from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";
import {
  CreateComponentOverrideCommand,
  CreatedOverrideResult,
} from "@growth-machine-llc/stridist-api-client";
import { CurriculumComponent } from "../../../../types";
import { setOverrideData } from "../../../curriculum-slice";

export default function* createOverrideWorker(payload: {
  baseComponentId: number;
  weekId: number;
  days: string;
  data: Partial<CurriculumComponent>;
  noDiscard?: boolean;
}) {
  const { baseComponentId, weekId, days, data, noDiscard } = payload;
  const map: TempIdsMap = yield select(selectComponentTempIdsMap);
  const weekMap: TempIdsMap = yield select(selectWeekTempIdsMap);
  const checkedComponentId = map?.[baseComponentId] || baseComponentId;
  const checkedWeekId = weekMap?.[weekId] || weekId;

  const request = new CreateComponentOverrideCommand({
    ...data,
    baseComponentId: checkedComponentId,
    weekId: checkedWeekId,
    days,
    noDiscard,
  });

  try {
    const result: CreatedOverrideResult = yield call(
      ComponentsService.createComponentOverride,
      request,
    );
    // ensure action will receive id which was dispatched (in case it's a temp id)
    result.copiedFrom = baseComponentId;

    yield put(
      setOverrideData({
        stateWeekId: weekId,
        stateBaseComponentId: baseComponentId,
        days,
        requestData: data,
        overrideId: result.id,
        overrideSlug: result.slug,
        overrideSlugId: result.slugId,
        basePreDraft: result.preDraft,
      }),
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
}
