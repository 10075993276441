import clsx from "clsx";
import React from "react";
import { DialogProps, Button, useTheme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { humanizeUserPlan } from "../../../utils/user";

import { useCurrentUser } from "../../../hooks/useCurrentUser";

import { BaseDialog } from "../BaseDialog";
import { COACH_PLAN_ROUTE } from "../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { Celebration } from "../../../icons/Celebration";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },

  svg: {
    width: "100%",
    maxWidth: 345,
    margin: theme.spacing(4, 0),
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.primary,
    textAlign: "left",
  },

  planButton: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    padding: theme.spacing(1.5),
  },
}));

export interface UpgradedDialogProps extends DialogProps {
  plan?: string;
  title?: string;
  description?: string;
  navigateToPlanPage?: boolean;
  showPlanButton?: boolean;
}

export function UpgradedDialog(props: UpgradedDialogProps) {
  const theme = useTheme();

  const {
    className,
    onClose,
    plan,
    title,
    description,
    navigateToPlanPage = true,
    showPlanButton = false,
    ...other
  } = props;

  const navigate = useNavigate();
  const s = useStyles();
  const user = useCurrentUser();

  const handleButtonClick = React.useCallback(
    (event) => {
      onClose(event, "backdropClick");
      if (navigateToPlanPage) {
        navigate(COACH_PLAN_ROUTE, { replace: true });
      }
    },
    [onClose],
  );

  const handleGoToPlans = React.useCallback(
    (event) => {
      onClose(event, "backdropClick");
      navigate(COACH_PLAN_ROUTE);
    },
    [onClose],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={
        title
          ? title
          : `Done! You're now on the ${humanizeUserPlan(
              plan || user.plan,
            )} plan`
      }
      showClose={false}
      {...other}
    >
      {description && (
        <Typography variant="body1" className={s.subtitle}>
          {description}
        </Typography>
      )}
      <Celebration className={s.svg} fill={theme.palette.primary.main} />
      <Button
        className={s.button}
        fullWidth
        variant="contained"
        children="OK, Let’s Go"
        onClick={handleButtonClick}
      />
      {showPlanButton && (
        <Button
          className={s.planButton}
          fullWidth
          variant="outlined"
          onClick={handleGoToPlans}
        >
          Navigate to Plans
        </Button>
      )}
    </BaseDialog>
  );
}
