import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";

import FileUploader from "../../other/FileUploader";

export type FileInfo = {
  name: string;
  url: string;
  type: string;
  size: number;
};

export type IFileQuestionAnswer = {
  file?: FileInfo | null;
};

type FileFeedbackProps = {} & ICheckInQuestionPropsBase<IFileQuestionAnswer>;
const FileQuestion = ({
  view,
  disableAnswer,
  element,
  handleSetNode,
}: FileFeedbackProps) => {
  const theme = useTheme();

  const isCoachView = view === EditorElementView.Coach;
  const disabled = isCoachView || disableAnswer;

  const answer = element.answer as IFileQuestionAnswer;

  const handleFileUpload = (file: FileInfo) => {
    handleSetNode({ answer: { file: file } });
  };

  const handleFileDelete = () => {
    handleSetNode({ answer: { file: null } });
  };

  return (
    <DisabledCheckInTooltip visible={isCoachView}>
      <Box
        sx={{
          height: 300,
        }}
      >
        <FileUploader
          file={answer?.file}
          readonly={view === EditorElementView.Review}
          onFileUpload={handleFileUpload}
          onFileDelete={handleFileDelete}
          disabled={disabled}
        />
      </Box>
    </DisabledCheckInTooltip>
  );
};

export default FileQuestion;
