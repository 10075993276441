import React, { useEffect, useState, ReactNode } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TextField, Box, Typography } from "@mui/material";
import Skeleton from "@mui/lab/Skeleton";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ADMIN_PROGRAMS_ROUTE } from "../../routes/routes";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import AdminService from "../../services/AdminService";
import { ProgramsTableRow } from "./ProgramsTableRow";

import { Users, Archive, DollarSign } from "lucide-react";
import { ToggleButtonWithTooltip } from "./ToogleButtonWithTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
    padding: 1,
    boxShadow: theme.shadows[4],
    marginBottom: theme.spacing(3),
  },

  tabs: {
    "& span": {
      fontWeight: "bold",
      fontSize: 14,
      textTransform: "uppercase",
    },
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.selected.main,
  },

  container: {
    margin: theme.spacing(3, 2),

    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(3, 6),
    },
  },

  searchInput: {
    "& input": {
      fontSize: 15,
      padding: theme.spacing(2),
      minWidth: theme.spacing(50),
    },
    marginBottom: theme.spacing(2),
  },

  toggleButton: {
    marginLeft: theme.spacing(1),
  },

  summary: {
    margin: theme.spacing(1.5, 0),
    fontWeight: 500,
    fontSize: 16,
  },

  table: {
    minWidth: 650,

    "& th": {
      fontWeight: "bold",
      fontSize: 15,
    },

    "& td": {
      fontWeight: 500,
      fontSize: 14,
    },

    "& tfoot td": {
      borderBottom: "none",
    },
  },
}));

export function parseBooleanString(str: string): boolean {
  return str?.toLowerCase() === "true";
}

export function toggleBooleanString(str: string): string {
  return str?.toLowerCase() === "true" ? "false" : "true";
}

export const ADMIN_PROGRAMS_QUERY_KEY = "admin-programs";

enum ProgramsTableType {
  LAST_UPDATED = "Last Updated",
  COMPONENT_DUPLICATES = "Component Duplicates",
  LENGTH_ISSUES = "Length Issues",
}

const useAdminProgramTabsQuery = (
  tab: ProgramsTableType,
  page: number,
  rowsPerPage: number,
  queryParams: any,
) => {
  const args = {
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    activeSubscription: parseBooleanString(queryParams.activeSubscription),
    publishedPrograms: parseBooleanString(queryParams.publishedPrograms),
    notArchivedComponents: parseBooleanString(
      queryParams.notArchivedComponents,
    ),
    searchQuery: queryParams.search,
  };

  const lastUpdated = useQuery({
    queryKey: [ADMIN_PROGRAMS_QUERY_KEY, { ...queryParams, type: tab }],
    queryFn: () => AdminService.getPrograms(args),
    placeholderData: keepPreviousData,
    enabled: tab === ProgramsTableType.LAST_UPDATED,
  });

  const componentDuplicates = useQuery({
    queryKey: [ADMIN_PROGRAMS_QUERY_KEY, { ...queryParams, type: tab }],
    queryFn: () => AdminService.getProgramsByComponentDuplicates(args),
    placeholderData: keepPreviousData,
    enabled: tab === ProgramsTableType.COMPONENT_DUPLICATES,
  });

  const lengthIssues = useQuery({
    queryKey: [ADMIN_PROGRAMS_QUERY_KEY, { ...queryParams, type: tab }],
    queryFn: () => AdminService.getProgramsByLengthIssues(args),
    placeholderData: keepPreviousData,
    enabled: tab === ProgramsTableType.LENGTH_ISSUES,
  });

  const querySwitch = {
    [ProgramsTableType.LAST_UPDATED]: lastUpdated,
    [ProgramsTableType.COMPONENT_DUPLICATES]: componentDuplicates,
    [ProgramsTableType.LENGTH_ISSUES]: lengthIssues,
  };

  return querySwitch[tab];
};

export function ProgramsTable() {
  const navigate = useNavigate();
  const s = useStyles();
  const [queryParams, setQueryParams] = useQueryParams({
    page: "0",
    perPage: "5",
    search: null,
    type: null,
    activeSubscription: "true",
    publishedPrograms: "true",
    notArchivedComponents: "true",
  });
  const page = parseInt(queryParams.page) || 0;
  const rowsPerPage = parseInt(queryParams.perPage) || 5;

  const [searchParams] = useSearchParams();

  const typeFromParam = searchParams.get("type");
  const selectedType =
    typeFromParam === null
      ? ProgramsTableType.LAST_UPDATED
      : (typeFromParam as ProgramsTableType);

  const querySearch = queryParams.search || "";
  const [search, setSearch] = useState(querySearch);

  const { data: programsData, isFetching } = useAdminProgramTabsQuery(
    selectedType,
    page,
    rowsPerPage,
    queryParams,
  );

  useEffect(() => {
    if (querySearch !== search) {
      const timer = setTimeout(() => {
        setQueryParams({
          search,
          page: "0",
        });
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [querySearch, search, setQueryParams]);

  const handleChangePage = (event, newPage) => {
    setQueryParams({
      page: newPage.toString(),
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({
      perPage: event.target.value,
      page: "0",
    });
  };

  const handleTabChange = (event, value) => {
    setSearch("");
    setQueryParams({
      type: value,
      page: "0",
      search: null,
    });
    navigate(
      ADMIN_PROGRAMS_ROUTE +
        `?page=0&type=${value}&perPage=${queryParams.perPage}`,
    );
  };

  const handleEnableArchivedComponentsToggle = () => {
    setQueryParams({
      notArchivedComponents: toggleBooleanString(
        queryParams.notArchivedComponents,
      ),
    });
  };

  const handleActiveSubscriptionToggle = () => {
    setQueryParams({
      activeSubscription: toggleBooleanString(queryParams.activeSubscription),
    });
  };

  const handlePublishedProgramsToggle = () => {
    setQueryParams({
      publishedPrograms: toggleBooleanString(queryParams.publishedPrograms),
    });
  };

  const emptyRows = Array.from(new Array(rowsPerPage));
  return (
    <Paper className={s.root}>
      <Tabs
        className={s.tabs}
        value={selectedType}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
      >
        <Tab
          label={ProgramsTableType.LAST_UPDATED.toString()}
          value={ProgramsTableType.LAST_UPDATED}
        />
        <Tab
          label={ProgramsTableType.COMPONENT_DUPLICATES.toString()}
          value={ProgramsTableType.COMPONENT_DUPLICATES}
        />
        <Tab
          label={ProgramsTableType.LENGTH_ISSUES.toString()}
          value={ProgramsTableType.LENGTH_ISSUES}
        />
      </Tabs>

      <Box className={s.container}>
        <TextField
          className={s.searchInput}
          variant="outlined"
          placeholder="Search Records"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <ToggleButtonWithTooltip
          className={s.toggleButton}
          value="archive"
          icon={<Archive />}
          toggleFlag={parseBooleanString(queryParams.notArchivedComponents)}
          onToggle={handleEnableArchivedComponentsToggle}
          tooltipTitle="Archive components"
          tooltipPlacement="top"
        />

        <ToggleButtonWithTooltip
          className={s.toggleButton}
          value="subscription"
          icon={<DollarSign />}
          toggleFlag={parseBooleanString(queryParams.activeSubscription)}
          onToggle={handleActiveSubscriptionToggle}
          tooltipTitle="Active coaches"
          tooltipPlacement="top"
        />

        <ToggleButtonWithTooltip
          className={s.toggleButton}
          value="published"
          icon={<Users />}
          toggleFlag={parseBooleanString(queryParams.publishedPrograms)}
          onToggle={handlePublishedProgramsToggle}
          tooltipTitle="Published programs"
          tooltipPlacement="top"
        />

        <Typography className={s.summary}>
          {programsData?.totalCount || 0} record
          {programsData?.totalCount === 1 ? "" : "s"} found
        </Typography>

        <Table className={s.table} aria-label="simple table">
          {isFetching ? (
            <>
              <TableHead>
                <TableRow>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {emptyRows.map((_, index) => (
                  <TableRow sx={{ height: 80 }} key={index}>
                    {Array.from({ length: 5 }).map((_, cellIndex) => (
                      <TableCell key={cellIndex} align="center">
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="center">Id</TableCell>
                  <TableCell align="center">Migration</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Length</TableCell>
                  <TableCell align="center">repeat duplicates</TableCell>
                  <TableCell align="center">same week duplicates</TableCell>
                  <TableCell align="center">Author</TableCell>
                  <TableCell align="center">Table updates</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {programsData.items.flatMap((p, index) => (
                  <ProgramsTableRow program={p} key={index} />
                ))}

                {!programsData.items.length && (
                  <TableRow>
                    <TableCell align="center" colSpan={8}>
                      No programs found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={8}
                count={programsData?.totalCount || 0}
                rowsPerPage={rowsPerPage}
                page={Number(page)}
                showFirstButton
                showLastButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </Paper>
  );
}
