import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  TextField,
  IconButton,
  IconButtonProps,
  ButtonProps,
  TextFieldProps,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActionButton, ActionButtonProps } from "../button/ActionButton";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { ReactComponent as BinIcon } from "../../icons/Bin.svg";
import { ProgramInviteCodeDto } from "@growth-machine-llc/stridist-api-client";
import LoadingActionButton from "../button/LoadingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },

  textLink: {},

  inviteLinkField: {
    flexGrow: 1,

    "$textLink &": {
      position: "absolute",
      left: -1000,
      top: -1000,
      opacity: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  inviteLinkText: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    fontWeight: 500,
  },

  generateLinkButton: {
    width: "45%",
  },

  copyLinkButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  deleteLinkButton: {
    color: theme.palette.text.secondary,
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.5),

    "[disabled] &": {
      color: theme.palette.text.secondary,
    },
  },
}));

export interface InviteLinkSettingsProps extends BoxProps {
  inviteCode: ProgramInviteCodeDto;
  generateCode: () => void;
  generatingCode: boolean;
  deleteCode: () => void;
  deletingCode: boolean;
  groupId?: number;
  programId?: number;
  textLink?: boolean;
  LinkFieldProps?: TextFieldProps;
  CopyButtonProps?: ActionButtonProps;
  RemoveButtonProps?: IconButtonProps;
  GenerateButtonProps?: ActionButtonProps;
  hideRemoveButton?: boolean;
  disabled?: boolean;
}

export function InviteLinkSettings(props: InviteLinkSettingsProps) {
  const {
    className,
    groupId,
    programId,
    textLink,
    inviteCode,
    LinkFieldProps,
    CopyButtonProps,
    RemoveButtonProps,
    GenerateButtonProps,
    generateCode,
    generatingCode,
    deleteCode,
    deletingCode,
    hideRemoveButton = false,
    ...other
  } = props;
  const s = useStyles();
  const inputRef = React.useRef<HTMLInputElement>();

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    React.useState(false);

  const handleCopyLink: ButtonProps["onClick"] = React.useCallback(() => {
    inputRef.current.select();
    document.execCommand("copy");
    inputRef.current.blur();
  }, []);

  const handleDeleteLink: ButtonProps["onClick"] = React.useCallback(() => {
    setConfirmDeleteDialogOpen(true);
  }, []);

  const handleConfirmDelete = React.useCallback(() => {
    setConfirmDeleteDialogOpen(false);
    deleteCode();
  }, [deleteCode]);

  const handleCancelDelete = React.useCallback(() => {
    setConfirmDeleteDialogOpen(false);
  }, []);

  const inviteLink = React.useMemo(() => {
    if (inviteCode?.code) {
      return `${window.location.origin}/invite/${inviteCode?.code}`;
    }
  }, [inviteCode?.code]);

  const disabled = generatingCode || deletingCode || props.disabled;

  return inviteLink ? (
    <Box className={clsx(s.root, textLink && s.textLink, className)} {...other}>
      {textLink && (
        <Typography className={s.inviteLinkText}>{inviteLink}</Typography>
      )}
      <TextField
        className={s.inviteLinkField}
        variant="outlined"
        value={inviteLink}
        contentEditable={false}
        inputRef={inputRef}
        disabled={disabled}
        {...LinkFieldProps}
      />
      <ActionButton
        className={s.copyLinkButton}
        size="large"
        children="Copy link"
        onClick={handleCopyLink}
        {...CopyButtonProps}
      />

      {!hideRemoveButton && (
        <>
          <IconButton
            className={s.deleteLinkButton}
            children={<BinIcon />}
            onClick={handleDeleteLink}
            disabled={disabled}
            {...(RemoveButtonProps as any)}
            size="large"
          />
          <ConfirmActionDialog
            title="Are you sure you want to delete this link?"
            description="Clients will no longer be able to use it to sign up."
            open={confirmDeleteDialogOpen}
            onClose={handleCancelDelete}
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        </>
      )}
    </Box>
  ) : (
    <LoadingActionButton
      className={s.generateLinkButton}
      size="large"
      children="Generate link"
      onClick={generateCode}
      disabled={disabled}
      {...GenerateButtonProps}
      loading={generatingCode}
    />
  );
}
