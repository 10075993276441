import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { ChevronDown, ChevronUp } from "lucide-react";
import React from "react";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { colorSystem } from "../../theme";
import { HighlightsPeriod } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderWidth: theme.shape.border.sm,
    borderColor: theme.palette.border.secondary,
    borderRadius: theme.shape.radius.md,
    gap: theme.spacing(1),
    flexWrap: "wrap",

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  stats: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  stat: {
    display: "flex",
    alignItems: "end",
    justifyContent: "center",
    gap: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textWrap: "nowrap",
  },

  caption: {
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    fontSize: "12px !important",
    lineHeight: "12px",
    height: "20px",
  },
}));

interface WorkoutHighlightsProps {
  clientId: number;
}

export default function WorkoutHighlights(props: WorkoutHighlightsProps) {
  const { data } = useQuery({
    queryKey: ["workoutHighlights", props.clientId],
    queryFn: async () =>
      EnrollmentsClientService.getWorkoutHighlights(
        HighlightsPeriod.LAST_7_DAYS,
        props.clientId,
      ),
  });

  const trainingDuration = dayjs.duration({
    minutes: data?.trainingMinutes.current || 0,
  });

  const trainingDurationIncrease = data?.trainingMinutes.prev
    ? trainingDuration.subtract(
        dayjs.duration({ minutes: data?.trainingMinutes.prev || 0 }),
      )
    : null;

  const volume =
    data?.weightVolume?.current != 0 || data?.weightVolume?.prev != 0
      ? data?.weightVolume
      : data?.timeVolume;

  const volumeIncrease = volume?.prev ? volume.current - volume?.prev : 0;

  const setsIncrease = data?.sets.prev
    ? data?.sets.current - data?.sets.prev
    : 0;

  const hasWorkouts =
    volume?.current > 0 ||
    data?.sets.current > 0 ||
    data?.trainingMinutes.current > 0;

  const getArrow = (value: number) => {
    if (value > 0) return <ChevronUp />;
    if (value < 0) return <ChevronDown />;
    return null;
  };

  const getColor = (value: number) => {
    if (value > 0) return colorSystem.green2;
    return colorSystem.border2;
  };

  const s = useStyles();
  return (
    data &&
    hasWorkouts && (
      <Box className={s.root}>
        <Box className={s.stat}>
          <Typography
            variant="body1"
            sx={{ lineHeight: 1.3, fontWeight: "bold" }}
            color="textPrimary"
          >
            Highlights
          </Typography>
          <Typography
            className={s.caption}
            sx={{ alignSelf: "flex-end" }}
            variant="caption"
            color={getColor(-1)}
          >
            last 7 days
          </Typography>
        </Box>

        <Box className={s.stats}>
          {trainingDuration && (
            <Box className={s.stat}>
              <Typography variant="body2" color="textPrimary">
                Training
              </Typography>
              <Typography
                variant="body1"
                sx={{ lineHeight: 1.3, fontWeight: "bold" }}
                color="textPrimary"
              >
                {Math.round(trainingDuration.asMinutes() / 60)}h{" "}
                {Math.round(trainingDuration.asMinutes() % 60)}min
              </Typography>
              {trainingDurationIncrease &&
                trainingDurationIncrease.asMinutes() !== 0 && (
                  <Typography
                    className={s.caption}
                    variant="caption"
                    color={getColor(trainingDurationIncrease.asMinutes())}
                  >
                    {getArrow(trainingDurationIncrease.asMinutes())}
                    {Math.abs(trainingDurationIncrease.asMinutes())}min
                  </Typography>
                )}
            </Box>
          )}

          <Box className={s.stat}>
            <Typography variant="body2" color="textPrimary">
              Volume
            </Typography>
            <Typography
              variant="body1"
              sx={{ lineHeight: 1.3, fontWeight: "bold" }}
              color="textPrimary"
            >
              {Math.round(volume.current)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              {volume?.units?.toLowerCase() || ""}
            </Typography>
            {volumeIncrease !== 0 && (
              <Typography
                className={s.caption}
                variant="caption"
                color={getColor(volumeIncrease)}
              >
                {getArrow(volumeIncrease)}
                {Math.abs(Math.round(volumeIncrease))
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                {volume?.units?.toLowerCase() || ""}
              </Typography>
            )}
          </Box>

          <Box className={s.stat}>
            <Typography variant="body2" color="textPrimary">
              Exercise
            </Typography>
            <Typography
              variant="body1"
              sx={{ lineHeight: 1.3, fontWeight: "bold" }}
              color="textPrimary"
            >
              {data.sets.current} sets
            </Typography>
            {setsIncrease !== 0 && (
              <Typography
                className={s.caption}
                variant="caption"
                color={getColor(setsIncrease)}
              >
                {getArrow(setsIncrease)}
                {Math.abs(setsIncrease)} sets
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    )
  );
}
