import React from "react";
import CoachComponentBarLoader from "../program-component/CoachComponentBarLoader";
import { grey } from "@mui/material/colors";
import { Box, CircularProgress } from "@mui/material";

const ComponentDialogSkeleton = ({ backClick }: { backClick?: () => void }) => {
  return (
    <>
      <CoachComponentBarLoader backClick={backClick} />
      <Box
        sx={{
          display: "flex",
          height: 1,
          color: grey[400],
        }}
      >
        <CircularProgress
          size={20}
          color={"inherit"}
          sx={{
            margin: "auto",
          }}
        />
      </Box>
    </>
  );
};

export default ComponentDialogSkeleton;
