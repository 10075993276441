import clsx from "clsx";
import React from "react";
import { Box, Button, ButtonProps, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ComponentStatus, ComponentType } from "../../constants";
import { CurriculumComponent } from "../../redux/types";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "nowrap",
  },
}));

export interface ComponentPublishButtonProps extends ButtonProps {
  componentData: Pick<CurriculumComponent, "status" | "draftExist" | "type">;
  empty: boolean;
  dirty: boolean;
}

export function ComponentPublishButton(props: ComponentPublishButtonProps) {
  const {
    className,
    componentData,
    empty,
    dirty,
    ...other
  } = props;
  const s = useStyles();
  const theme = useTheme();
  const { status, draftExist, type } = componentData;
  const draft = React.useMemo(() => status === ComponentStatus.DRAFT, [status]);
  const canPublishEmpty = [ComponentType.HABIT, ComponentType.MESSAGE].includes(
    type as ComponentType,
  );
  const disabled = draft ? empty && !canPublishEmpty : !draftExist || dirty;

  return (
    <Button
      className={clsx(s.root, className)}
      variant="contained"
      color="primary"
      sx={{
        backgroundColor: theme.palette.primary.main,
        minWidth: { xs: 110, md: 140 },
        minHeight: 48,
      }}
      children={
        draft ? (
          "Publish"
        ) : (
          <Box display={"flex"} flexDirection={"column"}>
            <Box mb={-0.5}>Publish</Box>
            <Box fontSize={8} mt={-0.5}>
              pending changes
            </Box>
          </Box>
        )
      }
      disabled={disabled || dirty}
      {...other}
    />
  );
}
