import { z } from "zod";
import {
  ClientSignupStep,
  Gender,
  setUnitsTypeOptions,
  UserAge,
} from "../constants";
import dayjs from "dayjs";
import { getStartEndDate } from "../components/fields/BirthdayField";
import { US_DATE_FORMAT } from "./date";

export type ClientSignupField =
  | "fullName"
  | "weight"
  | "height"
  | "location"
  | "birthday"
  | "gender"
  | "units"
  | "picture";

export const clientStepFields: Record<number, ClientSignupField[]> = {
  [ClientSignupStep.NAME_LOCATION]: ["fullName", "location"],
  [ClientSignupStep.METRICS]: ["weight", "height", "units"],
  [ClientSignupStep.BDAY_GENDER]: ["birthday", "gender"],
  [ClientSignupStep.PICTURE]: ["picture"],
};

export const clientCompleteSignUpSchema = () => {
  const { startDate, endDate } = getStartEndDate(UserAge.MIN, UserAge.MAX);
  const heightError = "Please enter a valid height";
  const weightError = "Please enter a valid weight";
  const birthdayDefaultError = "Please select a birthday";
  return z.object({
    // Step 1
    fullName: z.string().min(1, "Full name is required"),
    location: z.string().min(1, "Location is required"),
    // Step 2
    weight: z
      .number({
        required_error: weightError,
        invalid_type_error: weightError,
      })
      .min(1, weightError),
    height: z
      .number({
        required_error: heightError,
        invalid_type_error: heightError,
      })
      .min(1, heightError),
    units: z.enum(
      setUnitsTypeOptions.map((option) => option.value) as [
        string,
        ...string[],
      ],
      { errorMap: () => ({ message: "Please select a unit" }) },
    ),
    // Step 3
    birthday: z
      .string({
        required_error: birthdayDefaultError,
        invalid_type_error: birthdayDefaultError,
      })
      .regex(
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
        "Please select a valid birthday",
      )
      .refine((val) => {
        const date = dayjs(val, US_DATE_FORMAT);
        return date.isValid();
      }, birthdayDefaultError)
      .refine((val) => {
        const date = dayjs(val, US_DATE_FORMAT);
        return date.isAfter(startDate) || date.isSame(startDate);
      }, `Make sure the date is correct`)
      .refine((val) => {
        const date = dayjs(val, US_DATE_FORMAT);
        return date.isBefore(endDate) || date.isSame(endDate);
      }, `You must be at least ${UserAge.MIN} years old`),
    gender: z.nativeEnum(Gender, {
      errorMap: () => ({ message: "Please select a gender" }),
    }),
    // Step 4
    picture: z.string().nullable().optional(),
  });
};

export type ClientCompleteSignUpSchema = z.infer<
  ReturnType<typeof clientCompleteSignUpSchema>
>;
