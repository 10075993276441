import clsx from "clsx";
import React from "react";
import {
  Menu,
  MenuProps,
  MenuItem,
  ListItemIcon,
  ListItemText,
  MenuItemProps,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ComponentType } from "../../constants";
import { generateComponentDays, getContentType } from "../../utils/component";
import { colorSystem } from "../../theme";

import { ComponentTypeDefaultIcons } from "./icons";
import { defaultFilters, Filters } from "./filters/ComponentTypeFilter";
import { RowType } from "../../constants";
import { DEFAULT_ADD_MENU_DISABLED_ITEMS } from "../program-workout-spreadsheet/SpreadsheetCellRenderers/DragCell/InsertItemComponent";
import { useCurriculumDispatch } from "../../redux/hooks";
import {
  addComponent,
  addWorkoutExercise,
  addWorkoutSection,
} from "../../redux/curriculum/curriculum-slice";
import { generateNewWorkoutContent } from "../workout/utils";
import { CurriculumComponent } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { useProgramView } from "../../hooks/useProgramView";
import { ProgramDetailsViewMode } from "./ProgramDetailsViewButton";

const options = [
  {
    componentType: ComponentType.LESSON,
    description: "Introduce a new concept.",
    icon: ComponentTypeDefaultIcons[ComponentType.LESSON],
  },
  {
    componentType: ComponentType.HABIT,
    description: "Add a daily practice.",
    icon: ComponentTypeDefaultIcons[ComponentType.HABIT],
  },
  {
    componentType: ComponentType.CHECKIN,
    description: "Create a single or repeating survey.",
    icon: ComponentTypeDefaultIcons[ComponentType.CHECKIN],
  },
  {
    componentType: ComponentType.WORKOUT,
    description: "Add a workout component.",
    icon: ComponentTypeDefaultIcons[ComponentType.WORKOUT],
  },
  {
    componentType: RowType.WORKOUT_SECTION,
    description: "Add a section.",
    icon: ComponentTypeDefaultIcons[ComponentType.WORKOUT],
    indent: true,
  },
  {
    componentType: RowType.EXERCISE,
    description: "Add an exercise.",
    icon: ComponentTypeDefaultIcons[ComponentType.WORKOUT],
    indent: true,
  },
  {
    componentType: ComponentType.MESSAGE,
    description: "Send an automated messsage.",
    icon: ComponentTypeDefaultIcons[ComponentType.MESSAGE],
  },
];

const ARROW_SPACING = 1.25;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: theme.spacing(55),
  },

  paper: {
    borderRadius: theme.spacing(1.5),
    overflow: "visible",
    marginLeft: theme.spacing(-1),
    boxShadow: "none",
    filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.25))",

    "$arrowTop &::before, $arrowBottom &::before": {
      width: 20,
      height: 10,
      content: "''",
      position: "absolute",
      left: `calc(50% - ${theme.spacing(ARROW_SPACING / 2)})`,
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
    },

    "$arrowTop &::before": {
      clipPath: "polygon(0 100%, 50% 0, 100% 100%)",
      top: theme.spacing(-ARROW_SPACING),
    },

    "$arrowBottom &::before": {
      clipPath: "polygon(50% 100%, 100% 0, 0 0)",
      bottom: theme.spacing(-ARROW_SPACING),
    },
  },

  item: {
    padding: theme.spacing(2, 3),

    "&:hover": {
      backgroundColor: colorSystem.white2,
    },
  },

  primaryText: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  secondaryText: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.common.black,
  },

  itemIcon: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    backgroundColor: colorSystem.secondaryGray,
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.text.secondary,
  },

  arrowTop: {},
  arrowBottom: {},
}));

export interface AddComponentMenuProps extends MenuProps {
  withInitData?: boolean;
  enableDetailedWorkout?: boolean;
  arrow?: "top" | "bottom";
  filters?: Filters;
  disabledItems?: Filters;
  weekId: number;
  dayOfWeek?: number;
  componentId?: number;
  workoutSectionId?: number;
  exerciseId?: number;
}

export function AddComponentMenu(props: AddComponentMenuProps) {
  const {
    withInitData,
    enableDetailedWorkout,
    className,
    arrow,
    filters = defaultFilters,
    disabledItems = DEFAULT_ADD_MENU_DISABLED_ITEMS,
    weekId,
    dayOfWeek,
    componentId,
    workoutSectionId,
    exerciseId,
    ...other
  } = props;
  const dispatch = useCurriculumDispatch();
  const navigate = useNavigate();
  const s = useStyles();

  const VIEW = useProgramView();

  const getComponentInitContent = (componentType: ComponentType) => {
    switch (componentType) {
      case ComponentType.WORKOUT:
        return JSON.stringify(generateNewWorkoutContent(1, 2));
      default:
        return;
    }
  };

  const closeMenu = () => other.onClose({}, "backdropClick");

  const openComponentDialog = (slug: string) => {
    navigate(`?component=${slug}`, { replace: true });
  };

  const handleClick: MenuItemProps["onClick"] = (event) => {
    const { componentType } = event.currentTarget.dataset;

    closeMenu();

    switch (componentType) {
      case RowType.WORKOUT_SECTION:
        return dispatch(addWorkoutSection(componentId, workoutSectionId));

      case RowType.EXERCISE:
        return dispatch(
          addWorkoutExercise(componentId, workoutSectionId, exerciseId),
        );

      default:
        const initialComponentData: CurriculumComponent = {
          days: generateComponentDays(dayOfWeek),
          content: withInitData
            ? getComponentInitContent(componentType as ComponentType)
            : `[]`,
        };
        const action = dispatch(
          addComponent({
            weekId,
            componentType: componentType as ComponentType,
            initialComponentData,
          }),
        );

        !(
          componentType === ComponentType.WORKOUT &&
          VIEW === ProgramDetailsViewMode.SPREADSHEET
        ) && openComponentDialog(action.payload.newComponent.slug);

        return;
    }
  };

  return (
    <Menu
      className={clsx(s.root, className, {
        [s.arrowTop]: arrow === "top",
        [s.arrowBottom]: arrow === "bottom",
      })}
      classes={{ paper: s.paper }}
      onClose={closeMenu}
      {...other}
    >
      {options.map(
        ({ componentType, description, icon: Icon, indent }, index) => {
          const shouldRender =
            filters[componentType] &&
            (componentType === RowType.EXERCISE ||
            componentType === RowType.WORKOUT_SECTION
              ? enableDetailedWorkout && filters[ComponentType.WORKOUT]
              : true);

          return shouldRender ? (
            <React.Fragment key={componentType}>
              {!indent && index !== 0 && <Divider />}
              <MenuItem
                className={s.item}
                data-component-type={componentType}
                onClick={handleClick}
                sx={{ ml: indent ? 4 : 0 }}
                disabled={disabledItems[componentType]}
              >
                <ListItemIcon
                  className={s.itemIcon}
                  children={<Icon className={s.icon} />}
                />

                <ListItemText
                  classes={{
                    primary: s.primaryText,
                    secondary: s.secondaryText,
                  }}
                  primary={getContentType(componentType)}
                  secondary={description}
                />
              </MenuItem>
            </React.Fragment>
          ) : (
            <></>
          );
        },
      )}
    </Menu>
  );
}
