import React, { useRef } from "react";
import {
  bindPopper,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import {
  Box,
  BoxProps,
  Button,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { ReactComponent as EditIcon } from "../../icons/PencilOutline.svg";
import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker } from "../fields/DatePicker";
import {
  floatNumberInputProps,
  MeasurementTypeInfo,
  MeasurementUnit,
  getUnitLabel,
} from "../../constants";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";
import { MeasurementStep, MetricType } from "./MeasurementDrawer";
import dayjs from "dayjs";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";
import LoadingActionButton from "../button/LoadingActionButton";
import { parseAsFloat } from "../client-measurement/ClientMeasurementRow";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.75, 0, 1.75, 0),
    height: theme.spacing(8.5),
  },

  text: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 600,

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },

  date: {
    fontWeight: 500,
    whiteSpace: "nowrap",
    fontSize: 14,
    width: "100%",
    maxWidth: 100,

    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
  },

  editValue: {
    marginRight: "auto",
    width: theme.spacing(7.5),

    [theme.breakpoints.up("md")]: {
      width: theme.spacing(8),
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  measurement: {
    flex: 1,
    textAlign: "right",
    marginLeft: 10,
  },

  actions: {
    whiteSpace: "nowrap",
    flex: 1,
    minWidth: 140,
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.up("sm")]: {
      minWidth: 200,
    },

    "& button": {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
    },

    "& button svg path": {
      strokeWidth: 1,
    },

    "& button$save, & button$cancel": {
      fontSize: 14,
      fontWeight: 700,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.75, 1),

      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0.75, 2),
      },
    },

    "& button$save": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.progress.green,

      "&:disabled": {
        backgroundColor: theme.palette.text.secondary,
      },
    },

    "& button$cancel": {
      color: theme.palette.common.black,
    },
  },

  iconButton: {
    "& svg": {
      width: theme.spacing(2.75),
      height: theme.spacing(2.75),
    },
  },

  dateTextField: {
    width: "100%",
    maxWidth: 100,
  },

  inputRoot: {
    width: "inherit",
  },

  cancel: {
    fontSize: 14,
    fontWeight: 700,
  },

  save: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0.5),
    },
  },
}));

export interface ClientStepsHistoryRowProps extends BoxProps {
  editing?: boolean;
  waitingForEditConfirmation?: boolean;
  handleEditing: () => void;
  onCancel: () => void;
  editingDate: boolean;
  value: MeasurementStep;
  setEditingDate: (value: boolean) => void;
  editingValue: boolean;
  setEditingValue: (value: boolean) => void;
  onSave: (
    { id, unit, date, name, measurement, index }: MetricType,
    onSettled?: () => void,
  ) => void;
  index: number;
  disabled: boolean;
  onRemove: ({ id, index }: MetricType) => void;
  setNewHistory: (value: null) => void;
  newHistory: MeasurementStep;
  today: any;
  unit?: MeasurementUnit;
  selectedType?: MeasurementTypeInfo;
  isBodyMeasurement?: boolean;
}

const ClientBodyWeightHistoryRow = (props: ClientStepsHistoryRowProps) => {
  const {
    editing,
    waitingForEditConfirmation,
    handleEditing,
    onCancel,
    editingDate,
    value,
    setEditingDate,
    editingValue,
    setEditingValue,
    onSave,
    index,
    disabled,
    onRemove,
    setNewHistory,
    newHistory,
    today,
    isBodyMeasurement = false,
    unit = null,
    selectedType = null,
  } = props;
  const s = useStyles();
  const toggleDatePickerState = usePopupState({
    variant: "popover",
    popupId: "date-picker",
  });
  const dateTextFieldRef = useRef(null);

  const [stepValue, setStepValue] = React.useState<number | string>(
    value.value,
  );
  const [date, setDate] = React.useState<dayjs.Dayjs>(
    value.measuredOn as dayjs.Dayjs,
  );
  const [dirty, setDirty] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const handleDateClick = React.useCallback(() => {
    if (editing) {
      setEditingDate(true);
      setTimeout(() => {
        toggleDatePickerState.open(dateTextFieldRef.current);
      }, 0);
    }
  }, [editing, setEditingDate]);

  const handleValueClick = React.useCallback(() => {
    if (editing) {
      setEditingValue(true);
      setEditingDate(false);
    }
  }, [editing, setEditingValue, setEditingDate]);

  const handleValueBlur = React.useCallback(() => {
    setEditingValue(false);
  }, [setEditingValue]);

  const handleChangeMeasurement = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (parseAsFloat(event.target.value) === 0) {
        setStepValue(event.target.value);
        setDirty(false);
      } else {
        const value = parseAsFloat(event.target.value);
        setStepValue(value);
        setDirty(true);
      }
    },
    [],
  );

  const handleCancel = React.useCallback(() => {
    setStepValue(value.value || "");
    setDate(value.measuredOn as dayjs.Dayjs);
    setDirty(false);
    if (newHistory) setNewHistory(null);
    onCancel();
  }, [value, onCancel, newHistory, setNewHistory]);

  const handleDatePickerChange = React.useCallback(
    (selectedDate: any) => {
      setDate(dayjs(selectedDate).startOf("day"));
      setDirty(true);
      toggleDatePickerState.close();
    },
    [toggleDatePickerState],
  );

  const handleRemoveClick = React.useCallback(() => setConfirmDelete(true), []);

  const handleCancelDelete = React.useCallback(
    () => setConfirmDelete(false),
    [],
  );

  const handleSave = React.useCallback(() => {
    const handleSettled = () => {
      setDirty(false);
      if (newHistory) setNewHistory(null);
      onCancel();
    };
    onSave(
      {
        id: value?.id,
        date,
        measurement: parseFloat(stepValue.toString()),
        name: isBodyMeasurement ? selectedType.name : null,
        index: today?.id ? index : index - 1,
        history: true,
        unit: unit as MeasurementUnit,
      },
      handleSettled,
    );
  }, [date, onSave, stepValue, value, index, handleCancel, today]);

  const handleDelete = React.useCallback(() => {
    onRemove({
      id: value?.id,
      index: today?.id ? index : index - 1,
    });
    handleCancelDelete();
  }, [value, index, handleCancelDelete, onRemove, today]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSave();
      }
    },
    [handleSave],
  );

  React.useEffect(() => {
    if (editing) {
      setEditingValue(true);
    } else {
      setStepValue(value.value);
      setDate(value.measuredOn as dayjs.Dayjs);
      setDirty(false);
    }
  }, [editing, setEditingValue, value]);

  return (
    <OptimisticUpdateContainer id={value?.id}>
      <Box className={clsx(s.root)}>
        {editing && editingDate ? (
          <>
            <TextField
              variant="standard"
              className={s.date}
              value={dayjs(date).format("DD/MM/YYYY")}
              inputRef={dateTextFieldRef}
              {...bindTrigger(toggleDatePickerState)}
              autoFocus
              fullWidth
              slotProps={{
                input: {
                  classes: {
                    input: s.text,
                  },
                },
              }}
            />

            <Popover {...bindPopper(toggleDatePickerState)}>
              <ClickAwayListener
                onClickAway={toggleDatePickerState.close}
                mouseEvent={"onMouseUp"}
              >
                <div>
                  <DatePicker
                    value={value?.measuredOn.toDate()}
                    onChange={handleDatePickerChange}
                    maxDate={dayjs().subtract(1, "day").startOf("day")}
                  />
                </div>
              </ClickAwayListener>
            </Popover>
          </>
        ) : (
          <Typography
            className={clsx(s.text, s.date)}
            onClick={handleDateClick}
          >
            {dayjs(date).format("MMM DD, YYYY")}
          </Typography>
        )}
        <Box className={s.measurement}>
          {editing && editingValue && !waitingForEditConfirmation ? (
            <TextField
              variant="standard"
              className={s.editValue}
              value={stepValue}
              onChange={handleChangeMeasurement}
              onKeyDown={handleKeyDown}
              onBlur={handleValueBlur}
              autoFocus
              type="number"
              slotProps={{
                input: {
                  classes: {
                    input: s.text,
                  },
                  endAdornment: (
                    <InputAdornment
                      classes={{ positionEnd: s.text }}
                      disableTypography
                      position="end"
                      children={unit ? getUnitLabel(unit) : ""}
                    />
                  ),
                  inputProps: floatNumberInputProps,
                },
              }}
            />
          ) : (
            <>
              <Typography className={s.text} onClick={handleValueClick}>
                {stepValue} {unit ? ` ${getUnitLabel(unit)}` : ""}
              </Typography>
            </>
          )}
        </Box>
        <Box className={s.actions}>
          {editing || waitingForEditConfirmation ? (
            <>
              <Button
                className={s.cancel}
                variant="text"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <LoadingActionButton
                className={s.save}
                variant="contained"
                onClick={handleSave}
                disabled={!dirty}
                loading={waitingForEditConfirmation}
              >
                Save
              </LoadingActionButton>
            </>
          ) : (
            <>
              <IconButton
                className={s.iconButton}
                children={<EditIcon />}
                onClick={handleEditing}
                size="large"
              />
              <IconButton
                className={s.iconButton}
                children={<DeleteIcon />}
                onClick={handleRemoveClick}
                disabled={disabled}
                size="large"
              />
            </>
          )}
        </Box>
        <ConfirmActionDialog
          title="Are you sure you want to remove this result?"
          onCancel={handleCancelDelete}
          onConfirm={handleDelete}
          open={confirmDelete}
          disabled={disabled}
        />
      </Box>
    </OptimisticUpdateContainer>
  );
};

export default ClientBodyWeightHistoryRow;
