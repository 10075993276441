import React, { Suspense, useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { PageSkeleton } from "../../../components/loading/PageSkeleton";
import { SkeletonCard } from "../../../components/card/SkeletonCard";
import { Box, Breakpoint, Container } from "@mui/material";
import { COACH_CLIENTS_ROUTE } from "../../../routes/routes";
import CommonSuspenseWrapper from "../client/CommonSuspenseWrapper";
import { ClientContext as ClientHookContext } from "../../../hooks/useClient";
import ClientsService from "../../../services/ClientsService";
import { ClientContext } from "../../../contexts/ClientContext";
import LayoutLoadingContext from "../../../contexts/LayoutLoadingContext";
import { useQuery } from "@tanstack/react-query";
import { DefaultLoader } from "../../../components/loading/DefaultLoader";

export const COACH_CLIENT_GENERIC_QUERY_KEY = "coach-client-generic";

const CoachClientWrapper = () => {
  const location = useLocation();
  const { username } = useParams();

  const { data: client, isLoading } = useQuery({
    queryKey: [COACH_CLIENT_GENERIC_QUERY_KEY, { username }],
    queryFn: () => ClientsService.getBriefClientInfo(username),
    enabled: !!username,
  });

  const context = React.useContext(ClientContext);
  const { setLoading: setClientLoading } =
    React.useContext(LayoutLoadingContext);

  React.useEffect(() => {
    // TODO_API_V2_NOTES: Consider refactoring loading logic for AppLayout.
    // Currently may cause delays when client is get from cache instead of API
    setClientLoading(true);
    if (client) {
      context.setCoachClientQuery({ client: client });
      setClientLoading(false);
    }

    // reset context on unmount
    return () => {
      context.resetCoachClientQuery();
    };
  }, [client]);

  const maxWidth: Breakpoint = React.useMemo(() => {
    if (
      location.pathname.includes(COACH_CLIENTS_ROUTE) &&
      (location.pathname.includes("overview") ||
        location.pathname.includes("programs") ||
        location.pathname.includes("notes") ||
        location.pathname.includes("calendar") ||
        location.pathname.includes("response"))
    ) {
      return "lg";
    }
    return undefined;
  }, [location.pathname]);

  return isLoading ? (
    <Box
      sx={{
        position: "relative",
        height: "80vh",
      }}
    >
      <DefaultLoader fillParent fullWidth />
    </Box>
  ) : (
    <ClientHookContext.Provider value={client}>
      {CommonSuspenseWrapper({ maxWidth })}
    </ClientHookContext.Provider>
  );
};

export default CoachClientWrapper;
