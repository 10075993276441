import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";
import { MenuType } from "../WeekComponentMenu";

interface IComponentMenuItem {
  text: string;
  icon: ReactNode;
  onClick?: (event: any) => void;
  tooltipTitle?: string;
  dataMenu?: MenuType;
  disabled?: boolean;
}
const ComponentMenuItem = (props: IComponentMenuItem) => {
  const { text, icon, onClick, tooltipTitle, disabled, dataMenu } = props;
  const theme = useTheme();

  const Item = () => {
    return (
      <MenuItem onClick={onClick} disabled={disabled} data-menu={dataMenu}>
        <ListItemIcon sx={{ color: theme.palette.common.black }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </MenuItem>
    );
  };
  return (
    <>
      {tooltipTitle ? (
        <Tooltip
          arrow
          placement="left"
          title={tooltipTitle}
          data-menu={dataMenu}
        >
          <div>
            {Item()}
          </div>
        </Tooltip>
      ) : (
        Item()
      )}
    </>
  );
};

export default ComponentMenuItem;
