import { Box, Divider, Menu, ToggleButton } from "@mui/material";
import { Filter } from "lucide-react";
import React from "react";
import { FILTER_ICON_SIZE } from "../../screen/CurriculumEditScreen";
import FilterMenuItem, { FilterMenuTitle } from "./components/FilterMenuItem";
import { getContentType } from "../../../utils/component";
import { ComponentType, componentTypes } from "../../../constants";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import {
  isComponentTypeFilterApplied,
  Filters,
  WEEK_DAYS,
} from "./ComponentTypeFilter";
import MinimizedTooltip from "../../tooltip/MinimizedTooltip";

interface IFilterButtonProps {
  filters: Filters;
  onChange: (filters: Filters) => void;
  weekDaysFilter: number[];
  setWeekDaysFilter: React.Dispatch<React.SetStateAction<number[]>>;
  showDaysFilter: boolean;
}

const FilterButton = (props: IFilterButtonProps) => {
  const {
    filters,
    onChange,
    weekDaysFilter,
    setWeekDaysFilter,
    showDaysFilter,
  } = props;

  const menuState = usePopupState({
    variant: "popover",
    popupId: "type-filter",
  });

  const handleDirectClick = (
    event: React.MouseEvent<
      HTMLDivElement | HTMLLIElement | HTMLLabelElement,
      MouseEvent
    >,
    type: ComponentType,
  ) => {
    event.stopPropagation();
    if (onChange) {
      const newFilter = {
        ...filters,
        [type]: !filters[type],
      };
      onChange(newFilter);
    }
  };

  const IS_FILTER_APPLIED = isComponentTypeFilterApplied(filters);

  const handleItemClick = (
    event: React.MouseEvent<
      HTMLDivElement | HTMLLIElement | HTMLLabelElement,
      MouseEvent
    >,
    value: number,
  ) => {
    event.stopPropagation();
    setWeekDaysFilter((arr) => {
      if (arr.includes(value)) {
        // prevent unselect last
        if (arr.length === 1) return arr;
        return arr.filter((item) => item !== value);
      } else {
        return [...arr, value];
      }
    });
  };

  return (
    <>
      <MinimizedTooltip
        title={`Filter visible components by type ${showDaysFilter ? "or hide visible day" : ""}`}
        placement="top"
      >
        <ToggleButton
          color="primary"
          value={"filter"}
          selected={IS_FILTER_APPLIED}
          size={"small"}
          {...bindTrigger(menuState)}
        >
          <Filter size={FILTER_ICON_SIZE} />
        </ToggleButton>
      </MinimizedTooltip>

      <Menu
        {...bindMenu(menuState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display={"flex"}>
          <Box>
            <FilterMenuTitle text="Type" />
            {componentTypes.map((type) => (
              <FilterMenuItem
                item={type}
                label={`${getContentType(type)}s`}
                checked={filters[type]}
                onClick={(e) => handleDirectClick(e, type)}
              />
            ))}
          </Box>
          {showDaysFilter && <Divider orientation="vertical" flexItem />}
          {showDaysFilter && (
            <Box>
              <FilterMenuTitle text="Days" />
              {WEEK_DAYS.map((value) => (
                <FilterMenuItem
                  item={value}
                  preLabel={"Day"}
                  label={`${value}`}
                  checked={weekDaysFilter.includes(value)}
                  onClick={(e) => handleItemClick(e, value)}
                />
              ))}
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
};

export default FilterButton;
