import {
  Box,
  Checkbox,
  ListItem,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";

interface IFilterMenuTitleProps {
  text: string;
  sx?: SxProps;
}

export const FilterMenuTitle = ({ text, sx }: IFilterMenuTitleProps) => {
  const theme = useTheme();

  return (
    <ListItem
      sx={{
        paddingLeft: 1.25,
        color: theme.palette.text.secondary,
        fontSize: 14,
        fontWeight: 600,
        pb: 0,
        ...sx,
      }}
    >
      {text}
    </ListItem>
  );
};

interface IFilterMenuItemProps<T> {
  item: T;
  label: string;
  preLabel?: string;
  checked: boolean;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  endIcon?: ReactNode;
}

const FilterMenuItem = <T,>(props: IFilterMenuItemProps<T>) => {
  const { item, preLabel, label, checked, disabled, onClick, endIcon } = props;

  const theme = useTheme();

  return (
    <>
      <Box
        onClick={!disabled && onClick}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",

          ...(!disabled && {
            ":hover": {
              cursor: "pointer",
              background: theme.palette.selected.main,
            },
          }),
          pr: 2,
        }}
      >
        <Checkbox
          color="primary"
          value={item}
          checked={checked}
          disabled={disabled}
        />
        <Box display={"flex"} gap={3} width={"100%"}>
          {preLabel && (
            <Typography
              variant="body1"
              fontWeight={600}
              color={
                disabled
                  ? theme.palette.grey[300]
                  : theme.palette.text.secondary
              }
            >
              {preLabel}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{
              textTransform: "capitalize",
              fontWeight: preLabel ? 700 : 600,
              color: disabled
                ? theme.palette.grey[300]
                : preLabel
                  ? "unset"
                  : theme.palette.text.secondary,
            }}
          >
            {label}
          </Typography>
          {endIcon && <Box marginLeft={"auto"}>{endIcon}</Box>}
        </Box>
      </Box>
    </>
  );
};

export default FilterMenuItem;
