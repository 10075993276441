import React from "react";
import GenericScheduleSelect, {
  IScheduleSelectProps,
} from "./generic/GenericScheduleSelect";
import { MenuItem } from "@mui/material";
import { ComponentRepeat } from "../../../../constants";
import { ProgramWeek } from "../../../../hooks/useProgramWeeks";
import { TILL_THE_END_OF_THE_PROGRAM } from "../../../program-component/utils";

const DEFAULT_REPEAT_OPTION = ComponentRepeat.WEEKLY;
const DEFAULT_DURATION_OPTION = TILL_THE_END_OF_THE_PROGRAM;

const REPEATS_SELECT_ASSETS = {
  [ComponentRepeat.NONE]: { title: "This week only" },
  [ComponentRepeat.WEEKLY]: { title: "Every week" },
  [ComponentRepeat.BIWEEKLY]: { title: "Every 2" },
  [ComponentRepeat.EVERY_3_WEEKS]: { title: "Every 3" },
  [ComponentRepeat.EVERY_4_WEEKS]: { title: "Every 4" },
};

interface IRepeatsSelectProps {
  weeks: ProgramWeek[];
  handleRepeatsToggle: (result: ComponentRepeat, duration: number) => void;
  flashInputs?: boolean;
  tooltipTitle?: React.ReactNode;
}

const RepeatsSelect = (
  props: IScheduleSelectProps<ComponentRepeat> & IRepeatsSelectProps,
) => {
  const {
    value,
    onChange,
    weeks,
    handleRepeatsToggle,
    disabled,
    tooltipTitle,
  } = props;

  const IS_REPEATED = value !== ComponentRepeat.NONE;

  const handleSwitchOnChange = (checked: boolean) => {
    // No repeat
    if (!checked) handleRepeatsToggle(ComponentRepeat.NONE, 1);
    // Return to default
    if (checked)
      handleRepeatsToggle(DEFAULT_REPEAT_OPTION, DEFAULT_DURATION_OPTION);
  };

  return (
    <>
      <GenericScheduleSelect
        title="Repeats"
        value={value}
        onChange={onChange}
        checked={IS_REPEATED}
        text={!IS_REPEATED && "scheduled for single week"}
        handleSwitchOnChange={handleSwitchOnChange}
        disabled={disabled}
        flashInputs={props.flashInputs}
        tooltipTitle={tooltipTitle}
      >
        {Object.values(ComponentRepeat).map((value) => {
          return (
            <MenuItem
              key={value}
              value={value}
              children={REPEATS_SELECT_ASSETS[value].title}
            />
          );
        })}
      </GenericScheduleSelect>
    </>
  );
};

export default RepeatsSelect;
