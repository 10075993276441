import React from "react";
import GenericScheduleSelect, {
  IScheduleSelectProps,
} from "./generic/GenericScheduleSelect";
import { Box, MenuItem } from "@mui/material";
import { ProgramWeek } from "../../../../hooks/useProgramWeeks";
import { ComponentRepeat, ComponentRepeatValues } from "../../../../constants";
import { TILL_THE_END_OF_THE_PROGRAM } from "../../../program-component/utils";

interface IEndWeekSelectProps {
  weeks: ProgramWeek[];
  startWeek: number;
  repeat: ComponentRepeat;
  handleEndWeekToggle: (duration: number) => void;
  hidden?: boolean;
  flashInputs?: boolean;
}

const EndWeekSelect = (
  props: IScheduleSelectProps<number> & IEndWeekSelectProps,
) => {
  const {
    value,
    onChange,
    weeks,
    startWeek,
    repeat,
    handleEndWeekToggle,
    hidden,
    disabled,
    flashInputs,
  } = props;
  const startWeekIndex = weeks.find((w) => w.id === startWeek).week - 1;
  const increment = ComponentRepeatValues[repeat] || 0;

  const WEEKS_LENGTH = weeks.length;
  const IS_TILL_THE_END = !weeks.find((w) => w.id === value);

  const handleSwitchOnChange = (checked: boolean) => {
    // Till the end
    if (!checked) handleEndWeekToggle(TILL_THE_END_OF_THE_PROGRAM);

    const DEFAULT_INIT_DURATION =
      (WEEKS_LENGTH - startWeekIndex) / increment > 1 ? 2 : 1;

    // Set default init value
    if (checked) handleEndWeekToggle(DEFAULT_INIT_DURATION);
  };

  return (
    <Box visibility={hidden ? "hidden" : "visible"}>
      <GenericScheduleSelect
        title="End week"
        value={value}
        onChange={onChange}
        checked={repeat !== ComponentRepeat.NONE && !IS_TILL_THE_END}
        handleSwitchOnChange={handleSwitchOnChange}
        text={IS_TILL_THE_END && "till program end"}
        disabled={disabled}
        flashInputs={flashInputs}
      >
        {weeks.map((week, index) => (
          <MenuItem
            value={week.id}
            disabled={
              // By repeat frequency
              (index - startWeekIndex) % increment !== 0 ||
              // Everything before start week
              startWeekIndex > index
            }
          >
            Week {week.week}
          </MenuItem>
        ))}
      </GenericScheduleSelect>
    </Box>
  );
};

export default EndWeekSelect;
