import React from "react";
import { HeaderButton } from "../../button/HeaderButton";
import { useNavigate } from "react-router-dom";
import { CLIENT_PROGRAMS_ACTIVE_ROUTE } from "../../../routes/routes";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { UserRole } from "../../../constants";

interface ExitWorkoutButtonProps {
  fullWidth?: boolean;
}

export default function ExitWorkoutButton(props: ExitWorkoutButtonProps) {
  const navigate = useNavigate();
  const user = useCurrentUser();

  const isCoach = user.role === UserRole.COACH;

  return (
    <HeaderButton
      fullWidth
      variant="contained"
      onClick={() =>
        isCoach ? navigate(-1) : navigate(CLIENT_PROGRAMS_ACTIVE_ROUTE)
      }
      {...props}
    >
      Back to program
    </HeaderButton>
  );
}
