import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import SidebarContent from "./SidebarContent";
import { useProgramView } from "../../hooks/useProgramView";
import { ProgramDetailsViewMode } from "../program/ProgramDetailsViewButton";
import { getCommonTransition } from "../../utils/mui";

export const CURRICULUM_SIDEBAR_WIDTH = 324;
export const SIDEBAR_HEADING_HEIGHT = 35;
export const SIDEBAR_BOTTOM_NAVIGATION_HEIGHT = 56;

interface ISidebarProps {
  visibleWeeks?: number[];
}

const COMPONENT_ID = "sidebar-container";

const Sidebar = (props: ISidebarProps) => {
  const { visibleWeeks } = props;
  const { isOpen, componentSlug } = useSidebar();
  const theme = useTheme();
  const viewMode = useProgramView();
  const [maxHeight, setMaxHeight] = useState<"unset" | number>("unset");

  const IS_SHEETS = viewMode === ProgramDetailsViewMode.SPREADSHEET;

  const SIDEBAR_MARGIN = 4;
  const SIDEBAR_MARGIN_INT_VALUE = parseInt(theme.spacing(SIDEBAR_MARGIN));

  //
  //
  // RESIZE ON SCROLL

  useEffect(() => {
    const handleScroll = () => {
      // get element
      const element = document.getElementById(COMPONENT_ID);

      if (element) {
        // calc max height based on space to top include margins
        const { top } = element.getBoundingClientRect();
        const maxHeight = window.innerHeight - top - SIDEBAR_MARGIN_INT_VALUE;

        // prevent overflow
        if (window.innerHeight - 2 * SIDEBAR_MARGIN_INT_VALUE > maxHeight) {
          setMaxHeight(maxHeight);
        }
      }
    };

    // init calculation
    handleScroll();

    // event listeners
    document?.getElementById("root")?.addEventListener("scroll", handleScroll);
    return () =>
      document
        ?.getElementById("root")
        ?.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box
      id={COMPONENT_ID}
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        position: "sticky",

        height: IS_SHEETS ? 1 : "unset",
        width: CURRICULUM_SIDEBAR_WIDTH,
        minHeight: 600,
        maxHeight: maxHeight,
        maxWidth: CURRICULUM_SIDEBAR_WIDTH,
        top: theme.spacing(4),
        zIndex: isOpen ? theme.zIndex.appBar : theme.zIndex.speedDial,

        marginTop: SIDEBAR_MARGIN,
        marginRight: isOpen ? SIDEBAR_MARGIN : 0,
        marginBottom: SIDEBAR_MARGIN,
        marginLeft: "auto",

        borderRadius: 2,
        border: IS_SHEETS ? 0 : 1,
        borderColor: theme.palette.divider,
        background: theme.palette.common.white,
        boxShadow: IS_SHEETS && theme.shadows[5],

        opacity: isOpen ? 1 : 0,
        transition: getCommonTransition(theme, ["opacity", "max-height"]),
      }}
    >
      <SidebarContent slug={componentSlug} visibleWeeks={visibleWeeks} />
    </Box>
  );
};

export default Sidebar;
