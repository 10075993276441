import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  activityCountItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export interface AdminProgramMetricProps {
  label: string;
  value: string;
}

const AdminProgramMetric = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.activityCountItem}>
      <Typography variant="body2">
        <strong>{label}:</strong>
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

export default AdminProgramMetric;
