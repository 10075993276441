import { ComponentRepeat, ComponentRepeatValues } from "../../constants";
import { ProgramWeek } from "../../hooks/useProgramWeeks";
import { Schedule } from "../schedule/types";
import { OverridesWithCoordinatesType } from "./CoachComponent";

// TODO: Move to a more appropriate location, since utility used in multiple places
// and we have to be careful with used APIs here
export function getCookie(name: string): string | null {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  return null;
}

export const countComponentDays = (days: string) => {
  return JSON.parse(days).filter((value) => value).length;
};

export const countScheduleRepeats = (
  schedule: Schedule,
  startWeek: number,
  programLength: number,
  overrides: OverridesWithCoordinatesType[],
): number => {
  // week numbers arr
  const weeks = getRecurrentComponentWeeks(
    startWeek,
    programLength,
    schedule.repeat,
    schedule.duration ?? programLength,
  );

  // day numbers arr
  const days = schedule.days
    .map((value, index) => (value ? index + 1 : null))
    .filter((index) => index !== null);

  // count base grid (weeks * days)
  const baseComponentsWeekDaysGrid = weeks.length * days.length;

  let totalItems = baseComponentsWeekDaysGrid;

  // add overrides that don't fit in base grid
  overrides.forEach((override) => {
    if (!days.includes(override.day) || !weeks.includes(override.weekNumber))
      totalItems += 1;
  });

  return totalItems;
};

export const getRecurrentComponentWeeks = (
  startWeek: number,
  endWeek: number,
  repeat: ComponentRepeat,
  duration: number,
): number[] => {
  const increment = ComponentRepeatValues[repeat];

  const weeks = [];
  let week = startWeek;

  while (
    week <= endWeek &&
    (weeks.length < duration || duration === TILL_THE_END_OF_THE_PROGRAM)
  ) {
    weeks.push(week);
    if (increment === 0) {
      break;
    }
    week += increment;
  }

  return weeks;
};

export const getMaxDurationForRepeat = (
  repeat: ComponentRepeat,
  startWeek: number,
  programLength: number,
): number => {
  const weeks = getRecurrentComponentWeeks(
    startWeek,
    programLength,
    repeat,
    programLength,
  );
  return weeks.length;
};

export const getScheduleEndWeek = (
  startWeekId: number,
  durationNumber: number,
  weekList: ProgramWeek[],
  repeat: ComponentRepeat,
  returnNumber?: boolean,
): number | null => {
  // Find the index of the start week
  const startWeekIndex = weekList.findIndex((week) => week.id === startWeekId);
  if (startWeekIndex === -1) {
    console.error("Start week not found in the list.");
    return null;
  }

  if (durationNumber === TILL_THE_END_OF_THE_PROGRAM) return null;

  // Retrieve the increment value from ComponentRepeatValues
  const increment = ComponentRepeatValues[repeat] || 0;

  // Calculate the ending week's index based on the repeat pattern
  const endWeekIndex = startWeekIndex + (durationNumber - 1) * increment;

  // Check if the endWeekIndex is within bounds
  if (endWeekIndex >= weekList.length || endWeekIndex < 0) {
    console.error("Duration exceeds available weeks.");
    return null;
  }

  // Return the end week number
  if (returnNumber) return weekList[endWeekIndex].week;

  // Return the end week id
  return weekList[endWeekIndex].id;
};

export const getScheduleDuration = (
  startWeekId: number,
  repeat: ComponentRepeat,
  endWeekId: number,
  weekList: ProgramWeek[],
): number | null => {
  // Retrieve indices of the start and end weeks
  const startWeekIndex = weekList.findIndex((week) => week.id === startWeekId);
  const endWeekIndex = weekList.findIndex((week) => week.id === endWeekId);
  const increment = ComponentRepeatValues[repeat] || 1;

  // Till the end of the program case
  if (endWeekIndex === -1) {
    return repeat === ComponentRepeat.NONE ? 1 : TILL_THE_END_OF_THE_PROGRAM;
  }

  // General validation checks
  if (
    startWeekIndex === -1 || // Start week not found
    endWeekIndex === -1 || // End week not found
    endWeekIndex < startWeekIndex || // End week before start week
    increment <= 0 // Invalid repeat pattern
  ) {
    return 1;
  }

  // Calculate duration
  return Math.floor((endWeekIndex - startWeekIndex) / increment + 1);
};

export const TILL_THE_END_OF_THE_PROGRAM = -1;
