import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  settingOptions: {
    ["& > .MuiButton-contained"]: {
      ["&:not(.Mui-disabled)"]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
      },
      ["&.Mui-disabled"]: {
        backgroundColor: theme.palette.grey[400],
        color: theme.palette.common.white,
        "&:hover": {
          backgroundColor: theme.palette.grey[400],
        },
      },
    },
  },
}));

interface Option {
  label: string;
  description: string;
  icon: React.ReactNode;
}

interface SettingsPopupOptionProps {
  disabled?: boolean;
  setting: boolean;
  title: string;
  handleSettingChange: (value: boolean) => void;
  options: Option[];
}

export default function SettingsPopupOption(props: SettingsPopupOptionProps) {
  const { disabled, setting, title, handleSettingChange, options } = props;
  const s = useStyles();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        maxWidth: 340,
      }}
    >
      <Typography variant="subtitle2" fontWeight="bold">
        {title}
      </Typography>
      <ButtonGroup
        disabled={disabled}
        className={s.settingOptions}
        size="small"
      >
        <Button
          sx={{
            display: "flex",
            gap: 2,
            paddingY: 0.1,
          }}
          variant={!setting ? "contained" : "outlined"}
          onClick={() => handleSettingChange(false)}
        >
          {options[0].icon}
          {!setting && options[0].label}
        </Button>
        <Button
          sx={{
            display: "flex",
            gap: 2,
            paddingY: 0.1,
          }}
          variant={setting ? "contained" : "outlined"}
          onClick={() => handleSettingChange(true)}
        >
          {options[1].icon}
          {setting && options[1].label}
        </Button>
      </ButtonGroup>
      <Typography variant="caption">
        {setting ? options[1].description : options[0].description}
      </Typography>
    </Box>
  );
}
