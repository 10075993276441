import React from "react";
import { Box, Menu, Typography, useMediaQuery, useTheme } from "@mui/material";
import { WEEK_DAYS } from "./ComponentTypeFilter";
import { makeStyles } from "@mui/styles";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import FilterBadge from "./components/FilterBadge";
import FilterMenuItem, { FilterMenuTitle } from "./components/FilterMenuItem";
import MinimizedTooltip from "../../tooltip/MinimizedTooltip";

export const isWeekDaysFilterApplied = (value: number[]) => {
  return value.length < 7;
};

const useStyles = makeStyles((theme) => ({
  text: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
  },
}));

interface IWeekDaysFilterProps {
  weekDaysFilter: number[];
  setWeekDaysFilter: React.Dispatch<React.SetStateAction<number[]>>;
}
const WeekDaysFilter = (props: IWeekDaysFilterProps) => {
  const { weekDaysFilter, setWeekDaysFilter } = props;

  const s = useStyles();
  const theme = useTheme();
  const IS_MD = useMediaQuery(theme.breakpoints.down("md"));

  const menuState = usePopupState({
    variant: "popover",
    popupId: "days-filter",
  });

  const handleItemClick = (
    event: React.MouseEvent<
      HTMLDivElement | HTMLLIElement | HTMLLabelElement,
      MouseEvent
    >,
    value: number,
  ) => {
    event.stopPropagation();
    setWeekDaysFilter((arr) => {
      if (arr.includes(value)) {
        // prevent unselect last
        if (arr.length === 1) return arr;
        return arr.filter((item) => item !== value);
      } else {
        return [...arr, value];
      }
    });
  };

  const IS_FILTER_APPLIED = isWeekDaysFilterApplied(weekDaysFilter);
  if (!IS_FILTER_APPLIED) return <></>;

  return (
    <>
      <MinimizedTooltip
        title="Choose best combination of shown week days"
        placement="top"
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignContent: "center",
            gap: 1,
            alignItems: "center",
            color: theme.palette.text.secondary,
            ":hover": { cursor: "pointer" },
          }}
          {...bindTrigger(menuState)}
        >
          {!IS_MD && <Typography variant="subtitle1">Days</Typography>}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              width: "fit-content",
            }}
          >
            {WEEK_DAYS.map(
              (value) =>
                weekDaysFilter.includes(value) && (
                  <FilterBadge
                    value={value}
                    component={value}
                    selected={weekDaysFilter.includes(value)}
                  />
                ),
            )}
          </Box>
        </Box>
      </MinimizedTooltip>

      <Menu
        {...bindMenu(menuState)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FilterMenuTitle text="Days" />
        {WEEK_DAYS.map((value) => (
          <FilterMenuItem
            item={value}
            preLabel={"Day"}
            label={`${value}`}
            checked={weekDaysFilter.includes(value)}
            onClick={(e) => handleItemClick(e, value)}
          />
        ))}
      </Menu>
    </>
  );
};

export default WeekDaysFilter;
