import clsx from "clsx";
import React from "react";
import { Box, BoxProps, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";

import { queryMatchesContent } from "../../utils/search";
import { polyfillCSS } from "../../utils/css";

import { ThreadCard } from "./ThreadCard";
import { ThreadSkeleton } from "./ThreadSkeleton";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { ThreadsDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > div > *": {
      paddingLeft: polyfillCSS(
        `max(var(--safe-area-inset-left), ${theme.spacing(2)})`,
      ),
      paddingRight: polyfillCSS(
        `max(var(--safe-area-inset-right), ${theme.spacing(2)})`,
      ),

      [theme.breakpoints.up("md")]: {
        paddingLeft: "unset",
        paddingRight: "unset",
      },
    },
  },

  header: {
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
      backgroundColor: "unset",
    },
  },

  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: theme.spacing(2, 0),
    marginBottom: theme.spacing(0),

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  search: {
    "& fieldset": {
      borderColor: theme.palette.border.primary,
      borderWidth: 1,
      borderStyle: "solid",
    },
  },

  searchInput: {
    backgroundColor: theme.palette.background.paper,
  },

  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },

  loading: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  headlineSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    margin: 0,
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export interface ThreadsProps extends BoxProps {
  threads: ThreadsDto[];
  selectedThread?: string;
}

export function Threads(props: ThreadsProps) {
  const { className, threads, selectedThread, ...other } = props;
  const s = useStyles();
  const [keywords, setKeywords] = React.useState("");
  const { unreadMessagesCount } = useCurrentUser() || {};
  const handleSearchFilterChange = React.useCallback(
    (event) => {
      setKeywords(event.target.value);
    },
    [setKeywords],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box className={s.header}>
        <Typography variant="h1" className={s.title}>
          Messages
        </Typography>
        <Typography variant="h6" className={s.headlineSubtitle}>
          {`${unreadMessagesCount ?? 0} new message${unreadMessagesCount === 1 ? "" : "s"}`}
        </Typography>
        <TextField
          className={s.search}
          value={keywords}
          onChange={handleSearchFilterChange}
          placeholder="Search"
          variant="outlined"
          fullWidth
          slotProps={{
            input: {
              className: s.searchInput,
              startAdornment: <SearchIcon className={s.icon} />,
            },
          }}
        />
      </Box>
      {threads ? (
        threads
          .filter(Boolean)
          .filter(({ slug, threadAuthor: author, group }) =>
            [slug, author?.displayName, group?.name].some((value) =>
              queryMatchesContent(value, keywords),
            ),
          )
          .sort(({ read }) => (read ? 1 : -1))
          .map((thread) => (
            <ThreadCard
              key={thread.id}
              thread={thread}
              selected={selectedThread === thread.slug}
            />
          ))
      ) : (
        <Box className={s.loading}>
          <ThreadSkeleton />
          <ThreadSkeleton />
          <ThreadSkeleton />
        </Box>
      )}
    </Box>
  );
}
