import clsx from "clsx";
import React, { ReactNode } from "react";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReactDOMServer from "react-dom/server";

import sitting from "../../icons/sitting.svg";
import Sitting from "../../icons/Sitting";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  image: {
    maxWidth: "100%",
    marginBottom: theme.spacing(4),
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: "22px",
    color: theme.palette.common.black,
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
}));

export interface InfoBoxProps extends BoxProps {
  image?: ReactNode;
  title?: string;
  subtitle?: string;
  classes?: Partial<Record<"image" | "title" | "subtitle", string>>;
}
export function InfoBox(props: InfoBoxProps) {
  const { className, image, title, subtitle, classes, children, ...other } =
    props;
  const s = useStyles();
  const theme = useTheme();
  return (
    <Box className={clsx(s.root, className)} {...other}>
      {image ? image : <Sitting fill={theme.palette.primary.main} />}

      {title && (
        <Typography
          className={clsx(s.title, classes?.title)}
          variant="h5"
          children={title}
        />
      )}

      {subtitle && (
        <Typography
          className={clsx(s.subtitle, classes?.subtitle)}
          variant="body1"
          children={subtitle}
        />
      )}

      {children}
    </Box>
  );
}
