import { useCurrentUser } from "./useCurrentUser";

// fallback for some browsers that don't support hour12 in resolvedOptions
const always12HourZones = ["Canada", "America", "Australia", "US/"];

// TODO_TIMEZONE: add time format to user settings (preference)
export const useUserHourCycle = (): "12" | "24" => {
  const { timeZone } = useCurrentUser();
  let hour12 = false;
  try {
    hour12 = new Intl.DateTimeFormat(undefined, {
      hour: "numeric",
    }).resolvedOptions().hour12;
  } catch {
    hour12 = false;
  }

  let always12Hour = false;
  for (const zone of always12HourZones) {
    if (timeZone.includes(zone)) {
      always12Hour = true;
      break;
    }
  }

  if (!hour12 && !always12Hour) {
    return "24";
  }
  return "12";
};
