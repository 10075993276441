import clsx from "clsx";
import React, { useContext } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { ButtonProps } from "@mui/material";

import { InviteDialog } from "../../components/dialog/InviteDialog";

import { useNavigate } from "react-router-dom";
import AddClientDialogContext from "../../contexts/AddClientDialogContext";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface NewClientActionProps extends ButtonProps {
  handleNewClientAction: () => void;
}

export function NewClientAction(props: NewClientActionProps) {
  const navigate = useNavigate();
  const s = useStyles();
  const {
    className,
    handleNewClientAction,
    ...other
  } = props;
  const context = useContext(AddClientDialogContext);

  const handleClose = React.useCallback(() => {
    context.setAddClientDialog(false);
  }, [context]);

  return (
    <>
      <InviteDialog
        open={context.addClientDialog}
        onClose={handleClose}
        onInvite={handleNewClientAction}
      />
    </>
  );
}