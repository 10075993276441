import React from "react";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { CoachMarketingScreen } from "../../../components/marketing/CoachMarketingScreen";

export function CoachMarketingRoute() {
  return (
    <>
      <CoachMarketingScreen />
      <TrackInfoTool
        trackInfo={{
          name: "Coach - Marketing",
        }}
      />
    </>
  );
}
