import React from "react";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { Sets, WorkoutExercise } from "../workout/types";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { WorkoutExerciseDivider } from "../workout/WorkoutExerciseDivider";
import makeStyles from "@mui/styles/makeStyles";
import {
  ExerciseSetNumberType,
  getExerciseUnits,
  Units,
} from "../../constants";
import WorkoutSetOverviewItem from "./WorkoutSetOverviewItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&::after": {
      width: "100%",
      left: 0,
    },
    "&:hover::after": {
      backgroundColor: theme.palette.quote,
    },
  },

  button: {
    fontSize: 10,
    marginBlock: 4,
    "$root:hover &": {
      visibility: "hidden",
    },
  },
}));

interface IActivityPreviewExercisesProps {
  card: WorkoutExercise;
  index: number;
  exercises: WorkoutExercise[];
  isDndMove?: boolean;
  showTitle?: boolean;
  textWrap?: boolean;
}

const ActivityPreviewExercises = ({
  card,
  index,
  exercises,
  isDndMove,
  showTitle = true,
  textWrap = true,
}: IActivityPreviewExercisesProps) => {
  const { sets, typeExtraMeasurement, typeSet, units } = card;
  const s = useStyles();
  const theme = useTheme();

  const isSuperSet = () => {
    return (
      !isDndMove &&
      index < exercises.length - 1 &&
      exercises[index].superset === exercises[index + 1].superset
    );
  };

  const isLastItem = () => {
    return index === exercises.length - 1;
  };

  const getReps = (reps?: string | number) => {
    const item = String(reps).replace(/\s+/g, "");
    if (item === "-" || item?.length === 0 || !item) return "";
    if (item[0] === "-" || item[item.length - 1] === "-")
      return item.replace("-", "");
    return `${item} rep${Number(reps) === 1 ? "" : "s"}`;
  };

  const getWeight = (set) => {
    if (set.weight === "-") return "";
    if (set.weight && typeSet && units)
      return `${set.weight}${getExerciseUnits(typeSet, units as Units)}`;
    return "";
  };

  const getExtraMeasurement = (set) => {
    if (set.extraMeasurement && typeExtraMeasurement)
      return `${set.extraMeasurement}${typeExtraMeasurement}`;
    return "";
  };

  const generateSetString = (set: any) => {
    const parts = [
      getReps(set.reps),
      getWeight(set),
      getExtraMeasurement(set),
    ].filter(Boolean);
    return parts.join(", ");
  };

  const countAndGroupSets = (sets: Sets[]) => {
    const groupedSets: {
      setString: string;
      count: number;
      type: any;
      indices: number[];
    }[] = [];

    let previousSetString = "";
    let previousSetType = "";

    let currentGroup: {
      setString: string;
      count: number;
      type: any;
      indices: number[];
    } | null = null;

    sets.forEach((set, index) => {
      const setType = set.type ?? ExerciseSetNumberType.REGULAR_SET;
      const setString = generateSetString(set);
      if (
        setString === previousSetString &&
        setType === previousSetType &&
        currentGroup
      ) {
        currentGroup.count += 1;
        currentGroup.indices.push(index);
      } else {
        if (currentGroup) {
          groupedSets.push(currentGroup);
        }
        currentGroup = {
          setString,
          count: 1,
          type: set.type,
          indices: [index],
        };
      }
      previousSetString = setString;
      previousSetType = setType;
    });
    if (currentGroup) {
      groupedSets.push(currentGroup);
    }
    return groupedSets;
  };

  const TitleWithSuperset = () => {
    return (
      <Box
        sx={{
          fontSize: 9,
          display: "flex",
          gap: 0.75,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            fontSize: 16,
          }}
        >
          <RadioButtonUncheckedIcon fontSize="inherit" sx={{ mt: 0.25 }} />
          <Box
            sx={{
              left: "7px",
              position: "relative",
              top: theme.spacing(-0.375),
              marginBottom: -3,
              borderLeftWidth: 2,
              height: "calc(100% + 30px)",
              borderLeftStyle: "unset",
              borderColor: theme.palette.grey[400],
              ...(isSuperSet() && {
                borderStyle: "solid",
                borderColor: theme.palette.common.black,
              }),
              display: isLastItem() && "none",
            }}
          />
        </Box>
        <Typography
          style={{
            fontWeight: "600",
            fontSize: 14,
            lineHeight: 1.3,
          }}
          sx={{
            fontWeight: "700",
            mb: 0.75,
          }}
        >
          {card.title}
        </Typography>
        <Divider />
      </Box>
    );
  };

  const SupersetDivider = () => {
    return (
      !isLastItem() && (
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              paddingLeft: 1.5,
              marginLeft: "7px",
              marginBlock: -1,
              borderLeft: isSuperSet() ? "2px solid" : "unset",
            }}
          />
          <WorkoutExerciseDivider
            rootStyling={s.root}
            buttonStyling={s.button}
          />
        </Box>
      )
    );
  };

  return (
    <>
      {showTitle && <TitleWithSuperset />}
      <Box
        sx={{
          paddingLeft: 1.5,
          marginLeft: "7px",
          borderLeftWidth: 2,
          borderLeftStyle: "unset",
          borderColor: theme.palette.grey[400],

          ...(isSuperSet() && {
            borderLeftStyle: "solid",
            borderColor: theme.palette.common.black,
          }),

          display: "flex",
          flexDirection: "column",
          gap: 0.25,
        }}
      >
        {sets &&
          countAndGroupSets(sets).map((data, index) => (
            <WorkoutSetOverviewItem
              {...data}
              index={index}
              textWrap={textWrap}
            />
          ))}
      </Box>
      <SupersetDivider />
    </>
  );
};

export default ActivityPreviewExercises;
