import { adminClient } from "../api/ApiClients";
import {
  BodypartType,
  ComponentType,
  CreateAdminExerciseAssetCommand,
  ExerciseAssetsType,
  FixProgramLengthCommand,
  GenerateAutocompleteSampleSetupCommand,
  ICreateAdminExerciseAssetCommand,
  IGenerateAutocompleteSampleSetupCommand,
  IReorderProgramTemplatesCommand,
  IUpdateAdminPublicExerciseAssetCommand,
  IUpdateProgramTemplateCommand,
  MovementPatternType,
  RegenerateActivitiesCommand,
  ReorderProgramTemplatesCommand,
  UpdateAdminPublicExerciseAssetCommand,
  UpdateProgramTemplateCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

interface GetProgramsParams {
  pageNumber: number;
  pageSize: number;
  activeSubscription: boolean;
  publishedPrograms: boolean;
}

type GetProgramsByComponentDuplicatesParams = GetProgramsParams & {
  notArchivedComponents: boolean;
};

type GetProgramsSearchParams = GetProgramsParams & {
  searchQuery: string;
};

class AdminService extends ServiceBase {
  getPrograms({
    pageNumber,
    pageSize,
    activeSubscription,
    publishedPrograms,
    searchQuery,
  }: GetProgramsSearchParams) {
    return adminClient.getPrograms(
      pageNumber,
      pageSize,
      activeSubscription,
      publishedPrograms,
      searchQuery,
    );
  }

  getProgramsByComponentDuplicates({
    pageNumber,
    pageSize,
    activeSubscription,
    publishedPrograms,
    notArchivedComponents,
  }: GetProgramsByComponentDuplicatesParams) {
    return adminClient.getProgramsByComponentDuplicates(
      notArchivedComponents,
      pageNumber,
      pageSize,
      activeSubscription,
      publishedPrograms,
    );
  }

  getProgramsByLengthIssues({
    pageNumber,
    pageSize,
    activeSubscription,
    publishedPrograms,
    searchQuery,
  }: GetProgramsSearchParams) {
    return adminClient.getProgramsByLengthIssues(
      pageNumber,
      pageSize,
      activeSubscription,
      publishedPrograms,
      searchQuery,
    );
  }

  getExerciseCustomAssetsByType(
    exerciseAssetsType: ExerciseAssetsType,
    pageNumber: number,
    pageSize: number,
    searchQueryByName?: string,
    searchQueryByCoachId?: number,
    activeSubscription?: boolean,
    bodyparts?: BodypartType[],
    movementPatterns?: MovementPatternType[],
  ) {
    return adminClient.getExerciseAssetsByType(
      exerciseAssetsType,
      pageNumber,
      pageSize,
      activeSubscription,
      searchQueryByName,
      searchQueryByCoachId,
      bodyparts,
      movementPatterns,
    );
  }

  getExercisesAssetsHistory(assetId: number, legacyAssetId?: string) {
    return adminClient.getExerciseAssetHistory(assetId, legacyAssetId);
  }

  createAdminExerciseAsset(variables: ICreateAdminExerciseAssetCommand) {
    const command = new CreateAdminExerciseAssetCommand(variables);
    return adminClient.createAdminExerciseAsset(command);
  }

  updateAdminPublicExerciseAsset(
    variables: IUpdateAdminPublicExerciseAssetCommand,
  ) {
    const command = new UpdateAdminPublicExerciseAssetCommand(variables);
    return adminClient.updateAdminPublicExerciseAsset(command.id, command);
  }

  adminDeleteExerciseCustomAsset(id: number) {
    return adminClient.adminDeleteExerciseCustomAsset(id);
  }

  getProgramActivityDetails(programSlug: string) {
    return adminClient.getProgramActivityDetails(programSlug);
  }

  getDuplicatesByTitle(
    programId: number,
    days: string | null,
    title: string | null,
    type: ComponentType,
    includeContent: boolean,
  ) {
    return adminClient.getComponentDuplicatesInfo(
      programId,
      days,
      title,
      type,
      includeContent,
    );
  }

  getAdminStats(extended: boolean, programId?: number | null) {
    return adminClient.adminStats(extended, programId);
  }

  fixProgramLength(id: number) {
    const command = new FixProgramLengthCommand({ programId: id });
    return adminClient.fixProgramLength(command);
  }

  regenerateActivities(enrollmentIds: number[]) {
    const command = new RegenerateActivitiesCommand({ enrollmentIds });
    return adminClient.regenerateActivities(command);
  }

  async updateProgramTemplate(variables: IUpdateProgramTemplateCommand) {
    const command = UpdateProgramTemplateCommand.fromJS(variables);
    return adminClient.updateProgramTemplate(command);
  }

  async reorderProgramTemplates(variables: IReorderProgramTemplatesCommand) {
    const command = ReorderProgramTemplatesCommand.fromJS(variables);
    return adminClient.reorderProgramTemplates(command);
  }

  async sampleSetup(variables: IGenerateAutocompleteSampleSetupCommand) {
    const command = GenerateAutocompleteSampleSetupCommand.fromJS(variables);
    return adminClient.generateAutocompleteSampleSetupCommand(command);
  }
}

export default new AdminService();
