import axios from "axios";
import { billingsClient, mealLoggingsClient } from "../api/ApiClients";
import {
  ContinueOldPlanCommand,
  CreateCheckoutSessionCommand,
  CreateCustomerPortalSessionCommand,
  CreatePromoSubscriptionCommand,
  IContinueOldPlanCommand,
  ICreateCheckoutSessionCommand,
  ICreateCustomerPortalSessionCommand,
  ICreatePromoSubscriptionCommand,
  IUpdateMealLoggingPromptsCommand,
  IUpdateUserSubscriptionCommand,
  UpdateMealLoggingPromptsCommand,
  UpdateUserSubscriptionCommand,
} from "@growth-machine-llc/stridist-api-client";
import ServiceBase from "./ServiceBase";

class BillingsService extends ServiceBase {
  async continueOldPlan(variables: IContinueOldPlanCommand) {
    const command = ContinueOldPlanCommand.fromJS(variables);
    return billingsClient.continueOldPlan(command);
  }

  async updateUserSubscription(variables: IUpdateUserSubscriptionCommand) {
    const command = UpdateUserSubscriptionCommand.fromJS(variables);
    return billingsClient.updateUserSubscription(command);
  }

  async changeCoachCreditCard() {
    return billingsClient.changeCoachCreditCard();
  }

  async getHostedInvoiceUrl() {
    return billingsClient.getHostedInvoiceUrl();
  }

  async getUserSubscription() {
    return billingsClient.getUserSubscription();
  }

  async createCheckoutSession(variables: ICreateCheckoutSessionCommand) {
    const command = CreateCheckoutSessionCommand.fromJS(variables);
    return billingsClient.createCheckoutSession(command);
  }

  async createSignupCheckoutSession(variables: ICreateCheckoutSessionCommand) {
    const command = CreateCheckoutSessionCommand.fromJS(variables);
    return billingsClient.createSignupCheckoutSession(command);
  }

  async createCustomerPortal(variables: ICreateCustomerPortalSessionCommand) {
    const command = CreateCustomerPortalSessionCommand.fromJS(variables);
    return billingsClient.createCustomerPortalSession(command);
  }

  async createPromoSubscription(variables: ICreatePromoSubscriptionCommand) {
    const command = CreatePromoSubscriptionCommand.fromJS(variables);
    return billingsClient.createPromoSubscription(command);
  }
}

export default new BillingsService();
