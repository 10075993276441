import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as CheckMarkCircle } from "../../icons/CheckMarkCircle.svg";
import art from "../../icons/personal-training.svg";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    minHeight: "100vh",
  },

  content: {
    maxWidth: 450,
    marginRight: 20,
    marginLeft: 20,
  },

  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "30px",
    color: theme.palette.common.black,
  },

  list: {
    marginTop: theme.spacing(2),
    padding: 0,
  },

  item: {
    padding: 0,
    marginBottom: 12,
    display: "flex",
    alignItems: "flex-start",
  },

  check: {
    marginRight: theme.spacing(1.25),

    "& svg": {
      width: 20,
      height: 20,
      color: theme.palette.primary.main,
    },
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.common.black,
  },

  lineHeightText: {
    lineHeight: "28px",
  },

  art: {
    marginTop: theme.spacing(4),
    width: 360,
  },
}));

export interface SignUpBenefitsProps extends BoxProps {
  plan: string;
}

export function SignUpBenefits(props: SignUpBenefitsProps) {
  const { className, plan, ...other } = props;
  const s = useStyles();
  const { brandName } = useCurrentBrand();

  const signUpBenefitsTitle = React.useMemo(() => {
    return `Start your free trial of ${brandName} today`;
  }, []);

  const benefits = [
    "Unlimited training and nutrition programmes",
    "Marketing suite tools including unlimited landing pages and email automations",
    `${brandName} resource library and templates`,
    "Automate client onboarding, check-ins and communication",
    "Take payments and manage clients in one place",
  ];
  const signUpBenefitsSubtitle = React.useMemo(() => {
    return `Get full access to ${brandName} free for 14 days! Try our suite of coaching tools with no risk and no commitment. We won't charge you until after your free trial and you can cancel anytime`;
  }, []);

  const signUpBenefitsText = React.useMemo(() => {
    return benefits;
  }, []);

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Box>
        <Box className={s.content}>
          {plan === "PROMO" ? (
            <>
              <Typography className={s.title} variant="h2">
                {brandName} Build Programme
              </Typography>
              <Typography className={clsx(s.text, s.lineHeightText)}>
                Build Out The Systems And Process That Make Your Business
                Predictable
              </Typography>
              <br />
              <List className={s.list}>
                <ListItem className={s.item}>
                  <ListItemIcon
                    className={s.check}
                    children={<CheckMarkCircle />}
                  />
                  <Typography className={s.text}>Lead Generation</Typography>
                </ListItem>
                <ListItem className={s.item}>
                  <ListItemIcon
                    className={s.check}
                    children={<CheckMarkCircle />}
                  />
                  <Typography className={s.text}>Client Acquisition</Typography>
                </ListItem>
                <ListItem className={s.item}>
                  <ListItemIcon
                    className={s.check}
                    children={<CheckMarkCircle />}
                  />
                  <Typography className={s.text}>Launches</Typography>
                </ListItem>
                <ListItem className={s.item}>
                  <ListItemIcon
                    className={s.check}
                    children={<CheckMarkCircle />}
                  />
                  <Typography className={s.text}>Evergreen Funnels</Typography>
                </ListItem>
                <ListItem className={s.item}>
                  <ListItemIcon
                    className={s.check}
                    children={<CheckMarkCircle />}
                  />
                  <Typography className={s.text}>
                    Client Onboarding, Nurture and Retention Systems
                  </Typography>
                </ListItem>
                <ListItem className={s.item}>
                  <ListItemIcon
                    className={s.check}
                    children={<CheckMarkCircle />}
                  />
                  <Typography className={s.text}>
                    12 Month Warmap That Ensures You Know Exactly What You're
                    Doing Every Single Week
                  </Typography>
                </ListItem>
              </List>
              <br />
              <Typography className={clsx(s.text, s.lineHeightText)}>
                If Your Business Experiences Regular Plateaus.
                <br />
                <br />
                New Clients Come Inconsistently And Sometimes More Clients
                Leaving Per Month Than Coming In.
                <br />
                <br />
                And You Feel Like You Don't Have Systems In Place So That Things
                Run Like Clockwork.
                <br />
                <b>Then This Programme Is For You</b>
              </Typography>
            </>
          ) : (
            <>
              <Typography className={s.title} variant="h2">
                {signUpBenefitsTitle}
              </Typography>

              <Typography className={clsx(s.text, s.lineHeightText)}>
                {signUpBenefitsSubtitle}
              </Typography>

              <Typography className={s.title} variant="h2">
                What's included?
              </Typography>

              <List className={s.list}>
                {signUpBenefitsText.map((benefit, index) => (
                  <ListItem key={index} className={s.item}>
                    <ListItemIcon
                      className={s.check}
                      children={<CheckMarkCircle />}
                    />
                    <Typography className={s.text} children={benefit} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <img className={s.art} src={art} alt="" />
        </Box>
      </Box>
      <div />
    </Box>
  );
}
