import clsx from "clsx";
import React, { FormEventHandler } from "react";
import { Box, BoxProps, Typography, TextField, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AuthButton } from "./AuthButton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 457,
    textAlign: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
  },

  submitButton: {
    marginTop: theme.spacing(3),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(3),
  },
}));

export interface ForgotPasswordFormPropsX {
  className?: string;
  email: string;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  disabled?: boolean;
}

export function ForgotPasswordFormX(props: ForgotPasswordFormPropsX) {
  const {
    className,
    email,
    onEmailChange,
    onSubmit,
    disabled = false,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Typography className={s.title} variant="h1">
        Forgot your password?
      </Typography>

      <Box component={"form"} onSubmit={onSubmit}>
        <TextField
          variant="filled"
          name="email"
          type="email"
          label="Email address"
          placeholder="Enter your email address..."
          value={email}
          onChange={onEmailChange}
          disabled={disabled}
          fullWidth
        />

        <AuthButton
          className={s.submitButton}
          type="submit"
          children="Send reset link"
          disabled={disabled || !email}
        />
      </Box>

      <Typography className={s.text}>
        Don’t have an account? <Link href="/signup">Sign Up as Coach</Link>
      </Typography>
    </Box>
  );
}
