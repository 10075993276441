import {
  Box,
  FormControl,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import GenericScheduleSelect from "../curriculum-sidebar/components/schedule/generic/GenericScheduleSelect";
import { TimePicker } from "@mui/x-date-pickers";
import GenericScheduleTitle from "../curriculum-sidebar/components/schedule/generic/GenericScheduleTitle";
import dayjs from "dayjs";
import { Schedule } from "../schedule/types";
import { useCurriculumDispatch } from "../../redux/hooks";
import { updateComponentSchedule } from "../../redux/curriculum/curriculum-slice";
import { parseComponentDays } from "../../utils/component";
import { messageTimeByType, MessageTimeType } from "../../constants";
import { IComponentTimeSettingsProps } from "./ComponentTimeSettings";

const MessageSettings = (props: IComponentTimeSettingsProps) => {
  const { component } = props;
  const theme = useTheme();
  const dispatch = useCurriculumDispatch();

  const updateSchedule = (updates: Partial<Schedule>) => {
    dispatch(
      updateComponentSchedule({
        componentId: component.id,
        schedule: {
          weekId: component.weekId,
          repeat: component.repeat,
          days: parseComponentDays(component.days),
          duration: component.duration,
          reminderType: component.reminderType,
          reminderTime: component.reminderTime,
          messageTime: component.messageTime,
          ...updates,
        },
      }),
    );
  };

  //
  // Handlers

  const handleTimePickerChange = (newValue: dayjs.Dayjs) => {
    const result = newValue?.isValid() ? newValue : dayjs();
    updateSchedule({
      messageTime: result.format("HH:mm"),
    });
  };

  const handleMessageTimeTypeSelectChange = (newValue: string) => {
    updateSchedule({
      messageTime: newValue,
    });
  };

  //
  // UI

  const getMessageTimeSelectValue = (time: string) => {
    // string to dayjs
    const parsedTime = dayjs(time, "HH:mm");
    const validTimes = Object.values(messageTimeByType).map((i) =>
      dayjs(i, "HH:mm").format("HH:mm"),
    );

    // dayjs to string
    return validTimes.includes(parsedTime.format("HH:mm"))
      ? parsedTime.format("HH:mm")
      : messageTimeByType[MessageTimeType.CUSTOM];
  };

  // MessageTime
  const TIME_VALUE = dayjs(component.messageTime, "HH:mm");

  return (
    <>
      <Box paddingInline={2}>
        <Typography
          variant="h6"
          sx={{
            marginTop: 3,
            marginBottom: 4,
            borderBottom: 2,
            borderColor: theme.palette.text.secondary,
          }}
        >
          {"Time of day"}
        </Typography>

        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <GenericScheduleSelect
            title="Message at"
            enableSwitch={false}
            value={getMessageTimeSelectValue(component.messageTime)}
            onChange={handleMessageTimeTypeSelectChange}
            disabled={false}
          >
            <MenuItem value={messageTimeByType[MessageTimeType.MORNING]}>
              Morning
            </MenuItem>
            <MenuItem value={messageTimeByType[MessageTimeType.NOON]}>
              Noon
            </MenuItem>
            <MenuItem value={messageTimeByType[MessageTimeType.AFTERNOON]}>
              Afternoon
            </MenuItem>
            <MenuItem value={messageTimeByType[MessageTimeType.END_OF_DAY]}>
              End of day
            </MenuItem>
            <MenuItem value={messageTimeByType[MessageTimeType.CUSTOM]}>
              Custom
            </MenuItem>
          </GenericScheduleSelect>

          <FormControl>
            <GenericScheduleTitle title={"Time"} enableSwitch={false} />
            <TimePicker
              value={TIME_VALUE.isValid() ? TIME_VALUE : null}
              slotProps={{
                textField: { size: "small" },
              }}
              timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
              onChange={handleTimePickerChange}
            />
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default MessageSettings;
