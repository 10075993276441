import { formatDuration, intervalToDuration } from "date-fns";
import { useEffect, useState, useMemo } from "react";

export const useTimer = (defaultTimeInSeconds?: number) => {
  const [count, setCount] = useState<number | undefined>(defaultTimeInSeconds);

  const setTime = (timeInSeconds: number) => {
    setCount(timeInSeconds);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const { timeString, isDone, seconds } = useMemo(() => {
    if (count !== undefined) {
      const duration = intervalToDuration({
        start: 0,
        end: count * 1000,
      });
      const timeString = formatDuration(duration);
      const seconds = duration.seconds;
      const isDone = count === 0;
      return { timeString, isDone, seconds };
    }

    return { timeString: undefined, isDone: false, setCount, seconds: 0 };
  }, [count]);

  return { timeString, isDone, setCount, seconds };
};
