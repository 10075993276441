import { useQueryClient } from "@tanstack/react-query";
import { PROGRAM_ENROLLMENTS_QUERY_KEY } from "../routes/coach/program/enrollments/CoachProgramEnrollmentsRoute";
import { MANAGE_PROGRAM_CLIENTS_DIALOG_QUERY_KEY } from "../components/groups/ClientsAndGroupsSelectDialog";
import { COACH_PROGRAMS_WRAPPER_QUERY_KEY } from "../wrappers/router/coach/CoachProgramsWrapper";
import { COACH_PROGRAMS_LIST_QUERY_KEY } from "../components/coach-programs/CoachProgramsListScreen";
import {
  COACH_GROUPS_COUNT_QUERY_KEY,
  COACH_GROUPS_LIST_QUERY_KEY,
} from "../routes/coach/groups/CoachGroupsListRoute";
import { GROUP_MEMBERS_LIST_QUERY_KEY } from "../components/group-members/GroupMembersList";
import { COACH_GROUP_WRAPPER_QUERY_KEY } from "../routes/coach/group/CoachGroupRouteWrapper";
import { MANAGE_GROUP_DIALOG_CLIENTS_QUERY_KEY } from "../components/groups/ManageGroupDialog";

export const useInvalidateCoachProgramAndGroupQueries = () => {
  const queryClient = useQueryClient();

  return () => {
    const queries = [
      COACH_PROGRAMS_WRAPPER_QUERY_KEY,
      PROGRAM_ENROLLMENTS_QUERY_KEY,
      MANAGE_PROGRAM_CLIENTS_DIALOG_QUERY_KEY,
      COACH_PROGRAMS_LIST_QUERY_KEY,
      COACH_GROUPS_LIST_QUERY_KEY,
      COACH_GROUPS_COUNT_QUERY_KEY,
      GROUP_MEMBERS_LIST_QUERY_KEY,
      COACH_GROUP_WRAPPER_QUERY_KEY,
      MANAGE_GROUP_DIALOG_CLIENTS_QUERY_KEY,
    ];

    queries.forEach((queryKey) => {
      queryClient.invalidateQueries({ queryKey: [queryKey], exact: false });
    });
  };
};
