import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  MeasurementType,
  MeasurementUnits,
  UnitTypeAssets,
  getClientUnits,
  getDefaultUnit,
  measurementTypeArr,
  measurementTypesAssets,
  measurementUnitsArr,
  measurementUnitsAssets,
} from "../../other/measurementUtil";
import { TElement } from "@udecode/plate-common";
import { EditorElementView } from "../../utils/editorUtils";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import DisabledCheckInTooltip from "../tooltips/DisabledCheckInTooltip";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import CheckInTextfield from "./CheckInTextfield";

interface MeasurementInputData {
  type: string;
  unit: string;
  measurement: number;
}

interface IMeasurementQuestionProps
  extends ICheckInQuestionPropsBase<MeasurementInputData> {
  handleSetNode: (dataToSet: object) => void;
}
const MeasurementQuestion = ({
  handleSetNode,
  element,
  view,
  disableAnswer,
}: IMeasurementQuestionProps) => {
  const inputData = element.answer as unknown as MeasurementInputData;
  const inputType = inputData && (inputData.type as MeasurementType);
  const inputUnit = inputData && (inputData.unit as MeasurementUnits);
  const inputValue = inputData && (inputData.measurement as number);

  const me = useCurrentUser();
  const defaultUserUnits = getClientUnits(me);

  const type = Object.values(MeasurementType).includes(inputType)
    ? inputType
    : MeasurementType.BODYWEIGHT;

  React.useEffect(() => {
    if (!inputType) {
      handleSetNode({ answer: { type: type } });
    }
  }, [inputType]);

  const unit = inputUnit || getDefaultUnit(type, defaultUserUnits);

  const value = inputValue;

  const handleUnitChange = React.useCallback(
    (e) => {
      handleSetNode({
        answer: {
          unit: e.target.value as MeasurementUnits,
          type: type,
          measurement: value,
        },
      });
    },
    [type, value, handleSetNode],
  );

  const handleTypeChange = React.useCallback(
    (e) => {
      const newType = e.target.value as MeasurementType;
      handleSetNode({ answer: { type: newType } });
    },
    [handleSetNode],
  );

  const handleValueChange = React.useCallback(
    (e) => {
      handleSetNode({
        answer: { unit: unit, type: type, measurement: e.target.value },
      });
    },
    [unit, type, handleSetNode],
  );

  const editable = view === EditorElementView.Coach;

  const currentTypeUnits = measurementUnitsArr
    .map((unit) => {
      if (
        measurementTypesAssets[type].unitType ===
        measurementUnitsAssets[unit].unitType
      )
        return measurementUnitsAssets[unit];
    })
    // Filter out undefined values
    .filter((unit) => unit);

  const unitsPlaceholder = currentTypeUnits.map((unit) => unit.label).join("/");

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1.5,
        alignItems: "flex-end",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
        }}
      >
        {!editable && (
          <Typography variant="body1" gutterBottom>
            {measurementTypesAssets[type].label}{" "}
          </Typography>
        )}
        <DisabledCheckInTooltip visible={editable}>
          <CheckInTextfield
            type="number"
            placeholder={`Current ${measurementTypesAssets[type].label}`}
            value={value ?? ""}
            onChange={handleValueChange}
            fullWidth
            disabled={editable || disableAnswer}
          />
        </DisabledCheckInTooltip>
      </Box>
      <DisabledCheckInTooltip visible={editable}>
        <Select
          fullWidth
          value={editable ? unitsPlaceholder : unit}
          onChange={handleUnitChange}
          disabled={editable || disableAnswer}
        >
          {editable ? (
            <MenuItem value={unitsPlaceholder} disabled>
              {unitsPlaceholder}
            </MenuItem>
          ) : (
            currentTypeUnits.map((unit, index) => (
              <MenuItem value={unit.value} key={index}>
                {unit.label}
              </MenuItem>
            ))
          )}
        </Select>
      </DisabledCheckInTooltip>
      {editable && (
        <Select fullWidth value={type} onChange={handleTypeChange}>
          {measurementTypeArr.map((type, index) => {
            return (
              <MenuItem value={measurementTypesAssets[type].value} key={index}>
                {measurementTypesAssets[type].label}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </Box>
  );
};

export default MeasurementQuestion;
