import React from "react";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Zoom from "@mui/material/Zoom";
import { CircleCheck, CircleX, Lock, LockOpen } from "lucide-react";
import { CircularLoader } from "../loading/CircularLoader";

const useStyles = makeStyles(({ palette, spacing }) => ({
  buttonGroup: {
    fontWeight: "bold",
    height: 40,
    width: 300,
    transition: "all 0.1s",
  },

  description: {
    fontSize: 14,
    fontWeight: 500,
    color: palette.text.secondary,
    marginBottom: spacing(2),
  },

  icon: {
    height: 25,
    width: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export type ActionStatus = "idle" | "pending" | "success" | "error";

interface SettingsLockUnlockProps {
  lockingStatus: ActionStatus;
  unlockingStatus: ActionStatus;
  lock: () => void;
  unlock: () => void;
}

interface StatusState<T> {
  lock: T;
  unlock: T;
}

export default function SettingsLockUnlock(props: SettingsLockUnlockProps) {
  const s = useStyles();
  const { lockingStatus, unlockingStatus, lock, unlock } = props;

  const [localStatuses, setLocalStatuses] = React.useState<
    StatusState<ActionStatus>
  >({
    lock: "idle",
    unlock: "idle",
  });
  const [timers, setTimers] = React.useState<
    StatusState<NodeJS.Timeout | null>
  >({
    lock: null,
    unlock: null,
  });
  const [transitions, setTransitions] = React.useState<StatusState<boolean>>({
    lock: true,
    unlock: true,
  });

  const getIcon = (status: ActionStatus, idleIcon: any) => {
    switch (status) {
      case "idle":
        return idleIcon;
      case "pending":
        return <CircularLoader />;
      case "success":
        return <CircleCheck />;
      case "error":
        return <CircleX />;
    }
  };

  const changeLocalStatus = (index: "lock" | "unlock", status) => {
    status !== localStatuses[index] &&
      setTransitions((prev) => {
        prev[index] = false;
        return { ...prev };
      });
    const statusTimeout = setTimeout(() => {
      setLocalStatuses((prev) => {
        prev[index] = status;
        return { ...prev };
      });
      clearTimeout(statusTimeout);
    }, 50);
    const transitionTimeout = setTimeout(() => {
      setTransitions((prev) => {
        prev[index] = true;
        return { ...prev };
      });
      clearTimeout(transitionTimeout);
    }, 100);
  };

  React.useEffect(() => {
    changeLocalStatus("lock", lockingStatus);
    if (lockingStatus === "success" || lockingStatus === "error") {
      setTimers((prev) => {
        prev["lock"] = setTimeout(() => {
          changeLocalStatus("lock", "idle");
        }, 1000);
        return prev;
      });
    }
  }, [lockingStatus]);

  React.useEffect(() => {
    changeLocalStatus("unlock", unlockingStatus);
    if (unlockingStatus === "success" || unlockingStatus === "error") {
      setTimers((prev) => {
        prev["unlock"] = setTimeout(() => {
          changeLocalStatus("unlock", "idle");
        }, 1000);
        return prev;
      });
    }
  }, [unlockingStatus]);

  React.useEffect(() => {
    return () => {
      Object.values(timers).map((timer) => timer && clearTimeout(timer));
    };
  }, [timers]);

  return (
    <Box>
      <Typography className={s.description}>
        Locked components are visible to clients, but they can't interact or
        open them.
      </Typography>
      <ButtonGroup
        disabled={Object.values(localStatuses).some(
          (status) => status === "pending",
        )}
        variant="outlined"
        className={s.buttonGroup}
        aria-label="Basic button group"
      >
        <Button
          onClick={lock}
          startIcon={
            <Zoom in={transitions["lock"]}>
              <Box className={s.icon}>
                {getIcon(localStatuses["lock"], <Lock />)}
              </Box>
            </Zoom>
          }
        >
          Lock all
        </Button>
        <Button
          onClick={unlock}
          startIcon={
            <Zoom in={transitions["unlock"]}>
              <Box className={s.icon}>
                {getIcon(localStatuses["unlock"], <LockOpen />)}
              </Box>
            </Zoom>
          }
        >
          Unlock all
        </Button>
      </ButtonGroup>
    </Box>
  );
}
