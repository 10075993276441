import clsx from "clsx";
import React from "react";
import { TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FieldsGroup, FieldsGroupProps } from "./FieldsGroup";
import { FieldError } from "./FieldError";
import { useForm, UseFormRegister } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {},
  error: {
    position: "absolute",
  },
}));

interface LocationFormFields {
  location?: string;
}

export interface LocationFieldProps<T = LocationFormFields>
  extends Omit<FieldsGroupProps, "onChange" | "label" | "children" | "error"> {
  disabled?: boolean;
  onChange?: (value: string) => void;
  errorMessage?: React.ReactNode;
  register: UseFormRegister<T>;
}

export function LocationField(props: LocationFieldProps) {
  const { className, onChange, disabled, register, errorMessage, ...other } =
    props;
  const s = useStyles();

  return (
    <FieldsGroup
      className={clsx(s.root, className)}
      label="Location"
      {...other}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Add your location"
        disabled={disabled}
        name="location"
        {...(register("location") || {})}
      />
      {errorMessage && (
        <FieldError hideIcon={true} className={s.error}>
          {errorMessage}
        </FieldError>
      )}
    </FieldsGroup>
  );
}
