import { Box, Button, Divider, useTheme } from "@mui/material";
import React from "react";
import { Icons } from "../../../plate-ui/Icons/icons";
import { IMenuSection } from "../../utils/menuSectionsUtil";
import { TElement } from "@udecode/plate-common";
import { Path } from "slate";
import { InsertDropdownMenu } from "../../../plate-ui/insert-dropdown-menu";

interface IInsertLineProps {
  handleOpenInsertMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
  isInsertLineHovered: boolean;
  setIsInsertLineHovered: React.Dispatch<React.SetStateAction<boolean>>;
  element: TElement;
  generalDropDownMenuItems: IMenuSection[];
  insertItemAnchorEl: HTMLElement;
  openInsetItemMenu: boolean;
  handleCloseInsertMenu: (event: any) => void;
  insertPath: Path;
}

const InsertLine = ({
  handleOpenInsertMenu,
  isInsertLineHovered,
  setIsInsertLineHovered,
  element,
  generalDropDownMenuItems,
  insertItemAnchorEl,
  openInsetItemMenu,
  handleCloseInsertMenu,
  insertPath,
}: IInsertLineProps) => {
  const theme = useTheme();
  return (
    <>
      <Box
        contentEditable={false}
        onClick={handleOpenInsertMenu}
        onMouseEnter={() => setIsInsertLineHovered(true)}
        onMouseLeave={() => setIsInsertLineHovered(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBlock: 0.5,
          opacity: 0,
          minHeight: 10,
          borderColor: theme.palette.divider,

          ":hover": {
            opacity: 1,
            cursor: "pointer",
          },

          transition: "opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: { "@xs": -18, "@sm": -23 },
          }}
        >
          <InsertDropdownMenu
            element={element}
            menuItems={generalDropDownMenuItems}
            buttonComponent={
              <Button sx={{ minWidth: 0, py: 0.4, px: 0.4 }}>
                <Icons.add
                  className="h-4 w-4"
                  style={{ color: theme.palette.grey[500], strokeWidth: 3 }}
                />
              </Button>
            }
            anchorEl={insertItemAnchorEl}
            open={openInsetItemMenu}
            handleClose={handleCloseInsertMenu}
            handleClick={handleOpenInsertMenu}
            insertPath={insertPath}
          />
        </Box>
        {isInsertLineHovered && (
          <Divider
            sx={{
              borderWidth: 1,
              borderColor: "inherit",
              width: "100%",
              borderRadius: 5,
            }}
          />
        )}
      </Box>
    </>
  );
};

export default InsertLine;
