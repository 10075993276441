import { Theme } from "@mui/material/styles";
import { CSSObject, minWidth, padding } from "@mui/system";
import { navigationClasses } from "../navigationClasses";

export const navItemHorzPadding = 2;

export const getNavigationLineStyles = (
  arg: Theme | { theme: Theme },
): CSSObject => {
  const { palette, spacing, typography } = "theme" in arg ? arg.theme : arg;
  return {
    [`& .${navigationClasses.item}`]: {
      position: "relative",
      display: "inline-block",
      fontWeight: typography.fontWeightBold,

      paddingY: spacing(navItemHorzPadding),
      paddingX: spacing(navItemHorzPadding),

      "&:not(.Mui-disabled)": {
        color: palette.text.secondary,
      },
      "&.Mui-active": {
        fontWeight: typography.fontWeightBold,
        color:
          palette.mode === "dark"
            ? palette.primary.light
            : palette.primary.main,

        "&:after": {
          opacity: 1,
          transform: "scale(1, 1)",
          backgroundColor:
            palette.mode === "dark"
              ? palette.primary.light
              : palette.primary.main,
        },
      },

      "&:after": {
        content: '""',
        display: "block",
        position: "absolute",
        bottom: 0,
        left: spacing(navItemHorzPadding),
        width: `calc(100% - ${spacing(navItemHorzPadding * 2)})`,
        height: 3,
        transform: "scale(0, 1)",
        transition: "0.2s ease-out",
        opacity: 0,
        backgroundColor: palette.primary.light,
      },
      "&:hover": {
        color:
          palette.mode === "dark"
            ? palette.primary.light
            : palette.primary.main,
        "&:after": {
          opacity: 1,
          transform: "scale(1, 1)",
        },
      },
    },
  };
};
