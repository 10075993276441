/**
 * useNavigationGuard Hook
 *
 * Prevents users from accidentally navigating away from a page when `shouldBlock` is true.
 * - **Blocks page refresh & tab close** using the `beforeunload` event.
 * - **Intercepts back/forward navigation** and shows a prompt.
 *
 * @param {boolean} shouldBlock - If true, navigation will be blocked.
 * @param {string} [message] - Warning message displayed in the toast and confirmation prompt.

 */

import { useEffect, useState } from "react";
import { useToastAlert } from "../components/app/ToastAlert/ToastAlertProvider";

export const useNavigationGuard = (
  shouldBlock: boolean,
  alertMessage: string,
  toastMessage: string,
) => {
  const { showToastAlert } = useToastAlert();
  const [pendingNavigation, setPendingNavigation] = useState(false);

  useEffect(() => {
    if (!shouldBlock) return;

    // Handle reload
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldBlock) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    // Handle history navigation
    const handleNavigation = (event: PopStateEvent) => {
      if (shouldBlock) {
        event.preventDefault();
        setPendingNavigation(true);
        showToastAlert("warning", { message: toastMessage });

        // Push back to previous state to prevent navigation
        history.pushState(null, "", window.location.href);
      }
    };

    if (pendingNavigation) {
      window.alert(alertMessage);
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handleNavigation);

    // Ensure there's a state to revert to
    history.pushState(null, "", window.location.href);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleNavigation);
    };
  }, [shouldBlock, pendingNavigation]);

  return { pendingNavigation };
};
