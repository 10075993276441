import React, { MouseEventHandler } from "react";
import {
  Menu,
  MenuProps,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as DeleteIcon } from "../../icons/Bin.svg";
import { ReactComponent as MoveUpIcon } from "../../icons/NavigationUpCircle.svg";
import { ReactComponent as DuplicateIcon } from "../../icons/DuplicateOutline.svg";
import { ChevronUp, ChevronDown } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    minWidth: theme.spacing(32),
    borderRadius: theme.spacing(1.5),

    "& svg": {
      width: 16,
      height: 16,
    },

    "& .MuiListItem-root": {
      fontWeight: 500,
    },

    "& .MuiListItem-root:hover": {
      backgroundColor: theme.palette.quote,
    },

    "& .MuiListItemIcon-root": {
      margin: theme.spacing(0, -1, 0, 1),
    },
  },
}));

export interface WeekMenuProps extends MenuProps {
  weekId: string;
  onClickDelete: MouseEventHandler<HTMLElement>;
  onClickMove: MouseEventHandler<HTMLElement>;
  onClickDuplicate: MouseEventHandler<HTMLElement>;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
  canDuplicate?: boolean;
  canDelete?: boolean;
}

export function WeekMenu(props: WeekMenuProps) {
  const {
    weekId,
    onClose,
    onClickDelete,
    onClickMove,
    canMoveUp,
    canMoveDown,
    onClickDuplicate,
    canDuplicate,
    canDelete,
    ...other
  } = props;
  const s = useStyles();

  return (
    <Menu
      className={s.root}
      classes={{ paper: s.paper }}
      MenuListProps={{ dense: true }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose}
      {...other}
    >
      <MenuItem
        onClick={onClickMove}
        data-id={weekId}
        data-direction="up"
        dense
        disabled={!canMoveUp}
      >
        <ListItemIcon children={<ChevronUp />} />
        <ListItemText primary="Move Up" />
      </MenuItem>

      <MenuItem
        onClick={onClickMove}
        data-id={weekId}
        data-direction="down"
        dense
        disabled={!canMoveDown}
      >
        <ListItemIcon children={<ChevronDown />} />
        <ListItemText primary="Move Down" />
      </MenuItem>
    </Menu>
  );
}
