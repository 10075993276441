import React from "react";
import { Card, Popover } from "@mui/material";

interface SettingsPopupProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  children: React.ReactNode;
}

export default function SettingsPopup(props: SettingsPopupProps) {
  const { anchorEl, setAnchorEl, children } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Card
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {children}
      </Card>
    </Popover>
  );
}
