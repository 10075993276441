import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { ComponentStatus } from "../../../../../constants";
import dayjs from "dayjs";

export const publishComponentAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    componentId: number;
    title: string;
    content: string;
  }>,
) => {
  const { componentId, content, title } = action.payload;
  const component = state.value.components[componentId];
  if (!component) return;

  state.value.components[componentId] = {
    ...component,
    title: title,
    content: content,
    status: ComponentStatus.PUBLISHED,
    draftExist: false,
    lastPublished: dayjs(),
  };
};
