import React from "react";
import { Button, ButtonProps } from "@mui/material";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(0.75),
    fontSize: 14,
    fontWeight: 700,
    whiteSpace: "nowrap",
    padding: theme.spacing(0.5, 4.5),
    height: 40,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.75, 3.5),
      fontSize: 13,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.75, 3.5),
      fontSize: 12,
    },
  },
  colors: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

interface HeaderButtonProps extends ButtonProps {
  useThemePalette?: boolean;
}

export const HeaderButton: React.FC<HeaderButtonProps> = ({
  useThemePalette = true,
  className,
  ...other
}) => {
  const s = useStyles();
  return (
    <Button
      className={clsx(s.root, useThemePalette && s.colors, className)}
      variant="contained"
      {...other}
    />
  );
};
