import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { CurriculumComponent } from "../../../../types";
import { DraftableDto } from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";
import { ComponentRepeat } from "../../../../../constants";

export const setOverrideDataAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    stateWeekId: number;
    stateBaseComponentId: number;
    days: string;
    requestData: Partial<CurriculumComponent>;
    overrideId: number;
    overrideSlug: string;
    overrideSlugId: string;
    basePreDraft?: DraftableDto;
  }>,
) => {
  const {
    stateWeekId,
    stateBaseComponentId,
    days,
    requestData,
    overrideId,
    overrideSlug,
    overrideSlugId,
    basePreDraft,
  } = action.payload;

  // Get base component
  const baseComponent = state.value.components[stateBaseComponentId];

  // Create new override component

  const overrideComponent = {
    // copy base component data
    ...baseComponent,
    // override with new data
    ...requestData,
    // handle override specific data
    title: baseComponent.title,
    duration: 1,
    repeat: ComponentRepeat.NONE,
    overrides: [],
    baseComponentId: stateBaseComponentId,
    days: days,
    weekId: stateWeekId,
    id: overrideId,
    slugId: overrideSlugId,
    slug: overrideSlug,
    previousStatus: undefined,
    updatedAt: dayjs(),
    draftExist: false,
  };

  // update week
  state.value.weeks[stateWeekId].components.push(overrideId);
  state.value.weeks[stateWeekId].positions.push(overrideId.toString());

  // add override to components
  state.value.components[overrideId] = overrideComponent;

  // update base component
  state.value.components[stateBaseComponentId] = {
    ...baseComponent,
    ...(basePreDraft || {}), // reset base component to pre-draft state if provided
    ...(basePreDraft ? { draftExist: false } : {}), // reset draftExist if pre-draft state provided
    overrides: [...baseComponent.overrides, overrideId],
    updatedAt: dayjs(),
  };
};
