import Url from "url";

import React, { useEffect } from "react";
// TODO_V2_API drop obsolete found
import { Match } from "found";

import { AuthTypeEnum, Plan, planClients, UserRole } from "../../constants";
import { AppThemeProvider } from "../app/ThemeProvider";
import { CurrentUserContext, UserInfo } from "../../hooks/useCurrentUser";
import { useLiveUpdates } from "../../hooks/useLiveUpdates";

import { useSwitchUser } from "../../hooks/useSwitchUser";
import { isMobileApp } from "../../utils/mobile";

import dayjs from "dayjs";
import firebase from "firebase";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import FbUsersService from "../../services/FbUsersService";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import {
  IUpdateUserProfileCommand,
  IWorkspaceInfoDto,
} from "@growth-machine-llc/stridist-api-client";
import useAuth from "../../hooks/auth/useAuth";
import { UpgradedDialog } from "../dialog/payments/UpgradedDialog";

const isIncomplete = (user: UserInfo): boolean => {
  return !(user.displayName && user.birthday && user.location);
};

const getReturnUrl = (url: string) =>
  Url.parse(url, true).query.returnUrl as string;

const getRedirect = (
  user: UserInfo | null,
  authType: AuthTypeEnum,
  currentUrl: string,
) => {
  const returnUrl = getReturnUrl(currentUrl);

  if (/^\/(\?|$)/.test(currentUrl)) {
    return !user
      ? "/login"
      : user.role === UserRole.COACH && !isMobileApp
        ? "/coach/programs"
        : "/home";
  }

  if (user) {
    if (AuthTypeEnum.NOBODY === authType && returnUrl) {
      return returnUrl;
    }

    if (authType === AuthTypeEnum.ADMIN && user.admin) {
      return null;
    } else if (user.role === AuthTypeEnum.CLIENT) {
      if (
        isIncomplete(user) &&
        currentUrl.indexOf("/signup/client/complete") === -1
      ) {
        return (
          "/signup/client/complete?returnUrl=" + encodeURIComponent(currentUrl)
        );
      }

      if ([AuthTypeEnum.COACH, AuthTypeEnum.NOBODY].includes(authType)) {
        return "/home";
      }
    }

    if (user.role === AuthTypeEnum.COACH) {
      if ([AuthTypeEnum.CLIENT, AuthTypeEnum.NOBODY].includes(authType)) {
        return "/coach";
      }
    }
  } else if (authType && authType !== AuthTypeEnum.NOBODY) {
    return "/login?returnUrl=" + encodeURIComponent(currentUrl);
  }
};

const getSwitchableUser = (
  user: UserInfo,
  authType: AuthTypeEnum,
): number | null => {
  if (!user) {
    return null;
  }

  if (![AuthTypeEnum.CLIENT, AuthTypeEnum.COACH].includes(authType)) {
    return null;
  }

  const mirrorUser = user.accounts.find(
    (it) => it.role !== user.role && (it.role === "COACH" || it.isSample),
  );

  return mirrorUser ? mirrorUser.id : null;
};

export interface CurrentUserProviderProps {
  children: React.ReactNode;
  me?: UserInfo;
  workspace: IWorkspaceInfoDto;
  match?: Match;
  liveUpdates?: boolean;
}

export const CurrentUserProvider = (props: CurrentUserProviderProps) => {
  const { mutate: updateUser, isPending: updatingUser } = useMutation({
    mutationKey: ["update-user-timezone"],
    mutationFn: FbUsersService.updateUser,
  });
  const { match, me: user, liveUpdates = true, workspace } = props;
  const { userId } = useAuth();

  const switchUser = useSwitchUser(false);
  const [upgraded, setUpgraded] = React.useState(false);
  const [nextPlan, setNextPlan] = React.useState<Plan | null>(null);

  const handleSubscriptionUpdate = (newPlan) => {
    setUpgraded(true);
    setNextPlan(newPlan);
  };

  const handleCloseUpgradedDialog = () => {
    setUpgraded(undefined);
    setNextPlan(null);
  };

  useLiveUpdates({
    userId: userId ? +userId : null,
    enabled: user?.id && liveUpdates,
    onSubscriptionUpdate: handleSubscriptionUpdate,
  });

  useEffect(() => {
    const Sentry: any = window["Sentry"];
    if (!Sentry) {
      return;
    }
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        Sentry.setUser({ id: user.uid, email: user.email });
      } else {
        Sentry.setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const isActive = match && match.router.isActive(match, location as any);
  const currentUrl = window.location.pathname;
  const authType =
    match &&
    match.routes
      .map(({ authType }) =>
        typeof authType === "function" ? authType(match) : authType,
      )
      .find(Boolean);

  const switchUserId = getSwitchableUser(user, authType);
  let redirect = isActive && getRedirect(user, authType, currentUrl);

  if (
    /^\/login/.test(redirect) &&
    authType === AuthTypeEnum.CLIENT &&
    match.params.program
  ) {
    redirect = redirect.replace(/^(\/login)/, `/${(workspace as any).slug}`);
  }

  React.useEffect(() => {
    if (redirect) {
      if (switchUserId) {
        switchUser(switchUserId);
      } else {
        // TODO change to navigate()
        window.location.href = redirect;
      }
    }
  }, [history, redirect, switchUser, switchUserId]);

  const queryClient = useQueryClient();
  React.useEffect(() => {
    if (user && !user.isImpersonating && !user.timeZone && !updatingUser) {
      updateUser(
        {
          // TODO_API_V2: Drop Number cast after changing user.id to number
          id: user.id,
          timezone: dayjs.tz.guess(),
        } as IUpdateUserProfileCommand,
        {
          onSuccess: (data, variables) => {
            queryClient.setQueryData(
              [CURRENT_USER_QUERY_KEY],
              (prev: UserInfo) => {
                return { ...prev, timeZone: variables.timezone };
              },
            );
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppThemeProvider workspace={workspace}>
      <CurrentUserContext.Provider value={user}>
        {redirect ? null : props.children}
        <UpgradedDialog
          open={Boolean(upgraded)}
          onClose={handleCloseUpgradedDialog}
          title={"Congrats on growing your business"}
          plan={nextPlan}
          navigateToPlanPage={false}
          description={`Your business has been upgraded! Your new plan supports up to ${planClients(nextPlan)} clients.`}
          showPlanButton={true}
        />
      </CurrentUserContext.Provider>
    </AppThemeProvider>
  );
};
