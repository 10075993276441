import clsx from "clsx";
import React, { FormEvent } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { useAnalytics } from "../../hooks/useAnalytics";
import {
  CompactContainer,
  CompactContainerProps,
} from "../container/CompactContainer";

import { useNavigate } from "react-router-dom";
import { HOME_ROUTE } from "../../routes/routes";
import { UserInfo } from "../../hooks/useCurrentUser";
import { UnitSystem } from "@growth-machine-llc/stridist-api-client";

import { useUpdateCurrentUserMutation } from "../../hooks/useUpdateCurrentUser";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  clientCompleteSignUpSchema,
  ClientCompleteSignUpSchema,
} from "../../utils/clientCompleteSignupSchema";
import { ClientSignUpForm } from "../form/ClientCompleteSignUpForm";
import { isUserUsingUSLocale } from "../fields/BodyWeightHeightField";
import { AppLogo } from "../app/AppLogo";
import { Box } from "@mui/material";
import { useCurrentBrand } from "../../hooks/useCurrentWorkspace";
import { ConsentBanner } from "../app/ConsentBanner";

const useStyles = makeStyles((theme) => ({
  root: {},

  submit: {
    margin: theme.spacing(2.5, 0, 3.5, 0),

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(5.5, 0, 6.5, 0),
    },
  },

  header: {
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(2),
  },

  title: {
    display: "flex",
    flexDirection: "row",

    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  logo: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2),
    },
  },

  consentBanner: {
    justifyContent: "center",
  },

  body: {
    display: "flex",
    flexDirection: "column",
  },
}));

export interface SignupClientScreenProps
  extends Omit<CompactContainerProps, "children"> {
  returnUrl?: string;
  user: UserInfo;
}

export function SignupClientCompleteScreen(props: SignupClientScreenProps) {
  const s = useStyles();
  const navigate = useNavigate();
  const { className, user, returnUrl, ...other } = props;
  // TODO_API_V2 STR-1345: Fix settings type after this component is migrated. Replace any with ClientSettings
  const [trackEvent] = useAnalytics();

  const units =
    user.units || isUserUsingUSLocale() ? UnitSystem.US : UnitSystem.METRIC;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
    clearErrors,
    trigger,
    control,
  } = useForm<ClientCompleteSignUpSchema>({
    resolver: zodResolver(clientCompleteSignUpSchema()),
    defaultValues: {
      units,
    },
  });

  const { mutate: updateUser, isPending: signupInFlight } =
    useUpdateCurrentUserMutation();

  const { photoUrl: photoURL } = user;

  const disabled = signupInFlight;

  const onSubmit = React.useCallback(
    (data: ClientCompleteSignUpSchema) => {
      updateUser(
        {
          ...data,
          id: user.id,
          displayName: data.fullName,
          units: data.units as UnitSystem,
          photoUrl: data.picture,
        },
        {
          onSuccess() {
            navigate(HOME_ROUTE);
            trackEvent("Client - Enter Info", data);
          },
        },
      );
    },
    [photoURL, trackEvent, updateUser, user.id],
  );

  const { brandName } = useCurrentBrand();

  return (
    <CompactContainer
      className={clsx(s.root, className)}
      classes={{ header: s.header, body: s.body }}
      maxWidth="sm"
      title={
        <Box className={s.title}>
          <AppLogo className={s.logo} />
          {` ${user.coach.displayName} on ${brandName}`}
        </Box>
      }
      subtitle={" Welcome! First, tell us about yourself."}
      {...other}
    >
      <ClientSignUpForm
        user={user}
        disabled={disabled}
        errors={errors}
        setError={setError}
        register={register}
        control={control}
        setValue={setValue}
        clearErrors={clearErrors}
        watch={watch}
        trigger={trigger}
        onSubmit={handleSubmit(onSubmit)}
      />
      <ConsentBanner className={s.consentBanner} />
    </CompactContainer>
  );
}
