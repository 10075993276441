import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

export const removeComponentAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{ weekId: number; componentId: number }>,
) => {
  const { componentId, weekId } = action.payload;

  const component = state.value.components[componentId];
  if (component && component.baseComponentId) {
    throw new Error("Invalid operation for the overriding component");
  }

  component.overrides.forEach(overrideId => {
    const overrideComponent = state.value.components[overrideId];
    const week = state.value.weeks[overrideComponent.weekId];
    week.components = week.components.filter((id) => id !== overrideId);
    week.positions = week.positions.filter((id) => id !== overrideId.toString());
    delete state.value.components[overrideId];
  });
  
  delete state.value.components[componentId];

  const week = state.value.weeks[weekId];
  if (!week) return;

  week.components = week.components.filter((id) => id !== componentId);
  week.positions = week.positions.filter((id) => id !== componentId.toString());
};
