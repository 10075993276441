import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Typography,
  alpha,
  lighten,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ComponentType, ComponentStatus } from "../../constants";
import { useEnableColors } from "../../hooks/useEnableColors";
import { ComponentIcon } from "../program-component/ComponentIcon";
import { getLockIcon, getStatusIcon } from "../../utils/icons";
import { getColorByComponentType } from "../program-calendar/utils/common";
import { ComponentForCalendarDto } from "@growth-machine-llc/stridist-api-client";
import { FlagTriangleRight } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    background: theme.palette.background.paper,
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },

  icon: {},

  title: {
    wordWrap: "break-word",
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "15px",

    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "initial",
    display: "-webkit-box",
    marginRight: "auto",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },

  clickable: { cursor: "pointer" },

  menuButtonSpacing: {
    ":not(.draggable) [draggable=true]:hover &": {
      marginRight: "20px",
    },

    transition: theme.transitions.create(["margin-right"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },

  showShortened: {
    display: "none",
  },

  "@container (max-width: 115px)": {
    typeIcon: {
      display: "none",
    },
    typeTitle: {
      display: "none",
    },
    showShortened: {
      display: "block",
    },
  },

  "@container (max-width: 70px)": {
    menuButtonSpacing: {
      display: "none",
    },
  },

  typeIcon: {},
  typeTitle: {},
  infoIconContainer: {},
}));

export interface CalendarComponentCardProps
  extends Omit<BoxProps, "title" | "component"> {
  component: ComponentForCalendarDto;
  subtitle?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCardBodyClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCardBodyDoubleClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  isHighlighted?: boolean;
  isOverride?: boolean;
  isHighlightedWithOverdrives?: boolean;
}

export function CalendarComponentCard(props: CalendarComponentCardProps) {
  const {
    className,
    component,
    subtitle,
    onClick,
    onCardBodyClick,
    onCardBodyDoubleClick,
    isHighlighted,
    isOverride,
    isHighlightedWithOverdrives,
    ...other
  } = props;
  const theme = useTheme();
  const enableColors = useEnableColors();
  const { title, type, status, locked } = component;
  const isArchived = status === ComponentStatus.ARCHIVED;
  const s = useStyles();
  const color = getColorByComponentType(type as ComponentType);

  return (
    <Box
      className={clsx(s.root, className, onClick && s.clickable)}
      onClick={onClick}
      onDoubleClick={onCardBodyDoubleClick}
      // Little hack to fix @container collision
      sx={{ p: "1px" }}
      {...other}
    >
      <Box
        sx={{
          // TODO simplify styles with some kind of helpers
          display: "flex",
          alignItems: "center",
          containerType: "inline-size",
          padding: theme.spacing(0.5, 1),
          borderTopRightRadius: theme.spacing(0.5),
          borderTopLeftRadius: theme.spacing(0.5),
          borderStyle: isArchived ? "dashed" : "solid",
          borderColor: theme.palette.quote,
          borderWidth: 1,

          ...(enableColors && {
            color: color,
            borderColor: color,
            backgroundColor: lighten(color, isOverride ? 1 : 0.9),
          }),

          ...(isHighlighted && {
            color: theme.palette.common.white,
            borderColor: theme.palette.common.black,
            backgroundColor: theme.palette.common.black,
            ...(enableColors && {
              color: theme.palette.common.white,
              borderColor: color,
              backgroundColor: color,
            }),
          }),

          transition: theme.transitions.create(
            ["background-color", "border-color"],
            {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            },
          ),
        }}
      >
        <Typography
          className={s.typeTitle}
          sx={{
            textTransform: "uppercase",
            fontWeight: 700,
            lineHeight: "12px",
          }}
          style={{
            fontSize: 10,
          }}
        >
          {subtitle || type}
        </Typography>

        <Typography
          className={s.showShortened}
          sx={{
            textTransform: "uppercase",
            fontWeight: 700,
            lineHeight: "12px",
          }}
          style={{
            fontSize: 10,
          }}
        >
          {subtitle || type[0]}
        </Typography>

        <Box
          className={s.menuButtonSpacing}
          sx={{
            display: "flex",
            justifyContent: "end",
            gap: 0.5,
            marginLeft: "auto",

            color: isHighlighted
              ? theme.palette.common.white
              : theme.palette.common.black,

            "& svg": {
              width: 14,
              height: 14,
            },
          }}
        >
          {getStatusIcon(status as ComponentStatus)}
          {locked && getLockIcon(locked)}
        </Box>
      </Box>
      <Box
        sx={{
          containerType: "inline-size",

          padding: theme.spacing(1),

          display: "flex",
          flexDirection: "column",
          flexGrow: 1,

          borderBottomRightRadius: theme.spacing(0.5),
          borderBottomLeftRadius: theme.spacing(0.5),

          borderStyle: isArchived ? "dashed" : "solid",
          borderColor: theme.palette.quote,
          borderWidth: 1,
          borderTopWidth: 0,

          ...(enableColors && {
            color: color,
            borderColor: color,
          }),

          ...(isHighlighted && {
            borderColor: theme.palette.common.black,
            ...(enableColors && {
              color: color,
              borderColor: color,
              backgroundColor: lighten(color, 0.9),
            }),
          }),

          ...(isHighlightedWithOverdrives && {
            backgroundColor: alpha(color, 0.1),
          }),

          transition: theme.transitions.create(["background", "border-color"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),

          "& svg": {
            width: 16,
            height: 16,
          },

          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={onCardBodyClick}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            marginBlock: "auto",
            containerType: "inline-size",
          }}
        >
          <ComponentIcon
            className={s.typeIcon}
            componentData={component}
            variant="icon"
          />

          <Typography
            className={clsx(s.title, onCardBodyClick && s.clickable)}
            children={title}
          />
          {isOverride && (
            <FlagTriangleRight
              style={{
                minWidth: 18,
                minHeight: 18,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
