import React from "react";
import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import { ComponentIcons } from "../../program/icons";
import { ComponentStatus, ComponentType } from "../../../constants";
import { getLockIcon, getStatusIcon } from "../../../utils/icons";
import WorkoutOverview from "../../program-calendar/WorkoutOverview";
import { CurriculumComponent } from "../../../redux/types";
import dayjs from "dayjs";
import {
  FlagTriangleRight,
  SquarePen,
  ClockArrowUp,
  BellDot,
} from "lucide-react";
import { useDialog } from "../../../contexts/CurriculumComponentDialogContext";
import { getColorByComponentType } from "../../program-calendar/utils/common";
import { useEnableColors } from "../../../hooks/useEnableColors";
import { useTimeFormat } from "../../../hooks/useTimeFormat";

interface IGenericComponentContainerProps {
  componentData: CurriculumComponent;
}

export const ComponentTitle = (title, slug, iconName) => {
  const { openDialog } = useDialog();
  const Icon = ComponentIcons[iconName];
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: " center",
        gap: 1,
        p: 1.5,
        marginBlock: 1,
        borderBottom: 2,

        ":hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => openDialog(slug)}
    >
      <Box
        sx={{
          "& svg": {
            width: 25,
            height: 25,
          },
        }}
      >
        <Icon />
      </Box>
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const SidebarDetailsTab = (props: IGenericComponentContainerProps) => {
  const { componentData } = props;
  const { type, iconName } = componentData;
  const Icon = ComponentIcons[iconName];
  const locked = componentData.locked;
  const timeFormat = useTimeFormat();
  const theme = useTheme();
  const color = getColorByComponentType(type);
  const enableColors = useEnableColors();
  const { openDialog } = useDialog();

  const getMainComponent = () => {
    switch (type) {
      case ComponentType.WORKOUT:
        return <WorkoutOverview result={componentData} />;
      case ComponentType.LESSON:
      case ComponentType.HABIT:
      case ComponentType.CHECKIN:
      case ComponentType.MESSAGE:
        return <></>;

      default:
        break;
    }
  };

  const getStatusIconText = (status: ComponentStatus) => {
    switch (status) {
      case ComponentStatus.ARCHIVED:
        return "Archived";
      case ComponentStatus.DRAFT:
        return "Draft";

      default:
        return "";
    }
  };

  const showReminder =
    Boolean(componentData.reminderType) &&
    componentData.type !== ComponentType.MESSAGE;
  const messageTime = dayjs(componentData.messageTime, "HH:mm");
  const showMessageTime =
    componentData.type === ComponentType.MESSAGE && messageTime.isValid();
  const componentStatus = componentData.status as ComponentStatus;
  const IS_OVERRIDE = !!componentData.baseComponentId;
  const IS_PUBLISHED = componentStatus === ComponentStatus.PUBLISHED;

  return (
    <>
      <Box
        sx={{
          p: 2,
          pt: 0,
          pb: 0,
          overflow: "scroll",
          scrollbarGutter: "stable both-edges",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: 1,
        }}
      >
        {ComponentTitle(
          componentData.title,
          componentData.slug,
          componentData.iconName,
        )}
        {getMainComponent()}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            marginTop: "auto",
            marginBottom: 2,
          }}
        >
          <Divider sx={{ marginBlock: 2, mb: 1 }} />
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {(!componentData?.lastPublished ||
              dayjs(componentData.updatedAt?.toString()).fromNow() !==
                dayjs(componentData.lastPublished?.toString()).fromNow()) && (
              <>
                Last update:{" "}
                <i>{dayjs(componentData.updatedAt?.toString()).fromNow()}</i>
                {componentData?.lastPublished && <br />}
              </>
            )}
            {componentData?.lastPublished && (
              <>
                Published:{" "}
                <i>
                  {dayjs(componentData.lastPublished?.toString()).fromNow()}
                </i>
              </>
            )}
          </Typography>
          <Divider sx={{ marginBlock: 2, mt: 1 }} />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "column",

              "& svg": {
                width: 20,
                height: 20,
              },
            }}
          >
            {IS_OVERRIDE && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <FlagTriangleRight />
                Custom day
              </Box>
            )}

            {!IS_PUBLISHED && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                {getStatusIcon(componentStatus)}
                {getStatusIconText(componentStatus)}
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              {locked && getLockIcon(locked)}
              {locked && "Locked"}
            </Box>

            {showReminder && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <BellDot />
                Reminder
              </Box>
            )}

            {showMessageTime && (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <ClockArrowUp />
                {messageTime.format(timeFormat)}
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            pb: 2,
            position: "sticky",
            bottom: 0,
            background: theme.palette.common.white,
            color: enableColors ? color : theme.palette.common.black,
          }}
        >
          <Button
            fullWidth
            variant="contained"
            startIcon={<SquarePen />}
            sx={{ fontWeight: 600 }}
            onClick={() => openDialog(componentData.slug)}
          >
            Edit
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SidebarDetailsTab;
