import React from "react";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, Box } from "@mui/material";
import NotificationsSettingsService from "../../../services/NotificationsSettingsService";
import { UserRole } from "../../../constants";
import { UserSettingsNotifications } from "../UserSettingsNotifications";

export const USER_NOTIFICATION_SETTINGS_QUERY_KEY =
  "user-notification-settings";

export function CoachSettingsNotificationRoute() {
  const { data: settings, isLoading } = useQuery({
    queryKey: [USER_NOTIFICATION_SETTINGS_QUERY_KEY],
    queryFn: () =>
      NotificationsSettingsService.getCurrentUserNotificationsSettings(),
  });

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        settings && (
          <UserSettingsNotifications
            settings={settings}
            role={UserRole.COACH}
          />
        )
      )}
      <TrackInfoTool
        trackInfo={{
          name: "Client - Notifications Settings",
        }}
      />
    </>
  );
}
