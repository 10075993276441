import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { IconButton, useTheme } from "@mui/material";
import { colorSystem } from "../../theme";

import { InputPlusMinusProps } from "./types";
import PlusCircle from "../../icons/PlusCircle";
import MinusCircle from "../../icons/MinusCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    width: 62,
    height: 56,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.spacing(0.75),
    borderColor: colorSystem.border2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 700,

    [theme.breakpoints.down("md")]: {
      width: 52,
      height: 46,
      fontSize: 15,
    },
  },
  button: {
    margin: theme.spacing(0, 1.2),

    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 0.5),

      "& svg": {
        width: 15,
        height: 15,
      },
    },
  },
}));

function InputPlusMinus(props: InputPlusMinusProps) {
  const { value, onClickPlus, onClickMinus, disabledMin, disabledMax } = props;
  const s = useStyles();
  const theme = useTheme();

  return (
    <div className={s.root}>
      <IconButton
        onClick={onClickMinus}
        className={s.button}
        disabled={disabledMin}
        size="large"
      >
        <MinusCircle
          fill={
            disabledMin
              ? theme.palette.text.disabled
              : theme.palette.primary.main
          }
        />
      </IconButton>
      <div className={s.input}>
        <span>{value}</span>
      </div>
      <IconButton
        onClick={onClickPlus}
        className={s.button}
        disabled={disabledMax}
        size="large"
      >
        <PlusCircle
          fill={
            disabledMax
              ? theme.palette.text.disabled
              : theme.palette.primary.main
          }
        />
      </IconButton>
    </div>
  );
}

export default InputPlusMinus;
