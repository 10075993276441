import clsx from "clsx";
import React from "react";
import {
  Container,
  Card,
  TextField,
  ContainerProps,
  Typography,
  useMediaQuery,
  Grid2,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import InputPlusMinus from "../input-plus-minus/InputPlusMinus";
import { ReactComponent as EndWorkoutIcon } from "../../icons/endWorkout.svg";
import { useLocation, useNavigate } from "react-router-dom";
import TrackInfoTool from "../tools/TrackInfoTool";
import { ActivityEndWorkout } from "../activity/ActivityWorkoutResponse";
import {
  getDaysDiffFromToday,
  getTimeDiff,
  ISO_DATE_FORMAT,
} from "../../utils/date";
import { ActivityLocked } from "../activity/ActivityLocked";
import { ActivityBriefDto } from "@growth-machine-llc/stridist-api-client";
import LoadingActionButton from "../button/LoadingActionButton";
import {
  useWorkoutStartEndMutation,
  WorkoutStartEndParams,
} from "../../hooks/activities/useWorkout";
import { useMutation } from "@tanstack/react-query";
import StorageService from "../../services/StorageService";
import CheckInUploadClientPhoto from "../editor/components/checkin/CheckInUploadClientPhoto";
import { ImageUp } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      fontFamily: "'Montserrat', sans-serif",
      minHeight: "100vh",
      [theme.breakpoints.down("md")]: {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: 0,
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
    },
  },
  valuesContainer: {
    display: "flex",
    marginTop: 32,
    [theme.breakpoints.down("md")]: {
      marginTop: 37,
    },
  },
  value: {
    backgroundColor: colorSystem.gray9,
    padding: "2.5vh 4.4vh",
    marginRight: 2,
    fontWeight: 700,
    fontSize: 28,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3.3),
      fontSize: 20,
    },
  },
  values: {
    "&:first-of-type $value": {
      borderBottomLeftRadius: 14,
      borderTopLeftRadius: 14,
    },
    "&:last-of-type $value": {
      borderBottomRightRadius: 14,
      borderTopRightRadius: 14,
      marginRight: 0,
    },
  },

  index: {
    marginLeft: 1,
    marginRight: 1,
    color: colorSystem.secondaryGray,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "17px",
    marginTop: 8,
    display: "flex",
    justifyContent: "center",
  },
  activeValue_1: {
    backgroundColor: colorSystem.green2,
    color: colorSystem.white,
  },
  activeValue_2: {
    backgroundColor: colorSystem.blue1,
    color: colorSystem.white,
  },
  activeValue_3: {
    backgroundColor: colorSystem.yellow,
    color: colorSystem.white,
  },
  activeValue_4: {
    backgroundColor: colorSystem.orange,
    color: colorSystem.white,
  },
  activeValue_5: {
    backgroundColor: colorSystem.primary,
    color: colorSystem.white,
  },
  activeText_1: {
    color: colorSystem.green2,
  },
  activeText_2: {
    color: colorSystem.blue1,
  },
  activeText_3: {
    color: colorSystem.yellow,
  },
  activeText_4: {
    color: colorSystem.orange,
  },
  activeText_5: {
    color: colorSystem.primary,
  },
  icon: {
    marginTop: 60,
    marginBottom: 60,
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
      marginBottom: 20,
    },
  },
  title: {
    lineHeight: "39px",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      lineHeight: "29px",
    },
  },
  subTitle: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "22px",
    color: colorSystem.gray,
    marginTop: 12,
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  textField: {
    width: "100%",
  },
  trainingText: {
    fontSize: 24,
    lineHeight: "29px",
    fontWeight: "bold",
    marginTop: 60,
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
  },
  trainingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  notes: {
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: "19px",
    marginTop: 18,
    marginBottom: 12,
    color: colorSystem.gray,
  },

  notesContainer: {
    width: "100%",
    maxWidth: 453,
    padding: "0 5px",
    [theme.breakpoints.down("md")]: {
      maxWidth: 370,
      marginBottom: 240,
    },
  },

  buttonContainer: {
    padding: "0 5px",
    width: "100%",
    maxWidth: 375,
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },

  button: {
    paddingTop: 18,
    paddingBottom: 18,
    width: "100%",
    backgroundColor: colorSystem.black,
    borderRadius: 4,
    color: colorSystem.white,
    margin: "40px 0",
    fontSize: 16,
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      margin: "5px 0",
    },
  },
  mediaUploadContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 453,
    padding: "0 5px",
    [theme.breakpoints.down("md")]: {
      maxWidth: 370,
    },
  },
}));

export interface ActivityProps extends Omit<ContainerProps, "children"> {
  activity: ActivityBriefDto;
  username: string;
  coach?: boolean;
}

const EndWorkout = (props: ActivityProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { activity, username } = props;

  const endWorkoutData =
    activity.endWorkout && JSON.parse(activity?.endWorkout);

  const { mutate: createUrl, isPending: creatingUrl } = useMutation({
    mutationKey: ["create-signed-url"],
    mutationFn: StorageService.createSignedUrl,
  });

  const s = useStyles();
  const [time, setTime] = React.useState(endWorkoutData?.time ?? 60);
  const [feel, setFeel] = React.useState(endWorkoutData?.feel ?? "");
  const [notes, setNotes] = React.useState(endWorkoutData?.notes ?? "");
  const [media, setMedia] = React.useState(endWorkoutData?.media ?? "");
  const [uploadingMedia, setUploadingMedia] = React.useState(false);
  const { breakpoints } = useTheme();
  const mdSm = useMediaQuery(breakpoints.down("sm"));

  const { mutate: endWorkout, isPending: upsertActivityInFlight } =
    useWorkoutStartEndMutation({
      username,
      component: activity.component.slug,
      date: activity.date.format(ISO_DATE_FORMAT),
      workoutActionType: "endWorkout",
    });

  const path = location.pathname.replace("end", "summary");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const locked =
    activity.component?.locked && getTimeDiff(activity.date.toISOString()) > 0;

  const handleSubmit = React.useCallback(() => {
    const isEndWorkoutUpdated =
      endWorkoutData?.time !== time ||
      endWorkoutData?.feel !== feel ||
      endWorkoutData?.notes !== notes ||
      endWorkoutData?.media !== media;
    if (isEndWorkoutUpdated) {
      const input: WorkoutStartEndParams = {
        id: activity.id,
        workoutAction: JSON.stringify({ feel, notes, time, media }),
      };
      endWorkout(input, {
        onSuccess: () => {
          navigate(path);
        },
      });
    } else {
      navigate(path);
    }
  }, [activity.id, endWorkout, feel, notes, time, path, media]);

  const handleActivityClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      handleSubmit();
    },
    [handleSubmit],
  );

  const handleChangePlusTime = React.useCallback(() => {
    setTime((prev) => prev + 5);
  }, []);

  const handleChangeMinusTime = React.useCallback(() => {
    if (time) setTime((prev) => prev - 5);
  }, [time]);

  const handleTextFieldChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setNotes(value);
    },
    [],
  );

  const values: Array<string> = ["V.Ease", "Ease", "Medium", "Hard", "V.Hard"];
  return (
    <>
      <Container maxWidth="md" className={clsx(s.container)}>
        {locked ? (
          <Grid2
            sx={{
              mt: 20,
            }}
          >
            <ActivityLocked
              daysDiff={getDaysDiffFromToday(activity.date.toISOString())}
            />
          </Grid2>
        ) : (
          <Card className={clsx(s.cardContainer)}>
            <EndWorkoutIcon
              width={mdSm ? 80 : 120}
              height={mdSm ? 80 : 120}
              className={clsx(s.icon)}
            />
            <Typography variant="h3" className={clsx(s.title)}>
              End of workout survey
            </Typography>
            <div className={clsx(s.subTitle)}>How did you feel?</div>
            <div className={clsx(s.valuesContainer)}>
              {values.map((value, index) => (
                <div key={value} className={s.values}>
                  <div
                    onClick={() => setFeel(`${index + 1} ${value}`)}
                    className={clsx(
                      s.value,
                      feel === `${index + 1} ${value}` &&
                        s[`activeValue_${index + 1}`],
                    )}
                  >
                    {index + 1}
                  </div>
                  <div
                    className={clsx(
                      s.index,
                      feel === `${index + 1} ${value}` &&
                        s[`activeText_${index + 1}`],
                    )}
                  >
                    {value}
                  </div>
                </div>
              ))}
            </div>
            <div className={clsx(s.trainingText)}>Training Time</div>
            <div className={s.trainingContainer}>
              <InputPlusMinus
                value={time}
                onClickPlus={handleChangePlusTime}
                onClickMinus={handleChangeMinusTime}
              />
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                }}
              >
                minutes
              </Typography>
            </div>
            <div className={clsx(s.container, s.mediaUploadContainer)}>
              <div className={s.notes}>Have any photos to share?</div>
              <CheckInUploadClientPhoto
                name=""
                createUrl={createUrl}
                idEntityType="User"
                id={activity.id.toString()}
                value={media}
                onChange={(_, url) => setMedia(url)}
                uploadingPhoto={uploadingMedia}
                disabled={uploadingMedia}
                setUploadingPhoto={setUploadingMedia}
                icon={<ImageUp />}
                photoLimit={5}
                multiple
              />
            </div>
            <div className={s.notesContainer}>
              <div className={clsx(s.notes)}>Notes</div>
              <TextField
                className={s.textField}
                placeholder="ex. Need to buy a bandage for the bench press"
                variant="outlined"
                fullWidth
                rows={3}
                multiline
                value={notes}
                onChange={handleTextFieldChange}
              />
            </div>
            <div className={s.buttonContainer}>
              <LoadingActionButton
                onClick={handleActivityClick}
                className={clsx(s.button)}
                disabled={!feel || uploadingMedia}
                loading={upsertActivityInFlight}
              >
                Confirm
              </LoadingActionButton>
            </div>
          </Card>
        )}
      </Container>
      <TrackInfoTool
        trackInfo={{
          name: "Client - Program",
        }}
      />
    </>
  );
};

export default EndWorkout;
