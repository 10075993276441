import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  divider: {
    height: 1,
    width: 300,
    backgroundColor: colorSystem.secondaryGray,
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    padding: theme.spacing(0, 2),
    color: theme.palette.common.black,
    backgroundColor: colorSystem.white2,
    position: "absolute",
  },
}));

export interface OrDividerProps extends BoxProps {
  textClassName?: string;
}

export function OrDivider(props: OrDividerProps) {
  const { className, textClassName, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <Divider className={s.divider} />
      <Typography className={clsx(s.text, textClassName)} children="or" />
    </Box>
  );
}
