import clsx from "clsx";
import React from "react";
import {
  Select,
  SelectProps,
  MenuItem,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useTimeZones } from "../../hooks/useTimeZones";

import { FieldsGroup } from "./FieldsGroup";
import { Control, Controller } from "react-hook-form";
import { FieldError } from "./FieldError";

const useStyles = makeStyles((theme) => ({
  root: {},

  select: {
    fontWeight: 500,
    backgroundColor: theme.palette.background.paper,
  },

  separator: {
    width: "100%",
  },

  error: {
    position: "absolute",
  },
}));

export interface TimezoneFieldProps<T = { timezone?: string }>
  extends Omit<SelectProps, "error" | "onChange"> {
  error?: boolean;
  errorMessage?: string;
  control: Control<T>;
}

export function TimezoneField(props: TimezoneFieldProps) {
  const {
    className,
    error,
    control,
    errorMessage = "Please select a time zone",
    ...other
  } = props;
  const s = useStyles();
  const timeZones = useTimeZones();

  return (
    <FieldsGroup label="Time Zone">
      <Controller
        name="timezone"
        control={control}
        defaultValue={timeZones.length > 0 ? timeZones[0].value : ""}
        render={({ field }) => (
          <Select
            className={s.select}
            variant="outlined"
            displayEmpty
            fullWidth
            error={error}
            {...field}
            {...other}
          >
            <MenuItem disabled>Timezone</MenuItem>
            {timeZones.map(({ value, label, separate }) => [
              <MenuItem value={value} children={label} />,
              separate && (
                <MenuItem disabled>
                  <Divider className={s.separator} />
                </MenuItem>
              ),
            ])}
          </Select>
        )}
      />
      {error && (
        <FieldError hideIcon={true} className={s.error}>
          {errorMessage}
        </FieldError>
      )}
    </FieldsGroup>
  );
}
