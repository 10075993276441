import React from "react";
import TrackInfoTool from "../../../components/tools/TrackInfoTool";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AdminService from "../../../services/AdminService";
import { Box, Skeleton } from "@mui/material";
import { AdminProgramActivityDetails } from "../../../components/admin/AdminProgramActivityDetails";

const ADMIN_PROGRAM_QUERY_KEY = "admin-program-activity-details";

export function AdminProgramRoute() {
  const queryClient = useQueryClient();
  const { program: programSlug } = useParams();
  const {
    data: program,
    isLoading,
    isPending,
  } = useQuery({
    queryKey: [ADMIN_PROGRAM_QUERY_KEY, { programSlug }],
    queryFn: () => AdminService.getProgramActivityDetails(programSlug),
  });

  const invalidateQuery = () =>
    queryClient.invalidateQueries({
      queryKey: [ADMIN_PROGRAM_QUERY_KEY, { programSlug }],
    });

  React.useEffect(() => {
    if (program) {
      window.document.title = program.name;
    }
  }, [program]);

  const {
    mutate: regenerateActivitiesMutation,
    isPending: regenerateActivitiesIsPending,
  } = useMutation({
    mutationKey: ["regenetate-activities"],
    mutationFn: AdminService.regenerateActivities,
    onSuccess: invalidateQuery,
  });

  const {
    mutate: fixProgramLengthMutation,
    isPending: fixProgramLengthIsPending,
  } = useMutation({
    mutationKey: ["fix-program-length"],
    mutationFn: AdminService.fixProgramLength,
    onSuccess: invalidateQuery,
  });

  const handleRegenerateActivities = (enrollmentIds: number[]) => {
    regenerateActivitiesMutation(enrollmentIds);
  };

  const handleSyncLength = () => {
    fixProgramLengthMutation(program.id);
  };

  return (
    <>
      {isLoading && (
        <>
          <Box>
            <Skeleton animation="wave" width={600} height={40} />
            <Skeleton
              sx={{ marginTop: 6 }}
              animation="wave"
              width={100}
              height={30}
            />
            {Array.from({ length: 4 }).map((_, i) => (
              <Skeleton key={i} animation="wave" width={900} height={50} />
            ))}
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              {Array.from({ length: 3 }).map((_, i) => (
                <Skeleton key={i} animation="wave" width={400} height={40} />
              ))}
            </Box>
          </Box>
        </>
      )}
      {program && (
        <>
          <TrackInfoTool
            trackInfo={{
              name: "Admin - Programs",
            }}
          />
          <AdminProgramActivityDetails
            program={program}
            onRegenerateActivities={handleRegenerateActivities}
            updating={
              isPending ||
              regenerateActivitiesIsPending ||
              fixProgramLengthIsPending
            }
            onSyncLength={handleSyncLength}
          />
        </>
      )}
    </>
  );
}
