import clsx from "clsx";
import React from "react";
import { Button, Card, CardProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as BlankProgramIcon } from "../../icons/BlankProgram.svg";
import { TextButton } from "../button/TextButton";
import { CreateProgramDialog } from "../dialog/CreateProgramDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.secondary,
  },

  header: {
    color: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& svg": {
      marginBottom: theme.spacing(2),
    },
  },

  button: {
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
    borderStyle: "solid",
    fontWeight: 700,
    fontSize: 13,
    padding: theme.spacing(0.5, 3),
    marginTop: theme.spacing(2),
  },

  text: {
    fontSize: 14,
  },
}));

export interface CardProgramTemplateBlankProps extends CardProps {}

export function CardProgramTemplateBlank(props: CardProgramTemplateBlankProps) {
  const { className, ...other } = props;
  const s = useStyles();
  const [openBlankDialog, setOpenBlankDialog] = React.useState(false);

  const handleToggleBlankDialog = React.useCallback(
    () => setOpenBlankDialog((value) => !value),
    [],
  );

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Typography variant="subtitle1" className={s.header}>
        <BlankProgramIcon /> Blank Program
      </Typography>
      <Typography className={s.text} gutterBottom>
        Build a program from scratch
      </Typography>

      <Button
        className={s.button}
        variant="contained"
        onClick={handleToggleBlankDialog}
      >
        Create program
      </Button>

      {openBlankDialog && (
        <CreateProgramDialog open onClose={handleToggleBlankDialog} />
      )}
    </Card>
  );
}
