import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

import { MoreMenuButton } from "../button/MoreMenuButton";
import { LibraryExerciseMenu } from "../menu/LibraryExerciseMenu";
import { ExerciseAsset } from "../exercise-library/types";
import { useCurrentUser } from "../../hooks/useCurrentUser";

import { WorkoutExercise } from "../workout/types";
import { DraggableSnippet } from "../editor/components/utils/DraggableSnippet";
import { WORKOUT_SECTION } from "../new-editor/components/workout/WorkoutSectionElement";
import { ComponentLibraryAssetSource } from "../component-library/ComponentLibraryAsset";
import OptimisticUpdateContainer from "../loading/OptimisticUpdateContainer";
import { ThumbnailVideoBadge } from "./ThumbnailVideoBadge";
import { useStylesExerciseOptionStyles } from "./ExerciseAutocompleteOption";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: theme.spacing(9),
    gap: theme.spacing(1),
    paddingRight: theme.spacing(4),
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.palette.selected.light,
    },
  },

  draggingContent: {
    gap: theme.spacing(1.2),
    width: "100%",
    paddingRight: theme.spacing(4),
  },

  moreButton: {
    position: "absolute",
    right: theme.spacing(4),
    opacity: 0,

    "$root:hover &": {
      opacity: 1,
    },
  },
}));

export interface Asset {
  id: number;
  name: string;
  content: string;
  coachId: number;
}

export interface ExerciseLibraryExerciseProps
  extends Omit<BoxProps, "onClick"> {
  asset: Asset;
  onClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: ExerciseAsset,
  ) => void;
}
export interface IMinimizedExerciseTooltipProps {
  canEdit: boolean;
}

export function ExerciseLibraryExercise(props: ExerciseLibraryExerciseProps) {
  const { className, asset, onClick, ...other } = props;
  const s = useStyles();
  const exerciseOptionStyles = useStylesExerciseOptionStyles();
  const user = useCurrentUser();
  const { id: assetId, name, content, coachId } = asset;
  const menuState = usePopupState({
    popupId: "library-exercise-menu",
    variant: "popper",
  });
  const optionContent = JSON.parse(content) as WorkoutExercise;

  const canEdit = user.id === coachId;
  const handleClick = React.useCallback(
    (event) => {
      const exercise = JSON.parse(content) as WorkoutExercise;
      const value: ExerciseAsset = {
        assetId,
        exercise,
      };

      if (onClick) {
        onClick(event, value);
      }
    },
    [assetId, content, onClick],
  );

  const handleMenuClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      menuState.open(event);
    },
    [menuState],
  );

  return (
    <OptimisticUpdateContainer id={assetId} className={clsx(s.root, className)}>
      <DraggableSnippet
        content={{
          element: {
            type: WORKOUT_SECTION,
            children: [{ text: "" }],
            workout: {
              exercises: [JSON.parse(content) as WorkoutExercise],
              title: "",
            },
          },
          source: ComponentLibraryAssetSource,
        }}
        draggableContent
        className={s.draggingContent}
      >
        <Box
          className={clsx(s.root, className)}
          onClick={handleClick}
          {...other}
        >
          <ThumbnailVideoBadge
            border={canEdit}
            size={48}
            video={optionContent.video}
          />

          <Box className={exerciseOptionStyles.contentContainer}>
            <Typography className={exerciseOptionStyles.title}>
              {name}
            </Typography>
            {optionContent.instructions && (
              <Typography
                variant="caption"
                className={exerciseOptionStyles.instructions}
              >
                {optionContent.instructions}
              </Typography>
            )}
          </Box>

          {canEdit && (
            <>
              <MoreMenuButton
                {...bindTrigger(menuState)}
                className={s.moreButton}
                onClick={handleMenuClick}
              />
              {menuState.isOpen && (
                <LibraryExerciseMenu
                  {...bindMenu(menuState)}
                  assetId={assetId}
                />
              )}
            </>
          )}
        </Box>
      </DraggableSnippet>
    </OptimisticUpdateContainer>
  );
}
