import { IProgramsCountDto } from "@growth-machine-llc/stridist-api-client";
import {
  COACH_PROGRAMS_ARCHIVED_ROUTE,
  COACH_PROGRAMS_DRAFT_ROUTE,
  COACH_PROGRAMS_FOLDERS_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
} from "../../routes/routes";
import { Box, Skeleton } from "@mui/material";
import React from "react";
import InlineCounter from "../loading/InlineCounter";

export const programsNavigation = (count: IProgramsCountDto) => [
  {
    name: "Published",
    link: COACH_PROGRAMS_PUBLISHED_ROUTE,
  },
  {
    name: (
      <InlineCounter
        count={count.draft}
        label="Draft"
        loading={!count.draft && count.draft !== 0}
      />
    ),
    link: COACH_PROGRAMS_DRAFT_ROUTE,
  },
  {
    name: "Archived",
    link: COACH_PROGRAMS_ARCHIVED_ROUTE,
  },
  {
    name: "Folders",
    link: COACH_PROGRAMS_FOLDERS_ROUTE,
  },
];
