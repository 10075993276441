import React, { createContext, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ActivityBriefDto } from "@growth-machine-llc/stridist-api-client";
import { Box } from "@mui/material";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { DefaultLoader } from "../../../components/loading/DefaultLoader";
import { UserRole } from "../../../constants";
import { useActivityComponent } from "../../../hooks/activities/useActivity";

interface ProgramComponentContextProps {
  activity: ActivityBriefDto;
  isLoadingInitial: boolean;
  isSecondaryLoading: boolean;
  isPlaceholderData: boolean;
}

const ProgramComponentContext = createContext<
  ProgramComponentContextProps | undefined
>(undefined);

const useProgramComponentRoute = () => {
  const context = useContext(ProgramComponentContext);
  if (context === undefined) {
    throw new Error(
      "useProgramComponent must be used within a ProgramComponentProvider",
    );
  }
  return context;
};

const ProgramsComponentRouteWrapper = () => {
  const params = useParams();
  const { date, component: componentSlug } = params;

  const { username: currentUsername, role } = useCurrentUser();
  const username = role === UserRole.COACH ? params.client : currentUsername;
  const { activity, isLoadingInitial, isSecondaryLoading, isPlaceholderData } =
    useActivityComponent({
      username,
      date,
      componentSlug,
    });
  return (
    <ProgramComponentContext.Provider
      value={{
        activity,
        isLoadingInitial,
        isSecondaryLoading,
        isPlaceholderData,
      }}
    >
      {isLoadingInitial ? (
        <Box
          sx={{
            height: "100vh",
          }}
        >
          <DefaultLoader fillParent />
        </Box>
      ) : (
        <Outlet />
      )}
    </ProgramComponentContext.Provider>
  );
};

export { ProgramsComponentRouteWrapper, useProgramComponentRoute };
