import { call, select } from "redux-saga/effects";
import { TempIdsMap } from "../../../../api/api-slice";
import {
  selectComponentTempIdsMap,
  selectWeekTempIdsMap,
} from "../../../../api/selectors";
import ComponentsService from "../../../../../services/ComponentsService";
import {
  ComponentRepeat,
  IUpdateComponentCommand,
  ReminderType,
} from "@growth-machine-llc/stridist-api-client";
import { Schedule } from "../../../../../components/schedule/types";
import { stringifyComponentDays } from "../../../../../utils/component";

export default function* updateComponentScheduleWorker(payload: {
  componentId: number;
  nullifyReminder?: boolean;
  schedule: Schedule;
}) {
  const { componentId, schedule, nullifyReminder } = payload;

  const map: TempIdsMap = yield select(selectComponentTempIdsMap);
  const checkedComponentId = map?.[componentId] || componentId;

  const targetWeekId = Number(schedule.weekId);

  const weekMap: TempIdsMap = yield select(selectWeekTempIdsMap);
  const weekId = weekMap?.[targetWeekId] || targetWeekId;

  const updatedSchedule = {
    ...schedule,
    weekId,
    days: stringifyComponentDays(schedule.days),
  };

  const command: IUpdateComponentCommand = {
    id: checkedComponentId,
    weekId,
    duration: updatedSchedule.duration,
    repeat: updatedSchedule.repeat as ComponentRepeat,
    reminderTimeString: updatedSchedule.reminderTime || null,
    messageTimeString: updatedSchedule.messageTime || null,
    reminderType: updatedSchedule.reminderType as ReminderType,
    days: stringifyComponentDays(schedule.days),
    nullifyReminder,
  };

  try {
    yield call(ComponentsService.update, command);
  } catch (error) {
    console.error(error);
    throw error;
  }
}
