import clsx from "clsx";
import React from "react";
import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useThumbnail } from "../../hooks/useThumbnail";
import { AppLogo } from "../app/AppLogo";

const useStyles = makeStyles((theme) => ({
  thumbnailBorder: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
  },
  logoBorder: { borderRadius: 4 },
}));

export interface ThumbnailVideoBadgeProps {
  video: string;
  border: boolean;
  size?: number;
}

export function ThumbnailVideoBadge(props: ThumbnailVideoBadgeProps) {
  const { video, border, size = 48 } = props;
  const s = useStyles();
  const videoThumbnail = useThumbnail(video);

  return videoThumbnail ? (
    <Avatar
      sx={{ width: size, height: size }}
      className={border && s.thumbnailBorder}
      src={videoThumbnail}
      alt="Video thumbnail"
    />
  ) : (
    <AppLogo
      className={border ? s.thumbnailBorder : s.logoBorder}
      size={size}
      width={size}
      height={size}
    />
  );
}
