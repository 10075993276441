import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { generateId } from "../../../../../components/new-editor/utils/nodeUtil";
import { CurriculumState } from "../../../curriculum-slice";
import dayjs from "dayjs";

export const duplicateComponentAction = {
  prepare(props: { weekId: number; componentId: number }) {
    const { weekId, componentId } = props;
    const newComponentId = generateId();
    return {
      payload: {
        weekId,
        componentId,
        newComponentId,
      },
    };
  },
  reducer(
    state: Draft<CurriculumState>,
    action: PayloadAction<{
      weekId: number;
      componentId: number;
      newComponentId: number;
    }>,
  ) {
    const { weekId, componentId, newComponentId } = action.payload;
    if (!weekId || !componentId) return;

    // Get target component
    const targetComponent = state.value.components[componentId];
    if (!targetComponent) return;

    if (targetComponent.baseComponentId) {
      throw new Error("Cannot duplicate a component that is an override");
    }

    // Generate new component
    const newComponent = {
      ...targetComponent,
      id: newComponentId,
      previousStatus: undefined,
      weekId,
      slug: `${newComponentId}-tempslug`,
      updatedAt: dayjs(),
      title: `${targetComponent.title}`,
      overrides: [],
      baseComponentId: null,
    };

    // Find target index
    const componentsArr = state.value.weeks[weekId].components;
    const componentIndex = componentsArr.findIndex((id) => id === componentId);

    // Add to weeks.components arr
    state.value.weeks[weekId].components = [
      ...componentsArr.slice(0, componentIndex + 1),
      newComponent.id,
      ...componentsArr.slice(componentIndex + 1),
    ];

    // Find target index in weeks.positions
    const positionsArr = state.value.weeks[weekId].positions;
    const positionIndex = positionsArr.findIndex(
      (id) => id === componentId.toString(),
    );

    // Add to weeks.positions arr
    state.value.weeks[weekId].positions = [
      ...positionsArr.slice(0, positionIndex + 1),
      newComponent.id.toString(),
      ...positionsArr.slice(positionIndex + 1),
    ];

    // Add to components
    state.value.components[newComponent.id] = newComponent;
  },
};
