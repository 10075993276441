import React from "react";
import { Card, Typography, Popover, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ClientActivityInfo } from "@growth-machine-llc/stridist-api-client";
import { useActivitiesPopover } from "./hooks/useActivitiesPopover";
import ComponentActivitiesTable from "./ComponentActivitiesTable";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    cursor: "pointer",
    maxWidth: 200,
  },
  popoverContent: {
    padding: theme.spacing(2),
  },
}));

interface OutOfScheduleActivitiesProps {
  activities: ClientActivityInfo[];
  weekNumber: number;
}

const OutOfScheduleActivities: React.FC<OutOfScheduleActivitiesProps> = ({
  activities,
  weekNumber,
}) => {
  const classes = useStyles();

  const { bind, handleMouseEnter, handleMouseLeave } = useActivitiesPopover(
    "out-of-schedule-activities-popover",
  );

  return (
    <>
      <Card
        className={classes.card}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography variant="body2">
          Out of Schedule Activities: {activities.length}
        </Typography>
      </Card>
      <Popover {...bind}>
        <Box className={classes.popoverContent}>
          <Typography variant="subtitle2" gutterBottom>
            Out of Schedule Activities Week {weekNumber}
          </Typography>
          <ComponentActivitiesTable activities={activities} />
        </Box>
      </Popover>
    </>
  );
};

export default OutOfScheduleActivities;
