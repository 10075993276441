import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Switch,
  TextField,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { IAdminStats } from "@growth-machine-llc/stridist-api-client";
import AdminService from "../../services/AdminService";

const ADMIN_STATS_QUERY_KEY = "admin-stats";

const AdminStatsView: React.FC = () => {
  const [extended, setExtended] = useState<boolean>(false);
  const [programId, setProgramId] = useState<number | null>(null);

  const [previousData, setPreviousData] = useState<IAdminStats | null>(null);

  const {
    data: currentData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [ADMIN_STATS_QUERY_KEY, { extended, programId }],
    queryFn: () => AdminService.getAdminStats(extended, programId),
    enabled: false,
  });

  const handleGetData = async () => {
    refetch();
    setPreviousData(currentData);
  };

  return (
    <Box sx={{ p: 2, width: 400 }}>
      <Typography variant="h5" gutterBottom>
        Admin Stats
      </Typography>

      {/* Extended toggle */}
      <FormControlLabel
        control={
          <Switch
            checked={extended}
            onChange={() => setExtended((prev) => !prev)}
            color="primary"
          />
        }
        label="Extended"
      />

      {/* Program ID input */}
      <TextField
        label="Program ID"
        type="number"
        value={programId ?? ""}
        onChange={(e) => {
          const val = e.target.value;
          setProgramId(val === "" ? null : Number(val));
        }}
        fullWidth
        margin="normal"
      />

      {/* 'Get' button */}
      <Button variant="contained" onClick={handleGetData}>
        Get
      </Button>

      {/* Loading state */}
      {isFetching && (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Loading...
        </Typography>
      )}

      {/* Display previous and current data */}
      <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
        <Typography variant="subtitle1">Previous</Typography>
        {previousData ? (
          <Box sx={{ ml: 2 }}>
            <Typography variant="body2">total: {previousData.total}</Typography>
            <Typography variant="body2">
              completed: {previousData.totalTouchedByUsers}
            </Typography>
            <Typography variant="body2">
              programTotal: {previousData.programTotal}
            </Typography>
            <Typography variant="body2">
              programCompleted: {previousData.programTotalTouchedByUsers}
            </Typography>
            <Typography variant="body2">
              form-requests: {previousData.formRequestsCount}
            </Typography>
            <Typography variant="body2">
              werCount: {previousData.werCount}
            </Typography>
            <Typography variant="body2">
              user-exercises: {previousData.userExercisesCount}
            </Typography>
            <Typography variant="body2">
              body-measurements: {previousData.bodyMeasurementsCount}
            </Typography>
            <Typography variant="body2">
              body-weight: {previousData.bodyWeightCount}
            </Typography>
            <Typography variant="body2">
              check-in-answers: {previousData.checkInAnswersCount}
            </Typography>
            <Typography variant="body2">
              photo-sets: {previousData.photoSetsCount}
            </Typography>
            <Typography variant="body2">
              feedbacks: {previousData.feedbackCount}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2" sx={{ ml: 2 }}>
            No previous data
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Current
        </Typography>
        {currentData ? (
          <Box sx={{ ml: 2 }}>
            <Typography variant="body2">total: {currentData.total}</Typography>
            <Typography variant="body2">
              completed: {currentData.totalTouchedByUsers}
            </Typography>
            <Typography variant="body2">
              programTotal: {currentData.programTotal}
            </Typography>
            <Typography variant="body2">
              programCompleted: {currentData.programTotalTouchedByUsers}
            </Typography>
            <Typography variant="body2">
              form-requests: {currentData.formRequestsCount}
            </Typography>
            <Typography variant="body2">
              werCount: {currentData.werCount}
            </Typography>
            <Typography variant="body2">
              user-exercises: {currentData.userExercisesCount}
            </Typography>
            <Typography variant="body2">
              body-measurements: {currentData.bodyMeasurementsCount}
            </Typography>
            <Typography variant="body2">
              body-weight: {currentData.bodyWeightCount}
            </Typography>
            <Typography variant="body2">
              check-in-answers: {currentData.checkInAnswersCount}
            </Typography>
            <Typography variant="body2">
              photo-sets: {currentData.photoSetsCount}
            </Typography>
            <Typography variant="body2">
              feedbacks: {currentData.feedbackCount}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body2" sx={{ ml: 2 }}>
            No current data
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AdminStatsView;
