import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { HistoryBlock } from "../history-block/HistoryBlock";
import { AppLayout } from "../app/AppLayout";
import { useQueryParam } from "../../hooks/useQueryParam";
import { maybePluralize } from "../../utils/text";
import {
  SelectAddableClientsDialog,
  SelectAddableClientsDialogProps,
} from "../coach-clients/SelectAddableClientsDialog";
import { ClientForm } from "../client-forms/ClientForm";

import { useNavigate, useParams } from "react-router-dom";
import { COACH_CLIENTS_FORMS_ROUTE } from "../../routes/routes";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import ClientsService from "../../services/ClientsService";
import ClientFormsCoachService from "../../services/ClientFormsCoachService";
import { LoadingPage } from "../loading/LoadingPage";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { RefreshSlug } from "../routes/RefreshSlug";
import {
  ClientFormCoachInfoDto,
  UserBriefInfoDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import { DefaultLoader } from "../loading/DefaultLoader";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    position: "relative",
    containerType: "inline-size",
  },
}));

export const COACH_CLIENTS_FORM_QUERY_KEY = "coach-clients-form";

export function CoachClientsFormScreen() {
  const { slug } = useParams();
  const slugId = slug.split("-").pop();
  const { data: clientForm, isLoading } = useQuery({
    queryKey: [COACH_CLIENTS_FORM_QUERY_KEY, slugId],
    queryFn: () => ClientFormsCoachService.getForm(slug),
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });
  const navigate = useNavigate();
  const s = useStyles();
  const [previewQueryParam] = useQueryParam("preview", false);
  const previewing = Boolean(previewQueryParam);
  const [openSelectClients, setOpenSelectClients] = React.useState(false);
  const queryClient = useQueryClient();
  const { showToastAlert } = useToastAlert();
  const { mutate: sendForm, isPending: SendFormMutationInFlight } = useMutation(
    {
      mutationFn: (clients: UserBriefInfoDto[]) =>
        ClientFormsCoachService.sendClientFormBatch({
          clients: clients.map((c) => c.id),
          formId: clientForm.id,
        }),
      onSuccess: (_, clients) => {
        queryClient.setQueriesData(
          {
            queryKey: [COACH_CLIENTS_FORM_QUERY_KEY, slugId],
          },
          (oldData: ClientFormCoachInfoDto) => {
            return ClientFormCoachInfoDto.fromJS({
              ...oldData,
              clients: [...oldData.clients, ...clients],
            });
          },
        );
      },
    },
  );

  const handleSendForm = React.useCallback(
    () => setOpenSelectClients(true),
    [],
  );

  const handleCloseClientsDialog = React.useCallback(() => {
    setOpenSelectClients(false);
  }, []);

  const handleSelectClients: SelectAddableClientsDialogProps["onSelectClients"] =
    React.useCallback(
      (clients) =>
        sendForm(clients, {
          onSuccess: () => {
            showToastAlert("success", {
              message: `Form sent to ${maybePluralize(
                clients.length,
                "client",
              )}.`,
            });
            navigate(COACH_CLIENTS_FORMS_ROUTE);
          },
        }),
      [clientForm?.id, sendForm],
    );

  return (
    <AppLayout appDrawer={false} appBar={false} hideUpgradeBanner>
      {isLoading ? (
        <DefaultLoader fillScreen />
      ) : (
        <>
          <Box className={s.root}>
            <RefreshSlug
              slugInfo={{
                slug: clientForm?.slug,
                slugId: clientForm?.slugId,
              }}
            />
            <HistoryBlock>
              <ClientForm
                clientForm={clientForm}
                onSendForm={handleSendForm}
                readOnly={previewing}
              />
            </HistoryBlock>
          </Box>

          {openSelectClients && (
            <SelectAddableClientsDialog
              open
              title="Send Client Intake Form"
              subtitle="Select one or more clients to send this form to."
              onSelectClients={handleSelectClients}
              onClose={handleCloseClientsDialog}
              SubmitButtonProps={{
                children: "Send form",
              }}
              selectedClientEmails={clientForm.clients.map(
                (client) => client.email,
              )}
              disabled={SendFormMutationInFlight}
            />
          )}
        </>
      )}
    </AppLayout>
  );
}
