import { Share } from "@capacitor/share";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { isMobileApp } from "./mobile";

import dumbbell from "../icons/workout-stats/dumbbell.svg";
import stopwatch from "../icons/workout-stats/timer.svg";
import ruler from "../icons/workout-stats/ruler.svg";

export type CanvasDrawFn = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
) => void;

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export const downloadImage = async (blob: Blob, filename: string) => {
  if (isMobileApp) {
    const file = await Filesystem.writeFile({
      path: `stridist_progress/${filename}`,
      directory: Directory.Cache,
      data: await blobToBase64(blob),
      recursive: true,
    });
    await Share.share({
      title: "Stridist progress",
      text: "Check out my progress with Stridist",
      files: [file.uri],
    });
    await Filesystem.deleteFile({
      path: `stridist_progress/${filename}`,
      directory: Directory.Cache,
    });
  } else {
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const drawImageWithAspect = (
  img: ImageBitmap,
  img_aspect: number,
): CanvasDrawFn => {
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    const img_sw =
      img.height / img_aspect > img.width ? img.width : img.height / img_aspect;
    const img_sh = img_sw * img_aspect;
    ctx.drawImage(
      img,
      (img.width - img_sw) / 2,
      (img.height - img_sh) / 2,
      img_sw,
      img_sh,
      x,
      y,
      w,
      h,
    );
  };
};

interface TextOptions {
  lineHeight?: number;
  alignment?: "left" | "right" | "center";
  maxWidth?: number;
  bold?: boolean;
  italic?: boolean;
  fontSize?: number;
  fontFamily?: string;
  color?: string;
  outlineColor?: string;
}

const roundedRect = (r: number): CanvasDrawFn => {
  return (ctx, x, y, w, h) => {
    ctx.moveTo(x + r, y);
    ctx.lineTo(x + w - r, y);
    ctx.quadraticCurveTo(x + w, y, x + w, y + r);
    ctx.lineTo(x + w, y + h - r);
    ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
    ctx.lineTo(x + r, y + h);
    ctx.quadraticCurveTo(x, y + h, x, y + h - r);
    ctx.lineTo(x, y + r);
    ctx.quadraticCurveTo(x, y, x + r, y);
  };
};

export const text = (text: string, textOptions?: TextOptions): CanvasDrawFn => {
  const options = {
    lineHeight: 48,
    ...textOptions,
  };
  return (ctx, x, y, w, h) => {
    const text_lines = text.split("\n");
    let text_height = (text_lines.length - 1) * options.lineHeight;
    ctx.font = `${options.bold ? "bold " : ""}${options.italic ? "italic " : ""}${options.fontSize || options.lineHeight}px ${options.fontFamily || "Montserrat"}`;
    ctx.fillStyle = options.color || "white";
    ctx.strokeStyle = options.outlineColor;
    switch (options.alignment) {
      case "right":
        for (const line of text_lines) {
          options.outlineColor &&
            ctx.strokeText(
              line,
              x - ctx.measureText(line).width,
              y - text_height,
              options.maxWidth,
            );
          ctx.fillText(
            line,
            x - ctx.measureText(line).width,
            y - text_height,
            options.maxWidth,
          );
          text_height -= options.lineHeight;
        }
        break;

      case "center":
        for (const line of text_lines) {
          options.outlineColor &&
            ctx.strokeText(
              line,
              x + w / 2 - ctx.measureText(line).width / 2,
              y - text_height,
              options.maxWidth,
            );
          ctx.fillText(
            line,
            x + w / 2 - ctx.measureText(line).width / 2,
            y - text_height,
            options.maxWidth,
          );
          text_height -= options.lineHeight;
        }
        break;

      default:
      case "left":
        for (const line of text_lines) {
          options.outlineColor &&
            ctx.strokeText(line, x, y - text_height, options.maxWidth);
          ctx.fillText(line, x, y - text_height, options.maxWidth);
          text_height -= options.lineHeight;
        }
        break;
    }
  };
};

export const drawImageWithText = (
  img: ImageBitmap,
  s: string,
  img_aspect: number,
): CanvasDrawFn => {
  const text_margin = 15;
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    drawImageWithAspect(img, img_aspect)(ctx, x, y, w, h);

    ctx.lineJoin = "round";
    ctx.miterLimit = 2;
    ctx.lineWidth = 3;

    text(s, { bold: true, outlineColor: "black" })(
      ctx,
      x + text_margin,
      y + h - text_margin,
      w,
      h,
    );
  };
};

export const mergeImages = (
  img1: ImageBitmap,
  img2: ImageBitmap,
  text1?: string,
  text2?: string,
): CanvasDrawFn => {
  const img_margin = 15;
  const text_margin = img_margin * 2;
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    drawImageWithAspect(img1, 2)(ctx, x, y, (w - img_margin) / 2, h);
    drawImageWithAspect(img2, 2)(
      ctx,
      x + (w + img_margin) / 2,
      y,
      (w - img_margin) / 2,
      h,
    );

    vignetteGradient({})(ctx, x, y, (w - img_margin) / 2, h);
    vignetteGradient({})(
      ctx,
      x + (w + img_margin) / 2,
      y,
      (w - img_margin) / 2,
      h,
    );

    ctx.lineJoin = "round";
    ctx.miterLimit = 2;
    ctx.lineWidth = 3;

    text1 &&
      text(text1, { bold: true })(
        ctx,
        x + text_margin,
        y + h - text_margin,
        w,
        h,
      );

    text2 &&
      text(text2, { bold: true, alignment: "right" })(
        ctx,
        x + w - text_margin,
        y + h - text_margin,
        w,
        h,
      );
  };
};

export const drawSvg = (svgUrl: string, color: string): CanvasDrawFn => {
  return async (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    const svg = await fetch(svgUrl);
    const svgText = (await svg.text()).replace(
      'stroke="currentColor"',
      `stroke="${color}"`,
    );
    const img = new Image();
    img.src = `data:image/svg+xml,${encodeURIComponent(svgText)}`;
    img.onload = () => {
      ctx.drawImage(img, x, y, w, h);
    };
  };
};

export const badge = (
  icon: string,
  color: string,
  title: string,
  text_options?: TextOptions,
): CanvasDrawFn => {
  return (ctx, x, y, w, h) => {
    drawSvg(icon, color)(ctx, x, y, h, h);
    text(title, { bold: true, ...text_options })(
      ctx,
      x + h + 20,
      y + h / 2 + 10,
      w - h - 20,
      h,
    );
  };
};

export const spread_row = (elements: CanvasDrawFn[]): CanvasDrawFn => {
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    const element_width = w / elements.length;
    for (let i = 0; i < elements.length; i++) {
      elements[i](ctx, x + i * element_width, y, element_width, h);
    }
  };
};

export const column = (
  elements: CanvasDrawFn[],
  element_height: number,
): CanvasDrawFn => {
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    for (let i = 0; i < elements.length; i++) {
      elements[i](ctx, x, y + i * element_height, w, element_height);
    }
  };
};

export const padding = (
  element: CanvasDrawFn,
  padding: number,
): CanvasDrawFn => {
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    element(ctx, x + padding, y + padding, w - padding * 2, h - padding * 2);
  };
};

export const generateWatermakedCanvasImage = async (
  imageGenerator: CanvasDrawFn,
  canvasRef: any,
  watermarkUrl: string,
) => {
  const canvas: HTMLCanvasElement = canvasRef?.current;
  const ctx = canvas.getContext("2d");

  const padding = 30;
  const bottom_margin = 72;
  const img_h = 1000;
  const img_w = 1000;
  const watermark_h = 95;

  if (canvas) {
    canvas.height = img_h + padding * 2 + bottom_margin;
    canvas.width = img_w + padding * 2;

    // fill the background
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "white";
    ctx.fill();

    const watermarkImg = await getImageBitmap(watermarkUrl);
    const watermark_w =
      (watermarkImg.width / watermarkImg.height) * watermark_h;
    ctx.drawImage(
      watermarkImg,
      (canvas.width - watermark_w) / 2,
      img_h - 100 + padding * 2 + bottom_margin,
      watermark_w,
      watermark_h,
    );

    // draw clipping box to round image corners
    ctx.beginPath();
    roundedRect(30)(ctx, padding, padding, img_w, img_h);
    ctx.closePath();
    ctx.clip();

    // draw images
    imageGenerator(ctx, padding, padding, img_w, img_h);
  }
};

export interface WorkoutStat {
  icon: string;
  value: number;
  title: string;
  unit?: string;
}

const statsRow = (stats: WorkoutStat[]) =>
  Promise.all(
    stats.map(async (stat) =>
      padding(
        badge(stat.icon, "white", `${stat.value} ${stat.unit}`, {
          fontSize: 32,
          alignment: "left",
        }),
        50,
      ),
    ),
  );

export const vignetteGradient = ({
  top = false,
  bottom = true,
}: {
  top?: boolean;
  bottom?: boolean;
}): CanvasDrawFn => {
  return (ctx, x, y, w, h) => {
    const gradient = ctx.createLinearGradient(x, y, x, h + y);

    if (top) {
      gradient.addColorStop(0, "rgba(0, 0, 0, 0.7)");
      gradient.addColorStop(0.1, "rgba(0, 0, 0, 0.4)");
      gradient.addColorStop(0.2, "rgba(0, 0, 0, 0)");
    }
    if (bottom) {
      gradient.addColorStop(0.8, "rgba(0, 0, 0, 0)");
      gradient.addColorStop(0.9, "rgba(0, 0, 0, 0.4)");
      gradient.addColorStop(1, "rgba(0, 0, 0, 0.7)");
    }
    ctx.fillStyle = gradient;

    ctx.fillRect(x, y, w, h);
  };
};

export const drawImageWithStats = async (
  imageUrl: string,
  stats: WorkoutStat[],
): Promise<CanvasDrawFn> => {
  const image = await getImageBitmap(imageUrl);
  const rowStats = await statsRow(stats);
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    imageUrl && drawImageWithAspect(image, 0.9)(ctx, x, y, w, h);

    vignetteGradient({ top: true })(ctx, x, y, w, h);

    spread_row(rowStats)(ctx, x, h - 120, w, 170);
  };
};

const statsCard = (svgUrl: string, stat: WorkoutStat): CanvasDrawFn => {
  return padding((...args) => {
    const ctx = args[0];
    ctx.fillStyle = "black";
    ctx.beginPath();
    roundedRect(20)(...args);
    ctx.closePath();
    ctx.fill();
    padding((...args) => {
      const [ctx, x, y, w, h] = args;
      drawSvg(svgUrl, "white")(ctx, x + w / 2 - h / 4, y, h / 2, h / 2);
      text(
        `${stat.value} ${stat.unit && stat.unit != stat.title?.toLowerCase() ? stat.unit : ""}`,
        {
          fontSize: 84,
          bold: true,
          alignment: "center",
        },
      )(ctx, x + 10, y + h / 2 + 100, w, h);
      text(stat.title, {
        fontSize: 32,
        bold: true,
        alignment: "center",
      })(ctx, x, y + h / 2 + 170, w, h);
    }, 50)(...args);
  }, 20);
};

export const drawStatsGrid = async (
  stats: WorkoutStat[],
): Promise<CanvasDrawFn> => {
  const rowStats = await Promise.all(
    stats.map(async (stat) => statsCard(stat.icon, stat)),
  );
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    column(
      [spread_row(rowStats.slice(0, 2)), spread_row(rowStats.slice(2))],
      450,
    )(ctx, x, y + h / 2 - 450, w, h);
  };
};

export const drawExerciseList = async (
  workout: any,
  stats: WorkoutStat[],
): Promise<CanvasDrawFn> => {
  const rowStats = await statsRow(stats);

  const exerciseIcons = workout.exercises.map((exercise) =>
    exercise.typeSet === "WEIGHT"
      ? dumbbell
      : exercise.typeSet === "TIME"
        ? stopwatch
        : ruler,
  );
  return (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
  ) => {
    padding(
      column(
        [
          ...workout.exercises.slice(0, 10).map((exercise, i) => {
            return padding(
              badge(
                exerciseIcons[i],
                "black",
                `${exercise.sets.filter((set) => set.checked).length} x ${exercise.title}`,
                {
                  fontSize: 32,
                  bold: true,
                  color: "black",
                },
              ),
              10,
            );
          }),
          ...(workout.exercises.length > 10
            ? [
                padding(
                  text(`+${workout.exercises.length - 10} more`, {
                    fontSize: 32,
                    bold: true,
                    color: "black",
                  }),
                  40,
                ),
              ]
            : []),
        ],
        70,
      ),
      60,
    )(ctx, x, y, w, h);

    const gradient = ctx.createLinearGradient(x, y, x, h + y);

    gradient.addColorStop(0.8, "rgba(0, 0, 0, 0)");
    gradient.addColorStop(0.9, "rgba(0, 0, 0, 0.2)");
    gradient.addColorStop(1, "rgba(0, 0, 0, 0.5)");

    ctx.fillStyle = gradient;

    ctx.fillRect(x, y, w, h);

    spread_row(rowStats)(ctx, x, h - 120, w, 170);
  };
};

export const downloadCanvas = (
  canvasRef: any,
  filename: string,
): Promise<void> => {
  const canvas: HTMLCanvasElement = canvasRef?.current;

  return new Promise((resolve, reject) => {
    try {
      canvas.toBlob((blob) => {
        resolve(downloadImage(blob, filename));
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const convertUrl = (url: string) => {
  const match = url.match(/https:\/\/([^.]+)\.stridist\.com/);
  if (match) {
    const prefix = match[1];
    return url.replace(
      `https://${prefix}.stridist.com`,
      `https://storage.googleapis.com/${prefix}.stridist.com`,
    );
  }
  return url;
};

export const getImageBitmap = async (url: string) => {
  const img = await fetch(url);
  return await createImageBitmap(await img.blob());
};
