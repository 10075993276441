import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE_NAMES } from "../utils";

export interface TempIdsMap {
  // key is the temp id, value is the api-returned id
  [key: string]: number;
}

export interface DebugWarning {
  path: string;
  optimistic: string;
  actual: string;
}

export interface ApiState {
  tempIds: {
    components: TempIdsMap;
    weeks: TempIdsMap;
  };
  loading: boolean;
  errors: {
    hasError: boolean;
    message?: string;
  };
  // TODO_V2_API:
  // TODO_CURRICULUM:
  // TODO: shouldRefetchCurriculum may not be necessary anymore (fix of publish with duration > 1)
  shouldRefetchCurriculum: boolean;
  latestDebugWarnings?: DebugWarning[];
}

const initialState: ApiState = {
  tempIds: {
    components: {},
    weeks: {},
  },
  loading: false,
  errors: {
    hasError: false,
    message: "",
  },
  shouldRefetchCurriculum: false,
  latestDebugWarnings: [],
};

const apiSlice = createSlice({
  name: SLICE_NAMES.API,
  initialState,
  reducers: {
    updateTempIdMap: (
      state,
      action: PayloadAction<{
        type: "components" | "weeks";
        tempId: string;
        realId: number;
      }>,
    ) => {
      const { type, tempId, realId } = action.payload;
      state.tempIds[type][tempId] = realId;
    },
    updateComponentsTempIds: (
      state,
      action: PayloadAction<
        {
          tempId: string;
          realId: number;
        }[]
      >,
    ) => {
      action.payload.forEach(({ tempId, realId }) => {
        state.tempIds.components[tempId] = realId;
      });
    },
    setError: (state, action: PayloadAction<string>) => {
      state.errors.hasError = true;
      state.errors.message = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShouldRefetchCurriculum: (state, action: PayloadAction<boolean>) => {
      state.shouldRefetchCurriculum = action.payload;
    },
    setLatestDebugWarnings: (state, action: PayloadAction<DebugWarning[]>) => {
      state.latestDebugWarnings = action.payload;
    },
  },
});

export const {
  updateTempIdMap,
  updateComponentsTempIds,
  setLoading,
  setError,
  setShouldRefetchCurriculum,
  setLatestDebugWarnings,
} = apiSlice.actions;

export default apiSlice.reducer;
