import clsx from "clsx";
import React, { Suspense, useState, useTransition } from "react";
import {
  Card,
  CardProps,
  Box,
  Typography,
  Button,
  Portal,
  useMediaQuery,
  useTheme,
  Grid2,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowForwardRounded } from "@mui/icons-material";
import {
  bindPopper,
  bindToggle,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import omit from "lodash.omit";

import { useClient } from "../../hooks/useClient";
import { ReactComponent as NutritionTargetsIcon } from "../../icons/NutritionTargets.svg";
import { ReactComponent as EditIcon } from "../../icons/PenEdit.svg";
import ClientAutoNutritionDetails from "../client-auto-nutrition/ClientAutoNutritionDetails";
import ClientAutoNutritionItem from "../client-auto-nutrition/ClientAutoNutritionItem";
import EmptyText from "../client-auto-nutrition/EmptyText";
import LastSync from "../client-auto-nutrition/LastSync";
import { useQuery } from "@tanstack/react-query";

import { ClientAutoNutritionDrawer } from "./ClientAutoNutritionDrawer";
import { ClientNutritionTargetEmpty } from "./ClientNutritionTargetEmpty";
import { ClientNutritionTargetDrawer } from "./ClientNutritionTargetDrawer";
import { ClientNutritionTargetList } from "./ClientNutritionTargetList";
import NutritionEntriesService from "../../services/NutritionEntriesService";
import dayjs from "dayjs";
import NutritionTargetsService from "../../services/NutritionTargetsService";
import { NutritionTrackingType } from "../../utils/nutrition";
import { formatDateFromMilitary } from "../../utils/date";
import { useTimeFormat } from "../../hooks/useTimeFormat";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },

  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "29px",
    color: theme.palette.common.black,
    marginBottom: 4,
  },

  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  actionButton: {
    fontSize: "1rem",
    fontWeight: 600,
    height: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },

  actionButtonIcon: {
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
  },
}));

export interface ClientNutritionCardProps extends CardProps {
  client?: { clientId?: number; username?: string };
  refresh?: () => void;
  handleAddNutrition?: any;
  isProgram?: boolean;
  readonly?: boolean;
}

export const CLIENT_NUTRITION_ENTRIES_QUERY_KEY =
  "client-nutrition-entries-key";
export const CLIENT_NUTRITION_TARGETS_QUERY_KEY =
  "client-nutrition-targets-key";
export const CLIENT_NUTRITION_ENTRIES_REFETCH_QUERY_KEY =
  "client-nutrition-entries-refetch-key";

export function ClientNutritionCard(props: ClientNutritionCardProps) {
  const {
    className,
    client,
    refresh,
    handleAddNutrition,
    isProgram,
    readonly,
    ...other
  } = props;
  const s = useStyles();
  const { breakpoints } = useTheme();
  const mdUp = useMediaQuery(breakpoints.up("md"));
  const timeFormat = useTimeFormat();
  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-nutrition",
  });
  const { clientId, username } = client;
  const { data: nutritionTargets } = useQuery({
    queryKey: [CLIENT_NUTRITION_TARGETS_QUERY_KEY, { username }],
    queryFn: () => NutritionTargetsService.getNutritionTargets(username),
  });

  const filteredTargetsByPortions = nutritionTargets?.filter(
    (target) => target.trackingType === NutritionTrackingType.PORTIONS,
  );

  const filteredTargetsByMacros = nutritionTargets?.filter(
    (target) => target.trackingType === NutritionTrackingType.MACROS,
  );

  const { data: nutritionEntries } = useQuery({
    queryKey: [CLIENT_NUTRITION_ENTRIES_QUERY_KEY, { username }],
    queryFn: () => NutritionEntriesService.getNutritionEntries(clientId),
  });

  const filteredNutritionEntries = nutritionEntries?.filter(
    (entry) => entry.date && entry.date.isSame(dayjs(), "day"),
  );

  const todayTotalNutrition = filteredNutritionEntries?.reduce(
    (totals, entry) => {
      return Object.entries(entry).reduce((result, [key, value]) => {
        if (key in result) {
          return {
            ...result,
            [key]: result[key] + (+value || 0),
          };
        }
        return result;
      }, totals);
    },
    { calories: 0, carbs: 0, fat: 0, protein: 0 },
  );

  // NOTE: Used to determine if the card is viewed by a coach in the client profile.
  // IMPORTANT: Works only on `Overview` route.
  const viewedClient = useClient();
  const isCoachView = Boolean(viewedClient);

  const autoNutritionDetailsDialogState = usePopupState({
    variant: "popover",
    popupId: "auto-nutrition-details",
  });

  const autoEditDrawerState = usePopupState({
    variant: "popover",
    popupId: "auto-nutrition-edit-drawer",
  });

  const showSyncTime =
    dayjs(filteredNutritionEntries?.[0]?.lastModified.toDate() || 0).diff(
      dayjs(nutritionTargets?.[0]?.lastModified.toDate() || 0),
    ) >= 0;

  const [isPending, startTransition] = useTransition();

  const handleMoreInfoClick = () => {
    startTransition(() => {
      autoNutritionDetailsDialogState.open();
    });
  };

  return (
    <>
      <Card
        sx={{ ...(isProgram && { boxShadow: 0 }) }}
        className={clsx(s.root, className)}
        {...other}
      >
        <Grid2
          container
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid2>
            <Grid2
              container
              wrap="nowrap"
              sx={{
                alignItems: "center",
              }}
            >
              <NutritionTargetsIcon />
              <Grid2>
                <Box
                  sx={{
                    ml: 2,
                  }}
                >
                  <Grid2 container direction="column">
                    <Typography variant="h6" className={s.title}>
                      Nutrition
                    </Typography>

                    <Grid2
                      container
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <Grid2>
                        {showSyncTime ? (
                          <LastSync
                            value={formatDateFromMilitary(
                              filteredNutritionEntries?.[0]?.lastModified.toString(),
                              timeFormat,
                              true,
                            )}
                          />
                        ) : (
                          !!nutritionTargets?.length && (
                            <Typography variant="body1" className={s.subtitle}>
                              Last recorded{" "}
                              {formatDateFromMilitary(
                                filteredNutritionEntries?.[0]?.lastModified.toString(),
                                timeFormat,
                                true,
                              )}
                            </Typography>
                          )
                        )}
                      </Grid2>
                      {!isCoachView && !readonly && (
                        <IconButton
                          {...bindToggle(autoEditDrawerState)}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Grid2>
                  </Grid2>
                </Box>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2>
            {!readonly && (
              <Grid2
                container
                sx={{
                  alignItems: "center",
                }}
              >
                {Boolean(nutritionTargets?.length) &&
                  mdUp &&
                  Boolean(viewedClient) && (
                    <Button
                      className={s.actionButton}
                      endIcon={
                        <ArrowForwardRounded className={s.actionButtonIcon} />
                      }
                      {...bindToggle(drawerState)}
                    >
                      Create
                    </Button>
                  )}
                <Button
                  className={s.actionButton}
                  endIcon={
                    <ArrowForwardRounded className={s.actionButtonIcon} />
                  }
                  onClick={handleMoreInfoClick}
                  disabled={isPending}
                >
                  More Info
                </Button>
              </Grid2>
            )}
          </Grid2>
        </Grid2>

        <Box
          sx={{
            my: 4,
          }}
        >
          {filteredNutritionEntries?.length ? (
            <Grid2 container spacing={2}>
              <Grid2
                size={{
                  xs: 6,
                  sm: "grow",
                }}
              >
                <ClientAutoNutritionItem
                  value={todayTotalNutrition.calories}
                  label="Calories"
                />
              </Grid2>
              <Grid2
                size={{
                  xs: 6,
                  sm: "grow",
                }}
              >
                <ClientAutoNutritionItem
                  value={todayTotalNutrition.carbs}
                  label="Carbs"
                  unit="g"
                />
              </Grid2>
              <Grid2
                size={{
                  xs: 6,
                  sm: "grow",
                }}
              >
                <ClientAutoNutritionItem
                  value={todayTotalNutrition.fat}
                  label="Fat"
                  unit="g"
                />
              </Grid2>
              <Grid2
                size={{
                  xs: 6,
                  sm: "grow",
                }}
              >
                <ClientAutoNutritionItem
                  value={todayTotalNutrition.protein}
                  label="Protein"
                  unit="g"
                />
              </Grid2>
            </Grid2>
          ) : !isCoachView ? (
            <EmptyText />
          ) : (
            <></>
          )}
        </Box>

        {autoNutritionDetailsDialogState.isOpen && (
          <ClientAutoNutritionDetails
            open={autoNutritionDetailsDialogState.isOpen}
            onClose={autoNutritionDetailsDialogState.close}
            autoNutritionMacroTarget={nutritionTargets}
            autoNutritionLastSync={filteredNutritionEntries?.[0]?.lastModified?.format(
              "h:mm A, MMM DD, YYYY",
            )}
            client={client}
          />
        )}
        {nutritionTargets?.length ? (
          <>
            {Boolean(filteredTargetsByMacros?.length) && (
              <ClientNutritionTargetList
                title="Macro targets"
                targets={filteredTargetsByMacros}
                refresh={refresh}
                isProgram={isProgram}
                handleAddNutrition={handleAddNutrition}
                {...bindToggle(drawerState)}
              />
            )}
            {Boolean(filteredTargetsByPortions?.length) && (
              <ClientNutritionTargetList
                title="Portion targets"
                targets={filteredTargetsByPortions}
                refresh={refresh}
                isProgram={isProgram}
                handleAddNutrition={handleAddNutrition}
                {...bindToggle(drawerState)}
              />
            )}
          </>
        ) : (
          <ClientNutritionTargetEmpty {...bindToggle(drawerState)} />
        )}
      </Card>
      {drawerState.isOpen && (
        <Portal>
          <ClientNutritionTargetDrawer
            {...omit(bindPopper(drawerState), ["anchorEl"])}
            onClose={drawerState.close}
            nutritionTargets={nutritionTargets || []}
            refresh={refresh}
            clientInfo={client}
          />
        </Portal>
      )}
      {autoEditDrawerState.isOpen && (
        <Suspense fallback={<div></div>}>
          <Portal>
            <ClientAutoNutritionDrawer
              {...omit(bindPopper(autoEditDrawerState), ["anchorEl"])}
              onClose={autoEditDrawerState.close}
              refresh={refresh}
              entries={nutritionEntries || []}
              clientInfo={client}
            />
          </Portal>
        </Suspense>
      )}
    </>
  );
}
