import React from "react";
import { ProgramDetailsViewMode } from "../program/ProgramDetailsViewButton";
import { ProgramCalendarWeeksList } from "../program-calendar/ProgramCalendarWeeksList";
import { ProgramWeeksList } from "../program/ProgramWeeksList";
import { Filters } from "./filters/ComponentTypeFilter";
import { ProgramSpreadsheet } from "../program-workout-spreadsheet/ProgramSpreadsheet";
import { useCurriculumSelector } from "../../redux/hooks";
import { useDeferredSidebarOpening } from "../../hooks/curriculum/useDeferredSidebarOpening";
import { NormalizedCurriculumProgram } from "../../redux/types";
import { WeekMenu } from "../program-week/WeekMenu";
import { selectWeeks } from "../../redux/curriculum/selectors/curriculum";
import ComponentDialog from "./ComponentDialog";
import { SpreadsheetInfoDrawer } from "../program-workout-spreadsheet/instructions/SpreadsheetInfoDrawer";
import AddNewWeek from "./AddNewWeek";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import Sidebar, {
  CURRICULUM_SIDEBAR_WIDTH,
} from "../curriculum-sidebar/Sidebar";
import { useDialog } from "../../contexts/CurriculumComponentDialogContext";
import WeekNavigation from "../screen/curriculum/WeekNavigation";
import { getCommonTransition } from "../../utils/mui";
import DeleteConfirmationTooltip from "./modals/DeleteConfirmation.tsx/DeleteConfirmationTooltip";

export type ComponentClickHandlerType = (
  componentSlug: string,
  clickedComponent?: {
    week: string;
    day: string;
  },
  preventClick?: boolean,
) => void;

interface ProgramDetailsProps {
  program: NormalizedCurriculumProgram;
  viewMode: ProgramDetailsViewMode;
  filters: Filters;
  weeksFilter: number[];
  enableSidebar: boolean;
  startDate: string;
  weeks: number[];
  handleAddWeek?: () => void;
  handleDeleteWeek?: (event, setMoreMenuEl) => void;
  handleDuplicateWeek?: (event, closeMoreMenu) => void;
  handleMove?: (event, closeMoreMenu) => void;
  handleWeeksDateRangeChange: (value: number[]) => void;
  weekDaysFilter: number[];
}

export function ProgramDetails(props: ProgramDetailsProps) {
  const {
    program,
    viewMode,
    filters,
    weeksFilter,
    enableSidebar,
    startDate,
    weeks: allWeeks,
    handleAddWeek,
    handleDeleteWeek,
    handleDuplicateWeek,
    handleMove,
    weekDaysFilter,
    handleWeeksDateRangeChange,
  } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const weeksList = useCurriculumSelector(selectWeeks);
  useDeferredSidebarOpening();

  const [moreMenuEl, setMoreMenuEl] = React.useState(null);
  const [weekId, setWeekId] = React.useState(null);

  const {
    isOpen: isSidebarOpen,
    openSidebar,
    componentSlug: currentSidebarComponentSlug,
  } = useSidebar();
  const { openDialog } = useDialog();

  const hasNext =
    weeksFilter.sort((a, b) => a - b)[weeksFilter.length - 1] < program.length;

  const weeks = allWeeks || [];
  const weekIndex = program.weeks.findIndex((id) => id == weekId);
  const weekData = weeksList[weekId];

  const canMoveUp = weekIndex > 0;
  const canMoveDown = weekIndex < weeks.length - 1;

  const IS_LIST = viewMode === ProgramDetailsViewMode.LIST;
  const IS_CALENDAR = viewMode === ProgramDetailsViewMode.CALENDAR;
  const IS_SHEETS = viewMode === ProgramDetailsViewMode.SPREADSHEET;

  const openMoreMenu = React.useCallback((event) => {
    setWeekId(event.currentTarget.dataset.id);
    setMoreMenuEl(event.currentTarget);
  }, []);

  const closeMoreMenu = React.useCallback(() => {
    setMoreMenuEl(null);
  }, []);

  const WeeksList = React.useMemo(() => {
    switch (viewMode) {
      case ProgramDetailsViewMode.LIST:
        return ProgramWeeksList;
      case ProgramDetailsViewMode.CALENDAR:
        return ProgramCalendarWeeksList;
      case ProgramDetailsViewMode.SPREADSHEET:
        return ProgramSpreadsheet;
      default:
        throw new Error(`Not implemented ${viewMode} program view.`);
    }
  }, [viewMode]);

  const WeeksComponent = () => {
    const getFilterWeeks = () => {
      return weeksFilter
        .sort((a, b) => a - b)
        .filter((index) => index > 0 && index <= program.weeks.length)
        .map((index) => program.weeks[index - 1]);
    };

    const componentClickHandler: ComponentClickHandlerType = (
      componentSlug: string,
      clickedComponent?: {
        week: string;
        day: string;
      },
      preventClick?: boolean,
    ) => {
      if (preventClick) return;

      // Open sidebar only for large screen
      if (!isMd && enableSidebar) {
        // Open dialog on sheets when on second click
        // (sidebar is opened for clicked component)
        if (
          viewMode === ProgramDetailsViewMode.SPREADSHEET &&
          isSidebarOpen &&
          componentSlug === currentSidebarComponentSlug
        ) {
          openDialog(componentSlug, clickedComponent);
          return;
        }

        openSidebar(componentSlug);
        return;
      }
      openDialog(componentSlug, clickedComponent);
    };

    const componentDoubleClickHandler: ComponentClickHandlerType = (
      componentSlug: string,
      clickedComponent?: {
        week: string;
        day: string;
      },
      preventClick?: boolean,
    ) => {
      if (preventClick) return;

      openDialog(componentSlug, clickedComponent);
    };

    return (
      <Box display={"flex"} position={"relative"}>
        <Box
          overflow={"unset"}
          flex={1}
          sx={{
            ...(IS_LIST && {
              paddingInline: 4,
              maxWidth: 1050,
            }),
            ...(IS_CALENDAR && {
              paddingInline: 4,
              paddingTop: 2,
              maxWidth: 1050,
            }),
            ...(IS_SHEETS && {
              overflowX: "scroll",
            }),

            zIndex: theme.zIndex.appBar,
            ml: "auto",
            mr: !isSidebarOpen
              ? IS_SHEETS
                ? 0
                : `${-CURRICULUM_SIDEBAR_WIDTH}px`
              : 0,
            transition: getCommonTransition(theme, "margin-right"),
          }}
        >
          <WeeksList
            filters={filters}
            onOpenMenu={openMoreMenu}
            program={program}
            startDate={startDate}
            weeks={getFilterWeeks()}
            weeksLength={program.weeks.length}
            weeksActions={{
              onClickDelete: (e) => handleDeleteWeek(e, setMoreMenuEl),
              onClickDuplicate: (e) => handleDuplicateWeek(e, closeMoreMenu),
            }}
            weekDaysFilter={weekDaysFilter}
            componentClickHandler={componentClickHandler}
            componentDoubleClickHandler={componentDoubleClickHandler}
            weeksByNumber={weeksFilter}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              ...(IS_SHEETS && {
                mb: 3,
                position: "sticky",
                left: 32,
                right: 32,
                width: `calc(100% - ${theme.spacing(2 * 4)})`,
              }),
            }}
          >
            <AddNewWeek handleAddWeek={handleAddWeek} isShown={!hasNext} />

            <WeekNavigation
              visibleWeeks={weeksFilter}
              handleWeeksDateRangeChange={handleWeeksDateRangeChange}
              programLength={program.length}
            />
          </Box>
        </Box>

        {IS_SHEETS ? (
          <>
            <Box position={"absolute"} height={1} right={0}>
              <Sidebar visibleWeeks={weeksFilter} />
            </Box>
          </>
        ) : (
          <>
            <Sidebar visibleWeeks={weeksFilter} />
          </>
        )}
      </Box>
    );
  };

  const WeekMenuComponent = () => {
    return (
      Boolean(moreMenuEl) && (
        <WeekMenu
          id="week-menu"
          weekId={weekId}
          open={true}
          anchorEl={moreMenuEl}
          onClose={closeMoreMenu}
          onClickDelete={(e) => handleDeleteWeek(e, setMoreMenuEl)}
          onClickMove={(e) => handleMove(e, closeMoreMenu)}
          canMoveUp={canMoveUp}
          canMoveDown={canMoveDown}
          onClickDuplicate={(e) => handleDuplicateWeek(e, closeMoreMenu)}
          canDuplicate={weekData?.components?.length === 0}
          canDelete={weeks.length > 1}
        />
      )
    );
  };

  return (
    <>
      {WeeksComponent()}
      {WeekMenuComponent()}
      <ComponentDialog />
      <SpreadsheetInfoDrawer />
      <DeleteConfirmationTooltip />
    </>
  );
}
