import React from "react";
import { SidebarTabs } from "../../constants";
import CalendarSidebarTitle from "./components/CalendarSidebarTitle";
import ScheduleWithMap from "./tabs/ScheduleWithMap";
import SidebarDetailsTab from "./tabs/SidebarDetailsTab";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectComponentBySlugId } from "../../redux/curriculum/selectors/curriculum";
import { extractSlugId } from "../../utils/slug";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import BottomNavigation from "./components/BottomNavigation";
import PreviewTab from "./tabs/PreviewTab";
import { Box } from "@mui/material";
import {
  SIDEBAR_BOTTOM_NAVIGATION_HEIGHT,
  SIDEBAR_HEADING_HEIGHT,
} from "./Sidebar";
import { getColorByComponentType } from "../program-calendar/utils/common";

interface ISidebarContentProps {
  slug: string;
  visibleWeeks?: number[];
}

const SidebarContent = (props: ISidebarContentProps) => {
  const { slug, visibleWeeks } = props;

  // TODO_API_V2_REFRESH_SLUG: Investigate `undefined` result` after component updated with new slug
  const slugId = extractSlugId(slug);
  const result = useCurriculumSelector(selectComponentBySlugId(slugId));
  const type = result?.type;

  const { activeTab: initTab } = useSidebar();

  const color = getColorByComponentType(type);
  const contentHeight = `calc(100% - ${SIDEBAR_HEADING_HEIGHT}px - ${SIDEBAR_BOTTOM_NAVIGATION_HEIGHT}px)`;

  const getTabsContent = (activeTab: SidebarTabs) => {
    switch (activeTab) {
      case SidebarTabs.DETAILS:
        return <SidebarDetailsTab componentData={result} />;
      case SidebarTabs.PREVIEW:
        return <PreviewTab type={type} slug={slug} />;
      case SidebarTabs.SCHEDULE:
        return (
          <ScheduleWithMap
            componentData={result}
            color={color}
            visibleWeeks={visibleWeeks}
          />
        );
      default:
        break;
    }
  };

  //
  // UI //

  // Init or no slug case
  if (!slug) return <></>;

  // Component specific sidebar
  return (
    <>
      <CalendarSidebarTitle
        componentData={result}
        title={type}
        type={type}
        componentSlug={slug}
      />

      <Box
        overflow={"auto"}
        height={contentHeight}
        display={"flex"}
        sx={{
          scrollbarGutter: initTab === SidebarTabs.SCHEDULE ? "stable" : "unset",
          overscrollBehavior: "contain",
        }}
      >
        <Box width={1}>{result && getTabsContent(initTab)}</Box>
      </Box>

      <BottomNavigation type={type} />
    </>
  );
};

export default SidebarContent;
