import React from "react";
import { CardContent, Skeleton, useTheme } from "@mui/material";
import { Card, CardProps, CardHeader, Grid2 } from "@mui/material";

export function GroupPostSkeleton(props: CardProps) {
  const theme = useTheme();
  return (
    <Card {...props}>
      <CardContent>
        <Grid2
          container
          sx={{
            gap: 2,
          }}
        >
          <Grid2
            container
            sx={{
              height: 80,
              alignItems: "center",
              gap: 3,
            }}
          >
            <Skeleton animation="wave" width={50} height="100%" />
            <Grid2>
              <Skeleton
                animation="wave"
                height={20}
                width={125}
                sx={{ mb: 1 }}
              />
              <Skeleton animation="wave" height={15} width={125} />
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Skeleton animation="wave" height={20} width={"75%"} />
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
}
