import React from "react";
import {
  ButtonProps,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
  useMediaQuery,
  Box,
  Tooltip,
} from "@mui/material";
import { CalendarDays, LayoutList, TableProperties } from "lucide-react";
import { useEnableColors } from "../../hooks/useEnableColors";
import ToggleIconButtonGroup from "../button/ToggleIconButtonGroup";
import MinimizedTooltip from "../tooltip/MinimizedTooltip";

export enum ProgramDetailsViewMode {
  CALENDAR = "calendar",
  LIST = "list",
  SPREADSHEET = "spreadsheet",
}

export const DEFAULT_PROGRAM_DETAILS_VIEW_MODE = ProgramDetailsViewMode.LIST;

export type CalendarViewOptions = Record<
  ProgramDetailsViewMode,
  CalendarViewOption
>;

export const defaultCalendarViewOptions: CalendarViewOptions = {
  [ProgramDetailsViewMode.LIST]: {
    label: "List view",
    icon: <LayoutList size={20} />,
    disabled: false,
  },
  [ProgramDetailsViewMode.CALENDAR]: {
    label: "Calendar view",
    icon: <CalendarDays size={20} />,
    disabled: false,
  },
  [ProgramDetailsViewMode.SPREADSHEET]: {
    label: "Sheets view",
    icon: <TableProperties size={20} />,
    disabled: false,
  },
};

export interface CalendarViewOption {
  label: string;
  icon: React.ReactNode;
  disabled: boolean;
}

export interface ProgramDetailsViewButtonProps
  extends Omit<ButtonProps, "onChange"> {
  onChange?: (value: ProgramDetailsViewMode) => void;
  value?: ProgramDetailsViewMode;
  isCalendarEnabled: boolean;
}

export function ProgramDetailsViewButton(props: ProgramDetailsViewButtonProps) {
  const {
    value = ProgramDetailsViewMode.CALENDAR,
    onChange,
    isCalendarEnabled,
  } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("xl"));
  const [view, setView] = React.useState(value);

  const enableColors = useEnableColors();

  const handleViewChange = (value: ProgramDetailsViewMode) => {
    setView(value as ProgramDetailsViewMode);

    if (value && onChange) {
      onChange(value as ProgramDetailsViewMode);
    }
  };

  return (
    <>
      <MinimizedTooltip title="Choose program view" placement="top">
        <ToggleIconButtonGroup
          color={enableColors ? "primary" : "secondary"}
          displayLabel="selected"
          value={view}
          onChange={handleViewChange}
          options={Object.entries(defaultCalendarViewOptions)
            .map(([name, { label, icon }]) => ({
              value: name as ProgramDetailsViewMode,
              icon,
            }))
            .filter(({ value }) => {
              return value === ProgramDetailsViewMode.CALENDAR
                ? isCalendarEnabled
                : true;
            })}
        />
      </MinimizedTooltip>
    </>
  );
}
