import { Card, CardProps, CardHeader, Grid2 } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";

export interface BasicSkeletonCardProps extends CardProps {}

export function BasicSkeletonCard(props: BasicSkeletonCardProps) {
  return (
    <Card {...props}>
      <CardHeader
        title={
          <Grid2
            size={{
              xs: 12,
              md: 6,
            }}
          >
            <Skeleton animation="wave" />
          </Grid2>
        }
        subheader={
          <Grid2
            size={{
              xs: 6,
              md: 3,
            }}
          >
            <Skeleton animation="wave" />
          </Grid2>
        }
      />
    </Card>
  );
}
