import { Box, Button, darken, SxProps } from "@mui/material";
import React from "react";
import { ReactComponent as AddWeekIcon } from "../../icons/AddCircleOutline.svg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  addWeekButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: "none",
    fontWeight: 600,
    fontSize: 18,
    marginTop: theme.spacing(3),
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: theme.spacing(1),

    [theme.breakpoints.up("md")]: {
      height: theme.spacing(8.75),
      fontSize: 20,
      "& svg": {
        width: 32,
        height: 32,
      },
    },

    "& svg": {
      width: 24,
      height: 24,
    },

    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.3),
    },

    "& svg path[stroke]": {
      stroke: theme.palette.primary.contrastText,
    },

    "& svg path[fill]": {
      fill: theme.palette.primary.contrastText,
    },
  },
}));

interface IAddNewWeekProps {
  handleAddWeek: () => void;
  isShown: boolean;
  sx?: SxProps;
}

const AddNewWeek = (props: IAddNewWeekProps) => {
  const { handleAddWeek, isShown, sx } = props;
  const s = useStyles();
  return (
    <>
      {/* TODO move weeks logic to each view or refactor to a sidebar */}
      {isShown && (
        <Button
          className={s.addWeekButton}
          fullWidth
          variant="outlined"
          startIcon={<AddWeekIcon />}
          children="Add new week"
          onClick={handleAddWeek}
          sx={sx}
        />
      )}
    </>
  );
};

export default AddNewWeek;
