import clsx from "clsx";
import React, { useCallback } from "react";
import {
  DialogProps,
  Box,
  Checkbox,
  CheckboxProps,
  Typography,
  Divider,
  Skeleton,
  DialogActions,
  Button,
  Avatar,
  TextField,
  Autocomplete,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { BaseDialog, BaseDialogProps } from "./BaseDialog";

import {
  ClientDto,
  UserInviteStatus,
} from "@growth-machine-llc/stridist-api-client";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import ClientsService from "../../services/ClientsService";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { MAX_VISIBLE_TAGS, Plan, planClients } from "../../constants";
import { UserInfo } from "../../hooks/useCurrentUser";
import { ClientAddableItem } from "../item/ClientAddableItem";
import { AddedLozenge } from "../item/AddedLozenge";

const useStyles = makeStyles((theme) => ({
  root: {},

  list: {
    maxHeight: 420,
    overflowY: "auto",
    overflowX: "auto",
    display: "flex",
    flexDirection: "column",
    whiteSpace: "nowrap",
    paddingRight: theme.spacing(1),
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[400],
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.grey[200],
    },
  },

  item: {
    padding: theme.spacing(2.5, 0),
  },

  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    flex: 1,
    maxWidth: "100%",
  },

  input: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },

  name: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: "22px",
    color: theme.palette.common.black,
    wordBreak: "break-word",
  },

  email: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },

  sectionDivider: {},

  button: {
    margin: 0,
    flex: 1,
    fontSize: 16,
  },

  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0, 0),
    width: "100%",
    boxSizing: "border-box",
  },

  avatar: {
    flexShrink: 0,
    borderRadius: 2.6,
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },

  lozenges: {
    padding: theme.spacing(2, 0),
    whiteSpace: "nowrap",
    overflowX: "auto",
  },

  lozenge: {
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
}));

export interface SelectClientsDialogProps
  extends Omit<DialogProps, "title">,
    Pick<BaseDialogProps, "title" | "description"> {
  onConfirm: (clients: ClientDto[]) => void;
  minSelected: number;
  user: UserInfo;
  selectedPlan: Plan;
}

export function SelectClientsDialog(props: SelectClientsDialogProps) {
  const {
    className,
    onClose,
    title,
    onConfirm,
    minSelected,
    user,
    selectedPlan,
    ...other
  } = props;

  const s = useStyles();
  const theme = useTheme();
  const [selectedClients, setSelectedClients] = React.useState<ClientDto[]>([]);
  const [filter, setFilter] = React.useState("");

  const { data: clientsData, isLoading } = useQuery({
    queryKey: ["select-dialog-clients"],
    queryFn: () => ClientsService.getAllClients([UserInviteStatus.ACTIVE]),
  });

  const allClients = React.useMemo(() => {
    const items = (clientsData?.items || []).filter(
      (client) => !client.isSample,
    );
    if (filter.trim()) {
      const lowerFilter = filter.toLowerCase();
      return items.filter(
        (client) =>
          client.displayName.toLowerCase().includes(lowerFilter) ||
          client.email.toLowerCase().includes(lowerFilter),
      );
    }
    return items;
  }, [clientsData, filter]);

  const handleClose = React.useCallback(() => {
    setSelectedClients([]);
    onClose({}, "backdropClick");
  }, [onClose]);

  const handleToggleClient = React.useCallback(
    (added: boolean, client: ClientDto) => {
      setSelectedClients((prev) => {
        if (added) {
          return [...prev, client];
        } else {
          return prev.filter((c) => c.id !== client.id);
        }
      });
    },
    [],
  );

  const handleConfirm = React.useCallback(() => {
    onConfirm(selectedClients);
    handleClose();
  }, [onConfirm, selectedClients, handleClose]);

  const planLimit = planClients(selectedPlan, true) as number;
  const remainClientsCount = user.clientsCountNoSample - selectedClients.length;
  const canContinue =
    selectedClients.length >= minSelected && remainClientsCount <= planLimit;

  const handleFilterChange: TextFieldProps["onChange"] = React.useCallback(
    (event) => {
      setFilter(event.target.value);
    },
    [],
  );

  const handleAutocompleteUpdate = React.useCallback(
    (_: any, __: any, reason: string) => {
      if (reason === "clear") {
        setFilter("");
        setSelectedClients([]);
      }
    },
    [],
  );

  const removeClient = React.useCallback((id: number) => {
    setSelectedClients((prev) => prev.filter((c) => c.id !== id));
  }, []);

  const getRemoveClientHandler = React.useCallback(
    (id: number) => () => removeClient(id),
    [removeClient],
  );

  return (
    <BaseDialog
      className={clsx(s.root, className)}
      title={title}
      onClose={handleClose}
      {...other}
    >
      <Typography variant="body1" className={s.subtitle}>
        You currently have{" "}
        <strong style={{ color: theme.palette.primary.main }}>
          {remainClientsCount}
        </strong>{" "}
        active clients.
      </Typography>

      <Typography variant="body1" className={s.subtitle}>
        Your new plan allows up to <strong>{planLimit}</strong> clients.
      </Typography>

      <Autocomplete
        className={s.input}
        onInputChange={handleAutocompleteUpdate}
        fullWidth
        multiple
        freeSolo
        inputValue={filter}
        value={selectedClients}
        onChange={(_, newValue) => {
          setSelectedClients(newValue as ClientDto[]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={filter}
            onChange={handleFilterChange}
            placeholder="Select clients to archive"
          />
        )}
        options={[]}
        renderTags={(records: ClientDto[], _1) => {
          return (
            <>
              {records
                .toSpliced(MAX_VISIBLE_TAGS)
                .map(({ id, displayName }) => (
                  <AddedLozenge
                    key={id}
                    className={s.lozenge}
                    text={displayName}
                    onRemoveClick={getRemoveClientHandler(id)}
                  />
                ))}
              {records.length > MAX_VISIBLE_TAGS &&
                `+${records.length - MAX_VISIBLE_TAGS}`}
            </>
          );
        }}
      />

      <Box className={s.list}>
        {isLoading
          ? Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} height={30} sx={{ mb: 1.5 }} />
            ))
          : allClients.map((client, index) => {
              return (
                <React.Fragment key={client.id}>
                  <ClientAddableItem
                    className={s.item}
                    client={client}
                    added={selectedClients.some((c) => c.id === client.id)}
                    disabled={false}
                    onToggle={handleToggleClient}
                  />
                  {index < allClients.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
      </Box>

      <DialogActions className={s.dialogActions}>
        <Button onClick={handleClose} variant="outlined" className={s.button}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={!canContinue}
          variant="contained"
          color="primary"
          className={s.button}
        >
          Archive
        </Button>
      </DialogActions>
    </BaseDialog>
  );
}
