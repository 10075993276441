/**
 * SidebarContext: Manages the state of the curriculum sidebar, including:
 * - `isOpen`: Whether the sidebar is open.
 * - `componentSlug`: Identifies the current component associated with the sidebar.
 * - `sidebarTab`: Identifies sidebar tab.
 * Provides methods to toggle, open, and close the sidebar:
 * - `openSidebar(slug, tab?)`: Opens the sidebar for a specific component slug on a specific tab.
 * - `closeSidebar()`: Closes the sidebar and clears the component slug.
 * - `changeActiveTab(tab: SidebarTabs)`: Change active tab
 */

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import MinimizedDrawerContext, {
  getLocalStorageMinimizedDrawerValue,
} from "./MinimizedDrawerContext";
import { useMediaQuery, useTheme } from "@mui/material";
import { SidebarTabs } from "../constants";

const SIDEBAR_INIT_STATE = false;

interface SidebarContextType {
  isOpen: boolean;
  componentSlug: string | null;
  activeTab: SidebarTabs;
  openSidebar: (slug: string, tab?: SidebarTabs) => void;
  closeSidebar: () => void;
  changeActiveTab: (tab: SidebarTabs) => void;
}

const SidebarContext = createContext<SidebarContextType>({
  isOpen: false,
  componentSlug: null,
  activeTab: SidebarTabs.DETAILS,
  openSidebar: () => {},
  closeSidebar: () => {},
  changeActiveTab: () => {},
});

export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const { setMinimizedDrawer } = useContext(MinimizedDrawerContext);

  //
  //
  // STATES

  const [isOpen, setIsOpen] = useState(SIDEBAR_INIT_STATE);
  const [componentSlug, setComponentSlug] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<SidebarTabs>(SidebarTabs.DETAILS);

  //
  //
  // METHODS

  const openSidebar = (slug: string, tab?: SidebarTabs) => {
    setIsOpen(true);
    setComponentSlug(slug);
    setActiveTab(tab ?? activeTab);
  };

  const closeSidebar = () => {
    setIsOpen(false);
    setComponentSlug(null);
    setActiveTab(SidebarTabs.DETAILS);
  };

  const changeActiveTab = (tab: SidebarTabs) => {
    setActiveTab(tab);
  };

  //
  //
  // ADDITIONAL LOGIC

  // Minimize main drawer if sidebar opens
  useEffect(() => {
    setMinimizedDrawer(isOpen ? true : getLocalStorageMinimizedDrawerValue());
  }, [isOpen]);

  // Close sidebar for small screens
  useEffect(() => {
    isMd && closeSidebar();
  }, [isMd]);

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        componentSlug,
        activeTab,
        openSidebar,
        closeSidebar,
        changeActiveTab,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = (): SidebarContextType => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};
