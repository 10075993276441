import React from "react";
import clsx from "clsx";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    "& span:first-child": {
      marginLeft: theme.spacing(-0.5),
    },
    "& span:last-child": {
      marginLeft: theme.spacing(-0.75),
    },
  },
  arrow: {
    padding: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",

    "& svg": {
      width: 16,
      height: 16,
    },
  },
}));

type CoachClientsArrowsSortProps = {
  activeUp: boolean;
  activeDown: boolean;
};

const CoachClientsArrowsSort = ({
  activeUp,
  activeDown,
}: CoachClientsArrowsSortProps) => {
  const s = useStyles();
  return (
    <Box className={s.container}>
      <span className={s.arrow}>
        <NorthIcon
          sx={{
            fill: activeUp ? "primary" : "gray",
          }}
        />
      </span>
      <span className={clsx(s.arrow)}>
        <SouthIcon
          sx={{
            fill: activeDown ? "primary" : "gray",
          }}
        />
      </span>
    </Box>
  );
};

export default CoachClientsArrowsSort;
