import {
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Rnd } from "react-rnd";
import { Media } from "./Image";
import { AlignCenter, AlignJustify, AlignLeft, AlignRight } from "lucide-react";

interface ResizableImageProps {
  children: any;
  handleTooltipClose: any;
  openAlignDialog: any;
  alignImage: any;
  setAlignImage: any;
  media: Media;
  setMedia: any;
  isClicked: boolean;
  caption: any;
}
function ResizableImage({
  children,
  handleTooltipClose,
  openAlignDialog,
  alignImage,
  setAlignImage,
  media,
  setMedia,
  isClicked,
  caption,
}: ResizableImageProps) {
  const handleResize = React.useCallback(
    (event, dimensions) => {
      const newMedia = {
        ...media,
        dimensions: {
          width: document.getElementById("image-box")?.offsetWidth,
          height: document.getElementById("image-box")?.offsetHeight,
        },
      };

      setMedia(newMedia);
    },
    [media],
  );

  const theme = useTheme();

  const divider = (
    <Divider
      orientation="vertical"
      variant="middle"
      flexItem
      sx={{
        m: 0.5,
        borderRightWidth: 5,
        visibility: isClicked ? "visible" : "hidden",
        borderRadius: 0.5,
      }}
    ></Divider>
  );
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: `${alignImage}`,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ClickAwayListener disableReactTree onClickAway={handleTooltipClose}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Rnd
            id={"image-box"}
            className="react-draggable-overwrite"
            resizeGrid={[5, 5]}
            lockAspectRatio
            disableDragging
            enableUserSelectHack
            data-image-resized
            onResize={handleResize}
          >
            <Tooltip
              placement="top"
              onClose={handleTooltipClose}
              open={openAlignDialog}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <Box>
                  <IconButton
                    sx={{ color: theme.palette.selected.light }}
                    onClick={() => {
                      setAlignImage("flex-start");
                    }}
                  >
                    <AlignLeft />
                  </IconButton>
                  <IconButton
                    sx={{ color: theme.palette.selected.light }}
                    onClick={() => {
                      setAlignImage("center");
                    }}
                  >
                    <AlignCenter />
                  </IconButton>
                  <IconButton
                    sx={{ color: theme.palette.selected.light }}
                    onClick={() => {
                      setAlignImage("flex-end");
                    }}
                  >
                    <AlignRight />
                  </IconButton>
                  <IconButton
                    sx={{ color: theme.palette.selected.light }}
                    onClick={() => {
                      setAlignImage("stretch");
                    }}
                  >
                    <AlignJustify />
                  </IconButton>
                </Box>
              }
              slotProps={{
                tooltip: {
                  sx: {
                    bgcolor: theme.palette.common.black,
                    opacity: "0.7 !important",
                  },
                },

                popper: {
                  popperOptions: {
                    modifiers: [
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "offset",
                        enabled: false,
                        offset: "0px -60px",
                      },
                    ],
                  },
                },
              }}
            >
              <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
                {divider}
                {children}
                {divider}
              </Box>
            </Tooltip>
          </Rnd>
          {caption}
        </Box>
      </ClickAwayListener>
    </Box>
  );
}

export default ResizableImage;
