import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import {
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  useMediaQuery,
  Box,
  Theme,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import { darken, useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { LocalStorageKeys, UserRole } from "../../constants";
import { polyfillCSS } from "../../utils/css";
import { getUserRole } from "../../utils/user";
import { GroupNavItem } from "../groups/GroupNavItem";
import { Link } from "../link/Link";

import { AppLogo } from "./AppLogo";
import { CurrentUserBox } from "./CurrentUserBox";
import { Toolbar } from "./AppBar";
import { menuItems } from "./constants";
import {
  CLIENT_HOME_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  COACH_ROUTE,
  DEFAULT_ROUTE,
  GROUPS_ROUTE,
  HOME_ROUTE,
} from "../../routes/routes";
import { useCurrentUserRole } from "../../hooks/useCurrentUser";
import { useLocation } from "react-router-dom";
import { isDrawerItemSelected } from "./common";
import { useDrawer } from "../../hooks/useDrawer";
import { ChevronsLeftIcon } from "lucide-react";
import { ChevronsRightIcon } from "lucide-react";
import MinimizedDrawerContext from "../../contexts/MinimizedDrawerContext";
import { useMediaMobile } from "../../hooks/useMediaMobile";

const useStyles = (minimizedDrawer) =>
  makeStyles(
    ({ palette, spacing, breakpoints, drawer, transitions, zIndex }) => ({
      "@global": {
        "#root": {
          [breakpoints.up("llg")]: {
            paddingLeft: polyfillCSS(
              `calc(${minimizedDrawer ? drawer.minimizedWidth : drawer.width}px + var(--safe-area-inset-left))`,
            ),
            transition: transitions.create("padding-left", {
              easing: transitions.easing.sharp,
              duration: transitions.duration.enteringScreen,
            }),
          },
        },
      },

      root: {
        width: polyfillCSS(
          `calc(${drawer.width}px + var(--safe-area-inset-left))`,
        ),
        flexShrink: 0,

        [breakpoints.up("md")]: {
          display: "none",
        },

        [breakpoints.up("llg")]: {
          display: "block",
        },
      },

      paper: {
        width: polyfillCSS(
          `calc(${drawer.width}px + var(--safe-area-inset-left))`,
        ),
        borderRight: "1px solid",
        borderRightColor: palette.grey[300],

        paddingLeft: polyfillCSS("var(--safe-area-inset-left)"),
        paddingTop: polyfillCSS("var(--safe-area-inset-top)"),
      },

      toolbar: {
        paddingInline: spacing(minimizedDrawer ? 1 : 2.5),
        paddingTop: spacing(5.5),
        paddingBottom: spacing(2),
        transition: transitions.create("padding", {
          easing: transitions.easing.sharp,
          duration: transitions.duration.enteringScreen,
        }),
        display: "flex",
        alignContent: "center",
        minHeight: "125px",

        [breakpoints.down("md")]: {
          "@media (orientation: landscape)": {
            display: "none",
          },
        },
      },

      logo: {
        height: "auto",
        width: minimizedDrawer ? "45px" : "60px",

        transition: transitions.create(["height", "width"], {
          easing: transitions.easing.sharp,
          duration: transitions.duration.enteringScreen,
        }),
      },

      title: {
        paddingTop: spacing(2),
        paddingLeft: spacing(2),
      },

      nav: {
        flexGrow: 1,
        overflowY: "auto",
        overflowX: "hidden",
      },

      navItem: {
        minHeight: 50,
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: zIndex.mobileStepper,

        "&:hover": {
          background: darken(palette.common.white, 0.05),
        },
      },

      icon: {
        color: palette.text.secondary,

        "&$active": {
          color: palette.primary.main,
        },
      },

      itemText: {
        fontSize: 19,
        color: palette.text.secondary,

        "&$active": {
          color: palette.primary.main,
        },
      },

      unreadCount: {
        width: 18,
        height: 18,
        borderRadius: "50%",
        backgroundColor: palette.primary.main,
      },

      active: {},
    }),
  );

export const APP_DRAWER_ICON_WIDTH = 40;

function AppDrawer(props) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const { className, ...other } = props;
  const { breakpoints } = useTheme();
  const location = useLocation();
  const mediaQuery = `(min-width:${breakpoints.values.md}px)`;
  const mdUp = useMediaQuery(mediaQuery);
  const [drawerOpen, setDrawerOpen] = useDrawer();
  const mode = useCurrentUserRole();

  const theme = useTheme();

  const { minimizedDrawer, setMinimizedDrawer } = useContext(
    MinimizedDrawerContext,
  );
  const isMobile = useMediaMobile();
  const showMinimizedDrawbar = minimizedDrawer && !isMobile;
  const s = useStyles(showMinimizedDrawbar)();

  const handleMinimizeDrawerClick = () => {
    setMinimizedDrawer((v) => {
      localStorage.setItem(
        LocalStorageKeys.IS_DRAWER_MINIMIZED,
        !v ? "true" : "false",
      );
      return !v;
    });
  };

  const handleUserMenuClose = React.useCallback((closeAppDrawer: boolean) => {
    if (closeAppDrawer) {
      setDrawerOpen(false);
    }
  }, []);

  return (
    <Drawer
      className={clsx(s.root, className)}
      {...other}
      classes={{ paper: s.paper }}
      open={mdUp ? true : drawerOpen}
      variant={mdUp ? "permanent" : "temporary"}
      anchor="left"
      PaperProps={{
        style: {
          width: showMinimizedDrawbar
            ? theme.drawer.minimizedWidth
            : theme.drawer.width,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflow: "unset",
        },
      }}
    >
      {!isMobile && (
        <IconButton
          id="app-drawer-minimize-icon-button"
          onClick={handleMinimizeDrawerClick}
          sx={{
            position: "absolute",
            top: 60,
            right: -18,
            p: 0.5,
            zIndex: theme.zIndex.modal,
            background: theme.palette.common.white,
            ":hover": {
              background: darken(theme.palette.common.white, 0.05),
            },
          }}
        >
          {minimizedDrawer ? <ChevronsRightIcon /> : <ChevronsLeftIcon />}
        </IconButton>
      )}
      <Toolbar className={s.toolbar}>
        <Link
          href={
            mode === UserRole.CLIENT
              ? HOME_ROUTE
              : COACH_PROGRAMS_PUBLISHED_ROUTE
          }
          underline="none"
          children={
            <AppLogo
              className={clsx(s.logo)}
              size={showMinimizedDrawbar ? 45 : 60}
              width={showMinimizedDrawbar ? 45 : 60}
              height={showMinimizedDrawbar ? 45 : 60}
            />
          }
        />
      </Toolbar>
      <List className={s.nav}>
        {(mode === UserRole.COACH ? menuItems.coach : menuItems.client).map(
          ({ text, link, Icon }) =>
            link.includes(GROUPS_ROUTE) ? (
              <GroupNavItem
                key={link}
                minimized={showMinimizedDrawbar}
                setDrawerMinimized={setMinimizedDrawer}
              />
            ) : (
              <ListItemButton
                key={link}
                className={s.navItem}
                component={Link}
                href={link}
                target={link.includes("marketing") && "_blank"}
                onClick={() => {
                  setDrawerOpen(false);
                }}
                underline="none"
                sx={{
                  ...(!showMinimizedDrawbar && {
                    p: 0,
                    paddingLeft: theme.spacing(4),
                  }),
                  transition: theme.transitions.create(["padding-left"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: showMinimizedDrawbar ? 30 : APP_DRAWER_ICON_WIDTH,
                  }}
                >
                  <Icon
                    size={24}
                    className={clsx(
                      s.icon,
                      isDrawerItemSelected(link, mode) && s.active,
                    )}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    className: clsx(
                      s.itemText,
                      isDrawerItemSelected(link, mode) && s.active,
                    ),
                    variant: "h5",
                  }}
                  sx={{
                    visibility: showMinimizedDrawbar ? "hidden" : "visible",
                    opacity: showMinimizedDrawbar ? 0 : 1,
                    transition: theme.transitions.create(
                      ["visibility", "opacity"],
                      {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                      },
                    ),
                  }}
                />
              </ListItemButton>
            ),
        )}
      </List>
      <Divider />
      <CurrentUserBox
        minimized={showMinimizedDrawbar}
        onClose={handleUserMenuClose}
      />
    </Drawer>
  );
}

export default AppDrawer;
