import clsx from "clsx";
import React from "react";
import {
  TableRow,
  TableRowProps,
  TableCell,
  Box,
  Typography,
  Link,
  IconButton,
  Button,
  Skeleton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs, { Dayjs } from "dayjs";
import { ExercisesMenu } from "./ExercisesMenu";
import {
  ExerciseAssetsType,
  AdminExerciseAssetDto,
} from "@growth-machine-llc/stridist-api-client";
import { WorkoutDemoVideoPreview } from "../workout/WorkoutDemoVideoPreview";
import { WorkoutExercise } from "../workout/types";
import { ADMIN_USERS_ROUTE } from "../../routes/routes";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";
import AdminService from "../../services/AdminService";
import ActivityPreviewExercises from "../activity-preview/ActivityPreviewExercises";
import SeeMoreNotes from "../training-summary/SeeMoreNotes";
import { WorkoutVideoDemoWithThumbnail } from "./WorkoutVideoDemoWithThumbnail";

const useStyles = makeStyles((theme) => ({
  root: {},

  link: {
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },

  value: {
    fontSize: 14,
    fontWeight: 500,
  },

  subText: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
    hyphens: "auto",
    hyphenateCharacter: "auto",
    wordBreak: "break-word",
  },
}));

export interface ExercisesTableRowProps extends TableRowProps {
  exerciseInfo: AdminExerciseAssetDto;
  type: ExerciseAssetsType;
  onSelectedExercise?: (exerciseAsset: AdminExerciseAssetDto) => void;
}

export function ExercisesTableRow(props: ExercisesTableRowProps) {
  const { className, exerciseInfo, type, onSelectedExercise, ...other } = props;
  const s = useStyles();
  const exercise = JSON.parse(exerciseInfo.content) as WorkoutExercise;
  const [exerciseDrawerOpen, setExerciseDrawerOpen] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const EXERCISE_ASSET_HISTORY_QUERY_KEY = "exercise-asset-history";
  const { data: historyCount, isLoading } = useQuery({
    queryKey: [
      EXERCISE_ASSET_HISTORY_QUERY_KEY,
      {
        coachId: exerciseInfo.coachId,
        exerciseId: exerciseInfo.id,
      },
    ],
    queryFn: () =>
      AdminService.getExercisesAssetsHistory(
        exerciseInfo.id,
        exerciseInfo.legacyAssetId,
      ),
    enabled: openHistory,
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });
  const LabeledDate = ({ label, date }: { label: string; date: Dayjs }) => (
    <Typography
      variant="caption"
      sx={{ whiteSpace: "nowrap" }}
      className={s.subText}
    >
      <b>{label}:</b> {date ? dayjs(date).format("MMM DD, YYYY") : "-"}
    </Typography>
  );
  return (
    <TableRow className={clsx(s.root, className)} hover {...other}>
      <TableCell component="th" scope="row">
        {exercise.video && (
          <Box sx={{ width: 355 }}>
            <WorkoutVideoDemoWithThumbnail video={exercise.video} />
          </Box>
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box sx={{ width: 355 }}>
          {exercise.title}
          <br />
          <Typography
            variant="caption"
            component="div"
            sx={{ textWrap: "auto" }}
            className={s.subText}
          >
            <SeeMoreNotes rows={5}>{exercise.instructions}</SeeMoreNotes>
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="center">{<b>{exerciseInfo.id}</b>}</TableCell>
      <TableCell align="center">
        {!openHistory && (
          <Box>
            <Button onClick={() => setOpenHistory(true)}>Show</Button>
          </Box>
        )}
        {isLoading && <Skeleton variant="rounded" />}
        {historyCount && <Typography>{historyCount}</Typography>}
      </TableCell>
      <TableCell align="center">
        {exercise.images.length > 0 ? <b>{exercise.images.length}</b> : "-"}
      </TableCell>
      <TableCell align="center">
        <ActivityPreviewExercises
          card={exercise}
          index={0}
          exercises={[exercise]}
          showTitle={false}
          textWrap={false}
        />
      </TableCell>
      {type === ExerciseAssetsType.PRIVATE && (
        <TableCell align="center">
          <Link
            href={`${ADMIN_USERS_ROUTE}/${exerciseInfo.coachId}`}
            target="_blank"
            rel="noopener noreferrer"
            className={clsx(s.link)}
          >
            <Typography className={clsx(s.value)}>
              {exerciseInfo.coachId}
            </Typography>
          </Link>
        </TableCell>
      )}
      <TableCell align="center">
        <LabeledDate
          label="Updated"
          date={dayjs(exerciseInfo.lastModified?.toString())}
        />
        <br />
        <LabeledDate
          label="Created"
          date={dayjs(exerciseInfo.created?.toString())}
        />
      </TableCell>
      <TableCell align="center">
        <ExercisesMenu
          exercise={exercise}
          exerciseId={exerciseInfo.id}
          jsonContent={exerciseInfo.content}
        />
        <IconButton
          children={<ArrowForwardRounded />}
          onClick={() => onSelectedExercise(exerciseInfo)}
          size="large"
        />
      </TableCell>
    </TableRow>
  );
}
