import { Collapse, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FixedToolbar } from "../../../plate-ui/fixed-toolbar";
import { FixedToolbarButtons } from "../../../plate-ui/fixed-toolbar-buttons";
import { Schema } from "../../editor-configuration/schemas/Schema";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";
import clsx from "clsx";

interface IEditorToolbarProps {
  readOnly: boolean;
  top: number;
  editorSchema: Schema;
}

const EditorToolbar = ({
  readOnly,
  top = 0,
  editorSchema,
}: IEditorToolbarProps) => {
  const theme = useTheme();
  const user = useCurrentUser();

  const [isSticked, setIsSticked] = useState(false);
  const stickyContainer = useRef(null);

  const handleScroll = () => {
    if (stickyContainer.current) {
      const offsetTop = stickyContainer.current.getBoundingClientRect().top;
      setIsSticked(offsetTop === top);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [top]);

  const isSticky = editorSchema.stickyToolbar && isSticked;

  return (
    <>
      {editorSchema.showToolbar !== false && (
        <Collapse
          ref={stickyContainer}
          in={!readOnly && (user.topToolbar || editorSchema.showToolbarAlways)}
          sx={{
            top: editorSchema.stickyToolbar && top,
            position: editorSchema.stickyToolbar && "sticky",
            zIndex: theme.zIndex.appBar,
            background: theme.palette.common.white,
          }}
          className={clsx("rounded-b-lg")}
        >
          <FixedToolbar
            className={clsx(
              "rounded-b-lg",
              editorSchema.showToolbarBorder === false && "border-0",
              isSticky && "!rounded-t-none",
            )}
          >
            <FixedToolbarButtons
              dropDownMenuItems={editorSchema.generalDropDownMenuItems}
              toolbarOptions={editorSchema.toolbarOptions}
            />
          </FixedToolbar>
        </Collapse>
      )}
    </>
  );
};

export default EditorToolbar;
