import clsx from "clsx";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Card,
  CardProps,
  IconButton,
  Portal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  bindPopper,
  bindToggle,
  usePopupState,
} from "material-ui-popup-state/hooks";
import makeStyles from "@mui/styles/makeStyles";
import { sortBy } from "lodash";
import omit from "lodash.omit";

import { colorSystem } from "../../theme";
import { TextButton } from "../button/TextButton";

import { ReactComponent as StepsIcon } from "../../icons/Steps.svg";

import StepsCountsService from "../../services/StepsCountsService";

import dayjs from "dayjs";
import {
  ICreateStepCountCommand,
  IUpdateStepCountCommand,
  StepsCountDto,
} from "@growth-machine-llc/stridist-api-client";
import { BasicSkeletonCard } from "../loading/BasicSkeletonCard";
import {
  MeasurementDrawer,
  stepFromStepsCount,
} from "../client-generic-measurement/MeasurementDrawer";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import { MetricType } from "../client-measurement/ClientMeasurementRow";
import { ISO_DATE_FORMAT } from "../../utils/date";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
  // eslint-disable-next-line import/no-unresolved
} from "recharts/types/component/DefaultTooltipContent";
import {
  Calendar,
  ChartColumn,
  ChartColumnBig,
  ChevronLeft,
  Pencil,
  Settings,
} from "lucide-react";
import { maybePluralize } from "../../utils/text";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  chunkBy,
  chunkKeepTail,
  interpolateDataDiscrete,
} from "../../utils/chart";
import SettingsPopupOption from "../charts/SettingsPopupOption";
import SettingsPopup from "../charts/SettingsPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    position: "relative",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  headerContent: {
    marginLeft: theme.spacing(1.3),
  },

  iconButton: {
    margin: theme.spacing(-10, 0),
  },

  iconButtonGroup: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(1.5),
  },

  today: {
    fontSize: 10,
    fontWeight: 800,
    lineHeight: "12px",
    color: theme.palette.common.black,
    backgroundColor: colorSystem.gray9,
    borderRadius: 4,
    width: "fit-content",
    padding: theme.spacing(0.2, 0.8),
    textTransform: "uppercase",
  },

  subtitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "24px",
    color: theme.palette.common.black,
  },

  editButton: {
    margin: theme.spacing(-10, 0),
  },

  buttons: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2.3, -0.45, 3, -0.45),
    gap: theme.spacing(0.5),
  },

  button: {
    backgroundColor: "transparent",
    borderWidth: 0,
    color: theme.palette.text.secondary,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 700,
    margin: 0,
    padding: theme.spacing(0.8, 1),
    lineHeight: "17px",
    minWidth: 0,
    "&:hover": {
      color: colorSystem.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  activeButton: {
    color: colorSystem.white,
    backgroundColor: theme.palette.secondary.main,
  },

  headerNav: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  navButtonRight: {
    transform: "rotate(180deg)",
  },

  navButton: {
    width: 40,
  },

  todayContainer: {
    display: "flex",
    alignItems: "center",
  },
  todayWeight: {
    fontSize: 13,
    lineHeight: "13px",
    fontWeight: 500,
    color: colorSystem.gray,
    marginLeft: 6,
  },
  indicator: {
    fontSize: 14,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    "&$gain": {
      color: theme.palette.progress.red,
    },

    "&$drop": {
      color: theme.palette.progress.green,
    },
  },
  indicatorValue: {
    fontSize: 20,
    "&$gain": {
      color: theme.palette.progress.red,
    },

    "&$drop": {
      color: theme.palette.progress.green,
    },
  },
  arrowIconDown: {
    transform: "rotate(180deg)",
  },
  headerContainer: {
    display: "flex",
  },
  smlsubtitle: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  gain: {},
  drop: {},

  syncButton: {
    color: colorSystem.black,
    backgroundColor: "transparent",
    boxShadow: "none",
    height: theme.spacing(4),
    fontSize: 16,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
  },
  graphContainer: {
    minHeight: 220,
    [theme.breakpoints.down("sm")]: {
      minHeight: 150,
    },
  },
  graph: {
    height: theme.breakpoints.up("sm") ? 60 : 120,
  },
  invisible: {
    visibility: "hidden",
  },
  noSteps: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    margin: theme.spacing(4, 0),
    textAlign: "center",
  },
}));

export const CLIENT_STEPS_QUERY_KEY = "client-steps";

const AVERAGING_WINDOW_SIZE = 2; // Amount of data points on each side to take into averaging min/max
const AVERAGING_FN_SLOPE = 1 + AVERAGING_WINDOW_SIZE; // coefficient of slope of the averaging function, bigger coeff = more influence of distant points

export interface ClientStepsCardProps extends CardProps {
  client: { id: number; units: string };
}

const ChartTooltip = (themeColor: string) => {
  return ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <Card sx={{ padding: 1 }}>
          <Typography variant="subtitle2">
            {dayjs(label).format("MMMM DD, YYYY")}
          </Typography>
          <Typography color={themeColor} variant="h5">
            {maybePluralize(
              payload[0].value.valueOf() as number,
              "step",
            ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Typography>
        </Card>
      );
    }

    return null;
  };
};

const averagingFn = (index: number) =>
  (AVERAGING_FN_SLOPE - Math.abs(index - AVERAGING_WINDOW_SIZE)) /
  (AVERAGING_FN_SLOPE * AVERAGING_FN_SLOPE);

export enum TimeUnit {
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  ALLTIME = "alltime",
}

interface Settings {
  pointsSum: boolean;
  alignToRange: boolean;
}

export function ClientStepsCard(props: ClientStepsCardProps) {
  const { client, ...other } = props;
  const { breakpoints, palette } = useTheme();
  const [settingsDialogAnchorEl, setSettingsDialogAnchorEl] =
    useState<HTMLElement | null>(null);
  const [settings, setSettings] = useLocalStorage<Settings>(
    "stepsChartSettings",
    {
      pointsSum: false,
      alignToRange: false,
    },
  );

  const { data: stepData, isLoading } = useQuery({
    queryKey: [CLIENT_STEPS_QUERY_KEY, { clientId: client.id }],
    queryFn: () => StepsCountsService.getSteps(client.id),
    select: (data) =>
      data.sort((a, b) => {
        const dateA = a.targetDate;
        const dateB = b.targetDate;
        return dateA.isAfter(dateB) ? -1 : 1;
      }),
  });

  const borderColor = palette.primary.main;

  const steps = stepData || [];

  const queryClient = useQueryClient();

  const {
    mutate: createMeasurement,
    isPending: creating,
    variables: createVars,
  } = useMutation({
    mutationFn: StepsCountsService.createStepsCount,
    onSuccess: (id: number, vars: ICreateStepCountCommand) => {
      queryClient.setQueryData(
        [CLIENT_STEPS_QUERY_KEY, { clientId: client.id }],
        (oldData: StepsCountDto[]) => {
          const updatedSteps = [...oldData];
          updatedSteps.unshift(
            StepsCountDto.fromJS({
              ...vars,
              id,
              count: vars.steps,
              manual: true,
            }),
          );
          return updatedSteps;
        },
      );
    },
  });

  const {
    mutate: updateMeasurement,
    isPending: updating,
    variables: updateVars,
  } = useOptimisticUpdateMutation({
    queryKey: [CLIENT_STEPS_QUERY_KEY, { clientId: client.id }],
    mutationFn: StepsCountsService.updateStepsCount,
    disableToastAlerts: true,
    optimisticUpdater: {
      updateFn: (
        oldData: StepsCountDto[],
        vars: IUpdateStepCountCommand,
        _,
      ) => {
        const updateIndex = oldData.findIndex((item) => item.id === vars.id);
        const updatedSteps = [...oldData];
        updatedSteps.splice(
          updateIndex,
          1,
          StepsCountDto.fromJS({
            ...vars,
            count: vars.steps,
            manual: true,
          }),
        );
        return updatedSteps;
      },
    },
  });

  const { mutate: removeMeasurement, isPending: removing } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_STEPS_QUERY_KEY, { clientId: client.id }],
      mutationFn: StepsCountsService.deleteStepsCount,
      optimisticUpdater: {
        updateFn: (oldData: StepsCountDto[], vars: number, _) => {
          return oldData.filter((item) => item.id !== vars);
        },
      },
    });

  const updatingId = updating && updateVars?.id;
  const creatingForDate =
    createVars?.targetDate && creating ? dayjs(createVars?.targetDate) : null;

  const handleSave = React.useCallback(
    ({ id, date, measurement }: MetricType, onSuccess?: () => void) => {
      if (id) {
        updateMeasurement(
          {
            id,
            targetDate: dayjs(date).format(ISO_DATE_FORMAT),
            steps: measurement,
          },
          {
            onSuccess: () => {
              if (onSuccess) onSuccess();
            },
          },
        );
      } else {
        createMeasurement(
          {
            clientId: client.id,
            targetDate: dayjs(date).format(ISO_DATE_FORMAT),
            steps: measurement,
          },
          {
            onSuccess: () => {
              if (onSuccess) onSuccess();
            },
          },
        );
      }
    },
    [client.id, createMeasurement, updateMeasurement],
  );

  const handleRemove = React.useCallback(
    ({ id, index }: MetricType) => {
      removeMeasurement(id, {
        onSuccess: (data, variables, context) => {
          const updateSteps = [...steps];
          updateSteps.splice(index, 1);
        },
      });
    },
    [removeMeasurement, steps],
  );

  const [activeButton, setActiveButton] = useState<TimeUnit>(TimeUnit.WEEK);
  const [navIndex, setNavIndex] = useState(0);
  const [isOpenContent, setIsOpenContent] = React.useState(true);
  const smUp = useMediaQuery(breakpoints.up("sm"));
  const s = useStyles();
  const theme = useTheme();
  const drawerState = usePopupState({
    variant: "popover",
    popupId: "update-goals",
  });

  const handleClose = React.useCallback(() => {
    drawerState.close();
  }, [drawerState.close]);

  const data = React.useMemo(() => {
    return sortBy(steps, ({ targetDate }) => targetDate).map(
      ({ targetDate, count }) => ({
        x: dayjs(targetDate.toISOString()),
        y: count,
      }),
    );
  }, [steps]);

  const getToday = React.useCallback(() => {
    return steps.find(
      (day) =>
        day.targetDate.format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD"),
    );
  }, [steps]);

  const todayValue = React.useMemo(() => {
    const today = getToday();
    return today ? `${today.count} steps` : "0 steps ";
  }, [getToday]);

  const chartData = React.useMemo(() => {
    // undefined so that alltime data will never be averaged
    const timeUnits = ["day", "week", "month", "year", undefined, "alltime"];
    const averagingWindow: any = timeUnits[timeUnits.indexOf(activeButton) - 1];
    const unaveragedChunkSizes = {
      week: 7,
      month: 30,
      year: 365,
      alltime: Infinity,
    };
    const averagedChunkSizes = {
      week: 7,
      month: 4,
      year: 12,
      alltime: Infinity,
    };
    const interpolatedData = interpolateDataDiscrete(
      data,
      !settings.alignToRange || activeButton === "alltime",
      settings.pointsSum ? averagingWindow : undefined,
    );
    if (settings.alignToRange) {
      return chunkBy(
        interpolatedData,
        activeButton !== "alltime"
          ? [
              (activeButton === "week" ? "isoWeek" : activeButton) as string,
              activeButton === "week" ? "isoWeekYear" : "year",
            ]
          : [],
      );
    } else {
      return chunkKeepTail(
        interpolatedData,
        (settings.pointsSum ? averagedChunkSizes : unaveragedChunkSizes)[
          activeButton
        ],
      );
    }
  }, [activeButton, data, settings]);

  React.useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);

  const averagingStartPoint =
    navIndex - AVERAGING_WINDOW_SIZE > 0 ? navIndex - AVERAGING_WINDOW_SIZE : 0;
  const averagingEndPoint =
    navIndex + AVERAGING_WINDOW_SIZE + 1 > chartData.length
      ? chartData.length
      : navIndex + AVERAGING_WINDOW_SIZE + 1;

  const averagingData = chartData.slice(averagingStartPoint, averagingEndPoint);

  const chartMax = React.useMemo(() => {
    if (!chartData[navIndex] || !averagingData) return 0;
    const max =
      Math.max(
        averagingData
          .map(
            (page, index) =>
              Math.max(...page.map((point) => point.y || -Infinity)) *
              averagingFn(
                index + AVERAGING_WINDOW_SIZE * 2 + 1 - averagingData.length,
              ),
          )
          .reduce((acc, item) => acc + item, 0),
        ...chartData[navIndex].map((point) => point.y || -Infinity),
      ) * 1.1;

    return max >= 1000
      ? Math.ceil(max / 1000) * 1000
      : (Math.ceil(max * 1.1) / 5 + Number.EPSILON) * 5;
  }, [chartData, navIndex]);

  const chartMin = React.useMemo(() => {
    if (!chartData[navIndex] || !averagingData) return 0;
    return (
      Math.floor(
        Math.min(
          averagingData
            .map(
              (page, index) =>
                Math.min(...page.map((point) => point.y || Infinity)) /
                averagingFn(
                  index + AVERAGING_WINDOW_SIZE * 2 + 1 - averagingData.length,
                ),
            )
            .reduce((acc, item) => acc + item, 0),
          ...chartData[navIndex].map((point) => point.y || Infinity),
        ) /
          1.1 /
          5 +
          Number.EPSILON,
      ) * 5
    );
  }, [chartData, navIndex]);

  const chartTicks = React.useMemo(() => {
    const tickCount = 7;
    const ticks = new Set();
    for (let i = 0; i <= tickCount; i++) {
      const value =
        (Math.pow(i, 2) / Math.pow(tickCount, 2)) * (chartMax - chartMin) +
        chartMin;
      ticks.add(
        value >= 1000
          ? Math.round(value / 1000) * 1000
          : Math.round(value / 5) * 5,
      );
    }
    return Array.from(ticks) as number[];
  }, [chartMax, chartMin]);

  React.useEffect(() => {
    setNavIndex(chartData.length - 1);
  }, [chartData.length, activeButton]);

  React.useEffect(() => {
    !smUp ? setIsOpenContent(false) : setIsOpenContent(true);
  }, [smUp]);

  const genericDto = React.useMemo(
    () => steps.map((i) => stepFromStepsCount(i)),
    [steps],
  );

  const xTicksWithYear = React.useMemo(() => {
    const viewData = chartData[navIndex];
    if (!viewData || activeButton !== "alltime") return [];
    const tickCount = 15;
    const ticksPadding = Math.round(viewData.length / tickCount);
    const yearsStartpoints = viewData
      .filter((item) => item.year)
      .map((item) => item.x);

    let ticks = [...yearsStartpoints];

    for (let i = 0; i < tickCount - yearsStartpoints.length; i++) {
      const curr = ticks[i];
      const next = ticks[i + 1] || viewData[viewData.length - 1].x;
      if (dayjs(next).diff(dayjs(curr), "days") > 2 * ticksPadding) {
        const tick =
          viewData[
            viewData.findIndex((item) => item.x === curr) +
              Math.max(2 * ticksPadding, 1)
          ].x;
        ticks = [...ticks.slice(0, i + 1), tick, ...ticks.slice(i + 1)];
      }
    }

    return ticks;
  }, [chartData, navIndex, activeButton]);

  const handleSettingsChange = (field) => {
    return (value) =>
      setSettings((settings) => ({ ...settings, [field]: value }));
  };

  const tickFormater = (value: any) =>
    value >= 1000 ? `${Math.round(value / 1000)}k` : value.toString();

  const stepsCard = React.useMemo(() => {
    return (
      <Card className={clsx(s.root)} {...other}>
        <Box className={s.header}>
          <Box className={s.headerContainer}>
            <StepsIcon />
            <Box className={s.headerContent}>
              <Box className={s.todayContainer}>
                <Typography variant="h6" className={s.today} children="Today" />
              </Box>

              <Typography variant="body1" className={clsx(s.subtitle)}>
                {todayValue}
              </Typography>
              <Box className={s.iconButtonGroup}>
                <IconButton
                  className={s.iconButton}
                  {...bindToggle(drawerState)}
                >
                  <Pencil size={18} />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    setSettingsDialogAnchorEl(e.currentTarget);
                  }}
                  className={s.iconButton}
                >
                  <Settings size={18} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        {steps?.length > 0 ? (
          <>
            <Box className={s.buttons}>
              <TextButton
                className={clsx(
                  s.button,
                  activeButton === "week" && s.activeButton,
                )}
                onClick={() => {
                  setActiveButton(TimeUnit.WEEK);
                  setNavIndex(0);
                }}
              >
                Week
              </TextButton>
              <TextButton
                className={clsx(
                  s.button,
                  activeButton === "month" && s.activeButton,
                )}
                onClick={() => {
                  setActiveButton(TimeUnit.MONTH);
                  setNavIndex(0);
                }}
              >
                Month
              </TextButton>
              <TextButton
                className={clsx(
                  s.button,
                  activeButton === "year" && s.activeButton,
                )}
                onClick={() => {
                  setActiveButton(TimeUnit.YEAR);
                  setNavIndex(0);
                }}
              >
                Year
              </TextButton>
              <TextButton
                className={clsx(
                  s.button,
                  activeButton === "alltime" && s.activeButton,
                )}
                onClick={() => {
                  setActiveButton(TimeUnit.ALLTIME);
                  setNavIndex(0);
                }}
              >
                {smUp ? "All time" : "All"}
              </TextButton>
              <Box
                className={clsx(
                  s.headerNav,
                  activeButton === "alltime" && s.invisible,
                )}
              >
                <IconButton
                  className={s.navButton}
                  onClick={() => setNavIndex((prev) => prev - 1)}
                  disabled={navIndex === 0 || chartData.length === 0}
                >
                  <ChevronLeft
                    scale={2}
                    color={
                      navIndex === 0 || chartData.length === 0
                        ? "grey"
                        : "black"
                    }
                  />
                </IconButton>
                <IconButton
                  className={clsx(s.navButton, s.navButtonRight)}
                  onClick={() => setNavIndex((prev) => prev + 1)}
                  disabled={
                    chartData &&
                    (navIndex >= chartData.length - 1 || chartData.length === 0)
                  }
                >
                  <ChevronLeft
                    scale={2}
                    color={
                      chartData &&
                      (navIndex >= chartData.length - 1 ||
                        chartData.length === 0)
                        ? "grey"
                        : "black"
                    }
                  />
                </IconButton>
              </Box>
            </Box>
            <Box className={s.graphContainer}>
              <ResponsiveContainer
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  fontSize: 12,
                }}
                width="100%"
                height={308}
              >
                <BarChart
                  margin={{ right: 10, left: -20 }}
                  data={chartData[navIndex]}
                >
                  <CartesianGrid
                    strokeWidth={1.5}
                    vertical={false}
                    strokeDasharray="3 3"
                  />

                  {activeButton === "week" ? (
                    <>
                      <XAxis
                        dataKey="x"
                        tickFormatter={(value) => dayjs(value).format("ddd")}
                        xAxisId={0}
                        strokeWidth={1.5}
                        tickMargin={10}
                        stroke={theme.palette.text.secondary}
                        fill={theme.palette.text.secondary}
                      />
                      <XAxis
                        strokeWidth={1.5}
                        dataKey="x"
                        minTickGap={15}
                        xAxisId={1}
                        tickMargin={-5}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(value) => dayjs(value).format("MMM D")}
                        stroke={theme.palette.text.secondary}
                        fill={theme.palette.text.secondary}
                      />
                    </>
                  ) : (
                    <XAxis
                      strokeWidth={1.5}
                      dataKey="x"
                      minTickGap={15}
                      xAxisId={0}
                      tickMargin={10}
                      interval="preserveStart"
                      ticks={activeButton === "alltime" && xTicksWithYear}
                      tickFormatter={(value) => dayjs(value).format("MMM D")}
                      stroke={theme.palette.text.secondary}
                      fill={theme.palette.text.secondary}
                    />
                  )}

                  {activeButton === "alltime" && (
                    <XAxis
                      dataKey="year"
                      ticks={chartData[navIndex]
                        .filter((item) => item.year)
                        .map((item) => item.year)}
                      axisLine={false}
                      xAxisId={1}
                      strokeWidth={1.5}
                      tickMargin={-5}
                      tickLine={false}
                      stroke={theme.palette.text.secondary}
                      fill={theme.palette.text.secondary}
                    />
                  )}

                  <YAxis
                    domain={[chartMin, chartMax]}
                    ticks={chartTicks}
                    axisLine={false}
                    tickLine={false}
                    strokeWidth={1.5}
                    yAxisId={0}
                    tickFormatter={tickFormater}
                    stroke={theme.palette.text.secondary}
                    fill={theme.palette.text.secondary}
                  />

                  <Tooltip
                    animationDuration={300}
                    animationEasing="linear"
                    cursor={false}
                    content={ChartTooltip(borderColor)}
                  />

                  <Bar
                    dataKey="y"
                    stroke={borderColor}
                    fillOpacity={0.6}
                    fill={borderColor}
                    strokeWidth={2}
                    animationDuration={300}
                    barSize={20}
                    radius={[5, 5, 0, 0]}
                    activeBar={<Rectangle opacity={0.6} />}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </>
        ) : (
          <Typography className={s.noSteps}>
            Enter steps regularly or connect tracker on Profile page.
          </Typography>
        )}
      </Card>
    );
  }, [steps, activeButton, navIndex, smUp, settings]);

  return isLoading ? (
    <BasicSkeletonCard />
  ) : (
    <>
      {stepsCard}
      {drawerState.isOpen && (
        <Portal>
          <MeasurementDrawer
            {...omit(bindPopper(drawerState), ["anchorStepEl"])}
            onClose={handleClose}
            handleSave={handleSave}
            handleRemove={handleRemove}
            title="Steps"
            subtitle="Correct steps"
            today={getToday()}
            steps={genericDto}
            creating={creating}
            creatingForDate={creatingForDate}
            updatingId={updatingId}
            clientId={client?.id}
          />
        </Portal>
      )}
      <SettingsPopup
        anchorEl={settingsDialogAnchorEl}
        setAnchorEl={setSettingsDialogAnchorEl}
      >
        <SettingsPopupOption
          title="Bars"
          setting={settings.pointsSum}
          handleSettingChange={handleSettingsChange("pointsSum")}
          disabled={activeButton === "alltime"}
          options={[
            {
              label: "All records",
              description:
                "See daily records within your selected time frame (week, month, year, or all-time).",
              icon: <ChartColumn size={15} />,
            },
            {
              label: "Sums",
              description:
                "See sum client progress, shown daily (for weeks), weekly (for months), monthly (for years)",
              icon: <ChartColumnBig size={15} />,
            },
          ]}
        />
        <SettingsPopupOption
          title="Alignment"
          setting={settings.alignToRange}
          handleSettingChange={handleSettingsChange("alignToRange")}
          disabled={activeButton === "alltime"}
          options={[
            {
              label: "Recent days",
              description:
                "Focus on the latest 7, 30, or 365 days of the data.",
              icon: <ChartColumn size={15} />,
            },
            {
              label: "Calendar view",
              description:
                "View data for ongoing calendar week, month, or year.",
              icon: <Calendar size={15} />,
            },
          ]}
        />
      </SettingsPopup>
    </>
  );
}
