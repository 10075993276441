import React from "react";
import {
  Dialog,
  DialogProps,
  Box,
  Typography,
  Button,
  Stack,
  DialogContent,
  useTheme,
  Avatar,
} from "@mui/material";
import { AppLogo } from "../../app/AppLogo";
import EmptyActivitiesArt from "../../../icons/EmptyActivitiesArt";
import GetItOnGooglePlay from "../../../icons/GetItOnGooglePlay";
import DownloadOnAppStore from "../../../icons/DownloadOnAppStore";
import { useCurrentBrand } from "../../../hooks/useCurrentWorkspace";
import { iOSMobileApp } from "../../../utils/mobile";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export interface DownloadMobileAppDialogProps extends DialogProps {
  force?: boolean;
}

export function DownloadMobileAppDialog(props: DownloadMobileAppDialogProps) {
  const { className, onClose, force, ...other } = props;
  const {
    appName,
    AppleStoreUrl,
    GoogleStoreUrl,
    isBrandedAppPublished,
    brandName,
    brandedAppLogo,
  } = useCurrentBrand();
  const user = useCurrentUser();
  const theme = useTheme();

  const handleCloseButtonClick = React.useCallback(
    (event) => {
      if (onClose && !force) {
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  const forceMobileDialogContent = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Avatar
            variant="circular"
            sx={{ width: 58, height: 58, mb: 1 }}
            src={user?.coach.photoUrl}
          ></Avatar>
          <Typography variant="h6" gutterBottom>
            Your coach {user?.coach.displayName} is inviting you to experience{" "}
            {brandName} at its Best!
          </Typography>
        </Box>
        <Typography variant="body1" gutterBottom>
          Hey there! To get the full <b>{brandName}</b> experience, we've got a
          special app just for you! <br></br>Tap below to download it and unlock
          exclusive features
        </Typography>
        <Box
          component={"img"}
          sx={{
            width: 80,
            height: 80,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
            borderRadius: "20%",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
          src={brandedAppLogo}
        ></Box>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            letterSpacing: 0.5,
            color: "#333",
            textTransform: "capitalize",
            marginTop: 1,
          }}
        >
          {appName}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "center",
            my: 2,
            maxWidth: "160px",
          }}
        >
          {iOSMobileApp ? (
            <DownloadOnAppStore href={AppleStoreUrl}></DownloadOnAppStore>
          ) : (
            <GetItOnGooglePlay href={GoogleStoreUrl}></GetItOnGooglePlay>
          )}
        </Stack>
      </>
    );
  };

  const flexibleWebDialogContent = () => {
    return (
      <>
        {/* TODO_Logo: Refactor AppLogo width and height setting. STR-924 */}
        <AppLogo
          main={!isBrandedAppPublished}
          appStoreLogo={isBrandedAppPublished}
          full
          size={!isBrandedAppPublished ? 160 : 80}
          width={160}
        ></AppLogo>
        <Box
          sx={{
            width: "100%",
            maxWidth: "300px",
            height: "auto",
            marginY: 3,
          }}
        >
          <EmptyActivitiesArt fill={theme.palette.primary.main} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Thank you for choosing {appName}!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Install our mobile app for the best experience.
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "center",
            my: 2,
            maxWidth: "350px",
          }}
        >
          <GetItOnGooglePlay href={GoogleStoreUrl}></GetItOnGooglePlay>
          <DownloadOnAppStore href={AppleStoreUrl}></DownloadOnAppStore>
        </Stack>
        <Button
          onClick={() => {
            onClose({}, "escapeKeyDown");
          }}
          variant="text"
        >
          Not now
        </Button>
      </>
    );
  };

  return (
    <Dialog
      onClose={handleCloseButtonClick}
      PaperProps={{ sx: { width: 1, maxWidth: 500 } }}
      componentsProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(3px)",
          },
        },
      }}
      {...other}
    >
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          padding: 3,
          paddingTop: 4,
        }}
      >
        {force ? forceMobileDialogContent() : flexibleWebDialogContent()}
      </DialogContent>
    </Dialog>
  );
}
