import React, { useTransition, Suspense } from "react";
import {
  Box,
  Container,
  ContainerProps,
  Skeleton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useQueryParam } from "../../hooks/useQueryParam";

import { ClientProgramTimeline } from "./ClientProgramTimeline";
import { ClientProgramWeek } from "./ClientProgramWeek";
import {
  ClientEnrollmentWeekDto,
  ProgramWeeksDto,
} from "@growth-machine-llc/stridist-api-client";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import EnrollmentsClientService from "../../services/EnrollmentsClientService";
import { ISO_DATE_FORMAT } from "../../utils/date";
import { ProgramComponentsSkeleton } from "../loading/ProgramComponentsSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },
  timeline: {
    // TODO: Normalize theme shadows
    filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.05))",
    marginBottom: theme.spacing(4),
    marginTop: 0,
    [theme.containerQueries.down("md")]: {
      marginTop: `calc(${theme.spacing(8)} + var(--safe-area-inset-top))`,
    },

    [theme.breakpoints.down("md")]: {
      marginTop: `calc(${theme.spacing(8)} + var(--safe-area-inset-top))`,
    },
  },
  header: {
    fontSize: 30,
    marginBottom: theme.spacing(5),
  },
  paper: {
    padding: theme.spacing(3.5, 6, 4),
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(2.5),
  },
  component: {
    margin: theme.spacing(1, 0),
  },
  selected: {},
}));

export const CLIENT_ENROLLMENT_WEEK_QUERY_KEY = "client-enrollment-week";

export interface ClientProgramProps extends Omit<ContainerProps, "children"> {
  enrollment?: ClientEnrollmentWeekDto;
  slug?: string;
  program?: ProgramWeeksDto;
}

export function ClientProgram(props: ClientProgramProps) {
  const s = useStyles();
  const [weekQueryParam, setWeekQueryParam] = useQueryParam("week");
  const { slug, program } = props;

  const currentWeek = program?.currentWeek || 1;

  const tempWeekNumber = Number(weekQueryParam) || currentWeek;

  const selectedWeek = React.useMemo(() => {
    if (program && program.weeks) {
      const week = program.weeks.find(
        ({ weekNumber }) => weekNumber === tempWeekNumber,
      );
      return week || program.weeks[0];
    }
  }, [program, tempWeekNumber]);

  const selectedWeekNumber = selectedWeek.weekNumber;

  React.useEffect(() => {
    if (!weekQueryParam && selectedWeekNumber) {
      setWeekQueryParam(String(selectedWeekNumber));
    }
  }, [selectedWeek, setWeekQueryParam, weekQueryParam]);

  const {
    data: enrollment,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      CLIENT_ENROLLMENT_WEEK_QUERY_KEY,
      { week: selectedWeekNumber, slug },
    ],
    queryFn: () =>
      EnrollmentsClientService.getClientEnrollmentWeek(
        slug,
        selectedWeekNumber,
      ),
    placeholderData: keepPreviousData,
  });

  return (
    <>
      <ClientProgramTimeline
        className={s.timeline}
        weeks={program.weeks}
        currentWeek={currentWeek}
        selected={selectedWeek.weekNumber}
        setSelected={(week) => setWeekQueryParam(week)}
      />

      {isFetching ? (
        <>
          <ProgramComponentsSkeleton />
        </>
      ) : (
        <>
          <Container className={s.root}>
            {selectedWeek.weekNumber &&
            program &&
            enrollment &&
            program.weeks.length ? (
              <ClientProgramWeek
                week={selectedWeek}
                startDate={enrollment.startDate.format(ISO_DATE_FORMAT)}
                activities={enrollment.activities}
                completionRate={enrollment.completionRate}
                program={program}
              />
            ) : program ? (
              <Typography>Program does not contain any lessons</Typography>
            ) : (
              <Typography>
                You are currently not enrolled into a program
              </Typography>
            )}
          </Container>
        </>
      )}
    </>
  );
}
