import { Box, Typography } from "@mui/material";
import React from "react";
import clsx from "clsx";

interface IconButtonOption<T> {
  value: T;
  icon: React.ReactNode;
  label?: string;
}

interface ToggleIconButtonGroupProps<T> {
  className?: string;
  options: IconButtonOption<T>[];
  value: T;
  displayLabel?: "always" | "selected";
  color?: "primary" | "secondary";
  onChange: (value: T) => void;
}

function ToggleIconButtonGroup<T>({
  className,
  options,
  value,
  displayLabel = "selected",
  color = "primary",
  onChange,
}: ToggleIconButtonGroupProps<T>) {
  return (
    <Box
      className={clsx(className)}
      sx={{
        display: "flex",
        "& button": {
          display: "flex",
          alignItems: "center",
          paddingY: 1,
          paddingX: 2,
          border: 1,
          borderColor: "divider",
          borderRadius: 0,
          cursor: "pointer",

          "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
          },

          "&:first-of-type": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: (theme) => theme.spacing(1),
            borderBottomLeftRadius: (theme) => theme.spacing(1),
          },

          "&:last-of-type": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: (theme) => theme.spacing(1),
            borderBottomRightRadius: (theme) => theme.spacing(1),
          },

          "&:not(:first-of-type):not(:last-of-type)": {
            borderLeft: 0,
            borderRight: 0,
          },
        },
      }}
    >
      {options.map((item, index) => {
        const { value: optionValue, icon, label } = item;
        const isSelected = optionValue === value;

        return (
          <Box
            component="button"
            key={index}
            sx={{
              ...(isSelected && {
                backgroundColor: (theme) => theme.palette.action.selected,
                "& svg": {
                  color: (theme) => theme.palette[color].main,
                },
              }),
            }}
            onClick={() => onChange(optionValue)}
          >
            {icon}
            {label && (displayLabel === "always" || isSelected) && (
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 1,
                  fontWeight: "medium",
                  color: isSelected ? color : "text.secondary",
                }}
              >
                {label}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default ToggleIconButtonGroup;
