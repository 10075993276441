import clsx from "clsx";
import React, { useEffect } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDebounce } from "../../hooks/useDebounce";
import useInfiniteScrollQuery from "../../hooks/useInfiniteScroll";
import { keepPreviousData } from "@tanstack/react-query";
import AdminService from "../../services/AdminService";
import { ExerciseAssetsType } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    margin: 0,
  },

  popper: {
    zIndex: "99999 !important" as any,
    borderRadius: 12,
    boxShadow: theme.shadows[5],
  },
}));

export interface ExerciseAutocompleteProps
  extends Omit<
    AutocompleteProps<string, true, false, any, any>,
    "options" | "onChange" | "onInputChange" | "renderInput"
  > {
  pageSize?: number;
  onCloseAutocomplete?: (options: string[]) => void;
  onInputChange?: (e: any, value: string, options: string[]) => void;
  renderCustomInput?: (
    params: AutocompleteRenderInputParams,
    isLoading: boolean,
  ) => React.ReactNode;
  onFetchingExercise?: (value: boolean) => void;
  onInitialExercise?: (exercise: any) => void;
}

const EXERCISE_AUTOCOMPLETE_PAGE_SIZE = 20;
const ADMIN_EXERCISES_ASSETS_KEY_AUTOCOMPLETE_QUERY_KEY =
  "admin-exercises-assets-autocomplete";

export function AdminExerciseAutocomplete(props: ExerciseAutocompleteProps) {
  const {
    className,
    pageSize = 50,
    inputValue,
    onInputChange,
    onFetchingExercise,
    onInitialExercise,
    onCloseAutocomplete,
    classes,
    ...other
  } = props;

  const theme = useTheme();

  const delayedQuery = useDebounce(inputValue, 250);
  const {
    data: customAssetsData,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteScrollQuery({
    queryKey: [
      ADMIN_EXERCISES_ASSETS_KEY_AUTOCOMPLETE_QUERY_KEY,
      { query: delayedQuery },
    ],
    queryFn: ({ pageParam = 1 }) =>
      AdminService.getExerciseCustomAssetsByType(
        ExerciseAssetsType.LIBRARY,
        pageParam as number,
        EXERCISE_AUTOCOMPLETE_PAGE_SIZE,
        inputValue === "" ? undefined : inputValue,
      ),
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    getNextPageParam: (lastPage, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
  });

  const exerciseAssets =
    customAssetsData?.pages.flatMap((page) => page.items) ?? [];

  const options = React.useMemo(
    () => exerciseAssets?.map((a) => a.name),
    [exerciseAssets],
  );
  const s = useStyles();

  useEffect(() => {
    const matchedExercise = options.find(
      (title) => title.trim() === inputValue.trim(),
    );
    if (onFetchingExercise) {
      onFetchingExercise(isFetching || !!matchedExercise);
    }
  }, [isFetching, onFetchingExercise, inputValue, options]);

  return (
    <Autocomplete
      classes={{
        root: clsx(s.root, className),
        paper: s.paper,
        popper: s.popper,
        ...classes,
      }}
      forcePopupIcon={false}
      filterOptions={(options) => options}
      inputValue={inputValue}
      clearOnBlur={false}
      options={options}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : (option[0] as string)
      }
      freeSolo
      renderOption={(props, option, { index }) => (
        <>
          {index === 0 && (
            <div
              style={{
                padding: theme.spacing(1),
                fontWeight: "bold",
                background: theme.palette.grey[200],
              }}
            >
              You can't create asset with name that are already in the library
            </div>
          )}
          <li {...props}>{option}</li>
        </>
      )}
      getOptionDisabled={() => true}
      onClose={(_, reason) => {
        if (onCloseAutocomplete && (reason === "blur" || reason === "escape")) {
          onCloseAutocomplete(options);
        }
      }}
      onInputChange={(_, value) => onInputChange(_, value, options)}
      disableClearable={false}
      renderInput={(params) => props.renderCustomInput(params, isFetching)}
      {...other}
      slotProps={{
        listbox: {
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            if (
              Math.round(listboxNode.scrollTop) + listboxNode.clientHeight >
              listboxNode.scrollHeight - 100
            ) {
              if (!isFetching && hasNextPage) fetchNextPage();
            }
          },
        },
      }}
    />
  );
}
