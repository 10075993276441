import React from "react";
import { HouseIcon as HomeIcon, SettingsIcon } from "lucide-react";
import { FoldersIcon as ProgramsIcon } from "lucide-react";
import { UserIcon as ClientsIcon } from "lucide-react";
import { SquareActivityIcon as ActivityIcon } from "lucide-react";
import { MessageSquareTextIcon as MessagesIcon } from "lucide-react";
import { UsersIcon as GroupsIcon } from "lucide-react";
import { MegaphoneIcon as MarketingIcon } from "lucide-react";
import { PencilRulerIcon as BrandIcon } from "lucide-react";
import { UnreadActivitiesCounter } from "../counter/UnreadActivitiesCounter";
import { UnreadMessagesCounter } from "../counter/UnreadMessagesCounter";
import {
  CLIENT_ACTIVITY_ROUTE,
  CLIENT_GROUP_POST_ROUTE,
  CLIENT_HOME_ROUTE,
  CLIENT_PROFILE_ROUTE,
  CLIENT_PROGRAMS_ACTIVE_ROUTE,
  COACH_ACTIVITY_INBOX_ROUTE,
  COACH_CLIENT_PORTAL_ROUTE,
  COACH_CLIENTS_ACTIVE_ROUTE,
  COACH_GROUPS_ACTIVE_ROUTE,
  COACH_MARKETING_ROUTE,
  COACH_MESSAGES_ROUTE,
  COACH_PROGRAMS_PUBLISHED_ROUTE,
  HOME_ROUTE,
  MESSAGES_ROUTE,
} from "../../routes/routes";
import { getISODate } from "../../utils/date";

export const menuItems = {
  client: [
    {
      text: "Home",
      link: HOME_ROUTE,
      Icon: HomeIcon,
    },
    {
      text: "Programs",
      link: CLIENT_PROGRAMS_ACTIVE_ROUTE,
      Icon: ProgramsIcon,
    },
    {
      text: "Groups",
      link: CLIENT_GROUP_POST_ROUTE,
      Icon: GroupsIcon,
    },
    {
      text: <UnreadActivitiesCounter>Activity</UnreadActivitiesCounter>,
      link: CLIENT_ACTIVITY_ROUTE,
      Icon: ActivityIcon,
    },
    {
      text: <UnreadMessagesCounter>Messages</UnreadMessagesCounter>,
      link: MESSAGES_ROUTE,
      Icon: MessagesIcon,
    },
    {
      text: "Profile",
      link: CLIENT_PROFILE_ROUTE,
      Icon: ClientsIcon,
    },
  ],
  coach: [
    {
      text: "Programs",
      link: COACH_PROGRAMS_PUBLISHED_ROUTE,
      Icon: ProgramsIcon,
    },
    {
      text: "Clients",
      link: COACH_CLIENTS_ACTIVE_ROUTE,
      Icon: ClientsIcon,
    },
    {
      text: "Groups",
      link: COACH_GROUPS_ACTIVE_ROUTE,
      Icon: GroupsIcon,
    },
    {
      text: <UnreadActivitiesCounter>Activity</UnreadActivitiesCounter>,
      link: COACH_ACTIVITY_INBOX_ROUTE,
      Icon: ActivityIcon,
    },
    {
      text: <UnreadMessagesCounter>Messages</UnreadMessagesCounter>,
      link: COACH_MESSAGES_ROUTE,
      Icon: MessagesIcon,
    },
    {
      text: "Client portal",
      link: COACH_CLIENT_PORTAL_ROUTE,
      Icon: BrandIcon,
    },
    {
      text: "Marketing",
      link: COACH_MARKETING_ROUTE,
      Icon: MarketingIcon,
    },
  ],
};
