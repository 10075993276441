import { alpha, Avatar, Box, SxProps, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { ReactNode } from "react";
import { useEnableColors } from "../../../../hooks/useEnableColors";

interface IFilterBadgeProps<T> {
  value: T;
  component: ReactNode;
  selected?: boolean;
  resizeForMobile?: boolean;
  color?: {
    selected: string;
    unselected: string;
    hovered: string;
  };
  sx?: SxProps;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement | HTMLLIElement, MouseEvent>,
    value: T,
  ) => void;
}
const FilterBadge = <T,>(props: IFilterBadgeProps<T>) => {
  const {
    value,
    component,
    selected = true,
    resizeForMobile,
    color: customColor,
    onClick,
    sx,
  } = props;

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const enableColors = useEnableColors();

  return (
    <>
      <Avatar
        sx={{
          width: resizeForMobile ? (isMd ? 32 : 40) : 32,
          height: resizeForMobile ? (isMd ? 32 : 40) : 32,

          border: 2,
          borderStyle: !selected ? "dashed" : "solid",

          transition: theme.transitions.create(
            ["background-color", "border-color"],
            {
              duration: "1s",
              easing: theme.transitions.easing.easeOut,
            },
          ),

          // No colors
          ...(!enableColors && {
            color: theme.palette.common.black,
            bgcolor: theme.palette.common.white,
            borderColor: theme.palette.grey[400],

            ":hover": {
              bgcolor: theme.palette.grey[300],
            },
          }),

          // Colored
          ...(enableColors && {
            color: theme.palette.primary.contrastText,
            bgcolor: selected
              ? alpha(theme.palette.primary.main, 1)
              : alpha(theme.palette.primary.main, 0.5),

            borderColor: selected
              ? alpha(theme.palette.primary.main, 0.75)
              : alpha(theme.palette.primary.main, 0.5),

            // Custom colors
            ...(customColor && {
              bgcolor: selected ? customColor.selected : customColor.unselected,
              borderColor: selected
                ? customColor.hovered
                : customColor.unselected,
            }),

            ":hover": {
              cursor: "pointer",
              bgcolor: customColor
                ? customColor.hovered
                : alpha(theme.palette.primary.main, 0.75),
            },
          }),
          ...sx,
        }}
        onClick={(e) => onClick && onClick(e, value)}
      >
        <Box sx={{ fontSize: 16, fontWeight: "bold" }}>{component}</Box>
      </Avatar>
    </>
  );
};

export default FilterBadge;
