import React from "react";

export type ProgramWeek = {
  id: number;
  week: number;
};

export const ProgramWeeksContext =
  React.createContext<Array<ProgramWeek>>(null);

export function useProgramWeeks(): ProgramWeek[] {
  return React.useContext(ProgramWeeksContext);
}

export function useProgramWeekGetNumberById(weekId: number): number {
  const weeksArr = React.useContext(ProgramWeeksContext);
  return weeksArr?.find((w) => w.id === weekId)?.week;
}
