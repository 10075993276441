import clsx from "clsx";
import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TextField } from "@mui/material";

import { colorSystem } from "../../theme";
import {
  floatNumberInputProps,
  wholeNumberInputProps,
  ExerciseTypeReps,
  ExerciseTypeSet,
  ExerciseTypeExtra,
  ExerciseSetNumberType,
} from "../../constants";
import { unitSetLabelCoach } from "../../utils/units";
import {
  getExtraColumnLabel,
  getRepsColumnLabel,
  hasExtraColumn,
} from "../workout/utils";
import WorkoutSetNumber from "../workout/workout-set/WorkoutSetNumber";
import CoachSetRangeInput from "./CoachSetRangeInput";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colorSystem.border2,
    borderRadius: theme.spacing(1),
  },
  menu: {
    zIndex: "99999 !important" as any,
  },
  rootNonTopBorder: {
    borderTopWidth: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  rootNonBottomBorderRadius: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  notchedOutline: {
    borderColor: "transparent",
    borderLeftColor: colorSystem.border2,
  },
  field: {
    width: "100%",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  fieldRange: {
    "& input": {
      minWidth: "72px",
    },
  },
  inputRoot: {
    width: "100%",
  },
  inputFocused: {
    "& $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  inputDisabled: {
    backgroundColor: theme.palette.grey[100],
    "&.Mui-disabled fieldset$notchedOutline": {
      borderColor: `transparent`,
      borderLeftColor: colorSystem.border2,
    },
  },
  indexBlock: {
    minWidth: 57,
    height: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spanText: {
    fontSize: 16,
    fontFamily: "Montserrat, sans-serif",
    fontWeight: 700,
    color: colorSystem.gray,
  },
  inputContainer: {
    position: "relative",
    width: "100%",
  },
  inputLabel: {
    position: "absolute",
    left: 16,
    top: "50%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down("sm")]: {
      top: "30%",
      left: "15%",
    },
  },
  inputLabelDisabled: {
    color: theme.palette.text.disabled,
  },
  input: {
    textAlign: "center",
    paddingLeft: 40,
    background: "transparent!important",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingLeft: "12%",
    },
  },
  fourColumnLayout: {
    "& $input": {
      paddingLeft: 50,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "15%",
      },
    },
  },
  userIconContainer: { padding: theme.spacing(0, 2, 0, 0) },
}));

export interface CoachSetsProps {
  extraMeasurement: number | string;
  reps: number | string;
  weight: number | string;
  index: number;
  units: string;
  typeExtraMeasurement: ExerciseTypeExtra;
  typeSet: ExerciseTypeSet;
  typeReps: ExerciseTypeReps;
  onChangeSet: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
  ) => void;
  disabled?: boolean;
  type: ExerciseSetNumberType;
  totalSets: number;
}

function CoachSet(props: CoachSetsProps) {
  const {
    extraMeasurement,
    reps,
    weight,
    index,
    onChangeSet,
    units,
    typeExtraMeasurement,
    typeSet,
    typeReps,
    disabled,
    type,
    totalSets,
  } = props;
  const s = useStyles();

  const handleSetNumberChange = (value: ExerciseSetNumberType) => {
    const event = {
      currentTarget: {
        name: "type",
        value: value,
      },
    };

    onChangeSet(
      event as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      index,
    );
  };

  const handleRepsRangeChange = (value: string) => {
    const event = {
      currentTarget: {
        name: "reps",
        value: value,
      },
    };

    onChangeSet(
      event as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      index,
    );
  };

  const { isLast, isFirst, isOnly } = {
    isLast: index === totalSets - 1,
    isFirst: index === 0,
    isOnly: totalSets === 1,
  };

  return (
    <>
      <div
        className={clsx(
          !isFirst && s.rootNonTopBorder,
          !isLast && s.rootNonBottomBorderRadius,
          s.root,
          hasExtraColumn(typeExtraMeasurement) && s.fourColumnLayout,
        )}
      >
        <div className={s.indexBlock}>
          <WorkoutSetNumber
            setNumber={index + 1}
            type={type}
            onChange={handleSetNumberChange}
            disabled={disabled}
          />
        </div>
        <div className={s.inputContainer}>
          {typeReps === ExerciseTypeReps.RANGE ? (
            <CoachSetRangeInput
              reps={reps}
              onChange={handleRepsRangeChange}
              disabled={disabled}
              dense={hasExtraColumn(typeExtraMeasurement)}
            />
          ) : (
            <TextField
              className={s.field}
              name="reps"
              value={reps}
              onChange={(e) => !disabled && onChangeSet(e, index)}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              variant="outlined"
              autoComplete="off"
              type="number"
              disabled={disabled}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: 0,
                },
              }}
              slotProps={{
                input: {
                  classes: {
                    notchedOutline: s.notchedOutline,
                    root: s.inputRoot,
                    focused: s.inputFocused,
                    disabled: s.inputDisabled,
                    input: s.input,
                  },
                  inputProps: wholeNumberInputProps,
                },
              }}
            />
          )}
          <span
            className={clsx(
              s.spanText,
              s.inputLabel,
              disabled || s.inputLabelDisabled,
            )}
          >
            {getRepsColumnLabel(typeReps, false)}
          </span>
        </div>
        <div className={s.inputContainer}>
          <TextField
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            className={s.field}
            name="weight"
            value={weight}
            onChange={(e) => !disabled && onChangeSet(e, index)}
            variant="outlined"
            type="number"
            autoComplete="off"
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderRadius: 0,
                ...(!hasExtraColumn(typeExtraMeasurement) && {
                  borderTopRightRadius:
                    !isFirst && !isOnly ? 0 : (theme) => theme.spacing(1),
                  borderBottomRightRadius:
                    !isLast && !isOnly ? 0 : (theme) => theme.spacing(1),
                }),
              },
            }}
            slotProps={{
              input: {
                classes: {
                  notchedOutline: s.notchedOutline,
                  root: s.inputRoot,
                  focused: s.inputFocused,
                  input: s.input,
                },
                inputProps: {
                  ...floatNumberInputProps,
                },
              },
            }}
          />
          <span className={clsx(s.spanText, s.inputLabel)}>
            {unitSetLabelCoach(units, typeSet)}
          </span>
        </div>
        {hasExtraColumn(typeExtraMeasurement) && (
          <div className={s.inputContainer}>
            <TextField
              className={s.field}
              name="extraMeasurement"
              value={extraMeasurement}
              onChange={(e) => !disabled && onChangeSet(e, index)}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              variant="outlined"
              type="number"
              autoComplete="off"
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: 0,
                  borderTopRightRadius:
                    !isFirst && !isOnly ? 0 : (theme) => theme.spacing(1),
                  borderBottomRightRadius:
                    !isLast && !isOnly ? 0 : (theme) => theme.spacing(1),
                },
              }}
              slotProps={{
                input: {
                  classes: {
                    notchedOutline: s.notchedOutline,
                    root: s.inputRoot,
                    focused: s.inputFocused,
                    input: s.input,
                  },
                  inputProps: {
                    ...floatNumberInputProps,
                    min: 0,
                  },
                },
              }}
            />
            <span className={clsx(s.spanText, s.inputLabel)}>
              {getExtraColumnLabel(typeExtraMeasurement)}
            </span>
          </div>
        )}
      </div>
    </>
  );
}

export default CoachSet;
