import { createContext } from "react";
import {
  CurriculumVm,
  ProgramEnrollmentsAvatarsVm,
} from "@growth-machine-llc/stridist-api-client";
import { CurriculumProgram } from "../redux/types";
import { NormalizedCurriculum } from "../redux/curriculum/curriculum-slice";

export type ProgramQuery = Omit<CurriculumVm, "curriculum"> & {
  curriculum: NormalizedCurriculum;
};
export interface IProgramContextProps {
  programQuery: ProgramEnrollmentsAvatarsVm;
  setProgramQuery: (programQuery: ProgramEnrollmentsAvatarsVm) => void;
  resetProgramQuery: () => void;
}

export const ProgramContext = createContext<IProgramContextProps | undefined>(
  undefined,
);
