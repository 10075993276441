import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { FieldsGroup, FieldsGroupProps } from "./FieldsGroup";
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormWatch,
} from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { UserAge } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {},

  newDesign: {},

  controls: {
    display: "flex",
  },

  error: {
    position: "absolute",
  },

  calendar: {
    display: "flex",
    width: "auto",
  },

  select: {
    fontWeight: 500,
    backgroundColor: theme.palette.background.paper,
  },

  empty: {
    color: theme.palette.text.secondary,
  },
}));

export const getStartEndDate = (minAge: number, maxAge: number) => {
  const currentYear = new Date();
  const startDate = new Date(
    currentYear.getFullYear() - maxAge,
    currentYear.getMonth(),
    currentYear.getDate() + 1,
  );
  const endDate = new Date(
    currentYear.getFullYear() - minAge,
    currentYear.getMonth(),
    currentYear.getDate(),
  );
  return { startDate, endDate };
};

export interface BirthdayOptionsProps {
  day?: number;
  month?: number;
  year?: number;
}

interface BirthdayFormField {
  birthday?: string;
}

export interface BirthdayFieldProps<T = BirthdayFormField>
  extends Omit<FieldsGroupProps, "onChange" | "label" | "error" | "children"> {
  disabled?: boolean;
  errorMessage?: string;
  clearErrors: UseFormClearErrors<T>;
  watch: UseFormWatch<T>;
  control: Control<T>;
  handleBlur?: () => void;
}

export const DATE_BIRTHDAY_FORMAT = "MM/DD/YYYY";

export function BirthdayField(props: BirthdayFieldProps) {
  const {
    className,
    disabled,
    watch,
    clearErrors,
    handleBlur,
    control,
    errorMessage,
    ...other
  } = props;
  const s = useStyles();

  const { startDate, endDate } = getStartEndDate(UserAge.MIN, UserAge.MAX);
  return (
    <Controller
      name="birthday"
      control={control}
      render={({ field }) => (
        <>
          <FieldsGroup
            label="Birthday"
            error={errorMessage}
            hideErrorIcon
            errorClassName={s.error}
            {...other}
          >
            <DatePicker
              {...field}
              views={["year", "month", "day"]}
              className={clsx(s.calendar, className)}
              value={
                field.value ? dayjs(field.value, DATE_BIRTHDAY_FORMAT) : null
              }
              minDate={dayjs(startDate)}
              maxDate={dayjs(endDate)}
              onChange={(date) => {
                field.onChange(date ? date.format(DATE_BIRTHDAY_FORMAT) : "");
                clearErrors("birthday");
              }}
              slotProps={{
                textField: {
                  onBlur: () => {
                    if (handleBlur) handleBlur();
                  },
                },
              }}
            />
          </FieldsGroup>
        </>
      )}
    />
  );
}
