// TODO move selectors to different files

import { OverridesWithCoordinatesType } from "../../../components/program-component/CoachComponent";
import { ComponentStatus, ComponentType } from "../../../constants";
import type { CurriculumRootState } from "../../store";

export const selectCurriculum = (state: CurriculumRootState) =>
  state.curriculum.present;

export const selectProgramWeeks = (state: CurriculumRootState) => {
  const weeks = state.curriculum.present?.value?.weeks;
  if (!weeks) return [];

  const weeksArr = Object.values(weeks).map((week) => ({
    id: week.id,
    week: week.weekNumber,
  }));

  return weeksArr.sort((a, b) => a.week - b.week);
};

export const selectWeeksComponents = (state: CurriculumRootState) => {
  const weeks = state.curriculum.present?.value?.weeks;
  if (!weeks) return [];

  const weeksArr = Object.values(weeks).map((week) => ({
    id: week.id,
    week: week.weekNumber,
    components: week.components,
  }));

  return weeksArr.sort((a, b) => a.week - b.week);
};

export const selectPastCurriculum = (state: CurriculumRootState) =>
  state.curriculum.past;
export const selectFutureCurriculum = (state: CurriculumRootState) =>
  state.curriculum.future;

export const selectProgram = (state: CurriculumRootState) =>
  state.curriculum.present.value?.program;

export const isProgramEmpty = (state: CurriculumRootState) => {
  const components = state.curriculum.present.value.components;
  return !components || Object.keys(components).length === 0;
};

export const selectProgramImage = (state: CurriculumRootState) =>
  state.curriculum.present.value.program.image;

export const selectProgramSlug = (state: CurriculumRootState) =>
  state.curriculum.present.value.program.slug;

export const selectFirstWeek = (state: CurriculumRootState) =>
  state.curriculum.present.value.weeks[
    state.curriculum.present.value.program.weeks[0]
  ];

export const selectWeeks = (state: CurriculumRootState) =>
  state.curriculum.present.value.weeks;

export const selectComponents = (state: CurriculumRootState) =>
  state.curriculum.present.value.components;

export const selectWeekById = (id: number) => (state: CurriculumRootState) =>
  state.curriculum.present.value.weeks[id];

export const selectComponentBySlug =
  (slug: string) => (state: CurriculumRootState) => {
    return Object.values(state.curriculum.present.value?.components || {}).find(
      (component) => component.slug === slug,
    );
  };

export const selectOverridesSlugsByComponentId =
  (id: number) => (state: CurriculumRootState) => {
    const componentOverridesIdArr =
      state.curriculum.present.value.components[id].overrides;

    if (!componentOverridesIdArr || !Array.isArray(componentOverridesIdArr)) {
      return [];
    }

    return componentOverridesIdArr.map(
      (overrideId) =>
        state.curriculum.present.value.components[overrideId].slug,
    );
  };

export const selectComponentBySlugId =
  (slugId: string) => (state: CurriculumRootState) => {
    return (
      Object.values(state.curriculum.present.value?.components || {}).find(
        (component) => component.slugId === slugId,
      ) ||
      Object.values(state.curriculum.present.value?.components || {}).find(
        (component) => component.slug === `${slugId}-tempslug`,
      )
    );
  };

export const selectComponentExistsBySlugId =
  (slugId: string) => (state: CurriculumRootState) => {
    return (
      Object.values(state.curriculum.present.value?.components || {}).some(
        (component) => component.slugId === slugId,
      ) ||
      Object.values(state.curriculum.present.value?.components || {}).some(
        (component) => component.slug === `${slugId}-tempslug`,
      )
    );
  };

export const selectComponentById =
  (id: number) => (state: CurriculumRootState) => {
    return state.curriculum.present.value?.components[id];
  };

export const selectPresentCurriculumId = (state: CurriculumRootState) =>
  state.curriculum.present.value.program.id;

export const selectCantMoveWeeksExceptSelf =
  (self: number) => (state: CurriculumRootState) => {
    return Object.values(state.curriculum.present?.value?.weeks || {})
      .filter((week) => week.id !== self && week.waitingForApiResponse)
      .map((week) => week.weekNumber);
  };

export const selectComponentsAmountByType =
  (componentType: ComponentType) => (state: CurriculumRootState) => {
    return Object.values(state.curriculum.present.value.components).filter(
      (component) => component.type === componentType,
    ).length;
  };

export const selectComponentsAmount = (state: CurriculumRootState) => {
  return Object.keys(state.curriculum.present.value.components).length;
};

export const selectWeekNumberByWeekId =
  (id: number) => (state: CurriculumRootState) =>
    state.curriculum.present.value.weeks[id]?.weekNumber;

export const selectComponentOverrides =
  (id: number) => (state: CurriculumRootState) => {
    const componentOverridesIdArr =
      state.curriculum.present.value.components[id].overrides;

    if (!componentOverridesIdArr || !Array.isArray(componentOverridesIdArr)) {
      return [];
    }

    return componentOverridesIdArr.map(
      (overrideId) => state.curriculum.present.value.components[overrideId],
    );
  };

export const selectFromWeekFirstVisibleComponent =
  (weekNumber: number) => (state: CurriculumRootState) => {
    const week = Object.values(state.curriculum.present.value.weeks).find(
      (w) => w.weekNumber === weekNumber,
    );

    const firstActiveComponent = week.positions
      .map(
        (pos: string | number) =>
          state.curriculum.present.value.components[Number(pos)],
      )
      .find((component) => component.status !== ComponentStatus.ARCHIVED);

    return firstActiveComponent;
  };

export const selectOverridesWithCoordinates =
  (arr: number[]) =>
  (state: CurriculumRootState): OverridesWithCoordinatesType[] => {
    const components = state.curriculum.present.value.components;
    const weeks = state.curriculum.present.value.weeks;

    return arr
      .map((id) => {
        const component = components[id];
        if (!component) return null;

        return {
          id: component.id,
          day: (JSON.parse(component.days).findIndex((value) => value) +
            1) as number,
          weekNumber: weeks[component.weekId]?.weekNumber ?? null,
        };
      })
      .filter(Boolean);
  };

export const selectSplitResults = (state: CurriculumRootState) =>
  Object.values(state.curriculum.present.value.components).filter(
    (component) => component.isRecentSplitResult === true,
  );
