import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { AppLogo } from "../app/AppLogo";

import { ForgotPasswordFormX } from "./ForgotPasswordForm";
import { PasswordResetDialog } from "./PasswordResetDialog";
import { useMutation } from "@tanstack/react-query";
import SignUpService from "../../services/SignUpService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(10, 3),
    backgroundColor: colorSystem.white2,
  },

  logo: {
    marginBottom: theme.spacing(10),
  },
}));

export interface ForgotPasswordScreenProps extends BoxProps {
  returnUrl?: string;
}

export function ForgotPasswordScreen(props: ForgotPasswordScreenProps) {
  const { className, returnUrl, ...other } = props;
  const s = useStyles();
  const [email, setEmail] = React.useState("");
  const [passwordResetDialogOpen, setPasswordResetDialogOpen] =
    React.useState(false);

  const { mutate: resetPassword, isPending: resetPasswordInFlight } =
    useMutation({
      mutationKey: ["reset-password"],
      mutationFn: SignUpService.resetPassword,
      onSuccess: () => {
        setPasswordResetDialogOpen(true);
      },
    });

  const handleEmailChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(value);
    },
    [],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      resetPassword({ email });
    },
    [email, resetPassword],
  );

  return (
    <>
      <Box className={clsx(s.root, className)} {...other}>
        <AppLogo className={s.logo} main full width={160} height={37} />
        <ForgotPasswordFormX
          email={email}
          onEmailChange={handleEmailChange}
          onSubmit={handleSubmit}
          disabled={resetPasswordInFlight}
        />
      </Box>
      <PasswordResetDialog open={passwordResetDialogOpen} email={email} />
    </>
  );
}
