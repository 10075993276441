import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

export const removeOverrideAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{ overrideId: number }>,
) => {
  const { overrideId } = action.payload;

  const overrideComponent = state.value.components[overrideId];
  if (!overrideComponent) return;
  if (!overrideComponent.baseComponentId) {
    throw new Error("Invalid operation for the base component");
  }

  const baseComponent =
    state.value.components[overrideComponent.baseComponentId];
  if (!baseComponent) {
    throw new Error("Base component not found");
  }

  baseComponent.overrides = baseComponent.overrides.filter(
    (id) => id !== overrideId,
  );

  delete state.value.components[overrideId];

  const week = state.value.weeks[overrideComponent.weekId];
  if (!week) return;

  week.components = week.components.filter((id) => id !== overrideId);
  week.positions = week.positions.filter((id) => id !== overrideId.toString());
};
