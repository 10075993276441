import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Icons } from "../../plate-ui/Icons/icons";
import {
  PlateElement,
  TElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
  withRef,
} from "@udecode/plate-common";
import { ClickAwayListener } from "@mui/material";
import CheckInQuestions from "../components/CheckInQuestions";
import AnimateHeight, { Height } from "react-animate-height";
import { ReactEditor, useSlate } from "slate-react";
import { SchemaElements } from "../utils/withSchema";
import {
  TURN_INTO_CHECK_IN_MENU_ITEM_DEFAULT,
  TURN_INTO_CHECK_IN_MENU_ITEM_GROUP,
} from "../utils/menuItemTurnIntoGroups";
import { CheckInTypes } from "../utils/menuItemUtil";
import useAutoFocusWithTimeout from "../other/useAutoFocusWithTimeout";
import { TurnIntoDropdownMenu } from "../../plate-ui/turn-into/turn-into-dropdown-menu";
import { Toolbar } from "../../plate-ui/toolbar";
import { BorderedBox } from "./common/BorderedBox";
import { EditResponseToolbar } from "../components/toolbars/EditResponseToolbar";
import { useOnAnswerUpdate } from "../../../hooks/useOnAnswerUpdate";
import MinimizedTooltip from "../../tooltip/MinimizedTooltip";
import AnimateHeightBox from "./common/AnimateHeightBox";
import CheckInTextfield from "../components/checkInQuestions/CheckInTextfield";
import { useCheckInComponent } from "../hooks";
import {
  CheckInComponentStatus,
  EditorElementView,
} from "../utils/editorUtils";

// Element types that require extra padding to fit controls in the editor
const extraPaddingRequiredTypes = [
  CheckInTypes.MULTIPLE_CHECKBOX,
  CheckInTypes.MULTIPLE_CHOICE,
  CheckInTypes.YES_NO,
];

interface ICheckInProps {
  element: TElement;
  changesPending?: boolean;
  initCheckInType: CheckInTypes;
  handleDeleteElement?: () => void;
  handleSetNode: (dataToSet: object, debounce?: boolean) => void;
  initIsRequired?: boolean;
  initQuestion?: string;
  initDescription?: string;
  initHasDescription?: boolean;
}

const CheckIn = ({
  element,
  changesPending,
  initCheckInType,
  handleDeleteElement,
  handleSetNode,
  initIsRequired,
  initDescription,
  initQuestion,
  initHasDescription,
}: ICheckInProps) => {
  const turnIntoMenuItems = TURN_INTO_CHECK_IN_MENU_ITEM_GROUP.map(
    (g) => g.items,
  ).flat();
  const type = initCheckInType;
  const selectedMenuItem =
    turnIntoMenuItems.find((item) => item.value === type) ??
    TURN_INTO_CHECK_IN_MENU_ITEM_DEFAULT;

  const open = (element.open as boolean) || false;

  const isRequired = initIsRequired ?? false;
  const [question, setQuestion] = React.useState(initQuestion ?? "");
  const description = initDescription ?? "";
  const hasDescription = initHasDescription ?? !!description;

  const [forceSelected, setForceSelected] = React.useState(!question);

  const editor = useSlate();
  const { status, view, editingEl, setEditingEl } = useCheckInComponent();

  const onAnswerUpdate = useOnAnswerUpdate();

  const editable = view === EditorElementView.Coach;
  const elementId = element.id;

  const handleForceSelect = React.useCallback(() => setForceSelected(true), []);

  const isElementSelected = SchemaElements.isElementSelected(
    editor as ReactEditor,
    elementId,
  );

  const isSelected = forceSelected || isElementSelected;

  useEffect(() => {
    if (isElementSelected) {
      setForceSelected(true);
    }
  }, [isElementSelected]);

  const isEditing =
    editable &&
    (isSelected || !question || (hasDescription && !description) || open);

  const handleForceDeselect = React.useCallback(() => {
    setForceSelected(false);
    SchemaElements.updateElement(editor as ReactEditor, elementId, {
      open: false,
    });
  }, [editor, elementId]);

  const questionInputRef = useAutoFocusWithTimeout();

  const handleTypeChange = React.useCallback(
    (type: string) => {
      handleSetNode({ type: type as CheckInTypes, answer: null });
    },
    [handleSetNode],
  );

  const handleHasDescriptionChange = React.useCallback(
    (value) => {
      handleSetNode({ hasDescription: value });
    },
    [handleSetNode],
  );

  const handleDescriptionChange = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleSetNode({ description: e.target.value }, true);
    },
    [handleSetNode],
  );

  const handleRequiredChange = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleSetNode({ required: e.target.checked });
    },
    [handleSetNode],
  );

  const handleQuestionChange = React.useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setQuestion(e.target.value);
      handleSetNode({ question: e.target.value }, true);
    },
    [handleSetNode],
  );

  const handleEdit = React.useCallback(() => {
    setEditingEl(element);
  }, [element, setEditingEl]);

  const handleEditCancel = React.useCallback(() => {
    handleSetNode({ answer: editingEl.answer });
    setEditingEl(null);
  }, [editingEl, handleSetNode, setEditingEl]);

  const handleAnswerUpdate = React.useCallback(() => {
    onAnswerUpdate();
    setEditingEl(null);
  }, [onAnswerUpdate, setEditingEl]);

  const theme = useTheme();

  const editorRef = useEditorRef();

  const readOnly = React.useMemo(() => {
    switch (view) {
      case EditorElementView.Preview:
      case EditorElementView.Review:
        return true;
      default:
        return false;
    }
  }, [view]);

  return (
    <>
      <BorderedBox border={editable}>
        <ClickAwayListener onClickAway={handleForceDeselect}>
          <Box
            contentEditable={false}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,

              ...(readOnly && {
                pointerEvents: "none",
              }),
            }}
          >
            {isEditing ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 1.5,
                }}
              >
                <TextField
                  label="Question"
                  placeholder="Type question here..."
                  fullWidth
                  multiline
                  value={question}
                  onChange={handleQuestionChange}
                  inputRef={
                    editorRef.history.undos.length !== 0 && questionInputRef
                  }
                  {...(!hasDescription && { sx: { mb: 3.75 } })}
                />
              </Box>
            ) : (
              <Box>
                <Typography
                  sx={{ cursor: "pointer" }}
                  variant="h6"
                  onClick={handleForceSelect}
                >
                  {question}
                </Typography>
                {hasDescription && description && (
                  <Typography
                    sx={{ cursor: "pointer" }}
                    variant="body1"
                    onClick={handleForceSelect}
                  >
                    {description}
                  </Typography>
                )}
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Collapse in={hasDescription && isEditing}>
                <CheckInTextfield
                  label="Description"
                  placeholder="Type description here..."
                  fullWidth
                  multiline
                  value={description}
                  onChange={handleDescriptionChange}
                  sx={{ mt: 1, mb: 5 }}
                />
              </Collapse>

              <AnimateHeightBox
                animate={view === EditorElementView.Coach}
                duration={700}
              >
                <Box
                  {...(extraPaddingRequiredTypes.includes(type) && {
                    paddingX: 1.5,
                  })}
                >
                  <CheckInQuestions
                    initType={type}
                    element={element}
                    handleSetNode={handleSetNode}
                    view={view}
                    disableAnswer={
                      status === CheckInComponentStatus.Submitted &&
                      editingEl?.id !== elementId
                    }
                  />
                </Box>
              </AnimateHeightBox>
            </Box>

            {editable && (
              <>
                <Divider />

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    gap: 1.5,
                    mt: 1,
                  }}
                >
                  {element.type !== CheckInTypes.NUTRITION_TARGET && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isRequired}
                          onChange={handleRequiredChange}
                        />
                      }
                      label="Required"
                      sx={{
                        userSelect: "none",
                        "& .MuiFormControlLabel-label": {
                          fontWeight: 500,
                        },
                      }}
                    />
                  )}

                  <Box
                    sx={{
                      marginLeft: "auto",
                    }}
                  >
                    <Toolbar className="border-none">
                      <TurnIntoDropdownMenu
                        menuItemGroups={TURN_INTO_CHECK_IN_MENU_ITEM_GROUP}
                        selectedMenuItem={selectedMenuItem}
                        onTypeChange={handleTypeChange}
                        label="Turn check-in into"
                      />
                      <Divider
                        orientation="vertical"
                        sx={{ height: "24px", marginX: 1 }}
                      />
                      <MinimizedTooltip
                        title={
                          hasDescription
                            ? "Hide description"
                            : "Add description"
                        }
                      >
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleHasDescriptionChange(!hasDescription);
                            if (hasDescription) setForceSelected(false);
                          }}
                        >
                          {hasDescription ? (
                            <Icons.messageDashed
                              color={theme.palette.text.primary}
                            />
                          ) : (
                            <Icons.messagePlus
                              color={theme.palette.text.primary}
                            />
                          )}
                        </IconButton>
                      </MinimizedTooltip>
                      <IconButton
                        disabled={!handleDeleteElement}
                        onClick={handleDeleteElement}
                      >
                        <Icons.bin color={theme.palette.text.primary} />
                      </IconButton>
                    </Toolbar>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </ClickAwayListener>
      </BorderedBox>
      {status === CheckInComponentStatus.Submitted &&
        element.type !== CheckInTypes.NUTRITION_TARGET && (
          <EditResponseToolbar
            edit={editingEl?.id === elementId}
            anyEditing={!!editingEl}
            setEdit={handleEdit}
            onCancel={handleEditCancel}
            onUpdateAnswer={handleAnswerUpdate}
            disableSave={changesPending}
          />
        )}
    </>
  );
};

export default CheckIn;

interface ICheckInElementProps {
  type: CheckInTypes;
}

export const CHECKIN_INPUT_SAVE_DELAY = 300;

export const CheckInElement = withRef<
  typeof PlateElement,
  ICheckInElementProps
>(({ type, ...props }, ref) => {
  const { children, element } = props;
  const [elementData, setElementData] = useState(element);
  const [pending, setPending] = useState(false);
  const editor = useEditorRef();
  const path = findNodePath(editor, element);
  if (!path) return;

  const handleRemoveNode = () => {
    removeNodes(editor, { at: path });
  };

  const handleSetNode = (dataToSet: object, debounce?: boolean) => {
    setElementData((prev) => ({ ...prev, ...dataToSet }));
    if (debounce) {
      setPending(true);
    } else {
      setNodes(editor, dataToSet, { at: path });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Debounce the setNodes call if pending is true
      // Pending is set only when `handleSetNode` is called with debounce property
      if (pending) {
        setNodes(editor, elementData, { at: path });
        setPending(false);
      }
    }, CHECKIN_INPUT_SAVE_DELAY);
    return () => clearInterval(interval);
  }, [elementData, pending]);

  return (
    <PlateElement ref={ref} {...props} style={{ paddingBlock: "0.75rem" }}>
      <Box sx={{ visibility: "hidden", height: 0 }} contentEditable={false}>
        {children}
      </Box>
      <CheckIn
        element={elementData}
        changesPending={pending}
        handleSetNode={handleSetNode}
        handleDeleteElement={handleRemoveNode}
        initCheckInType={type}
        initIsRequired={elementData.required as boolean}
        initDescription={elementData.description as string}
        initQuestion={elementData.question as string}
        initHasDescription={elementData.hasDescription as boolean}
      />
    </PlateElement>
  );
});
