import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { getTrialEndsText } from "../../utils/text";
import { planStatus } from "../../constants";
import { useNavigate } from "react-router-dom";
import { COACH_PLAN_ROUTE } from "../../routes/routes";
import { useMediaMobile } from "../../hooks/useMediaMobile";

export const UPGRADE_BANNER_SPACING = 6.5;

const useStyles = makeStyles((theme) => ({
  "@global": {
    "#root": {
      marginTop: theme.spacing(UPGRADE_BANNER_SPACING),
    },
    "#app-drawer-minimize-icon-button": {
      marginTop: theme.spacing(UPGRADE_BANNER_SPACING),
    },
    ".MuiDrawer-docked > div": {
      paddingTop: theme.spacing(UPGRADE_BANNER_SPACING),
    },
    ".MuiAppBar-positionFixed": {
      marginTop: theme.spacing(UPGRADE_BANNER_SPACING),
    },
    '[data-role="messages-list-container"]': {
      top: `${theme.spacing(UPGRADE_BANNER_SPACING)} !important`,
    },

    ".mobile-toast .Toastify__toast": {
      top: `${theme.spacing(UPGRADE_BANNER_SPACING)} !important`,
    },
  },

  root: {
    backgroundColor: theme.palette.common.black,
    height: theme.spacing(UPGRADE_BANNER_SPACING),
    [theme.breakpoints.up("llg")]: {
      zIndex: 1201,
    },
    zIndex: 1199,
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
  },

  text: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "20px",
    color: theme.palette.common.white,
  },

  link: {
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

export interface UpgradeBannerProps extends BoxProps {
  trialEndsInDays: number;
  status?: string;
  setVisibleUpgradeBanner?: (value) => void;
}

export function UpgradeBanner(props: UpgradeBannerProps) {
  const {
    className,
    trialEndsInDays,
    setVisibleUpgradeBanner,
    status,
    ...other
  } = props;
  const s = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaMobile();

  const text = getTrialEndsText(trialEndsInDays);

  const renderPlansLink = (navigate, s) => (
    <span
      className={s.link}
      onClick={(e) => {
        e.stopPropagation();
        navigate(COACH_PLAN_ROUTE);
      }}
    >
      Plans
    </span>
  );

  return (
    <Box
      className={clsx(s.root, className)}
      {...other}
      onClick={() => setVisibleUpgradeBanner(false)}
    >
      {status === planStatus.TRIALING ? (
        <Typography className={s.text}>
          Your trial ends {text}. The new plan is activated after the trial
          period.
        </Typography>
      ) : (status === planStatus.PAST_DUE || status === planStatus.INCOMPLETE) ? (
        <Typography className={clsx(s.text)} style={{ whiteSpace: "pre-line" }}>
          {isMobile ? (
            <>
              Issues with subscription renewal. <br />
              Check {renderPlansLink(navigate, s)} page.
            </>
          ) : (
            <>
              There was an issue processing your subscription renewal. Check{" "}
              {renderPlansLink(navigate, s)} page for more details.
            </>
          )}
        </Typography>
      ) : (
        status !== planStatus.ACTIVE && (
          <Typography className={s.text}>
            To continue working with the platform, you need to buy a
            subscription
          </Typography>
        )
      )}
    </Box>
  );
}
