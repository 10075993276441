import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import IphoneFrame from "../../../src/assets/iphone-frame.jpeg";
import IpadFrame from "../../../src/assets/ipad-frame-vertical.png";
import { CheckInComponentContext } from "../new-editor/hooks";
import { EditorElementView } from "../new-editor/utils/editorUtils";
import CoachComponentPreview from "../program-component/CoachComponentPreview";
import { CurriculumComponent } from "../../redux/types";

export enum DeviceForPreview {
  IPAD = "ipad",
  IPHONE = "iphone",
}

interface IIphonePreviewProps {
  device: DeviceForPreview;
  minWidth?: number;
}

type ConditionalModeProps =
  | {
      mode: "component";
      component: CurriculumComponent;
      customContent?: string;

      // never
      children?: never;
      wrapperPosition?: never;
    }
  | {
      mode: "wrapper";
      children: ReactNode;
      wrapperPosition?: string;

      // never
      component?: never;
      customContent?: never;
    };

const DevicePreview = (props: IIphonePreviewProps & ConditionalModeProps) => {
  const {
    component,
    customContent,
    minWidth,
    mode,
    children,
    device,
    wrapperPosition,
  } = props;

  const IS_IPHONE = device === DeviceForPreview.IPHONE;
  const IS_IPAD = device === DeviceForPreview.IPAD;

  const MIN_WIDTH = minWidth ?? { xs: 150, sm: 275, md: 300 };

  return (
    <Box
      id="preview-container"
      position={"relative"}
      width={"fit-content"}
      margin={"auto"}
    >
      <Box
        id="device-frame"
        component="img"
        src={IS_IPHONE ? IphoneFrame : IpadFrame}
        sx={{
          objectFit: "contain",

          minWidth: MIN_WIDTH,
          minHeight: 400,
          maxHeight: 600,
        }}
        alt="Device Frame"
      />

      <Box
        id="content-container"
        position={"absolute"}
        sx={{
          minWidth: MIN_WIDTH,

          ...(IS_IPHONE && {
            // Background frame dimensions
            aspectRatio: 716 / 1420,

            // Scale
            scale: "0.6",
            width: `calc(100% * 1.6 + 8px)`, // 100% * scale(160%) + magic number
            top: `calc(100% / -3.2)`, // 100% / scale(-320%)
            left: `calc(100% / -3.2)`, // 100% / scale(-320%)
          }),

          ...(IS_IPAD && {
            // Background frame dimensions
            aspectRatio: 4903 / 6602,

            // Scale
            scale: "0.6",
            width: `calc(100% * 1.6 + 8px)`, // 100% * scale(160%) + magic number
            top: `calc(100% / -3.2)`, // 100% / scale(-320%)
            left: `calc(100% / -3.2)`, // 100% / scale(-320%)
          }),
        }}
      >
        <Box
          id="frame-container"
          sx={{
            height: 1,

            ...(IS_IPHONE && {
              paddingInline: 3,
              paddingTop: 8,
              paddingBottom: 3,
            }),

            ...(IS_IPAD && {
              p: 4,
              pb: 3,
            }),
          }}
        >
          <Box
            id="content-wrapper"
            sx={{
              position: wrapperPosition,
              overflowY: "scroll",
              overflowX: "hidden",
              overscrollBehavior: "contain",
              scrollbarColor: "#0000003e transparent",
              height: 1,
              containerType: "inline-size",
              borderBottomLeftRadius: 56,
              borderBottomRightRadius: 56,
              paddingInline: 3,
            }}
          >
            {mode === "component" ? (
              <CheckInComponentContext.Provider
                value={{ view: EditorElementView.Preview }}
              >
                <CoachComponentPreview
                  component={component}
                  customContent={customContent}
                />
              </CheckInComponentContext.Provider>
            ) : (
              children
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DevicePreview;
