import React from "react";
import {
  Dialog,
  DialogProps,
  Box,
  Typography,
  DialogContent,
} from "@mui/material";
import { AppLogo } from "../../app/AppLogo";
import { useCurrentBrand } from "../../../hooks/useCurrentWorkspace";
import Lottie from "react-lottie";
import animationData from "../../../lotties/gears-loading.json";

export function MaintenanceModeDialog(props: DialogProps) {
  const { className, onClose, ...other } = props;
  const { appName, isBrandedAppPublished } = useCurrentBrand();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleCloseButtonClick = React.useCallback(() => {}, [onClose]);

  return (
    <Dialog
      onClose={handleCloseButtonClick}
      PaperProps={{ sx: { width: 1, maxWidth: 500 } }}
      componentsProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(3px)",
          },
        },
      }}
      {...other}
    >
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          padding: 3,
          paddingTop: 4,
        }}
      >
        {/* TODO_Logo: Refactor AppLogo width and height setting. STR-924 */}
        <AppLogo
          main={!isBrandedAppPublished}
          appStoreLogo={isBrandedAppPublished}
          full
          size={!isBrandedAppPublished ? 160 : 80}
          width={160}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            maxWidth: "300px",
            height: "auto",
            marginY: 3,
          }}
        >
          <Lottie options={defaultOptions} width={"100%"} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Hey there!
          <br /> The app is in the maintenance mode!
        </Typography>
        <Typography variant="body1" gutterBottom>
          We’re working to make {appName} even better! Please hold on to
          continue your fitness journey with the best experience.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
