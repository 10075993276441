import React, { ReactNode } from "react";
import { ButtonProps } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ToggleButton as MuiToggleButton } from "@mui/material";

export interface ToggleColorsButtonProps extends ButtonProps {
  value: string;
  icon: ReactNode;
  toggleFlag: boolean;
  onToggle: (value: boolean) => void;
  tooltipTitle: string;
  tooltipPlacement?: "left" | "right" | "top" | "bottom";
  className?: string;
}

export function ToggleButtonWithTooltip(props: ToggleColorsButtonProps) {
  const {
    value,
    icon,
    toggleFlag,
    onToggle,
    tooltipTitle,
    className,
    tooltipPlacement = "top",
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onToggle(!toggleFlag);
  };

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
      <span>
        <MuiToggleButton
          color="primary"
          value={value}
          selected={toggleFlag}
          onClick={handleClick}
          size={"small"}
          className={className}
        >
          {icon}
        </MuiToggleButton>
      </span>
    </Tooltip>
  );
}
