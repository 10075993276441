import clsx from "clsx";
import React from "react";
import { Menu, MenuProps, MenuItem, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MoreHoriz } from "@mui/icons-material";
import { useSwitchUser } from "../../hooks/useSwitchUser";
import { ConfirmActionDialog } from "../dialog/ConfirmActionDialog";

import {
  AdminUserInfoDto,
  UserRole,
} from "@growth-machine-llc/stridist-api-client";
import { useUpdateUserMutation } from "./mutations/useUpdateUserMutation";
import { useMutation } from "@tanstack/react-query";
import AdminService from "../../services/AdminService";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles(() => ({
  root: {},
}));

export interface UserMenuProps extends Omit<MenuProps, "open" | "onClose"> {
  user: AdminUserInfoDto;
}

export function UserMenu(props: UserMenuProps) {
  const { className, user, ...other } = props;
  const { mutate: updateUser } = useUpdateUserMutation();
  const SETUP_CLIENT_TOAST_ID = "setup-client-toast";
  const { mutate: setupSample, isPending: isPendingSetupSample } = useMutation({
    mutationFn: AdminService.sampleSetup,
    onMutate: () => {
      showToastAlert("loading", {
        toastId: SETUP_CLIENT_TOAST_ID,
        message: "Setting up sample client...",
      });
    },
    onSuccess: () => {
      showToastAlert("success", {
        message: "Successfully set up sample client",
      });
    },
    onError: (error) => {
      showToastAlert("error", {
        message: error.message,
      });
    },
    onSettled: () => {
      closeToastAlert(SETUP_CLIENT_TOAST_ID);
    },
  });

  const { showToastAlert, closeToastAlert } = useToastAlert();
  const { id: userId, admin } = user;
  const s = useStyles();
  const switchUser = useSwitchUser();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [addAsAdminDialogOpen, setAddAsAdminDialogOpen] = React.useState(false);
  const [setupSampleDialogOpen, setSetupSampleDialogOpen] =
    React.useState(false);

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLoginAs = React.useCallback(() => {
    // TODO_API_V2_USER_ID: Temporary convert userId to string. Remove when after complete migrated
    switchUser(+userId);
  }, [switchUser, userId]);

  const updateUserAdmin = React.useCallback(
    (admin: boolean) => {
      updateUser({ id: user.id, admin });
    },
    [updateUser, user.id],
  );

  const handleUpdateAdmin = React.useCallback(() => {
    setAnchorEl(null);

    if (admin) {
      updateUserAdmin(false);
    } else {
      setAddAsAdminDialogOpen(true);
    }
  }, [admin, updateUserAdmin]);

  const handleConfirmAddAsAdmin = React.useCallback(() => {
    updateUserAdmin(true);
    setAddAsAdminDialogOpen(false);
  }, [updateUserAdmin]);

  const handleConfirmSetupSample = React.useCallback(() => {
    setupSample({ coachIds: [user.coachId] });
    setSetupSampleDialogOpen(false);
  }, []);

  const handleCloseAddAsAdmin = React.useCallback(() => {
    setAddAsAdminDialogOpen(false);
  }, []);

  const handleCloseSetupSample = React.useCallback(() => {
    setSetupSampleDialogOpen(false);
  }, []);

  return (
    <>
      <IconButton
        aria-describedby={userId.toString()}
        onClick={handleOpen}
        size="large"
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id={userId.toString()}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        className={clsx(s.root, className)}
        {...other}
      >
        <MenuItem onClick={handleLoginAs}>Login as User</MenuItem>
        {user.role === UserRole.COACH && (
          <MenuItem onClick={handleUpdateAdmin}>
            {user.admin ? "Remove as Admin" : "Add as Admin"}
          </MenuItem>
        )}
        {user.isSample && (
          <MenuItem onClick={() => setSetupSampleDialogOpen(true)}>
            Setup sample client
          </MenuItem>
        )}

        {/* <MenuItem onClick={() => null)}>
        Reset and Set Password
      </MenuItem> */}
      </Menu>

      <ConfirmActionDialog
        title="Are you sure you want to make this user an admin?"
        open={addAsAdminDialogOpen}
        onClose={handleCloseAddAsAdmin}
        onCancel={handleCloseAddAsAdmin}
        onConfirm={handleConfirmAddAsAdmin}
      />
      <ConfirmActionDialog
        title="Are you sure you want to setup sample client?"
        open={setupSampleDialogOpen}
        onClose={handleCloseSetupSample}
        onCancel={handleCloseSetupSample}
        onConfirm={handleConfirmSetupSample}
      />
    </>
  );
}
