import React, { useContext } from "react";
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogTitle,
  DialogProps,
  DialogTitleProps,
  DialogContent,
  DialogContentProps,
  Box,
  useTheme,
} from "@mui/material";
import { colorSystem } from "../../theme";
import { PlainTextButton } from "../button/PlainTextButton";
import GetAnyHelpModalContext from "../../contexts/GetAnyHelpModalContext";
import { LogOutIcon } from "lucide-react";
import { InfoItemName } from "../menu/GetAnyHelpModal";
import { useLogout } from "../../hooks/useLogout";

type Props = DialogProps & {
  title?: string;
  titleProps?: DialogTitleProps;
  titleImage?: React.ReactNode;
  actions?: React.ReactNode;
  actionsAlignment?: "start" | "end" | "center" | "space-between";
  children?: React.ReactNode;
  contentProps?: DialogContentProps;
  background?: string;
  showLogout?: boolean;
  showNeedHelp?: boolean;
};

const defaultPaddingX = 5;
const defaultPaddingY = 4;

const BlockingDialog = ({
  title,
  titleProps,
  actionsAlignment = "end",
  contentProps,
  children,
  background = colorSystem.blackOpacity,
  showLogout = true,
  showNeedHelp = true,
  onClose = () => {},
  ...props
}: Props) => {
  const { setGetAnyHelpModal: setHelpModalOpen } = useContext(
    GetAnyHelpModalContext,
  );

  const logout = useLogout({ immediate: true });

  const theme = useTheme();

  const handleDialogClose = (
    event,
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    if (reason === "backdropClick") return;

    onClose(event, reason);
  };

  const handleNeedHelpOpen = () => {
    setHelpModalOpen(true, [
      InfoItemName.EMAIL_SUPPORT,
      InfoItemName.ONBOARDING_CALL,
    ]);
  };

  return (
    <MuiDialog
      {...props}
      onClose={handleDialogClose}
      slotProps={{
        backdrop: {
          sx: {
            background,
          },
        },
      }}
    >
      <DialogTitle
        {...titleProps}
        sx={{
          paddingX: defaultPaddingX,
          paddingTop: defaultPaddingY,
          ...titleProps?.sx,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent
        {...contentProps}
        sx={{
          paddingX: defaultPaddingX,
          paddingTop: 0,
          ...contentProps?.sx,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: actionsAlignment,
          padding: 0,
          paddingX: defaultPaddingX,
          paddingBottom: defaultPaddingY,
        }}
      >
        {props.actions}
      </DialogActions>
      {(showLogout || showNeedHelp) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingX: 2,
            margin: theme.spacing(-2, 0, 1, 0),
            [theme.breakpoints.down("sm")]: {
              margin: (theme) => theme.spacing(-1, 0, 1, 0),
            },
          }}
        >
          <PlainTextButton
            color="text.secondary"
            fontSize={12}
            onClick={() => logout()}
            endIcon={<LogOutIcon size={12} />}
            sx={{ visibility: showLogout ? "visible" : "hidden" }}
          >
            Logout
          </PlainTextButton>
          <PlainTextButton
            color="text.secondary"
            fontSize={12}
            onClick={handleNeedHelpOpen}
            sx={{ visibility: showNeedHelp ? "visible" : "hidden" }}
          >
            Need help?
          </PlainTextButton>
        </Box>
      )}
    </MuiDialog>
  );
};

export default BlockingDialog;
