import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";

export const removeComponentSplitMarksAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<null>,
) => {
  state.value.components = Object.fromEntries(
    Object.entries(state.value.components).map(([key, component]) => [
      key,
      { ...component, isRecentSplitResult: undefined },
    ]),
  );
};