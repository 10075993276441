import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CardProgramTemplateBlank } from "../card/CardProgramTemplateBlank";
import { CardProgramTemplate } from "../card/CardProgramTemplate";

import { ProgramTemplateDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 316px)",
    justifyContent: "center",
    margin: theme.spacing(5, 0),
    gap: theme.spacing(4),
  },

  card: {
    width: 316,
    height: 374,
    flexShrink: 0,
  },
}));

export interface ProgramTemplatesListProps extends BoxProps {
  templates: ProgramTemplateDto[];
}

export function ProgramTemplatesList(props: ProgramTemplatesListProps) {
  const { className, templates, ...other } = props;
  const s = useStyles();

  return (
    <Box className={clsx(s.root, className)} {...other}>
      <CardProgramTemplateBlank className={s.card} />

      {templates.map((template) => (
        <CardProgramTemplate
          key={template.id}
          template={template}
          className={s.card}
        />
      ))}
    </Box>
  );
}
