import React from "react";
import { withRef } from "@udecode/cn";
import {
  PlateElement,
  TElement,
  findNodePath,
  removeNodes,
  setNodes,
  useEditorRef,
} from "@udecode/plate-common";
import { Box, Fade } from "@mui/material";
import { EmbedElementType, MoreMenu } from "../MoreMenu";
import { LinkPreviewVariant, UrlMetadata } from "../../types/links";
import { EmbedUrlDialog } from "../../modals/EmbedUrlDialog";
import { EmptyWebBookmark } from "./EmptyWebBookmark";
import { LoadingWebBookmark } from "./LoadingWebBookmark";
import { PreviewLink } from "./preview-link/PreviewLink";
import { useReadOnly } from "../../hooks";
import UrlService from "../../../../services/UrlService";
import { useMutation } from "@tanstack/react-query";

export const WEB_BOOKMARK = "preview_link";

interface IMediaEmbedProps {
  element: TElement;
  children;
  handleSetNode: (dataToSet: object) => void;
  handleChange: (url: string) => void;
  handleRemoveNode: () => void;
}

export const WebBookmark = ({
  element,
  handleSetNode,
  handleChange,
  handleRemoveNode,
}: IMediaEmbedProps) => {
  const { mutate: getUrlMetadata, isPending: isUrlMetadataFetching } =
    useMutation({
      mutationKey: ["url-metadata"],
      mutationFn: UrlService.getUrlMetadata,
    });

  const metadata = element.metadata as UrlMetadata;

  const fetched = metadata && metadata.url;
  const url = (fetched ? metadata.url : element.url) as string;
  const empty = !url;

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  const readOnly = useReadOnly();

  const handleClick = () => {
    if (!readOnly && !openDialog && empty) {
      setOpenDialog(true);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  if (url && !fetched && !isUrlMetadataFetching) {
    getUrlMetadata(url, {
      onSuccess: (data) => {
        handleSetNode({
          url: url,
          metadata: {
            ...metadata,
            ...data,
            variant: metadata.variant,
          },
        });
      },
    });
  }

  const handleSetVariant = (variant: LinkPreviewVariant) => {
    handleSetNode({
      url: url,
      metadata: {
        ...metadata,
        variant,
      },
    });
  };

  const getVariantIndex = (variant: LinkPreviewVariant) => {
    switch (variant) {
      case LinkPreviewVariant.INLINE:
        return 0;
      case LinkPreviewVariant.TEXT:
        return 1;
      case LinkPreviewVariant.RICH:
        return 2;
      default:
        break;
    }
  };

  if (empty && readOnly) return <></>;

  return (
    <Box
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        display: "flex",
        gap: 1.5,
        border: empty ? "1px solid" : "none",
        borderRadius: 2,
        padding: empty ? 2.5 : 0,
        pt: 1.4,
        pr: 0,

        backgroundColor: (theme) =>
          empty || isUrlMetadataFetching ? theme.palette.selected.light : null,

        borderColor: (theme) => theme.palette.border.primary,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "baseline",
          height: "100%",
          width: "100%",
        }}
        contentEditable={false}
      >
        {empty ? (
          <EmptyWebBookmark />
        ) : isUrlMetadataFetching ? (
          <LoadingWebBookmark url={url} />
        ) : (
          <PreviewLink metadata={metadata} url={url} />
        )}
        {!readOnly && (
          <>
            <Fade in={isHovered}>
              <Box>
                <MoreMenu
                  handleRemoveNode={handleRemoveNode}
                  empty={empty}
                  setOpenDialog={setOpenDialog}
                  type={EmbedElementType.BOOKMARK}
                  setType={handleSetVariant}
                  activeIndex={getVariantIndex(
                    (element.metadata as any).variant,
                  )}
                ></MoreMenu>
              </Box>
            </Fade>
            <EmbedUrlDialog
              url={url}
              title="Create a web bookmark"
              open={openDialog}
              onSubmit={handleChange}
              onClose={handleClose}
            ></EmbedUrlDialog>
          </>
        )}
      </Box>
    </Box>
  );
};

export const WebBookmarkElement = withRef<typeof PlateElement>(
  ({ ...props }, ref) => {
    const { children, element } = props;
    const editor = useEditorRef();
    const path = findNodePath(editor, element);
    if (!path) return;

    const handleSetNode = (dataToSet: object) => {
      setNodes(editor, dataToSet, { at: path });
    };

    const handleUrlChange = React.useCallback((url: string) => {
      const variant = element.metadata
        ? (element.metadata as UrlMetadata).variant
        : LinkPreviewVariant.RICH;

      setNodes(
        editor,
        {
          url: url,
          metadata: { variant },
        },
        { at: path },
      );
    }, []);

    const handleRemoveNode = () => {
      removeNodes(editor, { at: path });
    };

    return (
      <PlateElement ref={ref} {...props} style={{ paddingBlock: ".25rem" }}>
        <Box sx={{ visibility: "hidden", height: 0 }} contentEditable={false}>
          {children}
        </Box>
        <WebBookmark
          element={
            element.metadata
              ? element
              : { ...element, metadata: { variant: LinkPreviewVariant.RICH } }
          }
          children={children}
          handleSetNode={handleSetNode}
          handleChange={handleUrlChange}
          handleRemoveNode={handleRemoveNode}
        />
      </PlateElement>
    );
  },
);
