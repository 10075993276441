import React from "react";
import { Add } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { COACH_CLIENTS_FORM_ROUTE } from "../../routes/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ClientFormsCoachService from "../../services/ClientFormsCoachService";
import { CLIENT_FORMS_LIST_QUERY_KEY } from "./CoachClientsFormsListScreen";
import { useOptimisticUpdateMutation } from "../../hooks/useOptimisticUpdateMutation";
import {
  ClientFormCoachListDto,
  PaginatedListOfClientFormCoachListDto,
} from "@growth-machine-llc/stridist-api-client";
import dayjs from "dayjs";
import { HeaderButton } from "../button/HeaderButton";
import { ButtonProps } from "@mui/material";

export interface CreateClientFormActionProps extends ButtonProps {
  useCustomColorStyles?: boolean;
}

export function CreateClientFormAction(props: CreateClientFormActionProps) {
  const { useCustomColorStyles = true } = props;
  const queryClient = useQueryClient();
  const { mutate: createForm, isPending: createFormInFlight } =
    useOptimisticUpdateMutation({
      queryKey: [CLIENT_FORMS_LIST_QUERY_KEY],
      mutationFn: ClientFormsCoachService.createForm,
      optimisticUpdater: {
        updateFn: (oldData: PaginatedListOfClientFormCoachListDto, _, id) => {
          oldData.items.unshift(
            ClientFormCoachListDto.fromJS({
              id,
              name: null,
              created: dayjs(),
              totalQuestions: 0,
            }),
          );
          return oldData;
        },
      },
      options: {
        onSuccess: (form, _, context: any) => {
          queryClient.setQueriesData(
            { queryKey: [CLIENT_FORMS_LIST_QUERY_KEY] },
            (oldData: PaginatedListOfClientFormCoachListDto) => {
              const item = oldData.items.find(
                (item) => item.id === context.tempId,
              );
              item.id = form.id;
              item.slug = form.slug;
              return oldData;
            },
          );
          navigate(COACH_CLIENTS_FORM_ROUTE.replace(":slug", form.slug));
        },
      },
    });

  const navigate = useNavigate();

  const handleClick = React.useCallback(
    () => createForm({}),

    [createForm],
  );

  return (
    <HeaderButton
      startIcon={<Add />}
      children="Add a form"
      disabled={createFormInFlight}
      useCustomColorStyles={useCustomColorStyles}
      onClick={handleClick}
      {...props}
    />
  );
}
