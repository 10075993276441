import { Box, Menu, Theme, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { TElement } from "@udecode/plate-common";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import { ICheckInQuestionPropsBase } from "../CheckInQuestions";
import { EditorElementView } from "../../utils/editorUtils";
import { EmojiRating, INIT_EMOJI_VALUES } from "../../../fields/EmojiRating";

type IEmojiQuestionAnswer = {
  emoji: string;
};

const EmojiQuestion = ({
  element,
  handleSetNode,
  view,
  disableAnswer,
}: ICheckInQuestionPropsBase<IEmojiQuestionAnswer>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const editable = view === EditorElementView.Coach;

  const answer = element.answer as IEmojiQuestionAnswer;

  const selected = answer?.emoji;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const emojiArr = (element.emojiArr as string[]) ?? INIT_EMOJI_VALUES;

  const handleAddEmoji = (newEmoji: string) => {
    const newEmojiArr = emojiArr.includes(newEmoji)
      ? emojiArr
      : [...emojiArr, newEmoji];
    handleSetNode({ emojiArr: newEmojiArr });
  };

  const handleEmojiSelect = (index: number) => {
    handleSetNode({ answer: { emoji: emojiArr[index] } });
  };

  const handleEmojiDelete = (index: number) => {
    handleSetNode({
      emojiArr: [...emojiArr.slice(0, index), ...emojiArr.slice(index + 1)],
    });
  };

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <EmojiRating
          value={selected}
          options={emojiArr}
          onSelect={editable ? undefined : handleEmojiSelect}
          onDelete={editable ? handleEmojiDelete : undefined}
          editable={editable}
          disabled={disableAnswer}
        />

        {editable && (
          <Box
            onClick={handleClick}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              maxWidth: 35,
              position: "relative",
              border: "1px solid",
              borderStyle: "dashed",
              ml: "auto",
              aspectRatio: 1,
              borderRadius: "100%",
              color: theme.palette.grey[600],

              ":hover": {
                cursor: "pointer",
                color: theme.palette.grey[800],
              },
            }}
          >
            <AddIcon fontSize={"medium"} />
          </Box>
        )}
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          MenuListProps={{ sx: { p: 0 } }}
          sx={{ borderRadius: 3 }}
          slotProps={{ paper: { sx: { borderRadius: 3 } } }}
        >
          <Picker
            data={data}
            onEmojiSelect={(e) => {
              handleAddEmoji(e.native);
            }}
          />
        </Menu>
      </Box>
    </>
  );
};

export default EmojiQuestion;
