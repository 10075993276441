import React from "react";
import { Box } from "@mui/material";

interface InlineCounterProps {
  label: string;
  count?: number;
  loading?: boolean;
  brackets?: boolean;
}

const InlineCounter = ({
  label,
  count,
  loading,
  brackets = true,
}: InlineCounterProps) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        columnGap: 0.5,
      }}
    >
      {label}
      <Box
        sx={{
          visibility: loading ? "hidden" : "visible",
          minWidth: 20,
        }}
      >
        {brackets && "("}
        {/* Fallback to `0` to reserve space. Won't be visible if `loading` is true.*/}
        {count || 0}
        {brackets && ")"}
      </Box>
    </Box>
  );
};

export default InlineCounter;
