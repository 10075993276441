import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import {
  IUpdateAdminPublicExerciseAssetCommand,
  PaginatedListOfExerciseAssetDto,
} from "@growth-machine-llc/stridist-api-client";
import { ADMIN_EXERCISES_ASSETS_KEY_QUERY_KEY } from "../ExercisesTable";
import AdminService from "../../../services/AdminService";

export function useAdminUpdateExerciseLibraryAsset() {
  return useOptimisticUpdateMutation({
    queryKey: [ADMIN_EXERCISES_ASSETS_KEY_QUERY_KEY],
    mutationFn: AdminService.updateAdminPublicExerciseAsset,
    successToastMessage: "Update library asset",
    optimisticUpdater: {
      updateFn: (
        oldData: PaginatedListOfExerciseAssetDto,
        variables: IUpdateAdminPublicExerciseAssetCommand,
      ) => {
        const updatedItems = oldData.items.map((item) =>
          item.id === variables.id ? { ...item, ...variables } : item,
        );

        return {
          ...oldData,
          items: updatedItems,
        } as PaginatedListOfExerciseAssetDto;
      },
    },
  });
}
