import clsx from "clsx";
import React from "react";
import { BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CalendarComponentCard } from "../card/CalendarComponentCard";
import { ComponentForCalendarDto } from "@growth-machine-llc/stridist-api-client";
import { ComponentClickHandlerType } from "../program/ProgramDetails";
import { CurriculumComponent } from "../../redux/types";
import { useCurriculumSelector } from "../../redux/hooks";
import {
  selectComponentById,
  selectComponentOverrides,
} from "../../redux/curriculum/selectors/curriculum";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: "100%",
    height: "100%",
    boxSizing: "content-box",
  },
}));

export interface ProgramCalendarComponentProps {
  component: CurriculumComponent;
  onCardBodyClick: ComponentClickHandlerType;
  onCardBodyDoubleClick: ComponentClickHandlerType;
  activeComponentSlug?: string;
  isOverride?: boolean;
  dayIndex: number;
  weekNumber: number;
}

export function ProgramCalendarComponent(props: ProgramCalendarComponentProps) {
  const {
    component,
    onCardBodyClick,
    onCardBodyDoubleClick,
    activeComponentSlug,
    isOverride,
    dayIndex,
    weekNumber,
  } = props;
  const s = useStyles();

  const COMPONENT_OVERRIDES = useCurriculumSelector(
    selectComponentOverrides(component.id),
  );

  const COMPONENT_BASE_COMPONENT = useCurriculumSelector(
    selectComponentById(component.baseComponentId),
  );

  return (
    <CalendarComponentCard
      className={clsx(s.root)}
      // TODO Refactor hard coupling between activity and component calendars
      component={new ComponentForCalendarDto(component)}
      onCardBodyClick={() =>
        onCardBodyClick(component.slug, {
          week: weekNumber.toString(),
          day: (dayIndex + 1).toString(),
        })
      }
      onCardBodyDoubleClick={() => {
        onCardBodyDoubleClick(component.slug, {
          week: weekNumber.toString(),
          day: (dayIndex + 1).toString(),
        });
      }}
      isHighlighted={component.slug === activeComponentSlug}
      isHighlightedWithOverdrives={
        // isBaseComponentHighlighted
        COMPONENT_BASE_COMPONENT?.slug === activeComponentSlug ||
        // isOverrideComponentHighlighted
        !!COMPONENT_OVERRIDES.find(
          (override) => override.slug === activeComponentSlug,
        )
      }
      isOverride={isOverride}
    />
  );
}
