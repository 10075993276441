import {
  IReorderProgramTemplatesCommand,
  ProgramTemplateDto,
  ProgramTemplatesVm,
} from "@growth-machine-llc/stridist-api-client";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";

import { ADMIN_TEMPLATES_KEY_QUERY_KEY } from "../ProgramTemplatesPage";
import AdminService from "../../../services/AdminService";

export const useChangeProgramTemplatesOrdinalMutation = () => {
  return useOptimisticUpdateMutation({
    queryKey: [ADMIN_TEMPLATES_KEY_QUERY_KEY],
    mutationFn: AdminService.reorderProgramTemplates,
    optimisticUpdater: {
      updateFn: (
        store: ProgramTemplatesVm,
        variables: IReorderProgramTemplatesCommand,
      ) => {
        for (const id in variables.idsToOrdinals) {
          const programTemplate = store?.programTemplates?.find(
            (programTemplate) => programTemplate.id === parseInt(id),
          );
          if (programTemplate) {
            programTemplate.ordinal = variables.idsToOrdinals[id];
          }
        }

        store?.programTemplates?.sort((a, b) => a.ordinal - b.ordinal);
        return store;
      },
    },
  });
};
