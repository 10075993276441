import React from "react";
import { sortBy } from "lodash";

import { ComponentStatus, ComponentRepeat, ComponentType } from "../constants";
import { Filters } from "../components/program/filters/ComponentTypeFilter";
import {
  componentComparator,
  canScheduleComponentAtWeek,
  generateWeekDaysMap,
} from "../components/program-calendar/utils";
import { useDeepCompareMemoize } from "../utils/memo";
import { parseComponentDays } from "../utils/component";
import { CurriculumComponent, CurriculumProgram } from "../redux/types";

export type ProgramWeekScheduleDaysForWeekMapType = Map<number, boolean[]>;

export type ProgramWeekSchedule = Array<{
  weeks: number[];
  days: boolean[];
  component: CurriculumComponent;
  daysForWeeksMap: ProgramWeekScheduleDaysForWeekMapType;
}>;

export type ScheduleUpdate = {
  id: number;
  weekId: number;
  days: boolean[];
  originDay: number;
  originWeek: number;
};

export function useProgramSchedule(
  program: CurriculumProgram,
  filters: Filters,
  scheduleUpdate?: ScheduleUpdate,
) {
  const memoWeeks = useDeepCompareMemoize(program.weeks);

  const components: CurriculumComponent[] = React.useMemo(() => {
    const acc = [];

    for (const week of program.weeks) {
      const _components = week.components
        .filter(Boolean)
        .filter(({ type }) => !filters || filters[type])
        .filter(
          ({ status }) =>
            status !== ComponentStatus.ARCHIVED ||
            (filters && filters.archived),
        );
      const components = sortBy(_components, ({ id }) => {
        const index = week.positions.indexOf(String(id));

        return index === -1 ? Date.now() : index;
      });

      for (const component of components) {
        if (!acc.some((it) => it.id === component.id)) {
          acc.push(component);
        }
      }
    }

    return sortBy(acc, componentComparator);
  }, [filters, memoWeeks]);

  const schedule = React.useMemo(
    () =>
      components.map((component) => {
        const { type, duration, repeat, overrides: overridesIdArr } = component;

        const update = scheduleUpdate?.id === component.id && scheduleUpdate;
        const weekId = update?.weekId || component.weekId;
        const days = update?.days || (JSON.parse(component.days) as boolean[]);
        const startingWeek = program.weeks.find(({ id }) => id === weekId);
        const startingWeekNumber = startingWeek ? startingWeek.weekNumber : 1;

        const weeks = program.weeks
          .map(({ weekNumber }) => weekNumber)
          .filter((weekNumber) =>
            canScheduleComponentAtWeek(
              startingWeekNumber,
              weekNumber,
              type as ComponentType,
              repeat as ComponentRepeat,
              duration,
            ),
          );

        const overrides = overridesIdArr?.map((overrideId) =>
          components.find((c) => c.id === overrideId),
        );

        const overridesAssets = overrides?.map((override) => {
          return {
            week: program.weeks.find((w) => w.id === override.weekId)
              .weekNumber,
            dayIndex: parseComponentDays(override.days).findIndex(
              (item) => item === true,
            ),
          };
        });

        const daysForWeeksMap = generateWeekDaysMap(
          days,
          weeks,
          overridesAssets,
        );

        return {
          weeks,
          days,
          component,
          daysForWeeksMap,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [components, memoWeeks, scheduleUpdate],
  );

  return [components, schedule] as const;
}
