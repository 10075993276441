import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { handleFulfilled } from "../../utils";
import { CurriculumState } from "../curriculum-slice";
import { CurriculumProgram } from "../../types";
import { normalizeCurriculum } from "../../normalize";
import { CurriculumDto } from "@growth-machine-llc/stridist-api-client";

export const setCurriculumAction = (state: Draft<CurriculumState>, action: PayloadAction<CurriculumDto>) => {
  state.value = normalizeCurriculum(action.payload);
  handleFulfilled(state);
};
