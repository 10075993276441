import clsx from "clsx";
import React from "react";
import { Box, Typography, TypographyProps } from "@mui/material";

export interface PlainTextButtonProps extends TypographyProps {
  color?: string;
  fontSize?: number;
  underline?: "none" | "hover" | "always";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  disabled?: boolean;
}

export function PlainTextButton(props: PlainTextButtonProps) {
  const {
    color = "primary.main",
    fontSize = 16,
    startIcon,
    endIcon,
    disabled,
    onClick,
    underline = "hover",
    ...other
  } = props;

  return (
    <Box
      onClick={disabled ? undefined : onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.75,
        color: disabled ? "text.disabled" : color,
        cursor: disabled ? "default" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
        ...other.sx,
      }}
    >
      {startIcon}
      <Typography
        {...other}
        sx={{
          color: "inherit",
          fontSize: `${fontSize}px !important`,
          textDecoration: underline === "always" ? "underline" : "none",

          "&:hover": {
            textDecoration: underline === "hover" ? "underline" : "none",
          },
        }}
      />
      {endIcon}
    </Box>
  );
}
