import { MutateFunction, useMutation } from "@tanstack/react-query";
import BillingsService from "../services/BillingsService";
import { Plan } from "@growth-machine-llc/stridist-api-client";
import {
  CONFIRM_SIGNUP_SUBSCRIPTION_QUERY,
  SignupCheckoutStatus,
} from "../constants";
import { useToastAlert } from "../components/app/ToastAlert/ToastAlertProvider";
import { stripeAction } from "../stripe";
import { useCallback } from "react";
import { useCoachIsImpersonating } from "./useCurrentUser";
import { COACH_PROGRAMS_PUBLISHED_ROUTE } from "../routes/routes";

interface CreateSignupCheckoutSessionParams {
  autoRedirect?: boolean;
}

export function useCreateSignupCheckoutSession({
  autoRedirect = true,
}: CreateSignupCheckoutSessionParams = {}) {
  const { showToastAlert } = useToastAlert();
  const isImpersonating = useCoachIsImpersonating();
  const {
    mutate,
    isPending: creatingSignupCheckoutSession,
    isSuccess,
    data: { sessionId } = {},
  } = useMutation({
    mutationKey: ["create-signup-checkout-session"],
    mutationFn: BillingsService.createSignupCheckoutSession,
    retry: 0,
    onSuccess: ({ sessionId }) => {
      return autoRedirect ? handleStripeAction(sessionId) : sessionId;
    },
  });
  const handleStripeAction = useCallback(
    (id) => {
      stripeAction(id, showToastAlert);
    },
    [showToastAlert],
  );

  const createSignupCheckoutSession = (args?: {
    onError?: (error: Error, variables: any, context: any) => void;
  }) => {
    if (isImpersonating) {
      showToastAlert("error", {
        message:
          "You cannot proceed with subscription confirmation while impersonating a user.",
      });
      return;
    }
    return mutate(
      {
        plan: Plan.UP_TO_50,
        successUrl: `${process.env.REACT_APP_CLIENT_URL}${COACH_PROGRAMS_PUBLISHED_ROUTE}?${CONFIRM_SIGNUP_SUBSCRIPTION_QUERY}=${SignupCheckoutStatus.SUCCEEDED}`,
        cancelUrl: `${process.env.REACT_APP_CLIENT_URL}${COACH_PROGRAMS_PUBLISHED_ROUTE}?${CONFIRM_SIGNUP_SUBSCRIPTION_QUERY}=${SignupCheckoutStatus.FAILED}`,
      },
      { ...(args ?? {}) },
    );
  };

  return {
    createSignupCheckoutSession,
    creatingSignupCheckoutSession,
    sessionCreated: isSuccess,
    sessionId,
  };
}
