import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  UseFormClearErrors,
} from "react-hook-form";
import { genderOptions } from "../../constants";
import { RadioGroupField, RadioGroupFieldProps } from "./RadioGroupField";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  error: {
    position: "absolute",
  },
  controlLabel: {
    width: "auto",
    margin: 0,
  },

  gender: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    margin: 0,
    "& .MuiFormControlLabel-root:nth-child(3)": {
      gridColumn: "span 2",
    },
    gap: theme.spacing(2),
  },
}));

interface GenderFormFields {
  gender?: string;
}

export interface GenderFieldProps<T = GenderFormFields>
  extends Omit<
    RadioGroupFieldProps<(typeof genderOptions)[0]>,
    "onChange" | "options" | "label" | "error"
  > {
  disabled?: boolean;
  error?: FieldError;
  control: Control;
  clearErrors: UseFormClearErrors<T>;
}

export function GenderField(props: GenderFieldProps) {
  const {
    className,
    disabled,
    value,
    error,
    name,
    clearErrors,
    control,
    ...other
  } = props;
  const s = useStyles();
  return (
    <Controller
      name="gender"
      control={control}
      render={({ field }) => (
        <RadioGroupField
          {...field}
          label="Gender"
          onChange={(e) => {
            if (error) {
              clearErrors("gender");
            }
            field.onChange(e);
          }}
          options={genderOptions}
          className={clsx(s.gender, className)}
          errorStyle={s.error}
          controlLabelStyle={s.controlLabel}
          disabled={disabled}
          hideErrorIcon={true}
          error={error ? error.message : null}
          {...other}
        />
      )}
    />
  );
}
