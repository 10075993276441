import {
  BodypartType,
  MovementPatternType,
} from "@growth-machine-llc/stridist-api-client";

export const BodypartTypeLabels: Record<BodypartType, string> = {
  [BodypartType.NONE]: "None",
  [BodypartType.BICEPS]: "Biceps",
  [BodypartType.CHEST]: "Chest",
  [BodypartType.CORE]: "Core",
  [BodypartType.FOREARMS]: "Forearms",
  [BodypartType.FULL_BODY]: "Full Body",
  [BodypartType.GLUTES]: "Glutes",
  [BodypartType.HAMSTRINGS]: "Hamstrings",
  [BodypartType.HIP_AND_GROIN]: "Hip and Groin",
  [BodypartType.LOWER_BACK]: "Lower Back",
  [BodypartType.LOWER_BODY]: "Lower Body",
  [BodypartType.LOWER_LEG]: "Lower Leg",
  [BodypartType.MID_BACK]: "Mid Back",
  [BodypartType.QUADS]: "Quads",
  [BodypartType.SHOULDERS]: "Shoulders",
  [BodypartType.TRICEPS]: "Triceps",
  [BodypartType.UPPER_BACK_AND_NECK]: "Upper Back and Neck",
  [BodypartType.UPPER_BODY]: "Upper Body",
};

export const MovementPatternTypeLabels: Record<MovementPatternType, string> = {
  [MovementPatternType.NONE]: "None",
  [MovementPatternType.CARRY_GAIT]: "Carry/Gait",
  [MovementPatternType.CORE_BRACING]: "Core Bracing",
  [MovementPatternType.CORE_FLEXION_EXTENSION]: "Core Flexion/Extension",
  [MovementPatternType.CORE_ROTATION]: "Core Rotation",
  [MovementPatternType.LOCOMOTION]: "Locomotion",
  [MovementPatternType.LOWER_BODY_HINGE]: "Lower Body Hinge",
  [MovementPatternType.LOWER_BODY_PUSH]: "Lower Body Push",
  [MovementPatternType.UPPER_BODY_HORIZONTAL_PULL]:
    "Upper Body Horizontal Pull",
  [MovementPatternType.UPPER_BODY_HORIZONTAL_PUSH]:
    "Upper Body Horizontal Push",
  [MovementPatternType.UPPER_BODY_VERTICAL_PULL]: "Upper Body Vertical Pull",
  [MovementPatternType.UPPER_BODY_VERTICAL_PUSH]: "Upper Body Vertical Push",
};
