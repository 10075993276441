import clsx from "clsx";
import React from "react";
import {
  Box,
  BoxProps,
  Grid2,
  Grid2Props,
  MenuItem,
  Select,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SearchField } from "../fields/SearchField";
import { ClientSort, clientSortOptions } from "../../constants";

import CoachClientsSortHeader from "./CoachClientsSortHeader";
import { TagDto } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  search: {
    backgroundColor: theme.palette.background.paper,
  },

  sort: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    fontWeight: 500,

    '& [aria-haspopup="listbox"]': {
      color: theme.palette.text.secondary,

      "&::before": {
        content: '"Sort by: "',
      },
    },
  },

  inputActive: {
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },

  sortOption: {
    fontWeight: 500,
  },

  noVisibleItem: {
    display: "none",
  },
}));

export interface CoachClientsFilterProps extends Grid2Props {
  query: string;
  sortKey: ClientSort;
  tagList: TagDto[];

  onChangeQuery?: (query: string) => void;
  onChangeSortKey?: (sortKey: ClientSort) => void;
  onChangeFilterTagId?: (tagId: number) => void;
  filterTag?: number;
  disabled: boolean;

  defaultValues: {
    query: string;
    sortKey: ClientSort;
  };
}

export function CoachClientsFilter(props: CoachClientsFilterProps) {
  const s = useStyles();
  const {
    className,
    query,
    sortKey,
    tagList,
    onChangeQuery,
    onChangeSortKey,
    onChangeFilterTagId,
    filterTag,
    disabled,
    defaultValues: { query: defaultQuery, sortKey: defaultSortKey },
    ...other
  } = props;

  const handleChangeQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const query = event.currentTarget.value;

      if (onChangeQuery) {
        onChangeQuery(query);
      }
    },
    [onChangeQuery],
  );

  const handleChangeSort = React.useCallback(
    (event) => {
      const sortKey = event.target.value as ClientSort;

      if (onChangeSortKey) {
        onChangeSortKey(sortKey);
      }
    },
    [onChangeSortKey],
  );

  const handleChangeSortNameProgram = React.useCallback(
    (sortByName) => {
      if (onChangeSortKey)
        onChangeSortKey(
          sortByName
            ? sortKey === ClientSort.NAME_ASC
              ? ClientSort.NAME_DESC
              : ClientSort.NAME_ASC
            : sortKey === ClientSort.PROGRAM_END_ASC
              ? ClientSort.PROGRAM_END_DESC
              : ClientSort.PROGRAM_END_ASC,
        );
    },
    [onChangeSortKey, sortKey],
  );

  const notVisibleSortOptions = [
    ClientSort.NAME_ASC,
    ClientSort.NAME_DESC,
    ClientSort.PROGRAM_END_ASC,
    ClientSort.PROGRAM_END_DESC,
  ];

  const itemClassName = React.useCallback(
    (key) => {
      if (notVisibleSortOptions.includes(key)) {
        return s.noVisibleItem;
      }

      return;
    },
    [s],
  );

  const valueSort = React.useMemo(() => {
    if (notVisibleSortOptions.includes(sortKey)) {
      return "";
    }

    return sortKey;
  }, [sortKey]);

  return (
    <Grid2 container className={clsx(s.root, className)} {...other}>
      <Grid2 size={5}>
        <SearchField
          className={clsx(s.search, query !== defaultQuery && s.inputActive)}
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Search clients..."
          value={query}
          onChange={handleChangeQuery}
        />
      </Grid2>

      <Grid2
        size={5}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Select
          className={clsx(
            s.sort,
            defaultSortKey !== sortKey &&
              !notVisibleSortOptions.includes(sortKey) &&
              s.inputActive,
          )}
          size="small"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          variant="outlined"
          fullWidth
          value={valueSort}
          onChange={handleChangeSort}
        >
          {Object.entries(clientSortOptions).map(([key, label]) => (
            <MenuItem
              className={clsx(s.sortOption, itemClassName(key))}
              key={key}
              value={key}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid2>
      <Grid2
        size={12}
        sx={{
          marginTop: 3,
        }}
      >
        <CoachClientsSortHeader
          sortKey={sortKey}
          handleChangeSortNameProgram={handleChangeSortNameProgram}
          tagList={tagList}
          onChangeFilterTagId={onChangeFilterTagId}
          filterTag={filterTag}
          disabled={disabled}
        />
      </Grid2>
    </Grid2>
  );
}
