import { Collapse, Box, useTheme } from "@mui/material";
import { ChevronDown } from "lucide-react";
import React from "react";

interface OneTimeExpandableProps {
  children: React.ReactNode;
  label?: string;
}

const OneTimeExpandable = ({
  children,
  label = "See more",
}: OneTimeExpandableProps) => {
  const [expand, setExpand] = React.useState(false);
  const [final, setFinal] = React.useState(false);
  const theme = useTheme();

  const handleExpand = () => {
    setExpand(true);
    setTimeout(() => {
      setFinal(true);
    }, theme.transitions.duration.complex);
  };

  return (
    <>
      <Box
        sx={{
          cursor: !final ? "pointer" : "default",
          display: "flex",
          flexDirection: "row",
          fontSize: "inherit",
          color: expand ? "transparent" : "inherit",
          transition: theme.transitions.create(["color"], {
            duration: theme.transitions.duration.complex,
          }),
        }}
        onClick={handleExpand}
      >
        {!final ? label : "|"}
        <Box
          sx={{
            marginLeft: 1,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChevronDown size={12} />
        </Box>
      </Box>
      <Collapse in={expand} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

export default OneTimeExpandable;
