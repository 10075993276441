import React, { MouseEventHandler } from "react";
import { BoxProps } from "@mui/material";
import { WeekCard, WeekCardProps } from "../program-week/WeekCard";
import { useProgramSchedule } from "../../hooks/useProgramSchedule";
import { NormalizedCurriculumProgram } from "../../redux/types";
import { denormalizeCurriculum } from "../../redux/normalize";
import { selectCurriculum } from "../../redux/curriculum/selectors/curriculum";
import { useCurriculumSelector } from "../../redux/hooks";
import { ComponentClickHandlerType } from "./ProgramDetails";
import WeeksGap from "../curriculum/WeeksGap";
import { ProgramDefaultView } from "../../constants";

export interface ProgramWeeksListProps extends BoxProps {
  program: NormalizedCurriculumProgram;
  filters: WeekCardProps["filters"];
  startDate: string;
  onOpenMenu?: WeekCardProps["onOpenMenu"];
  weeks: number[];
  weeksActions: {
    onClickDelete: MouseEventHandler<HTMLElement>;
    onClickDuplicate: MouseEventHandler<HTMLElement>;
  };
  componentClickHandler: ComponentClickHandlerType;
  componentDoubleClickHandler: ComponentClickHandlerType;
  weeksByNumber: number[];
}

export function ProgramWeeksList(props: ProgramWeeksListProps) {
  const {
    filters,
    startDate,
    onOpenMenu,
    weeks,
    weeksActions,
    componentClickHandler,
    componentDoubleClickHandler,
    weeksByNumber,
  } = props;
  const scheduleAssets = denormalizeCurriculum(
    useCurriculumSelector(selectCurriculum).value,
  );
  const [, schedule] = useProgramSchedule(scheduleAssets, filters);
  return weeks.map((week, i) => {
    const isGap = weeksByNumber[i] - weeksByNumber[i - 1] > 1;

    return (
      <>
        {isGap && (
          <WeeksGap
            startWeekNumber={weeksByNumber[i - 1] + 1}
            endWeekNumber={weeksByNumber[i] - 1}
            view={ProgramDefaultView.LIST}
          />
        )}
        <WeekCard
          key={week}
          weekId={week}
          initSchedule={schedule}
          filters={filters}
          startDate={startDate}
          onOpenMenu={onOpenMenu}
          weeksActions={weeksActions}
          componentClickHandler={componentClickHandler}
          componentDoubleClickHandler={componentDoubleClickHandler}
        />
      </>
    );
  });
}
