import React from "react";
import {
  Dialog,
  DialogProps,
  Box,
  Typography,
  Button,
  Stack,
  DialogContent,
  useTheme,
} from "@mui/material";
import { AppLogo } from "../../app/AppLogo";
import EmptyActivitiesArt from "../../../icons/EmptyActivitiesArt";
import GetItOnGooglePlay from "../../../icons/GetItOnGooglePlay";
import DownloadOnAppStore from "../../../icons/DownloadOnAppStore";
import { useCurrentBrand } from "../../../hooks/useCurrentWorkspace";
import { iOSMobileApp } from "../../../utils/mobile";
import { LocalStorage } from "ttl-localstorage";

export interface UpdateMobileAppDialogProps extends DialogProps {
  force?: boolean;
}

export function UpdateMobileAppDialog(props: UpdateMobileAppDialogProps) {
  const HIDE_UPDATE_MODAL_KEY = "HIDE_UPDATE_MODAL_KEY";
  const HIDE_UPDATE_MODAL_TIMEOUT = 86400; //24 hours in seconds

  const { className, onClose, force, ...other } = props;
  const { appName, AppleStoreUrl, GoogleStoreUrl, isBrandedAppPublished } =
    useCurrentBrand();
  const theme = useTheme();

  const handleCloseButtonClick = React.useCallback(
    (event) => {
      if (onClose && !force) {
        hideModal();
        onClose(event, "backdropClick");
      }
    },
    [onClose],
  );

  const hideModal = () => {
    LocalStorage.put(HIDE_UPDATE_MODAL_KEY, true, HIDE_UPDATE_MODAL_TIMEOUT);
  };

  return LocalStorage.get(HIDE_UPDATE_MODAL_KEY) && !force ? null : (
    <Dialog
      onClose={handleCloseButtonClick}
      PaperProps={{ sx: { width: 1, maxWidth: 500 } }}
      componentsProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(3px)",
          },
        },
      }}
      {...other}
    >
      <DialogContent
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          padding: 3,
          paddingTop: 4,
        }}
      >
        {/* TODO_Logo: Refactor AppLogo width and height setting. STR-924 */}
        <AppLogo
          main={!isBrandedAppPublished}
          appStoreLogo={isBrandedAppPublished}
          full
          size={!isBrandedAppPublished ? 160 : 80}
          width={160}
        />
        <Box
          sx={{
            width: "100%",
            maxWidth: "300px",
            height: "auto",
            marginY: 3,
          }}
        >
          <EmptyActivitiesArt fill={theme.palette.primary.main} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Hey there!
          <br /> New app version is ready!
        </Typography>
        <Typography variant="body1" gutterBottom>
          We’ve added exciting new features to {appName}! Please update to the
          latest version to continue your fitness journey with the best
          experience.
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "center",
            my: 2,
            maxWidth: "160px",
          }}
        >
          {iOSMobileApp ? (
            <DownloadOnAppStore href={AppleStoreUrl}></DownloadOnAppStore>
          ) : (
            <GetItOnGooglePlay href={GoogleStoreUrl}></GetItOnGooglePlay>
          )}
        </Stack>
        {!force && (
          <Button
            onClick={() => {
              hideModal();
              onClose({}, "escapeKeyDown");
            }}
            variant="text"
          >
            Not now
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
}
