import { z } from "zod";
import { setUnitsTypeOptions } from "../constants";

import { validatePhone } from "./user";

export const coachSettingsSchema = () => {
  return z.object({
    fullName: z.string().min(1, "Full name is required"),
    units: z.enum(
      setUnitsTypeOptions.map((option) => option.value) as [
        string,
        ...string[],
      ],
      { errorMap: () => ({ message: "Please select a unit" }) },
    ),
    timezone: z.string().min(1, "Please select a timezone"),
    phone: z
      .string()
      .nullable()
      .optional()
      .refine((val) => !val || val.length <= 5 || validatePhone(val), {
        message: "Please enter a valid phone number",
      }),
    phoneCode: z.string().nullable().optional(),
    picture: z.any(),
  });
};

export type CoachSettingsSchema = z.infer<
  ReturnType<typeof coachSettingsSchema>
>;
