import clsx from "clsx";
import React from "react";
import {
  Box,
  CardProps,
  Card,
  Typography,
  useTheme,
  Divider,
  Skeleton,
  Collapse,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colorSystem } from "../../theme";
import WorkoutSetTable from "../workout/WorkoutSetTable";
import WorkoutButton from "../workout/WorkoutButton";
import { ExerciseTypeSet } from "../../constants";
import { unitSetLabel, unitSetLabelCoach } from "../../utils/units";
import { useCurrentUser } from "../../hooks/useCurrentUser";

import { ReactComponent as BetterIcon } from "../../icons/BetterTriangle.svg";
import { ReactComponent as WorseIcon } from "../../icons/WorseTriangle.svg";
import { ReactComponent as SessionCountIcon } from "../../icons/SessionCount.svg";
import { ReactComponent as RepsCountIcon } from "../../icons/RepsCount.svg";
import { Sets, WorkoutSection } from "../workout/types";
import HistoryIcon from "../../icons/HistoryIcon";
import CloseIcon from "../../icons/CloseIcon";
import { parseWorkoutResultValueJsonToWorkoutSection } from "../workout/utils";
import WorkoutResultsService from "../../services/WorkoutResultsService";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../api/ReactQueryConfig";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => {
  return {
    root: {
      padding: spacing(3),
      marginBottom: spacing(2),
      overflow: "visible",
    },

    exerciseNameContainer: {
      marginBottom: spacing(1.5),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    exerciseName: {
      marginRight: spacing(1.5),
      marginBottom: spacing(0.5),
      fontFamily: "Montserrat, sans-serif",
    },

    recorded: {
      color: palette.text.secondary,
      fontSize: 16,
      fontWeight: 400,
    },

    recordedWrapper: {
      marginBottom: spacing(2),
    },

    exerciseContainer: {
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: palette.quote,
      paddingBottom: spacing(3.4),
      marginBottom: spacing(2.9),
    },

    history: {
      marginTop: 12,
    },

    triangleCircle: {
      width: 22.6,
      height: 22.6,
      borderRadius: "50%",
      backgroundColor: colorSystem.greenOpacity,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    triangleCircleWorse: {
      backgroundColor: colorSystem.primaryOpacity,
    },

    statsBottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    statItem: {
      display: "flex",
      alignItems: "center",
    },

    statText: {
      fontSize: 18,
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 500,
      marginLeft: spacing(1.3),
    },
    historyButton: {
      paddingRight: 0,
      display: "flex",
      alignItems: "center",
      borderRadius: spacing(0.5),
      height: spacing(4),
      "& svg": {
        width: spacing(2),
        height: spacing(2),
      },
    },
    historyButtonText: {
      fontSize: 18,
      fontWeight: 500,
      marginLeft: spacing(0.75),
    },
    header: {
      display: "flex",
      color: colorSystem.gray,
      fontWeight: 500,
      marginBottom: 12,
      marginTop: 12,
    },
  };
});

export interface TrainingSummaryExerciseProps extends CardProps {
  exercise: any;
  clientUsername: string;
}

export function TrainingSummaryExercise(props: TrainingSummaryExerciseProps) {
  const { className, exercise, clientUsername, ...other } = props;

  const s = useStyles();
  const user = useCurrentUser();
  const [bestResult, setBestResult] = React.useState(0);
  const [openHistory, setOpenHistory] = React.useState(false);
  const theme = useTheme();

  const { id, result, title } = exercise;

  const CLIENT_SUMMARY_EXERCISE_HISTORY_QUERY_KEY =
    "client-summary-exercise-history";

  const { data: workoutExerciseResultsData, isLoading } = useQuery({
    queryKey: [
      CLIENT_SUMMARY_EXERCISE_HISTORY_QUERY_KEY,
      { clientUsername, exerciseTitle: title },
    ],
    queryFn: () =>
      WorkoutResultsService.getUserExerciseHistory(
        null,
        null,
        title,
        clientUsername,
      ),
    ...REACT_QUERY_NO_CACHING_OPTIONS,
  });
  const workoutExerciseResults = workoutExerciseResultsData?.items.map(
    ({ value, ...dto }) => ({
      ...dto,
      section: parseWorkoutResultValueJsonToWorkoutSection(value),
    }),
  );

  const allSum = React.useMemo(() => {
    const sumWeight = result
      ? result
          .map((item) =>
            !item.weight || item.weight === "-"
              ? 0
              : item.weight * (+item.completedReps || +item.reps || 0),
          )
          .reduce((prev, curr) => +prev + +curr, 0)
      : 0;
    const sumReps = result
      ? result
          .map((item) =>
            !isNaN(item.completedReps)
              ? item.completedReps
              : !isNaN(item.reps)
                ? item.reps
                : 0,
          )
          .reduce((prev, curr) => +prev + +curr, 0)
      : 0;
    return { lbs: sumWeight, reps: sumReps };
  }, [result]);

  const resultSets = React.useMemo<
    Array<{ sets: Array<Sets>; completedAt: string }>
  >(() => {
    const results = [];
    workoutExerciseResults?.forEach((workoutExerciseResult) =>
      workoutExerciseResult.section.exercises.forEach((exercise, index) => {
        if (exercise.title === title && Array.isArray(exercise.result)) {
          const resultSets = exercise.result.map((item) => ({
            ...item,
            typeReps: exercise.typeReps,
            typeSet: exercise.typeSet,
            units: exercise.units,
          }));
          if (resultSets.length > 0) {
            results.push({
              sets: resultSets,
              completedAt: workoutExerciseResult.activity.completedAt,
            });
          }
        }
      }),
    );
    return results;
  }, [workoutExerciseResults, title]);

  // TODO_API_V2_EXERCISE_RESULT STR-1452: Improve loading of training summary data
  React.useEffect(() => {
    let bestResult = 0;
    resultSets.forEach(({ sets }) => {
      if (Array.isArray(sets)) {
        const summ = sets
          .map((i) => (i.checked ? Number(i.weight) ?? 0 : 0))
          .reduce((prev, curr) => +prev + +curr, 0);
        if (summ > bestResult) bestResult = summ;
      }
    });
    setBestResult(bestResult);
  }, [resultSets]);

  //TODO_API_V2_EXERCISE_RESULT: Investigate how to fix issue when сollapse is not working properly and show all sets at once after first loading.

  return (
    <Card className={clsx(s.root, className)} {...other}>
      <Box className={s.exerciseContainer}>
        <Box className={s.exerciseNameContainer}>
          <div>
            <Typography
              variant="h5"
              className={s.exerciseName}
              children={title}
            />
          </div>
          <WorkoutButton
            icon={<HistoryIcon fill={theme.palette.primary.main} />}
            text="History"
            style={s.historyButton}
            styleText={s.historyButtonText}
            onClick={() => setOpenHistory(!openHistory)}
            closeIcon={<CloseIcon fill={theme.palette.primary.main} />}
            isOpen={openHistory}
          />
        </Box>
        <Box className={s.recordedWrapper}>
          <span className={s.recorded}>
            {exercise.time && `Recorded ${exercise.time}`}
          </span>
        </Box>
        {isLoading && openHistory ? (
          <Skeleton variant="text" animation="wave" />
        ) : (
          <>
            {resultSets.length === 0 && !isLoading && openHistory && (
              <Typography>No results...</Typography>
            )}
            {resultSets.length > 0 && !isLoading && (
              <Collapse in={openHistory} timeout={"auto"} unmountOnExit>
                {resultSets.map(({ sets, completedAt }, index) => (
                  <React.Fragment key={index}>
                    <WorkoutSetTable
                      sets={sets}
                      history
                      exercise={exercise}
                      completedAt={completedAt}
                    />
                    {resultSets.length - 1 !== index && (
                      <Divider sx={{ marginBlock: 3 }} />
                    )}
                  </React.Fragment>
                ))}
              </Collapse>
            )}
          </>
        )}
      </Box>
      <Box className={s.statsBottom}>
        <Box className={s.statItem}>
          {isLoading ? (
            <SessionCountIcon />
          ) : (
            <div
              className={clsx(
                s.triangleCircle,
                bestResult > allSum.lbs && s.triangleCircleWorse,
              )}
            >
              {bestResult > allSum.lbs ? <WorseIcon /> : <BetterIcon />}
            </div>
          )}
          <span className={s.statText}>{`${Math.round(
            allSum.lbs,
          )} ${unitSetLabelCoach(
            exercise?.units || user.units,
            exercise?.typeSet || ExerciseTypeSet.WEIGHT,
            "s",
          )}`}</span>
        </Box>
        <Box className={s.statItem}>
          <RepsCountIcon />
          <span className={s.statText}>{`${Math.round(
            allSum.reps,
          )} reps`}</span>
        </Box>
        <Box className={s.statItem}>
          <SessionCountIcon />
          <span className={s.statText}>{`Sets ${
            result ? result.length : 0
          }`}</span>
        </Box>
      </Box>
    </Card>
  );
}
