import { call, put, select } from "redux-saga/effects";
import { selectCurriculum } from "./curriculum/selectors/curriculum";
import ProgramsService from "../services/ProgramsService";
import { CurriculumState } from "./curriculum/curriculum-slice";
import { CurriculumVm } from "@growth-machine-llc/stridist-api-client";
import { normalizeCurriculum } from "./normalize";
import { compareNormalizedCurriculum } from "./compare";
import { replaceCurriculumIdsWithRealIds } from "./utils";
import {
  DebugWarning,
  setLatestDebugWarnings,
  TempIdsMap,
} from "./api/api-slice";
import {
  selectComponentTempIdsMap,
  selectWeekTempIdsMap,
} from "./api/selectors";

export default function* debugWorker() {
  const { value: optimisticStateAsTempIdsUnchecked }: CurriculumState =
    yield select(selectCurriculum);
  const weeksIdMap: TempIdsMap = yield select(selectWeekTempIdsMap);
  const componentsIdMap: TempIdsMap = yield select(selectComponentTempIdsMap);
  const optimisticState = replaceCurriculumIdsWithRealIds(
    optimisticStateAsTempIdsUnchecked,
    weeksIdMap,
    componentsIdMap,
  );
  try {
    const { curriculum }: CurriculumVm = yield call(
      ProgramsService.getCurriculum,
      optimisticState.program.slug,
    );
    const apiState = normalizeCurriculum(curriculum);
    const diff = compareNormalizedCurriculum(optimisticState, apiState);
    const warnings = diff.map(
      (d) =>
        ({
          path: d.path,
          optimistic: d.valueA,
          actual: d.valueB,
        }) as DebugWarning,
    );
    if (warnings.length > 0) {
      const log = ["State inconsistencies detected: ", warnings];
      console.error(...log);
      console.log(...log);
    }
    yield put(setLatestDebugWarnings(warnings));
  } catch (error) {
    console.error(error);
    yield put(
      setLatestDebugWarnings([
        {
          path: "debug worker error",
          optimistic: "error",
          actual: error,
        } as DebugWarning,
      ]),
    );
  }
}
