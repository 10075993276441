import { billingAddressesClient, fbUsersClient } from "../api/ApiClients";
import {
  CreateBillingAddressCommand,
  CreateSubAccountForExistingUserCommand,
  ICreateBillingAddressCommand,
  ICreateSubAccountForExistingUserCommand,
  IResendGoHighLevelEmailCommand,
  IToggleStopSubAccountCommand,
  IUpdateBillingAddressCommand,
  ResendGoHighLevelEmailCommand,
  ToggleStopSubAccountCommand,
  UpdateBillingAddressCommand,
} from "@growth-machine-llc/stridist-api-client";

import ServiceBase from "./ServiceBase";

class BillingService extends ServiceBase {
  async updateBillingAddress(variables: IUpdateBillingAddressCommand) {
    const command = UpdateBillingAddressCommand.fromJS(variables);
    return billingAddressesClient.updateBillingAddress(command);
  }

  async createBillingAddress(variables: ICreateBillingAddressCommand) {
    const command = CreateBillingAddressCommand.fromJS(variables);
    return billingAddressesClient.createBillingAddress(command);
  }

  async createSubAccount(variables: ICreateSubAccountForExistingUserCommand) {
    const command = CreateSubAccountForExistingUserCommand.fromJS(variables);
    return billingAddressesClient.createSubAccountForExistingUser(command);
  }

  async resendGoHighLevelEmail(variables: IResendGoHighLevelEmailCommand) {
    const command = ResendGoHighLevelEmailCommand.fromJS(variables);
    return billingAddressesClient.resendGoHighLevelEmail(command);
  }

  async getMissingSubAccounts(emails: string[]) {
    return billingAddressesClient.getMissingSubAccounts(emails);
  }

  async toggleStopSubAccount(variables: IToggleStopSubAccountCommand) {
    const command = ToggleStopSubAccountCommand.fromJS(variables);
    return billingAddressesClient.toggleStopSubAccount(command);
  }

  async deleteSubAccount(id: number) {
    return billingAddressesClient.deleteSubAccount(id);
  }
}

export default new BillingService();
