import clsx from "clsx";
import React from "react";
import {
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  Box,
  Grid2,
  Button,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import type { SelectedPhoto } from "./ProgressDialog";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../hooks/useClient";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import {
  CLIENT_PROFILE_ROUTE,
  CLIENT_PROGRESS_ROUTE,
  COACH_CLIENT_OVERVIEW_ROUTE,
  COACH_CLIENT_PROGRESS_ROUTE,
} from "../../routes/routes";
import dayjs from "dayjs";
import { formatDateToLongString } from "../../utils/date";

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    margin: spacing(2, 1),
    height: "70%",
    maxHeight: "70%",
    aspectRatio: "5/7",
    width: "100%",
  },
  media: {
    height: "100%",
    width: "100%",
    aspectRatio: "5/7",
    borderRadius: spacing(1.5),
  },
  mediaHighlight: {
    position: "absolute",
    top: -7,
    right: -7,
    bottom: -7,
    left: -7,
    border: "2px solid",
    borderRadius: spacing(2),
    borderColor: palette.primary.main,
  },
  mediaWrapper: {
    borderRadius: 0,
    boxShadow: "none",
    position: "relative",
    overflow: "visible",
  },
  textContainer: {
    paddingTop: spacing(2),
  },
}));

export interface ProgressDialogCardProps {
  selectedPhoto: SelectedPhoto;
  title?: string;
  cell?: number;
  selected?: boolean;
  onClick?: any;
  className?: string;
  comparisonMode?: boolean;
  showWeight: boolean;
  timeAgoFormat: boolean;
}

export function ProgressDialogCard(props: ProgressDialogCardProps) {
  const {
    selectedPhoto: { url, takenOn, id, weight, weightUnit },
    title,
    cell,
    selected,
    onClick,
    className,
    showWeight,
    timeAgoFormat,
    comparisonMode = false,
  } = props;
  const s = useStyles(comparisonMode);
  const navigate = useNavigate();
  const client = useClient();
  const user = useCurrentUser();

  const navigateToProfile = React.useCallback(() => {
    window.location.href = client
      ? COACH_CLIENT_OVERVIEW_ROUTE.replace(":username", client?.username)
      : CLIENT_PROFILE_ROUTE;
  }, [navigate, user, client]);

  const timeDiff = dayjs.duration(dayjs(takenOn).diff(dayjs()));

  return (
    <Grid2 className={clsx(s.root, className)}>
      <Typography variant="h5" marginBottom={2}>
        {title || ""}
      </Typography>
      <Card className={s.mediaWrapper}>
        <CardActionArea
          onClick={onClick}
          disabled={selected || !onClick}
          data-cell={cell}
          data-id={id}
        >
          <CardMedia className={s.media} image={url} />
          {selected && <div className={s.mediaHighlight} />}
        </CardActionArea>
      </Card>
      <Box className={s.textContainer}>
        {showWeight &&
          (weight !== 0 && weightUnit ? (
            <Typography
              variant="h5"
              children={`${Math.round((+weight + Number.EPSILON) * 10) / 10} ${weightUnit.toLowerCase()}`}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                children={`No weight`}
              />
              <Button
                onClick={navigateToProfile}
                size="small"
                sx={{ padding: 0 }}
                component="a"
                target="_blank"
              >
                <Typography variant="body2" children="Log a weight" />
              </Button>
            </Box>
          ))}
        <Typography
          variant="body1"
          color="textSecondary"
          children={
            timeAgoFormat
              ? Math.abs(timeDiff.asDays()) < 7 && cell
                ? "NOW"
                : timeDiff.humanize(true)
              : dayjs(takenOn).format("MMM DD, YYYY")
          }
        />
      </Box>
    </Grid2>
  );
}

export function ProgressDialogCardSkeleton(props) {
  const {
    className,
    cell,
    selected,
    onClick,
    title,
    comparisonMode = false,
  } = props;
  const s = useStyles(comparisonMode);

  return (
    <Grid2 className={clsx(s.root, className)}>
      <Typography variant="h5" marginBottom={2}>
        {title || ""}
      </Typography>
      <CardActionArea
        disabled={selected || !onClick}
        onClick={onClick}
        data-cell={cell}
      >
        <Card className={s.mediaWrapper}>
          <Skeleton
            sx={{ maxWidth: "100%" }}
            className={s.media}
            variant="rectangular"
            animation={false}
          ></Skeleton>
          <AddCircleIcon
            height={100}
            width={100}
            sx={{
              position: "absolute",
              top: "calc(50% - 40px)",
              left: "calc(50% - 40px)",
              height: "80px",
              width: "80px",
            }}
            color="disabled"
          />
          {selected && <div className={s.mediaHighlight} />}
        </Card>
      </CardActionArea>
    </Grid2>
  );
}
