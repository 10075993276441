import clsx from "clsx";
import React, { useCallback } from "react";
import {
  Box,
  BoxProps,
  Button,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  nutritionTrackingTypeArray,
  nutritionCommentsTypeArray,
  nutritionTargetsArray,
  floatNumberInputProps,
  nutritionComments,
} from "../../constants";
import { colorSystem } from "../../theme";
import { ReactComponent as CalendarEmptyIcon } from "../../icons/CalendarEmpty.svg";
import dayjs from "dayjs";
import LoadingButton from "../button/LoadingButton";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginBottom: theme.spacing(2.5),
  },

  label: {
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },

  input: {
    "& input": {
      fontWeight: 500,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  disableInput: {
    color: "rgba(0, 0, 0, 0.38)",
  },

  box: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  select: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    height: theme.spacing(7),

    "& [role=button]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },

  primaryText: {
    color: colorSystem.black,
    fontWeight: 500,
  },

  gramContainer: {
    position: "relative",
  },

  gram: {
    position: "absolute",
    zIndex: 2,
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: 16,
  },

  button: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(1.5),
    width: "100%",
  },

  calendar: {
    borderColor: "rgba(25, 25, 25, 0.32)",
    borderWidth: 1,
    borderStyle: "solid",
    height: 56,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    fontSize: 16,
    fontFamily: "Montserrat",
    color: colorSystem.black,
    fontWeight: 500,
    cursor: "pointer",
  },

  sort: {},
}));

export interface ClientNutritionTargetDrawerEditViewProps extends BoxProps {
  dirty: boolean;
  creating: boolean;
  date: string;
  targetValue: string;
  trackingTypeValue: string;
  commentTypeValue: string;
  proteinValue: string;
  carbohydrateValue: string;
  fatValue: string;
  caloriesValue: string;
  onChangeTarget: (value: string) => void;
  onChangeCommentType: (value: string) => void;
  onChangeTrackingType: (value: string) => void;
  onChangeProtein: (value: string) => void;
  onChangeCarbohydrate: (value: string) => void;
  onChangeFat: (value: string) => void;
  onChangeCalories: (value: string) => void;
  onClickCalendar: () => void;
  onClickSave: () => void;
}

export function ClientNutritionTargetDrawerEditView(
  props: ClientNutritionTargetDrawerEditViewProps,
) {
  const {
    dirty,
    creating,
    date,
    targetValue,
    trackingTypeValue,
    commentTypeValue,
    proteinValue,
    carbohydrateValue,
    fatValue,
    caloriesValue,
    onChangeTarget,
    onChangeCommentType,
    onChangeTrackingType,
    onChangeProtein,
    onChangeCarbohydrate,
    onChangeFat,
    onChangeCalories,
    onClickCalendar,
    onClickSave,
    className,
    ...other
  } = props;
  const s = useStyles();

  const gramRender = React.useCallback(
    (value: any) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "500 16px Montserrat";
      const { width } = context.measureText(value);
      return (
        Boolean(value) &&
        trackingTypeValue === "MACROS" && (
          <span style={{ left: width + 23 }} className={s.gram}>
            g
          </span>
        )
      );
    },
    [trackingTypeValue, s],
  );

  const disableCalories = useCallback(() => {
    const { PROTEIN_ONLY, TRACKING_ONLY } = nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      commentTypeValue === TRACKING_ONLY ||
      trackingTypeValue === "PORTIONS" ||
      commentTypeValue === nutritionComments.FULL_MACROS
    );
  }, [commentTypeValue, trackingTypeValue]);

  const disableFat = useCallback(() => {
    const { PROTEIN_ONLY, TRACKING_ONLY, PROTEIN_CALORIES, CALORIES_ONLY } =
      nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      commentTypeValue === TRACKING_ONLY ||
      commentTypeValue === PROTEIN_CALORIES ||
      commentTypeValue === CALORIES_ONLY
    );
  }, [commentTypeValue]);

  const disableCarb = useCallback(() => {
    const { PROTEIN_ONLY, TRACKING_ONLY, PROTEIN_CALORIES, CALORIES_ONLY } =
      nutritionComments;
    return (
      commentTypeValue === PROTEIN_ONLY ||
      commentTypeValue === TRACKING_ONLY ||
      commentTypeValue === PROTEIN_CALORIES ||
      commentTypeValue === CALORIES_ONLY
    );
  }, [commentTypeValue]);

  const disableProtein = useCallback(() => {
    const { TRACKING_ONLY, CALORIES_ONLY } = nutritionComments;
    return (
      commentTypeValue === TRACKING_ONLY || commentTypeValue === CALORIES_ONLY
    );
  }, [commentTypeValue]);

  const disableButton = useCallback(() => {
    const {
      PROTEIN_ONLY,
      TRACKING_ONLY,
      PROTEIN_CALORIES,
      CALORIES_ONLY,
      FULL_MACROS,
    } = nutritionComments;
    if (creating) {
      return true;
    }
    switch (commentTypeValue) {
      case PROTEIN_ONLY:
        return !proteinValue || +proteinValue <= 0;
      case CALORIES_ONLY:
        return !caloriesValue || +caloriesValue <= 0;
      case PROTEIN_CALORIES:
        return (
          !proteinValue ||
          +proteinValue <= 0 ||
          !caloriesValue ||
          +caloriesValue <= 0
        );
      case FULL_MACROS:
        return (
          !proteinValue ||
          +proteinValue <= 0 ||
          !carbohydrateValue ||
          +carbohydrateValue <= 0 ||
          !fatValue ||
          +fatValue <= 0
        );
      case TRACKING_ONLY:
        return false;
    }
  }, [
    commentTypeValue,
    proteinValue,
    fatValue,
    carbohydrateValue,
    caloriesValue,
    creating,
  ]);

  return (
    <Box className={clsx(className)} {...other}>
      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Tracking type
        </Typography>
        <Select
          className={clsx(s.select, s.sort)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          variant="outlined"
          fullWidth
          onChange={(e) => onChangeTrackingType(e.target.value as string)}
          value={trackingTypeValue}
        >
          {nutritionTrackingTypeArray.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              <ListItemText
                classes={{ primary: s.primaryText }}
                primary={label}
              />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Targets
        </Typography>
        <Select
          className={clsx(s.select, s.sort)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          variant="outlined"
          fullWidth
          onChange={(e) => onChangeTarget(e.target.value as string)}
          value={targetValue}
        >
          {nutritionTargetsArray.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              <ListItemText
                classes={{ primary: s.primaryText }}
                primary={label}
              />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Start date
        </Typography>
        <Box onClick={onClickCalendar} className={s.calendar}>
          <span>{dayjs(date, "YYYY-MM-DD").format("MMM DD YYYY")}</span>
          <CalendarEmptyIcon />
        </Box>
      </Box>
      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Comments
        </Typography>
        <Select
          className={clsx(s.select, s.sort)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          variant="outlined"
          fullWidth
          onChange={(e) => onChangeCommentType(e.target.value as string)}
          value={commentTypeValue}
        >
          {nutritionCommentsTypeArray
            .filter((comment) => {
              if (trackingTypeValue === "MACROS") {
                return true;
              } else {
                return comment.value === nutritionComments.CALORIES_ONLY
                  ? false
                  : comment.value !== nutritionComments.PROTEIN_CALORIES;
              }
            })
            .map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                <ListItemText
                  classes={{ primary: s.primaryText }}
                  primary={label}
                />
              </MenuItem>
            ))}
        </Select>
      </Box>
      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Protein
        </Typography>
        <Box
          className={clsx(s.gramContainer, disableProtein() && s.disableInput)}
        >
          <TextField
            variant="outlined"
            fullWidth
            value={proteinValue}
            onChange={(e) => onChangeProtein(e.target.value as string)}
            className={s.input}
            disabled={disableProtein()}
            error={(!proteinValue || +proteinValue <= 0) && dirty}
            type="number"
            slotProps={{
              input: {
                inputProps: floatNumberInputProps,
              },
            }}
          />
          {gramRender(proteinValue)}
        </Box>
      </Box>
      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Carbohydrate
        </Typography>
        <Box className={clsx(s.gramContainer, disableCarb() && s.disableInput)}>
          <TextField
            variant="outlined"
            fullWidth
            value={carbohydrateValue}
            onChange={(e) => onChangeCarbohydrate(e.target.value as string)}
            className={s.input}
            disabled={disableCarb()}
            error={(!carbohydrateValue || +carbohydrateValue <= 0) && dirty}
            type="number"
            slotProps={{
              input: {
                inputProps: floatNumberInputProps,
              },
            }}
          />
          {gramRender(carbohydrateValue)}
        </Box>
      </Box>
      <Box className={s.inputWrapper}>
        <Typography variant="h4" className={s.label}>
          Fat
        </Typography>
        <Box className={clsx(s.gramContainer, disableCarb() && s.disableInput)}>
          <TextField
            variant="outlined"
            fullWidth
            value={fatValue}
            onChange={(e) => onChangeFat(e.target.value as string)}
            className={s.input}
            disabled={disableFat()}
            error={(!fatValue || +fatValue <= 0) && dirty}
            type="number"
            slotProps={{
              input: {
                inputProps: floatNumberInputProps,
              },
            }}
          />
          {gramRender(fatValue)}
        </Box>
      </Box>
      {trackingTypeValue === "MACROS" && (
        <Box className={s.inputWrapper}>
          <Typography variant="h4" className={s.label}>
            Calories
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={caloriesValue}
            onChange={(e) => onChangeCalories(e.target.value as string)}
            className={s.input}
            disabled={disableCalories()}
            error={(!caloriesValue || +caloriesValue <= 0) && dirty}
            type="number"
            slotProps={{
              input: {
                inputProps: floatNumberInputProps,
              },
            }}
          />
        </Box>
      )}
      <LoadingButton
        className={s.button}
        variant="contained"
        children="Save target"
        onClick={onClickSave}
        disabled={disableButton()}
      />
    </Box>
  );
}
