import React, { FormEvent } from "react";
import { DialogContent, TextField, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../../../components/dialog/FullScreenDialog";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import firebase from "firebase";
import FbUsersService from "../../../services/FbUsersService";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { validatePassword } from "../../../utils/password";
import PasswordField from "../../fields/PasswordField";
import { BaseDialog } from "../../dialog/BaseDialog";

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(1),
  },
  form: {
    width: 450,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  submit: {
    padding: theme.spacing(2),
    fontWeight: 600,
  },
}));

const initialState = {
  password: "",
  newPassword: "",
};

const initialErrors = {
  password: undefined,
  newPassword: undefined,
};

export interface ChangePasswordDialogProps
  extends Omit<FullScreenDialogProps, "title" | "children" | "id"> {
  // TODO_API_V2_USER_ID: Temporary change type to string. Should be number only after component migrated.
  id: string | number;
}

export function ChangePasswordDialog(props: ChangePasswordDialogProps) {
  const { className, id: id, ...other } = props;
  const [state, setState] = React.useState(initialState);
  const [errors, setErrors] = React.useState(initialErrors);
  const [loading, setLoading] = React.useState(false);
  const user = useCurrentUser();
  const s = useStyles();

  const updatePassword = async () => {
    await firebase
      .auth()
      .signInWithEmailAndPassword(user.email, state.password);
    await firebase.auth().currentUser?.updatePassword(state.newPassword);
    await FbUsersService.updatePasswordDate();
  };

  const updatePasswordMutation = useMutation({
    mutationFn: updatePassword,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
      setLoading(false);
      props.onClose(event, "escapeKeyDown");
    },
    onError: (error: any) => {
      setErrors((x) => ({ ...x, password: error.message }));
      setLoading(false);
    },
    onMutate: () => {
      setLoading(true);
    },
  });

  const handleChange = React.useCallback((event) => {
    const { name, value } = event.target;
    setState((x) => (x[name] === value ? x : { ...x, [name]: value }));
    setErrors((x) => (x[name] ? { ...x, [name]: undefined } : x));
  }, []);

  const queryClient = useQueryClient();

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    const err: any = {};

    if (!state.password) {
      err.password = "Old password is required";
    }

    if (!state.newPassword) {
      err.newPassword = "New password is required.";
    } else {
      err.newPassword = validatePassword(state.newPassword);
    }

    if (Object.keys(err).filter((key) => err[key]).length > 0) {
      setErrors(err);
      return;
    }

    updatePasswordMutation.mutate();
  }

  return (
    <BaseDialog title="Change password" {...other}>
      <DialogContent>
        <form
          id="change-password-form"
          onSubmit={handleSubmit}
          className={s.form}
        >
          <TextField
            className={s.field}
            variant="filled"
            name="password"
            type="password"
            label="Current Password"
            placeholder="* * * * * *"
            value={state.password}
            onChange={handleChange}
            error={Boolean(errors.password)}
            helperText={errors.password || " "}
            disabled={loading}
            fullWidth
            //needed to to fix cutting of label
            margin="dense"
          />
          <PasswordField
            tooltipPlacement="top"
            className={s.field}
            name="newPassword"
            label="New Password"
            value={state.newPassword}
            onChange={handleChange}
            error={Boolean(errors.newPassword)}
            helperText={errors.newPassword || " "}
            disabled={loading}
            fullWidth
          />

          <Button
            className={s.submit}
            variant="contained"
            type="submit"
            form="change-password-form"
            disabled={loading}
            children="Change password"
            fullWidth
          />
        </form>
      </DialogContent>
    </BaseDialog>
  );
}
