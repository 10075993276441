import React, { useMemo } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { ClientActivityInfo } from "@growth-machine-llc/stridist-api-client";

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    minWidth: 600,
  },
  tableCell: {
    padding: theme.spacing(1),
  },
  userCard: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

interface ComponentActivitiesTableProps {
  activities: ClientActivityInfo[];
}

const ComponentActivitiesTable: React.FC<ComponentActivitiesTableProps> = ({
  activities,
}) => {
  const classes = useStyles();

  const sortedActivities = useMemo(() => {
    return [...activities].sort((a, b) => {
      const aName = a.client?.displayName || a.client?.username || "";
      const bName = b.client?.displayName || b.client?.username || "";
      return aName.localeCompare(bName);
    });
  }, [activities]);

  return (
    <Table className={classes.tableRoot} aria-label="activities table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell}>Client</TableCell>
          <TableCell className={classes.tableCell} align="center">
            Exists?
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            ID
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Component ID
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Enrollment ID
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Db Week
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Activity Date
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Completed At
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Coach Archived
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Answers
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Submitted At
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Edited At
          </TableCell>
          <TableCell className={classes.tableCell} align="center">
            Original API
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedActivities.map((item, index) => {
          const client = item.client;
          const activity = item.activity;
          return (
            <TableRow key={index}>
              <TableCell className={classes.tableCell}>
                <Box className={classes.userCard}>
                  <Avatar
                    src={client?.photoUrl}
                    alt={client?.displayName || client?.username}
                  />
                  <Typography variant="body2">
                    {client?.displayName || client?.username} <br />
                    <small>{client?.email}</small>
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align="center"
                sx={{
                  color: item.isMissingActivity ? "error.main" : "text.primary",
                }}
              >
                {item.isMissingActivity ? "Missing" : "Yes"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {item.activity?.id || "-"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {item.activity?.componentId || "-"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {item.enrollmentId || "-"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {item.activity?.week || "-"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {activity?.date
                  ? dayjs(activity.date.toISOString()).format("YYYY-MM-DD")
                  : "-"}
                {item.dateCalculatedDay
                  ? ` (w ${item.dateCalculatedDay.weekNumber}; d ${item.dateCalculatedDay.day + 1})`
                  : ""}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {activity?.completedAt
                  ? dayjs(activity.completedAt.toISOString()).format(
                      "YYYY-MM-DD",
                    )
                  : "-"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {activity?.coachArchived ? "Yes" : "No"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {activity?.answers || "-"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {activity?.submittedAt
                  ? dayjs(activity.submittedAt.toISOString()).format(
                      "YYYY-MM-DD",
                    )
                  : "-"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {activity?.editedAt
                  ? dayjs(activity.editedAt.toISOString()).format("YYYY-MM-DD")
                  : "-"}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {activity?.isOldApi ? "Migrated" : "New API"}
              </TableCell>
            </TableRow>
          );
        })}
        {!sortedActivities.length && (
          <TableRow>
            <TableCell
              className={classes.tableCell}
              align="center"
              colSpan={12}
            >
              No activities found.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ComponentActivitiesTable;
