import ClientsService from "../../../services/ClientsService";
import { COACH_CLIENTS_LIST_QUERY_KEY } from "../CoachClientsListScreen";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useInvalidateCoachProgramAndGroupQueries } from "../../../hooks/useInvalidateCoachProgramAndGroupQueries";
import { CURRENT_USER_QUERY_KEY } from "../../../wrappers/current-user/CurrentUserWrapper";
import { useNavigate } from "react-router-dom";
import { COACH_CLIENTS_ACTIVE_ROUTE } from "../../../routes/routes";
import { UserRole } from "../../../constants";

const useDeleteSingleClientMutation = (userRole: UserRole) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const invalidateCoachProgramAndGroupQueries =
    useInvalidateCoachProgramAndGroupQueries();
  return useMutation({
    mutationFn: ClientsService.deleteClient,
    onSuccess: () => {
      if (userRole === UserRole.COACH) {
        queryClient.resetQueries({
          queryKey: [COACH_CLIENTS_LIST_QUERY_KEY],
          exact: false,
        });

        queryClient.invalidateQueries({
          queryKey: [CURRENT_USER_QUERY_KEY],
          refetchType: "all",
        });

        invalidateCoachProgramAndGroupQueries();
        navigate(COACH_CLIENTS_ACTIVE_ROUTE);
      } else {
        localStorage.clear();
        window.location.reload();
      }
    },
  });
};
export default useDeleteSingleClientMutation;
