import ReactGA from "react-ga4";
import { SignupStep, UserRole } from "../constants";
import { toSnakeCase } from "./misc";

enum SignupEventTypes {
  SIGNUP_STEP_COMPLETED = "signup_step_completed",
  SIGNUP_STARTED = "signup_started",
  SIGNUP_COMPLETED = "signup_completed",
  SIGNUP_FAILED = "coach_signup_failed",
}

enum SubscriptionEventTypes {
  COACH_SIGNUP_SUBSCRIPTION_SUCCEEDED = "coach_signup_subscription_succeeded",
  COACH_SIGNUP_SUBSCRIPTION_FAILED = "coach_signup_subscription_failed",
}

type GAEventName = SignupEventTypes | SubscriptionEventTypes; // | OtherEventTypes

export const initGoogleAnalytics = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID);
};
export const sendGoogleAnalyticsEvent = (
  eventName: GAEventName,
  params: Record<string, any> = {},
) => {
  ReactGA.event(eventName, params);
};

/**
 * @refactor This method is used for sending events  that are not defined as constants.
 * Should be removed after all events are switched to constants.
 */
export const sendUnknownGoogleAnalyticsEvent = (
  eventName: string,
  params: Record<string, any>,
) => {
  const formattedName = toSnakeCase(eventName);
  ReactGA.event(formattedName, params);
};

// Role-specific events
export const sendCoachGoogleAnalyticsEvent: typeof sendGoogleAnalyticsEvent = (
  eventName,
  params = {},
) => {
  sendGoogleAnalyticsEvent(eventName, { ...params, role: UserRole.COACH });
};

// Event-case specific functions

// --- Coach Signup Events ---
export const sendCoachSignupStartedEvent = () => {
  sendCoachGoogleAnalyticsEvent(SignupEventTypes.SIGNUP_STARTED, {});
};

export const sendCoachSignupCompletedEvent = () => {
  sendCoachGoogleAnalyticsEvent(SignupEventTypes.SIGNUP_COMPLETED, {});
};

export const sendCoachSignupStepCompletedEvent = (step: SignupStep) => {
  sendCoachGoogleAnalyticsEvent(SignupEventTypes.SIGNUP_STEP_COMPLETED, {
    step,
  });
};

export const sendCoachSignupFailedEvent = (
  error: string = "Failed to complete signup",
) => {
  sendCoachGoogleAnalyticsEvent(SignupEventTypes.SIGNUP_FAILED, {
    error,
  });
};

export const sendCoachSignupSubscriptionSucceededEvent = (userId: number) => {
  sendCoachGoogleAnalyticsEvent(
    SubscriptionEventTypes.COACH_SIGNUP_SUBSCRIPTION_SUCCEEDED,
    { userId },
  );
};

export const sendCoachSignupSubscriptionFailedEvent = (userId: number) => {
  sendCoachGoogleAnalyticsEvent(
    SubscriptionEventTypes.COACH_SIGNUP_SUBSCRIPTION_FAILED,
    {
      userId,
    },
  );
};
