import React, { useState, useEffect, ChangeEvent, useMemo } from "react";
import {
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import AdminService from "../../services/AdminService";
import { ComponentDuplicateInfo, ComponentType } from "@growth-machine-llc/stridist-api-client";

function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

export interface ComponentDuplicatesViewerProps {}

const ADMIN_DUPLICATES_QUERY_KEY = "admin-duplicates-viewer";

export function ComponentDuplicatesViewer(props: ComponentDuplicatesViewerProps) {
  const { program: programIdString } = useParams();
  const programId = parseInt(programIdString);
  const [days, setDays] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState<ComponentType | null>(null);
  const [includeContent, setIncludeContent] = useState(false);
  const [showOnlyMatchingWeeks, setShowOnlyMatchingWeeks] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const debouncedDays = useDebounce(days, 1500);
  const debouncedTitle = useDebounce(title, 1500);
  const debouncedType = useDebounce(type, 1500);
  const debouncedIncludeContent = useDebounce(includeContent, 1500);

  const {
    data: duplicates = [],
    isLoading,
  } = useQuery({
    queryKey: [
      ADMIN_DUPLICATES_QUERY_KEY,
      {
        programId,
        days: debouncedDays,
        title: debouncedTitle,
        type: debouncedType,
        includeContent: debouncedIncludeContent,
      },
    ],
    queryFn: () =>
      AdminService.getDuplicatesByTitle(
        programId,
        debouncedDays,
        debouncedTitle,
        debouncedType,
        debouncedIncludeContent,
      ),
    enabled:
      !isNaN(programId) &&
      programId > 0 &&
      debouncedDays.trim() !== "" &&
      debouncedTitle.trim() !== "" &&
      debouncedType !== null,
  });

  const contentGroups = useMemo(() => {
    const table = {};
    duplicates.forEach((dup) => {
      const content = dup.draftContent || dup.content;
      table[content] =  table[content] ? table[content] + 1 : 1;
    });
    return table;
  }, [duplicates]);

  const filteredDuplicates = showOnlyMatchingWeeks
    ? duplicates.filter(
        (item) =>
          item.rootWeekNum != null && item.scheduledWeekNum === item.rootWeekNum,
      )
    : duplicates;

  const handleToggleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setShowOnlyMatchingWeeks(event.target.checked);
    setSelectedIds([]);
  };

  const handleRowCheckboxChange = (id: number | undefined, checked: boolean) => {
    if (!id) return;
    if (checked) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => prev.filter((x) => x !== id));
    }
  };

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allIds = filteredDuplicates
        .map((dup) => dup.componentId)
        .filter((id): id is number => !!id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const allIdsInTable = filteredDuplicates
    .map((dup) => dup.componentId)
    .filter((id): id is number => !!id);
  const allSelected = allIdsInTable.length > 0 && selectedIds.length === allIdsInTable.length;
  const partiallySelected = selectedIds.length > 0 && selectedIds.length < allIdsInTable.length;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Component Duplicates Viewer
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1">Selected IDs:</Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={selectedIds.join(",")}
          InputProps={{ readOnly: true }}
        />
      </Box>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={3}>
          <TextField
            label="Days"
            value={days}
            onChange={(e) => setDays(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              value={type ?? ""}
              onChange={(e) =>
                setType(e.target.value === "" ? null : (e.target.value as ComponentType))
              }
            >
              <MenuItem value={"LESSON"}>LESSON</MenuItem>
              <MenuItem value={"HABIT"}>HABIT</MenuItem>
              <MenuItem value={"CHECKIN"}>CHECKIN</MenuItem>
              <MenuItem value={"WORKOUT"}>WORKOUT</MenuItem>
              <MenuItem value={"MESSAGE"}>MESSAGE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControlLabel
            label="Include Content"
            control={
              <Checkbox
                checked={includeContent}
                onChange={(e) => setIncludeContent(e.target.checked)}
              />
            }
          />
        </Grid>
      </Grid>
      <Box sx={{ mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showOnlyMatchingWeeks}
              onChange={handleToggleFilter}
            />
          }
          label="Show only items where rootWeekNum === scheduledWeekNum"
        />
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={partiallySelected}
                checked={allSelected}
                onChange={handleSelectAllChange}
              />
            </TableCell>
            <TableCell>Component ID</TableCell>
            <TableCell>Root Week #</TableCell>
            <TableCell>Scheduled Week #</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Days</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Repeat</TableCell>
            <TableCell>Last Modified</TableCell>
            <TableCell>Content Group</TableCell>
            <TableCell>Draft Content</TableCell>
            <TableCell>Content</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredDuplicates.map((item, index) => {
            const isChecked = item.componentId
              ? selectedIds.includes(item.componentId)
              : false;
            return (
              <TableRow key={index} hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) =>
                      handleRowCheckboxChange(item.componentId, e.target.checked)
                    }
                  />
                </TableCell>
                <TableCell>{item.componentId ?? "N/A"}</TableCell>
                <TableCell>{item.rootWeekNum ?? "N/A"}</TableCell>
                <TableCell>{item.scheduledWeekNum ?? "N/A"}</TableCell>
                <TableCell>{item.title ?? ""}</TableCell>
                <TableCell>{item.days ?? ""}</TableCell>
                <TableCell>{item.type ?? ""}</TableCell>
                <TableCell>{item.status ?? ""}</TableCell>
                <TableCell>{item.repeat ?? ""}</TableCell>
                <TableCell>
                  {item.lastModified
                    ? item.lastModified.format("YYYY-MM-DD HH:mm")
                    : ""}
                </TableCell>
                <TableCell>{contentGroups[item.draftContent || item.content]}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => copyToClipboard(item.draftContent ?? "null")}
                  >
                    Copy Draft
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => copyToClipboard(item.content ?? "null")}
                  >
                    Copy
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ComponentDuplicatesViewer;
