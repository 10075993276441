import React from "react";
import {
  ButtonProps,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import {
  CalendarDays,
  LayoutList,
  PanelLeftClose,
  SquarePen,
  TableProperties,
} from "lucide-react";
import { useEnableColors } from "../../hooks/useEnableColors";
import ToggleIconButtonGroup from "../button/ToggleIconButtonGroup";
import MinimizedTooltip from "../tooltip/MinimizedTooltip";

export enum ProgramDetailsViewMode {
  CALENDAR = "calendar",
  LIST = "list",
  SPREADSHEET = "spreadsheet",
}

export const DEFAULT_PROGRAM_DETAILS_VIEW_MODE = ProgramDetailsViewMode.LIST;

export type CalendarViewOptions = Record<
  ProgramDetailsViewMode,
  CalendarViewOption
>;

export const defaultCalendarViewOptions: CalendarViewOptions = {
  [ProgramDetailsViewMode.LIST]: {
    label: "List view",
    icon: <LayoutList size={20} />,
    disabled: false,
  },
  [ProgramDetailsViewMode.CALENDAR]: {
    label: "Calendar view",
    icon: <CalendarDays size={20} />,
    disabled: false,
  },
  [ProgramDetailsViewMode.SPREADSHEET]: {
    label: "Sheets view",
    icon: <TableProperties size={20} />,
    disabled: false,
  },
};

export interface CalendarViewOption {
  label: string;
  icon: React.ReactNode;
  disabled: boolean;
}

export interface ProgramDetailsSidebarModeButtonProps {
  onChange: (value: boolean) => void;
  value?: boolean;
}

export function ProgramDetailsSidebarModeButton(
  props: ProgramDetailsSidebarModeButtonProps,
) {
  const { value = true, onChange } = props;

  const enableColors = useEnableColors();

  const handleSidebarModeChange = (value: boolean) => {
    onChange(value);
  };

  return (
    <>
      <MinimizedTooltip
        title={"Choose working mode (open or eliminate sidebar)"}
        placement="top"
      >
        <ToggleIconButtonGroup
          color={enableColors ? "primary" : "secondary"}
          displayLabel="selected"
          value={value}
          onChange={handleSidebarModeChange}
          options={[
            {
              value: false,
              icon: <SquarePen size={20} />,
            },
            {
              value: true,
              icon: <PanelLeftClose size={20} />,
            },
          ]}
        />
      </MinimizedTooltip>
    </>
  );
}
