import clsx from "clsx";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import { ActionButton, ActionButtonProps } from "../button/ActionButton";

import { GroupPostsEditDialog } from "./GroupPostsEditDialog";
import { HeaderButton } from "../button/HeaderButton";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export interface GroupPostsAddButtonProps extends ActionButtonProps {
  groupId: number;
}

export function GroupPostsAddButton(props: GroupPostsAddButtonProps) {
  const { className, children, groupId, ...other } = props;
  const s = useStyles();

  const [openAddDialog, setOpenAddDialog] = React.useState(false);

  const handleOpenAddDialog = React.useCallback(() => {
    setOpenAddDialog(true);
  }, []);

  const handleCloseAddDialog = React.useCallback(() => {
    setOpenAddDialog(false);
  }, []);

  return (
    <>
      <HeaderButton
        className={clsx(s.root, className)}
        onClick={handleOpenAddDialog}
        {...other}
      >
        {children}
      </HeaderButton>

      {openAddDialog && (
        <GroupPostsEditDialog
          groupId={groupId}
          maxWidth="sm"
          open={openAddDialog}
          onClose={handleCloseAddDialog}
          post={null}
        />
      )}
    </>
  );
}
