import clsx from "clsx";
import React, { useContext, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Container,
  ContainerProps,
  Typography,
  Theme,
  Button,
  useScrollTrigger,
  Slide,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Node } from "slate";

import {
  getComponentSpecifics,
  checkinQuestionsAreValid,
} from "../../utils/component";
import {
  CheckInComponentContext,
  EditorProgramContext,
} from "../new-editor/hooks";
import { ComponentType, UserRole } from "../../constants";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useQueryParam } from "../../hooks/useQueryParam";
import { OnAnswerUpdateContext } from "../../hooks/useOnAnswerUpdate";
import { HabitPrompt } from "../habit-prompt/HabitPrompt";
import { LockedActivityDialog } from "../dialog/LockedActivityDialog";
import { ActivityFeedbackButton } from "../activity-feedback/ActivityFeedbackButton";
import { ComponentIcon } from "../program-component/ComponentIcon";
import { CHECKIN_ELEMENT_TYPES } from "../editor/types/elements";
import {
  dateWithoutWeekDay,
  formatDateOrdinal,
  getDaysDiffFromToday,
  getOrdinalSuffix,
  getTimeDiff,
  ISO_DATE_FORMAT,
} from "../../utils/date";
import { colorSystem } from "../../theme";
import { vibrate } from "../../utils/device";
import { useBackupState } from "../../hooks/useLocalStorage";

import { ActivityBar, ActivityBarProps } from "./ActivityBar";
import { ActivityStatus } from "./ActivityStatus";
import {
  ActivityCalendar,
  CLIENT_ACTIVITY_SUMMARY_QUERY_KEY,
  SelectedDay,
} from "./ActivityCalendar";
import { ActivityLocked } from "./ActivityLocked";
import WorkoutFinishAction from "../workout/WorkoutFinishAction";
import { usePopupState } from "material-ui-popup-state/hooks";
import WorkoutFinishModal from "../workout/WorkoutFinishModal";

import { useLocation, useNavigate } from "react-router-dom";
import { FinishWorkoutModalContext } from "../../contexts/FinishWorkoutModalContext";
import { COACH_ROUTE, HOME_ROUTE } from "../../routes/routes";
import TrackInfoTool from "../tools/TrackInfoTool";
import {
  EditorElementView,
  getCheckInComponentStatus,
} from "../new-editor/utils/editorUtils";
import { TElement } from "@udecode/plate-common";
import { unfixLegacyContent } from "../editor/utils/common";
import MinimizedDrawerContext from "../../contexts/MinimizedDrawerContext";
import {
  ActivityBriefDto,
  IActivityBriefDto,
} from "@growth-machine-llc/stridist-api-client";

import { useUpdateActivityComponentMutation } from "./mutations/useUpdateActivityComponentMutation";
import { DefaultLoader } from "../loading/DefaultLoader";
import JsonEditor from "../editor/JsonEditor";
import dayjs from "dayjs";
import { extractSlugId } from "../../utils/slug";
import { PROGRAM_COMPONENT_QUERY_KEY } from "../../hooks/activities/useActivity";
import { getCookie } from "../program-component/utils";

const ComponentEditor = React.lazy(
  () => import("../new-editor/ComponentEditor"),
);

const useStyles = makeStyles((theme) => {
  return {
    "@global": {
      body: {
        fontFamily: "Montserrat, sans-serif",
      },
    },

    root: {
      marginTop: 34,
      maxWidth: 1100,
      borderRadius: theme.spacing(1.5),
      marginBottom: theme.spacing(4),
      paddingBottom: theme.spacing(8),

      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(12),
      },
    },
    card: {},
    contentContainer: {
      display: "flex",
    },

    content: {
      flex: 1,
    },
    video: {
      flex: 1,
    },

    calendar: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(5),

      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(6),
      },
    },

    summaryDate: {
      fontSize: 24,
      fontWeight: 600,
      margin: theme.spacing(4, 8, 4, 0),
      whiteSpace: "break-spaces",
    },

    icon: {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      alignSelf: "baseline",
      "&$lesson": getComponentTypeStyles(ComponentType.LESSON, theme),
      "&$habit": getComponentTypeStyles(ComponentType.HABIT, theme),
      "&$checkin": getComponentTypeStyles(ComponentType.CHECKIN, theme),
    },

    subtitle: {
      position: "relative",
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      display: "flex",
    },

    header: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      position: "relative",
      flexWrap: "wrap",
    },

    feedbackButton: {
      backgroundColor: theme.palette.background.paper,
      width: theme.spacing(9),
      marginLeft: "auto",
    },

    title: {
      wordBreak: "break-word",
    },

    button: {
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      padding: theme.spacing(1.25, 0),
      marginTop: theme.spacing(4),
      color: theme.palette.common.white,
      borderColor: theme.palette.progress.green,

      "&:hover": {
        borderColor: colorSystem.green1,
        backgroundColor: colorSystem.green1,
      },

      "&$completed": {
        color: theme.palette.progress.green,
        borderColor: theme.palette.progress.green,
        backgroundColor: theme.palette.common.white,
      },
    },

    locked: {
      margin: theme.spacing(10, 0),
    },
    readyToSubmit: {
      backgroundColor: theme.palette.progress.green,
    },

    backButtonStyle: {
      backgroundColor: "transparent",
    },

    lesson: {},
    habit: {},
    checkin: {},
    completed: {},
  };

  function getComponentTypeStyles(componentType: ComponentType, theme: Theme) {
    const key = componentType.toLowerCase();
    const color = theme.palette.activity[key];

    return { fill: color };
  }
});

export interface ActivityProps extends Omit<ContainerProps, "children"> {
  activity: ActivityBriefDto;
  coach?: boolean;
  program?: any;
  client: { username: string };
  shouldFetchSummary?: boolean;
  // `isLoadingActivity` is used to show loading when switching between dates in `ActivityCalendar`,
  // since this component is renders after activity is fetched
  isLoadingActivity?: boolean;
  isPlaceholderData?: boolean;
}

export function Activity(props: ActivityProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    className,
    activity,
    coach = false,
    program,
    shouldFetchSummary = false,
    client,
    isLoadingActivity,
    isPlaceholderData,
  } = props;

  const s = useStyles();
  const location = useLocation();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const ISOFormattedDate = activity.date.format(ISO_DATE_FORMAT);

  const {
    updateComponentContent,
    completeComponent,
    submitComponent,
    submitComponentWithNoAlert,
    isPending: upsertActivityInFlight,
  } = useUpdateActivityComponentMutation({ id: activity.id });

  const component = activity?.component;

  const originalContent: Node[] = React.useMemo(
    () => JSON.parse(activity.content) || [],
    [activity.content],
  );

  const formattedLongDate = activity.date.format("dddd, MMMM DD");

  const day = activity.date.date();
  const month = activity.date.format("MMM");
  const ordinalSuffix = getOrdinalSuffix(day);
  const formattedDate = `${month} ${day}${ordinalSuffix}`;

  const backupKey = [activity.id, activity.date].filter(Boolean).join(":");
  const [content, setContent, removeBackup] = useBackupState(
    "activity",
    backupKey,
    originalContent,
    (!coach && !activity.submittedAt) || !isPlaceholderData,
  );

  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [responsesOnlyQueryParam, setResponsesOnlyQueryParam] = useQueryParam(
    "responses",
    undefined,
  );
  const [disabledAction] = useQueryParam("notification", "");
  const responsesOnly = responsesOnlyQueryParam === "true";
  const { breakpoints } = useTheme();
  const isMd = useMediaQuery(breakpoints.only("md"));
  const triggerTreshold =
    activity.component.type === ComponentType.WORKOUT && isMd ? 170 : 100;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: triggerTreshold,
  });
  const [trackEvent] = useAnalytics();
  const user = useCurrentUser();
  const [previewQueryParam] = useQueryParam("preview", "");
  const returnBack = location.key !== "default";
  const showSummary =
    shouldFetchSummary && component.type !== ComponentType.LESSON;

  const timeDiff = getTimeDiff(ISOFormattedDate);
  const daysDiff = getDaysDiffFromToday(ISOFormattedDate);
  const locked = component.locked && timeDiff > 0 && !previewQueryParam;
  const closeAfterSave = !showSummary;
  const mdSm = useMediaQuery(breakpoints.down("sm"));
  const finishWorkoutModalContext = useContext(FinishWorkoutModalContext);
  const path = location.pathname;
  const componentSlugId = component?.slug ? extractSlugId(component.slug) : "";
  const dialogState = usePopupState({
    variant: "popover",
    popupId: "workout-finish-exercise",
  });

  const backButtonTooltip =
    user.role === UserRole.CLIENT
      ? returnBack
        ? "Back to Program"
        : "Back to Home"
      : null;

  const { typeName } = React.useMemo(() => {
    return getComponentSpecifics(component.type as ComponentType);
  }, [component.type]);

  const goBack = React.useCallback(() => {
    if (returnBack) {
      navigate(-1);
    } else {
      const returnUrl =
        user.role === UserRole.CLIENT
          ? HOME_ROUTE
          : location.pathname.replace(/\/[^/]+\/[^/]+\/?\/?$/, "") +
            location.search;
      navigate(returnUrl);
    }
  }, [returnBack, user.role]);

  const handleComplete = React.useCallback(() => {
    const completed = !activity.completedAt;

    completeComponent(
      { completed },
      {
        onSuccess: () => {
          trackEvent("Client - Complete " + typeName);
          if (completed) {
            vibrate();
          }

          queryClient.invalidateQueries({
            queryKey: [
              CLIENT_ACTIVITY_SUMMARY_QUERY_KEY,
              { username: client.username, slugId: componentSlugId },
            ],
            exact: true,
          });

          removeBackup();
        },
      },
    );
  }, [
    activity.completedAt,
    activity.id,
    content,
    completeComponent,
    showSummary,
    trackEvent,
    typeName,
  ]);

  const handleSubmit = React.useCallback(() => {
    const newContent = JSON.stringify(unfixLegacyContent(content));
    const submit =
      component.type === ComponentType.WORKOUT
        ? submitComponentWithNoAlert
        : submitComponent;

    submit(
      { content: newContent },
      {
        onSuccess: () => {
          trackEvent("Client - Complete " + typeName);

          if (closeAfterSave) {
            if (component.type === ComponentType.WORKOUT) {
              queryClient.setQueryData<IActivityBriefDto>(
                [
                  PROGRAM_COMPONENT_QUERY_KEY,
                  {
                    username: client.username,
                    date: ISOFormattedDate,
                    slugId: componentSlugId,
                  },
                ],
                (prev) => ({
                  ...prev,
                  submittedAt: dayjs(),
                  completedAt: dayjs(),
                  content: newContent,
                }),
              );

              navigate(
                `${path.substring(0, 9)}/end${path.substring(9, path.length)}`,
                {
                  state: {
                    defaultValues: {
                      startWorkout: activity.startWorkout,
                      endWorkout: activity.endWorkout,
                    },
                  },
                },
              );
            } else goBack();
          }

          if (component.type === ComponentType.LESSON) {
            vibrate();
          }

          if (component.type != ComponentType.HABIT) {
            queryClient.invalidateQueries({
              queryKey: [
                CLIENT_ACTIVITY_SUMMARY_QUERY_KEY,
                { username: client.username, slugId: componentSlugId },
              ],
              exact: true,
            });
          }
          removeBackup();
        },
      },
    );
  }, [
    activity.id,
    content,
    updateComponentContent,
    showSummary,
    trackEvent,
    typeName,
    closeAfterSave,
    component.type,
    goBack,
    path,
  ]);

  const devCookie = getCookie("stabilization");

  const handleAnswerUpdate = React.useCallback(
    (newContent?: Node[]) => {
      updateComponentContent(
        {
          content: JSON.stringify(newContent ?? content),
        },
        {
          onSuccess: () => {
            removeBackup();
          },
        },
      );
    },
    [activity.id, content, removeBackup, updateComponentContent],
  );

  const buttonText = React.useMemo(() => {
    switch (component.type) {
      case ComponentType.WORKOUT:
      case ComponentType.LESSON:
        return activity.completedAt ? "Completed" : "Mark as completed";
      case ComponentType.CHECKIN:
      case ComponentType.HABIT:
        return activity.submittedAt
          ? "Answers saved"
          : closeAfterSave
            ? "Save & close"
            : "Save my answers";
    }
  }, [
    activity.completedAt,
    activity.submittedAt,
    closeAfterSave,
    component.type,
  ]);

  const haveTodosChanged = (newTodos, prevTodos) => {
    return newTodos.some(
      (todo, index) => todo.checked !== prevTodos[index].checked,
    );
  };

  const getTodos = (content) => {
    return content.filter((node) => node.listStyleType === "todo");
  };

  const hasQuestions = React.useMemo(
    () =>
      content.some((node: any) => CHECKIN_ELEMENT_TYPES.includes(node.type)),
    [content],
  );

  React.useEffect(() => {
    setDisabled(
      !checkinQuestionsAreValid(
        content,
        component.type === ComponentType.CHECKIN,
      ),
    );
  }, [component.type, content]);

  const handleContentChange = React.useCallback(
    (value: Node[]) => {
      // TODO_API_V2_PROGRAM_COMPONENT STR-1332: [refactor] Investigate better approach for checkboxes update. Consider using custom editor plugin
      const previousTodos = getTodos(content);
      setContent(value);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        const newTodos = getTodos(value);
        if (haveTodosChanged(newTodos, previousTodos)) {
          handleAnswerUpdate(value);
        }
      }, 3000);
    },
    [setContent],
  );

  const handleChangeResponsesOnly = React.useCallback(
    (value: boolean) => {
      setResponsesOnlyQueryParam(value);

      setContent(
        value
          ? originalContent.filter((node: any) => node.answer)
          : originalContent,
      );
    },
    [originalContent, setContent, setResponsesOnlyQueryParam],
  );

  const handleOpen = React.useCallback(
    (e?) => {
      e && e.stopPropagation();
      dialogState.open();
      finishWorkoutModalContext?.setIsFinishWorkoutModalOpen(false);
    },
    [dialogState],
  );

  const ActivityBarCommon = React.forwardRef<HTMLElement, ActivityBarProps>(
    (props: Partial<ActivityBarProps>, ref) => {
      return (
        <ActivityBar
          ref={ref}
          coach={coach}
          responsesOnly={responsesOnly}
          onChangeResponsesOnly={handleChangeResponsesOnly}
          onCloseClick={goBack}
          backButtonTooltip={backButtonTooltip}
          {...props}
        />
      );
    },
  );

  const handleCalendarDaySelect = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, value: SelectedDay) => {
      const path =
        location.pathname.replace(`/${ISOFormattedDate}/`, `/${value.date}/`) +
        location.search;
      navigate(path, { replace: true });
    },
    [activity.date],
  );

  useEffect(() => {
    finishWorkoutModalContext?.isFinishWorkoutModalOpen && handleOpen();
  }, [finishWorkoutModalContext?.isFinishWorkoutModalOpen]);

  const [editingEl, setEditingEl] = React.useState<TElement | null>(null);

  const workoutFinishButton = React.useMemo(() => {
    return (
      activity.component.type === ComponentType.WORKOUT &&
      !coach &&
      !disabledAction && (
        <WorkoutFinishAction
          submitted={activity?.submittedAt}
          handleSubmit={handleOpen}
        />
      )
    );
  }, [
    activity?.component?.type,
    activity.submittedAt,
    coach,
    disabledAction,
    handleOpen,
  ]);

  const theme = useTheme();
  const isLlg = useMediaQuery(theme.breakpoints.down("llg"));
  const { minimizedDrawer } = useContext(MinimizedDrawerContext);

  return (
    <>
      <EditorProgramContext.Provider value={{ programId: program.id }}>
        {!locked || showSummary ? (
          <>
            {!(component.type === ComponentType.WORKOUT) && (
              <ActivityBarCommon position="relative" elevation={0} />
            )}
            {mdSm && component.type === ComponentType.WORKOUT ? (
              <ActivityBarCommon
                position="relative"
                elevation={0}
                {...{ action: workoutFinishButton }}
              />
            ) : (
              !mdSm &&
              component.type === ComponentType.WORKOUT && (
                <ActivityBarCommon
                  position="relative"
                  elevation={0}
                  {...{ action: workoutFinishButton }}
                  className={s.backButtonStyle}
                  backgroundStyle={s.backButtonStyle}
                />
              )
            )}
            <Container className={clsx(s.root, className)}>
              <Slide
                appear={false}
                direction="down"
                in={trigger}
                style={{
                  zIndex: theme.zIndex.modal,
                  paddingLeft:
                    !activity?.submittedAt &&
                    !coach &&
                    component.type === ComponentType.WORKOUT &&
                    !isLlg
                      ? minimizedDrawer
                        ? theme.drawer.minimizedWidth
                        : theme.drawer.width
                      : 0,
                }}
              >
                <ActivityBarCommon
                  subtitle={typeName}
                  title={component.title}
                  action={workoutFinishButton}
                />
              </Slide>

              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  className={s.subtitle}
                  variant="body1"
                  component="h4"
                >
                  {showSummary ? typeName : `${typeName} • ${formattedDate}`}
                </Typography>
                {!showSummary && mdSm && (
                  <ActivityFeedbackButton
                    className={s.feedbackButton}
                    activity={activity}
                  />
                )}
              </Box>

              <Box className={s.header}>
                <ComponentIcon
                  className={s.icon}
                  componentData={component}
                  variant="icon"
                />
                <Typography
                  className={s.title}
                  variant="h3"
                  component="h1"
                  children={component.title}
                />
                {!showSummary && !mdSm && (
                  <ActivityFeedbackButton
                    className={s.feedbackButton}
                    activity={activity}
                  />
                )}
              </Box>

              {showSummary && (
                <>
                  <ActivityCalendar
                    className={s.calendar}
                    client={client}
                    componentSlug={component.slug}
                    onDaySelect={handleCalendarDaySelect}
                    selected={ISOFormattedDate}
                  />
                  <Box className={s.header}>
                    <Typography className={s.summaryDate} variant="h3">
                      {formattedLongDate}
                    </Typography>

                    <ActivityFeedbackButton
                      className={s.feedbackButton}
                      activity={activity}
                    />
                  </Box>
                </>
              )}

              {isLoadingActivity ? (
                <Box
                  sx={{
                    position: "relative",
                    height: 200,
                  }}
                >
                  <DefaultLoader fillParent />
                </Box>
              ) : locked ? (
                <ActivityLocked className={s.locked} daysDiff={daysDiff} />
              ) : (
                <>
                  {coach && <ActivityStatus activity={activity} />}

                  {!coach && component.type === ComponentType.HABIT && (
                    <HabitPrompt
                      completed={!!activity.completedAt}
                      submitted={!!activity.submittedAt}
                      disabled={
                        (!hasQuestions && !!activity.submittedAt) ||
                        upsertActivityInFlight
                      }
                      prompt={component.habitPrompt}
                      onClick={handleComplete}
                    />
                  )}
                  <OnAnswerUpdateContext.Provider
                    value={
                      user.role === UserRole.CLIENT && activity.submittedAt
                        ? handleAnswerUpdate
                        : undefined
                    }
                  >
                    <CheckInComponentContext.Provider
                      value={{
                        // NOTE: Coach can only review the activity
                        view:
                          user.role === UserRole.CLIENT
                            ? EditorElementView.Client
                            : EditorElementView.Review,
                        status: getCheckInComponentStatus(
                          user.role,
                          !!activity.submittedAt,
                        ),
                        editingEl: editingEl,
                        setEditingEl: setEditingEl,
                      }}
                    >
                      <ComponentEditor
                        componentType={component.type as ComponentType}
                        value={content}
                        onChange={handleContentChange}
                        readOnly={true}
                        disabled={true}
                      />
                      {devCookie === "true" && (
                        <JsonEditor
                          content={content}
                          onContentChange={(newContent) => {
                            handleContentChange(newContent);
                          }}
                        />
                      )}
                    </CheckInComponentContext.Provider>
                  </OnAnswerUpdateContext.Provider>

                  {!coach &&
                    !activity.submittedAt &&
                    !(
                      (component.type === ComponentType.HABIT ||
                        component.type === ComponentType.WORKOUT) &&
                      !hasQuestions
                    ) && (
                      <Button
                        className={clsx(
                          s.button,
                          activity.submittedAt && s.completed,
                          !disabled &&
                            !upsertActivityInFlight &&
                            s.readyToSubmit,
                        )}
                        fullWidth
                        variant="outlined"
                        onClick={handleSubmit}
                        disabled={disabled || upsertActivityInFlight}
                        children={buttonText}
                      />
                    )}
                </>
              )}
              {dialogState.isOpen && (
                <WorkoutFinishModal
                  handleSubmit={handleSubmit}
                  onClose={() => dialogState.close()}
                  loading={upsertActivityInFlight}
                />
              )}
            </Container>
          </>
        ) : (
          <LockedActivityDialog
            open={true}
            onClose={goBack}
            daysDiff={daysDiff}
          />
        )}
      </EditorProgramContext.Provider>
      <TrackInfoTool
        trackInfo={{
          name: previewQueryParam
            ? "Coach - Preview Activity"
            : "Client - Program",
          properties: {
            component: component,
            date: activity.date,
            program: program,
          },
        }}
      />
    </>
  );
}
