import React from "react";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { SidebarTabs } from "../../../constants";
import { CalendarDays, FileText, Eye } from "lucide-react";
import { useEnableColors } from "../../../hooks/useEnableColors";
import { ComponentType } from "../../../constants";
import { getColorByComponentType } from "../../program-calendar/utils/common";
import { SIDEBAR_BOTTOM_NAVIGATION_HEIGHT } from "../Sidebar";
import { useSidebar } from "../../../contexts/CurriculumSidebarContext";

const TABS_ASSETS = [
  { tab: SidebarTabs.DETAILS, icon: <FileText />, title: "Details" },
  { tab: SidebarTabs.PREVIEW, icon: <Eye />, title: "Preview" },
  { tab: SidebarTabs.SCHEDULE, icon: <CalendarDays />, title: "Schedule" },
];

interface IBottomNavigationProps {
  type: ComponentType;
}

const BottomNavigation = (props: IBottomNavigationProps) => {
  const { type } = props;

  const theme = useTheme();
  const enableColors = useEnableColors();
  const componentTypeColor = getColorByComponentType(type as ComponentType);
  const { activeTab, changeActiveTab } = useSidebar();

  interface ITabProps {
    tab: SidebarTabs;
    icon: JSX.Element;
    title: string;
    index: number;
  }
  const Tab = ({ tab, icon, title, index }: ITabProps) => {
    const isActive = tab === activeTab;
    const isFirst = index === 0;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",

          color: isActive
            ? enableColors
              ? componentTypeColor
              : theme.palette.common.black
            : "inherit",

          borderColor: isActive
            ? enableColors
              ? componentTypeColor
              : theme.palette.common.black
            : "inherit",
        }}
      >
        {index !== 0 && (
          <Divider
            orientation="vertical"
            sx={{ height: "50%", marginBlock: "auto", marginInline: 0.25 }}
          />
        )}
        {isActive && !isFirst && (
          <Typography sx={{ pl: 1, fontWeight: 600 }}>{title}</Typography>
        )}
        <IconButton onClick={() => changeActiveTab(tab)} color="inherit">
          {icon}
          {isActive && (
            <Divider
              sx={{
                borderColor: "inherit",
                position: "absolute",
                bottom: "4px",
                width: "50%",
              }}
            />
          )}
        </IconButton>
        {isActive && isFirst && (
          <Typography sx={{ pr: 1, fontWeight: 600 }}>{title}</Typography>
        )}
      </Box>
    );
  };
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          paddingBlock: 1,
          background: theme.palette.common.white,
          borderTop: 1,
          display: "flex",
          justifyContent: "center",
          borderColor: theme.palette.divider,
          color: theme.palette.text.secondary,
          height: SIDEBAR_BOTTOM_NAVIGATION_HEIGHT,
        }}
      >
        {TABS_ASSETS.map((item, index) => (
          <Tab
            tab={item.tab}
            icon={item.icon}
            title={item.title}
            index={index}
          />
        ))}
      </Box>
    </>
  );
};

export default BottomNavigation;
