import clsx from "clsx";
import React from "react";
import {
  Drawer,
  DrawerProps,
  Typography,
  TextFieldProps,
  InputLabel,
  useTheme,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDebounce } from "../../hooks/useDebounce";
import { EditorField } from "../program/EditorField";
import { NormalizedCurriculumWeek } from "../../redux/types";
import { useCurriculumDispatch } from "../../redux/hooks";
import { updateDescription } from "../../redux/curriculum/curriculum-slice";
import { X } from "lucide-react";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(3, 4),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(65),
      minWidth: theme.spacing(50),
      width: theme.spacing(65),
      boxShadow: theme.shadows[8],
    },
  },

  title: {
    fontSize: 32,
    margin: theme.spacing(5, 0),
  },

  description: {
    borderRadius: 0,
  },

  input: {
    "& .MuiInputBase-multiline": {
      padding: theme.spacing(0, 1.75),
    },
  },
}));

export interface WeekDrawerProps extends DrawerProps {
  week: NormalizedCurriculumWeek;
  weekId?: number;
  weekNumber?: number;
}

export function WeekDrawer(props: WeekDrawerProps) {
  const { className, week, weekId, weekNumber, onClose, ...other } = props;
  const theme = useTheme();
  const s = useStyles();
  const dispatch = useCurriculumDispatch();

  const [description, setDescription] = React.useState(week.description);
  const debouncedDescription = useDebounce(description, 500);

  React.useEffect(() => {
    if (week.description !== debouncedDescription) {
      dispatch(
        updateDescription({
          id: weekId,
          description: debouncedDescription || null,
        }),
      );
    }
  }, [debouncedDescription, week.description, weekNumber]);

  const handleDescriptionChange: TextFieldProps["onChange"] = React.useCallback(
    ({ target: { value } }) => {
      setDescription(value);
    },
    [],
  );

  return (
    <Drawer
      className={clsx(s.root, className)}
      classes={{ paper: s.paper }}
      anchor="right"
      variant="temporary"
      disableEnforceFocus
      onClose={onClose}
      {...other}
    >
      <IconButton
        onClick={() => onClose(event, "backdropClick")}
        sx={{
          position: "absolute",
          top: theme.spacing(2),
          right: theme.spacing(2),
        }}
      >
        <X />
      </IconButton>

      <Typography variant="h2" className={s.title}>
        Week {weekNumber} Settings
      </Typography>

      <InputLabel>Description</InputLabel>
      <EditorField
        className={clsx(s.input)}
        value={description}
        onChange={handleDescriptionChange}
        helperText="Optional description to describe what your clients will focus on."
        variant="outlined"
        color="primary"
        fullWidth
        multiline
      />
    </Drawer>
  );
}
