import { useQueryClient } from "@tanstack/react-query";
import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import {
  COACH_GROUPS_COUNT_QUERY_KEY,
  COACH_GROUPS_LIST_QUERY_KEY,
} from "../../../routes/coach/groups/CoachGroupsListRoute";
import GroupsService from "../../../services/GroupsService";
import {
  IGroupsCountDto,
  GroupStatus,
  PaginatedListOfGroupDto,
} from "@growth-machine-llc/stridist-api-client";
import { getGroupsCountFromAction } from "../../../contexts/CoachTabsContext";

export const useDeleteGroupMutation = () => {
  const queryClient = useQueryClient();

  return useOptimisticUpdateMutation({
    queryKey: [COACH_GROUPS_LIST_QUERY_KEY],
    mutationFn: GroupsService.deleteGroup,
    optimisticUpdater: {
      updateFn: (oldData: PaginatedListOfGroupDto, id) => {
        oldData.items = oldData.items.filter((group) => group.id !== id);
        return oldData;
      },
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            COACH_GROUPS_LIST_QUERY_KEY,
            { status: GroupStatus.ARCHIVED },
          ],
        });

        queryClient.setQueryData<IGroupsCountDto>(
          [COACH_GROUPS_COUNT_QUERY_KEY],
          (prev) => (prev ? getGroupsCountFromAction(prev, "delete") : prev),
        );
      },
    },
  });
};
