import { Box, Skeleton, BoxProps } from "@mui/material";
import { ReactComponent as ClientFormIcon } from "../../icons/TaskListEdit.svg";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import clsx from "clsx";
import { useStyles } from "./ClientFormCardItem";

export function ClientFormCardItemSkeleton(props: BoxProps) {
  const s = useStyles();
  const { className, ...other } = props;
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <ClientFormIcon className={s.icon} />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Skeleton variant="text" width={"100%"} />
        <Skeleton variant="text" width={"50%"} />
      </Box>
    </Box>
  );
}
