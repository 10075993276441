import React from "react";
import { Box, DialogContent, Grid2 } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  FullScreenDialog,
  FullScreenDialogProps,
} from "../dialog/FullScreenDialog";
import { useQueryParam } from "../../hooks/useQueryParam";

import { ProgressDialogActions } from "./ProgressDialogActions";
import {
  ProgressDialogCard,
  ProgressDialogCardSkeleton,
} from "./ProgressDialogCard";
import { PaginatedProgressPhotoDto } from "@growth-machine-llc/stridist-api-client";
import ProgressPhotoService from "../../services/ProgressPhotoService";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import { ProgressDialogPhotoSelector } from "./ProgressDialogPhotoSelector";

const useStyles = makeStyles((theme) => ({
  content: {
    position: "absolute",
    left: 0,
    overflowX: "hidden",
    top: "5vh",
    height: "95vh",
    maxHeight: "95vh",
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
  },
  grid: {
    flexWrap: "nowrap",
    height: "100%",
    maxWidth: "95vw",
    maxHeight: "100%",
    overflowY: "auto",
    paddingBottom: theme.spacing(10),
    alignItems: "center",
  },
  actions: {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
  },
}));

type ProgressPhotos = ReadonlyArray<SelectedPhoto> | SelectedPhoto[];

export type SelectedPhoto = PaginatedProgressPhotoDto;

export type ProgressDialogState = {
  comparisonMode: boolean;
  selected: SelectedPhoto;
  showWeight: boolean;
  timeAgoFormat: boolean;
  comparison: SelectedPhoto[];
  selectedCell: number;
};

export interface ProgressDialogProps
  extends Omit<FullScreenDialogProps, "title" | "children"> {
  clientId: number;
}

export const PROGRESS_DIALOG_QUERY_KEY = "progress-photos-dialog";
export const PAGE_SIZE = 20;

const cardTitles = ["Before", "After"];

export function ProgressDialog(props: ProgressDialogProps) {
  const { clientId, onClose, ...other } = props;
  const s = useStyles();

  const {
    data: photosData,
    ref: loadNextPageRef,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isInitialLoading,
  } = useInfiniteScroll({
    queryKey: [PROGRESS_DIALOG_QUERY_KEY, { clientId: clientId }],
    queryFn: ({ pageParam }) =>
      ProgressPhotoService.getPhotos(pageParam as number, PAGE_SIZE, clientId),
    initialPageParam: 1,
    getNextPageParam: (currentPage) =>
      currentPage.hasNextPage ? currentPage.pageNumber + 1 : undefined,
  });

  const photos = photosData?.pages.flatMap((page) => page.items) || [];

  const [state, setState] = React.useState<ProgressDialogState>({
    comparisonMode: true,
    selected: undefined,
    comparison: [undefined, undefined],
    showWeight: true,
    timeAgoFormat: true,
    selectedCell: 1,
  });

  React.useEffect(() => {
    if (photos[0]) {
      setState((prev) => ({
        ...prev,
        comparison: [undefined, photos[0]],
        selectedCell: 0,
      }));
    }
  }, [isInitialLoading]);

  function handleClickClose(event) {
    onClose(event, "escapeKeyDown");
  }

  function handleSelect(event, id) {
    const comparisonItems = state.comparison;
    const callToReplace = state.selectedCell;
    const selectedPhoto = photos.find((photo) => photo.id == id);
    comparisonItems[callToReplace] = selectedPhoto;
    const isInitialSelect = !comparisonItems[0] || !comparisonItems[1];
    setState((x) => ({
      ...x,
      selectedCell: isInitialSelect ? Number(!selectedCell) : selectedCell,
      selected: !isInitialSelect && selectedPhoto,
      comparison: comparisonItems,
    }));
  }

  function toggleComparisonMode() {
    setState((x) => ({
      ...x,
      comparisonMode: !x.comparisonMode,
      comparison: [undefined, x.selected],
      selectedCell: 1,
      selected: x.comparisonMode ? x.comparison[1] : x.selected,
    }));
  }

  function handleCardClick(event) {
    const cell = parseInt(event.currentTarget.dataset.cell);
    const id = event.currentTarget.dataset.id;
    const selectedPhoto = photos.find((photo) => photo.id == id);
    setState((x) => ({
      ...x,
      selectedCell: cell,
      selected: selectedPhoto,
    }));
  }

  const cards = state.comparisonMode ? state.comparison : undefined;
  const card = !state.comparisonMode
    ? photos.find((photo) => state.selected?.id == photo.id)
    : undefined;
  const selectedCell: number = state.comparisonMode
    ? state.selectedCell
    : undefined;

  return (
    <FullScreenDialog
      onClose={handleClickClose}
      maxWidth="lg"
      align="top"
      topBarActions={
        <ProgressDialogActions
          onSwitchMode={toggleComparisonMode}
          onSelect={handleSelect}
          state={state}
          setState={setState}
        />
      }
      {...other}
    >
      <DialogContent className={s.content}>
        <Grid2 container justifyContent="center" className={s.grid}>
          {cards &&
            cards.map((x, indx) =>
              x ? (
                <ProgressDialogCard
                  title={cardTitles[indx]}
                  key={indx}
                  showWeight={state.showWeight}
                  timeAgoFormat={state.timeAgoFormat}
                  onClick={handleCardClick}
                  selected={selectedCell === indx}
                  cell={indx}
                  selectedPhoto={x}
                  comparisonMode={state.comparisonMode}
                />
              ) : (
                <ProgressDialogCardSkeleton
                  title={cardTitles[indx]}
                  onClick={handleCardClick}
                  selected={selectedCell === indx}
                  cell={indx}
                  comparisonMode={state.comparisonMode}
                />
              ),
            )}
          {card && (
            <ProgressDialogCard
              showWeight={state.showWeight}
              timeAgoFormat={state.timeAgoFormat}
              selectedPhoto={card}
              comparisonMode={state.comparisonMode}
            />
          )}
        </Grid2>
        <Box className={s.actions}>
          <ProgressDialogPhotoSelector
            photos={photos}
            state={state}
            setState={setState}
            onSelect={handleSelect}
            loadNextPageRef={loadNextPageRef}
            isLoading={isFetchingNextPage}
            loadingInitial={isInitialLoading}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
          />
        </Box>
      </DialogContent>
    </FullScreenDialog>
  );
}
