import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { CurriculumState } from "../../../curriculum-slice";
import { CurriculumComponent } from "../../../../types";

export const overrideComponentAction = (
  state: Draft<CurriculumState>,
  action: PayloadAction<{
    baseComponentId: number;
    weekId: number;
    days: string;
    data: Partial<CurriculumComponent>;
    noDiscard?: boolean;
  }>,
) => state;
