/**
 * DialogContext: Manages the state and behavior of a dialog component.
 *
 * @property {boolean} isOpen - Indicates whether the dialog is currently open.
 * @property {string|null} componentSlug - Identifies the current component associated with the dialog (or `null` if none).
 *
 * @method openDialog
 * @param {string} slug - The component slug to associate with the dialog.
 * @description Opens the dialog and sets the associated component slug.
 *
 * @method closeDialog
 * @param {boolean} [preventReturn=false] - If true, ensures the user cannot navigate back to the dialog state.
 * @description Closes the dialog, clears the component slug, and optionally prevents navigation back to the dialog state.
 */

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCurriculumSelector } from "../redux/hooks";
import { selectComponentById } from "../redux/curriculum/selectors/curriculum";

interface DialogContextType {
  isOpen: boolean;
  componentSlug: string | null;
  openDialog: (
    slug: string,
    clickedComponent?: {
      week: string;
      day: string;
    },
  ) => void;
  closeDialog: (preventReturn?: boolean) => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

const COMPONENT_SEARCH_PARAM = "component";
export const DAY_SEARCH_PARAM = "day";
export const WEEK_SEARCH_PARAM = "week";

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const componentSearchParam = searchParams.get(COMPONENT_SEARCH_PARAM);

  //
  //
  // STATES

  const [isOpen, setIsOpen] = useState(false);
  const [componentSlug, setComponentSlug] = useState<string | null>(null);

  //
  //
  // METHODS

  const openDialog = (
    slug: string,
    clickedComponent?: { week: string; day: string },
  ) => {
    const componentParam = `?${COMPONENT_SEARCH_PARAM}=${slug}`;
    setIsOpen(true);
    setComponentSlug(slug);

    if (!clickedComponent) {
      navigate(componentParam, { replace: true });
      return;
    }

    const { week, day } = clickedComponent;
    const weekParam = `&${WEEK_SEARCH_PARAM}=${week}`;
    const dayParam = `&${DAY_SEARCH_PARAM}=${day}`;
    const link = `${componentParam}${clickedComponent ? weekParam + dayParam : ""}`;
    navigate(link, { replace: true });
  };

  const closeDialog = (preventReturn?: boolean) => {
    setIsOpen(false);
    setComponentSlug(null);

    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete(COMPONENT_SEARCH_PARAM);
    setSearchParams(updatedParams, { replace: preventReturn });
  };

  //
  //
  // ADDITIONAL LOGIC

  // Open dialog if url contains component search param
  useEffect(() => {
    !isOpen && componentSearchParam && openDialog(componentSearchParam);
  }, [componentSearchParam]);

  // Change temp dialog slug to real one
  let id = null;
  if (componentSlug?.endsWith("-tempslug")) {
    id = parseInt(componentSlug?.split("-tempslug")[0]);
  }
  const componentByTempSlug = useCurriculumSelector(selectComponentById(id));

  useEffect(() => {
    // If componentSlug differs from slug at store,
    // and slug at store is no longer a temp slug -
    // reopen dialog with new slug
    if (
      componentSlug &&
      componentByTempSlug &&
      componentByTempSlug.slug &&
      componentByTempSlug.slug != componentSlug &&
      !componentByTempSlug.slug.endsWith("-tempslug")
    ) {
      openDialog(componentByTempSlug.slug);
    }
  }, [componentByTempSlug, componentSlug, openDialog]);

  return (
    <DialogContext.Provider
      value={{ isOpen, componentSlug, openDialog, closeDialog }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};
