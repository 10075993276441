import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  collapseBox: {
    marginTop: theme.spacing(1),
    whiteSpace: "pre-wrap",
  },

  expandIcon: {
    cursor: "pointer",
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },

  close: {
    fontSize: 16,
  },
}));

export enum AlertDisplayMode {
  COLLAPSE = "collapse",
  DIALOG = "dialog",
}

export type ActiveClientThresholdAlertProps =
  | {
      mode: AlertDisplayMode.COLLAPSE;
      alertTitle: string;
      fullText: string;
      dialogTitle?: never;
    }
  | {
      mode: AlertDisplayMode.DIALOG;
      alertTitle: string;
      fullText: string;
      dialogTitle: string;
    };

export function ActiveClientThresholdAlert(
  props: ActiveClientThresholdAlertProps,
) {
  const { alertTitle, fullText, dialogTitle, mode } = props;
  const [open, setOpen] = useState(false);
  const s = useStyles();

  const handleToggle = () => setOpen((prev) => !prev);
  const handleCloseDialog = () => setOpen(false);

  return (
    <>
      <Alert
        severity="info"
        icon={false}
        className={s.root}
        onClick={handleToggle}
      >
        <Box className={s.header}>
          <AlertTitle sx={{ mb: 0 }}>{alertTitle}</AlertTitle>
          {mode === AlertDisplayMode.COLLAPSE ? (
            open ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )
          ) : (
            <InfoIcon className={s.expandIcon} />
          )}
        </Box>

        {mode === AlertDisplayMode.COLLAPSE && (
          <Collapse in={open}>
            <Box className={s.collapseBox}>{fullText}</Box>
          </Collapse>
        )}
      </Alert>

      {mode === AlertDisplayMode.DIALOG && (
        <Dialog open={open} onClose={handleCloseDialog} disableScrollLock>
          <DialogTitle className={s.header}>{dialogTitle}</DialogTitle>
          <DialogContent>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>{fullText}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className={s.close}
              onClick={handleCloseDialog}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
