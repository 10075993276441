import clsx from "clsx";
import React from "react";
import {
  Box,
  Select,
  MenuItem,
  ListItemText,
  SelectProps,
  Grid2,
  Grid2Props,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { SearchField } from "../fields/SearchField";
import {
  programSortOptions,
  ProgramSort,
  ProgramFilterFolder,
} from "../../constants";
import { LayoutGrid, List as LayoutList } from "lucide-react";

import { CoachProgramCardDisplay } from "./CoachProgramCard";
import { ProgramFolderDto } from "@growth-machine-llc/stridist-api-client";
import ToggleIconButtonGroup from "../button/ToggleIconButtonGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  search: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      minWidth: 350,
    },
  },

  select: {
    backgroundColor: theme.palette.background.paper,

    ...filterInputHeightProps,

    "& [role=combobox]": {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  },

  inputActive: {
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },

  sort: {},

  view: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  viewList: {
    padding: theme.spacing(1),
  },

  primaryText: {
    fontWeight: 500,
    minWidth: theme.spacing(20),

    "$sort [role=combobox] &": {
      "&::before": {
        content: '"Sort by: "',
      },
    },

    "$view [role=combobox] &": {
      display: "none !important",
    },
  },

  icon: {
    color: theme.palette.text.secondary,
    minWidth: "auto",

    fill: theme.palette.text.secondary,
    "&.active": {
      fill: theme.palette.primary.main,
    },

    "& svg": {
      width: 20,
      height: 20,
    },

    "[role=combobox] & svg": {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },

  sortOption: {
    fontWeight: 500,
  },
}));

const filterInputHeightProps = {
  minHeight: 40,
  height: 40,
};

export interface CoachProgramsFilterProps extends Grid2Props {
  programFolders?: ProgramFolderDto[];

  query: string;
  folderId: ProgramFilterFolder;
  sortKey: ProgramSort;

  onChangeQuery?: (query: string) => void;
  onChangeFolder?: (folder: ProgramFilterFolder) => void;
  onChangeSortKey?: (sortKey: ProgramSort) => void;

  display?: CoachProgramCardDisplay;
  onChangeDisplay?: (display: CoachProgramCardDisplay) => void;

  defaultValues: {
    query: string;
    folderId: ProgramFilterFolder;
    sortKey: ProgramSort;
  };
}

export function CoachProgramsFilter(props: CoachProgramsFilterProps) {
  const s = useStyles();
  const {
    className,
    programFolders,
    query,
    folderId,
    sortKey,
    onChangeQuery,
    onChangeFolder,
    onChangeSortKey,
    display = CoachProgramCardDisplay.list,
    onChangeDisplay,
    defaultValues: {
      query: defaultQuery,
      folderId: defaultFolderId,
      sortKey: defaultSortKey,
    },
    ...other
  } = props;

  const handleChangeQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const query = event.currentTarget.value;

      if (onChangeQuery) {
        onChangeQuery(query);
      }
    },
    [onChangeQuery],
  );

  const handleChangeFolder = React.useCallback<SelectProps["onChange"]>(
    (event) => {
      const value = event.target.value;

      const folder = value === "" ? value : (value as ProgramFilterFolder);
      if (onChangeFolder) {
        onChangeFolder(folder);
      }
    },
    [onChangeFolder],
  );

  const handleChangeSort = React.useCallback(
    (event) => {
      const sortKey = event.target.value as ProgramSort;

      if (onChangeSortKey) {
        onChangeSortKey(sortKey);
      }
    },
    [onChangeSortKey],
  );

  const handleChangeDisplay = React.useCallback(
    (value: CoachProgramCardDisplay) => {
      if (onChangeDisplay) {
        onChangeDisplay(value);
      }
    },
    [onChangeDisplay],
  );

  return (
    <Grid2
      container
      className={clsx(s.root, className)}
      sx={{
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        rowGap: 2,
        justifyContent: "space-between",
      }}
      {...other}
    >
      <Grid2
        size={{ xs: 12, md: 4 }}
        sx={{
          columnGap: 2,
          display: "flex",
        }}
      >
        <SearchField
          className={clsx(s.search, query !== defaultQuery && s.inputActive)}
          variant="outlined"
          fullWidth
          placeholder="Search programs"
          value={query}
          onChange={handleChangeQuery}
          sx={{
            "& .MuiOutlinedInput-root": {
              ...filterInputHeightProps,

              "& fieldset": {
                borderRadius: (theme) => theme.shape.borderRadius / 3,
              },
            },
          }}
        />
        <Select
          className={clsx(
            s.select,
            defaultFolderId !== folderId && s.inputActive,
          )}
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            classes: {
              list: s.viewList,
            },
          }}
          value={folderId}
          onChange={handleChangeFolder}
          disabled={!programFolders}
          displayEmpty
        >
          <MenuItem value={null}>
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="Any folder"
            />
          </MenuItem>
          <MenuItem value={""}>
            <ListItemText
              classes={{ primary: s.primaryText }}
              primary="Ungrouped"
            />
          </MenuItem>
          {(programFolders || []).map((programFolder) => {
            return (
              <MenuItem key={programFolder.id} value={programFolder.id}>
                <ListItemText
                  classes={{ primary: s.primaryText }}
                  primary={programFolder.name}
                />
              </MenuItem>
            );
          })}
        </Select>
      </Grid2>
      <Grid2
        size={{ xs: 12, md: 4 }}
        sx={{
          columnGap: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Select
          className={clsx(
            s.select,
            s.sort,
            defaultSortKey !== sortKey && s.inputActive,
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          variant="outlined"
          fullWidth
          value={sortKey}
          onChange={handleChangeSort}
        >
          {Object.entries(programSortOptions).map(([key, label]) => (
            <MenuItem className={s.sortOption} key={key} value={key}>
              <ListItemText
                classes={{ primary: s.primaryText }}
                primary={label}
              />
            </MenuItem>
          ))}
        </Select>

        <ToggleIconButtonGroup
          className={clsx(s.view, s.icon)}
          value={display}
          onChange={handleChangeDisplay}
          options={[
            {
              value: CoachProgramCardDisplay.grid,
              icon: (
                <LayoutGrid
                  className={clsx(s.icon, {
                    active: display === CoachProgramCardDisplay.grid,
                  })}
                />
              ),
            },
            {
              value: CoachProgramCardDisplay.list,
              icon: (
                <LayoutList
                  className={clsx(s.icon, {
                    active: display === CoachProgramCardDisplay.list,
                  })}
                />
              ),
            },
          ]}
        />
      </Grid2>
    </Grid2>
  );
}
