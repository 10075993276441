import { Fade, FormControl, Select, Typography, useTheme } from "@mui/material";
import { keyframes } from "@mui/system";
import React, { ReactNode } from "react";
import GenericScheduleTitle, {
  ITitleSwitchProps,
} from "./GenericScheduleTitle";
import { useSidebar } from "../../../../../contexts/CurriculumSidebarContext";
import { selectComponentBySlug } from "../../../../../redux/curriculum/selectors/curriculum";
import { getColorByComponentType } from "../../../../program-calendar/utils/common";
import { useCurriculumSelector } from "../../../../../redux/hooks";
import MinimizedTooltip from "../../../../tooltip/MinimizedTooltip";

export interface IScheduleSelectProps<T> {
  value: T;
  onChange: (result: T) => void;
  disabled?: boolean;
}

interface IGenericScheduleSelectProps<T> {
  title: string;
  children?: ReactNode;
  value?: T;
  onChange?: (result: T) => void;
  text?: string;
  disabled?: boolean;
  flashInputs?: boolean;
  tooltipTitle?: React.ReactNode;
}

const GenericScheduleSelect = <T,>(
  props: IGenericScheduleSelectProps<T> & ITitleSwitchProps,
) => {
  const {
    title,
    children,
    value,
    onChange,
    checked,
    enableSwitch = true,
    handleSwitchOnChange,
    text,
    disabled,
    flashInputs,
    tooltipTitle,
  } = props;
  const theme = useTheme();

  const flashBackground = keyframes`
    0% {
      background-color: ${theme.palette.common.white};
    }
    50% {
      background-color: ${theme.palette.grey[200]};
    }
    100% {
      background-color: ${theme.palette.common.white};
    }
  `;

  // TODO maybe move color to directly to useSidebar mixed with `enable colors`?
  const { componentSlug } = useSidebar();
  const component = useCurriculumSelector(selectComponentBySlug(componentSlug));

  return (
    <>
      <MinimizedTooltip title={tooltipTitle}>
        <FormControl
          sx={{ minWidth: 120, width: 1 }}
          size="small"
          disabled={disabled}
        >
          <GenericScheduleTitle
            title={title}
            color={
              component
                ? getColorByComponentType(component.type)
                : theme.palette.primary.main
            }
            checked={checked}
            enableSwitch={enableSwitch}
            handleSwitchOnChange={handleSwitchOnChange}
            disabled={disabled}
          />
          <Fade in={!!text} exit={false} timeout={700} unmountOnExit>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              color={disabled ? theme.palette.divider : theme.palette.grey[400]}
              height={40}
            >
              {text}
            </Typography>
          </Fade>
          <Fade in={!text} exit={false} timeout={700} unmountOnExit>
            <Select
              value={value}
              onChange={(e) => onChange(e.target.value as T)}
              displayEmpty
              sx={{
                "& .MuiOutlinedInput-input": {
                  paddingRight: "24px !important",
                },
                ...(flashInputs && {
                  animation: `${flashBackground} 1s ease-in-out`,
                }),
              }}
            >
              {children}
            </Select>
          </Fade>
        </FormControl>
      </MinimizedTooltip>
    </>
  );
};

export default GenericScheduleSelect;
