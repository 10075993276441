import clsx from "clsx";
import React from "react";
import {
  TableRow,
  TableRowProps,
  TableCell,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs, { Dayjs } from "dayjs";
import { ProgramAdminDto } from "@growth-machine-llc/stridist-api-client";
import { ADMIN_USERS_ROUTE } from "../../routes/routes";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},

  link: {
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },

  value: {
    fontSize: 14,
    fontWeight: 500,
  },

  subText: {
    color: theme.palette.text.secondary,
    fontWeight: "normal",
  },
}));

export interface ProgramsTableRowProps extends TableRowProps {
  program: ProgramAdminDto;
}

export function ProgramsTableRow(props: ProgramsTableRowProps) {
  const { className, program, ...other } = props;
  const s = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const handleForwardClick = React.useCallback(() => {
    navigate(`${location.pathname}/${program.slug}`);
  }, [program.slug]);

  const LabeledDate = ({ label, date }: { label: string; date: Dayjs }) => (
    <Typography
      variant="caption"
      sx={{ whiteSpace: "nowrap" }}
      className={s.subText}
    >
      <b>{label}:</b> {date ? dayjs(date).format("MMM DD, YYYY") : "-"}
    </Typography>
  );

  return (
    <TableRow className={clsx(s.root, className)} hover {...other}>
      <TableCell component="th" scope="row">
        <Typography>{program.name}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>{program.id}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>{program.legacyEntityId ? "migrated" : "new"}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>
          {program.status.toString().toLowerCase().slice(0, 3)}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>
          {program.length === program.weeksCount
            ? program.length.toString()
            : `${program.length} (w=${program.weeksCount})`}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>{program.componentDuplicatesDueRepeatCount}</Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography>{program.componentDuplicatesSameWeekCount}</Typography>
      </TableCell>
      <TableCell align="center">
        <Link
          href={`${ADMIN_USERS_ROUTE}/${program.author?.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className={clsx(s.link)}
        >
          <Typography className={clsx(s.value)}>
            {`${program.author?.id} - "${program.author?.displayName}"`}
          </Typography>
        </Link>
      </TableCell>
      <TableCell align="center">
        <LabeledDate
          label="Updated"
          date={dayjs(program.lastModified?.toString())}
        />
        <br />
        <LabeledDate
          label="Created"
          date={dayjs(program.created?.toString())}
        />
      </TableCell>
      <TableCell align="center">
        <IconButton
          children={<ArrowForwardRounded />}
          onClick={handleForwardClick}
          size="large"
        />
      </TableCell>
    </TableRow>
  );
}
