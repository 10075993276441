import { useOptimisticUpdateMutation } from "../../../hooks/useOptimisticUpdateMutation";
import {
  ExerciseAssetDto,
  ICreateAdminExerciseAssetCommand,
  PaginatedListOfAdminExerciseAssetDto,
} from "@growth-machine-llc/stridist-api-client";

import { ADMIN_EXERCISES_ASSETS_KEY_QUERY_KEY } from "../ExercisesTable";
import AdminService from "../../../services/AdminService";

export function useAdminCreateExerciseLibraryAsset() {
  return useOptimisticUpdateMutation({
    queryKey: [ADMIN_EXERCISES_ASSETS_KEY_QUERY_KEY],
    mutationFn: AdminService.createAdminExerciseAsset,
    successToastMessage: "Library asset created.",
    optimisticUpdater: {
      updateFn: (
        oldData: PaginatedListOfAdminExerciseAssetDto,
        variables: ICreateAdminExerciseAssetCommand,
        tempId: number,
      ) => {
        const newItem = { ...variables, id: tempId };
        oldData.items.unshift(ExerciseAssetDto.fromJS(newItem));
        oldData.totalCount += 1;
        return oldData;
      },
    },
    invalidateQueryOptions: {
      enabled: true,
      refetchType: "none",
    },
    exactQueryKey: false,
    successUpdater: {
      updateFn: (oldData, variables, tempId) => {
        oldData.items = oldData.items?.map((asset) => {
          if (asset.id === tempId) {
            asset.id = variables;
          }
          return asset;
        });
        return oldData;
      },
    },
  });
}
