import React, { createContext, useContext, useState, ReactNode } from "react";
import { usePopupState } from "material-ui-popup-state/hooks";

export enum DeleteConfirmationMode {
  WEEK = "week",
  COMPONENT = "component",
}

interface DeleteConfirmationContextType {
  isOpen: boolean;
  deleteMode: DeleteConfirmationMode | null;
  openDialog: (mode: DeleteConfirmationMode, callback: () => void) => void;
  closeDialog: () => void;
  confirmDelete: () => void;
  popupState: ReturnType<typeof usePopupState>;
}

const DeleteConfirmationContext = createContext<
  DeleteConfirmationContextType | undefined
>(undefined);

export const DeleteConfirmationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteMode, setDeleteMode] = useState<DeleteConfirmationMode | null>(
    null,
  );
  const [callback, setCallback] = useState<(() => void) | null>(null);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "delete-confirmation-popup",
  });

  const openDialog = (mode: DeleteConfirmationMode, callback: () => void) => {
    setIsOpen(true);
    setDeleteMode(mode);
    setCallback(() => callback || null);
    popupState.open();
  };

  const closeDialog = () => {
    setIsOpen(false);
    setDeleteMode(null);
    setCallback(null);
    popupState.close();
  };

  const confirmDelete = () => {
    if (callback) {
      callback();
    }
    closeDialog();
  };

  return (
    <DeleteConfirmationContext.Provider
      value={{
        isOpen,
        deleteMode,
        openDialog,
        closeDialog,
        confirmDelete,
        popupState,
      }}
    >
      {children}
    </DeleteConfirmationContext.Provider>
  );
};

export const useDeleteConfirmation = (): DeleteConfirmationContextType => {
  const context = useContext(DeleteConfirmationContext);
  if (!context) {
    throw new Error(
      "useDeleteConfirmation must be used within a DeleteConfirmationProvider",
    );
  }
  return context;
};
