import React from "react";
import { NavigationItem, UserRole } from "../../../constants";
import {
  CLIENT_GROUP_MEMBERS_ROUTE,
  CLIENT_GROUP_POST_ROUTE,
  COACH_GROUP_MEMBERS_ROUTE,
  COACH_GROUP_POST_ROUTE,
  COACH_GROUP_SETTINGS_ROUTE,
} from "../../routes";
import InlineCounter from "../../../components/loading/InlineCounter";

export const groupNavigation = (
  slug: string,
  role: UserRole,
  membersCount?: number,
): NavigationItem[] =>
  role === UserRole.COACH
    ? [
        {
          name: "Posts",
          link: COACH_GROUP_POST_ROUTE.replace(":slug", slug),
        },
        {
          name: (
            <InlineCounter
              label="Members"
              count={membersCount}
              loading={!membersCount && membersCount !== 0}
            />
          ),
          link: COACH_GROUP_MEMBERS_ROUTE.replace(":slug", slug),
        },
        {
          name: "Settings",
          link: COACH_GROUP_SETTINGS_ROUTE.replace(":slug", slug),
        },
      ]
    : [
        {
          name: "Posts",
          link: CLIENT_GROUP_POST_ROUTE.replace(":slug", slug),
        },
        {
          name: `Members (${membersCount ?? 0})`,
          link: CLIENT_GROUP_MEMBERS_ROUTE.replace(":slug", slug),
        },
      ];
