import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, ClickAwayListener } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ActionButton } from "../button/ActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid",
    borderColor: theme.palette.border.secondary,
    borderRadius: theme.spacing(1),
    paddingBottom: theme.spacing(3),

    "&$expanded": {
      boxShadow: theme.shadows[4],
    },
  },

  rootSideBar: {
    border: "unset",
    "&$expanded": {
      boxShadow: "unset",
    },
  },

  summary: {
    display: "flex",
    padding: theme.spacing(3, 3, 0, 3),
    alignItems: "center",
    gap: theme.spacing(2),
  },

  summarySidebar: {
    gap: theme.spacing(2),
    flexDirection: "column",
    "$expanded &": {
      flexDirection: "row",
    },
  },

  label: {
    fontSize: 20,
    fontWeight: "bold",
  },

  header: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: "bold",
    transition: theme.transitions.create(["opacity"]),

    "$expanded &": {
      opacity: 0,
    },
  },

  editButton: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: "16px",
    height: 40,
    width: 106,
    padding: "initial",
  },

  content: {
    padding: theme.spacing(0, 3),
    transition: theme.transitions.create(["opacity", "max-height"]),
    overflow: "hidden",
    opacity: 0,
    maxHeight: 0,

    "$expanded &": {
      maxHeight: "100vh",
      opacity: 1,
    },
  },

  expanded: {},
}));

export interface AccordionProps extends Omit<BoxProps, "onChange" | "header"> {
  label?: React.ReactNode;
  header?: React.ReactNode;
  repeatsBadge?: () => React.JSX.Element;
  open?: boolean;
  onChange?: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
  layout?: "flat" | "expansion" | "sidebar";
  disableClickAwayListener?: boolean;
}

export function Accordion(props: AccordionProps) {
  const {
    className,
    label,
    header,
    onChange,
    children,
    open,
    layout,
    disableClickAwayListener,
    repeatsBadge,
    ...other
  } = props;

  const isSidebar = props.layout === "sidebar";
  const s = useStyles();
  const [expanded, setExpanded] = React.useState(Boolean(open));

  // Ensure that expanded is tied to open prop if it is set
  React.useEffect(() => {
    if (open !== undefined && expanded !== open) {
      setExpanded(open);
    }
  }, [expanded, open]);

  const handleExpandedChange = React.useCallback(
    (event, value) => {
      setExpanded(value);

      if (onChange) {
        onChange(event, value);
      }
    },
    [onChange],
  );

  const handleClickAway = React.useCallback(
    (event) => {
      handleExpandedChange(event, false);
    },
    [handleExpandedChange],
  );

  const expand = React.useCallback(
    (event) => {
      handleExpandedChange(event, true);
    },
    [handleExpandedChange],
  );

  return (
    <ClickAwayListener
      onClickAway={disableClickAwayListener ? () => {} : handleClickAway}
    >
      <Box
        className={clsx(
          s.root,
          className,
          { [s.expanded]: expanded },
          isSidebar && s.rootSideBar,
        )}
        {...other}
      >
        <Box className={clsx(s.summary, isSidebar && s.summarySidebar)}>
          {label && (
            <Typography variant="h5" className={s.label}>
              {label}
            </Typography>
          )}

          {header && (
            <Typography variant="body2" className={s.header}>
              {header}
            </Typography>
          )}

          {repeatsBadge && repeatsBadge()}

          {!expanded && (
            <ActionButton
              className={s.editButton}
              children="EDIT"
              onClick={expand}
            />
          )}
        </Box>
        <Box className={s.content}>{children}</Box>
      </Box>
    </ClickAwayListener>
  );
}
