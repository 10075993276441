import { Card, CardProps, CardHeader, Grid2 } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React, { useMemo } from "react";

export interface ClientSkeletonCardProps extends CardProps {}

export function ClientSkeletonCard(props: ClientSkeletonCardProps) {
  const skeletonWidth = useMemo(() => Math.floor(Math.random() * 100) + 70, []);
  return (
    <Card {...props}>
      <CardHeader
        sx={{ "&.MuiCardHeader-root": { py: 2, px: 3 } }}
        avatar={
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        }
        title={
          <Skeleton
            animation="wave"
            width={`${skeletonWidth}px`}
            height={"20px"}
            sx={{ ml: 0.5 }}
          />
        }
      />
    </Card>
  );
}
