import clsx from "clsx";
import React from "react";
import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ReactComponent as Image } from "../../icons/UndrawAddPost.svg";
import NoTemplates from "../../icons/NoTemplates";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      marginTop: theme.spacing(2),
    },
  },

  picture: {
    margin: theme.spacing(3),
  },

  title: {
    fontSize: 24,
    fontWeight: 700,
    textAlign: "center",
  },

  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
  },
}));

export interface ComponentTemplatePreviewEmptyProps extends BoxProps {}

export function ComponentTemplatePreviewEmpty(
  props: ComponentTemplatePreviewEmptyProps,
) {
  const { className, ...other } = props;
  const s = useStyles();
  const theme = useTheme();
  return (
    <Box className={clsx(s.root, className)} {...other}>
      <NoTemplates fill={theme.palette.primary.main} className={s.picture} />
      <Typography className={s.title}>No more blank canvas</Typography>
      <Typography className={s.subtitle}>
        Select a template to jump-start your program creation
      </Typography>
    </Box>
  );
}
