import clsx from "clsx";
import React, { ReactElement } from "react";
import {
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
  Radio,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { colorSystem } from "../../theme";
import { polyfillCSS } from "../../utils/css";

import { FieldsGroup, FieldsGroupProps } from "./FieldsGroup";
import { FieldError } from "./FieldError";

const useStyles = makeStyles((theme) => ({
  root: {},

  radioGroup: {
    display: "flex",
    marginRight: theme.spacing(-2),
  },

  controlLabel: {
    backgroundColor: theme.palette.background.paper,
    borderWidth: 1,
    borderRadius: theme.spacing(0.5),
    borderStyle: "solid",
    borderColor: colorSystem.border1,
    padding: theme.spacing(1),

    width: polyfillCSS(`calc(100% / 2 - ${theme.spacing(2)} * 2)`),
    margin: theme.spacing(0, 2, 2, 0),
    flexGrow: 1,
    flexShrink: 0,
  },

  radioLabel: {
    fontWeight: 500,
  },

  error: {
    borderColor: theme.palette.error.main,
  },
}));

export type RadioGroupOption<T> = {
  label: string;
  value: T;
};

export interface RadioGroupFieldProps<T>
  extends RadioGroupProps,
    Pick<FieldsGroupProps, "label" | "error"> {
  options: RadioGroupOption<T>[];
  optionElement?: (option: any) => ReactElement;
  fieldGroupClassName?: any;
  controlLabelStyle?: any;
  controlLabelSelectedStyle?: any;
  errorStyle?: any;
  hideErrorIcon?: boolean;
  radioLabelStyle?: any;
  disabled?: boolean;
}

export function RadioGroupField<T extends string>(
  props: RadioGroupFieldProps<T>,
) {
  const {
    className: radioGroupClassName,
    fieldGroupClassName,
    value,
    disabled,
    options,
    optionElement,
    label,
    controlLabelStyle,
    controlLabelSelectedStyle,
    error,
    errorStyle,
    hideErrorIcon,
    radioLabelStyle,
    ...other
  } = props;
  const s = useStyles();

  return (
    <FieldsGroup className={clsx(s.root, fieldGroupClassName)} label={label}>
      <RadioGroup
        className={clsx(s.radioGroup, radioGroupClassName)}
        value={value}
        {...other}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            data-option={option.value}
            value={option.value}
            classes={{
              root: clsx(
                s.controlLabel,
                controlLabelStyle,
                value === option.value && controlLabelSelectedStyle,
                error && s.error,
              ),
              label: clsx(s.radioLabel, radioLabelStyle),
            }}
            control={<Radio color="primary" disabled={disabled} />}
            label={optionElement ? optionElement(option) : option.label}
          />
        ))}
      </RadioGroup>
      {Boolean(error) && (
        <FieldError hideIcon={hideErrorIcon} className={errorStyle}>
          {error}
        </FieldError>
      )}
    </FieldsGroup>
  );
}
