import clsx from "clsx";
import React from "react";
import {
  ListItem,
  ListItemProps,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
  ListItemIcon,
  ButtonBase,
} from "@mui/material";
import { MoreHoriz, ArrowForward, Loop } from "@mui/icons-material";
import { darken, Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  useDrop,
  DropTargetMonitor,
  useDrag,
  DragSourceMonitor,
} from "react-dnd";
import {
  ComponentType,
  ComponentRepeat,
  ComponentStatus,
  SidebarTabs,
} from "../../constants";
import { WeekComponentMenu } from "../menu/WeekComponentMenu";
import { useMediaMobile } from "../../hooks/useMediaMobile";
import { useShowCovers } from "../../hooks/useShowCovers";
import { getDaysInfo, getComponentSpecifics } from "../../utils/component";
import { ReactComponent as DragIcon } from "../../icons/Drag.svg";
import { WeekComponentSchedule } from "./WeekComponentSchedule";
import { FlagTriangleRight, Lock } from "lucide-react";
import MinimizedTooltip from "../tooltip/MinimizedTooltip";
import { ProgramDetailsViewMode } from "../program/ProgramDetailsViewButton";
import { CurriculumComponent } from "../../redux/types";
import { ComponentIcon } from "../program-component/ComponentIcon";
import { useCurriculumSelector } from "../../redux/hooks";
import { selectProgramImage } from "../../redux/curriculum/selectors/curriculum";
import { ComponentClickHandlerType } from "../program/ProgramDetails";
import { useSidebar } from "../../contexts/CurriculumSidebarContext";
import { getColorByComponentType } from "../program-calendar/utils/common";
import { useEnableColors } from "../../hooks/useEnableColors";

type StyleProps = {
  image: string;
  color: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  wrapper: {
    display: "flex",
    padding: theme.spacing(1, 0),
  },

  root: {
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      paddingBlock: theme.spacing(3),
      paddingRight: theme.spacing(8.5),
    },

    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    height: "100%",

    "&:hover + .MuiListItemSecondaryAction-root $menuButton": {
      display: "initial",
    },

    "& + .MuiListItemSecondaryAction-root:hover $menuButton": {
      display: "initial",
    },
  },

  override: {
    borderStyle: "dotted",
    borderWidth: 2,
  },

  container: {
    flexGrow: 1,
  },

  cover: {
    backgroundImage: ({ image }) => `url("${image}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: theme.spacing(20),
    height: theme.spacing(10.75),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.05))",
  },

  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  icon: {
    marginRight: theme.spacing(0.5),
  },

  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: theme.spacing(-0.5),
    textOverflow: "ellipsis",
    overflow: "hidden",

    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(10),
    },
  },

  subtitle: {
    fontSize: 14,
    fontWeight: 500,
  },

  statusText: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginInline: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },

  lockIcon: {
    "&:disabled": {
      color: theme.palette.text.secondary,
    },
  },

  secondaryTextWrapper: {
    display: "flex",
    alignItems: "center",
  },

  daysInfo: {
    padding: theme.spacing(0, 0.5),
    margin: theme.spacing(0, -0.5),
  },

  repeatIcon: {
    fontSize: theme.typography.body1.fontSize,
    marginLeft: theme.spacing(0.5),
  },

  dragButton: {
    color: theme.palette.border.primary,
    cursor: "grab",
    marginLeft: theme.spacing(-1.5),

    "&:active": {
      cursor: "grabbing",
    },

    "&:hover": {
      backgroundColor: "inherit",
    },

    "& svg": {
      width: 18,
      height: 18,
    },

    "& svg [stroke]": {
      stroke: theme.palette.quote,
    },

    "& svg [fill]": {
      fill: theme.palette.quote,
    },
  },

  isDragging: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: 0,
    height: 0,
    "& *": {
      display: "none",
    },
  },

  draggable: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
}));

interface DragItem {
  type: string;
  index: number;
  week: number;
  node: Node;
}

export interface WeekComponentListItemProps extends ListItemProps {
  componentData: CurriculumComponent;
  week: number;
  onMove?: (dragIndex: number, hoverIndex: number) => void;
  onMoveEnd?: () => void;
  index?: number;
  id?: string;
  weekId?: number;
  componentClickHandler: ComponentClickHandlerType;
  componentDoubleClickHandler: ComponentClickHandlerType;
}

export function WeekComponentListItem(props: WeekComponentListItemProps) {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    className,
    componentData,
    onMove,
    onMoveEnd,
    id,
    weekId,
    componentClickHandler,
    componentDoubleClickHandler,
  } = props;
  const defaultCover = useCurriculumSelector(selectProgramImage);
  const component = componentData;
  const mobile = useMediaMobile();
  const showCovers = useShowCovers();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(
    null,
  );

  const { componentSlug: currentSidebarComponent, openSidebar } = useSidebar();
  const color = getColorByComponentType(component.type);
  const s = useStyles({ image: component?.image ?? defaultCover, color });
  const enableColors = useEnableColors();

  const { typeName } = React.useMemo(() => {
    return getComponentSpecifics(component.type as ComponentType);
  }, [component.type]);

  const menuId = `component-${component.slug}-menu`;
  const scheduleId = `component-${component.slug}-schedule`;

  const IS_OVERRIDE = component.baseComponentId;

  const handleScheduleMenu = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      openSidebar(component.slug, SidebarTabs.SCHEDULE);
    },
    [],
  );

  const closeMenu = React.useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleMoreButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setMenuAnchorEl(event.currentTarget);
    },
    [],
  );

  const secondaryText = React.useMemo(() => {
    const daysInfo = getDaysInfo(
      component.type as ComponentType,
      JSON.parse(component.days),
    );
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        {typeName} •{" "}
        {mobile ? (
          daysInfo
        ) : (
          <ButtonBase
            className={s.daysInfo}
            aria-controls={scheduleId}
            aria-haspopup="true"
            onClick={handleScheduleMenu}
            sx={{
              borderRadius: 2,
              "&:hover": {
                backgroundColor: theme.palette.grey[200],
              },
            }}
          >
            {daysInfo}
          </ButtonBase>
        )}
        {IS_OVERRIDE && (
          <FlagTriangleRight size={14} style={{ marginLeft: -6 }} />
        )}
      </Box>
    );
  }, [
    component.type,
    component.days,
    typeName,
    mobile,
    s.daysInfo,
    scheduleId,
    handleScheduleMenu,
  ]);

  const itemType = "WEEK_COMPONENT";
  const ref = React.useRef<HTMLLIElement>(null);
  const dragRef = React.useRef<HTMLDivElement>(null);
  const index = props.index;

  const [, drop] = useDrop({
    accept: itemType,

    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current || index === undefined) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      if (props.week !== item.week) {
        return;
      }

      const hoverBoundingRect = ref.current!.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();

      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (onMove) {
        onMove(dragIndex, hoverIndex);

        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    item: {
      index: props.index,
      week: props.week,
    },
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end() {
      if (onMoveEnd) {
        onMoveEnd();
      }
    },
  });

  drag(dragRef);
  drop(ref);
  preview(ref);

  if (!component) {
    return null;
  }

  return (
    <Box className={s.wrapper}>
      {!mobile && showCovers && component.type !== ComponentType.MESSAGE && (
        <Box className={s.cover} />
      )}

      <ListItem
        classes={{
          root: clsx(
            className,
            s.root,
            isDragging && s.isDragging,
            IS_OVERRIDE && s.override,
          ),
          container: s.container,
        }}
        ref={ref}
        onClick={() => componentClickHandler(component.slug)}
        onDoubleClick={() => componentDoubleClickHandler(component.slug)}
        sx={{
          borderColor:
            currentSidebarComponent === component.slug
              ? enableColors
                ? getColorByComponentType(component?.type, 0.5)
                : theme.palette.common.black
              : theme.palette.quote,
          background:
            currentSidebarComponent === component.slug
              ? enableColors
                ? getColorByComponentType(component?.type, 0.05)
                : darken(theme.palette.common.white, 0.03)
              : "unset",
        }}
      >
        <div ref={dragRef} className={s.draggable}>
          <IconButton
            className={s.dragButton}
            children={<DragIcon />}
            disableRipple
            size="large"
          />
        </div>
        <ListItemIcon
          children={<ComponentIcon variant="icon" componentData={component} />}
          className={s.icon}
        />
        <ListItemText
          primary={component.title}
          secondary={
            <span className={s.secondaryTextWrapper}>
              {secondaryText}
              {component.type === ComponentType.CHECKIN &&
                component.repeat !== ComponentRepeat.NONE && (
                  <Loop className={s.repeatIcon} />
                )}
            </span>
          }
          primaryTypographyProps={{
            className: s.title,
          }}
          secondaryTypographyProps={{
            className: s.subtitle,
          }}
        />
        {!isDragging && (
          <ListItemSecondaryAction
            sx={{
              display: "flex",
              aligntItems: "center",
              flexDirection: isSm && "column",
            }}
          >
            {IS_OVERRIDE && (
              <IconButton
                className={s.lockIcon}
                children={<FlagTriangleRight />}
                size="large"
                disabled
              />
            )}

            {component.status !== ComponentStatus.PUBLISHED && (
              <Typography
                className={s.statusText}
                component="span"
                children={
                  component.status === ComponentStatus.DRAFT
                    ? "Draft"
                    : "Archived"
                }
              />
            )}
            {component.locked && (
              <MinimizedTooltip
                placement={isSm ? "bottom" : "left"}
                title="Content appears locked for clients until scheduled date"
              >
                {/* span for enabling tooltip for disabled component */}
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    className={s.lockIcon}
                    children={<Lock />}
                    size="large"
                    disabled
                  />
                </span>
              </MinimizedTooltip>
            )}

            {!mobile && (
              <>
                <IconButton
                  className={clsx(
                    s.menuButton && Boolean(menuAnchorEl) && s.menuOpen,
                  )}
                  children={<MoreHoriz />}
                  onClick={handleMoreButtonClick}
                  aria-controls={menuId}
                  aria-haspopup="true"
                  size="large"
                />

                <IconButton
                  children={<ArrowForward />}
                  onClick={() => componentClickHandler(component.slug)}
                  disabled={!component.slug}
                  size="large"
                />

                <WeekComponentMenu
                  id={menuId}
                  containerWeekId={weekId}
                  anchorEl={menuAnchorEl}
                  componentData={component}
                  open={Boolean(menuAnchorEl)}
                  onClose={closeMenu}
                  view={ProgramDetailsViewMode.LIST}
                />
              </>
            )}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </Box>
  );
}
