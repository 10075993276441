import clsx from "clsx";
import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useMediaMobile } from "../../hooks/useMediaMobile";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useGenericErrorHandler } from "../../hooks/useGenericErrorHandler";
import { colorSystem } from "../../theme";

import { SignUpSetUpSide } from "./SignUpSetUpSide";
import { SignUpSetUpForm, SignUpValues } from "./SignUpSetUpForm";
import { useSignInMutation } from "./mutations/SignIn";
import { useSignUpMutation } from "./mutations/SignUp";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { COACH_PROGRAMS_PUBLISHED_ROUTE } from "../../routes/routes";
import { useMutation } from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
  },

  side: {
    width: "40%",
    backgroundColor: theme.palette.common.white,
  },

  form: {
    margin: "auto",
    backgroundColor: colorSystem.white2,

    "&$mobile": {
      padding: theme.spacing(4),
      textAlign: "center",
    },
  },

  mobile: {},
}));

export interface SignUpSetUpScreenProps extends BoxProps {
  code?: string;
  id?: string;
  email?: string;
}

/**
 * TODO_API_V2:
 * @deprecated This component is seems to be outdated and not used anymore.
 * Appropriate sign up actions/endpoint is not implemented on the backend.
 * This component should be removed or updated.
 */

export function SignUpSetUpScreen(props: SignUpSetUpScreenProps) {
  const { className, code, id, email, ...other } = props;
  const navigate = useNavigate();
  const s = useStyles();
  const [values, setValues] = React.useState<SignUpValues>({
    name: "",
    password: "",
  });
  const [errors, setErrors] = React.useState<SignUpValues>({
    name: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);
  const isMobile = useMediaMobile();
  const [trackEvent] = useAnalytics();
  const handleError = useGenericErrorHandler({
    setFieldError: (field, message) => {
      if (field === "name" || field === "password") {
        setErrors((errors) => ({
          ...errors,
          [field]: message,
        }));
      }
    },
  });
  const { mutate: signIn, isPending: signingIn } = useSignInMutation();
  const { mutate: verifyEmail, isPending: verifyingEmail } = useMutation({
    mutationKey: ["complete-coach-sign-up"],
    mutationFn: FbUsersService.verifyUserEmail,
  });
  const { showToastAlert } = useToastAlert();
  const handleChange = React.useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (Object.keys(values).includes(name)) {
        setValues((values) => ({
          ...values,
          [name]: value,
        }));
        setErrors((errors) => ({
          ...errors,
          [name]: value ? undefined : errors[name],
        }));
      }
    },
    [values],
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      verifyEmail(
        {
          email,
          oobCode: code,
        },
        {
          onSuccess: (response) => {
            firebase
              .auth()
              .applyActionCode(code)
              .then(() =>
                firebase
                  .auth()
                  .signInWithEmailAndPassword(email, values.password),
              )
              .then((x) => x.user.getIdToken())
              .then((idToken) => {
                trackEvent("Coach - Email Verified");
                signIn(
                  {
                    idToken,
                  },
                  {
                    onSuccess: () => {
                      navigate(
                        COACH_PROGRAMS_PUBLISHED_ROUTE + "?photoPrompt=true",
                      );
                    },
                  },
                );
              })
              .catch((error) => {
                console.error(error);
                showToastAlert("error", { message: error.message });
              })
              .finally(() => {
                setLoading(false);
              });
          },
          onError: handleError,
        },
      );
    },
    [
      code,
      email,
      id,
      handleError,
      verifyEmail,
      showToastAlert,
      trackEvent,
      values.name,
      values.password,
    ],
  );

  return (
    <Box className={clsx(s.root, className)} {...other}>
      {!isMobile && <SignUpSetUpSide className={s.side} />}

      <SignUpSetUpForm
        className={clsx(s.form, isMobile && s.mobile)}
        values={values}
        errors={errors}
        onChange={handleChange}
        onSubmit={handleSubmit}
        submitDisabled={
          !values.name ||
          values.password?.length < 8 ||
          loading ||
          verifyingEmail
        }
      />
    </Box>
  );
}
