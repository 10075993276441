import React from "react";
import { useParams } from "react-router-dom";

import { AppLayout } from "../../../../components/app/AppLayout";
import { ProgramSettings } from "../../../../components/program-settings/ProgramSettings";
import { useQuery } from "@tanstack/react-query";
import ProgramsService from "../../../../services/ProgramsService";
import { DefaultLoader } from "../../../../components/loading/DefaultLoader";
import { extractSlugId } from "../../../../utils/slug";
import { REACT_QUERY_NO_CACHING_OPTIONS } from "../../../../api/ReactQueryConfig";
import TrackInfoTool from "../../../../components/tools/TrackInfoTool";
import { Box, Container } from "@mui/material";

export const PROGRAM_SETTINGS_QUERY_KEY = "program-settings";

export function CoachProgramSettingsRoute() {
  // TODO_API_V2_PROGRAM change layout from separate page to tab on program
  // & use program context for information in header
  const { slug } = useParams();
  const slugId = extractSlugId(slug);
  const { data: program, isLoading: isProgramInitLoading } = useQuery({
    queryKey: [PROGRAM_SETTINGS_QUERY_KEY, { slugId }],
    queryFn: () => ProgramsService.getProgramSettings(slug),
    ...REACT_QUERY_NO_CACHING_OPTIONS, 
  });

  return (
    <>
    <Container>
      {isProgramInitLoading ? (
        <Box sx={{ marginBlock: "12px" }} >
          <DefaultLoader fullWidth />
        </Box>
      ) : (
        <>
          {/* TODO_API_V2_GROUP_SETTINGS: Investigate & migrate UnsplashContext functionality */}
          {/* <UnsplashContext.Provider value={props}> */}
          <ProgramSettings program={program} />
          {/* </UnsplashContext.Provider> */}
        </>
      )}
      <TrackInfoTool
        trackInfo={
          program && {
            name: "Coach - Program Settings",
            properties: {
              programId: program.id,
              programSlug: program.slug,
              programTitle: program.name,
            },
          }
        }
      />
      </Container>
    </>
  );
}
