import clsx from "clsx";
import React from "react";
import {
  Container,
  ContainerProps,
  Typography,
  Button,
  Box,
  TextField,
  FormGroup,
  TextFieldProps,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CardAvatar } from "../card/CardAvatar";
import { ReactComponent as ChangePasswordIcon } from "../../icons/LockClosedOutlined.svg";
import { CheckboxField, CheckboxFieldProps } from "../checkbox/CheckboxField";
import { TimezoneField } from "../fields/TimezoneField";
import { SettingsCard } from "../card/SettingsCard";
import { FieldsGroup } from "../fields/FieldsGroup";
import { UploadAvatar, UploadAvatarProps } from "../fields/UploadAvatar";
import { FieldLabel } from "../fields/FieldLabel";
import { validateEmail, validatePhone } from "../../utils/user";
import { PhoneField, PhoneFieldProps } from "../fields/PhoneField";
import { PHONE_NUMBER_VERIFICATION_CODE_SENT } from "../../constants";
import { DiscardChangesDialog } from "../dialog/DiscardChangesDialog";

import { RadioGroupField } from "../fields/RadioGroupField";
import { Units, UnitsLabels } from "../../constants";
import { ChangePasswordDialog } from "./dialogs/ChangePasswordDialog";
import { ChangeEmailDialog } from "./dialogs/ChangeEmailDialog";
import { UserInfo } from "../../hooks/useCurrentUser";
import {
  IUpdateUserProfileCommand,
  UnitSystem,
} from "@growth-machine-llc/stridist-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import FbUsersService from "../../services/FbUsersService";
import { CURRENT_USER_QUERY_KEY } from "../../wrappers/current-user/CurrentUserWrapper";
import { useToastAlert } from "../app/ToastAlert/ToastAlertProvider";
import useAuth from "../../hooks/auth/useAuth";
import {
  coachSettingsSchema,
  CoachSettingsSchema,
} from "../../utils/coachSettingsSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.slg,
  },

  headerText: {
    fontSize: 24,
    fontWeight: "bold",
  },

  subheaderText: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },

  button: {
    "&.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
    },
    fontWeight: 600,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.75, 3),
  },

  photoUrl: {
    display: "flex",
    alignItems: "center",
  },

  avatar: {
    borderRadius: theme.spacing(1),
    width: 104,
    height: 104,
    marginRight: theme.spacing(4),
  },

  fields: {
    display: "grid",
    gridGap: theme.spacing(0, 4),
    margin: theme.spacing(5, 0),
    alignItems: "center",
    gridTemplateColumns: "1fr",

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },

  unit: {
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  controlLabelStyle: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },

  passwordButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(-1),
    padding: theme.spacing(0.5, 1),

    "& svg": {
      marginRight: theme.spacing(1),
    },

    "& svg [path]": {
      color: theme.palette.primary.main,
    },

    "& svg [stroke]": {
      stroke: theme.palette.primary.main,
    },
  },

  verifyButtonBox: {
    height: "100%",
  },

  verifyButton: {
    height: theme.spacing(7),
    minWidth: "50%",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "20px",
    width: "100%",
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4.25),
      width: "auto",
    },
  },

  dialog: {
    "& .MuiDialog-paperWidthSm": {
      [theme.breakpoints.up("sm")]: {
        minWidth: 500,
      },
    },
  },

  emailField: {
    [theme.breakpoints.up("sm")]: {
      position: "relative",

      "& > button": {
        position: "absolute",
        left: 0,
        bottom: theme.spacing(-5),
      },
    },
  },

  changeEmailButton: {
    marginBottom: theme.spacing(-2.5),
  },

  error: {
    position: "absolute",
  },

  fullNameField: {
    alignSelf: "start",
  },
}));

export interface CoachSettingsAccountProps
  extends Omit<ContainerProps, "children"> {
  user: UserInfo;
}

export function CoachSettingsAccount(props: CoachSettingsAccountProps) {
  const { className, user, ...other } = props;
  const s = useStyles();
  const [passwordDialogOpen, setPasswordDialogOpen] = React.useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    watch,
    reset,
    setValue,
    control,
  } = useForm<CoachSettingsSchema>({
    mode: "onBlur",
    resolver: zodResolver(coachSettingsSchema()),
    defaultValues: {
      ...user,
      fullName: user.displayName,
      units: user.units as Units,
      picture: user.photoUrl,
      phoneCode: user.phoneCode || "us",
    },
  });

  const [isUploadingAvatar, setIsUploadingAvatar] = React.useState(false);
  const { refreshToken } = useAuth();
  const queryClient = useQueryClient();
  const { showToastAlert } = useToastAlert();
  const {
    mutate: updateUser,
    isPending: updateUserInFlight,
    status: updateUserStatus,
  } = useMutation({
    mutationKey: ["update-user-account-settings"],
    mutationFn: FbUsersService.updateUser,

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
      if (variables.timezone != user.timeZone) {
        refreshToken();
        reset({
          ...variables,
          fullName: user.displayName,
          picture: user.photoUrl,
        });
      }
    },
  });

  const inFlight = updateUserInFlight;

  const handleError = React.useCallback(
    (message: string) => {
      showToastAlert("error", {
        message,
      });
    },
    [showToastAlert],
  );

  const unitOptions = React.useMemo(
    () =>
      Object.keys(Units).map((unit) => ({
        value: unit,
        label: UnitsLabels[unit],
      })),
    [],
  );

  const handleChangePassword = React.useCallback(() => {
    setPasswordDialogOpen(true);
  }, []);

  const handlePasswordDialogClose = React.useCallback(() => {
    setPasswordDialogOpen(false);
  }, []);

  const handleOpenEmailDialog = React.useCallback(() => {
    setEmailDialogOpen(true);
  }, []);

  const handleCloseEmailDialog = React.useCallback(() => {
    setEmailDialogOpen(false);
  }, []);

  const handleUploadingAvatar: UploadAvatarProps["onUploading"] =
    React.useCallback((value) => {
      setIsUploadingAvatar(value);
    }, []);

  const handleSave = (data: CoachSettingsSchema) => {
    updateUser(
      {
        ...data,
        id: user.id,
        displayName: data.fullName,
        photoUrl: data.picture,
        units: data.units as UnitSystem,
      } as IUpdateUserProfileCommand,
      {
        onError: (error) => {
          showToastAlert("error", { message: "Failed to update settings" });
        },
      },
    );
  };

  const handleUnitsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("units", event.target.value as Units, { shouldDirty: true });
  };

  const validationErrorMessages = Object.values(errors)
    .map((error) => error?.message)
    .join(".  ");

  return (
    <Container className={clsx(s.root, className)} {...other}>
      <DiscardChangesDialog dirty={isDirty} />
      <SettingsCard
        header="Profile"
        ButtonProps={{
          disabled: !isDirty || isUploadingAvatar,
          onClick: handleSubmit(handleSave, () =>
            showToastAlert("error", {
              message: validationErrorMessages,
              title: "Some entered data doesn't pass form validation.",
            }),
          ),
          status: updateUserStatus,
        }}
      >
        <FieldLabel>Profile image</FieldLabel>

        <Box className={s.photoUrl}>
          <CardAvatar className={s.avatar} src={watch("picture")} />

          <UploadAvatar
            setValue={setValue}
            setError={setError}
            onError={handleError}
            value={watch("picture")}
            disabled={inFlight || isUploadingAvatar}
            onUploading={handleUploadingAvatar}
          >
            <Button
              variant="contained"
              className={s.button}
              disabled={inFlight}
              fullWidth
            >
              Upload new picture
            </Button>
          </UploadAvatar>
        </Box>

        <Box className={s.fields}>
          <FieldsGroup
            label="Full name"
            hideErrorIcon={true}
            error={errors.fullName?.message}
            errorClassName={s.error}
            className={s.fullNameField}
          >
            <TextField
              variant="outlined"
              name="fullName"
              {...register("fullName")}
              placeholder={"Please add your name"}
              fullWidth
              disabled={inFlight}
            />
          </FieldsGroup>

          <FieldsGroup className={s.emailField} label="Email address">
            <TextField
              variant="outlined"
              fullWidth
              type="helperText"
              value={user.email}
              disabled
            />
            <Button
              className={s.changeEmailButton}
              variant="text"
              color="primary"
              onClick={handleOpenEmailDialog}
            >
              Change email address
            </Button>
          </FieldsGroup>

          <FieldsGroup label="Password">
            <TextField
              variant="outlined"
              type="password"
              value="••••••••••••"
              fullWidth
              disabled
            />
          </FieldsGroup>

          <Box>
            <Button
              variant="text"
              onClick={handleChangePassword}
              className={s.passwordButton}
              disabled={inFlight}
            >
              <ChangePasswordIcon />
              Change password
            </Button>

            <Typography variant="body1" className={s.subheaderText}>
              {user?.passwordUpdatedAt &&
                `Last changed ${user?.passwordUpdatedAt.format("MMM DD, YYYY")}`}{" "}
            </Typography>
          </Box>

          <TimezoneField
            errorMessage={errors.timezone?.message}
            control={control}
            disabled={inFlight}
          />
          <Box></Box>
          <RadioGroupField
            className={s.unit}
            label="Unit of Measurement"
            value={watch("units")}
            options={unitOptions}
            controlLabelStyle={s.controlLabelStyle}
            onChange={handleUnitsChange}
            disabled={inFlight}
            hideErrorIcon={true}
            error={errors.units?.message}
            errorStyle={s.error}
          />
        </Box>
      </SettingsCard>

      {emailDialogOpen && (
        <ChangeEmailDialog
          className={s.dialog}
          id={user.id}
          email={user.email}
          open={true}
          onClose={handleCloseEmailDialog}
          fullScreen={false}
        />
      )}

      {passwordDialogOpen && (
        <ChangePasswordDialog
          id={user.id}
          open={true}
          onClose={handlePasswordDialogClose}
          fullScreen={false}
        />
      )}
    </Container>
  );
}
