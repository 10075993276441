import { ProgramDetailsViewMode } from "./ProgramDetailsViewButton";

// since sheets could be quiet heavy to render
export const SHEETS_MAX_WEEK_RANGE = 3;
// TODO decide values
export const CALENDAR_MAX_WEEK_RANGE = 5;
export const LIST_MAX_WEEK_RANGE = 5;

export const getWeekPerPageMaxValue = (view: ProgramDetailsViewMode) => {
  switch (view) {
    case ProgramDetailsViewMode.CALENDAR:
      return CALENDAR_MAX_WEEK_RANGE;
    case ProgramDetailsViewMode.LIST:
      return LIST_MAX_WEEK_RANGE;
    case ProgramDetailsViewMode.SPREADSHEET:
      return SHEETS_MAX_WEEK_RANGE;

    default:
      return 1;
  }
};
