import React from "react";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

import {
  ClientsAndGroupsSelectDialog,
  EnrollDialogProps,
  EnrollmentSelected,
} from "../groups/ClientsAndGroupsSelectDialog";
import { EnrollDateDialog } from "../dialog/EnrollDateDialog";
import { ProgramProfileDto } from "@growth-machine-llc/stridist-api-client";
import { HeaderButton } from "../button/HeaderButton";

export interface EnrollActionProps {
  disabled?: boolean;
  program: ProgramProfileDto;
  clientsCount: number;
  groupsCount: number;
}

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: theme.spacing(20),
    borderRadius: theme.spacing(0.5),
    fontSize: 16,
    fontWeight: 700,
    whiteSpace: "nowrap",
    padding: theme.spacing(0.75, 5.5),
  },
}));

export function EnrollAction(props: EnrollActionProps) {
  const { disabled = false, program, clientsCount, groupsCount } = props;
  const s = useStyles();
  const [enrollDialogOpen, setEnrollDialogOpen] = React.useState(false);
  const [enrollDateDialogOpen, setEnrollDateDialogOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<EnrollmentSelected>({});

  const handleEnrollClick = React.useCallback(() => {
    setEnrollDialogOpen(true);
  }, []);

  const handleCloseEnrollDialog = React.useCallback(() => {
    setEnrollDialogOpen(false);
  }, []);

  const handleCloseEnrollDateDialog = React.useCallback(() => {
    setEnrollDateDialogOpen(false);
  }, []);

  const handleSelected: EnrollDialogProps["onSelected"] = React.useCallback(
    (selected) => {
      setSelected(selected);
      setEnrollDateDialogOpen(true);
    },
    [],
  );

  return (
    <>
      <HeaderButton
        startIcon={<Add />}
        children="Enroll clients"
        onClick={handleEnrollClick}
        disabled={disabled}
      />

      {enrollDialogOpen && (
        <ClientsAndGroupsSelectDialog
          open={true}
          onClose={handleCloseEnrollDialog}
          program={program}
          clientsCount={clientsCount}
          groupsCount={groupsCount}
          onSelected={handleSelected}
        />
      )}

      {enrollDateDialogOpen &&
        (selected.clients?.length || selected.groups?.length) && (
          <EnrollDateDialog
            open={true}
            onClose={handleCloseEnrollDateDialog}
            program={program}
            selected={selected}
          />
        )}
    </>
  );
}
